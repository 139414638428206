import { React, useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { Navigate, useParams } from "react-router-dom";
import Cookies from 'js-cookie';
import dateFormat, { masks } from "dateformat";
import { NumericFormat } from 'react-number-format';
import callFetch from "../../helpers/callFetch";
import CorrectionRequestView from "../project/create/CorrectionRequestView";
import Reklamation from "pages/project/create/timeline/Reklamation";
import ProjectFeedBack from "pages/project/create/timeline/ProjectFeedBack";
import SoftTypography from "components/SoftTypography";
import { NavLink } from "react-router-dom";
import SoftBadge from "components/SoftBadge";
function Timeline(props) {
    const { t } = useTranslation();
    let params = useParams();

    const [refresh, setRefresh] = useState(1);
    const [data, setData] = useState([]);
    const [projectFeedback, setProjectFeedback] = useState([]);
    const [activity, setActivity] = useState({});
    var AppointmentSerial = 1;
    var QuotationSerial = 1;
    var Orderserial = 1;
    var serial = 1;


    useEffect(() => {
        if (!props.projectid) return;
        callFetch("project/timelines/" + (props?.projectid ? props?.projectid : 0), "GET", []).then((res) => {
            setData(res.data);
        });
    }, [props?.projectid + props?.refresh + (props?.refresh ?? 0)]);

    return (
        <>
            <hr/>
            {data && data.project ? (
                <div className=" mb-3">
                    <div className="">
                        <h6>{t('Project Status')}</h6>
                        <div className="timeline timeline-one-side" data-timeline-axis-style="dotted">

                            {data?.project?.aczahlertermin?.id ? (
                                <div className="timeline-block mb-3">
                                    <span className="timeline-step">
                                        <span className="brand-icon-bg"><i className="icon-sola-check-circle icon-white"></i></span>
                                    </span>
                                    <div className="timeline-content">
                                        <h6 className="text-dark text-sm font-weight-bold mb-0">{'Fertigmeldung'}</h6>
                                        <p className="text-secondary font-weight-bold text-xs m-0">{data?.project?.fertigmeldung?.[0]?.created_at ? dateFormat(data?.project?.fertigmeldung?.[0]?.created_at, "dd.mm.yyyy") : '--'}</p>

                                        <p className="text-sm m-0">
                                            <SoftTypography variant="caption"><strong>{t('Fertigmeldung')}: </strong></SoftTypography>
                                            <SoftTypography variant="caption">{data?.project?.fertigmeldungErfolgreich == 0 ? t('Approve') : t('Approve')}</SoftTypography>
                                        </p>

                                        <p className="text-sm m-0">
                                            <SoftTypography variant="caption"><strong>{t('Status')}: </strong></SoftTypography>
                                            <SoftTypography
                                                variant="caption"
                                                color={data?.project?.fertigmeldungErfolgreich == 1 ? 'success' : ''}
                                            >
                                                {data?.project?.fertigmeldungErfolgreich == 1 ? t('Erfolgreich') : t('In Berbeitung')}
                                            </SoftTypography>
                                        </p>

                                        <p className="text-sm m-0">
                                            <SoftTypography variant="caption"><strong>{t('Zählertermin')}: </strong></SoftTypography>
                                            <SoftTypography variant="caption">{data?.project?.aczahlertermin?.date ? dateFormat(data?.project?.aczahlertermin?.date, "dd.mm.yyyy") + ' ' + (data?.project?.aczahlertermin?.time ? data?.project?.aczahlertermin?.time : '') + ' Uhr' : ''}</SoftTypography>
                                        </p>

                                        <p className="text-sm m-0">
                                            <SoftTypography variant="caption"><strong>{t('Techniker')}: </strong></SoftTypography>
                                            <SoftTypography variant="caption">{data?.project?.aczahlertermin_plan?.employee?.name ? data?.project?.aczahlertermin_plan?.employee?.name : ''}</SoftTypography>
                                        </p>

                                        <p className="text-sm m-0">
                                            <SoftTypography variant="caption"><strong>{t('Termin')}: </strong></SoftTypography>
                                            <SoftTypography variant="caption">{data?.project?.aczahlertermin_plan?.date ? dateFormat(data?.project?.aczahlertermin_plan?.date, "dd.mm.yyyy") + ' ' + (data?.project?.aczahlertermin_plan?.time ? data?.project?.aczahlertermin_plan?.time : '') + ' Uhr' : ''}</SoftTypography>
                                        </p>

                                        <p className="text-sm m-0 mb-0">
                                            <SoftTypography variant="caption"><strong>{t('E-Mail')}: </strong></SoftTypography>
                                            <SoftTypography variant="caption">{data?.project?.fertigmeldung?.[0]?.created_at ? dateFormat(data?.project?.fertigmeldung[0].created_at, "HH:ss, dd.mm.yyyy") : '--'}</SoftTypography>
                                        </p>
                                        <p className="text-sm m-0 mb-1">
                                            <SoftTypography variant="caption">{'fertigmeldung@solacloud.de'}</SoftTypography>
                                        </p>
                                    </div>
                                </div>
                            ) : ''}


                            {data.project.reclamations && data.project.reclamations.map((reclamation, index) => (
                                <>
                                    {reclamation?.department == 'Elektromontage' ? (
                                        <>
                                            <Reklamation reclamation={reclamation} key={index} acStatusEdit={props?.acStatusEdit ? props?.acStatusEdit : false} />
                                        </>
                                    ) : ''}
                                </>
                            ))}


                            {data.assign_orders && data.assign_orders.map((assign_order) => (
                                <>
                                    {assign_order.dachmontag_elektromontage == 'Elektromontage' ? (
                                        <div className="timeline-block mb-3">
                                            <span className="timeline-step">
                                                <span className="brand-icon-bg"><i className="icon-sola-check-circle icon-white"></i></span>
                                            </span>
                                            <div className="timeline-content">
                                                <h6 className="text-dark text-sm font-weight-bold mb-0">{assign_order.dachmontag_elektromontage}</h6>
                                                <p className="text-secondary font-weight-bold text-xs m-0">{dateFormat(assign_order.created_at, "dd.mm.yyyy")}</p>
                                                <p className="text-secondary font-weight-bold text-xs m-0">{dateFormat(assign_order.created_at, "HH::ss")}</p>

                                                <p className="text-sm m-0">
                                                    <SoftTypography variant="caption"><strong>{t('Techniker')}: </strong></SoftTypography> <SoftTypography variant="caption">{assign_order?.employee?.name}</SoftTypography>
                                                </p>

                                                <p className="text-sm m-0">
                                                    <SoftTypography variant="caption"><strong>{t('Termin')}: </strong></SoftTypography>
                                                    <SoftTypography variant="caption">{assign_order?.date ? dateFormat(assign_order?.date, "dd.mm.yyyy") + ' ' + (assign_order?.time ? assign_order?.time : '') + ' Uhr' : ''}</SoftTypography>
                                                </p>

                                                {/*
                                                <p className="text-sm m-0">
                                                    <SoftTypography variant="caption"><strong>{t('Stadium')}: </strong></SoftTypography>
                                                    <SoftTypography variant="caption" color={assign_order?.acabnameprotokoll?.comisioning_status == "1" ? 'success' : ''}>{assign_order?.acabnameprotokoll?.comisioning_status == "1" ? 'Successfull' : "Not Successfull"}</SoftTypography>
                                                </p>
                                                */}

                                                <p className="text-sm m-0">
                                                    <SoftTypography variant="caption"><strong>{t('Stadium')}: </strong></SoftTypography>
                                                    <SoftTypography
                                                        sx={{
                                                            fontWeight: '500'
                                                        }}
                                                        variant="caption"
                                                        color={assign_order?.acabnameprotokoll?.comisioning_status == "1" ? 'success'
                                                            : assign_order?.acabnameprotokoll?.comisioning_status == "2" ? ''
                                                                : 'warning'
                                                        }
                                                    >
                                                        {assign_order?.acabnameprotokoll?.comisioning_status == "1" ? t('Successfull')
                                                            : assign_order?.acabnameprotokoll?.comisioning_status == "2" ? t("Not Successfull")
                                                                : 'Nicht bewertet'
                                                        }
                                                    </SoftTypography>

                                                    {assign_order?.acabnameprotokoll?.id && props?.acStatusEdit ? (
                                                        <i
                                                            className="fa fa-pencil ms-2"
                                                            style={{
                                                                cursor: 'pointer'
                                                            }}
                                                            onClick={() => {
                                                                document.getElementById('ComisioningModalOpen').click();
                                                            }}
                                                        ></i>
                                                    ) : ''}

                                                </p>
                                                <p className="text-sm mb-1">
                                                    <SoftTypography variant="caption"><strong>{t('Abnahme')}: </strong></SoftTypography> <SoftTypography variant="caption">{assign_order?.acabnameprotokoll?.comisioning_status == "1" ? <a target={'_blank'} href={Cookies.get('backend_url') + 'acabname/' + assign_order?.acabnameprotokoll?.id}>AC Abnahmeprotokoll PDF</a> : "--"}</SoftTypography>
                                                </p>

                                                {assign_order.tickets && assign_order.tickets.map((ticket) => (
                                                    <p className="text-sm mb-1">
                                                        <SoftTypography variant="caption"><strong>{t('Ticket Nr.')}: </strong></SoftTypography> <SoftTypography variant="caption"><NavLink to={'/ticket/' + ticket?.id}>T-{ticket?.id}</NavLink></SoftTypography>
                                                    </p>
                                                ))}

                                                <p className="text-sm mb-1">
                                                    <SoftTypography variant="caption"><strong>{t('Eingangsrechnung')}: </strong></SoftTypography>
                                                    <SoftTypography variant="caption">
                                                        {data.project.incoming_invoces && data.project.incoming_invoces.map((incoming_invoce) => (
                                                            <>
                                                                {incoming_invoce?.abteilung == 'Elektromontage' ? (
                                                                    <NavLink className={'ms-1'} to={'/eingangsrechnungen/' + incoming_invoce?.eingangs_rechungs_nummer}>{'ER-' + incoming_invoce?.eingangs_rechungs_nummer}</NavLink>
                                                                ) : ''}
                                                            </>
                                                        ))}
                                                    </SoftTypography>
                                                </p>

                                                {data.project.corrections && data.project.corrections.map((correction) => (
                                                    <>
                                                        {correction.related == 'auftrag' ? <CorrectionRequestView refresh={refresh} setRefresh={setRefresh} data={correction} serial={Orderserial++}></CorrectionRequestView> : ''}
                                                    </>
                                                ))}

                                                {assign_order.email_trans && assign_order.email_trans.map((email_tran) => (
                                                    <>
                                                        {email_tran?.email && email_tran?.email.split(',').map((email) => (
                                                            <>
                                                                <p className="text-sm m-0 mb-1">
                                                                    <SoftTypography variant="caption"><strong>{t('E-Mail')}: </strong></SoftTypography>
                                                                    <SoftTypography variant="caption">{dateFormat(email_tran.created_at, "HH:ss, dd.mm.yyyy")}</SoftTypography>
                                                                </p>
                                                                <p className="text-sm m-0 mb-1">
                                                                    <SoftTypography variant="caption">{email}</SoftTypography>
                                                                </p>
                                                            </>
                                                        ))}
                                                    </>
                                                ))}
                                            </div>
                                        </div>
                                    ) : ''
                                    }
                                </>
                            ))}


                            {data.project.reclamations && data.project.reclamations.map((reclamation, index) => (
                                <>
                                    {reclamation?.department == 'Buchhaltung' ? (
                                        <>
                                            <Reklamation reclamation={reclamation} key={index} />
                                        </>
                                    ) : ''}
                                </>
                            ))}



                            {data.invoices && data.invoices.map((invoice, invoice_serial) => (
                                <>
                                    <div className="timeline-block mb-3">
                                        <span className="timeline-step">
                                            <span className="brand-icon-bg"><i className="icon-sola-check-circle icon-white"></i></span>
                                        </span>
                                        <div className="timeline-content">

                                        <div className="d-flex justify-content-between align-items-center">
                                            <h6 className="text-dark font-size-14 font-weight-500 mb-0">{t('Invoice') + ' ' + JSON.parse(invoice?.product_details)?.amountPerchantage + '%'}</h6>
                                            <p className="text-secondary font-size-14 mb-0">{dateFormat(invoice?.created_at, "dd.mm.yyyy")}</p> 
                                        </div>

                                        <hr className="mb-2 mt-2"/>

                                        <div className="d-flex justify-content-between align-items-center">
                                            <h6 className="text-dark font-size-14 font-weight-500 mb-0">{t('InvoiceNr.')}</h6>
                                            <p className="text-secondary font-size-14 mb-0">
                                            {invoice?.id}
                                            </p> 
                                        </div>  
                                        
                                        <div className="d-flex justify-content-between align-items-center">
                                            <h6 className="text-dark font-size-14 font-weight-500 mb-0">{t('Payment')}</h6>
                                            <p className="text-secondary font-size-14 mb-0">
                                                {(invoice.total_paid + invoice?.total_gutschrift).toFixed(2) >= (invoice.netto).toFixed(2) ? (
                                                    <>
                                                        <span className="badge badge-success">
                                                        {t('Paid')}
                                                        </span>
                                                    </>
                                                ) : (invoice.total_paid + invoice?.total_gutschrift).toFixed(2) < (invoice.netto).toFixed(2) && (invoice.total_paid + invoice?.total_gutschrift).toFixed(2) > 0 ? (
                                                    <>
                                                        {/* <SoftTypography variant="caption" fontWeight="medium" color="warning" sx={{ lineHeight: 0 }}>
                                                            {t('Partially')}
                                                        </SoftTypography> */}
                                                        <span className="badge badge-success">
                                                        {t('Partially')}
                                                        </span>
                                                    </>
                                                ) : (
                                                    <>
                                                        {/* <SoftTypography variant="caption" fontWeight="medium" color="error" sx={{ lineHeight: 0 }}>
                                                            {t('Open')}
                                                        </SoftTypography> */}
                                                        <span className="badge badge-danger">
                                                        {t('Open')}
                                                        </span>
                                                    </>
                                                )}
                                            </p> 
                                        </div>   
                                        </div>
                                    </div>
                                </>
                            ))}



                            {data.project.reclamations && data.project.reclamations.map((reclamation, index) => (
                                <>
                                    {reclamation?.department == 'Einkauf' ? (
                                        <>
                                            <Reklamation reclamation={reclamation} key={index} />
                                        </>
                                    ) : ''}
                                </>
                            ))}




                            {data.project.reclamations && data.project.reclamations.map((reclamation, index) => (
                                <>
                                    {reclamation?.department == 'Dachmontage' ? (
                                        <>
                                            <Reklamation reclamation={reclamation} key={index} dcStatusEdit={props?.dcStatusEdit ? props?.dcStatusEdit : false} />
                                        </>
                                    ) : ''}
                                </>
                            ))}


                            {data?.project.beschaffung?.items && (data?.project.beschaffung?.items).length ? (
                                <>
                                    <div className="timeline-block mb-3">
                                        <span className="timeline-step">
                                            <span className="brand-icon-bg"><i className="icon-sola-check-circle icon-white"></i></span>
                                        </span>
                                        <div className="timeline-content">
                                            <h6 className="text-dark text-sm font-weight-bold mb-0">{t('Beschaffung')}</h6>

                                            {data?.project.beschaffung?.items && data?.project?.beschaffung?.items?.map((beschaffung, index) => (
                                                <p className="text-sm  mb-0">
                                                    <SoftTypography variant="caption"><strong>
                                                        {
                                                            beschaffung?.product_id.includes('MDL-') ? t('Liefertermin Modul')
                                                                : beschaffung?.product_id.includes('WR-') ? t('Liefertermin Wechselrichter')
                                                                    : beschaffung?.product_id.includes('Pr-') ? t('Liefertermin Zusatzprodukte')
                                                                        : beschaffung?.product_id.includes('SPC-') ? t('Liefertermin Speicher')
                                                                            : ''
                                                        }: </strong></SoftTypography>
                                                    <SoftTypography variant="caption" color={'success'}>{beschaffung?.lieferdatum ? dateFormat(beschaffung?.lieferdatum, "dd.mm.yyyy") : '---'}</SoftTypography><br />
                                                    <SoftTypography variant="caption">
                                                        {beschaffung?.qty + ' Stck. | '}

                                                        {beschaffung?.product_id.includes('MDL-') ? beschaffung?.module?.name
                                                            : beschaffung?.product_id.includes('WR-') ? beschaffung?.wechselrichter?.name
                                                                : beschaffung?.product_id.includes('Pr-') ? beschaffung?.product?.name
                                                                    : beschaffung?.product_id.includes('SPC-') ? beschaffung?.speicher?.name
                                                                        : ''}
                                                    </SoftTypography>

                                                    {beschaffung?.delivery_link ? (
                                                        <><br /><SoftTypography sx={{ textDecoration: 'underline' }} variant="caption"><a className="text-primary" target="_blank" href={beschaffung?.delivery_link}><i className="fa-solid fa-truck "></i> Link</a></SoftTypography></>
                                                    ) : ''}

                                                </p>
                                            ))}
                                        </div>
                                    </div>
                                </>
                            ) : ''}

                            {data?.project?.purchase ? (
                                <div className="timeline-block mb-3">
                                    <span className="timeline-step">
                                        <span className="brand-icon-bg"><i className="icon-sola-check-circle icon-white"></i></span>
                                    </span>
                                    <div className="timeline-content">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <h6 className="text-dark font-size-14 font-weight-500 mb-0">{t('Beschaffung')}</h6>
                                            <p className="text-secondary font-size-14  mb-0"></p> 
                                        </div>

                                        <hr className="mb-2 mt-2"/>

                                        <div className="d-flex justify-content-between align-items-center">
                                            <h6 className="text-dark font-size-14 font-weight-500 mb-0">{t('Liefertermin Modul')}</h6>
                                            <p className="text-secondary font-size-14  mb-0">
                                                <SoftTypography variant="caption">{data?.project?.purchase && data?.project?.purchase?.liefertermin_module ? dateFormat(data?.project?.purchase?.liefertermin_module, "dd.mm.yyyy") : '---'}</SoftTypography><br />
                                                <SoftTypography variant="caption">{data?.project?.purchase && data?.project?.purchase?.liefertermin_module ? (data?.project?.quations?.anzahl_module && data?.project?.quations?.anzahl_module != 'null' ? data?.project?.quations?.anzahl_module : '---') + ' Stck. | ' + (data?.project?.quations?.module_eingeben == 'no' ? (data?.project?.quations?.module?.name && data?.project?.quations?.module?.name != 'null' ? data?.project?.quations?.module?.name : '---') : (data?.project?.quations?.module_name && data?.project?.quations?.module_name != 'null' ? data?.project?.quations?.module_name : '---')) : '---'}</SoftTypography>
                                            </p> 
                                        </div> 

                                        <div className="d-flex justify-content-between align-items-center">
                                            <h6 className="text-dark font-size-14 font-weight-500 mb-0">{t('Liefertermin Speicher')}</h6>
                                            <p className="text-secondary font-size-14  mb-0">
                                                <SoftTypography variant="caption">{data?.project?.purchase && data?.project?.purchase?.siefertermin_speicher ? dateFormat(data?.project?.purchase?.siefertermin_speicher, "dd.mm.yyyy") : '---'}</SoftTypography><br />
                                                <SoftTypography variant="caption">{data?.project?.purchase && data?.project?.purchase?.siefertermin_speicher ? (data?.project?.quations?.speicher_eingeben == 'no' ? (data?.project?.quations?.speichers?.name && data?.project?.quations?.speichers?.name != 'null' ? data?.project?.quations?.speichers?.name : '---') : (data?.project?.quations?.speicher_titel && data?.project?.quations?.speicher_titel != 'null' ? data?.project?.quations?.speicher_titel : '---')) : '---'}</SoftTypography>
                                            </p> 
                                        </div> 

                                        <div className="d-flex justify-content-between align-items-center">
                                            <h6 className="text-dark font-size-14 font-weight-500 mb-0">{t('DC Liefertermin Großhändler')}</h6>
                                            <p className="text-secondary font-size-14  mb-0">
                                                {data?.project?.purchase && data?.project?.purchase?.liefertermin_grobhandler ? dateFormat(data?.project?.purchase?.liefertermin_grobhandler, "dd.mm.yyyy") : '---'}
                                            </p> 
                                        </div>  

                                        <div className="d-flex justify-content-between align-items-center">
                                            <h6 className="text-dark font-size-14 font-weight-500 mb-0">{t('AC Liefertermin Großhändler')}</h6>
                                            <p className="text-secondary font-size-14  mb-0">
                                                {data?.project?.purchase && data?.project?.purchase?.ac_liefertermin_grobhandler ? dateFormat(data?.project?.purchase?.ac_liefertermin_grobhandler, "dd.mm.yyyy") : '---'}
                                            </p> 
                                        </div> 
                                    </div>
                                </div>
                            ) : ''}

                            {data.assign_orders && data.assign_orders.map((assign_order) => (
                                <>
                                    {assign_order.dachmontag_elektromontage == 'Dachmontage' ? (
                                        <>
                                            <div className="timeline-block mb-3">
                                                <span className="timeline-step">
                                                    <span className="brand-icon-bg"><i className="icon-sola-check-circle icon-white"></i></span>
                                                </span>
                                                <div className="timeline-content">
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <h6 className="text-dark font-size-14 font-weight-500 mb-0">{assign_order.dachmontag_elektromontage}</h6>
                                                        <p className="text-secondary font-size-14  mb-0">{dateFormat(assign_order?.created_at, "dd.mm.yyyy")}  {dateFormat(assign_order?.created_at, "HH::ss")}</p> 
                                                    </div>

                                                    <hr className="mb-2 mt-2"/>


                                                    {/* <p className="text-sm mt-3 mb-2">{t('Client Information')}:</p> */}
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <p className="text-secondary font-size-14 mb-0">{t('Techniker')}</p>
                                                        <p className="text-primary font-size-14 mb-0">{assign_order?.employee?.name ? assign_order?.employee?.name : ''}</p> 
                                                    </div> 

                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <p className="text-secondary font-size-14 mb-0">{t('Termin')}</p>
                                                        <p className="text-primary font-size-14 mb-0">{assign_order?.date ? dateFormat(assign_order?.date, "dd.mm.yyyy") + ' ' + assign_order?.time + ' Uhr' : ''}</p> 
                                                    </div>  

                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <p className="text-secondary font-size-14 mb-0">{t('Stadium')}</p>
                                                        <p className="text-primary font-size-14 mb-0"> <SoftTypography
                                                            sx={{
                                                                fontWeight: '500'
                                                            }}
                                                            variant="caption"
                                                            color={assign_order?.dcabnameprotokoll?.comisioning_status == "1" ? 'success'
                                                                : assign_order?.dcabnameprotokoll?.comisioning_status == "2" ? ''
                                                                    : 'warning'}
                                                        >{assign_order?.dcabnameprotokoll?.comisioning_status == "1" ? t('Successfull')
                                                            : assign_order?.dcabnameprotokoll?.comisioning_status == "2" ? t("Not Successfull")
                                                                : 'Nicht bewertet'
                                                            }
                                                        </SoftTypography>
                                                        {assign_order?.dcabnameprotokoll?.id && props?.dcStatusEdit ? (
                                                            <i
                                                                className="fa fa-pencil ms-2"
                                                                style={{
                                                                    cursor: 'pointer'
                                                                }}
                                                                onClick={() => {
                                                                    document.getElementById('ComisioningModalOpen').click();
                                                                }}
                                                            ></i>
                                                        ) : ''}</p> 
                                                    </div>

                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <p className="text-secondary font-size-14 mb-0">{t('Abnahme')}</p>
                                                        <p className="text-primary font-size-14 mb-0">{assign_order?.dcabnameprotokoll?.comisioning_status == "1" ? <a target={'_blank'} href={Cookies.get('backend_url') + 'dcabname/' + assign_order?.dcabnameprotokoll?.id}>DC Abnahmeprotokoll PDF</a> : "--"}</p> 
                                                    </div> 
   

                                                    {assign_order.tickets && assign_order.tickets.map((ticket) => ( 
                                                        <div className="d-flex justify-content-between align-items-center">
                                                            <p className="text-secondary font-size-14 mb-0">{t('Ticket Nr.')}</p>
                                                            <p className="text-primary font-size-14 mb-0"><NavLink to={'/ticket/' + ticket?.id}>T-{ticket?.id}</NavLink></p> 
                                                        </div> 
                                                    ))}

                                                        <div className="d-flex justify-content-between align-items-center">
                                                            <p className="text-secondary font-size-14 mb-0">{t('Eingangsrechnung')}</p>
                                                            <p className="text-primary font-size-14 mb-0">{data.project.incoming_invoces && data.project.incoming_invoces.map((incoming_invoce) => (
                                                                <>
                                                                    {incoming_invoce?.abteilung == 'Dachmontage' ? (
                                                                        <NavLink className={'ms-1'} to={'/eingangsrechnungen/' + incoming_invoce?.eingangs_rechungs_nummer}>{'ER-' + incoming_invoce?.eingangs_rechungs_nummer}</NavLink>
                                                                    ) : ''}
                                                                </>
                                                            ))}</p> 
                                                        </div> 

                                               

                                                    {data.project.corrections && data.project.corrections.map((correction) => (
                                                        <>
                                                            {correction.related == 'auftrag' ? <CorrectionRequestView refresh={refresh} setRefresh={setRefresh} data={correction} serial={Orderserial++}></CorrectionRequestView> : ''}
                                                        </>
                                                    ))}

                                                    {assign_order.email_trans && assign_order.email_trans.map((email_tran) => (
                                                        <>
                                                            {email_tran?.email && email_tran?.email.split(',').map((email) => (
                                                                <>
                                                                    <p className="text-sm m-0 mb-1">
                                                                        <SoftTypography variant="caption"><strong>{t('E-Mail')}: </strong></SoftTypography>
                                                                        <SoftTypography variant="caption">{dateFormat(email_tran.created_at, "HH:ss, dd.mm.yyyy")}</SoftTypography>
                                                                    </p>
                                                                    <p className="text-sm m-0 mb-1">
                                                                        <SoftTypography variant="caption">{email}</SoftTypography>
                                                                    </p>
                                                                </>
                                                            ))}
                                                        </>
                                                    ))}
                                                </div>
                                            </div>
                                        </>
                                    ) : ''
                                    }
                                </>
                            ))}

                            {data.orders && data.orders.map((order) => (
                                <>
                                    <div className="timeline-block mb-3">
                                        <span className="timeline-step">
                                            <span className="brand-icon-bg"><i className="icon-sola-check-circle icon-white"></i></span>
                                        </span>
                                        <div className="timeline-content">
                                            <div className="d-flex justify-content-between align-items-center">
                                                <h6 className="text-dark font-size-14 font-weight-500 mb-0">{t('Order')}</h6>
                                                <p className="text-secondary font-size-14  mb-0">{dateFormat(order.created_at, "dd.mm.yyyy")}</p> 
                                            </div>

                                            <hr className="mb-2 mt-2"/>


                                            {/* <p className="text-sm mt-3 mb-2">{t('Client Information')}:</p> */}
                                            <div className="d-flex justify-content-between align-items-center">
                                                <p className="text-secondary font-size-14 mb-0">{t('Order Nr.')}</p>
                                                <p className="text-primary font-size-14 mb-0">{order.id}</p> 
                                            </div>   
               
                                            {JSON.parse(Cookies.get('permissions')).indexOf("QR") !== -1 ?
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <p className="text-secondary font-size-14 mb-0">{t('Price')} Netto</p>
                                                    <p className="text-primary font-size-14 mb-0"><NumericFormat value={order.netto} displayType="text" thousandSeparator={"."} decimalSeparator="," decimalScale={2} />€</p> 
                                                </div>  
                                                : ''}

                                            {data.project.corrections && data.project.corrections.map((correction) => (
                                                <>
                                                    {correction.related == 'Order' ? <CorrectionRequestView refresh={refresh} setRefresh={setRefresh} data={correction} serial={serial++}></CorrectionRequestView> : ''}
                                                </>
                                            ))}

                                            <div className="d-flex justify-content-between align-items-center">
                                                <p className="text-secondary font-size-14 mb-0">{t('Wunschtermin vorhanden')}</p>
                                                <p className="text-primary font-size-14 mb-0">{data?.project?.desired_assembly}</p> 
                                            </div> 
 

                                            {order.email_trans && order.email_trans.map((email_tran) => (
                                                <>

                                                    {email_tran?.email && email_tran?.email.split(',').map((email) => (
                                                        <>
                                                            <p className="text-sm m-0 mb-1">
                                                                <SoftTypography variant="caption"><strong>{t('E-Mail')}: </strong></SoftTypography>
                                                                <SoftTypography variant="caption">{dateFormat(email_tran.created_at, "HH:ss, dd.mm.yyyy")}</SoftTypography>
                                                            </p>
                                                            <p className="text-sm m-0 mb-1">
                                                                <SoftTypography variant="caption">{email}</SoftTypography>
                                                            </p>
                                                        </>
                                                    ))}
                                                </>
                                            ))}
                                        </div>
                                    </div>
                                </>
                            ))}

                            {data.quations && data.quations.map((quation) => (
                                <>
                                    <div className="timeline-block mb-3">
                                        <span className="timeline-step">
                                            <span className="brand-icon-bg"><i className="icon-sola-check-circle icon-white"></i></span>
                                        </span>
                                        <div className="timeline-content">
                                            <div className="d-flex justify-content-between align-items-center">
                                                <h6 className="text-dark font-size-14 font-weight-500 mb-0">{t('Quotation')} {quation?.resale == 1 ? '(Resale)' : ''}</h6>
                                                <p className="text-secondary font-size-14  mb-0">{dateFormat(quation.created_at, "dd.mm.yyyy")}</p> 
                                            </div>

                                            <hr className="mb-2 mt-2"/>


                                            {/* <p className="text-sm mt-3 mb-2">{t('Client Information')}:</p> */}
                                            <div className="d-flex justify-content-between align-items-center">
                                                <p className="text-secondary font-size-14 mb-0">{t('Quotation Nr.')}</p>
                                                <p className="text-primary font-size-14 mb-0">{quation.id}</p> 
                                            </div>  

                                            {JSON.parse(Cookies.get('permissions')).indexOf("QR") !== -1 ?
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <p className="text-secondary font-size-14 mb-0">{t('Price')} Netto</p>
                                                    <p className="text-primary font-size-14 mb-0"> <NumericFormat value={quation.gesami_netto} displayType="text" thousandSeparator={"."} decimalSeparator="," decimalScale={2} />€</p> 
                                                </div>   
                                                : ''} 

                                            <div className="d-flex justify-content-between align-items-center">
                                                <p className="text-secondary font-size-14 mb-0">{t('VKB Name')}</p>
                                                <p className="text-primary font-size-14 mb-0">{quation?.user?.name}</p> 
                                            </div>  

                                            {data.project.corrections && data.project.corrections.map((correction) => (
                                                <>
                                                    {correction.related == 'Quotation' ? <CorrectionRequestView refresh={refresh} setRefresh={setRefresh} data={correction} serial={QuotationSerial++}></CorrectionRequestView> : ''}
                                                </>
                                            ))}

                                            {quation.email_trans && quation.email_trans.map((email_tran) => (
                                                <>

                                                    {email_tran?.email && email_tran?.email.split(',').map((email) => (
                                                        <>
                                                            <hr/>
                                                            <div className="d-flex justify-content-between align-items-center">
                                                                <p className="text-secondary font-size-14 mb-0">{t('E-Mail')}</p>
                                                                <p className="text-primary font-size-14 mb-0">{dateFormat(email_tran.created_at, "HH:ss, dd.mm.yyyy")}</p>  
                                                            </div> 
                                                            <div className="d-flex justify-content-between align-items-center">
                                                                <p className="text-secondary font-size-14 mb-0"></p>
                                                                <p className="text-primary font-size-14 mb-0">{email}</p>  
                                                            </div> 
                                                            
                                                        </>
                                                    ))}
                                                </>
                                            ))}

                                            {quation?.client_sign_order_overview && quation?.client_sign_order_overview != 'null' ? (
                                                <SoftBadge size="xs" color={'success'} badgeContent={'Unterschrieben'} container />
                                            ) : ''}
                                        </div>
                                    </div>
                                </>
                            ))}


                            <div className="timeline-block mb-3">
                                <span className="timeline-step">
                                    <span className="brand-icon-bg"><i className="icon-sola-check-circle icon-white"></i></span>
                                </span>
                                <div className="timeline-content">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <h6 className="text-dark font-size-14 font-weight-500 mb-0">{t('Appointment')}</h6>
                                        <p className="text-secondary font-size-14  mb-0">{dateFormat(data.project.date, "dd.mm.yyyy")}</p> 
                                    </div>

                                    <hr className="mb-2 mt-2"/>


                                    {/* <p className="text-sm mt-3 mb-2">{t('Client Information')}:</p> */}
                                    <div className="d-flex justify-content-between align-items-center">
                                        <p className="text-secondary font-size-14 mb-0">{t('Name')}</p>
                                        <p className="text-primary font-size-14 mb-0">{data.project && data.project != undefined ? data.project.vorname + ' ' + data.project.name : ''}</p> 
                                    </div>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <p className="text-secondary font-size-14 mb-0">{t('Straße')}</p>
                                        <p className="text-primary font-size-14 mb-0">{data.project && data.project != undefined ? (data.project.street + ' ' + data.project.nr) : ''}</p> 
                                    </div>  
                                    <div className="d-flex justify-content-between align-items-center">
                                        <p className="text-secondary font-size-14 mb-0">{t('PLZ und Ort')}</p>
                                        <p className="text-primary font-size-14 mb-0">{data.project && data.project != undefined ? data.project.plz + ' ' + data.project.ort : ''}</p> 
                                    </div>  
                                    <div className="d-flex justify-content-between align-items-center">
                                        <p className="text-secondary font-size-14 mb-0">{t('Straße')}</p>
                                        <p className="text-primary font-size-14 mb-0">{data.project && data.project != undefined ? (data.project.street + ' ' + data.project.nr) : ''}</p> 
                                    </div>   

                                    {JSON.parse(Cookies.get('permissions')).indexOf("CanSCIn") !== -1 ? (
                                        <div className="d-flex justify-content-between align-items-center">
                                            <p className="text-secondary font-size-14 mb-0">{t('Phone')}</p>
                                            <p className="text-primary font-size-14 mb-0">{data.project && data.project != undefined ? data.project.telefonnummer : ''}</p> 
                                        </div>    
                                    ) : ''}

                                    {data.project.status_activity && data.project.status_activity.map((activity, index) => {
                                        return <>
                                            <div className="d-flex justify-content-between align-items-center">
                                                <p className="text-secondary font-size-14 mb-0">{t('Status')}</p>
                                                <p className="text-primary font-size-14 mb-0">
                                                    {activity?.project_feedback ? (
                                                        <>
                                                            <a className="text-primary"
                                                                onClick={() => {
                                                                    setProjectFeedback(activity?.project_feedback);
                                                                    setActivity(activity)
                                                                    document.getElementById('projectStatusWithFileUploadOpenBtnUpdate').click();
                                                                }}
                                                            >{activity?.sales_status?.name}</a>
                                                        </>
                                                    ) : activity?.sales_status?.name}
                                                </p> 
                                            </div> 
                                        </>
                                    })}


                                    {data.project.corrections && data.project.corrections.map((correction, index) => {
                                        return <>
                                            {correction.related == 'Appointment' ? <CorrectionRequestView refresh={refresh} setRefresh={setRefresh} data={correction} serial={AppointmentSerial++}></CorrectionRequestView> : ''}
                                        </>
                                    })}
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            ) : <></>}

            <ProjectFeedBack data={projectFeedback} activity={activity} setrefresh={setRefresh} />

            <button className="d-none" id="timeline-refresh-btn" onClick={() => setRefresh(refresh + 1)}></button>
        </>
    )
}

export default Timeline;
