import { React, useState } from "react";
import { useForm } from "react-hook-form";

// react-router-dom components
import { Navigate } from "react-router-dom";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import { FaEye, FaEyeSlash } from 'react-icons/fa'; // FontAwesome icons
import Checkbox from "@mui/material/Checkbox";

// Authentication layout components
import IllustrationLayout from "layouts/authentication/components/IllustrationLayout";

import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import callFetch from "helpers/callFetch";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import SoftAvatar from 'components/SoftAvatar'

function Illustration() {
  const [showPassword, setShowPassword] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const [apps, setApps] = useState([]);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  let tempAuth = Cookies.get('token') ? true : false;
  const [errorMessage, setErrorMessage] = useState('');
  const [signinSuccess, setSigninSuccess] = useState(tempAuth);
  const [passwordError, setPasswordError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState('dashboard');
  const [emailErrorTwo, setEmailErrorTwo] = useState(false);
  const { register, handleSubmit, setError, formState: { errors } } = useForm();
  const { i18n } = useTranslation();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const setLoginData = (data) => {
    var loginData = data?.login_data;

    if (!loginData?.data?.token || !data?.app_data?.api_url) {
      console.log('setLoginData');
      setErrorMessage('Something wrong!');
      return;
    } else {
      setErrorMessage('');
    }

    let l = { flag: 'de', lang: 'de', name: 'Deutsch' };
    const cookieOptions = rememberMe ? { expires: 360 } : undefined; // Expiration for all cookies

    i18n.changeLanguage(l.lang);
    Cookies.set('lang', JSON.stringify(l), cookieOptions);
    Cookies.set('api_url', data?.app_data?.api_url, cookieOptions);
    Cookies.set('backend_url', data?.app_data?.backend_url, cookieOptions);

    if (loginData.message === 'The entered password is invalid.') {
      setPasswordError(true);
      setEmailError(false);
      setEmailErrorTwo(false);
      return;
    }
    if (loginData.message === 'The email must be a valid email address.') {
      setEmailErrorTwo(true);
      setEmailError(false);
      setPasswordError(false);
      return;
    }
    if (loginData.message === "The email address you entered isn't connected to an account.") {
      setEmailError(true);
      setEmailErrorTwo(false);
      setPasswordError(false);
      return;
    }

    setPasswordError(false);
    setEmailError(false);

    // Apply expiration to all cookies
    Cookies.set('token', loginData.data.token, cookieOptions);
    Cookies.set(
      'user',
      JSON.stringify({
        id: loginData.data.user.id,
        name: loginData.data.user.name,
        email: loginData.data.user.email,
        mobile: loginData.data.user.mobile,
        gender: loginData.data.user.gender,
        date_of_birth: loginData.data.user.date_of_birth,
        address: loginData.data.user.address,
        photo: loginData.data.user.photo,
        rolename: loginData.data.user.roles[0]?.name,
        roleid: loginData.data.user.roles[0]?.id,
      }),
      cookieOptions
    );

    Cookies.set('permissions', JSON.stringify(loginData.data.permissions), cookieOptions);
    Cookies.set('user_cache', loginData.data.user.cache, cookieOptions);

    setTimeout(() => {
      window.location.href = process.env.REACT_APP_FRONTEND_URL + redirectUrl;
    }, 1500);
  };

  const onSubmit = (formData) => {
    setApps([]);
    setLoading(true);
    setPasswordError(false);
    setEmailError(false);
    callFetch('client-signin', 'POST', formData, setError, process.env.REACT_APP_API_URL)
      .then(loginData => {
        setLoading(false);
        if (loginData?.message === "success") {
          if (loginData?.data.length === 0) {
            setErrorMessage('Email or Password invalid');
          } else if (loginData?.data.length === 1) {
            setLoginData(loginData?.data[0]);
            setErrorMessage('');
          } else {
            setApps(loginData?.data);
            document.getElementById('companyChooseModalBtn').click();
            setErrorMessage('');
          }
        } else {
          if (loginData?.errors?.length <= 0) {
            setErrorMessage('Something wrong!');
          }
        }
      });
  }


  return signinSuccess ? <Navigate to={'/' + redirectUrl} /> :
    <>
      <IllustrationLayout
        title="Login"
        description={t('Tragen Sie Ihre E-Mail und Passwort ein')}
      >
        <form className={`needs-validation ${Object.keys(errors).length ? "was-validated" : ""}`} onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">

          <div className="form-group">
            <label>
              {t('Email')} *
            </label>
            <input
              type="email"
              placeholder={t('Email')}
              className="form-control"
              size="large"
              {...register('email', {
                required: true
              })}
              required
            />

            {errors.email ?
              <div className="invalid-feedback d-block">{t(errors.email.message)}</div>
              : emailError ? <div className="invalid-feedback d-block">{t("The email address you entered isn't connected to an account.")}</div>
                : emailErrorTwo ? <div className="invalid-feedback d-block">{t('The email must be a valid email address.')}</div>
                  : errorMessage ? <div className="invalid-feedback d-block">{t(errorMessage)}</div>
                    : ""}
          </div>

          <div className="form-group">
            <label>
              {t('Password')} *
            </label>
            <div className="d-flex align-items-center">
              <input
                type={showPassword ? 'text' : 'password'}
                placeholder={t('Password')}
                className="form-control"
                size="large"
                {...register('password', {
                  required: true
                })}
                required
              />
              {showPassword ?
                <i className="ph ph-eye-slash cursor-pointer" style={{ marginLeft: '-32px', fontSize: '20px' }} onClick={togglePasswordVisibility} />
                : <i className="ph ph-eye cursor-pointer" style={{ marginLeft: '-32px', fontSize: '20px' }} onClick={togglePasswordVisibility} />}
            </div>

            {errors.password ? <div className="invalid-feedback d-block">{t(errors.password.message)}</div> : passwordError ? <div className="invalid-feedback d-block">{t('The entered password is invalid.')}</div> : ''}
          </div>

          <SoftBox display="flex" alignItems="center">
            <SoftBox display="flex" alignItems="center">
              <Checkbox
                checked={rememberMe}
                onChange={handleSetRememberMe}
              />
              <label>{t('Erinnere dich')}</label>
            </SoftBox>
          </SoftBox>

          <SoftBox mt={3} mb={1}>
            {loading ? (
              <button type="button" disabled className="btn btn-disabled w-100">{t('Sign In...')}</button>
            ) : (
              <button type="submit" className="btn btn-primary w-100">{t('Sign In')}</button>
            )}
          </SoftBox>
        </form>
      </IllustrationLayout >


      <button
        type="button"
        id="companyChooseModalBtn"
        className="btn bg-gradient-primary d-none"
        data-bs-toggle="modal"
        data-bs-target="#companyProfileChoose"
      >{t('Company Choose')}</button>

      <div className="modal fade" id="companyProfileChoose" tabindex="-1" role="dialog" aria-labelledby="companyProfileChooseLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h6 className="modal-title" id="companyProfileChooseLabel" style={{ fontWeight: 'bold' }}>{t('Choose Profile')}</h6>
              <button
                type="button"
                className="btn-close text-dark"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body py-0" style={{ background: 'rgb(248, 249, 250)' }}>
              {apps.map((app) => (
                <>
                  <Card
                    className="mt-3"
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      setLoginData(app);
                      setErrorMessage('');
                    }}
                  >
                    <SoftBox p={2}>
                      <Grid container spacing={3} alignItems="center">
                        <Grid item>
                          <SoftAvatar
                            src={'/assets/img/placeholder.png'}
                            alt="profile-image"
                            variant="rounded"
                            size="xl"
                            shadow="sm"
                          />
                        </Grid>
                        <Grid item>
                          <SoftBox height="100%" mt={0.5} lineHeight={1}>
                            <SoftTypography variant="h5" fontWeight="medium">
                              {app?.app_data?.company_name}
                            </SoftTypography>
                            <SoftTypography variant="button" color="text" fontWeight="medium">{app?.login_data?.data?.user?.name}</SoftTypography>
                          </SoftBox>
                        </Grid>
                      </Grid>
                    </SoftBox>
                  </Card>
                </>
              ))}
            </div>
            <div className="modal-footer border-0" style={{ background: 'rgb(248, 249, 250)' }}>

            </div>
          </div>
        </div>
      </div>
    </>;
}

export default Illustration;
