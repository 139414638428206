import { React, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Navigate, useLocation } from "react-router-dom";
import { callFetch } from "../../../helpers/callFetch";
import { useTranslation } from "react-i18next";
import SoftBox from "components/SoftBox";
import { Grid } from "@mui/material";
// Settings page components
import Sidenav from "./components/Sidenav";
import Header from "./components/Header";
import BasicInfo from "./components/BasicInfo";
import MonthlySalary from "./components/MontlySalary/MonthlySalary";
import DeviceManagment from "./components/DeviceManagemt/DeviceManagment";
import StartDateExitDate from "./components/StartDateExitDate/StartDateExitDate";
import Breadcrumbs from "examples/Breadcrumbs";
import { NavLink } from 'react-router-dom'

function EmployeeCreate() {
  const { t } = useTranslation();
  const location = useLocation();
  const route = useLocation().pathname.split("/").slice(1);
  //const [designations, setDesignations] = useState([]);
  //const [roles, setRoles] = useState([]);
  //const [departments, setDepartments] = useState([]);
  const [saving, setSaving] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const [selectedImageUrl, setSelectedImageUrl] = useState("");
  const [componentShwo, setComponentShwo] = useState("Kontaktdaten");
  const [showData, setShowData] = useState({
    name: "---",
    role: "---",
    department_id: "---",
  });
  const {
    register,
    handleSubmit,
    setError,
    setValue,
    formState: { errors },
  } = useForm();
  const handleImageSelect = (imageUrl) => {
    setSelectedImageUrl(imageUrl);
  };

  const [currentStep, setCurrentStep] = useState(0);

  const steps = ["Step 1", "Step 2", "Step 3", "Step 4"];

  const handleNext = () => {
    if (currentStep < steps.length - 1) {
      setCurrentStep(currentStep + 1);
    } else {
      alert("Form submitted!");
    }
  };

  const handlePrev = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleNameChange = (name) => {
    setShowData(name);
  };
  useEffect(() => {
    /*
        callFetch("employees/create", "GET", []).then((res) => {
            setValue('employee_identity_number', res.data.id);
            setRoles(res.data.roles);
        });
        
        /*
        callFetch("designations", "GET", []).then((res) => {
            setDesignations(res.data);
        });

        callFetch("departments", "GET", []).then((res) => {
            setDepartments(res.data);
        });
        */
  }, [refresh]);

  const onSubmit = (formData) => {
    setSaving(true);
    callFetch("employees", "POST", formData, setError).then((res) => {
      setSaving(false);
      if (!res.ok) return;
      setSubmitSuccess(true);
    });
  };

  const componentSwitch = (value) => {
    setComponentShwo(value);
  };

  return submitSuccess ? (
    <Navigate to="/employees" />
  ) : (
    <SoftBox mt={4}>
      <Breadcrumbs
        icon="Home"
        title={route[route.length - 1]}
        route={route}
        light={"light"}
      />
      <div className="mt-3 mb-3" style={{ paddingBottom: '100px' }}>
        <div className="container">
          <div className="step-progress">
            <div className="step-wrapper">
              <div
                className={`step ${currentStep >= 0 ? "completed" : ""}`}
                id="step-1"
              >
                {currentStep > 0 ? <i className="fa fa-check"></i> : "1"}
              </div>
              <div
                className={`step-line ${currentStep > 0 ? "completed" : ""}`}
                id="line-1"
              >
                <span
                  className={`${currentStep == 0 ? "" : "d-none-xs-cp-progress"}`}
                >
                  Kontaktdaten
                </span>
              </div>
            </div>

            <div className="step-wrapper">
              <div
                className={`step ${currentStep >= 1 ? "completed" : ""}`}
                id="step-2"
              >
                {currentStep > 1 ? <i className="fa fa-check"></i> : "2"}
              </div>
              <div
                className={`step-line ${currentStep > 1 ? "completed" : ""}`}
                id="line-2"
              >
                <span
                  className={`${currentStep == 1 ? "" : "d-none-xs-cp-progress"}`}
                >
                  Monatiches Gehalt
                </span>
              </div>
            </div>

            <div className="step-wrapper">
              <div
                className={`step ${currentStep >= 2 ? "completed" : ""}`}
                id="step-2"
              >
                {currentStep > 1 ? <i className="fa fa-check"></i> : "3"}
              </div>
              <div
                className={`step-line ${currentStep > 2 ? "completed" : ""}`}
                id="line-2"
              >
                <span
                  className={`${currentStep == 2 ? "" : "d-none-xs-cp-progress"}`}
                >
                  Geräteverwaltung
                </span>
              </div>
            </div>

            <div className="step-wrapper" style={{ flexGrow: "0" }}>
              <div
                className={`step ${currentStep >= 3 ? "completed" : ""}`}
                id="step-2"
              >
                {currentStep > 3 ? <i className="fa fa-check"></i> : "4"}
              </div>
              <div>
                <span
                  className={`${currentStep == 3 ? "" : "d-none-xs-cp-progress"}`}
                >
                  Beginndatum/Austrittsdatum
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="step-card">
          {currentStep === 0 && (
            <div className="step-content">
              {/* <h3>Step 1</h3> */}
              <BasicInfo
                onImageSelect={handleImageSelect}
                nameChangeValue={handleNameChange}
                showData={showData}
              />
            </div>
          )}

          {/*currentStep === 1 && (
            <div className="step-content">
              <MonthlySalary
                onImageSelect={handleImageSelect}
                nameChangeValue={handleNameChange}
                showData={showData}
                title={componentShwo}
              />
            </div>
          )}

          {currentStep === 2 && (
            <div className="step-content">
              <DeviceManagment title={componentShwo} />
            </div>
          )}

          {currentStep === 3 && (
            <div className="step-content">
              <StartDateExitDate title={componentShwo} />
            </div>
          )*/}
        </div>
      </div>



      {/*
      <div className="card fixed-bottom mb-3 fixed-bottom-width-available" >
        <div className="card-footer d-flex align-items-center justify-content-end gap-2">
          <button
            className="m-0 btn btn-default border-primary me-3 text-brand-primary  d-flex align-items-center"
            type="button"
            onClick={handlePrev}
            disabled={currentStep === 0}
          >
            {" "}
            {currentStep == 0 ? (
              ""
            ) : (
              <img className="me-2" src="/assets/icons/CaretLeftBrandPrimary.svg" />
            )}{" "}
            {t("Zurück")}
          </button>
          <button
            className="m-0 btn btn-primary d-flex align-items-center"
            type={currentStep > 2 ? "submit" : "button"}
            onClick={handleNext}
          >
            {t("Speichern und fortfahren")} <img className="ms-2" src="/assets/icons/CaretRightWhite.svg" />
          </button>
        </div>
      </div>
      */}

      {/* <Grid container spacing={3}>
        <Grid item xs={12} lg={3}>
          <Sidenav switchComponent={componentSwitch} />
        </Grid>
        <Grid item xs={12} lg={9}>
          <SoftBox mb={3}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Header imageUrl={selectedImageUrl} data={showData} />
              </Grid>
              <Grid item xs={12}>
                {location?.hash === "#kontaktdaten" && (
                  <BasicInfo
                    onImageSelect={handleImageSelect}
                    nameChangeValue={handleNameChange}
                    showData={showData}
                  />
                )}

                {!location?.hash && componentShwo === "Kontaktdaten" && (
                  <BasicInfo
                    onImageSelect={handleImageSelect}
                    nameChangeValue={handleNameChange}
                    showData={showData}
                    title={componentShwo}
                  />
                )}
                {location?.hash === "#monatiches-gehalt" && (
                  <MonthlySalary
                    onImageSelect={handleImageSelect}
                    nameChangeValue={handleNameChange}
                    showData={showData}
                    title={componentShwo}
                  />
                )}
                {location?.hash === "#gerateverwaltung" && (
                  <DeviceManagment title={componentShwo} />
                )}
                {location?.hash === "#beginndatum-austrittsdatum" && (
                  <StartDateExitDate title={componentShwo} />
                )}
              </Grid>

            
            </Grid>
          </SoftBox>
        </Grid>
      </Grid> */}
    </SoftBox>
  );
}

export default EmployeeCreate;
