import { useEffect, useState } from 'react';
import { NavLink } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import dateFormat from "dateformat";
import deleteAlert from "helpers/deleteAlert";

import { Grid, Icon, Menu } from '@mui/material';
import SoftButton from 'components/SoftButton';
import callFetch from "helpers/callFetch";
import DataTable from 'react-data-table-component';
import CloseIcon from "@mui/icons-material/Close";
import ProjectStatus from 'pages/project/ProjectStatus';
import SoftSelect from "components/SoftSelect";
import { minWidth } from '@mui/system';

function UpdateStatusProject() {
    const { t } = useTranslation();
    const [activeTab, setActiveTab] = useState('Unbewertet');
    const [loading, setLoading] = useState(false);
    const [tabledata, setTableData] = useState([]);
    const [refresh, setRefresh] = useState(0);

    const [pageNumber, setPageNumber] = useState(1);
    const [data, setData] = useState([]);
    const [openMenu, setOpenMenu] = useState(null);
    const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
    const handleCloseMenu = () => setOpenMenu(null);
    const projectData = (id) => {
        setData([])
        if (id) {
            callFetch("project/timelines/" + id, "GET", []).then((res) => {
                setData(res.data);
            });
        }
    }

    const renderMenu = () => (
        data?.project && <Menu
            anchorEl={openMenu}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            transformOrigin={{ vertical: "top", horizontal: "right" }}
            open={Boolean(openMenu)}
            onClose={handleCloseMenu}
            keepMounted
        >
            <CloseIcon
                onClick={handleCloseMenu}
                style={{
                    width: "17px",
                    height: "25px",
                    cursor: "pointer",
                    zIndex: "1",
                }}
            />
            <Grid mt={0} item xs={12} lg={12} pl={8}>

            </Grid>
        </Menu>
    );

    const tableHeadings = [
        {
            name: t('Project'),
            minWidth: '124px',
            cell: row => <NavLink to={'/projects/' + row.id} className="text-brand-primary text-link">
                #{row.id}
            </NavLink>,
            sortable: false
        },
        {
            name: t('Customer'),
            minWidth: '124px',
            selector: row => row?.vorname + ' ' + (row?.name == null ? '' : row?.name),
            sortable: false
        },
        {
            name: t('VKB Name'),
            minWidth: '124px',
            selector: row => row?.salse_person?.name,
            sortable: false
        },
        {
            name: t('Erstellt am'),
            minWidth: '124px',
            selector: row => dateFormat(row?.created_at, "dd.mm.yyyy"),
            sortable: false
        },
        {
            name: t('Time'),
            minWidth: '124px',
            selector: row => row?.time,
            sortable: false
        },
        {
            name: t('Phone'),
            width: '103px',
            selector: row => row?.telefonnummer,
            sortable: false
        },
        {
            name: t('Status'),
            minWidth: '124px',
            cell: row => (
                <>
                    <span className="badge badge-dot me-4">
                        {row.stornieren == 1 ? (
                            <>
                                <i className="bg-danger"></i>
                                <span className="text-dark text-xs">{'Storno'}</span>
                            </>
                        ) : (
                            <>
                                {row?.status && row?.sales_status?.id ? (
                                    <><i className={'status-' + row?.sales_status?.color ?? "bg-dark"}></i><span className={"text-dark text-xs"}>{t(row?.sales_status?.name ?? row?.status)}</span></>
                                ) : row?.termine_qoute?.sales_status?.name && row?.termine_qoute?.status && row?.termine_qoute?.status != 'null' ? (
                                    <><i className={'status-' + row?.termine_qoute?.sales_status?.color ?? "bg-dark"}></i><span className={"text-dark text-xs "}>{t(row?.termine_qoute?.sales_status?.name ?? row?.termine_qoute?.status)}</span></>
                                ) : <span className="text-dark text-xs">{'--'}</span>}
                            </>
                        )}
                    </span>
                </>
            ),
        },

        {
            name: t('Plz'),
            minWidth: '124px',
            selector: row => row?.plz,
            sortable: false
        },
        {
            name: t('Bewertung'),
            minWidth: '124px',
            cell: row => <>
                <ProjectStatus className={'form-control-sm'} projectid={row?.id} todayCard={true} status={row?.status} salesPopup={true} errorDisplay={false} refresh={refresh} />
            </>,

        }
    ];

    useEffect(() => {
        setLoading(true);
        callFetch("projects/todaysList?activeTab=" + activeTab + "&page=" + pageNumber, "GET", []).then((res) => {
            setTableData(res.data.data);

            if (res?.data?.data?.total > 0) {
                if (JSON.parse(Cookies.get('permissions')).indexOf("UnveCC") !== -1) {
                    Cookies.set('UnveCC', 'yes');
                } else {
                    Cookies.set('UnveCC', 'no');
                }
            } else {
                Cookies.set('UnveCC', 'no');
            }
            setLoading(false);
        });
    }, [refresh, pageNumber, activeTab]);

    const handlePageChange = page => {
        setPageNumber(page);
    }

    function toPages(pages) {
        const results = [];

        for (let i = 1; i <= pages; i++) {
            results.push(i);
        }

        return results;
    }

    // RDT exposes the following internal pagination properties
    const BootyPagination = ({
        onChangePage,
        currentPage
    }) => {
        const handleBackButtonClick = () => {
            onChangePage(currentPage - 1);
        };

        const handleNextButtonClick = () => {
            onChangePage(currentPage + 1);
        };

        const handlePageNumber = (e) => {
            onChangePage(Number(e.target.value));
        };

        const pages = tabledata.last_page;
        const pageItems = toPages(pages);
        const nextDisabled = currentPage === tabledata.last_page;
        const previosDisabled = currentPage === 1;

        return (
            <div className='table-pagination d-flex justify-content-between align-items-center'>
                <p className="entries">{tabledata.from} - {tabledata.to} {t('of')} {tabledata.total} {t('entries')}</p>
                {renderMenu()}
                <ul className="pagination ">
                    <li className="page-item">
                        <button
                            className="page-link"
                            onClick={handleBackButtonClick}
                            disabled={previosDisabled}
                            aria-disabled={previosDisabled}
                            aria-label="previous page"
                        >
                            &#60;
                        </button>
                    </li>

                    {tabledata?.links.map((page) => {
                        //const className = (parseInt(page.label) === currentPage ? "page-item active" : "page-item");
                        const className = (page.active === true ? "page-item active" : "page-item");
                        return (
                            <li key={page.label} className={className + ' ' + (parseInt(page.label) || page.label == '...' ? '' : 'd-none')}>
                                <button
                                    className="page-link"
                                    onClick={page.label != '...' ? handlePageNumber : ''}
                                    value={page.label}
                                    disabled={page.label == '...' ? true : false}
                                >
                                    {page.label}
                                </button>
                            </li>
                        );
                    })}

                    <li className="page-item">
                        <button
                            className="page-link"
                            onClick={handleNextButtonClick}
                            disabled={nextDisabled}
                            aria-disabled={nextDisabled}
                            aria-label="next page"
                        >
                            &#62;
                        </button>
                    </li>
                </ul>
            </div>
        );
    };

    return (
        <>
            {tabledata?.total ? (
                <>
                    <div className="card todays-Unbewertet-tab" style={{ zIndex: (tabledata?.total && Cookies.get('UnveCC') && Cookies.get('UnveCC') == 'yes' ? 9999 : 'unset') }}>
                        <div className="card-header">
                            <h6 className='title'>{t('Unbewertet')}</h6>
                        </div>
                        <div className="card-body">
                            <DataTable
                                columns={tableHeadings}
                                data={tabledata?.data}
                                noDataComponent={loading ? t('Loading...') : t('There are no records to display')}
                                pagination
                                highlightOnHover
                                paginationComponentOptions={{ noRowsPerPage: true }}
                                paginationServer
                                paginationTotalRows={tabledata?.total}
                                onChangePage={handlePageChange}
                                paginationComponent={BootyPagination}
                            />
                        </div>
                    </div>
                    <button className="d-none" id="dashboardProjectCardListRefresh" onClick={() => { setRefresh(refresh + 1); }}></button>
                </>
            ) : ''}
        </>
    );
}

export default UpdateStatusProject;
