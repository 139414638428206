import { React, useState } from "react";
import { useForm } from "react-hook-form";
import { callFetch } from "../../../../helpers/callFetch";
import { useTranslation } from 'react-i18next';
import SoftAlert from "components/SoftAlert";

function ChangePassword() {
  const { t } = useTranslation();
  const [saving, setSaving] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [passError, setPassError] = useState(false);
  const [passErrorNot, setPassErrorNot] = useState(false);
  const {
    register,
    handleSubmit,
    setError,
    setValue,
    watch,
    formState: { errors },
  } = useForm();

  const newPassword = watch('new_password', '');
  const confirmPassword = watch('new_password_confirmation', '');

  const passwordValidation = {
    minLength: newPassword.length >= 6,
    specialChar: /[!@#$%^&*(),.?":{}|<>]/.test(newPassword),
    number: /\d/.test(newPassword),
    match: newPassword === confirmPassword && confirmPassword.length > 0,
  };

  const onSubmit = (formData) => {
    setSaving(true);
    callFetch("update-password", "POST", formData, setError).then((res) => {
      setSaving(false);
      if (res.message == 'The new password confirmation does not match.') {
        setPassErrorNot(true);
        setPassError(false);
      } else {
        if (res.message == 'error') {
          setSubmitSuccess(false);
          setPassError(true);
          setPassErrorNot(false);
        } else {

          setValue('current_password', '');
          setValue('new_password', '');
          setValue('new_password_confirmation', '');

          setPassError(false);
          setPassErrorNot(false);
          setSubmitSuccess(true);
        }
      }
    });
  };

  return (
    <>
      <form className={`needs-validation ${Object.keys(errors).length ? "was-validated" : ""}`} onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
        <div className="row" style={{ paddingBottom: '100px' }}>
          <div className="col-md-12">
            <div className="card">
              <div className="card-header">
                <h3 className="title">{t('Change Password')}</h3>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>{t('Current Password')}</label>
                      <input className="form-control"
                        {...register('current_password', {
                          required: true,
                        })}
                        placeholder={t('Current Password', {
                          required: true,
                        })}
                        type="password" required
                      />
                    </div>

                    <div className="form-group">
                      <label>{t('New Password')}</label>
                      <input className="form-control"
                        {...register('new_password', {
                          required: true,
                        })}
                        placeholder={t('New Password')}
                        type="password" required
                      />
                      {passError ? <div className="invalid-feedback">Current Password Doesn't match!</div> : ' '}


                      <div className={`d-flex align-items-center gap-1 ${passwordValidation.specialChar ? "valid-feedback" : "invalid-feedback"}`}>
                        <i className={`ph ${passwordValidation.specialChar ? "ph-check" : "ph-x"}`}></i>{t('One special character')}
                      </div>
                      <div className={`d-flex align-items-center gap-1 ${passwordValidation.minLength ? "valid-feedback" : "invalid-feedback"}`}>
                        <i className={`ph ${passwordValidation.minLength ? "ph-check" : "ph-x"}`}></i>{t('Min 6 characters')}
                      </div>
                      <div className={`d-flex align-items-center gap-1 ${passwordValidation.number ? "valid-feedback" : "invalid-feedback"}`}>
                        <i className={`ph ${passwordValidation.number ? "ph-check" : "ph-x"}`}></i>{t("One number (2 are recommended)")}
                      </div>
                      {/*<div className={`invalid-feedback d-flex align-items-center gap-1`}><i className="ph ph-x"></i>{t('Change it often')}</div>*/}
                    </div>
                    <div className="form-group">
                      <label>{t('Confirm New Password')}</label>
                      <input className="form-control"
                        {...register('new_password_confirmation')}
                        placeholder={t('Confirm New Password')}
                        type="password" required
                      />
                      {passErrorNot || (!passwordValidation.match && newPassword) ? <div className="d-flex align-items-center gap-1 invalid-feedback">New password and confirm password does not match</div> : ' '}
                    </div>

                  </div>
                </div>

              </div>

              {submitSuccess ? <div className="card-footer"><SoftAlert color="success" dismissible>Password Changed Successfully</SoftAlert></div> : ' '}
            </div>
          </div>
        </div>

        <div className="card fixed-bottom mb-3 fixed-bottom-width-available">
          <div className="card-footer d-flex align-items-center justify-content-end gap-2">
            {saving ? (
              <button type="button" className="btn btn-disabled m-0" disabled>
                {t('Saving ...')}
              </button>
            ) : (
              <button type="submit" className="btn btn-primary m-0">
                {t('Save')}
              </button>
            )}
          </div>
        </div>
      </form>
    </>
  );
}

export default ChangePassword;
