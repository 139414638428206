import React, { useState, useEffect } from "react";
import callFetch from "../../helpers/callFetch";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Grid";
import SoftDatePicker from "components/SoftDatePicker";
import Flatpickr from "react-flatpickr";
import Cookies from 'js-cookie';

const CalendarNew = () => {
    const { t } = useTranslation();
    const [currentDate, setCurrentDate] = useState(new Date());
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [projectsByDate, setProjectsByDate] = useState({});
    const [projects, setProjects] = useState([]);
    const [projectsWeek, setProjectsWeek] = useState([]);
    const [refresh, setRefresh] = useState(0);

    // Fetch projects from API and group them by date
    useEffect(() => {
        if (!selectedDate) return;

        const date = selectedDate.toLocaleDateString("en-CA"); // Format as Y-m-d

        callFetch("get-projects-by-date" + '/' + date, "GET", []).then((res) => {
            const projectsData = res.data;
            const groupedProjects = projectsData.reduce((acc, project) => {
                const projectDate = project.date;
                if (!acc[projectDate]) {
                    acc[projectDate] = [];
                }
                acc[projectDate].push(project);
                return acc;
            }, {});

            console.log(groupedProjects);

            setProjectsByDate(groupedProjects);
            setProjects(projectsData);
        });

    }, [selectedDate]);


    // Render events for the selected date
    const renderSelectedDateEvents = () => {
        if (!selectedDate) return null;

        const dateString = selectedDate.toDateString();

        return (
            <div className="inner-main">
                {projects?.length > 0 ? (
                    <>
                        {projects.map((project) => (
                            <div class="card-custom">
                                <div className="d-flex justify-content-between align-items-center border-bottom pb-2 mb-2">
                                    <NavLink to={'/projects/' + project.id} className="btn-link">{project.id}</NavLink>
                                    <NavLink to={'/projects/' + project.id} className="btn-link btn-arrow-right-icon btn-icon-primary">Zum Projekt &nbsp;</NavLink>
                                </div>
                                <div class="card-content">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <p className="m-0 text-link text-secondary">Zeit</p>
                                        <p className="m-0 text-link">{project.time}</p>
                                    </div>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <p className="m-0 text-link text-secondary">Standort</p>
                                        <p
                                            className="m-0 "
                                            style={{
                                                maxWidth: '148px',
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                            }}
                                        >
                                            <a
                                                className="text-brand-primary text-link"
                                                target="_blank"
                                                href={`https://www.google.com/maps/search/?api=1&query=${project.street}+${project.nr}+${project.plz}+${project.ort}`}
                                                style={{
                                                    color: '#1D7BDB', // Ensures link color matches the ellipsis color
                                                    textDecoration: 'none', // Optional, removes underline
                                                }}
                                            >
                                                {project.street + ' ' + project.nr + ', ' + project.plz + ' ' + project.ort}
                                            </a>
                                        </p>
                                    </div>

                                    <div className="d-flex justify-content-between align-items-center">
                                        <p className="m-0 text-link text-secondary">{project.vorname + ' ' + project.name}, Tel</p>
                                        <p className="m-0 text-link">{project.telefonnummer}</p>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center border-top pt-2 mt-2">
                                    {project?.salse_person?.photo?.length > 0 ? (
                                        <img
                                            style={{ border: '0.125rem solid #E7EBF3' }}
                                            className="w-11 h-11 rounded-full"
                                            src={`${Cookies.get('backend_url')}storage/avatar/${project?.salse_person?.photo}`}
                                            alt={`${project?.salse_person?.name} image`}
                                        />
                                    ) : (
                                        <div
                                            style={{
                                                background: 'var(--brand-light)',
                                                color: 'var(--text-secondary)',
                                                width: '28px',
                                                height: '28px',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                borderRadius: '50%',
                                                fontWeight: 500,
                                                fontSize: '11px',
                                                textTransform: 'uppercase',

                                            }}
                                            className="w-11 h-11 rounded-full"
                                        >
                                            {project?.salse_person?.name
                                                ?.split(' ')
                                                .map((word) => word[0])
                                                .join('')}
                                        </div>
                                    )}
                                    <p className="text-success m-0 text-md ms-2">{project?.salse_person?.name}</p>
                                </div>

                            </div>
                        ))}
                    </>
                ) :
                    <div
                        className="d-flex align-items-center justify-content-center"
                        style={{
                            height: '100%'
                        }}
                    >
                        <div className="text-center">
                            <img
                                src="/assets/icons/Notepad.svg"
                                style={{
                                    width: '48px',
                                    height: '52px',
                                    filter: 'brightness(0) saturate(100%) invert(35%) sepia(15%) saturate(765%) hue-rotate(182deg) brightness(91%) contrast(89%)'
                                }}
                                className="mb-2"
                            />
                            <p className="text-secondary text-md m-0 mb-2">{t('You have no projects yet')}</p>
                        </div>
                    </div>
                }
            </div>
        );
    };

    return (
        <div className="card">
            <div className="card-body">
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6} lg={12}>
                        <SoftDatePicker
                            options={{
                                inline: true,
                                dateFormat: "Y-m-d",
                                defaultDate: selectedDate,
                                weekNumbers: false,
                                onChange: (date) => { setSelectedDate(date[0]) },
                            }}
                            className="d-none"
                        />
                    </Grid>

                    <Grid item xs={12} md={6} lg={12}>
                        <div className="d-flex justify-content-between align-items-center mb-3">
                            <p className="text-lg text-primary m-0">{t('Projekte Heute')}</p>
                            <NavLink to={'/projects-create'} className="btn-link btn-plus-icon btn-icon-primary">{t('Hinzufügen')}</NavLink>
                        </div>
                        <div className="event-container">{renderSelectedDateEvents()}</div>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
};
export default CalendarNew;