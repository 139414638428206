import { React, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { callFetch } from "../../../helpers/callFetch";
import { useTranslation } from 'react-i18next';
import deleteAlert from "../../../helpers/deleteAlert";

function DepartmentManageModal(props) {
    const { t } = useTranslation();
    const [departments, setdepartments] = useState([]);
    const [create, setCreate] = useState(false);
    const [saving, setSaving] = useState(false);
    const [refresh, setRefresh] = useState(0);
    const {
        register,
        handleSubmit,
        setError,
        formState: { errors },
        reset
    } = useForm();

    let serial = 1;

    useEffect(() => {
        callFetch("departments", "GET", []).then((res) => {
            setdepartments(res.data);
        });
    }, [refresh]);

    const onSubmit = (formData) => {
        setSaving(true);
        callFetch("departments", "POST", formData, setError).then((res) => {
            setSaving(false);
            if (!res.ok) return;
            setCreate(false);
            reset();
            setRefresh(refresh + 1);
            props.refreshParent();
        });
    };

    function doDepartmentUpdate(e, id) {
        let name = e.target.parentNode.parentNode.parentNode.getElementsByClassName('department-input')[0].value;
        callFetch("departments/" + id, "POST", { department_name: name, _method: 'PUT' }).then((res) => {
            if (!res.ok) return;
            setRefresh(refresh + 1);
            props.refreshParent();
        });
    }

    return (
        <form className={`needs-validation ${Object.keys(errors).length ? "was-validated" : ""}`} onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
            <div className="modal fade" id="departmentModal" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-md modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="departmentModalLabel">{t('Department')}a</h5>
                            <button type="button" className="btn-close text-dark" data-bs-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="text-end mb-3">
                                <a class="btn-link btn-plus-icon btn-icon-primary text-end" onClick={() => { setCreate(true) }}>{t('Abteilung hinzufügen')}</a>
                            </div>

                            <div class="table-responsive border border-radius">
                                <table className="table align-items-center mb-0">
                                    <thead>
                                        <tr>
                                            <th style={{ width: '48px' }}>{t('#')}</th>
                                            <th>{t('Department')}</th>
                                            <th style={{ width: '44px' }}></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {departments && departments.map((department) => (
                                            <tr key={department.id}>
                                                <td className="align-middle text-center">
                                                    <p className="text-xs font-weight-bold mb-0">{serial++}</p>
                                                </td>
                                                <td>
                                                    {department.name}
                                                    {/*
                                                    <input className="form-control department-input" defaultValue={department.name} placeholder={t('eg. Team Lead')} />
                                                    */}
                                                </td>
                                                <td>
                                                    <i
                                                        className="ph ph-trash-simple text-status-error-primary text-xl cursor-pointer"
                                                        onClick={(e) => {
                                                            deleteAlert(e, 'departments', department.id, t).then(res => {
                                                                setRefresh(refresh + 1);
                                                                props.refreshParent();
                                                            })
                                                        }}
                                                    ></i>

                                                    {/*
                                                    <p className="mt-3 mb-0">
                                                        <button type="button" className="btn btn-outline-dark p-2 btn-sm" onClick={(e) => doDepartmentUpdate(e, department.id)}>{t('Update')}</button>
                                                        &nbsp;
                                                        <button type="button" className="btn btn-danger p-2 btn-sm" onClick={(e) => deleteAlert(e, 'departments', department.id, t).then(res => { setRefresh(refresh + 1); props.refreshParent(); })}>{t('Delete')}</button>
                                                    </p>
                                                    */}
                                                </td>
                                            </tr>
                                        ))}

                                        {create && (
                                            <tr>
                                                <td colSpan={3}>
                                                    <div className="d-flex align-items-center justify-content-end gap-2">
                                                        <input
                                                            type="text"
                                                            className="form-control border-0"
                                                            placeholder={t('eg. Marketing')}
                                                            {...register("department_name", {
                                                                required: true,
                                                            })}
                                                            required
                                                        />

                                                        <button onClick={() => { setCreate(false) }} type="button" className="btn btn-sm btn-secondary m-0">{t('Close')}</button>

                                                        {saving ? (
                                                            <button type="button" className="btn btn-sm btn-disabled m-0" disabled>
                                                                {t('Saving ...')}
                                                            </button>
                                                        ) : (
                                                            <button type="submit" className="btn btn-sm btn-primary m-0">
                                                                {t('Save')}
                                                            </button>
                                                        )}
                                                    </div>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="modal-footer d-flex gap-3">
                            <button type="button" className="btn btn-secondary m-0 px-4" data-bs-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    );
}

export default DepartmentManageModal;
