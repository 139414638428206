import { React, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import callFetch from "../../../helpers/callFetch";
import { useTranslation } from "react-i18next";
import DataTable from "react-data-table-component";
import deleteAlert from "../../../helpers/deleteAlert";
import Cookies from "js-cookie";
import { useParams } from "react-router-dom";
import dateFormat from "dateformat";
import { useForm } from "react-hook-form";
import Checkbox from "@mui/material/Checkbox";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import Tooltip from "@mui/material/Tooltip";
import SoftAvatar from "components/SoftAvatar";

function FilesIndexTable(props) {
  let params = useParams();
  const { t } = useTranslation();
  const [tabledata, setTableData] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const [selectedFilesRefresh, setSelectedFilesRefresh] = useState(0);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [toggledClearRows, setToggleClearRows] = useState(false);
  const {
    register,
    handleSubmit,
    setError,
    reset,
    setValue,
    unregister,
    formState: { errors },
  } = useForm();

  const [selectedRows, setSelectedRows] = useState(false);

  useEffect(() => {
    if (
      props?.checkForEmail &&
      props?.checkForEmail == true &&
      props?.onlyCustomFiles == 1
    ) {
      props?.setSelectedFiles(selectedFiles);
      props?.setSelectedFilesRefresh(selectedFilesRefresh);
    }
  }, [selectedFilesRefresh]);

  const handleChange = ({ selectedRows }) => {
    // console.log(selectedRows);
    setSelectedRows(selectedRows);
  };

  const tableHeadings = [];
  if (
    props?.checkForEmail &&
    props?.checkForEmail == true &&
    props?.onlyCustomFiles == 1
  ) {
    tableHeadings.push({
      name: t("Organisation"),
      width: "50%",
      selector: (row) => (
        <SoftBox display="flex" alignItems="center">
          {/* <Checkbox
                        onChange={(e) => {
                            if (e.target.checked) {
                                var add_id = selectedFiles;
                                add_id.push(row?.id)
                            } else {
                                var remove_id = selectedFiles;
                                const index = remove_id.indexOf(row?.id);
                                if (index > -1) {
                                    remove_id.splice(index, 1);
                                    setSelectedFiles(remove_id);
                                }
                            }
                            setSelectedFilesRefresh(selectedFilesRefresh + 1)
                        }} */}
          {/* //defaultChecked={false} */}
          {/* /> */}
          <SoftBox ml={1}>
            <SoftTypography variant="caption" fontWeight="medium" color="text">
              <a
                target={"_blank"}
                rel="noreferrer"
                href={Cookies.get("backend_url") + row.pdflink}
                className="font-size-14 text-brand-primary"
              >
                {row.title}
              </a>
            </SoftTypography>
          </SoftBox>
        </SoftBox>
      ),
    });
  } else {
    tableHeadings.push({
      name: t("Organisation"),
      selector: (row) => (
        <a
          target={"_blank"}
          rel="noreferrer"
          href={
            row.title == "DC Abnahmeprotokoll"
              ? Cookies.get("backend_url") + "dcabname/" + row.id
              : row.title == "Dc Reclamation Abnahmeprotokoll"
              ? Cookies.get("backend_url") + "reclamation/dcabname/" + row.id
              : row.title == "AC Abnahmeprotokoll"
              ? Cookies.get("backend_url") + "acabname/" + row.id
              : row.title == "Ac Reclamation Abnahmeprotokoll"
              ? Cookies.get("backend_url") + "reclamation/acabname/" + row.id
              : Cookies.get("backend_url") + row.pdflink
          }
          style={{
            color: "#208DFC",
            textDecoration: "underline",
            fontWeight: "400",
          }}
        >
          {row.title}
        </a>
      ),
      sortable: true,
      width: "55%",
    });

    tableHeadings.push({
      name: t("Date"),
      selector: (row) => (
        <span style={{ color: "##273042", fontWeight: "400" }}>
          {dateFormat(row.created_at, "dd.mm.yyyy")}
        </span>
      ),
    });
  }

  if (
    props?.checkForEmail &&
    props?.checkForEmail == true &&
    props?.onlyCustomFiles == 1
  ) {
    tableHeadings.push({
      name: t("Erstellt von:"),
      cell: (row) => (
        <>
          <div
            className="d-flex justify-content-between align-items-center"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Tooltip key={row.name} title={row.name} placement="bottom">
              <SoftAvatar
                src={
                  row.picture?.length > 0
                    ? `${Cookies.get("backend_url")}storage/avatar/${
                        row.picture
                      }`
                    : "/assets/img/placeholder.png"
                }
                alt={row.name}
                size="xs"
                sx={({ palette: { white } }) => ({
                  cursor: "pointer",
                  position: "relative",
                  ml: false ? -1.8 : 0,
                  "&:hover, &:focus": {
                    zIndex: "10",
                  },
                })}
              />
            </Tooltip>
          </div>
        </>
      ),
    });

    tableHeadings.push({
      name: t("Date"),
      selector: (row) => (
        <span style={{ color: "##273042", fontWeight: "400" }}>
          {dateFormat(row.created_at, "dd.mm.yyyy")}
        </span>
      ),
    });

    tableHeadings.push({
      name: "",
      width: "12%",
      selector: (row) => (
        <div className="d-flex align-items-center">
          <a
            className="me-3"
            target={"_blank"}
            rel="noreferrer"
            href={Cookies.get("backend_url") + row.pdflink}
          >
            <img src="/assets/icons/DownloadSimple.svg" />{" "}
          </a>
          <a
            className="mt-1"
            target={"_blank"}
            rel="noreferrer"
            href={Cookies.get("backend_url") + row.pdflink}
          >
            <i class="ph ph-trash-simple text-danger font-size-20"></i>
          </a>
        </div>
      ),
    });
  } else {
    tableHeadings.push({
      name: t("Erstellt von:"),
      cell: (row) => (
        <>
          <div
            className="d-flex justify-content-between align-items-center"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Tooltip key={row.name} title={row.name} placement="bottom">
              <SoftAvatar
                src={
                  row.picture?.length > 0
                    ? `${Cookies.get("backend_url")}storage/avatar/${
                        row.picture
                      }`
                    : "/assets/img/placeholder.png"
                }
                alt={row.name}
                size="xs"
                sx={({ palette: { white } }) => ({
                  cursor: "pointer",
                  position: "relative",
                  ml: false ? -1.8 : 0,
                  "&:hover, &:focus": {
                    zIndex: "10",
                  },
                })}
              />
            </Tooltip>

            <a
              className="d-flex"
              target={"_blank"}
              rel="noreferrer"
              href={Cookies.get("backend_url") + row.pdflink}
            >
              <img src="/assets/icons/DownloadSimple.svg" />{" "}
            </a>
          </div>
        </>
      ),
    });
  }

  useEffect(() => {
    setLoading(true);
    callFetch(
      "project-files/" +
        params.id +
        "?page=" +
        pageNumber +
        "&onlyCustomFiles=" +
        (props?.onlyCustomFiles ? props?.onlyCustomFiles : 0) +
        "&for=" +
        props?.for,
      "GET",
      []
    ).then((res) => {
      setTableData(res.data);
      setLoading(true);
    });
  }, [pageNumber, refresh, props?.refresh]);

  const handlePageChange = (page) => {
    setPageNumber(page);
  };

  function toPages(pages) {
    const results = [];

    for (let i = 1; i <= pages; i++) {
      results.push(i);
    }

    return results;
  }

  // RDT exposes the following internal pagination properties
  const BootyPagination = ({ onChangePage, currentPage }) => {
    const handleBackButtonClick = () => {
      onChangePage(currentPage - 1);
    };

    const handleNextButtonClick = () => {
      onChangePage(currentPage + 1);
    };

    const handlePageNumber = (e) => {
      onChangePage(Number(e.target.value));
    };

    const pages = tabledata.last_page;
    const pageItems = toPages(pages);
    const nextDisabled = currentPage === tabledata.last_page;
    const previosDisabled = currentPage === 1;

    return (
      <>
         <div className='table-pagination d-flex justify-content-between align-items-center pb-0'>
                <p className="entries">{tabledata.from} - {tabledata.to} {t('of')} {tabledata.total} {t('entries')}</p>
                <ul className="pagination ">
                    <li className="page-item">
                        <button
                            className="page-link"
                            onClick={handleBackButtonClick}
                            disabled={previosDisabled}
                            aria-disabled={previosDisabled}
                            aria-label="previous page"
                        >
                            <i className='ph ph-caret-double-left text-primary text-xl'></i>
                        </button>
                    </li>

                    <li className="page-item">
                        <button
                            className="page-link"
                            onClick={handleBackButtonClick}
                            disabled={previosDisabled}
                            aria-disabled={previosDisabled}
                            aria-label="previous page"
                        >
                            <i className='ph ph-caret-left text-primary text-xl'></i>
                        </button>
                    </li>

                    {tabledata?.links.map((page) => {
                        //const className = (parseInt(page.label) === currentPage ? "page-item active" : "page-item");
                        const className = (page.active === true ? "page-item active" : "page-item");
                        return (
                            <li key={page.label} className={className + ' ' + (parseInt(page.label) || page.label == '...' ? '' : 'd-none')}>
                                <button
                                    className="page-link"
                                    onClick={page.label != '...' ? handlePageNumber : ''}
                                    value={page.label}
                                    disabled={page.label == '...' ? true : false}
                                >
                                    {page.label}
                                </button>
                            </li>
                        );
                    })}

                    <li className="page-item">
                        <button
                            className="page-link"
                            onClick={handleNextButtonClick}
                            disabled={nextDisabled}
                            aria-disabled={nextDisabled}
                            aria-label="next page"
                        >
                            <i className='ph ph-caret-right text-primary text-xl'></i>
                        </button>
                    </li>

                    <li className="page-item">
                        <button
                            className="page-link"
                            onClick={handleNextButtonClick}
                            disabled={nextDisabled}
                            aria-disabled={nextDisabled}
                            aria-label="next page"
                        >
                            <i className='ph ph-caret-double-right text-primary text-xl'></i>
                        </button>
                    </li>
                </ul>
            </div>
      </>
    );
  };

  return (
    <>
      <DataTable
        columns={tableHeadings}
        data={tabledata?.data}
        noDataComponent={
          <div className="d-flex align-items-center justify-content-center py-3" style={{ height: "100%" }}>
              <div className="text-center">
                  <i className='ph ph-notepad data-table-no-record-icon'></i>
                  <p className="text-secondary text-md m-0">{t('There are no records to display')}</p>
              </div>
          </div>
        }
        className="data-table"
        pagination
        highlightOnHover
        paginationComponentOptions={{ noRowsPerPage: true }}
        paginationServer
        paginationTotalRows={tabledata?.total}
        onChangePage={handlePageChange}
        paginationComponent={BootyPagination}
        selectableRows selectableRowsComponentProps={{
                    className: 'table-checkbox form-check-input'
                  }}
        onSelectedRowsChange={handleChange}
        clearSelectedRows={toggledClearRows}
      />
      {selectedRows?.length >= 1 && (
        <div className="data-table-button-float" style={{bottom: '180px'}}>
          <div className="row">
            <div className="col-sm-12 btn-group-float">
              <span className="text-secondary font-size-14 data-table-btn-item-count">
                {selectedRows?.length} Von {tabledata?.data.length} ausgewahlt
              </span>{" "}
              <span></span>
              <button
                type="button"
                style={{ cursor: "pointer", marginRight: "15px" }}
                data-bs-toggle="modal"
                data-bs-target="#BeschaffungslisteDetails"
                className="btn btn-secondary mb-0"
              >
                {" "}
                <i class="ph ph-download-simple font-size-20"></i>
              </button>
              <button
                type="button"
                style={{ cursor: "pointer" }}
                data-bs-toggle="modal"
                data-bs-target="#goodsOut"
                className="btn btn-secondary mb-0 me-3"
              >
                {" "}
                <i class="ph ph-trash-simple font-size-20 text-danger"></i>
              </button>
              
              <button
                type="button"
                style={{ cursor: "pointer" }}
                data-bs-toggle="modal"
                data-bs-target="#goodsOut"
                className="btn btn-primary mb-0"
              >
                {" "}
                <i class="ph ph-envelope-simple font-size-20 text-white"></i> <span className="position-relative bottom-4">An E-Mail senden</span>
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default FilesIndexTable;
