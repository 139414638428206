import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Navigate, useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import dateFormat from "dateformat";
import flatpickr from "flatpickr";
import { NumericFormat } from 'react-number-format';
import SoftAlert from "components/SoftAlert";
import SoftSnackbar from "components/SoftSnackbar";
import callFetch from "helpers/callFetch";
import { NavLink } from 'react-router-dom'
function DeviceManagment({ title, employee, refreshData }) {
    const params = useParams();
    const { t } = useTranslation();
    const [data, setData] = useState([]);
    const [emails, setEmails] = useState([{ name: '', email: '', currentEmail: '' }]);
    const [items, setItems] = useState([{ id: 0, title: '', description: '', quantity: 0, price: 0, currentPrice: 0, total: 0, artikel_id: 0, erhalten_am: '', zuruckgegeben_am: '' }]);
    const [itemSummery, setItemSummery] = useState({ items: [], emails: [], subTotal: 0, amountPerchantage: 30, discountPercentage: 0, discount: 0, taxPercentage: 19, tax: 0, total: 0 });
    const [saving, setSaving] = useState(false);
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [refresh, setRefresh] = useState(0);
    const [refresh2, setRefresh2] = useState(0);
    const [receivedError, setReceivedError] = useState(null);
    const [qq, setQqq] = useState([{ quantity: 0 }])
    const {
        register,
        handleSubmit,
        setError,
        setValue,
        formState: { errors },
    } = useForm();

    useEffect(() => {
        var subTotal = 0;
        items.map((item) => {
            subTotal += item.total;
        });
        itemSummery.subTotal = subTotal;
        itemSummery.amount = parseFloat((itemSummery.amountPerchantage / 100) * itemSummery.subTotal);
        // setSubtotal(subTotal);
        setValue('amountPerchantage', itemSummery.amountPerchantage);
        setValue('tax', itemSummery.taxPercentage);
        itemSummery.tax = parseFloat((itemSummery.amount * (itemSummery.taxPercentage / 100)).toFixed(2));
        itemSummery.discount = parseFloat((itemSummery.amount * (itemSummery.discountPercentage / 100)).toFixed(2));
        itemSummery.total = subTotal - itemSummery.discount + itemSummery.tax;
        itemSummery.items = items;
        setItemSummery(itemSummery);
        setRefresh2(refresh2 + 1);
    }, [refresh]);

    useEffect(() => { }, [refresh2]);


    function deleteProduct() {
        var titems = [];
        items.map((t) => {
            if (!t)
                return;
            titems.push(t);
        });
        setItems(titems);
        setRefresh(refresh + 1);
    }
    useEffect(() => {
        if (employee) {
            if (!employee.product_details) return;
            let product_details = JSON.parse(employee?.product_details);
            setItemSummery(product_details);
            setItems(product_details?.items);
        }
    }, [employee]);

    const [successSB, setSuccessSB] = useState(false);
    const openSuccessSB = () => setSuccessSB(true);
    const closeSuccessSB = () => setSuccessSB(false);
    const renderSuccessSB = (
        <SoftSnackbar
            color="success"
            icon="check"
            title="Success"
            content="Geräteverwaltung Erfolgreich gespeichert"
            dateTime="Just Now"
            open={successSB}
            onClose={closeSuccessSB}
            close={closeSuccessSB}
            bgWhite
        />
    );

    const onSubmit = (formData) => {
        setSaving(true);
        formData.product_details = JSON.stringify(itemSummery);
        formData.employee_identity_number = params?.id
        formData.name = employee?.name
        formData.email = employee?.email
        callFetch("employees/" + params?.id, "POST", formData, setError).then((res) => {
            if (receivedError == null && res.message === 'success' && params?.id) {
                openSuccessSB();
            }
            setSaving(false);
            setRefresh(refresh + 1)
            refreshData();
            if (!res.ok) return;
            setSubmitSuccess(true);
        });
    };

    return submitSuccess && params?.id ? <Navigate to={'/employees/' + params?.id + '?step=3'} /> : (
        <>
            <form className={`needs-validation ${Object.keys(errors).length ? "was-validated" : ""}`} onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
                <input type="hidden" defaultValue="PUT" {...register("_method")} />

                <div className="container">
                    <div className="card">
                        <div className="card-header">
                            <h3 className='title'>{t("Geräteverwaltung")}</h3>
                        </div>
                        <div className="card-body">


                            {/* <div className="row mt-3">
                                <div className="col-md-12">
                                    <label>{t('Description')}</label>
                                    <textarea className="form-control" rows="3"
                                        defaultValue=""
                                        placeholder="eg. Description"
                                        {...register("description")}></textarea>
                                </div>
                            </div> */}

                            <div className="">
                                {items && items.map((item, i) => (
                                    <div key={i} className="bg-secondary p-2 border-radius-8 mb-3">
                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="form-group mb-2">
                                                    <label>{t('Gerät hinzufügen')}</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        defaultValue={items[i].title}
                                                        placeholder={t('z.B. Firmenauto, Handy etc')}
                                                        onChange={(e) => {
                                                            items[i].id = e.target.value;
                                                            items[i].title = e.target.value;
                                                            setItems(items);
                                                            setRefresh(refresh + 1);
                                                        }}
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-md-2">
                                                <div className="form-group mb-2">
                                                    <label>{t('Description')}</label>
                                                    <input type="text" className="form-control" defaultValue={items[i].description} placeholder={t('Beschreibung hinzufügen')} onChange={(e) => {
                                                        items[i].id = e.target.value;
                                                        items[i].description = e.target.value;
                                                        setItems(items);
                                                        setRefresh(refresh + 1);
                                                    }} />
                                                </div>
                                            </div>

                                            <div className="col-md-2">
                                                <div className="form-group mb-2">
                                                    <label>{t('Erhalten am')}</label>
                                                    {/* <input type="text" className="form-control" defaultValue={items[i].description} placeholder={t('Beschreibung hinzufügen')} onChange={(e) => {
                                                        items[i].id = e.target.value;
                                                        items[i].description = e.target.value;
                                                        setItems(items);
                                                        setRefresh(refresh + 1);
                                                    }}/> */}

                                                    <input
                                                        defaultValue={items[i].erhalten_am}
                                                        type="date"
                                                        className="form-control flatpickr"
                                                        placeholder={t('eg. 16-04-2022')}
                                                        onChange={(e) => {
                                                            items[i].id = e.target.value;
                                                            items[i].erhalten_am = e.target.value;
                                                            setItems(items);
                                                            setRefresh(refresh + 1);
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-2">
                                                <div className="form-group mb-2">
                                                    <label>{t('Zuruckgegeben am')}</label>
                                                    <input
                                                        defaultValue={items[i].zuruckgegeben_am}
                                                        type="date"
                                                        className="form-control flatpickr"
                                                        placeholder={t('eg. 16-04-2022')}
                                                        onChange={(e) => {
                                                            items[i].id = e.target.value;
                                                            items[i].zuruckgegeben_am = e.target.value;
                                                            setItems(items);
                                                            setRefresh(refresh + 1);
                                                        }}
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-md-2">
                                                <div className="form-group mb-2">
                                                    <label>{t('Qty/Hrs')}</label>
                                                    <input type="number" style={{ width: '75%' }} value={items[i].quantity} className="form-control d-inline" placeholder="eg. 0.00" defaultValue="1" onChange={(e) => {
                                                        let value = 0;
                                                        if (e.target.value.length && !isNaN(e.target.value))
                                                            value = e.target.value;
                                                        if (value > 0) {
                                                            setQqq(parseFloat(value));
                                                        }
                                                        items[i].quantity = parseFloat(value);
                                                        items[i].total = items[i].currentPrice * items[i].quantity;
                                                        setItems(items);
                                                        setRefresh(refresh + 1);
                                                    }} />
                                                    &nbsp;
                                                    <i className="ph ph-trash-simple text-error font-size-20" data-key={i} onClick={() => { delete items[i]; deleteProduct(); }}></i>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                ))}

                                <div className="row mt-3 ">
                                    <div className="col-3">
                                        <button type="button" className="btn btn-secondary d-flex" style={{ cursor: 'pointer' }} onClick={() => { setItems([...items, { id: 0, title: '', description: '', quantity: 0, price: 0, currentPrice: 0, total: 0 }]); setRefresh(refresh + 1); }}><i className="ph ph-plus me-2"></i> {t('Add Item')}</button>
                                    </div>
                                </div>



                            </div>

                            <div className="row mt-3 d-none">
                                <div className="col-md-5">{receivedError && <SoftAlert color="error" dismissible> {receivedError} </SoftAlert>}</div>
                                <div className="col-md-12 mt-3 me-auto">
                                    <div className="col-12 text-end">
                                        {!saving && (
                                            <button type="submit" className="btn btn-primary">
                                                {t('Save')}
                                            </button>
                                        )}
                                        {saving && (
                                            <button type="submit" className="btn btn-disabled" disabled>
                                                {t('Saving ...')}
                                            </button>
                                        )}
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>


                <div className="card fixed-bottom mb-3 fixed-bottom-width-available" >
                    <div className="card-footer d-flex align-items-center justify-content-end gap-2">

                        <NavLink to={`/employees/${params?.id}?step=1`} className="btn btn-secondary m-0 d-flex align-items-center justify-content-between gap-2">
                            <i className='ph ph-caret-left'></i>
                            {t('Back')}
                        </NavLink>

                        {saving ? (
                            <button type="button" className="btn btn-disabled m-0" disabled>
                                {t('Saving ...')}
                            </button>
                        ) : (
                            <button type="submit" className="btn btn-primary m-0 d-flex align-items-center justify-content-between gap-2">
                                {t("Speichern und fortfahren")}
                                <i className='ph ph-caret-right'></i>
                            </button>
                        )}
                    </div>
                </div>
            </form>


            {renderSuccessSB}
        </>
    )

}

export default DeviceManagment