import { React, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { Navigate, useParams } from "react-router-dom";
import { callFetch } from "../../helpers/callFetch";
import { useTranslation } from 'react-i18next';
import SoftSnackbar from "components/SoftSnackbar";
import Select from 'react-select';
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import GoodsCreateIndex from "./GoodsCreateIndex";
const GoodsCreate = (props) => {
    const params = useParams();
    const { t } = useTranslation();
    const [articles, setArticles] = useState([]);
    const [articleName, setArticleName] = useState(null);
    const [lager, setLager] = useState([]);
    const [saving, setSaving] = useState(false);
    const [items, setItems] = useState([]);
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [refresh, setRefresh] = useState(0);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [warehousId, setWarehousId] = useState(0);
    const [storageAreas, setStorageAreas] = useState([]);
    const [deliveryNotes, setDeliveryNotes] = useState([]);
    const [modules, setModules] = useState([]);
    const [speichers, setSpeicher] = useState([]);
    const [wechselrichtes, setWechselrichtes] = useState([]);
    const [quantity, setQuantity] = useState(0);
    const [projectID, setID] = useState(0);
    const [projects, setProjects] = useState([]); 
    const [selectedProjectId, setSelectedProjectId] = useState({ id: '' });

    const {
        register,
        handleSubmit,
        setError,
        setValue,
        formState: { errors },
    } = useForm();
  
    useEffect(() => {
        const totalQty = items?.reduce((acc, item) =>{
            return acc + item.qty;
        },0)
        setQuantity(totalQty)
    },[refresh])


    useEffect(() => { 
        setValue('project_nr', props.project_id ? props.project_id : 0);
        callFetch("get-artikels-projects-employees", "GET", []).then((res) => {
            setProjects(res.projects); 
        });
    }, []);


   

 
   
  
return submitSuccess ? <Navigate to={'/ware-houses/goods-out'} /> :
<>
 
    <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog  modal-xl modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title fs-5" id="staticBackdropLabel">Modal title</h1>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
            <div className="row"> 
                            {props?.project_id > 0 ? '' : (
                              <> 
                                <div className="col-md-4"> 
                                <SoftBox
                                                display="flex"
                                                flexDirection="column"
                                                justifyContent="flex-end"
                                                height="100%"
                                            style={{marginTop: '5px'}}>
                                                <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                        Projekt Nr.
                                                    </SoftTypography>
                                                </SoftBox>
                                                <div className="form-group ">
                                                    <Select
                                                        placeholder={t('Select')}
                                                        options={projects ? projects : {}}
                                                        //value={selectedTeams}
                                                        getOptionLabel={(option) => ((option.id ? option.id : '') + (option.vorname ? ' - ' + option.vorname : '') + ' ' + (option?.name ? option?.name : ''))}
                                                        getOptionValue={(option) => option.id}
                                                        value={selectedProjectId}
                                                        onChange={(e) => {
                                                            setValue('project_nr', e.id); 
                                                            setSelectedProjectId({
                                                                id: e?.id,
                                                                vorname: e?.vorname,
                                                                name: e?.name,
                                                                telefonnummer: e?.telefonnummer,
                                                            });
                                                        }}
                                                        required
                                                    />
                                                    {selectedProjectId?.id ? (
                                                        <p className="mt-1" style={{ fontSize: '12px', textDecoration: 'underline', fontWeight: '500' }}>{((selectedProjectId?.id ? selectedProjectId?.id : '') + (selectedProjectId?.vorname ? ' - ' + selectedProjectId?.vorname : '') + ' ' + (selectedProjectId?.name ? selectedProjectId?.name : '') + ', ' + (selectedProjectId?.telefonnummer ? selectedProjectId?.telefonnummer : ''))}</p>
                                                    ) : ''}
                                                
                                                    <div className="invalid-feedback">{errors.status && errors.status.message}</div>
                                                </div>
                                            </SoftBox>               
                                </div>
                                </>
                               )} 
                          </div>
                         <div className="row">
                            <GoodsCreateIndex project_id={selectedProjectId?.id}></GoodsCreateIndex>
                         </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary mb-0" data-bs-dismiss="modal">{t('Abbrechen')}</button>
                <button type="button" class="btn btn-primary mb-0">{t('Speishern')}</button>
            </div>
            </div>
        </div>
    </div> 
</>
}
export default GoodsCreate