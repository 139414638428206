import { React, useEffect, useState, useRef } from "react";
import { NavLink } from "react-router-dom";
import callFetch from "../../helpers/callFetch";
import { useTranslation } from "react-i18next";
import DataTable from "react-data-table-component";
import deleteAlert from "../../helpers/deleteAlert";
import Cookies from "js-cookie";
import dateFormat from "dateformat";
import SoftBadge from "components/SoftBadge";
import { Grid, Menu, MenuItem } from "@mui/material";
import Select from "react-select";
import Divider from "@mui/material/Divider";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import Icon from "@mui/material/Icon";
import ConfiguratorRoot from "examples/Configurator/ConfiguratorRoot";
import {
  useSoftUIController,
  setOpenConfigurator,
  setTransparentSidenav,
  setMiniSidenav,
  setFixedNavbar,
  setSidenavColor,
} from "context";

import Swal from "sweetalert2";
import Flatpickr from "react-flatpickr";

function IndexTable(props) {
  const { t } = useTranslation();
  const [reclamations, setReclamations] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [refresh, setRefresh] = useState(1);
  const [id, setId] = useState(0);
  const [projectId, setProjectId] = useState(0);
  const [editId, setEditId] = useState(0);
  const [controller, dispatch] = useSoftUIController();
  const {
    openConfigurator,
    transparentSidenav,
    miniSidenav,
    fixedNavbar,
    sidenavColor,
  } = controller;

  const handleCloseConfigurator = () => setOpenConfigurator(dispatch, false);

  const fp = useRef(null);
  const fpt = useRef(null);
  const [searchData, setSearchData] = useState([]);
  const [projectNumbers, setProjectNumbers] = useState([]);
  const [kundenNames, setKundenNames] = useState([]);
  const [orts, setOrts] = useState([]);
  const [menuNumber, setMenuNumber] = useState(false);
  const [menu, setMenu] = useState(false);
  const [menuOrt, setMenuOrt] = useState(false);
  const handleConfiguratorOpen = () =>
    setOpenConfigurator(dispatch, !openConfigurator);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [startDateTerm, setStartDateTerm] = useState();
  const [endDateTerm, setEndDateTerm] = useState();
  var typingTimer;
  const [loading, setLoading] = useState(false);
  const [tabledata, setTableData] = useState([]);
  const [searchKey, setSearchKey] = useState("");
  const [vorname_name, setVornameName] = useState("");
  const [min, setMin] = useState(0);
  const [max, setMax] = useState(0);
  const [vkb_name, setVKBName] = useState("");
  const [ort, setOrt] = useState("");
  const [project_nr, setProjectNr] = useState("");
  const [filterKeys, setFilterKeys] = useState({});
  const [openMenu, setOpenMenu] = useState(null);
  const [action, setAction] = useState("");
  const [reclamationNumbers, setReclamationNumbers] = useState([]);
  const [menuReclamationNumber, setMenuReclamationNumber] = useState([]);
  const [reclamationNr, setReclamationNr] = useState([]);
  const [singleStatus, setSingleStatus] = useState([]);

  useEffect(() => {
    if (JSON.parse(Cookies.get("permissions")).indexOf("OnlyDacRec") !== -1) {
      var departme = [{ value: "Dachmontage", label: "Dachmontage" }];
    }
    if (JSON.parse(Cookies.get("permissions")).indexOf("OnlyElkRec") !== -1) {
      var departme = [{ value: "Elektromontage", label: "Elektromontage" }];
    }
    if (
      JSON.parse(Cookies.get("permissions")).indexOf("OnlyDacRec") !== -1 ||
      JSON.parse(Cookies.get("permissions")).indexOf("OnlyElkRec") !== -1
    ) {
    } else {
      var departme = [
        { value: "Dachmontage", label: "Dachmontage" },
        { value: "Elektromontage", label: "Elektromontage" },
        { value: "Einkauf", label: "Einkauf" },
        { value: "Buchhaltung", label: "Buchhaltung" },
        { value: "Vertrieb", label: "Vertrieb" },
        { value: "Fertigmeldung", label: "Fertigmeldung" },
      ];
    }

    setDepartments(departme);
  }, []);

  const handleChangeAction = (action) => {
    setAction(action);
  };

  const showAlert = () => {
    const newSwal = Swal.mixin({
      customClass: {
        confirmButton: "button button-success",
        cancelButton: "button button-error",
      },
      buttonsStyling: false,
    });

    newSwal
      .fire({
        title: t("Are you sure?"),
        text: t("You won't be able to revert this!"),
        // icon: 'warning',
        showCancelButton: true,
        confirmButtonText: t("Yes, do it!"),
        cancelButtonText: t("Cancel"),
      })
      .then((result) => {
        if (result.isConfirmed) {
          var ids = [];
          selectedRows.map((row) => {
            ids.push(row.id);
          });

          var formData = new FormData();
          formData.ids = ids;
          formData.action = action;

          callFetch("delete-reclamation", "POST", formData).then((res) => {
            setRefresh(refresh + 1);
          });

          Swal.fire(t("Success!"), t("Your action has succeeded."), "success");
        }
      });
  };

  const handleSelectedRowsApply = () => {
    showAlert();
  };

  const [selectedRows, setSelectedRows] = useState(false);
  const [toggledClearRows, setToggleClearRows] = useState(false);

  const handleChange = ({ selectedRows }) => {
    console.log(selectedRows);
    setSelectedRows(selectedRows);
  };

  // Toggle the state so React Data Table changes to clearSelectedRows are triggered
  const handleClearRows = () => {
    setToggleClearRows(!toggledClearRows);
  };

  // Use the useEffect hook to change the button state for the sidenav type based on window size.
  useEffect(() => {
    setOpenConfigurator(dispatch, false);
  }, []);

  const handleDateChange = (selectedDate) => {
    // Handle selected date range
    // if(Object.keys(selectedDates).length <= 0){
    //     setStartDate('undefined');
    //     setEndDate('undefined');
    // }else{
    //     const [startDate, endDate] = selectedDates;
    //     setStartDate(dateFormat(startDate, "yyyy-mm-dd"));
    //     setEndDate(dateFormat(endDate, "yyyy-mm-dd"));
    // }
    if (Object.keys(selectedDate).length <= 0) {
      setStartDate("undefined");
    } else {
      setStartDate(dateFormat(selectedDate, "yyyy-mm-dd"));
    }
  };

  const handleDateChangeTerm = (selectedDate) => {
    // Handle selected date range
    if (Object.keys(selectedDate).length <= 0) {
      setEndDate("undefined");
    } else {
      setEndDate(dateFormat(selectedDate, "yyyy-mm-dd"));
    }
  };
  const handleClearButton = () => {
    document.getElementById("projectidInput").value = "";
    document.getElementById("kundenInput").value = "";
    document.getElementById("reclamationInput").value = "";
    fp.current.flatpickr.clear();
    fpt.current.flatpickr.clear();
  };

  var now = new Date();
  var month = Number(now.getMonth()) + 1;
  var date_day = Number(now.getDate());

  if (month <= 9) {
    month = "0" + month;
  }
  if (date_day <= 9) {
    date_day = "0" + date_day;
  }

  const current = now.getFullYear() + "-" + month + "-" + date_day;

  const conditionalRowStyles = [
    {
      when: (row) => row?.seen == 1,
      style: {
        backgroundColor: "#FBDCB3",
        "&:hover": {
          cursor: "pointer",
        },
      },
    },
    {
      when: (row) =>
        Date.parse(current) > Date.parse(row?.end_date) && row?.status == 0,
      style: {
        backgroundColor: "#FDE3E3",
        "&:hover": {
          cursor: "pointer",
        },
      },
    },
  ];

  const tableHeadings = [
    {
      name: t("Reklamation"),
      width: "120px",
      sortable: true,
      selector: (row) => (
        <NavLink className={"text-brand-primary"} to={"/reclamation/" + row.id}>
          R-{row.id}
        </NavLink>
      ),
    },
    {
      name: t("Beschreibung"),
      // width: '200px',
      sortable: true,
      cell: (row) => <>{row.title}</>,
    },
    {
      name: t("Projekt"),
      width: "90px",
      sortable: true,
      selector: (row) => (
        <NavLink
          to={"/projects/" + row.project_nr}
          className="text-brand-primary"
        >
          {row.project_nr}{" "}
          <i class="ph ph-arrow-square-out font-size-15 ms-1 position-relative"></i>
        </NavLink>
      ),
    },
    {
      name: t("Client Name"),
      sortable: true,
      selector: (row) =>
        row?.project?.vorname +
        " " +
        (row?.project?.name ? row?.project?.name : ""),
    },
    {
      name: t("Aufgaben"),
      sortable: true,
      selector: (row) => row?.status1_count + "/" + row?.checklists_count,
    },
    {
      name: t("Start Date"),
      width: "120px",
      sortable: true,
      selector: (row) => dateFormat(row?.start_date, "dd.mm.yyyy"),
    },
    {
      name: t("Deadline"),
      width: "110px",
      sortable: true,
      selector: (row) => dateFormat(row?.end_date, "dd.mm.yyyy"),
    },
    {
      name: t("Status"),
      width: "90px",
      sortable: true,
      selector: (row) =>
        row?.status == 1 ? (
          <span className="badge badge-success"> {t("Erledigt")}</span>
        ) : (
          <span className="badge badge-danger"> {t("Open")}</span>
        ),
    },
    {
      name: t("Department"),
      width: "150px",
      sortable: true,
      selector: (row) => row.department,
    },
    /*
        {
            name: t('Actions'),
            width: '120px',
            cell: row => (
                <>
                    {JSON.parse(Cookies.get('permissions')).indexOf("RecU") !== -1 ||
                        JSON.parse(Cookies.get('permissions')).indexOf("RecD") !== -1 ? (
                        <div className="text-center dropstart">
                            <a href="/" className="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                <i className="fa fa-ellipsis-v text-xs"></i>
                            </a>
                            <ul className="dropdown-menu">
                                {JSON.parse(Cookies.get('permissions')).indexOf("RecD") !== -1 ? (
                                    <li><a className="dropdown-item text-danger" href="#0" onClick={(e) => deleteAlert(e, 'reclamation', row.id, t).then(res => setRefresh(refresh + 1))}>{t('Delete')}</a></li>
                                ) : <></>}
                            </ul>
                        </div>
                    ) : <></>}
                </>
            )
        }
        */
  ];

  useEffect(() => {
    document
      .getElementsByClassName("flatpickr-input")[0]
      .setAttribute("placeholder", "Select Start Date");
    document
      .getElementsByClassName("flatpickr-input")[1]
      .setAttribute("placeholder", "Select End Date");
    console.log(reclamationNr);
    var parameters =
      pageNumber +
      "&search=" +
      searchKey +
      "&reclamation_nr=" +
      reclamationNr +
      "&project_nr=" +
      project_nr +
      "&start_date=" +
      startDate +
      "&end_date=" +
      endDate +
      "&single_status=" +
      singleStatus +
      "&vkb_name=" +
      vorname_name;
    for (let [key, value] of Object.entries(filterKeys)) {
      parameters += "&" + key + "=" + value;
    }
    callFetch("reclamations?page=" + parameters, "GET", []).then((res) => {
      setReclamations(res.data);

      if (res.reclamation_numbers != "null") {
        if (Object.keys(res.reclamation_numbers).length > 1) {
          setReclamationNumbers(res.reclamation_numbers);
          setMenuReclamationNumber(true);
        } else {
          setReclamationNumbers([]);
          setMenuReclamationNumber(false);
        }
      }

      if (res.project_numbers != "null") {
        if (Object.keys(res.project_numbers).length > 1) {
          setProjectNumbers(res.project_numbers);
          setMenuNumber(true);
        } else {
          setProjectNumbers([]);
          setMenuNumber(false);
        }
      }

      if (res.vor_names != "null") {
        if (Object.keys(res.vor_names).length > 1) {
          setKundenNames(res.vor_names);
          setMenu(true);
        } else {
          setKundenNames([]);
          setMenu(false);
        }
      }
    });
  }, [
    pageNumber,
    filterKeys,
    refresh,
    props?.refresh,
    reclamationNr,
    project_nr,
    startDate,
    endDate,
    singleStatus,
    vorname_name,
    searchKey,
  ]);

  const handlePageChange = (page) => {
    setPageNumber(page);
  };

  function toPages(pages) {
    const results = [];

    for (let i = 1; i <= pages; i++) {
      results.push(i);
    }

    return results;
  }

  // RDT exposes the following internal pagination properties
  const BootyPagination = ({ onChangePage, currentPage }) => {
    const handleBackButtonClick = () => {
      onChangePage(currentPage - 1);
    };

    const handleNextButtonClick = () => {
      onChangePage(currentPage + 1);
    };

    const handlePageNumber = (e) => {
      onChangePage(Number(e.target.value));
    };

    const pages = reclamations.last_page;
    const pageItems = toPages(pages);
    const nextDisabled = currentPage === reclamations.last_page;
    const previosDisabled = currentPage === 1;

    return (
      <>
        <br />
        <p className="float-md-start pt-2 text-secondary text-xs font-weight-bolder ms-3">
          {t("Showing")} {reclamations.from} {t("to")} {reclamations.to}{" "}
          {t("of")} {reclamations.total} {t("entries")}
        </p>
        <nav className="float-md-end me-2">
          <ul className="pagination">
            <li className="page-item">
              <button
                className="page-link"
                onClick={handleBackButtonClick}
                disabled={previosDisabled}
                aria-disabled={previosDisabled}
                aria-label="previous page"
              >
                &#60;
              </button>
            </li>
            {reclamations?.links.map((page) => {
              //const className = (parseInt(page.label) === currentPage ? "page-item active" : "page-item");
              const className =
                page.active === true ? "page-item active" : "page-item";
              return (
                <li
                  key={page.label}
                  className={
                    className +
                    " " +
                    (parseInt(page.label) || page.label == "..."
                      ? ""
                      : "d-none")
                  }
                >
                  <button
                    className="page-link"
                    onClick={page.label != "..." ? handlePageNumber : ""}
                    value={page.label}
                    disabled={page.label == "..." ? true : false}
                  >
                    {page.label}
                  </button>
                </li>
              );
            })}
            <li className="page-item">
              <button
                className="page-link"
                onClick={handleNextButtonClick}
                disabled={nextDisabled}
                aria-disabled={nextDisabled}
                aria-label="next page"
              >
                &#62;
              </button>
            </li>
          </ul>
        </nav>
        <div className="clearfix"></div>
      </>
    );
  };

  return (
    <>
      <div className="d-flex justify-content-between pt-3 pb-3">
        <p className="font-size-20 text-primary mb-0 font-weight-600">
          {t("All Reclamation")}
        </p>
        <div className="w-33 d-flex align-items-center justify-content-between">
          <button
            onClick={handleConfiguratorOpen}
            className="btn btn-secondary me-2 w-30 mb-0 font-size-16 d-none"
          >
            <i class="ph ph-funnel-simple font-size-20"></i>{" "}
            <span className="position-relative bottom-3">Filter</span>
          </button>
          <input
            type="text"
            placeholder={t("Search...")}
            className=" form-control w-100"
            defaultValue={searchKey}
            /*
                                    onChange={(e)=> {
                                        setSearchKey(e.target.value);
                                    }}
                                    */
            onKeyUp={(e) => {
              clearTimeout(typingTimer);
              typingTimer = setTimeout(() => {
                setTableData([]);
                setSearchKey(e.target.value);
              }, 1500);
            }}
            onKeyDown={(e) => {
              clearTimeout(typingTimer);
            }}
          />
        </div>
      </div>
      <DataTable
        columns={tableHeadings}
        data={reclamations.data}
        noDataComponent={t("There are no records to display")}
        pagination
        highlightOnHover
        selectableRows selectableRowsComponentProps={{
                    className: 'table-checkbox form-check-input'
                  }}
        onSelectedRowsChange={handleChange}
        clearSelectedRows={toggledClearRows}
        paginationComponentOptions={{ noRowsPerPage: true }}
        conditionalRowStyles={conditionalRowStyles}
        paginationServer
        paginationTotalRows={reclamations.total}
        onChangePage={handlePageChange}
        paginationComponent={BootyPagination}
        // subHeader
        subHeaderComponent={
          <>
            {selectedRows?.length >= 1 && (
              <div
                style={{
                  position: "absolute",
                  left: "15px",
                  width: "250px",
                  marginTop: "15px",
                }}
              >
                <div className="row">
                  <div className="col-sm-8">
                    <select
                      className="form-control"
                      onChange={(e) => handleChangeAction(e.target.value)}
                    >
                      <option value="delete">Löschen</option>
                      <option value="Erledigt">{t("Erledigt Setzen")}</option>
                      <option value="Open">{t("Open Setzen")}</option>
                    </select>
                  </div>
                  <div className="col-sm-4">
                    <buttn
                      className="btn btn-secondary"
                      style={{ paddingTop: "10px", paddingBottom: "10px" }}
                      onClick={() => handleSelectedRowsApply()}
                    >
                      Apply
                    </buttn>
                  </div>
                </div>
              </div>
            )}

            <button
              onClick={handleConfiguratorOpen}
              className="btn btn-outline-dark mx-2"
              style={{
                paddingTop: "10px",
                paddingBottom: "10px",
                margin: "0px",
              }}
            >
              <i class="fa-solid fa-filter"></i> Filter
            </button>
            <input
              type="text"
              placeholder={t("Search...")}
              className=" form-control w-sm-50 w-md-25 w-lg-15 ms-1 mt-1"
              defaultValue={searchKey}
              /*
                            onChange={(e)=> {
                                setSearchKey(e.target.value);
                            }}
                            */
              onKeyUp={(e) => {
                clearTimeout(typingTimer);
                typingTimer = setTimeout(() => {
                  setTableData([]);
                  setSearchKey(e.target.value);
                }, 1500);
              }}
              onKeyDown={(e) => {
                clearTimeout(typingTimer);
              }}
            />
          </>
        }
      />

      <ConfiguratorRoot variant="permanent" ownerState={{ openConfigurator }}>
        <SoftBox
          display="flex"
          justifyContent="space-between"
          alignItems="baseline"
          pt={3}
          pb={0.8}
          px={3}
        >
          <SoftBox>
            <SoftTypography variant="h5">Filters</SoftTypography>
          </SoftBox>

          <Icon
            sx={({
              typography: { size, fontWeightBold },
              palette: { dark },
            }) => ({
              fontSize: `${size.md} !important`,
              fontWeight: `${fontWeightBold} !important`,
              stroke: dark.main,
              strokeWidth: "2px",
              cursor: "pointer",
              mt: 2,
            })}
            onClick={handleCloseConfigurator}
          >
            close
          </Icon>
        </SoftBox>

        <Divider />

        <SoftBox pt={1.25} pb={3} px={3}>
          <label>{t("Reklamation")} Nr</label>
          <div className="position-relative">
            <input
              id="reclamationInput"
              type="text"
              placeholder={t("Reklamation") + " Nr"}
              className="form-control mb-2"
              defaultValue={reclamationNr}
              onKeyUp={(e) => {
                clearTimeout(typingTimer);
                typingTimer = setTimeout(() => {
                  setTableData([]);
                  setReclamationNr(e.target.value);
                }, 1500);
              }}
              onKeyDown={(e) => {
                clearTimeout(typingTimer);
              }}
            />
            {reclamationNumbers?.length >= 0 && (
              <div
                className="card position-absolute p-0 m-0 me-10 top-100 w-100 rounded-0 mt-1"
                style={{ zIndex: "999" }}
              >
                {menuReclamationNumber && (
                  <div className="card-body p-0 m-0">
                    <ul className="p-1 m-0">
                      {reclamationNumbers.map((items, index) => (
                        <MenuItem
                          style={{ text: "black", hover: { color: "none" } }}
                          key={index}
                        >
                          {items?.id ? (
                            <NavLink
                              style={{ color: "#344767" }}
                              to={""}
                              onClick={() => {
                                setReclamationNumbers([]);
                                document.getElementById(
                                  "reclamationInput"
                                ).value = items.id;
                                setReclamationNr(items.id);
                              }}
                            >
                              {items?.id} <br />
                            </NavLink>
                          ) : (
                            ""
                          )}
                        </MenuItem>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            )}
          </div>

          <label>Project Nr</label>
          <div className="position-relative">
            <input
              id="projectidInput"
              type="text"
              placeholder={t("Projekt Nr") + "..."}
              className="form-control mb-2"
              defaultValue={project_nr}
              onKeyUp={(e) => {
                clearTimeout(typingTimer);
                typingTimer = setTimeout(() => {
                  setTableData([]);
                  setProjectNr(e.target.value);
                }, 1500);
              }}
              onKeyDown={(e) => {
                clearTimeout(typingTimer);
              }}
            />
            {projectNumbers?.length >= 0 && (
              <div
                className="card position-absolute p-0 m-0 me-10 top-100 w-100 rounded-0 mt-1"
                style={{ zIndex: "999" }}
              >
                {menuNumber && (
                  <div className="card-body p-0 m-0">
                    <ul className="p-1 m-0">
                      {projectNumbers.map((items, index) => (
                        <MenuItem
                          style={{ text: "black", hover: { color: "none" } }}
                          key={index}
                        >
                          {items?.project_nr ? (
                            <NavLink
                              style={{ color: "#344767" }}
                              to={""}
                              onClick={() => {
                                setProjectNumbers([]);
                                document.getElementById(
                                  "projectidInput"
                                ).value = items.project_nr;
                                setProjectNr(items.project_nr);
                              }}
                            >
                              {items?.project_nr} <br />
                            </NavLink>
                          ) : (
                            ""
                          )}
                        </MenuItem>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            )}
          </div>

          <label>{t("Kundenname")}</label>
          <div className="position-relative">
            <input
              id="kundenInput"
              type="text"
              placeholder={t("Kundenname") + "..."}
              className="form-control mb-2"
              defaultValue={vorname_name}
              /*
                            onChange={(e)=> {
                                setSearchKey(e.target.value);
                            }}
                            */
              onKeyUp={(e) => {
                clearTimeout(typingTimer);
                typingTimer = setTimeout(() => {
                  setTableData([]);
                  setVornameName(e.target.value);
                }, 1500);
              }}
              onKeyDown={(e) => {
                clearTimeout(typingTimer);
              }}
            />
            {kundenNames?.length >= 0 && (
              <div
                className="card position-absolute p-0 m-0 me-10 top-100 w-100 rounded-0 mt-1"
                style={{ zIndex: "999" }}
              >
                {menu && (
                  <div className="card-body p-0 m-0">
                    <ul className="p-1 m-0">
                      {kundenNames.map((items, index) => (
                        <MenuItem
                          style={{ text: "black", hover: { color: "none" } }}
                          key={index}
                        >
                          {items?.id ? (
                            <NavLink
                              style={{ color: "#344767" }}
                              to={""}
                              onClick={() => {
                                setKundenNames([]);
                                setVornameName(
                                  items.vorname + " " + items.name
                                );
                                setMenu(false);
                                document.getElementById("kundenInput").value =
                                  items.vorname + " " + items.name;
                              }}
                            >
                              {items.vorname + " " + items.name} <br />
                            </NavLink>
                          ) : (
                            ""
                          )}
                        </MenuItem>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            )}
          </div>

          <label>{t("Start Date")}</label>
          <Flatpickr
            ref={fp}
            options={{
              mode: "single",
              dateFormat: "Y-m-d",
            }}
            onChange={handleDateChange}
          />
          <label>{t("End Date")}</label>
          <Flatpickr
            ref={fpt}
            options={{
              mode: "single",
              dateFormat: "Y-m-d",
            }}
            onChange={handleDateChangeTerm}
          />

          <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
            <SoftTypography
              component="label"
              variant="caption"
              fontWeight="bold"
            >
              Status
            </SoftTypography>
          </SoftBox>
          <select
            onChange={(e) => setSingleStatus(e.target.value)}
            className="form-control"
          >
            <option value={""}>{t("Select")}</option>
            <option value={"Erledigt"}>{t("Erledigt")}</option>
            <option value={"Open"}>{t("Open")}</option>
          </select>

          <label className="mt-3">{t("Abteilung")}</label>
          <Select
            placeholder={t("Select")}
            options={departments}
            onChange={(e) => {
              var dataKeys = filterKeys;
              var status = [];
              for (let [option_key, option] of Object.entries(e)) {
                status.push(option?.value);
              }

              //dataKeys.status = status.toString();
              dataKeys.status = JSON.stringify(status);
              setFilterKeys(dataKeys);
              setRefresh(refresh + 1);
            }}
            className="react-select-filter mb-2"
            isMulti
          />

          <label>{t("AC Abnahme")}</label>
          <select
            className="form-control mb-2"
            onChange={(e) => {
              var dataKeys = filterKeys;
              dataKeys.ac_abnahme = e.target.value;
              setFilterKeys(dataKeys);
              setRefresh(refresh + 1);
            }}
          >
            <option value={""}>{t("Select")}</option>
            <option value="Nicht bewertet">{t("Nicht bewertet")}</option>
            <option value="Erfolgreich">{t("Erfolgreich")}</option>
            <option value="Nicht Erfolgreich">{t("Nicht Erfolgreich")}</option>
          </select>

          <label>{t("DC Abnahme")}</label>
          <select
            className="form-control mb-2"
            onChange={(e) => {
              var dataKeys = filterKeys;
              dataKeys.dc_abnahme = e.target.value;
              setFilterKeys(dataKeys);
              setRefresh(refresh + 1);
            }}
          >
            <option value={""}>{t("Select")}</option>
            <option value="Nicht bewertet">{t("Nicht bewertet")}</option>
            <option value="Erfolgreich">{t("Erfolgreich")}</option>
            <option value="Nicht Erfolgreich">{t("Nicht Erfolgreich")}</option>
          </select>

          <button
            type="button"
            className="btn btn-outline-dark mt-4"
            onClick={() => handleClearButton()}
          >
            Clear
          </button>
        </SoftBox>
      </ConfiguratorRoot>
    </>
  );
}

export default IndexTable;
