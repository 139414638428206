import { React, useEffect, useState, forwardRef  } from 'react';
import { NavLink } from "react-router-dom";
import { useCallFetchv2 } from 'helpers/callFetchv2';
import { useTranslation } from 'react-i18next';
import DataTable from 'react-data-table-component';
import SoftTypography from 'components/SoftTypography';
import { useForm } from "react-hook-form";
import IndexTablePopUp from "./IndexTablePopUp";
import GoodsOut from "../../GoodsOut/GoodsOut";
import Select from "react-select";
import SoftSelect from 'components/SoftSelect';


function IndexTable(props, ref){
    const { t } = useTranslation();
    const callFetch = useCallFetchv2();
    var typingTimer;
    const [loading, setLoading] = useState(false);
    const [tabledata, setTableData] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [refresh, setRefresh] = useState(0);
    const [refresh2, setRefresh2] = useState(0);
    const [selectedRows, setSelectedRows] = useState(false);
    const [toggledClearRows, setToggleClearRows] = useState(false);
    const [beschaffungData, setBeschaffungData] = useState([]);
    const [warenausgangData, setWarenausgangData] = useState([]);
    const [articles, setArticles] = useState([]);
    const [articleName, setArticleName] = useState(null);
    const [newProduct, setNewProduct] = useState(false);
    const [items, setItems] = useState([{}]);
    const {
        register,
        handleSubmit,
        setError,
        setValue,
        getValues,
        reset,
        formState: { errors },
    } = useForm();

    const purchaseSave = (data, datum) => {
        //console.log(props?.project_id);

        var formData = {}
        formData.project_id = props?.project_id;
        formData.product_id = data?.value;
        formData.product_type = data?.type;

        if (datum === 'bestelldatum') {
            formData.bestelldatum = getValues("bestelldatum[" + data?.value + "]");
        } else if (datum === 'versanddatum') {
            formData.versanddatum = getValues("versanddatum[" + data?.value + "]");
        } else if (datum === 'lieferdatum') {
            formData.lieferdatum = getValues("lieferdatum[" + data?.value + "]");
        } else if (datum === 'delivery_link') {
            formData.delivery_link = getValues("delivery_link[" + data?.value + "]");
        }
        // formData.lieferdatum = getValues("lieferdatum[" +data?.value + "]");
        formData.qty = getValues("qty[" + data?.value + "]");

        console.log(formData);

        callFetch('beschaffungsliste', "POST", formData, setError).then((res) => { 
            if (!res.ok) return; 
            setRefresh(refresh + 1);
        });
    }

    const handleArticleChange = (e, index=0) => {
        const articleId = e;
        if (articleId) {
          callFetch("items-by-prefix/" + articleId, "GET", []).then((res) => {
            if (res.prefix == "Art") {
              setArticleName(res?.data?.article_name);
              if (res?.data) {
                const updatedItems = [...items];
                if (!updatedItems[0]) {
                  updatedItems[0] = {}; // create an object if it doesn't exist
                }
                updatedItems[0].article_name = res?.data?.article_name;
                updatedItems[0].article_nr = res?.id;
                updatedItems[0].prefix = res?.prefix;
                setItems(updatedItems);
              }
            } else {
              setArticleName(res?.data?.name);
              if (res?.data) {
                const updatedItems = [...items];
                if (!updatedItems[0]) {
                updatedItems[0] = {}; // create an object if it doesn't exist
                }
                updatedItems[0].article_name = res?.data?.name;
                updatedItems[0].article_nr = res?.id;
                updatedItems[0].prefix = res?.prefix;
                setItems(updatedItems);
              }
            }
          });
        }
    };

    const addProjectMaterial = () => {
        // setSaving(true); 
        // console.log(items)
        
        // return;
        var formData = new FormData();
        formData.items = JSON.stringify(items);
        formData.project_id = props?.project_id ? props?.project_id : '';
        callFetch("store-project-material", "POST", formData, setError).then(
          (res) => {
            // console.log(res);
            // setSuccessSB(true);
            setRefresh(refresh + 1);
            setItems([]);
            setNewProduct(false);
          }
        );
    };

    const handleChange = ({ selectedRows }) => {
        // console.log(selectedRows);
        setSelectedRows(selectedRows);
    };

 

    const onSubmit = (formData) => {

    };

    const tableHeadings = [
        {
            name: t('Product Nr.'),
            width: '11%',
            selector: row => <> <p ref={ref} onClick={() => {setNewProduct(true); loadProducts()}}></p> <SoftTypography variant="caption" style={{ color: '#273042' }}>{row?.value}</SoftTypography> </>,
            sortable: false,
        },
        
        {
            name: t('Status'),
            width: '15%',
            cell: row => {
                if(row?.supplier){
                    
                }else{
                    const beschaffungDataExists = beschaffungData?.items?.some(dataItem => dataItem.product_id == row?.value && dataItem.bestelldatum != null);
                    const warenausgangDataExists = warenausgangData?.items?.some(dataItem => dataItem.product_id == row?.value && dataItem.warenausgang != null);
            
                    return (
                        <>
                            {beschaffungDataExists && <span className="badge badge-success me-2">PO</span>}
                            {warenausgangDataExists && <span className="badge badge-danger">WA</span>}
                            {!beschaffungDataExists && !warenausgangDataExists && (
                            <span className="badge badge-danger">Offen</span>
                            )} 
                        </>
                    );
                }
            },
            sortable: false,
        }, 

        {
            name: t('Description'),
            width: '29%',
            selector: row =>  <span title={row?.label}>{row?.label}</span>  ,
            sortable: false,
        },
        {
            name: t('Quantity'),
            width: '18%',
            selector: row => (
                <>
                    <input value={row?.quantity} {...register("qty[" + row?.value + "]", {
                        required: true,
                    })} readOnly className='form-control' style={{ width: '65px', float: 'left' }} />
                    <SoftTypography style={{ position: 'relative', top: '8px', left: '5px' }} variant="caption">Stk./m</SoftTypography>
                </>
            ),
            sortable: false,
        },
        {
            name: t('Supplier'),
            width: '12%',
            selector: row => 'Senec AG',
            sortable: false,
        },
        {
            name: t('Kategorie'), 
            selector: row => <span style={{ textTransform: 'uppercase' }}>{row?.type}</span>,
            sortable: false,
        }
    ];

    const loadProducts = () => {
        callFetch("goods/create", "GET", []).then((res) => {
              // setValue('receive_number', res?.goods_number)
              // setLager(res?.data?.lager)
              // setLieferanten(res?.data?.lieferanten);
              const products = [];
        
              // Assuming res.data.modules is an array
              var updatedProducts = res?.data?.modules.map((item) => ({
                id: item.module_identity,
                name: item.name,
              }));
        
              // Concatenate the existing products with the new items
              products.push(...updatedProducts);
        
              // Assuming res.data.modules is an array
              updatedProducts = res?.data?.speichers.map((item) => ({
                id: item.speicher_identity,
                name: item.name,
              }));
        
              // Concatenate the existing products with the new items
              products.push(...updatedProducts);
        
              // Assuming res.data.modules is an array
              updatedProducts = res?.data?.wechselrichtes.map((item) => ({
                id: item.identity,
                name: item.name,
              }));
        
              // Concatenate the existing products with the new items
              products.push(...updatedProducts);
        
              // Assuming res.data.modules is an array
              updatedProducts = res?.data?.articles.map((item) => ({
                id: item.article_nr,
                name: item.article_name,
              }));
        
              // Concatenate the existing products with the new items
              products.push(...updatedProducts);
        
              setArticles(products); 
        });
    }

    useEffect(() => {
        if (!(props?.project_id ? props?.project_id : 0)) return;
        reset({});

        setLoading(true);

        callFetch("product-purchase/details/" + (props?.project_id ? props?.project_id : 0) + "?page=" + pageNumber, "GET", []).then((res) => {
            setTableData(res.data);
            setBeschaffungData(res?.beschaffung);
            setWarenausgangData(res?.warenausgang);
            // if (res?.beschaffung && res?.beschaffung?.items) {
            //     for (let [key, value] of Object.entries(res?.beschaffung?.items)) {
            //         //console.log(value);
            //         //setValue(key, (value == null || value == 'null' ? "" : value));

            //         //setValue("qty[" + value?.product_id + "]", (value?.qty == null || value?.qty == 'null' ? "" : value?.qty));
            //         setValue("lieferdatum[" + value?.product_id + "]", (value?.lieferdatum == null || value?.lieferdatum == 'null' ? "" : value?.lieferdatum));
            //         setValue("versanddatum[" + value?.product_id + "]", (value?.versanddatum == null || value?.versanddatum == 'null' ? "" : value?.versanddatum));
            //         setValue("bestelldatum[" + value?.product_id + "]", (value?.bestelldatum == null || value?.bestelldatum == 'null' ? "" : value?.bestelldatum));
            //         setValue("delivery_link[" + value?.product_id + "]", (value?.delivery_link == null || value?.delivery_link == 'null' ? "" : value?.delivery_link));
            //     }
            // }

            loadProducts();

            setLoading(false);
            setRefresh2(refresh2 + 1);
        });

    }, [pageNumber, refresh, (props?.project_id ? props?.project_id : 0)]);

    const handlePageChange = page => {
        setPageNumber(page);
    }

    function toPages(pages) {
        const results = [];

        for (let i = 1; i <= pages; i++) {
            results.push(i);
        }

        return results;
    }

    // RDT exposes the following internal pagination properties
    const BootyPagination = ({
        onChangePage,
        currentPage
    }) => {
        const handleBackButtonClick = () => {
            onChangePage(currentPage - 1);
        };

        const handleNextButtonClick = () => {
            onChangePage(currentPage + 1);
        };

        const handlePageNumber = (e) => {
            onChangePage(Number(e.target.value));
        };

        const pages = tabledata.last_page;
        const pageItems = toPages(pages);
        const nextDisabled = currentPage === tabledata.last_page;
        const previosDisabled = currentPage === 1;

        return (
            <div className='table-pagination d-flex justify-content-between align-items-center pb-0'>
            <p className="entries">{tabledata.from} - {tabledata.to} {t('of')} {tabledata.total} {t('entries')}</p>
            <ul className="pagination ">
                <li className="page-item">
                    <button
                        className="page-link"
                        onClick={handleBackButtonClick}
                        disabled={previosDisabled}
                        aria-disabled={previosDisabled}
                        aria-label="previous page"
                    >
                        <i className='ph ph-caret-double-left text-primary text-xl'></i>
                    </button>
                </li>

                <li className="page-item">
                    <button
                        className="page-link"
                        onClick={handleBackButtonClick}
                        disabled={previosDisabled}
                        aria-disabled={previosDisabled}
                        aria-label="previous page"
                    >
                        <i className='ph ph-caret-left text-primary text-xl'></i>
                    </button>
                </li>

                {tabledata?.links.map((page) => {
                    //const className = (parseInt(page.label) === currentPage ? "page-item active" : "page-item");
                    const className = (page.active === true ? "page-item active" : "page-item");
                    return (
                        <li key={page.label} className={className + ' ' + (parseInt(page.label) || page.label == '...' ? '' : 'd-none')}>
                            <button
                                className="page-link"
                                onClick={page.label != '...' ? handlePageNumber : ''}
                                value={page.label}
                                disabled={page.label == '...' ? true : false}
                            >
                                {page.label}
                            </button>
                        </li>
                    );
                })}

                <li className="page-item">
                    <button
                        className="page-link"
                        onClick={handleNextButtonClick}
                        disabled={nextDisabled}
                        aria-disabled={nextDisabled}
                        aria-label="next page"
                    >
                        <i className='ph ph-caret-right text-primary text-xl'></i>
                    </button>
                </li>

                <li className="page-item">
                    <button
                        className="page-link"
                        onClick={handleNextButtonClick}
                        disabled={nextDisabled}
                        aria-disabled={nextDisabled}
                        aria-label="next page"
                    >
                        <i className='ph ph-caret-double-right text-primary text-xl'></i>
                    </button>
                </li>
            </ul>
        </div>
        );
    };

    return (
        <>
            <form className={`needs-validation ${Object.keys(errors).length ? "was-validated" : ""}`} onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
            <DataTable
                columns={tableHeadings}
                data={tabledata?.data} 
                noDataComponent={
                    <div className="d-flex align-items-center justify-content-center py-3" style={{ height: "100%" }}>
                        <div className="text-center">
                            <i className='ph ph-notepad data-table-no-record-icon'></i>
                            <p className="text-secondary text-md m-0">{t('There are no records to display')}</p>
                        </div>
                    </div>
                }
                className='data-table'
                // pagination
                highlightOnHover
                paginationComponentOptions={{ noRowsPerPage: true }}
                paginationServer
                paginationTotalRows={tabledata?.total}
                onChangePage={handlePageChange}
                paginationComponent={BootyPagination}
                selectableRows selectableRowsComponentProps={{
                    className: 'table-checkbox form-check-input'
                  }} 
                
                onSelectedRowsChange={handleChange}
                clearSelectedRows={toggledClearRows} 
                // subHeader
                // subHeaderComponent={
                // <>
                //     {selectedRows?.length >= 1 &&

                //         <div style={{ position: 'absolute', left: '15px', width: '100%', marginTop: '-15px' }}>
                //             <div className='row'>
                //                 <div className='col-sm-6'>
                //                     {/* <buttn className="btn btn-primary" style={{ paddingTop: '10px', paddingBottom: '10px' }}>Dokument PDF</buttn> */}
                //                 </div>
                //                 <div className='col-sm-6 text-end pe-5'>
                //                     <button type="button" style={{ cursor: 'pointer', marginRight: '15px'}} data-bs-toggle="modal" data-bs-target="#BeschaffungslisteDetails" className="btn btn-dark mb-0"> {t('Bestellung auslösen')} </button>
                //                     <button type="button" style={{ cursor: 'pointer'}} data-bs-toggle="modal" data-bs-target="#goodsOut" className="btn btn-dark mb-0"> {t('Warenausgang buchen')} </button>
                //                 </div>
                //             </div>
                //         </div>

                //     }
                // </>
                // }
                
            />

            {newProduct ? <>
                <div className='table-inner-popup'>
                <div className=''> 
                    <div className='d-flex align-items-center'>
                        <div className='me-3' style={{width: '32%'}}>
                            <SoftSelect
                                size={'medium'}
                                placeholder={t("Select")}
                                options={articles ? articles : []}
                                getOptionLabel={(option) => `${option.id} - ${option.name}`}
                                getOptionValue={(option) => option.id}
                                onChange={(selectedOption) => {

                                    // console.log('selectedOption', selectedOption)
                                    // console.log('row.value', row)

                                    // return;
                                // Use the selected option's id directly (no parseFloat needed)
                                handleArticleChange(selectedOption.id, 0);
                                const updatedItems = [...items];
                                updatedItems[0] = {
                                    ...updatedItems[0],
                                    article_nr: selectedOption.id,
                                };
                                setItems(updatedItems);
                                }}
                                required
                                menuPortalTarget={document.body}
                                menuPlacement="auto"
                            />
                        </div>
                        <div className='me-3' style={{width: '27%'}}>
                            <input
                                className="form-control"
                                defaultValue={items[0] ? items[0].article_name : ""}
                                style={{width: '100%'}}
                                onChange={(e) => {
                                    setItems({
                                      ...items,
                                      [0]: {
                                        ...(items[0] || {}),
                                        article_name: e.target.value,
                                      },
                                    });
                                }}
                            />
                        </div>
                        <div className='me-3' style={{width: '10%'}}>
                        <input
                                        type="number"
                                        value={items[0] ? parseInt(items[0].qty) : ''}
                                        style={{width: '100%'}}
                                        className="form-control"
                                        placeholder="eg. 0.00"
                                        onChange={(e) => {
                                            setItems({
                                                ...items,
                                                [0]: {
                                                  ...(items[0] || {}),
                                                  qty: e.target.value,
                                                },
                                            });
                                        //   setRefresh(refresh + 1);
                                        }}
                                      />
                        </div>

                        <div>
                            <button type='button' className='btn btn-secondary me-2 mb-0' onClick={() => setNewProduct(false)}><i class="ph ph-x font-size-20 text-danger"></i></button>
                            <button type='button' className='btn btn-primary mb-0' onClick={() => addProjectMaterial()}>{t('Save')}</button>
                        </div>
                    </div>
                </div>
            </div>
            </> : <></>}

            {selectedRows?.length >= 1 &&

                    <div className='data-table-button-float'>
                        <div className='row'> 
                            <div className='col-sm-12 btn-group-float'>
                                <span className='text-secondary font-size-14 data-table-btn-item-count'>{selectedRows?.length} Von {tabledata?.data.length} ausgewahlt</span> <span></span>
                                <button type="button" style={{ cursor: 'pointer', marginRight: '15px'}} data-bs-toggle="modal" data-bs-target="#BeschaffungslisteDetails" className="btn btn-secondary mb-0"> {t('Bestellung auslösen')} </button>
                                <button type="button" style={{ cursor: 'pointer'}} data-bs-toggle="modal" data-bs-target="#goodsOut" className="btn btn-secondary mb-0"> {t('Warenausgang buchen')} </button>
                            </div>
                        </div>
                    </div>

                }
            </form>

            <div className="modal fade" id="BeschaffungslisteDetails" tabindex="-1" role="dialog" aria-labelledby="BeschaffungslisteDetailsLabel" aria-hidden="true">
                <div className="modal-dialog modal-xl modal-dialog-centered " role="document" >
                    <div className="modal-content">
                        <div class="modal-header">
                            <h6 class="modal-title" id="calanderModalLabel">Bestellung auslösen</h6>
                            <button type="button" class="btn-close text-dark" id="projectInfoModalClose" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div> 
                        <div className="modal-body tb-ac-cs">
                            {selectedRows?.length >= 1 &&
                                <IndexTablePopUp selectedRows={selectedRows} project_id={(props?.project_id ? props?.project_id : 0)} />
                            }
                        </div>
                        <div className='modal-footer' style={{padding: '4px'}}>
                            <div className="d-flex justify-content-end w-100">
                                <button type="button" className="btn btn-secondary mx-2 mb-0" id="modalClose" data-bs-dismiss="modal">{t('Abbrechen')}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div className="modal fade" id="goodsOut" tabindex="-1" role="dialog" aria-labelledby="goodsOutLabel" aria-hidden="true">
                <div className="modal-dialog modal-xl modal-dialog-centered"  role="document" >
                    <div className="modal-content " >
                        <div class="modal-header">
                            <h6 class="modal-title" id="calanderModalLabel">Warenausgang buchen</h6>
                            <button type="button" class="btn-close text-dark" id="projectInfoModalClose" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body tb-ac-cs">
                            {selectedRows?.length >= 1 &&
                                <GoodsOut col12={false} selectedRows={selectedRows} project_id={(props?.project_id ? props?.project_id : 0)} />
                            }
                        </div>
                        <div className='modal-footer'>
                        <div className="d-flex justify-content-end w-100">
                                <button type="button" className="btn btn-secondary mx-2 mb-0" id="modalClose" data-bs-dismiss="modal">{t('Abbrechen')}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};

export default forwardRef(IndexTable);
