import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Timeline context
import { TimelineProvider } from "examples/Timeline/context";

const TimelineList = ({ title, dark, children }) => {
  return (
    <TimelineProvider value={dark}>
      <SoftBox pb={3} bgColor={dark ? "dark" : "white"} variant="gradient">
        {title && <SoftBox textAlign="right" mr={1} >
          <SoftTypography variant="h6" fontWeight="medium" color={dark ? "white" : "dark"}>
            {title}
          </SoftTypography>
        </SoftBox>}
        <SoftBox >{children}</SoftBox>
      </SoftBox>
    </TimelineProvider>
  );
}

// Setting default values for the props of TimelineList
TimelineList.defaultProps = {
  dark: false,
};

// Typechecking props for the TimelineList
TimelineList.propTypes = {
  title: PropTypes.string.isRequired,
  dark: PropTypes.bool,
  children: PropTypes.node.isRequired,
}

export default TimelineList;