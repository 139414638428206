import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { callFetch } from "../../../helpers/callFetch";
import { useTranslation } from "react-i18next";
import Card from "@mui/material/Card";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import Transaction from "layouts/pages/account/billing/components/Transaction";
import { NumericFormat } from "react-number-format";
import dateFormat from "dateformat";

const Transactions = (props) => {
  const [total, setTotal] = useState([]);
  const [data, setData] = useState([]);
  const [refresh, setRefresh] = useState(0);
  const { t } = useTranslation();

  const numberReturn = (number, prefix) => {
    return (
      <NumericFormat
        value={Number(number)}
        displayType="text"
        thousandSeparator={"."}
        decimalSeparator=","
        decimalScale={2}
        fixedDecimalScale
        suffix={" € "}
        prefix={prefix}
      />
    );
  };

  useEffect(() => {
    if (!props?.projectId) return;

    callFetch("transactions/" + props?.projectId, "GET", []).then((res) => {
      setData(res.data);
    });
  }, [props?.projectId, refresh]);

  return (
    <>
      <>
        <p className="font-size-16 font-weight-500 text-primary">
          Rechnungsüberblick
        </p>
        <SoftBox pt={1}>
          <SoftBox
            component="ul"
            display="flex"
            flexDirection="column"
            p={0}
            m={0}
            sx={{ listStyle: "none" }}
          >
            {data?.invoice &&
              data?.invoice?.map((item) => (
                <>
                  <Transaction
                    color={item?.gutschrift == 0 ? "text-success" : "text-error"}
                    icon={
                      item?.gutschrift == 0 ? <i class="ph ph-arrow-up icon-success"></i> : <i class="ph ph-arrow-down icon-danger"></i>
                    }
                    name={
                      item?.gutschrift == 0
                        ? t("Invoice") +
                          " " +
                          JSON.parse(item?.product_details)?.amountPerchantage +
                          "%"
                        : t("Gutschrift")
                    }
                    description={dateFormat(item.date, "dd.mm.yyyy")}
                    value={
                      item?.gutschrift == 0
                        ? numberReturn(
                            JSON.parse(item?.product_details)?.amount,
                            "+"
                          )
                        : numberReturn(item?.brutto, "-")
                    }
                    link={"/invoices/" + item.id}
                    data={item}
                  />
                </>
              ))}

            {data?.incommingInvoice &&
              data?.incommingInvoice?.map((item) => (
                <>
                  <Transaction
                    color="error"
                    icon={<i class="ph ph-arrow-down icon-danger"></i>}
                    name={
                      (item?.abteilung == "Dachmontage"
                        ? "DC"
                        : item?.abteilung == "Elektromontage"
                        ? "AC"
                        : item?.abteilung == "Kleinmaterial"
                        ? "KM"
                        : item?.abteilung == "Module"
                        ? "MDL"
                        : item?.abteilung == "Speicher"
                        ? "SPC"
                        : item?.abteilung == "Wechselrichter"
                        ? "WR"
                        : "") +
                      ", " +
                      item?.lieferanten_firma
                    }
                    description={dateFormat(item.created_at, "dd.mm.yyyy")}
                    value={numberReturn(
                      JSON.parse(item?.product_details)?.total,
                      "-"
                    )}
                    link={"/eingangsrechnungen/" + item?.number}
                    linkText={"ER-" + item?.number}
                    data={item}
                  />
                </>
              ))}
          </SoftBox>
          {/* <SoftBox display="flex">
            <SoftBox
              py={1}
              pl={2}
              mb={1}
              sx={{ textAlign: "right", width: "65%" }}
            >
              Ergebnis:{" "}
            </SoftBox>
            <SoftBox
              py={1}
              pl={2}
              mb={1}
              sx={{ textAlign: "right", width: "35%" }}
            >
              <NumericFormat
                value={Number(data?.total ? data?.total : 0)}
                displayType="text"
                thousandSeparator={"."}
                decimalSeparator=","
                decimalScale={2}
                fixedDecimalScale
                suffix={" € "}
              />
            </SoftBox>
          </SoftBox> */}
          <div className="d-flex justify-content-between align-content-center mt-2">
            <p className="font-size-14 text-secondary"> Ergebnis:{" "}</p>
            <p className="font-size-14 text-primary">
            <NumericFormat
                value={Number(data?.total ? data?.total : 0)}
                displayType="text"
                thousandSeparator={"."}
                decimalSeparator=","
                decimalScale={2}
                fixedDecimalScale
                suffix={" € "}
              />
            </p>
          </div>
        </SoftBox>
      </>
      <button
        id="InvoiceoverviewRefresh"
        onClick={() => {
          setRefresh(refresh + 1);
        }}
        className="d-none"
      >
        InvoiceoverviewRefresh
      </button>
    </>
  );
};

export default Transactions;
