import { React, useEffect, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import callFetch from "../../../helpers/callFetch";
import flatpickr from "flatpickr";
import SoftSnackbar from "components/SoftSnackbar";
import Cookies from "js-cookie";
import dateFormat from "dateformat";

function ProjectPost(props) {
  const { t } = useTranslation();
  const [saving, setSaving] = useState(false);
  const [user, setUser] = useState(JSON.parse(Cookies.get('user')));
  const [refresh, setRefresh] = useState(0);
  const [refesh3, setRefesh3] = useState(0);
  const [successSB, setSuccessSB] = useState(false);
  const [file, setFile] = useState();
  const [posts, setPosts] = useState([]);
  const [data, setData] = useState([]);
  const [remove, setRemove] = useState(false);
  const [errorSB, setErrorSB] = useState(false);

  const closeErrorSB = () => setErrorSB(false);

  //file upload
  function handleChange(e) {
    if (!e?.target?.files?.[0]) {
      e.preventDefault();
      return;
    };


    setFile(URL.createObjectURL(e.target.files[0]));
    setRemove(true);
    if (document.getElementById('messageBox')) {
      document.getElementById("messageBox").focus();
    }
  }

  const renderSuccessSB = (
    <SoftSnackbar
      color="success"
      icon="check"
      title={t("Success")}
      //content={t("Changes saved successfully")}
      dateTime={t("")}
      open={successSB}
      onClose={() => setSuccessSB(false)}
      close={() => setSuccessSB(false)}
      bgSuccess
    />
  );

  const renderErrorSB = (
    <SoftSnackbar
      color="error"
      icon="info"
      title={t("Nachricht, Notiz oder Info dürfen nicht leer sein")}
      //content={t("Changes saved successfully")}
      dateTime={t("")}
      open={errorSB}
      onClose={() => closeErrorSB(false)}
      close={() => closeErrorSB(false)}
      bgSuccess
    />
  );

  const {
    register,
    handleSubmit,
    setError,
    setValue,
    getValues,
    formState: { errors },
  } = useForm();

  const [formattedPosts, setFormattedPosts] = useState([]);
  useEffect(() => {

    const newPosts = posts
      .slice()
      .reverse()
      .map((post, key) => {
        return {
          ...post,
          side: post.user_id == user.id ? "justify-content-end" : "justify-content-start",
        };
      });

    setData(data);
    setFormattedPosts(newPosts);
  }, [posts, data?.user?.id]);

  //Edit Data load
  useEffect(() => {
    if (!props?.id) return;

    setValue("project_id", props.id);
    callFetch("get-project-posts/" + props.id, "GET", []).then((res) => {
      setData(res.project);
      setPosts(res.project_posts);
    });
  }, [props.id, refresh]);

  //Form Submit
  const onSubmit = (formData) => {
    setSaving(true);
    if (
      formData.description_post == null ||
      formData.description_post.length < 2
    ) {
      setErrorSB(true);
      setSaving(false);
      return false;
    }

    callFetch("store-project-post", "POST", formData, setError).then((res) => {
      setValue("description_post", "");
      setValue("attachment", "");
      setPosts(res.project_posts);
      // setSuccessSB(true);
      setRefresh(refresh + 1);
      setFile("");
      setRemove(false);
      setSaving(false);
    });
  };

  return (
    <>
      {props?.create !== false ? (
        <>
          <div className="layout-wrapper d-lg-flex chat">
            <div
              className="user-chat verflow-hidden border-primary"
              style={{ boxShadow: "unset" }}
            >
              <div className="d-lg-flex">
                {/* Start chat conversation section */}
                <div className="w-100 overflow-hidden position-relative">
                  <div className="p-8-12 border-bottom user-chat-topbar">
                    <div className="row align-items-center">
                      <div className="col-sm-4 col-10">
                        <div className="d-flex align-items-center">
                          <div className="d-block d-lg-none me-2 ms-0">
                            <a
                              href="#"
                              className="user-chat-remove text-muted font-size-16 p-2"
                            >
                              <i className="ri-arrow-left-s-line"></i>
                            </a>
                          </div>
                          <div className="me-3 ms-0">
                            {/* <img
                              className="avatar avatar-sm"
                              src="/assets/img/placeholder.png"
                              alt=""
                            /> */}
                          </div>
                          <div className="flex-grow-1 overflow-hidden">
                            {/* <h5 className="font-size-14 mb-0 text-truncate font-weight-500">
                              <a
                                href="#0"
                                className="text-reset user-profile-show"
                              >
                                User Name
                              </a>
                            </h5>  */}
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-8 col-2">
                        <ul className="list-inline user-chat-nav text-end mb-0 d-flex align-items-center justify-content-end gap-1">
                          <li className="list-inline-item">
                            <a
                              class="btn-link d-flex align-items-center gap-1"
                              href="#messageBox"
                              onClick={(e) => {
                                e.preventDefault();
                                document.getElementById("messageBox").focus();
                              }}
                            >
                              <i className="ph ph-plus text-lg"></i>
                              Nachricht hinzufügen
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  {/* End chat user head */}
                  {/* Start chat conversation */}
                  <div
                    className="chat-conversation p-3"
                    style={{ overflow: "auto", height: "400px" }}
                  >
                    <ul className="list-unstyled mb-0">
                      <li className="right">
                        <div className="conversation-list">
                          <div className="user-chat-content">
                            {/* <div className="ctext-wrap">
                                            <div className="ctext-wrap-content">
                                                <p className="mb-0">Message content</p>
                                                <p className="chat-time mb-0">
                                                    <i className="ri-time-line align-middle"></i>
                                                    <span className="align-middle">3 minutes ago</span>
                                                </p>
                                            </div> 
                                        </div> */}
                            <div className="chat-container">
                              <div className="chat-box">
                                <div className="messages">
                                  {formattedPosts &&
                                    formattedPosts
                                      .slice()
                                      .reverse()
                                      .map((post, key) => (
                                        <div
                                          className={`row mb-3`}
                                          key={key}
                                        >
                                          <div
                                            className={`col-auto-custom d-flex ${post.side}`}
                                          >
                                            <div className="card" style={{ minWidth: '300px' }}>
                                              <div className="card-body">
                                                <p
                                                  className="mb-1"
                                                  style={{
                                                    fontSize: "0.875rem",
                                                    lineHeight: "1.3em",
                                                    color: "#344767",
                                                  }}
                                                >
                                                  {post.description}
                                                </p>
                                                {post.attachment &&
                                                  post.attachment !== null ? (
                                                  <img
                                                    src={`${Cookies.get(
                                                      "backend_url"
                                                    )}storage/project_post/${post.attachment
                                                      }`}
                                                    alt=""
                                                    style={{
                                                      height: "auto",
                                                      borderRadius: "15px",
                                                      objectFit: "cover",
                                                      width: "100%",
                                                      maxWidth: "244px",
                                                      marginTop: "5px",
                                                    }}
                                                  />
                                                ) : null}
                                                <div className="text-right text-sm opacity-6">
                                                  <i class="ph ph-check text-sm me-1"></i>
                                                  <small style={{ fontSize: '0.675rem' }}>
                                                    {dateFormat(
                                                      post?.created_at,
                                                      "dd.mm.yyyy, HH:MM"
                                                    )} | Notiz erstellt von{" "}
                                                    <strong>
                                                      {post?.user?.name}
                                                    </strong>
                                                  </small>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      ))}

                                  {/* {data?.description ? (
                                    <div className={`row mb-3`}>
                                      <div className="col-auto-custom" style={{width: '80%'}}>
                                        <div className="card">
                                          <div className="card-header">
                                            <p
                                              style={{
                                                fontSize: "0.875rem",
                                                color: "#344767",
                                              }}
                                            >
                                              Notiz erstellt von{" "}
                                              <strong>
                                                {data?.user?.name}
                                              </strong>
                                            </p>
                                          </div>
                                          <div className="card-body py-2 px-3">
                                            <p
                                              className="mb-1"
                                              style={{
                                                fontSize: "0.875rem",
                                                lineHeight: "1.3em",
                                                color: "#344767",
                                              }}
                                            >
                                              {data?.description}
                                            </p>
                                            <div className="d-flex align-items-center text-sm opacity-6">
                                              <i className="ni ni-check-bold text-sm me-1"></i>
                                              <small>
                                                {dateFormat(
                                                  data?.created_at,
                                                  "dd.mm.yyyy, HH:MM"
                                                )}
                                              </small>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ) : (
                                    ""
                                  )} */}


                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                    {posts?.length || data?.description ? (
                      <></>
                    ) : (
                      <p
                        className="text-center"
                        style={{ lineHeight: "300px" }}
                      >
                        {t("Keine Nachrichten vorhanden")}
                      </p>
                    )}
                  </div>
                  {/* End chat conversation */}
                  {/* Start chat input section */}
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="message-box">
                      <div
                        className="me-3"
                        style={{
                          position: "relative",
                          display: "flex",
                          cursor: "pointer",
                        }}
                      >
                        <input
                          {...register("attachment")}
                          onChange={handleChange}
                          type="file"
                          className=""
                          style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                            opacity: 0,
                            cursor: "pointer",
                          }}
                          onKeyDown={(e) => {
                            console.log(e.target.files)
                            if (e.key === "Enter") {
                              handleSubmit(onSubmit)(); // Trigger form submit when Enter key is pressed
                            }
                          }}
                          id="upload_attacment_messageBox"
                        />
                        <label htmlFor="upload_attacment_messageBox">
                          <img
                            src="../../assets/icons/Paperclip.svg"
                            alt="Attach File"
                            style={{
                              cursor: 'pointer',
                              position: 'relative'
                            }}
                          />
                        </label>

                        {remove ? (
                          <>
                            <img
                              src={file}
                              style={{
                                height: "38px",
                                width: "100%",
                                borderRadius: "5px",
                              }}
                              alt=""
                            />{" "}
                            <span
                              title="Remove Attachment"
                              style={{
                                fontSize: "12px",
                                right: "-8px",
                                top: "-8px",
                                position: "absolute",
                                zIndex: "999",
                              }}
                            >
                              <i
                                style={{
                                  background: "#fff",
                                  padding: "3px",
                                  borderRadius: "8px",
                                  color: "red",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  setRemove(false);
                                  setFile("");
                                  setValue("attachment", "");
                                }}
                                className="fa fa-remove"
                                aria-hidden="true"
                              ></i>
                            </span>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                      <input
                        {...register("description_post")}
                        id="messageBox"
                        type="text"
                        className="input-field"
                        placeholder="Geben Sie Ihre Nachricht ein"
                      />
                      <button className="send-btn">
                        <img src="../../assets/icons/PaperPlaneRight.svg" />
                      </button>
                    </div>
                  </form>
                  {/* End chat input section */}
                </div>
                {/* End chat conversation section */}
                {/* Start User profile detail sidebar */}
                <div className="user-profile-sidebar">
                  <div className="px-3 px-lg-4 pt-3 pt-lg-4">
                    <div className="user-chat-nav text-end">
                      <button type="button" className="btn nav-btn">
                        <i className="ri-close-line"></i>
                      </button>
                    </div>
                  </div>
                  <div className="text-center p-4 border-bottom">
                    <div className="mb-4">
                      <img
                        src="assets/images/users/avatar-4.jpg"
                        className="rounded-circle avatar-lg img-thumbnail"
                        alt=""
                      />
                    </div>
                    <h5 className="font-size-16 mb-1 text-truncate">
                      Doris Brown
                    </h5>
                    <p className="text-muted text-truncate mb-1">
                      <i className="ri-record-circle-fill font-size-10 text-success me-1 ms-0"></i>{" "}
                      Active
                    </p>
                  </div>
                  <div className="p-4 user-profile-desc">
                    <div className="text-muted">
                      <p className="mb-4">If several languages coalesce...</p>
                    </div>
                  </div>
                </div>
                {/* End User profile detail sidebar */}
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}

      <button
        className="d-none"
        id="callcenterRefesh3"
        onClick={() => {
          setRefesh3(refesh3 + 1);
        }}
      >
        refesh3
      </button>
      {renderErrorSB}
      {renderSuccessSB}
    </>
  );
}

export default ProjectPost;
