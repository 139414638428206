import { useEffect, useState } from 'react';
import { NavLink } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import IndexTable from './IndexTable';
import ReklamationIndexTable from './ReklamationIndexTable';

function Index() {
    const { t } = useTranslation();
    const [chooseplan, setChoosePlan] = useState('Projects');
    const [search, setSearch] = useState('');

    return (
        <>
            <div className="row">
                <div className="col-12">
                    <div className="card mb-4">
                        <div className="card-header pb-0">
                            <div className='row'>
                                <div className='col-md-6'>
                                    <p className='table-title-cs'>
                                        <span className='float-start'>{t('Purchase')}</span> 
                                    </p>
                                </div>
                                
                            </div>
                        </div>
                        <div className="card-body pb-2">
                        <ul
                            className="nav nav-tabs nav-underline tabs-res"
                            id="myTab"
                            role="tablist"
                            style={{ width: "97.5%" }}
                        > 
                            <li className="nav-item" role="presentation">
                                <button
                                className="nav-link active"
                                id="home-tab"
                                data-bs-toggle="tab"
                                data-bs-target="#home-tab-pane"
                                type="button"
                                role="tab"
                                aria-controls="home-tab-pane"
                                aria-selected="true"
                                style={{padding:'12px 0px'}} 
                                >
                                Projekte
                                </button>
                            </li> 

                            <li className="nav-item ms-3" role="presentation">
                                <button
                                className="nav-link"
                                id="reklamation-tab"
                                data-bs-toggle="tab"
                                data-bs-target="#reklamation-tab-pane"
                                type="button"
                                role="tab"
                                aria-controls="reklamation-tab-pane"
                                aria-selected="true"
                                style={{padding:'12px 0px', marginLeft: '1rem'}} 
                                >
                                Reklamationnen
                                </button>
                            </li> 
                        </ul>

                        <div className='col-md-12 mt-3'>
                                    <input
                                        className='form-control'
                                        placeholder={t('Search...')}
                                        style={{ maxWidth: '300px' }}
                                        onChange={(e)=> {
                                            setSearch(e.target.value);
                                        }}
                                    />
                                </div>

                        <div className="tab-content" id="myTabContent">
              
                  <div
                    className="tab-pane fade show active pt-3 tabs"
                    id="home-tab-pane"
                    role="tabpanel"
                    aria-labelledby="home-tab"
                    tabIndex="0"
                  >
                   <IndexTable chooseplan={chooseplan} search={search} />
                  </div>

                  <div
                    className="tab-pane fade pt-3 tabs"
                    id="reklamation-tab-pane"
                    role="tabpanel"
                    aria-labelledby="reklamation-tab"
                    tabIndex="0"
                  >
                    <ReklamationIndexTable
                  chooseplan={chooseplan}
                  search={search}
                />
                  </div>
              </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Index;
