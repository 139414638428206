import { React, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Navigate, useParams } from "react-router-dom";
import { callFetch } from "../../../../helpers/callFetch";
import { useTranslation } from 'react-i18next';
import { addWeeks } from 'date-fns';
import dateFormat from "dateformat";
import SoftAlert from "components/SoftAlert";
import SoftTypography from "components/SoftTypography";
import SoftSnackbar from "components/SoftSnackbar";
function DateForm(props) {
    let params = useParams();
    const { t } = useTranslation();
    const [saving, setSaving] = useState(false);
    const [date, setDate] = useState(false);
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [totalEmails, setTotalEmails] = useState(1);
    const [refresh, setRefresh] = useState(0);
    const [minDate, setMinDate] = useState(dateFormat(addWeeks(new Date(), 2), "yyyy-mm-dd"));

    const {
        register,
        handleSubmit,
        setError,
        setValue,
        unregister,
        formState: { errors },
    } = useForm();

    useEffect(() => {
        callFetch("projects/" + props.projectid + "/edit", "GET", []).then((res) => {
            setValue('date', res.data.desired_assembly);
        });
    }, []);

    const onSubmit = (formData) => {
        setSaving(true);
        callFetch("save-desired-assembly", "POST", formData, setError).then((res) => {
            setSaving(false);

            if (document.getElementById('order-refresh-btn')) {
                document.getElementById('order-refresh-btn').click();
            }

            if (!res.ok) return;
            setSubmitSuccess(true);
        });
    };



    return (
        <>
            <form className={`needs-validation ${Object.keys(errors).length ? "was-validated" : ""}`} onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
                <input type="hidden" {...register('id')} value={props.projectid} />
                <div className="mb-3">
                    <div className=" pb-0">
                        <h6>{t('Wunschtermin Montage')}</h6>
                    </div>
                    <div className="">
                        <div>
                            <label>Datum *</label>
                            {/*
                            <DatePicker
                                selected={date}
                                onChange={e => setDate(e)}
                                showWeekNumbers
                                showWeekPicker
                            />
                            */}
                            <input
                                type="date"
                                className="form-control mb-2 flatpickr"
                                placeholder="z.B. 16-04-2022"
                                {...register('date', {
                                    onChange: () => {
                                        document.getElementById('WunschterminSave').click();
                                    }
                                })}
                                min={minDate}
                                required=""
                            />
                            <div className="invalid-feedback"> </div>
                        </div>

                        {submitSuccess ? ( 
                            <SoftSnackbar
                                color="success" 
                                title= {t('Speichern erfolgreich')}
                                //content={t("Changes saved successfully")}
                                open={true}
                                onClose={false}
                                close={false} 
                            />
                        ) : ''}

                        <div className="row g-3 mt-2 d-none">
                            <div className="col-12">
                                {!saving && (
                                    <button type="submit" id="WunschterminSave" className="btn btn-primary">
                                        {t('Save')}
                                    </button>
                                )}
                                {saving && (
                                    <button type="submit" className="btn btn-disabled" disabled>
                                        {t('Saving ...')}
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </>
    );
}

export default DateForm;
