import React from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import IndexTable from './IndexTable'
import Cookies from 'js-cookie';

const Index = () => {
    const { t } = useTranslation()
    return (
        <>
            <div className='card'>
                <div className='card-header d-flex align-items-center justify-content-between gap-3'>
                    <h3 className='title'>{t('All Bundle')}</h3>
                    <div className='d-flex align-items-center justify-content-end gap-3'>

                        {JSON.parse(Cookies.get('permissions')).indexOf("Import") !== -1 ? (
                            <>
                                <button className="btn btn-secondary m-0 d-flex align-items-center justify-content-between gap-2" data-bs-toggle="modal" data-bs-target="#bundleimportModal"><i className='ph ph-arrow-square-in'></i>{t('Import XLSX')}</button>
                                {/*<button className="btn btn-outline-dark mx-3 d-none" id='bundleoutputModal-show-button' data-bs-toggle="modal" data-bs-target="#bundleoutputModal">{t('Output XLSX')}</button>*/}
                            </>
                        ) : <></>}

                        <NavLink to="/bundle-preis/create" className="btn btn-primary m-0 d-flex align-items-center justify-content-between gap-2">
                            <i className='ph ph-plus'></i>
                            {t('Bundle-Preis erstellen')}
                        </NavLink>
                    </div>
                </div>
                <div className="card-body pb-3">
                    <IndexTable />
                </div>
            </div>
        </>
    )
}

export default Index