import React from "react";
import { useEffect, useState, useRef } from "react";
import { useTranslation } from 'react-i18next';
import IndexTable from "./IndexTable";
import IndexTableTab from "./IndexTableTab";
import Cookies from 'js-cookie';
import Create from "./Create";
// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import PieChart from "./PieChart";
// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import callFetch from "../../helpers/callFetch";
import SoftBadgeDot from "components/SoftBadgeDot";
import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCard"; 
import Card from "@mui/material/Card";
import Configurator from './Configurator';
import {
    useSoftUIController,
    setTransparentNavbar,
    setMiniSidenav,
    setOpenConfigurator,
  } from "context";
function Index() {
    const { t } = useTranslation();
    const [refresh, setRefresh] = useState(0);
    const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);
    const [controller, dispatch] = useSoftUIController();
    const [data, setData] = useState(0);
    const [type, setType] = useState('overdue');
    const [ticketTitle, setTicketTitle] = useState('Alle Reklamationen');
    useEffect(() => { 
        callFetch("reclamations-counter", "GET", []).then((res) => {
            setData(res.data);   
        });
     }, []) 

    const { miniSidenav, transparentNavbar, fixedNavbar, openConfigurator } = controller;
    return (
        <>
        <div className="d-sm-flex justify-content-between">
                        <p className="font-size-24 font-weight-600 mb-0">Reclamation</p>
                        <div className="relative-h text-right"> 
                            {JSON.parse(Cookies.get('permissions')).indexOf("RecC") !== -1 ? (
                                // <div className="float-end">
                                    <a target={"_blank"} rel="noreferrer" href={Cookies.get('backend_url') + 'employee-export'} className="btn btn-secondary me-2">
                                       <i class="ph ph-arrow-square-out font-size-20 me-1 position-relative bottom-2"></i> <span className="position-relative bottom-5">{t('Export CSV')}</span>
                                    </a> 
                                // </div>
                            ) : <></>}
        
                            {JSON.parse(Cookies.get('permissions')).indexOf("RecC") !== -1 ? (
                                <a data-bs-toggle="modal" data-bs-target="#createReclamationModalOpen" href="#" className="btn btn-icon btn-primary">
                                    {t('Reklamation anlegen')}
                                </a>
                            ) : <></>}
                        </div>
                    </div>
    
            <div className="">
            <Grid container spacing={2}> 
                <Grid item xs={6} sm={3} mb={2}>
                    <Card  className={type == 'overdue' ? "card-tr active" : "card-tr"}> 
                        <div onClick={() => { setType('overdue'); setTicketTitle('Überfällig Reklamationen')}} style={{cursor: 'pointer', padding: '16px'}}> 
                                <Grid container alignItems="center">
                                    <Grid item xs={8}>
                                        <SoftBox ml={0} lineHeight={1}>
                                            <SoftTypography
                                             variant="caption"
                                             fontWeight="400"
                                             lineHeight="20px"
                                             // opacity={type == 'overdue' ? 1 : 0.6}
                                             textTransform="capitalize" 
                                             className={'ministatistics-title'}
                                            >
                                            {t('Überfällig')}
                                            </SoftTypography>
                                            <SoftTypography
                                             variant="h5"
                                             lineHeight="26px"
                                             fontSize="1.5rem" 
                                            >
                                            {data?.overdue}{" "}
                                        
                                            </SoftTypography>
                                        </SoftBox>
                                    </Grid>

                                    <Grid item xs={4}>
                                        <SoftBox className={type == 'overdue' ? "card-tr-icon bg-active" : "card-tr-icon bg-normal"}>
                                            <i class="ph ph-receipt-x font-size-24"></i>
                                        </SoftBox>
                                    </Grid> 
                                </Grid> 
                            </div> 
                    </Card>
                </Grid> 
                <Grid item xs={6} sm={3}>
                    <Card className={type == 'todays' ? "card-tr active" : "card-tr"}> 
                        <div onClick={() => {setType('todays'); setTicketTitle('Heutige Reklamationen')}} style={{cursor: 'pointer', padding: '16px'}}> 
                                <Grid container alignItems="center">
                                    <Grid item xs={8}>
                                        <SoftBox ml={0} lineHeight={1}>
                                            <SoftTypography
                                              variant="caption"
                                              color={'text'}
                                              // opacity={type == 'todays' ? 1 : 0.6}
                                              textTransform="capitalize"
                                              // fontWeight={'bold'}
                                              className={'ministatistics-title'}
                                            >
                                            {t('Heutige')}
                                            </SoftTypography>
                                            <SoftTypography
                                             variant="h5"
                                             lineHeight="26px"
                                             fontSize="1.5rem" 
                                            >
                                            {data?.due_today}{" "}
                                        
                                            </SoftTypography>
                                        </SoftBox>
                                    </Grid>
                                
                                    <SoftBox className={type == 'todays' ? "card-tr-icon bg-active" : "card-tr-icon bg-normal"}>
                                            <i class="ph ph-check-square-offset font-size-24"></i>
                                    </SoftBox>
                                </Grid> 
                            </div> 
                    </Card>
                </Grid> 
                 <Grid item xs={6} sm={3}>
                 <Card className={type == 'open' ? "card-tr active" : "card-tr"}> 
                        <div onClick={() => { setType('open'); setTicketTitle('Offene Reklamationen')}} style={{cursor: 'pointer', padding: '16px'}}> 
                                <Grid container alignItems="center">
                                    <Grid item xs={8}>
                                        <SoftBox ml={0} lineHeight={1}>
                                            <SoftTypography
                                              variant="caption"
                                              color={'text'} 
                                              textTransform="capitalize" 
                                              className={'ministatistics-title'}
                                            >
                                            {t('Offene')}
                                            </SoftTypography>
                                            <SoftTypography
                                             variant="h5"
                                             lineHeight="26px"
                                             fontSize="1.5rem"  
                                            >
                                            {data?.open}{" "}
                                        
                                            </SoftTypography>
                                        </SoftBox>
                                    </Grid>
                                
                                    <SoftBox className={type == 'open' ? "card-tr-icon bg-active" : "card-tr-icon bg-normal"}>
                                        <i class="ph ph-clock font-size-24"></i>
                                    </SoftBox>
                                </Grid> 
                            </div> 
                    </Card>
                </Grid> 
                <Grid item xs={6} sm={3} mb={2}> 
                <Card className={type == 'watched' ? "card-tr active" : "card-tr"}> 
                        <div onClick={() => { setType('watched'); setTicketTitle('Verfolgte Reklamationen')}} style={{cursor: 'pointer', padding: '16px'}}> 
                                <Grid container alignItems="center">
                                    <Grid item xs={8}>
                                        <SoftBox ml={0} lineHeight={1}>
                                            <SoftTypography
                                            variant="caption"
                                            color={'text'}
                                            // opacity={type == 'watched' ? 1 : 0.6}
                                            textTransform="capitalize"
                                            // fontWeight={'bold'}
                                            className={'ministatistics-title'}
                                            >
                                            {t('Verfolgte')}
                                            </SoftTypography>
                                            <SoftTypography
                                             variant="h5"
                                             lineHeight="26px"
                                             fontSize="1.5rem" 
                                            >
                                            {data?.watched}{" "}
                                        
                                            </SoftTypography>
                                        </SoftBox>
                                    </Grid>
                                
                                    <SoftBox className={type == 'watched' ? "card-tr-icon bg-active" : "card-tr-icon bg-normal"}>
                                            <i class="ph ph-magnifying-glass font-size-24"></i>
                                    </SoftBox>
                                </Grid> 
                            </div> 
                    </Card>
                </Grid>   
                    
                
            </Grid>
            </div>
            <div className="row">
                <div className="col-sm-7">
                    <div className="card mb-3">
                        <div className="card-header pb-0">
                            <h6 className="font-size-20 font-weight-600">{t(ticketTitle)}</h6>
                        </div>
                        <div className="card-body pt-0 pb-2"  style={{height: '284px'}}>
                            <IndexTableTab refresh={refresh} type={type}/>
                        </div>
                    </div>
                </div> 
                <div className="col-sm-5 ministatistics-title mb-3"> 
                                <PieChart
                                    title="Chart"
                                    height="18.125rem"
                                    chart={{
                                        labels: ["Overdue", "Open", "Due Today"],
                                        datasets: {
                                        label: "Projects",
                                        backgroundColors: ["error", "warning", "info"],
                                        data: [data?.overdue, data?.open, data?.due_today],
                                        },
                                    }}
                                />  
                </div>
            </div>
            <div className="row ">
                <div className="col-12">
                    <div className="card mb-4">
                        {/* <div className="card-header pb-0">
                            <h6 className="ministatistics-title">{t('Alle Reklamationen')}</h6>
                        </div> */}
                        <div className="card-body pt-0 pb-2">
                            <IndexTable refresh={refresh} type={type}/>
                        </div>
                    </div>
                </div>  
            </div>

            <Create setrefresh={setRefresh} />
        </>
    );
}

export default Index;
