import { React, useEffect, useState } from 'react';
import { NavLink } from "react-router-dom";
import callFetch from "../../../helpers/callFetch";
import { useTranslation } from 'react-i18next';
import DataTable from 'react-data-table-component';
import deleteAlert from "../../../helpers/deleteAlert";
import Cookies from 'js-cookie';
import ImportModal from '../../importer/ImportModal';
import dateFormat from "dateformat";
import { width } from '@mui/system';

function EmployeeIndexTable(props) {
    const { t } = useTranslation();
    const [employees, setEmployees] = useState([]);
    const [roles, setRoles] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [refresh, setRefresh] = useState(0);
    const [searchKey, setSearchKey] = useState("")
    const [searchData, setSearchData] = useState([])
    const [filterData, setFilterData] = useState([])
    var typingTimer;

    const tableHeadings = [
        {
            name: t('Employee ID'),
            width: '180px',
            selector: row => <NavLink to={'/employees/' + row?.id} className={"text-brand-primary"}>{row?.employee_identity_number}</NavLink>
        },
        {
            name: t('Name'),
            width: '200px',
            selector: row => <div className="d-flex justify-content-start align-items-center py-1" style={{ width: '180px' }}>
                <div className="me-1">
                    <img className="avatar avatar-sm" src={row?.user?.photo ? Cookies.get('backend_url') + 'storage/avatar/' + row?.user?.photo : '/assets/img/placeholder.png'} alt="" />
                </div>
                <div className="">
                    <p className="mb-0 font-size-14 text-wrap">{row?.user?.name}</p>
                    <p className=" mb-0 font-size-14">{row?.designation && row?.designation?.name}</p>
                </div>
            </div>
        },
        {
            name: t('Email'),
            selector: row => row?.user?.email
        },
        {
            name: t('Start Date'),
            selector: row => row?.start_date ? dateFormat(row?.start_date, "dd.mm.yyyy") : '',
        },
        {
            name: t('Status'),
            selector: row => (row?.status == 'deaktivieren' || (row?.end_date && ((new Date(dateFormat("mm.dd.yyyy")).getTime()) > (new Date(row?.end_date).getTime()))) ? <span className='badge badge-danger'>{t('Deaktiv')}</span> : <span className='badge badge-success'>{t('Aktiv')}</span>)
        },
        {
            name: t('User Role'),
            width: '220px',
            cell: row => <>
                {roles && roles.map((role) => (
                    <>{row?.user?.roles[0]?.id == role?.id ? t(role?.name ? role?.name : '') : ''}</>
                ))}
            </>
        },
        {
            name: t(''),
            width: '44px',
            cell: row => (
                <>
                    {JSON.parse(Cookies.get('permissions')).indexOf("EU") !== -1 ||
                        JSON.parse(Cookies.get('permissions')).indexOf("ED") !== -1 ? (
                        <div className="text-center dropstart">
                            <a href="/" className="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                <i className="fa fa-ellipsis-v text-xs"></i>
                            </a>
                            <ul className="dropdown-menu">
                                {JSON.parse(Cookies.get('permissions')).indexOf("EU") !== -1 ? (
                                    <li>
                                        <NavLink to={'/employees/' + row?.id} className="dropdown-item">
                                            {t('Edit')}
                                        </NavLink>
                                    </li>
                                ) : <></>}
                                <li><hr className="dropdown-divider" /></li>
                                {JSON.parse(Cookies.get('permissions')).indexOf("ED") !== -1 ? (
                                    <li><a className="dropdown-item text-danger" href="#0" onClick={(e) => deleteAlert(e, 'employees', row?.id, t).then(res => setRefresh(refresh + 1))}>{t('Delete')}</a></li>
                                ) : <></>}
                            </ul>
                        </div>
                    ) : <></>}
                </>
            )
        }
    ];

    useEffect(() => {
        callFetch("employees?page=" + pageNumber + '&search=' + searchKey + '&status=' + (props?.status ? props?.status : ''), "GET", []).then((res) => {
            setEmployees(res.data);
            setFilterData(res.data.data);
            setRoles(res.roles);
        });
    }, [pageNumber, refresh, searchKey]);

    const handlePageChange = page => {
        setPageNumber(page);
    }

    function toPages(pages) {
        const results = [];

        for (let i = 1; i <= pages; i++) {
            results.push(i);
        }

        return results;
    }

    // RDT exposes the following internal pagination properties
    const BootyPagination = ({
        onChangePage,
        currentPage
    }) => {
        const handleBackButtonClick = () => {
            onChangePage(currentPage - 1);
        };

        const handleNextButtonClick = () => {
            onChangePage(currentPage + 1);
        };

        const handlePageNumber = (e) => {
            onChangePage(Number(e.target.value));
        };

        const pages = employees.last_page;
        const pageItems = toPages(pages);
        const nextDisabled = currentPage === employees.last_page;
        const previosDisabled = currentPage === 1;

        return (
            <div className='table-pagination d-flex justify-content-between align-items-center pb-0'>
                <p className="entries">{employees.from} - {employees.to} {t('of')} {employees.total} {t('entries')}</p>
                <ul className="pagination ">
                    <li className="page-item">
                        <button
                            className="page-link"
                            onClick={handleBackButtonClick}
                            disabled={previosDisabled}
                            aria-disabled={previosDisabled}
                            aria-label="previous page"
                        >
                            <i className='ph ph-caret-double-left text-primary text-xl'></i>
                        </button>
                    </li>

                    <li className="page-item">
                        <button
                            className="page-link"
                            onClick={handleBackButtonClick}
                            disabled={previosDisabled}
                            aria-disabled={previosDisabled}
                            aria-label="previous page"
                        >
                            <i className='ph ph-caret-left text-primary text-xl'></i>
                        </button>
                    </li>

                    {employees?.links.map((page) => {
                        //const className = (parseInt(page.label) === currentPage ? "page-item active" : "page-item");
                        const className = (page.active === true ? "page-item active" : "page-item");
                        return (
                            <li key={page.label} className={className + ' ' + (parseInt(page.label) || page.label == '...' ? '' : 'd-none')}>
                                <button
                                    className="page-link"
                                    onClick={page.label != '...' ? handlePageNumber : ''}
                                    value={page.label}
                                    disabled={page.label == '...' ? true : false}
                                >
                                    {page.label}
                                </button>
                            </li>
                        );
                    })}

                    <li className="page-item">
                        <button
                            className="page-link"
                            onClick={handleNextButtonClick}
                            disabled={nextDisabled}
                            aria-disabled={nextDisabled}
                            aria-label="next page"
                        >
                            <i className='ph ph-caret-right text-primary text-xl'></i>
                        </button>
                    </li>

                    <li className="page-item">
                        <button
                            className="page-link"
                            onClick={handleNextButtonClick}
                            disabled={nextDisabled}
                            aria-disabled={nextDisabled}
                            aria-label="next page"
                        >
                            <i className='ph ph-caret-double-right text-primary text-xl'></i>
                        </button>
                    </li>
                </ul>
            </div>
        );
    };
    return <>
        <DataTable
            columns={tableHeadings}
            data={filterData}
            noDataComponent={
                <div className="d-flex align-items-center justify-content-center py-3" style={{ height: "100%" }}>
                    <div className="text-center">
                        <i className='ph ph-notepad data-table-no-record-icon'></i>
                        <p className="text-secondary text-md m-0">{t('There are no records to display')}</p>
                    </div>
                </div>
            }
            className='data-table'
            pagination
            highlightOnHover
            paginationComponentOptions={{ noRowsPerPage: true }}
            paginationServer
            paginationTotalRows={employees.total}
            onChangePage={handlePageChange}
            paginationComponent={BootyPagination}
            // subHeader
            subHeaderComponent={<input
                type="text"
                placeholder={t("Search...")}
                className=' form-control w-sm-50 w-md-25 w-lg-15 ms-1 mt-1'
                defaultValue={searchKey}
                /*
                onChange={(e)=> {
                    setSearchKey(e.target.value);
                }}
                */
                onKeyUp={(e) => {
                    clearTimeout(typingTimer);
                    typingTimer = setTimeout(() => {
                        setEmployees([]);
                        setFilterData([]);
                        setRoles([]);
                        setSearchKey(e.target.value);
                    }, 1500);
                }}
                onKeyDown={(e) => {
                    clearTimeout(typingTimer);
                }}
            />}
        />
        <ImportModal type={'employee'} refreshParent={() => setRefresh(refresh + 1)} />
    </>
}

export default EmployeeIndexTable;
