import { React, useEffect, useState, useRef } from 'react';
import SoftTypography from "components/SoftTypography";
import callFetch from "../../helpers/callFetch";
import { useTranslation } from 'react-i18next';
import dateFormat, { masks } from "dateformat";
import { NavLink } from "react-router-dom";

const ExtraInfo = ({ data }) => {
  const [quation, setQuation] = useState(0);
  const [order, setOrder] = useState(0);
  const { t } = useTranslation();
  const [invoices, setInvoices] = useState(0);
  const [reclamations, setReclamations] = useState(0);
  const [project, setProject] = useState({});
  const [user, setUser] = useState({});
  const [assignOrders, setAssignOrders] = useState([]);
  useEffect(() => {
    callFetch("project-details" + "/" + data.id, "GET", []).then((res) => {
      setQuation(res.quation);
      setOrder(res.order);
      setUser(res.user);
      setReclamations(res.reclamations);
      setProject(res.module_speicher_wech);
      setAssignOrders(res.assign_orders);
      setInvoices(res.invoices);
    });
  }, []);

  return (
    <>
      <div className='d-flex gap-3 m-3'>
        <div className='bg-secondary p-3  border-radius flex-fill' style={{ maxWidth: '450px' }}>
          <div className='d-flex align-items-center justify-content-between'>
            <p className='m-0 text-secondary text-link'>Lead Origin</p>
            <p className='m-0 text-primary text-md'>{user?.id ? user?.name : '--'}</p>
          </div>

          {project?.newQuation === 'yes' ? (
            <>
              {project?.productItems && project?.productItems != 'null' ? JSON.parse(project?.productItems)?.items?.map((productItem, j) => (
                <>
                  {productItem?.type == 'bundle' && productItem.item.id ? (
                    <>
                      {/*
                      <p
                        style={{
                          fontSize: '0.875rem',
                          fontWeight: 'bold',
                          color: '#2D3748'
                        }}
                        className="m-0 p-0 mt-0"
                      >{productItem.item?.label}</p>
                      */}
                      {productItem.item?.items ? productItem.item?.items.map((item, item_index) => (
                        <>
                          <div className='d-flex align-items-center justify-content-between'>
                            <p className='m-0 text-secondary text-link'>{item?.type}</p>
                            <p className='m-0 text-primary text-md'>{item[item?.type]?.name}</p>
                          </div>
                        </>
                      )) : ''}
                    </>
                  ) : productItem?.type == 'product' ? (
                    <>
                      <div className='d-flex align-items-center justify-content-between'>
                        <p className='m-0 text-secondary text-link'>{productItem?.item?.product_from}</p>
                        <p className='m-0 text-primary text-md'>{productItem?.item?.label}</p>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </>
              )) : ''}
            </>
          ) : (
            <>
              <div className='d-flex align-items-center justify-content-between'>
                <p className='m-0 text-secondary text-link'>Module</p>
                <p className='m-0 text-primary text-md'>{project?.module_eingeben ? project.module_eingeben == 'yes' ? project.custom_module_short_title != null ? project.custom_module_short_title + " | LT: " + dateFormat(project.quation_created_at, "dd.mm.yyyy") : '--' : project.module_short_title != null ? project.module_short_title + " | LT: " + dateFormat(project.modules_created_at, "dd.mm.yyyy") : '--' : '--'}</p>
              </div>

              <div className='d-flex align-items-center justify-content-between'>
                <p className='m-0 text-secondary text-link'>Speicher</p>
                <p className='m-0 text-primary text-md'>{project?.speicher_eingeben ? project.speicher_eingeben == 'yes' ? project.custom_speicher_name != null ? project.custom_speicher_name + " | LT: " + dateFormat(project.quation_created_at, "dd.mm.yyyy") : '--' : project.speicher_name != null ? project.speicher_name + " | LT: " + dateFormat(project.speicher_created_at, "dd.mm.yyyy") : '--' : '--'}</p>
              </div>
              <div className='d-flex align-items-center justify-content-between'>
                <p className='m-0 text-secondary text-link'>Wechselrichter</p>
                <p className='m-0 text-primary text-md'>{project?.wechselrichter_eingeben ? project.wechselrichter_eingeben == 'yes' ? project.custom_wechselrichter_titel != null ? project.custom_wechselrichter_titel + " | LT: " + dateFormat(project.quation_created_at, "dd.mm.yyyy") : '--' : project.wechselrichter_name != null ? project.wechselrichter_name + " | LT: " + dateFormat(project.wechselrichter_created_at, "dd.mm.yyyy") : '--' : '--'}</p>
              </div>
            </>
          )}
        </div>

        <div className='bg-secondary p-3  border-radius flex-fill' style={{ maxWidth: '450px' }}>
          <div className='d-flex align-items-center justify-content-between'>
            <p className='m-0 text-secondary text-link'>Angebot & AB</p>
            <p className='m-0 text-primary text-md'>{quation?.id ? quation.id : '--'} | {order?.id ? order.id : '--'}</p>
          </div>
          <div className='d-flex align-items-center justify-content-between'>
            <p className='m-0 text-secondary text-link'>Rechnung</p>
            <p className='m-0 text-secondary text-link'>{invoices?.length > 0 ? invoices.map((invoice) => invoice.id + ", ") : '--'}</p>
          </div>
          <div className='d-flex align-items-center justify-content-between'>
            <p className='m-0 text-secondary text-link'>Einkauf Nr</p>
            <p className='m-0 text-secondary text-link'>{project?.purchase ? 'PO-' + project?.purchase?.id : ''}</p>
          </div>
          <div className='d-flex align-items-center justify-content-between'>
            <p className='m-0 text-secondary text-link'>Reclamation</p>
            <p className='m-0 text-secondary text-link'>{reclamations?.length > 0 ? reclamations.map((reclamation) => <NavLink to={'/reclamation/' + reclamation?.id}>{"R-" + reclamation?.id + " "}</NavLink>) : '--'}</p>
          </div>
        </div>

        <div className='bg-secondary p-3  border-radius flex-fill' style={{ maxWidth: '450px' }}>
          {assignOrders?.length == 0 ?
            <>
              <div className='d-flex align-items-center justify-content-between'><p className='m-0 text-secondary text-link'>Dachmontage</p><p className='m-0 text-secondary text-link'>--</p></div>
              <div className='d-flex align-items-center justify-content-between'><p className='m-0 text-secondary text-link'>Abnahme</p><p className='m-0 text-secondary text-link'>--</p></div>
              <div className='d-flex align-items-center justify-content-between'><p className='m-0 text-secondary text-link'>Elektromontage</p><p className='m-0 text-secondary text-link'>--</p></div>
              <div className='d-flex align-items-center justify-content-between'><p className='m-0 text-secondary text-link'>Abnahme</p><p className='m-0 text-secondary text-link'>--</p></div>
            </>
            : ''}

          {assignOrders?.length == 1 ?
            assignOrders && assignOrders.map((assign_order) => (
              <>
                {assign_order.dachmontag_elektromontage == 'Dachmontage' ?
                  <>

                    <div className='d-flex align-items-center justify-content-between'><p className='m-0 text-secondary text-link'>Dachmontage</p><p className='m-0 text-primary text-md'>{assign_order.employee.name} {dateFormat(assign_order.created_at, "dd.mm.yyyy")}</p></div>
                    <div className='d-flex align-items-center justify-content-between'><p className='m-0 text-secondary text-link'>Abnahme</p><p className='m-0 text-primary text-md'>{assign_order?.dcabnameprotokoll?.comisioning_status == "1" ? t('Successfull') : t("Not Successfull")}</p></div>
                    <div className='d-flex align-items-center justify-content-between'><p className='m-0 text-secondary text-link'>Elektromontage</p><p className='m-0 text-secondary text-link'>--</p></div>
                    <div className='d-flex align-items-center justify-content-between'><p className='m-0 text-secondary text-link'>Abnahme</p><p className='m-0 text-secondary text-link'>--</p></div>
                  </>
                  :
                  <>
                    <div className='d-flex align-items-center justify-content-between'><p className='m-0 text-secondary text-link'>Dachmontage</p><p className='m-0 text-secondary text-link'>--</p></div>
                    <div className='d-flex align-items-center justify-content-between'><p className='m-0 text-secondary text-link'>Abnahme</p><p className='m-0 text-secondary text-link'>--</p></div>
                    <div className='d-flex align-items-center justify-content-between'><p className='m-0 text-secondary text-link'>Elektromontage</p><p className='m-0 text-secondary text-link'>{assign_order.employee.name} {dateFormat(assign_order.created_at, "dd.mm.yyyy")}</p></div>
                    <div className='d-flex align-items-center justify-content-between'><p className='m-0 text-secondary text-link'>Abnahme</p><p className='m-0 text-secondary text-link'>{assign_order?.acabnameprotokoll?.comisioning_status == "1" ? t('Successfull') : t("Not Successfull")}</p></div>
                  </>
                }

              </>
            )) : ' '
          }

          {assignOrders?.length == 2 ?
            assignOrders && assignOrders.map((assign_order) => (
              <>
                {assign_order.dachmontag_elektromontage == 'Dachmontage' ?
                  <>
                    <div className='d-flex align-items-center justify-content-between'><p className='m-0 text-secondary text-link'>Dachmontage</p><p className='m-0 text-primary text-md'>{assign_order.employee.name} {dateFormat(assign_order.created_at, "dd.mm.yyyy")}</p></div>
                    <div className='d-flex align-items-center justify-content-between'><p className='m-0 text-secondary text-link'>Abnahme</p><p className='m-0 text-secondary text-link'>{assign_order?.dcabnameprotokoll?.comisioning_status == "1" ? t('Successfull') : t("Not Successfull")}</p></div>
                  </>
                  :
                  <>
                    <div className='d-flex align-items-center justify-content-between'><p className='m-0 text-secondary text-link'>Elektromontage</p><p className='m-0 text-secondary text-link'>{assign_order.employee.name} {dateFormat(assign_order.created_at, "dd.mm.yyyy")}</p></div>
                    <div className='d-flex align-items-center justify-content-between'><p className='m-0 text-secondary text-link'>Abnahme</p><p className='m-0 text-secondary text-link'>{assign_order?.acabnameprotokoll?.comisioning_status == "1" ? t('Successfull') : t("Not Successfull")}</p></div>
                  </>
                }

              </>
            )) :
            ' '
          }
        </div>
      </div>

    </>
  );
};

export default ExtraInfo;