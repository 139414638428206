import { React, useEffect, useState } from 'react';
import { NavLink } from "react-router-dom";
import callFetch from "../../helpers/callFetch";
import { useTranslation } from 'react-i18next';
import DataTable from 'react-data-table-component';
import deleteAlert from "../../helpers/deleteAlert";
import Cookies from 'js-cookie';
import dateFormat from "dateformat";
import SoftBadge from "components/SoftBadge";
import SoftBadgeDot from "components/SoftBadgeDot";
import { NumericFormat } from 'react-number-format';
import SoftTypography from "components/SoftTypography";
import { Grid, Menu } from "@mui/material";
import Icon from "@mui/material/Icon";
import CloseIcon from "@mui/icons-material/Close";
import { useForm } from "react-hook-form";
import Checkbox from "@mui/material/Checkbox";

function IndexTableBundle(props) {
    const { t } = useTranslation();
    const [saving, setSaving] = useState(false);
    const [loading, setLoading] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [tabledata, setTableData] = useState([]);
    const [data, setData] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [deleteButton, setDeleteButton] = useState(false);
    const [refresh, setRefresh] = useState(0);
    const [refresh2, setRefresh2] = useState(0);

    const {
        register,
        handleSubmit,
        setError,
        setValue,
        getValues,
        reset,
        watch,
        formState: { errors },
    } = useForm();


    useEffect(() => {
        callFetch("bundle/create", "GET", []).then((res) => {
            setData(res.data);
        });
    }, []);

    useEffect(() => {
        setDeleteButton(false);
        if (getValues('selectedrow')) {
            for (let [selectedrow_key, selectedrow_value] of Object.entries(getValues('selectedrow'))) {
                if (selectedrow_value) {
                    setDeleteButton(true);
                }
            }
        }

    }, [refresh2]);

    const deleteSelectedItem = () => {
        setDeleting(true);
        var selectedValues = [];
        for (let [selectedrow_key, selectedrow_value] of Object.entries(getValues('selectedrow'))) {
            if (selectedrow_value) {
                selectedValues.push(selectedrow_key);
            }
        }

        let formData = {
            ids: JSON.stringify(selectedValues),
            type: props.type,
        };

        callFetch("import/delete", "POST", formData, setError).then((res) => {
            if (res.message == 'success') {
                for (let [selectedrow_key, selectedrow_value] of Object.entries(getValues('selectedrow'))) {
                    setValue("selectedrow[" + selectedrow_key + "]", false)
                }
                setDeleting(false);
                setRefresh(refresh + 1);
                setRefresh2(refresh2 + 1);
            }
        })
    }

    const onSubmitSave = () => {
        setSaving(true);
        reset({});

        setTimeout(() => {
            var formData = {
                filename: props.filename,
            }
            callFetch("import/" + props.type + "/save", "POST", formData, setError).then((res) => {

                if (res.message == 'success') {
                    setRefresh(refresh + 1);
                    setRefresh2(refresh + 1);
                    document.getElementById('outputModal-button-close').click();
                    setSaving(false);
                    window.location.reload();
                }
            });
        }, 1000)
    }


    const onSubmit = (formData) => {
        onSubmitSave();
    };

    const updateFileData = (formData) => {
        formData.type = props.type;
        setLoading(true);
        callFetch("import/update", "POST", formData, setError).then((res) => {
            if (res.message == 'success') {
                setLoading(false);
                setRefresh(refresh + 1);
            }
        });
    }

    const tableHeadings = [
        {
            //name: t('Project Nr.'),
            name: <>
                <Checkbox
                    defaultChecked={false}
                    onChange={(e) => {
                        if (e.target.checked) {
                            for (let [selectedrow_key, selectedrow_value] of Object.entries(getValues('selectedrow'))) {
                                setValue("selectedrow[" + selectedrow_key + "]", true)
                                console.log()
                            }
                        } else {
                            for (let [selectedrow_key, selectedrow_value] of Object.entries(getValues('selectedrow'))) {
                                setValue("selectedrow[" + selectedrow_key + "]", false)
                            }
                        }
                        setRefresh2(refresh2 + 1);
                    }}
                    disabled={loading}
                />
            </>,
            width: '44px',
            cell: row => (
                <>
                    <Checkbox
                        {...register(`selectedrow[${row.id}]`, {
                            onChange: (e) => {
                                setRefresh2(refresh2 + 1);
                            }
                        })}
                        checked={watch(`selectedrow[${row.id}]`) || false}
                    />
                </>),
            sortable: true,
        },
        {
            name: t('Bundle Group'),
            width: '300px',
            cell: row => (<>
                <input
                    className="form-control form-control-sm"
                    {...register("bundle_group_name[" + row.id + "]", {
                        required: true,
                        onChange: (e) => {
                            updateFileData({
                                id: row.id,
                                bundle_group_name: e.target.value
                            });
                        }
                    })}
                    defaultValue={row.bundle_group_name}
                    required
                    type="text" />
            </>),
        },
        {
            name: t('Bundle Name'),
            // width: '450px',
            selector: row => (<>
                {row?.bundles ? JSON.parse(row?.bundles).map((bundle, i) => (
                    <input
                        className="form-control form-control-sm"
                        {...register("bundle_name[" + row.id + "_" + i + "]", {
                            required: true,
                            onBlur: (e) => {
                                var updateBundles = JSON.parse(row?.bundles);
                                if (updateBundles[i].name != e.target.value) {
                                    updateBundles[i].name = e.target.value;
                                    //console.log(updateBundles)
                                    updateFileData({
                                        id: row.id,
                                        bundles: JSON.stringify(updateBundles)
                                    });
                                }
                            }
                        })}
                        defaultValue={bundle.name}
                        required
                        type="text" />
                )) : ''}
            </>),
            sortable: false,
        },
        {
            name: t('Speicher'),
            // width: '450px',
            selector: row => (<>
                {row?.bundles ? JSON.parse(row?.bundles).map((bundle, i) => (
                    <>
                        {bundle?.items ? bundle?.items.map((item, j) => (
                            <>
                                {item?.type == 'speicher' ? (
                                    <select
                                        type="text"
                                        className="form-control form-control-sm"
                                        placeholder={t('Select Speicher')}
                                        defaultValue={item.product}
                                        onChange={(e) => {
                                            var updateBundles = JSON.parse(row?.bundles);
                                            //console.log(updateBundles[i].items[j].product)
                                            if (updateBundles[i].items[j].product != e.target.value) {
                                                updateBundles[i].items[j].product = e.target.value;
                                                //console.log(updateBundles)
                                                updateFileData({
                                                    id: row.id,
                                                    bundles: JSON.stringify(updateBundles)
                                                });
                                            }
                                        }}
                                    >
                                        <option value="">--</option>
                                        {data?.speicher && data?.speicher?.map((speiche, i) => (
                                            <option key={i} value={speiche.id}>{speiche.name}</option>
                                        ))}
                                    </select>
                                ) : ''}
                            </>
                        )) : ''}

                    </>
                )) : ''}
            </>),
            sortable: false,
        },
        {
            name: t('Anzahl Speicher'),
            // width: '450px',
            selector: row => (<>
                {row?.bundles ? JSON.parse(row?.bundles).map((bundle, i) => (
                    <>
                        {bundle?.items ? bundle?.items.map((item, j) => (
                            <>
                                {item?.type == 'speicher' ? (
                                    <input
                                        className="form-control form-control-sm"
                                        {...register("number[" + row.id + "_" + i + "_" + j + "]", {
                                            required: false,
                                            onBlur: (e) => {
                                                var updateBundles = JSON.parse(row?.bundles);
                                                //console.log(updateBundles[i].items[j].product)
                                                if (updateBundles[i].items[j].number != e.target.value) {
                                                    updateBundles[i].items[j].number = e.target.value;
                                                    //console.log(updateBundles)
                                                    updateFileData({
                                                        id: row.id,
                                                        bundles: JSON.stringify(updateBundles)
                                                    });
                                                }
                                            }
                                        })}
                                        defaultValue={item.number}
                                        type="text" />
                                ) : ''}
                            </>
                        )) : ''}

                    </>
                )) : ''}
            </>),
            sortable: false,
        },
        {
            name: t('Module'),
            // width: '450px',
            selector: row => (<>
                {row?.bundles ? JSON.parse(row?.bundles).map((bundle, i) => (
                    <>
                        {bundle?.items ? bundle?.items.map((item, j) => (
                            <>
                                {item?.type == 'module' ? (
                                    <select
                                        type="text"
                                        className="form-control form-control-sm"
                                        placeholder={t('Select Module')}
                                        defaultValue={item.product}
                                        onChange={(e) => {
                                            var updateBundles = JSON.parse(row?.bundles);
                                            //console.log(updateBundles[i].items[j].product)
                                            if (updateBundles[i].items[j].product != e.target.value) {
                                                updateBundles[i].items[j].product = e.target.value;
                                                //console.log(updateBundles)
                                                updateFileData({
                                                    id: row.id,
                                                    bundles: JSON.stringify(updateBundles)
                                                });
                                            }
                                        }}
                                    >
                                        <option value="">--</option>
                                        {data?.module && data?.module?.map((modul, i) => (
                                            <option key={i} value={modul.id}>{modul.name}</option>
                                        ))}
                                    </select>
                                ) : ''}
                            </>
                        )) : ''}

                    </>
                )) : ''}
            </>),
            sortable: false,
        },
        {
            name: t('Anzahl Module'),
            // width: '450px',
            selector: row => (<>
                {row?.bundles ? JSON.parse(row?.bundles).map((bundle, i) => (
                    <>
                        {bundle?.items ? bundle?.items.map((item, j) => (
                            <>
                                {item?.type == 'module' ? (
                                    <input
                                        className="form-control form-control-sm"
                                        {...register("number[" + row.id + "_" + i + "_" + j + "]", {
                                            required: false,
                                            onChange: (e) => {
                                                var updateBundles = JSON.parse(row?.bundles);
                                                //console.log(updateBundles[i].items[j].product)
                                                if (updateBundles[i].items[j].number != e.target.value) {
                                                    updateBundles[i].items[j].number = e.target.value;
                                                    //console.log(updateBundles)
                                                    updateFileData({
                                                        id: row.id,
                                                        bundles: JSON.stringify(updateBundles)
                                                    });
                                                }
                                            }
                                        })}
                                        defaultValue={item.number}
                                        type="text" />
                                ) : ''}
                            </>
                        )) : ''}

                    </>
                )) : ''}
            </>),
            sortable: false,
        },
        {
            name: t('Wechselrichter'),
            // width: '450px',
            selector: row => (<>
                {row?.bundles ? JSON.parse(row?.bundles).map((bundle, i) => (
                    <>
                        {bundle?.items ? bundle?.items.map((item, j) => (
                            <>
                                {item?.type == 'wechselrichter' ? (
                                    <select
                                        type="text"
                                        className="form-control form-control-sm"
                                        placeholder={t('Select Wechselrichter')}
                                        defaultValue={item.product}
                                        onChange={(e) => {
                                            var updateBundles = JSON.parse(row?.bundles);
                                            //console.log(updateBundles[i].items[j].product)
                                            if (updateBundles[i].items[j].product != e.target.value) {
                                                updateBundles[i].items[j].product = e.target.value;
                                                //console.log(updateBundles)
                                                updateFileData({
                                                    id: row.id,
                                                    bundles: JSON.stringify(updateBundles)
                                                });
                                            }
                                        }}
                                    >
                                        <option value="">--</option>
                                        {data?.wechselrichter && data?.wechselrichter?.map((modul, i) => (
                                            <option key={i} value={modul.id}>{modul.name}</option>
                                        ))}
                                    </select>
                                ) : ''}
                            </>
                        )) : ''}

                    </>
                )) : ''}
            </>),
            sortable: false,
        },
        {
            name: t('Anzahl Wechselrichter'),
            // width: '450px',
            selector: row => (<>
                {row?.bundles ? JSON.parse(row?.bundles).map((bundle, i) => (
                    <>
                        {bundle?.items ? bundle?.items.map((item, j) => (
                            <>
                                {item?.type == 'wechselrichter' ? (
                                    <input
                                        className="form-control form-control-sm"
                                        {...register("number[" + row.id + "_" + i + "_" + j + "]", {
                                            required: false,
                                            onChange: (e) => {
                                                var updateBundles = JSON.parse(row?.bundles);
                                                //console.log(updateBundles[i].items[j].product)
                                                if (updateBundles[i].items[j].number != e.target.value) {
                                                    updateBundles[i].items[j].number = e.target.value;
                                                    //console.log(updateBundles)
                                                    updateFileData({
                                                        id: row.id,
                                                        bundles: JSON.stringify(updateBundles)
                                                    });
                                                }
                                            }
                                        })}
                                        defaultValue={item.number}
                                        type="text" />
                                ) : ''}
                            </>
                        )) : ''}

                    </>
                )) : ''}
            </>),
            sortable: false,
        },
        {
            name: t('Bundle Price'),
            // width: '450px',
            selector: row => (<>
                {row?.bundles ? JSON.parse(row?.bundles).map((bundle, i) => (
                    <input
                        className="form-control form-control-sm"
                        {...register("bundle_price[" + row.id + "_" + i + "]", {
                            required: false,
                            onBlur: (e) => {
                                var updateBundles = JSON.parse(row?.bundles);
                                if (updateBundles[i].price != e.target.value) {
                                    updateBundles[i].price = e.target.value;
                                    //console.log(updateBundles)
                                    updateFileData({
                                        id: row.id,
                                        bundles: JSON.stringify(updateBundles)
                                    });
                                }
                            }
                        })}
                        defaultValue={bundle.price}
                        type="text" />
                )) : ''}
            </>),
            sortable: false,
        },
    ];

    useEffect(() => {
        if (props?.filename && props?.filename != '') {
            callFetch("import-data/" + props?.filename + '/' + props?.type + "?page=" + pageNumber, "GET", []).then((res) => {
                setTableData(res.data);
                // console.log(res.data);
            });
        }

    }, [pageNumber, refresh, props.filename]);

    const handlePageChange = page => {
        setPageNumber(page);
    }

    function toPages(pages) {
        const results = [];

        for (let i = 1; i <= pages; i++) {
            results.push(i);
        }

        return results;
    }

    // RDT exposes the following internal pagination properties
    const BootyPagination = ({
        onChangePage,
        currentPage
    }) => {
        const handleBackButtonClick = () => {
            onChangePage(currentPage - 1);
        };

        const handleNextButtonClick = () => {
            onChangePage(currentPage + 1);
        };

        const handlePageNumber = (e) => {
            onChangePage(Number(e.target.value));
        };

        const pages = tabledata.last_page;
        const pageItems = toPages(pages);
        const nextDisabled = currentPage === tabledata.last_page;
        const previosDisabled = currentPage === 1;

        return (
            <>
                {tabledata.last_page > 1 && (
                    <div className='table-pagination d-flex justify-content-between align-items-center pb-0'>
                        <p className="entries">{tabledata.from} - {tabledata.to} {t('of')} {tabledata.total} {t('entries')}</p>
                        <ul className="pagination ">
                            <li className="page-item">
                                <button
                                    className="page-link"
                                    onClick={handleBackButtonClick}
                                    disabled={previosDisabled}
                                    aria-disabled={previosDisabled}
                                    aria-label="previous page"
                                >
                                    <i className='ph ph-caret-double-left text-primary text-xl'></i>
                                </button>
                            </li>

                            <li className="page-item">
                                <button
                                    className="page-link"
                                    onClick={handleBackButtonClick}
                                    disabled={previosDisabled}
                                    aria-disabled={previosDisabled}
                                    aria-label="previous page"
                                >
                                    <i className='ph ph-caret-left text-primary text-xl'></i>
                                </button>
                            </li>

                            {tabledata?.links.map((page) => {
                                //const className = (parseInt(page.label) === currentPage ? "page-item active" : "page-item");
                                const className = (page.active === true ? "page-item active" : "page-item");
                                return (
                                    <li key={page.label} className={className + ' ' + (parseInt(page.label) || page.label == '...' ? '' : 'd-none')}>
                                        <button
                                            className="page-link"
                                            onClick={page.label != '...' ? handlePageNumber : ''}
                                            value={page.label}
                                            disabled={page.label == '...' ? true : false}
                                        >
                                            {page.label}
                                        </button>
                                    </li>
                                );
                            })}

                            <li className="page-item">
                                <button
                                    className="page-link"
                                    onClick={handleNextButtonClick}
                                    disabled={nextDisabled}
                                    aria-disabled={nextDisabled}
                                    aria-label="next page"
                                >
                                    <i className='ph ph-caret-right text-primary text-xl'></i>
                                </button>
                            </li>

                            <li className="page-item">
                                <button
                                    className="page-link"
                                    onClick={handleNextButtonClick}
                                    disabled={nextDisabled}
                                    aria-disabled={nextDisabled}
                                    aria-label="next page"
                                >
                                    <i className='ph ph-caret-double-right text-primary text-xl'></i>
                                </button>
                            </li>
                        </ul>
                    </div>
                )}
            </>
        );
    };
    return <>
        <form className={`needs-validation ${Object.keys(errors).length ? "was-validated" : ""}`} onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
            <div className="modal-body">
                <div className='mb-3'>
                    {deleteButton ? (
                        <>
                            {deleting ? (
                                <button type='button' className='btn btn-danger mb-0' disabled>{t('Deleting ...')}</button>
                            ) : (
                                <button type='button' className='btn btn-danger mb-0' onClick={() => {
                                    deleteSelectedItem();
                                }}>{t('Delete')}</button>
                            )}
                        </>
                    ) : (
                        <button type='button' className='btn btn-danger mb-0' disabled>{t('Delete')}</button>
                    )}
                </div>

                <DataTable
                    columns={tableHeadings}
                    data={tabledata?.data}
                    noDataComponent={
                        <div className="d-flex align-items-center justify-content-center py-3" style={{ height: "100%" }}>
                            <div className="text-center">
                                <i className='ph ph-notepad data-table-no-record-icon'></i>
                                <p className="text-secondary text-md m-0">{t('There are no records to display')}</p>
                            </div>
                        </div>
                    }
                    className='data-table'
                    pagination
                    highlightOnHover
                    paginationComponentOptions={{ noRowsPerPage: true }}
                    paginationServer
                    paginationTotalRows={tabledata?.total}
                    onChangePage={handlePageChange}
                    paginationComponent={BootyPagination}
                />
            </div>

            <div className='modal-footer'>
                <button className='btn btn-secondary m-0 mx-2' onClick={() => {
                    document.getElementById('outputModal-button-close').click();
                }}>{t('Cancel')}</button>

                {loading == false ? (
                    <>
                        {!saving && (
                            <button type="submit" className="btn btn-primary m-0">
                                {t(props.type + ' upload')}
                            </button>
                        )}
                        {saving && (
                            <button type="submit" className="btn btn-disabled m-0" disabled>
                                {t(props.type + ' upload ...')}
                            </button>
                        )}
                    </>
                ) : (
                    <button type="submit" className="btn btn-disabled m-0" disabled>
                        {t('Loading ...')}
                    </button>
                )}
            </div>
        </form>
    </>
}

export default IndexTableBundle;
