import React from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';

import IndexTable from './IndexTable';

function EmployeeIndex() {
    const { t } = useTranslation();

    return (
        <>
            <div className='card'>
                <div className='card-header d-flex align-items-center justify-content-between gap-3'>
                    <h3 className='title'>{t('All Product')}</h3>
                    <div className='d-flex align-items-center justify-content-end gap-3'>
                    {JSON.parse(Cookies.get('permissions')).indexOf("SC") !== -1 ? (
                            <NavLink to="/zusatzprodukte/CreateProduct" className="btn btn-primary m-0 d-flex align-items-center justify-content-between gap-2">
                                <i className='ph ph-plus'></i>
                                {t('Zusatzprodukte')}
                            </NavLink>
                        ) : <></>}
                    </div>
                </div>
                <div className="card-body pb-3">
                    <IndexTable />
                </div>
            </div>
        </>
    );
}

export default EmployeeIndex;
