import { React, useEffect, useState } from 'react';
import { NavLink } from "react-router-dom";
import callFetch from "../../helpers/callFetch";
import { useTranslation } from 'react-i18next';
import DataTable from 'react-data-table-component';
import deleteAlert from "../../helpers/deleteAlert";
import Cookies from 'js-cookie';
import Tooltip from "@mui/material/Tooltip";
import SoftBox from "components/SoftBox";
import SoftAvatar from "components/SoftAvatar";
import Edit from "./Edit.js";

function IndexTableSalesPersion(props) {
    const { t } = useTranslation();
    const [tabledata, setTableData] = useState([]);
    const [teamID, setTeamID] = useState(0);
    const [roles, setRoles] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [refresh, setRefresh] = useState(0);
    const [searchKey, setSearchKey] = useState("");
    const [searchData, setSearchData] = useState([]);
    const [filterData, setFilterData] = useState([]);

    const avatarStyles = {
        border: ({ borders: { borderWidth }, palette: { white } }) =>
            `${borderWidth[2]} solid ${white.main}`,
        cursor: "pointer",
        position: "relative",
        ml: -1.5,

        "&:hover, &:focus": {
            zIndex: "10",
        },
    };

    const tableHeadings = [
        {
            name: t('Team ID'),
            width: '180px',
            sortable: true,
            selector: row => <a onClick={() => setTeamID(row.id)} data-bs-toggle="modal" data-bs-target="#editTeam" className={"text-brand-primary"}>{row.team_id}</a>
        },
        {
            name: t('Team Name'),
            width: '180px',
            sortable: true,
            selector: row => <NavLink to={'/teams/' + row.id + '/edit'}>{row.team_name}</NavLink>
        },
        {
            name: t('Head of Sales'),
            sortable: true,
            cell: row => (
                <SoftBox display="flex" alignItems="center">
                    <SoftBox mt={0.5} pl={1}>
                        <SoftBox className="team-member-avator" display="flex">
                            {row?.head ? row?.head?.map((item) => (
                                <Tooltip title={item?.user?.name} placement="top">
                                    <SoftAvatar src={item?.user?.photo ? Cookies.get('backend_url') + 'storage/avatar/' + item?.user?.photo : '/assets/img/placeholder.png'} alt="" size="sm" sx={avatarStyles} style={{ height: 'auto !important' }} />
                                </Tooltip>
                            )) : ''}
                        </SoftBox>
                    </SoftBox>
                </SoftBox>
            )
        },
        {
            name: t('Team Manager'),
            sortable: true,
            cell: row => (
                <SoftBox display="flex" alignItems="center">
                    <SoftBox mt={0.5} pl={1}>
                        <SoftBox className="team-member-avator" display="flex">
                            {row?.manager ? row?.manager?.map((item) => (
                                <Tooltip title={item?.user?.name} placement="top">
                                    <SoftAvatar src={item?.user?.photo ? Cookies.get('backend_url') + 'storage/avatar/' + item?.user?.photo : '/assets/img/placeholder.png'} alt="" size="sm" sx={avatarStyles} style={{ height: 'auto !important' }} />
                                </Tooltip>
                            )) : ''}
                        </SoftBox>
                    </SoftBox>
                </SoftBox>
            )
        },
        {
            name: t('Frontoffice Agent'),
            sortable: true,
            cell: row => (
                <SoftBox display="flex" alignItems="center">
                    <SoftBox mt={0.5} pl={1}>
                        <SoftBox className="team-member-avator" display="flex">
                            {row?.frontoffice_aggent ? row?.frontoffice_aggent?.map((item) => (
                                <Tooltip title={item?.user?.name} placement="top">
                                    <SoftAvatar src={item?.user?.photo ? Cookies.get('backend_url') + 'storage/avatar/' + item?.user?.photo : '/assets/img/placeholder.png'} alt="" size="xs" sx={avatarStyles} style={{ height: 'auto !important' }} />
                                </Tooltip>
                            )) : ''}
                        </SoftBox>
                    </SoftBox>
                </SoftBox>
            )
        },
        {
            name: t('Team Members'),
            sortable: true,
            width: '320px',
            cell: row => (
                <>
                    {row?.teammember && row?.teammember.length > 0 && (
                        <>
                            {row.teammember.slice(0, 2).map((item, index) => (
                                <div
                                    key={index}
                                    className="bg-secondary p-1 border-radius-4 d-flex justify-content-between align-items-center me-2 border-primary"
                                >
                                    <img
                                        className="team-members-img"
                                        src={
                                            item?.employe?.photo
                                                ? Cookies.get('backend_url') + 'storage/avatar/' + item?.employe?.photo
                                                : "/assets/img/placeholder.png"
                                        }
                                        alt="team member"
                                    />
                                    <p className="font-size-12 mb-0">{item?.employe?.name}</p>
                                </div>
                            ))}
                            {row.teammember.length > 2 && (
                                <div className="dropstart">
                                    <button id={"teamMembersDropdown" + row?.id} data-bs-toggle="dropdown" aria-expanded="false" className="border-radius-4 text-center team-members-more-btn">
                                        {`+${row.teammember.length - 2}`}
                                    </button>
                                    <ul className="dropdown-menu p-0" style={{ minWidth: '254px'}} aria-labelledby={"teamMembersDropdown" + row?.id}>
                                        <li className="dropdown-item px-3 hover-bg-none d-flex align-items-center justify-content-between text-primary text-lg font-weight-600 border-bottom">
                                            {t('Team Members')}
                                            <i className='ph ph-x cursor-pointer font-size-20'></i>
                                        </li>
                                        {row.teammember.slice(2).map((item, index) => (
                                            <li key={index} className="dropdown-item px-3 d-flex align-items-center">
                                                <img
                                                    className="team-members-img me-2"
                                                    src={
                                                        item?.employe?.photo
                                                            ? Cookies.get('backend_url') + 'storage/avatar/' + item?.employe?.photo
                                                            : "/assets/img/placeholder.png"
                                                    }
                                                    alt="team member"
                                                    width="30"
                                                    height="30"
                                                    style={{ borderRadius: "50%" }}
                                                />
                                                <span>{item?.employe?.name}</span>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            )}
                        </>
                    )}

                    {/* <SoftBox display="flex" alignItems="center">
                    <SoftBox mt={0.5} pl={1}>
                        <SoftBox className="team-member-avator" display="flex">
                            {row?.teammember ? row?.teammember?.map((item) => (
                                <Tooltip title={item?.employe?.name} placement="top">
                                    <SoftAvatar src={item?.employe?.photo ? Cookies.get('backend_url') + 'storage/avatar/' + item?.employe?.photo : '/assets/img/placeholder.png'} alt="" size="sm" sx={avatarStyles} style={{ height: 'auto !important' }} />
                                </Tooltip>
                            )) : ''}
                        </SoftBox>
                    </SoftBox>
                </SoftBox> */}
                </>
            )
        },
        {
            name: t('Actions'),
            sortable: true,
            width: '30px',
            cell: row => (
                <>
                    {JSON.parse(Cookies.get('permissions')).indexOf("EU") !== -1 ||
                        JSON.parse(Cookies.get('permissions')).indexOf("ED") !== -1 ? (
                        <div className="text-center dropstart">
                            <a href="/" className="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                <i className="fa fa-ellipsis-v text-xs"></i>
                            </a>
                            <ul className="dropdown-menu">
                                {JSON.parse(Cookies.get('permissions')).indexOf("EU") !== -1 ? (
                                    <li onClick={() => setTeamID(row.id)}>
                                        <a data-bs-toggle="modal" data-bs-target="#editTeam" className="dropdown-item">
                                            {t('Edit')}
                                        </a>
                                    </li>
                                ) : <></>}
                                <li><hr className="dropdown-divider" /></li>
                                {JSON.parse(Cookies.get('permissions')).indexOf("ED") !== -1 ? (
                                    <li><a className="dropdown-item text-danger" href="#0" onClick={(e) => deleteAlert(e, 'teams/delete', row.id, t).then(res => setRefresh(refresh + 1))}>{t('Delete')}</a></li>
                                ) : <></>}
                            </ul>
                        </div>
                    ) : <></>}
                </>
            )
        }
    ];

    useEffect(() => {
        callFetch("teams/" + props.type + "?page=" + pageNumber + '&search=' + searchKey, "GET", []).then((res) => {
            setTableData(res.data);
            setFilterData(res.data.data);
        });
    }, [pageNumber, props.type, refresh, searchKey]);

    const handlePageChange = page => {
        setPageNumber(page);
    }

    function toPages(pages) {
        const results = [];

        for (let i = 1; i <= pages; i++) {
            results.push(i);
        }

        return results;
    }

    // RDT exposes the following internal pagination properties
    const BootyPagination = ({
        onChangePage,
        currentPage
    }) => {
        const handleBackButtonClick = () => {
            onChangePage(currentPage - 1);
        };

        const handleNextButtonClick = () => {
            onChangePage(currentPage + 1);
        };

        const handlePageNumber = (e) => {
            onChangePage(Number(e.target.value));
        };

        const pages = tabledata.last_page;
        const pageItems = toPages(pages);
        const nextDisabled = currentPage === tabledata.last_page;
        const previosDisabled = currentPage === 1;

        return (
            <div className='table-pagination d-flex justify-content-between align-items-center pb-0'>
                <p className="entries">{tabledata.from} - {tabledata.to} {t('of')} {tabledata.total} {t('entries')}</p>
                <ul className="pagination ">
                    <li className="page-item">
                        <button
                            className="page-link"
                            onClick={handleBackButtonClick}
                            disabled={previosDisabled}
                            aria-disabled={previosDisabled}
                            aria-label="previous page"
                        >
                            <i className='ph ph-caret-double-left text-primary text-xl'></i>
                        </button>
                    </li>

                    <li className="page-item">
                        <button
                            className="page-link"
                            onClick={handleBackButtonClick}
                            disabled={previosDisabled}
                            aria-disabled={previosDisabled}
                            aria-label="previous page"
                        >
                            <i className='ph ph-caret-left text-primary text-xl'></i>
                        </button>
                    </li>

                    {tabledata?.links.map((page) => {
                        //const className = (parseInt(page.label) === currentPage ? "page-item active" : "page-item");
                        const className = (page.active === true ? "page-item active" : "page-item");
                        return (
                            <li key={page.label} className={className + ' ' + (parseInt(page.label) || page.label == '...' ? '' : 'd-none')}>
                                <button
                                    className="page-link"
                                    onClick={page.label != '...' ? handlePageNumber : ''}
                                    value={page.label}
                                    disabled={page.label == '...' ? true : false}
                                >
                                    {page.label}
                                </button>
                            </li>
                        );
                    })}

                    <li className="page-item">
                        <button
                            className="page-link"
                            onClick={handleNextButtonClick}
                            disabled={nextDisabled}
                            aria-disabled={nextDisabled}
                            aria-label="next page"
                        >
                            <i className='ph ph-caret-right text-primary text-xl'></i>
                        </button>
                    </li>

                    <li className="page-item">
                        <button
                            className="page-link"
                            onClick={handleNextButtonClick}
                            disabled={nextDisabled}
                            aria-disabled={nextDisabled}
                            aria-label="next page"
                        >
                            <i className='ph ph-caret-double-right text-primary text-xl'></i>
                        </button>
                    </li>
                </ul>
            </div>
        );
    };

    return <>
        <DataTable
            columns={tableHeadings}
            data={filterData}
            noDataComponent={
                <div className="d-flex align-items-center justify-content-center py-3" style={{ height: "100%" }}>
                    <div className="text-center">
                        <i className='ph ph-notepad data-table-no-record-icon'></i>
                        <p className="text-secondary text-md m-0">{t('There are no records to display')}</p>
                    </div>
                </div>
            }
            className='data-table'
            pagination
            highlightOnHover
            paginationComponentOptions={{ noRowsPerPage: true }}
            paginationServer
            paginationTotalRows={tabledata.total}
            onChangePage={handlePageChange}
            paginationComponent={BootyPagination}
            // subHeader
            subHeaderComponent={<input type="text" className=' form-control w-sm-50 w-md-25 w-lg-15' value={searchKey} onChange={(e) => setSearchKey(e.target.value)} />}
        />
        <Edit id={teamID}></Edit>
        <button className='d-none' id='refreshTeamList' onClick={() => { setRefresh(refresh + 1) }}></button>
    </>
}

export default IndexTableSalesPersion;
