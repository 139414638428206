import { React, useEffect, useState, useRef } from 'react';
import { NavLink } from "react-router-dom";
import callFetch from "../../helpers/callFetch";
import { useTranslation } from 'react-i18next';
import DataTable from 'react-data-table-component';
import deleteAlert from "../../helpers/deleteAlert";
import 'react-datepicker/dist/react-datepicker.css';
import Cookies from 'js-cookie';
import dateFormat from "dateformat";
import SoftBadge from "components/SoftBadge";
import SoftBadgeDot from "components/SoftBadgeDot";
import { NumericFormat } from 'react-number-format';
import SoftTypography from "components/SoftTypography";
import { Grid, Menu, MenuItem, setRef } from "@mui/material";
import Icon from "@mui/material/Icon";
import CloseIcon from "@mui/icons-material/Close";
import Timeline from './Timeline';
import ImportModal from '../importer/ImportModal';
import Select from 'react-select';
import Flatpickr from "react-flatpickr";
import Divider from "@mui/material/Divider";
import SoftBox from "components/SoftBox";
import ConfiguratorRoot from "examples/Configurator/ConfiguratorRoot";
import ExtraInfo from './ExtraInfo';
import {
    useSoftUIController,
    setOpenConfigurator,
    setTransparentSidenav,
    setMiniSidenav,
    setFixedNavbar,
    setSidenavColor,
} from "context";
import Swal from "sweetalert2";
function ProjectIndexTable(props) {
    const fp = useRef(null);
    const fpt = useRef(null);
    const statusdatum = useRef(null);
    const [searchData, setSearchData] = useState([])
    const [url_parameters, setUrlParameters] = useState('')

    const [projectNumbers, setProjectNumbers] = useState([])
    const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);
    const [kundenNames, setKundenNames] = useState([])
    const [orts, setOrts] = useState([])
    const [menuNumber, setMenuNumber] = useState(false);
    const [menu, setMenu] = useState(false);
    const [menuOrt, setMenuOrt] = useState(false);
    const [invoice, setInvoice] = useState('');
    const [callCenter, setCallCenter] = useState('');
    const [reklamation, setReklamation] = useState('');
    const [dachmontage, setDachmontage] = useState('');
    const [elektromontage, setElektromontage] = useState('');
    const [more, setMore] = useState(false);
    const [ac, setAC] = useState('');
    const [dc, setDC] = useState('');
    const [filderData, setFilderData] = useState([]);
    const [controller, dispatch] = useSoftUIController();
    const { openConfigurator, transparentSidenav, miniSidenav, fixedNavbar, sidenavColor } = controller;
    const [disabled, setDisabled] = useState(false);
    const [ready, setReady] = useState(false);
    const sidenavColors = ["primary", "dark", "info", "success", "warning", "error"];
    // Use the useEffect hook to change the button state for the sidenav type based on window size.
    useEffect(() => {

        // A function that sets the disabled state of the buttons for the sidenav type.
        function handleDisabled() {
            return window.innerWidth > 1200 ? setDisabled(false) : setDisabled(true);
        }

        // The event listener that's calling the handleDisabled function when resizing the window.
        window.addEventListener("resize", handleDisabled);

        // Call the handleDisabled function to set the state with the initial value.
        setOpenConfigurator(dispatch, false);
        handleDisabled();
        // Remove event listener on cleanup
        return () => window.removeEventListener("resize", handleDisabled);

    }, []);

    useEffect(() => {
        if (props?.filderData?.message != 'success') return;
        setFilderData(props?.filderData?.data)
    }, [props?.filderData]);

    const handleCloseConfigurator = () => setOpenConfigurator(dispatch, false);


    const [date, setDate] = useState(new Date());
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [startDateTerm, setStartDateTerm] = useState('');
    const [endDateTerm, setEndDateTerm] = useState('');
    const [statusdatumStartDate, setStatusdatumStartDate] = useState('');
    const [statusdatumEndDate, setStatusdatumEndDate] = useState('');

    const { t } = useTranslation();
    var typingTimer;
    const [loading, setLoading] = useState(false);
    const [tabledata, setTableData] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [refresh, setRefresh] = useState(0);
    const [searchKey, setSearchKey] = useState("");
    const [vorname_name, setVornameName] = useState("");
    const [min, setMin] = useState(0);
    const [max, setMax] = useState(0);
    const [vkb_name, setVKBName] = useState("");
    const [ort, setOrt] = useState("");
    const [project_nr, setProjectNr] = useState("");
    const [filterKeys, setFilterKeys] = useState({});
    const [openMenu, setOpenMenu] = useState(null);
    const [data, setData] = useState([]);
    const [proID, setProID] = useState(0);
    const [action, setAction] = useState('');
    const [fertigmeldungErfolgreich, setFertigmeldungErfolgreich] = useState('');
    const handleDateChange = (selectedDates) => {
        // Handle selected date range 
        if (Object.keys(selectedDates).length <= 0) {
            setStartDate('');
            setEndDate('');
        } else {
            const [startDate, endDate] = selectedDates;
            setStartDate(dateFormat(startDate, "yyyy-mm-dd"));
            setEndDate(dateFormat(endDate, "yyyy-mm-dd"));
        }
    };

    const handleChangeAction = (action) => {
        setAction(action);
    }

    const showAlert = () => {
        const newSwal = Swal.mixin({
            customClass: {
                confirmButton: "button button-success",
                cancelButton: "button button-error",
            },
            buttonsStyling: false,
        });

        newSwal
            .fire({
                title: t('Are you sure?'),
                text: t("You won't be able to revert this!"),
                // icon: 'warning',
                showCancelButton: true,
                confirmButtonText: t('Yes, do it!'),
                cancelButtonText: t('Cancel')
            })
            .then((result) => {
                if (result.isConfirmed) {
                    var ids = [];
                    selectedRows.map((row) => {
                        ids.push(row.id);
                    })

                    var formData = new FormData;
                    formData.ids = ids;

                    callFetch("delete-cancel-projects", "POST", formData).then((res) => {
                        setRefresh(refresh + 1);
                    });

                    Swal.fire("Deleted!", "Your file has been deleted.", "success");
                }
            });
    };

    const handleSelectedRowsApply = () => {
        showAlert();
    }

    const handleDateChangeTerm = (selectedDates) => {
        // Handle selected date range 
        if (Object.keys(selectedDates).length <= 0) {
            setStartDateTerm('');
            setEndDateTerm('');
        } else {
            const [startDateTermX, endDateTermX] = selectedDates;
            setStartDateTerm(dateFormat(startDateTermX, "yyyy-mm-dd"));
            setEndDateTerm(dateFormat(endDateTermX, "yyyy-mm-dd"));
        }
    };

    const handleStatusdatum = (selectedDates) => {
        // Handle selected date range 
        if (Object.keys(selectedDates).length <= 0) {
            setStatusdatumStartDate('');
            setStatusdatumEndDate('');
        } else {
            const [startDateTermX, endDateTermX] = selectedDates;
            setStatusdatumStartDate(dateFormat(startDateTermX, "yyyy-mm-dd"));
            setStatusdatumEndDate(dateFormat(endDateTermX, "yyyy-mm-dd"));
        }
    };


    const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
    const handleCloseMenu = () => setOpenMenu(null);
    const projectData = (id) => {
        setData([])
        if (id) {
            callFetch("project/timelines/" + id, "GET", []).then((res) => {
                setData(res.data);
            });
        }
    }

    const cancelProject = (cancel, id) => {

        callFetch("project/cencel", "POST", {
            cancel: cancel,
            id: id,
        }, []).then((res) => {


            setRefresh(refresh + 1)
        });


        setRefresh(refresh + 1)
    }

    const handleChangeMin = (value) => {
        setMin(value);
    };

    const handleChangeMax = (value) => {
        setMax(value);
    };

    const renderMenu = () => (
        openMenu ? <Menu
            anchorEl={openMenu}
            //anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            //transformOrigin={{ vertical: "top", horizontal: "right" }}
            open={Boolean(openMenu)}
            onClose={handleCloseMenu}
            keepMounted
        >
            <div className='' style={{ minWidth: '280px' }}>
                <div className="d-flex align-items-center justify-content-between text-primary text-lg font-weight-600 border-bottom px-3 py-2">
                    {t('Project Status')}
                    <i onClick={handleCloseMenu} className='ph ph-x cursor-pointer font-size-20'></i>
                </div>
                <Timeline datas={data} title="" />
            </div>
        </Menu> : null
    );


    const [selectedRows, setSelectedRows] = useState(false);
    const [toggledClearRows, setToggleClearRows] = useState(false);

    const handleChange = ({ selectedRows }) => {
        setSelectedRows(selectedRows);
    };

    // Toggle the state so React Data Table changes to clearSelectedRows are triggered
    const handleClearRows = () => {
        setToggleClearRows(!toggledClearRows);
    }

    const handleClearButton = () => {
        document.getElementById('ortInput').value = '';
        document.getElementById('min').value = '';
        document.getElementById('max').value = '';
        //document.getElementById('projectidInput').value = '';
        //document.getElementById('kundenInput').value = '';
        //document.getElementById('vkbInput').value = '';
        setOrt('')
        setInvoice('');
        setReklamation('');
        setDachmontage('');
        setElektromontage('');
        setFertigmeldungErfolgreich('')
        setAC('');
        setDC('');
        setMore(false);
        setFilterKeys({});
        fp?.current?.flatpickr?.clear();
        fpt?.current?.flatpickr?.clear();
        statusdatum?.current?.flatpickr.clear();
    }

    const handleAddMoreButton = () => {
        setMore(true);
    }




    const tableHeadings = [
        {
            name: t('Project'),
            width: '100px',
            cell: row => (
                <>
                    <NavLink to={'/projects/' + row.id} className="text-brand-primary">
                        {row.id}
                    </NavLink>
                    <a href={'/projects/' + row.id} className="text-brand-primary" target='_blank'>
                        <i class="fa-solid fa-arrow-up-right-from-square ms-2" style={{ fontSize: '12px' }}></i>
                    </a>
                </>
            ),
            sortable: true
        },
        {
            name: t('Project Status'),
            width: '180px',
            cell: row => <>
                {Cookies.get('user') && JSON.parse(Cookies.get('user')).rolename != 'Call Center' ? (
                    <>
                        <div
                            onClick={(e) => {
                                handleOpenMenu(e);
                                projectData(row.id);
                            }}
                            className="d-flex align-items-center cursor-pointer text-brand-primary gap-1"
                        >
                            <i className='ph ph-info' style={{ fontSize: '16px'}}></i>
                            {t(
                                row?.fertigmeldungErfolgreich ? t('Fertigmeldung')
                                    : row?.cancel ? t('Project Cancelled')
                                        : row?.assignorderprocessings?.length >= 2 ? 'Elektromontage'
                                            : row?.assignorderprocessings?.length == 1 ? 'Dachmontage'
                                                : row?.projectstatus
                            )}
                        </div>
                    </>
                ) : ''
                }
            </>,
        },
        {
            name: t('Customer'),
            width: '200px',
            selector: row => row?.vorname + ' ' + (row?.name == null ? '' : row?.name),
            sortable: true
        },
        {
            name: t('VKB Name'),
            width: '200px',
            selector: row => <NavLink to={'/projects/' + row.id}>{row?.salse_person?.name}</NavLink>,
            sortable: true
        },
        {
            name: t('Erstellt am'),
            width: '120px',
            selector: row => dateFormat(row?.created_at, "dd.mm.yyyy"),
            sortable: true
        },
        {
            name: t('Termindatum'),
            width: '130px',
            selector: row => dateFormat(row?.ereignisdatum, "dd.mm.yyyy"),
            sortable: true
        },
        {
            name: t('Status'),
            width: '250px',
            cell: row => (
                <>
                    <span className="badge badge-dot me-4">
                        {row.stornieren == 1 ? (
                            <>
                                <i className="bg-danger"></i>
                                <span className="text-primary">{'Storno'}</span>
                            </>
                        ) : (
                            <>
                                {row?.status && row?.sales_status?.id ? (
                                    <><i className={'status-' + row?.sales_status?.color ?? "bg-dark"}></i><span className={"text-primary"}>{t(row?.sales_status?.name ?? row?.status)}</span></>
                                ) : row?.termine_qoute?.sales_status?.name && row?.termine_qoute?.status && row?.termine_qoute?.status != 'null' ? (
                                    <><i className={'status-' + row?.termine_qoute?.sales_status?.color ?? "bg-dark"}></i><span className={"text-primary"}>{t(row?.termine_qoute?.sales_status?.name ?? row?.termine_qoute?.status)}</span></>
                                ) : <span className="text-primary">{'--'}</span>}
                            </>
                        )}
                    </span>
                </>
            ),
        },
        {
            name: t('Statusdatum'),
            cell: row => <>
                {row?.activity_created_at && row?.activity_created_at != null ? (
                    <>{dateFormat(row?.activity_created_at, "dd.mm.yyyy")}</>
                ) : '--'}
            </>,
            width: '130px',
            sortable: true
        },
        {
            name: t('Price'),
            cell: row => ((
                <NumericFormat
                    value={Number(row?.quations?.gesami_netto)}
                    displayType="text"
                    thousandSeparator={"."}
                    decimalSeparator=","
                    decimalScale={2}
                    fixedDecimalScale
                    suffix={' € '}
                />
            )),
            selector: row => row?.quations?.gesami_netto,
            sortable: true,
            width: '130px',
        },
        {
            name: t('Resale Status'),
            selector: row => row?.resalestatus?.title ? row?.resalestatus?.title : '',
        },
        {
            name: t('Ort'),
            selector: row => row?.ort,
            sortable: true
        },
        {
            name: t('Plz'),
            selector: row => row?.plz,
            sortable: true
        },
        {
            name: t('Tags'),
            cell: row => (
                <>

                </>
            ),
            sortable: true
        },
        {
            name: t(''),
            width: '44px',
            cell: row => (
                <>
                    {JSON.parse(Cookies.get('permissions')).indexOf("PrU") !== -1 ||
                        JSON.parse(Cookies.get('permissions')).indexOf("PrD") !== -1 ? (
                        <div className="text-center dropstart">
                            <a href="/" className="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                <i className="fa fa-ellipsis-v text-xs"></i>
                            </a>
                            <ul className="dropdown-menu" style={{ width: '204px' }}>
                                {JSON.parse(Cookies.get('permissions')).indexOf("PrU") !== -1 ? (
                                    <>
                                        <li><NavLink to={'/projects/' + row.id} className="dropdown-item d-flex align-items-center gap-2"><i className='font-size-20 ph ph-pencil-simple'></i>{t('Edit')}</NavLink></li>
                                    </>
                                ) : <></>}
                                {JSON.parse(Cookies.get('permissions')).indexOf("PrD") !== -1 ? (
                                    <li><a className="dropdown-item d-flex align-items-center gap-2 text-danger" href="#0" onClick={(e) => deleteAlert(e, 'projects', row.id, t).then(res => setRefresh(refresh + 1))}><i className='font-size-20 ph ph-trash-simple'></i>{t('Delete')}</a></li>
                                ) : <></>}
                            </ul>
                        </div>
                    ) : <></>}
                </>
            )
        }
    ];

    useEffect(() => {
        if (!props?.ready) return;
        handleClearButton();

        if (props?.menu) {

            //reset first
            var dataKeys = filterKeys;
            dataKeys.status = '';
            dataKeys.signierteangebote = '';
            dataKeys.onlyTrashed = '';
            setFilterKeys(dataKeys);
            setDachmontage('');
            setElektromontage('');
            setFertigmeldungErfolgreich('');

            if (props?.menu == 'all') {

            } else if (props?.menu == 'Verkauft') {
                var dataKeys = filterKeys;
                var status = [];
                status.push('1');
                dataKeys.status = JSON.stringify(status);
                setFilterKeys(dataKeys);
            }
            else if (props?.menu == 'Angebot') {
                var dataKeys = filterKeys;
                var status = [];
                status.push('2');
                dataKeys.status = JSON.stringify(status);
                setFilterKeys(dataKeys);
            }
            else if (props?.menu == 'signierteangebote') {
                var dataKeys = filterKeys;
                dataKeys.signierteangebote = 'yes';
                setFilterKeys(dataKeys);
            }
            else if (props?.menu == 'deleted_projects') {
                var dataKeys = filterKeys;
                dataKeys.onlyTrashed = 'yes';
                setFilterKeys(dataKeys);
            }
            else if (props?.menu == 'dc') {
                setDachmontage('yes');
                //setElektromontage('no');
            }
            else if (props?.menu == 'ac') {
                setElektromontage('yes');
                //setDachmontage('no');
            } else if (props?.menu == 'Fertigmeldung_erledigt') {
                setFertigmeldungErfolgreich(1);
            }
        }

        if (Cookies.get('cacehQuery') && Cookies.get('cacehQuery') == 'yes' && JSON.parse(Cookies.get('variables')).project) {
            var parameters = JSON.parse(Cookies.get('variables')).project;

            var parametersArray = parameters.split('&');
            for (let [key, param] of Object.entries(parametersArray)) {
                var paramArray = param.split('=');
                if (paramArray[0] == 'search') {
                    setSearchKey(paramArray[1]);
                } else if (paramArray[0] == 'page') {
                    setPageNumber(paramArray[1]);
                } else if (paramArray[0] == 'statusdatumStartDate') {
                    setStatusdatumStartDate(paramArray[1]);
                } else if (paramArray[0] == 'statusdatumEndDate') {
                    setStatusdatumEndDate(paramArray[1]);
                } else if (paramArray[0] == 'vorname_name') {
                    setVornameName(paramArray[1]);
                } else if (paramArray[0] == 'project_nr') {
                    setProjectNr(paramArray[1]);
                } else if (paramArray[0] == 'created_start') {
                    setStartDate(paramArray[1]);
                } else if (paramArray[0] == 'created_end') {
                    setEndDate(paramArray[1]);
                } else if (paramArray[0] == 'appointment_start') {
                    setStartDateTerm(paramArray[1]);
                } else if (paramArray[0] == 'appointment_end') {
                    setEndDateTerm(paramArray[1]);
                } else if (paramArray[0] == 'ort') {
                    setOrt(paramArray[1]);
                } else if (paramArray[0] == 'min') {
                    setMin(paramArray[1]);
                } else if (paramArray[0] == 'max') {
                    setMax(paramArray[1]);
                } else if (paramArray[0] == 'vkb_name') {
                    setVKBName(paramArray[1]);
                } else if (paramArray[0] == 'invoice') {
                    setInvoice(paramArray[1]);
                } else if (paramArray[0] == 'reklamation') {
                    setReklamation(paramArray[1]);
                } else if (paramArray[0] == 'dachmontage') {
                    setDachmontage(paramArray[1]);
                } else if (paramArray[0] == 'elektromontage') {
                    setElektromontage(paramArray[1]);
                } else if (paramArray[0] == 'ac') {
                    setAC(paramArray[1]);
                } else if (paramArray[0] == 'dc') {
                    setDC(paramArray[1]);
                } else if (paramArray[0] == 'call_center') {
                    setCallCenter(paramArray[1]);
                } else if (paramArray[0] == 'fertigmeldungErfolgreich') {
                    setFertigmeldungErfolgreich(paramArray[1]);
                } else if (paramArray[0] == 'status'
                    || paramArray[0] == 'projects'
                    || paramArray[0] == 'customers'
                    || paramArray[0] == 'employees'
                ) {
                    console.log(param);
                    setFertigmeldungErfolgreich(paramArray[1]);
                    var dataKeys = filterKeys;
                    dataKeys[paramArray[0]] = paramArray[1];
                    setFilterKeys(dataKeys);
                    setRefresh(refresh + 1);

                } else {
                    console.log(param)
                }
            }
        }

        setReady(true);
        setRefresh(refresh + 1);

    }, [props?.ready, props?.menu]);


    useEffect(() => {
        if (!ready) return;
        setTableData([]);
        setLoading(true);


        var parameters = "search=" + searchKey + "&page=" + pageNumber + "&statusdatumStartDate=" + statusdatumStartDate + "&statusdatumEndDate=" + statusdatumEndDate + "&vorname_name=" + vorname_name + "&project_nr=" + project_nr + "&created_start=" + startDate + "&created_end=" + endDate + "&appointment_start=" + startDateTerm + "&appointment_end=" + endDateTerm + "&ort=" + ort + '&min=' + min + '&max=' + max + '&vkb_name=' + vkb_name + '&invoice=' + invoice + '&reklamation=' + reklamation + '&dachmontage=' + dachmontage + '&elektromontage=' + elektromontage + '&ac=' + ac + '&dc=' + dc + '&call_center=' + callCenter + '&fertigmeldungErfolgreich=' + fertigmeldungErfolgreich;
        for (let [key, value] of Object.entries(filterKeys)) {
            parameters += "&" + key + "=" + value;
        }


        setUrlParameters(parameters);

        var oldVariables = (Cookies.get('variables') ? JSON.parse(Cookies.get('variables')) : {});
        oldVariables.project = parameters;
        Cookies.set('variables', JSON.stringify(oldVariables));

        callFetch("projects?" + parameters, "GET", []).then((res) => {
            setTableData(res.data.projects);

            if (res.orts != 'null') {
                if (Object.keys(res.orts).length > 1) {
                    setOrts(res.orts);
                    setMenuOrt(true);
                } else {
                    setOrts([]);
                    setMenuOrt(false);
                }
            }

            setLoading(false);
            Cookies.set('cacehQuery', '')
        });
    }, [ready, searchKey, fertigmeldungErfolgreich, statusdatumStartDate, statusdatumEndDate, vorname_name, filterKeys, pageNumber, refresh, project_nr, startDate, endDate, startDateTerm, endDateTerm, ort, min, max, vkb_name, invoice, reklamation, dachmontage, elektromontage, ac, dc, callCenter]);

    const handlePageChange = page => {
        setPageNumber(page);
    }

    function toPages(pages) {
        const results = [];

        for (let i = 1; i <= pages; i++) {
            results.push(i);
        }

        return results;
    }

    // RDT exposes the following internal pagination properties
    const BootyPagination = ({
        onChangePage,
        currentPage
    }) => {
        const handleBackButtonClick = () => {
            onChangePage(currentPage - 1);
        };

        const handleNextButtonClick = () => {
            onChangePage(currentPage + 1);
        };

        const handlePageNumber = (e) => {
            onChangePage(Number(e.target.value));
        };

        const pages = tabledata.last_page;
        const pageItems = toPages(pages);
        const nextDisabled = currentPage === tabledata.last_page;
        const previosDisabled = currentPage === 1;

        return (
            <div className='table-pagination d-flex justify-content-between align-items-center pb-0'>
                <p className="entries">{tabledata.from} - {tabledata.to} {t('of')} {tabledata.total} {t('entries')}</p>
                <ul className="pagination ">
                    <li className="page-item">
                        <button
                            className="page-link"
                            onClick={handleBackButtonClick}
                            disabled={previosDisabled}
                            aria-disabled={previosDisabled}
                            aria-label="previous page"
                        >
                            <i className='ph ph-caret-double-left text-primary text-xl'></i>
                        </button>
                    </li>

                    <li className="page-item">
                        <button
                            className="page-link"
                            onClick={handleBackButtonClick}
                            disabled={previosDisabled}
                            aria-disabled={previosDisabled}
                            aria-label="previous page"
                        >
                            <i className='ph ph-caret-left text-primary text-xl'></i>
                        </button>
                    </li>

                    {tabledata?.links.map((page) => {
                        //const className = (parseInt(page.label) === currentPage ? "page-item active" : "page-item");
                        const className = (page.active === true ? "page-item active" : "page-item");
                        return (
                            <li key={page.label} className={className + ' ' + (parseInt(page.label) || page.label == '...' ? '' : 'd-none')}>
                                <button
                                    className="page-link"
                                    onClick={page.label != '...' ? handlePageNumber : ''}
                                    value={page.label}
                                    disabled={page.label == '...' ? true : false}
                                >
                                    {page.label}
                                </button>
                            </li>
                        );
                    })}

                    <li className="page-item">
                        <button
                            className="page-link"
                            onClick={handleNextButtonClick}
                            disabled={nextDisabled}
                            aria-disabled={nextDisabled}
                            aria-label="next page"
                        >
                            <i className='ph ph-caret-right text-primary text-xl'></i>
                        </button>
                    </li>

                    <li className="page-item">
                        <button
                            className="page-link"
                            onClick={handleNextButtonClick}
                            disabled={nextDisabled}
                            aria-disabled={nextDisabled}
                            aria-label="next page"
                        >
                            <i className='ph ph-caret-double-right text-primary text-xl'></i>
                        </button>
                    </li>
                </ul>
            </div>
        );
    };

    return <>
        <DataTable
            columns={tableHeadings}
            data={tabledata?.data}
            noDataComponent={
                <div className="d-flex align-items-center justify-content-center py-3" style={{ height: "100%" }}>
                    <div className="text-center">
                        <i className='ph ph-notepad data-table-no-record-icon'></i>
                        <p className="text-secondary text-md m-0">{loading ? t('Loading...') : t('There are no records to display')}</p>
                    </div>
                </div>
            }
            className='data-table'
            pagination
            highlightOnHover
            selectableRows selectableRowsComponentProps={{
                className: 'table-checkbox form-check-input'
            }}
            expandableRowsComponent={({ data }) => <ExtraInfo data={data} />}
            onSelectedRowsChange={handleChange}
            clearSelectedRows={toggledClearRows}
            paginationComponentOptions={{ noRowsPerPage: true }}
            paginationServer
            paginationTotalRows={tabledata?.total}
            onChangePage={handlePageChange}
            expandableRows
            paginationComponent={BootyPagination}
            customStyles={{
                subHeader: {
                    style: {
                        padding: "0px",
                    },
                },
            }}
            subHeader
            subHeaderComponent={
                <>
                    <div style={{ width: '100%' }}>
                        <div className='d-flex flex-column flex-lg-row align-items-start align-items-lg-center justify-content-between'>
                            <div className='flex-fill d-flex flex-column flex-lg-row gap-3 align-items-start align-items-lg-center'>
                                {selectedRows?.length >= 1 &&
                                    <>
                                        <select className='form-control' style={{ maxWidth: '250px' }} onChange={(e) => handleChangeAction(e.target.value)}>
                                            <option value="delete">Löschen</option>
                                        </select>
                                        <button className="btn btn-secondary m-0" onClick={() => handleSelectedRowsApply()}>Anwenden</button>
                                    </>
                                }

                                <div
                                    className="d-flex align-items-center ms-1"
                                >
                                    <i
                                        className="ph ph-magnifying-glass"
                                        style={{
                                            marginRight: -25,
                                            zIndex: 1,
                                            color: '#4D586E',
                                            fontSize: '20px'
                                        }}
                                    ></i>
                                    <input
                                        autoComplete="off"
                                        className="form-control"
                                        placeholder={t("Search...")}
                                        style={{
                                            paddingLeft: '28px',
                                        }}
                                        defaultValue={searchKey}
                                        onKeyUp={(e) => {
                                            clearTimeout(typingTimer);
                                            typingTimer = setTimeout(() => {
                                                setTableData([]);
                                                setSearchKey(e.target.value);
                                            }, 1500);
                                        }}
                                        onKeyDown={(e) => {
                                            clearTimeout(typingTimer);
                                        }}
                                    />
                                </div>
                            </div>
                            <button onClick={handleConfiguratorOpen} className='btn btn-secondary d-flex gap-2 m-0'><i class="ph ph-funnel font-size-20"></i> Filter</button>
                        </div>
                    </div>


                </>
            }
        />


        <ConfiguratorRoot
            variant="permanent"
            ownerState={{ openConfigurator }}
            configWidth={'100%'}
            configMaxWidth={'520px'}
            padding={0}
            sx={{
                maxWidth: '520px',
                background: '#ffffff'
            }}
        >

            <div className="p-3 border-bottom d-flex align-items-center justify-content-between gap-2">
                <h5 className="text-title m-0">{t("Filters")}</h5>
                <i
                    className="ph ph-x cursor-pointer text-title"
                    onClick={handleCloseConfigurator}
                ></i>
            </div>

            <div
                style={{
                    height: "95%",
                    display: "flex",
                    flexDirection: "column",
                    borderRadius: "0px", // No need for !important in inline styles
                }}
            >


                <div className="p-3"
                    style={{
                        flex: 1,
                        overflowY: 'auto'
                    }}
                >
                    <div className='form-group'>
                        <label>Project Nr</label>
                        <Select
                            placeholder={t('Select')}
                            options={filderData?.projects ? filderData?.projects : []}
                            //defaultValue={filterKeys?.status ? JSON.parse(filterKeys?.status) : ''}
                            onChange={(e) => {
                                var dataKeys = filterKeys;
                                var projects = [];
                                for (let [option_key, option] of Object.entries(e)) {
                                    projects.push(option?.value)
                                }

                                dataKeys.projects = JSON.stringify(projects);
                                setFilterKeys(dataKeys);
                                setRefresh(refresh + 1);
                            }}
                            className='react-select-filter'
                            isMulti
                        />
                    </div>

                    <div className='form-group'>
                        <label>{t("Kundenname")}</label>
                        <Select
                            placeholder={t('Select')}
                            options={filderData?.customers ? filderData?.customers : []}
                            //defaultValue={filterKeys?.status ? JSON.parse(filterKeys?.status) : ''}
                            onChange={(e) => {
                                var dataKeys = filterKeys;
                                var customers = [];
                                for (let [option_key, option] of Object.entries(e)) {
                                    customers.push(option?.value)
                                }

                                dataKeys.customers = JSON.stringify(customers);
                                setFilterKeys(dataKeys);
                                setRefresh(refresh + 1);
                            }}
                            className='react-select-filter'
                            isMulti
                        />
                    </div>

                    <div className='form-group'>
                        <label>{t("VKB Name")}</label>
                        <Select
                            placeholder={t('Select')}
                            options={filderData?.employees ? filderData?.employees : []}
                            //defaultValue={filterKeys?.status ? JSON.parse(filterKeys?.status) : ''}
                            onChange={(e) => {
                                var dataKeys = filterKeys;
                                var employees = [];
                                for (let [option_key, option] of Object.entries(e)) {
                                    employees.push(option?.value)
                                }

                                dataKeys.employees = JSON.stringify(employees);
                                setFilterKeys(dataKeys);
                                setRefresh(refresh + 1);
                            }}
                            className='react-select-filter'
                            isMulti
                        />
                    </div>

                    <div className='form-group'>
                        <label>{t("Erstellt am")}</label>
                        <Flatpickr
                            ref={fp}
                            options={{
                                mode: 'range',
                                dateFormat: 'Y-m-d',
                            }}
                            onChange={handleDateChange}
                            placeholder='Select Dates'
                            className="form-control"
                        />
                    </div>

                    <div className='form-group'>
                        <label>{t("Termindatum")}</label>
                        <Flatpickr
                            ref={fpt}
                            options={{
                                mode: 'range',
                                dateFormat: 'Y-m-d',
                            }}
                            onChange={handleDateChangeTerm}
                            placeholder='Select Dates'
                            className="form-control"
                        />
                    </div>

                    <div className='form-group'>
                        <label>{t("Status")}</label>
                        <Select
                            placeholder={t('Select')}
                            options={filderData?.salesStatus ? filderData?.salesStatus : []}
                            defaultValue={filterKeys?.status ? JSON.parse(filterKeys?.status) : ''}
                            onChange={(e) => {
                                var dataKeys = filterKeys;
                                var status = [];
                                for (let [option_key, option] of Object.entries(e)) {
                                    status.push(option?.value)
                                }

                                //dataKeys.status = status.toString();
                                dataKeys.status = JSON.stringify(status);
                                setFilterKeys(dataKeys);
                                setRefresh(refresh + 1);
                            }}
                            className='react-select-filter'
                            isMulti
                        />
                    </div>

                    <div className='form-group'>
                        <label>{t("Projekt Status")}</label>
                        <Select
                            placeholder={t('Select')}
                            options={[
                                { value: '', label: '--' },
                                { value: 'Elektromontage', label: 'Elektromontage' },
                                { value: 'Dachmontage', label: 'Dachmontage' },
                                { value: 'Order', label: t('Order') },
                                { value: 'Angebot', label: t('Angebot') },
                                { value: 'Appointment', label: t('Appointment') },
                            ]}
                            onChange={(e) => {
                                var dataKeys = filterKeys;
                                var status = [];

                                console.log(e);

                                status.push(e?.value);
                                /*
                                for (let [option_key, option] of Object.entries(e)) {
                                    status.push(option?.value)
                                }
                                */
                                dataKeys.project_status = JSON.stringify(status);
                                setFilterKeys(dataKeys);
                                setRefresh(refresh + 1);
                            }}
                            className='react-select-filter'
                        //isMulti
                        />
                    </div>

                    <div className='form-group'>
                        <label>{t("Min Preis")}</label>
                        <input type="number" id="min" className='form-control' name="min" onChange={(e) => handleChangeMin(e.target.value)} placeholder="Minimum value" />
                    </div>

                    <div className='form-group'>
                        <label>{t("Max Preis")}</label>
                        <input type="number" id="max" className='form-control' name="max" onChange={(e) => handleChangeMax(e.target.value)} placeholder="Maximum value" />
                    </div>

                    <div className='form-group'>
                        <label>{t("Ort")}</label>
                        <div className='position-relative'>
                            <input
                                id='ortInput'
                                type="text"
                                placeholder={t("Ort...")}
                                className=' form-control'
                                defaultValue={ort}
                                onKeyUp={(e) => {
                                    clearTimeout(typingTimer);
                                    typingTimer = setTimeout(() => {
                                        setTableData([]);
                                        setOrt(e.target.value);
                                    }, 1500);
                                }}
                                onKeyDown={(e) => {
                                    clearTimeout(typingTimer);
                                }}
                            />

                            {
                                orts?.length >= 0 && <div className="card position-absolute p-0 m-0 me-10 top-100 w-100 rounded-0 mt-1" style={{ zIndex: '999' }}>
                                    {
                                        menuOrt && <div className="card-body p-0 m-0" >
                                            <ul className="p-1 m-0">
                                                {
                                                    orts.map((items, index) => (
                                                        <MenuItem style={{ text: "black", hover: { color: 'none' } }} key={index}>
                                                            {
                                                                items?.id ? (
                                                                    <NavLink
                                                                        style={{ color: "#344767" }}
                                                                        to={''}
                                                                        onClick={() => {
                                                                            setOrts([]);
                                                                            setOrt(items.ort);
                                                                            document.getElementById('ortInput').value = items.ort;
                                                                        }}
                                                                    >
                                                                        {items.ort} <br />
                                                                    </NavLink>
                                                                ) : ''
                                                            }
                                                        </MenuItem>
                                                    ))
                                                }
                                            </ul>
                                        </div>
                                    }
                                </div>
                            }

                        </div>
                    </div>

                    {/* //more */}
                    {more ? (
                        <>
                            <div className='form-group'>
                                <label>{t("Statusdatum")}</label>
                                <Flatpickr
                                    ref={statusdatum}
                                    options={{
                                        mode: 'range',
                                        dateFormat: 'Y-m-d',
                                    }}
                                    onChange={handleStatusdatum}
                                    placeholder='Select Dates'
                                    className="form-control"
                                />
                            </div>


                            <div className='form-group'>
                                <label>{t("Call Center")}</label>
                                <select className='form-control' onChange={(e) => setCallCenter(e.target.value)} value={callCenter}>
                                    <option value={''}>Select</option>
                                    {filderData?.call_centers?.length > 0 && (
                                        filderData?.call_centers?.map((center) => (
                                            <option value={center.id}>{center.name}</option>
                                        ))
                                    )}
                                </select>
                            </div>

                            <div className='form-group'>
                                <label>{t("Invoice")}</label>
                                <select className='form-control' onChange={(e) => setInvoice(e.target.value)}>
                                    <option>Select</option>
                                    <option value="yes">{t('Yes')}</option>
                                    <option value="no">{t('No')}</option>
                                </select>
                            </div>

                            <div className='form-group'>
                                <label>{t("Reklamation")}</label>
                                <select className='form-control' onChange={(e) => setReklamation(e.target.value)}>
                                    <option>Select</option>
                                    <option value="yes">{t('Yes')}</option>
                                    <option value="no">{t('No')}</option>
                                </select>
                            </div>

                            <div className='form-group'>
                                <label>{t("Dachmontage")}</label>
                                <select className='form-control' onChange={(e) => setDachmontage(e.target.value)}>
                                    <option>Select</option>
                                    <option value="yes">{t('Yes')}</option>
                                    <option value="no">{t('No')}</option>
                                </select>
                            </div>

                            <div className='form-group'>
                                <label>{t("Elektromontage")}</label>
                                <select className='form-control' onChange={(e) => setElektromontage(e.target.value)}>
                                    <option>Select</option>
                                    <option value="yes">{t('Yes')}</option>
                                    <option value="no">{t('No')}</option>
                                </select>
                            </div>

                            <div className='form-group'>
                                <label>{t("AC Abnahme")}</label>
                                <select className='form-control' onChange={(e) => setAC(e.target.value)}>
                                    <option>Select</option>
                                    <option value="Nicht bewertet">{t('Nicht bewertet')}</option>
                                    <option value="Erfolgreich">{t('Erfolgreich')}</option>
                                    <option value="Nicht Erfolgreich">{t('Nicht Erfolgreich')}</option>
                                </select>
                            </div>

                            <div className='form-group'>
                                <label>{t("DC Abnahme")}</label>
                                <select className='form-control' onChange={(e) => setDC(e.target.value)}>
                                    <option>Select</option>
                                    <option value="Nicht bewertet">{t('Nicht bewertet')}</option>
                                    <option value="Erfolgreich">{t('Erfolgreich')}</option>
                                    <option value="Nicht Erfolgreich">{t('Nicht Erfolgreich')}</option>
                                </select>
                            </div>

                            <div className='form-group'>
                                <label>{t("Sales Pipeline")}</label>
                                <select className='form-control'
                                    onChange={(e) => {
                                        var dataKeys = filterKeys;
                                        dataKeys.lifecycle_stage = e.target.value;
                                        setFilterKeys(dataKeys);
                                        setRefresh(refresh + 1);
                                    }}
                                >
                                    <option value={''}>Select</option>
                                    <option value="1">{t('Neukunde')}</option>
                                    <option value="2">{t('Kontaktiert')}</option>
                                    <option value="3">{t('Interessiert')}</option>
                                    <option value="4">{t('Qualifiziert')}</option>
                                    <option value="5">{t('Angebot')}</option>
                                    <option value="6">{t('Gewinn')}</option>
                                    <option value="7">{t('Verlust')}</option>
                                </select>
                            </div>
                        </>
                    ) : null}

                </div>

                <div className="p-3 border-top d-flex align-items-center justify-content-between gap-2">
                    {!more ? <button type='button' className='btn btn-secondary m-0 w-100' onClick={() => handleAddMoreButton()}>More</button> : null}
                    <button type='button' className='btn btn-primary m-0 w-100' onClick={() => handleClearButton()}>Clear</button>
                </div>
            </div>

        </ConfiguratorRoot>

        {props?.menu == 'all' ? (
            <>
                <ImportModal type={'project'} refreshParent={() => setRefresh(refresh + 1)} />
                <ImportModal type={'projectWithSales'} refreshParent={() => setRefresh(refresh + 1)} />

                <a href={Cookies.get('backend_url') + 'project-export?token=' + Cookies.get('token') + '&filetype=csv&' + '&export=project&' + url_parameters} target='_blank' id="project-export-csv" className='d-none'>Export CSV</a>
                <a href={Cookies.get('backend_url') + 'project-export?token=' + Cookies.get('token') + '&filetype=csv&' + '&export=lead&' + url_parameters} target='_blank' id="lead-export-csv" className='d-none'>Export CSV</a>
                <a href={Cookies.get('backend_url') + 'project-export?token=' + Cookies.get('token') + '&filetype=xlsx&' + '&export=project&' + url_parameters} target='_blank' id="project-export-xlsx" className='d-none'>Export XLSX</a>
                <a href={Cookies.get('backend_url') + 'project-export?token=' + Cookies.get('token') + '&filetype=xlsx&' + '&export=lead&' + url_parameters} target='_blank' id="lead-export-xlsx" className='d-none'>Export XLSX</a>
            </>
        ) : ''}

        {renderMenu()}
    </>
}

export default ProjectIndexTable;
