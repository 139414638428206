// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard PRO React example components
import PageLayout from "examples/LayoutContainers/PageLayout";

// Soft UI Dashboard PRO React page layout routes
//import pageRoutes from "page.routes";

// Images
import loginPage from "assets/images/login-page.jpeg";

import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import image from "assets/images/solacloud_icon.png";
import { useTranslation } from 'react-i18next';

function IllustrationLayout({ color, title, description, illustration, children }) {
  const { t } = useTranslation();
  return (
    <PageLayout background="white">
      <div className="row p-5 m-0" style={{ background: '#F8F9FA' }}>
        <div className="col-12 col-md-6">
          <img src="/assets/img/solacloud_logo.png" className="ms-4" style={{ maxWidth: '180px' }} alt="" />

          <div className="d-flex flex-column justify-content-center align-items-center h-100">
            <div className="row w-100 justify-content-center">
              <div className="col-12 col-md-11 col-lg-9 col-xl-7">
                <div className="text-center">
                  <h1 className="text-primary" style={{ fontSize: '40px' }}>{title}</h1>
                  <p className="text-secondary mb-3">{description}</p>
                </div>
                <div>
                  {children}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-6">
          <Card raised
            style={{
              background: '#208DFC',
              borderRadius: '8px',
              height: 'calc(100vh - 64px)'
            }}
            className="ps-5"
          >
            <SoftBox
              sx={({
                functions: { linearGradient, rgba },
                palette: { gradients },
                borders: { borderRadius },
              }) => ({
                backgroundSize: "197px",
                p: 2,
                backgroundRepeat: "no-repeat",
                backgroundPositionX: "right",
                backgroundPositionY: "bottom",
              })}
            >

              <div className="row">
                <div className="col-12 col-lg-9">
                  <div
                    style={{
                      bottom: '0px',
                      position: 'absolute'
                    }}
                  >
                    <SoftTypography
                      style={{
                        fontSize: '40px',
                        color: '#FFFFFF',
                        fontWeight: 600
                      }}
                    >
                      {t('Welcome to Solacloud!')}
                    </SoftTypography>

                    <SoftTypography
                      style={{
                        fontSize: '16px',
                        color: '#F8F9FA',
                        fontWeight: 400
                      }}
                    >
                      {t('Create your own projects, communicate with customers and track order stages.')}
                    </SoftTypography>
                    <img
                      src={loginPage}
                      style={{
                        // position: "absolute",
                        borderRadius: '8px 0px 0px 0px',
                        zIndex: 1,
                        height: 'auto',
                        width: '100%',
                        marginTop: '40px'
                        // bottom: '0px'
                      }}
                    />
                  </div>
                </div>
              </div>

              <img
                src={image}
                style={{
                  position: "absolute",
                  width: "75%",
                  opacity: "0.12",
                  right: "-35px",
                  top: "-5px",
                }} />
            </SoftBox>
          </Card>
        </div>
      </div>
    </PageLayout>
  );
}

// Setting default values for the props of IllustrationLayout
IllustrationLayout.defaultProps = {
  color: "info",
  header: "",
  title: "",
  description: "",
  illustration: {},
};

// Typechecking props for the IllustrationLayout
IllustrationLayout.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  header: PropTypes.node,
  title: PropTypes.string,
  description: PropTypes.string,
  children: PropTypes.node.isRequired,
  illustration: PropTypes.shape({
    image: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
  }),
};

export default IllustrationLayout;
