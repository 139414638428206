import { React, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Navigate, useParams } from "react-router-dom";
import { callFetch } from "../../helpers/callFetch";
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import FileUploader from 'components/FileUploader/index';
import { NavLink } from 'react-router-dom'

function Edit() {
    let params = useParams();
    const { t } = useTranslation();
    const [saving, setSaving] = useState(false);
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [data, setData] = useState([]);
    const [selectedFile, setSelectedFile] = useState(null);
    const [attachments, setAttachments] = useState([]);

    const {
        register,
        handleSubmit,
        setError,
        clearErrors,
        setValue,
        formState: { errors },
    } = useForm();

    useEffect(() => {
        if (!params.id) return;

        callFetch("module/" + params.id + "/edit", "GET", []).then((res) => {
            setData(res);
        });
    }, [params?.id]);

    useEffect(() => {
        if (!data?.data) return;

        for (let [key, value] of Object.entries(data.data)) {
            if (key == 'description') {
                for (let [description_key, description_value] of Object.entries(JSON.parse(value))) {
                    setValue('description[' + description_key + ']', (description_value && description_value != 'null' ? description_value : ''));
                }
            } else {
                if (key !== 'attachments') {
                    setValue(key, (value == null || value == 'null' ? "" : value));
                } else if (key === 'attachments') {
                    setAttachments(value ? JSON.parse(value) : [])
                }
            }
        }
    }, [data]);

    const onSubmit = (formData) => {
        setSaving(true);

        formData.description = JSON.stringify(formData.description);

        let formDataWithFiles = new FormData();

        Object.keys(formData).forEach((key) => {
            formDataWithFiles[key] = formData[key];
        });

        if (selectedFile.length > 0) {
            selectedFile.forEach((file, index) => {
                formDataWithFiles[`files[${index}]`] = file;
            });
        }

        formDataWithFiles.attachments = JSON.stringify(attachments);

        callFetch("module/" + params.id, "POST", formDataWithFiles, setError).then((res) => {
            setSaving(false);
            if (!res.ok) return;
            setSubmitSuccess(true);
        });
    };

    return submitSuccess ? <Navigate to='/products/module' /> :
        <form className={`needs-validation ${Object.keys(errors).length ? "was-validated" : ""}`} onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
            <input type="hidden" defaultValue="PUT" {...register("_method")} />
            <div className="row" style={{ paddingBottom: '100px' }}>
                <div className="col-md-8">
                    <div className="card">
                        <div className="card-header">
                            <h6 className="title">{t('Create Module')}</h6>
                        </div>
                        <div className="card-body pb-3">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>{t('Modulnr.')} *</label>
                                        <input
                                            type="text"
                                            className="form-control "
                                            placeholder={t('')}
                                            {...register("module_identity", {
                                                required: true,
                                            })}
                                            required
                                            readOnly
                                        />
                                        <div className="invalid-feedback">{errors.name && errors.name.message}</div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>{t('Hersteller / Lieferanten')} *</label>
                                        <select
                                            className="form-control"
                                            {...register("lieferanten_id", {
                                                required: true,
                                            })}
                                            required
                                        >
                                            <option value={''}>{t("Select Lieferanten")}</option>
                                            {data?.lieferanten && data?.lieferanten.map((lief) => (
                                                <option value={lief?.id}>{lief?.lieferanten_nummer + (lief?.firma_name ? ' - ' + lief?.firma_name : '')}</option>
                                            ))}
                                        </select>
                                        <div className="invalid-feedback">{errors.lieferanten_id && errors.lieferanten_id.message}</div>

                                        <a class="btn-link btn-plus-icon btn-icon-primary">{t('Lieferanten Hinzufügen')}</a>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>{t('Markenhersteller')} *</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder={t('')}
                                            {...register("description[Markenhersteller]", {
                                                required: true,
                                            })}
                                            required
                                        />
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>{t('Modulbezeichnung')} *</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder={t('')}
                                            {...register("name", {
                                                required: true,
                                            })}
                                            required
                                        />
                                        <div className="invalid-feedback">{errors.name && errors.name.message}</div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>{t('Short Title')} *</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder={t('')}
                                            {...register("short_itle", {
                                                required: true,
                                            })}
                                            required
                                        />
                                        <div className="invalid-feedback">{errors.short_itle && errors.short_itle.message}</div>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>{t('Typ')} *</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder={t('')}
                                            {...register("description[Typ]", {
                                                required: true,
                                            })}
                                            required
                                        />
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>{t('Watt')} *</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            placeholder={t('')}
                                            {...register("watt", {
                                                required: true,
                                            })}
                                            required
                                        />
                                        <div className="invalid-feedback">{errors.watt && errors.watt.message}</div>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>{t('Zelltyp')} *</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder={t('')}
                                            {...register("description[Zelltyp]", {
                                                required: true,
                                            })}
                                            required
                                        />
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>{t('Modulabmessung') + ' (' + t('Width') + ')'} *</label>
                                        <input
                                            type="number"
                                            step={0.01}
                                            className="form-control"
                                            placeholder={t('')}
                                            {...register("description[Modulabmessung_width]", {
                                                required: true,
                                            })}
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>{t('Modulabmessung') + ' (' + t('Height') + ')'} *</label>
                                        <input
                                            type="number"
                                            step={0.01}
                                            className="form-control"
                                            placeholder={t('')}
                                            {...register("description[Modulabmessung_height]", {
                                                required: true,
                                            })}
                                            required
                                        />
                                    </div>
                                </div>


                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label>{t('Description')}</label>
                                        <textarea
                                            className="form-control"
                                            rows="4"
                                            placeholder={t('')}
                                            {...register("description[description]", {
                                                required: false,
                                            })}
                                        ></textarea>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>

                <div className="col-md-4 mt-3 mt-md-0">
                    <FileUploader
                        supportedFileType={['png', 'jpg', 'jpeg', 'pdf']}
                        maxFileSizeMB={10}//Mb 
                        multiple={true}
                        onChange={(files, type) => {
                            if (type === 'files') {
                                setSelectedFile(files)
                            } else if (type === 'attachment') {
                                setAttachments(files);
                            }
                        }}
                        attachments={attachments}
                        attachmentsBasePath={Cookies.get('backend_url') + 'storage/module/'}
                    />
                </div>
            </div>

            <div className="card fixed-bottom mb-3 fixed-bottom-width-available" >
                <div className="card-footer d-flex align-items-center justify-content-end gap-2">
                    <NavLink to="/products/speicher" className="btn btn-secondary m-0 d-flex align-items-center justify-content-between gap-2">
                        <i className='ph ph-caret-left'></i>
                        {t('Back')}
                    </NavLink>

                    {saving ? (
                        <button type="button" className="btn btn-disabled m-0" disabled>
                            {t('Saving ...')}
                        </button>
                    ) : (
                        <button type="submit" className="btn btn-primary m-0">
                            {t('Save')}
                        </button>
                    )}
                </div>
            </div>
        </form>;
}

export default Edit;
