import { React, useEffect, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import callFetch from "../../../helpers/callFetch";
import Cookies from "js-cookie";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { Step, StepLabel, Stepper, StepConnector } from "@mui/material";
import DCAbnahmeprotokoll from "../OrderProccessing/default/DCAbnahmeprotokoll";
import ACAbnahmeprotokoll from "../OrderProccessing/default/ACAbnahmeprotokoll";

import DCAbnahmeprotokollPowerplantSolar from "../OrderProccessing/powerplant_solar/DCAbnahmeprotokoll";
import ACAbnahmeprotokollPowerplantSolar from "../OrderProccessing/powerplant_solar/ACAbnahmeprotokoll";

import DCReklmationProtokoll from "../OrderProccessing/default/DCReklmationProtokoll";
import ACReklmationProtokoll from "../OrderProccessing/default/ACReklmationProtokoll";

import DCReklmationProtokollPowerplantSolar from "../OrderProccessing/powerplant_solar/DCReklmationProtokoll";
import ACReklmationProtokollPowerplantSolar from "../OrderProccessing/powerplant_solar/ACReklmationProtokoll";
import Timeline from "../create/Timeline";

function getSteps(acReklamations, dcReklamations) {
  var stepHeaders = [];

  if (JSON.parse(Cookies.get("permissions")).indexOf("dcabhR") !== -1) {
    stepHeaders.push(["dc_abhnahmeprotokoll", "0"]);
    dcReklamations.forEach((acRek) => {
      var type = "dc_reklmation_abhnahmeprotokoll";
      var id = acRek.id;
      stepHeaders.push([type, id]);
    });
  }

  if (JSON.parse(Cookies.get("permissions")).indexOf("acAbhR") !== -1) {
    stepHeaders.push(["ac_abhnahmeprotokoll", "0"]);
    acReklamations.forEach((acRek) => {
      var type = "ac_reklmation_abhnahmeprotokoll";
      var id = acRek.id;
      stepHeaders.push([type, id]);
    });
  }

  return stepHeaders;
  //return ["1. DC Abnahmeprotokoll","2. Sign DC", "3. AC Abnahmeprotokoll","4. Sign AC"];
}

function OrderProcessing(props) {
  let params = useParams();
  const { t } = useTranslation();
  const user = JSON.parse(Cookies.get("user"));
  const [data, setData] = useState([]);
  const [acReklamations, setAcReklamations] = useState([]);
  const [dcReklamations, setDcReklamations] = useState([]);
  const [reklamationId, setReklamationId] = useState(0);
  const [type, setType] = useState("ac_abhnahmeprotokoll");
  const [refresh, setRefresh] = useState(0);
  const errorNotify = () => toast("Please complete leitstand first");
  const [errorData, setErrorData] = useState(false);

  const [currentStep, setCurrentStep] = useState(0);

  const steps = ["Step 1", "Step 2", "Step 3"];

  const handleNext = () => {
    if (currentStep < steps.length - 1) {
      setCurrentStep(currentStep + 1);
    } else {
      alert("Form submitted!");
    }
  };

  const handlePrev = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  useEffect(() => {
    callFetch("get-ac-dc-reklamations/" + params?.id, "GET", []).then((res) => {
      if (!res?.data?.dachmontage) {
        //errorNotify();
        // setErrorData(true);
        //document.getElementById('callcenter_a').click();
        //document.getElementById('callcenter').click();
        //return;
      }
      if (res?.ac_reklmations) {
        setAcReklamations(res.ac_reklmations);
      }
      if (res?.dc_reklmations) {
        setDcReklamations(res.dc_reklmations);
      }
      setData(res?.data);
    });
  }, [params?.id]);

  const [activeStep, setActiveStep] = useState(0);
  //   const steps = getSteps(acReklamations, dcReklamations);
  const isLastStep = activeStep === steps.length - 1;

  const {
    register,
    handleSubmit,
    setError,
    setValue,
    getValues,
    reset,
    formState: { errors },
  } = useForm();

  const Components = (indexNumber) => {
    /*
        return (
            <>
                {type}
                <br />
                {JSON.stringify(steps)}
            </>
        )
        */

    return (
      <>
        {type == "dc_abhnahmeprotokoll" ? (
          <>
            {data?.theme === "powerplant_solar3" ? (
              <DCAbnahmeprotokollPowerplantSolar
                project_id={params?.id}
                user_id={user.id}
              />
            ) : data?.theme ? (
              <DCAbnahmeprotokoll project_id={params?.id} user_id={user.id} />
            ) : (
              ""
            )}
          </>
        ) : type == "ac_abhnahmeprotokoll" ? (
          <>
            {data?.theme === "powerplant_solar3" ? (
              <ACAbnahmeprotokollPowerplantSolar
                project_id={params?.id}
                user_id={user.id}
              />
            ) : data?.theme ? (
              <ACAbnahmeprotokoll project_id={params?.id} user_id={user.id} />
            ) : (
              ""
            )}
          </>
        ) : type == "dc_reklmation_abhnahmeprotokoll" ? (
          <>
            {data?.theme === "powerplant_solar" ? (
              <DCReklmationProtokollPowerplantSolar
                project_id={params?.id}
                reklamationId={reklamationId}
              />
            ) : data?.theme ? (
              <DCReklmationProtokoll
                project_id={params?.id}
                reklamationId={reklamationId}
              />
            ) : (
              ""
            )}
          </>
        ) : type == "ac_reklmation_abhnahmeprotokoll" ? (
          <>
            {data?.theme === "powerplant_solar" ? (
              <ACReklmationProtokollPowerplantSolar
                project_id={params?.id}
                reklamationId={reklamationId}
              />
            ) : data?.theme ? (
              <ACReklmationProtokoll
                project_id={params?.id}
                reklamationId={reklamationId}
              />
            ) : (
              ""
            )}
          </>
        ) : (
          <>{indexNumber}</>
        )}
      </>
    );
  };

  return (
    <>
      <div className="row mb-5">
        <div className="col-sm-12 col-md-12 col-lg-8 col-xl-8 col-xxl-9 mt-lg-0">
          {/* <SoftBox mt={1} mb={1}>
                        <Grid container="center">
                            <Grid item xs={12} lg={9}>
                                <Stepper
                                    activeStep={activeStep}
                                    alternativeLabel className="my-3"
                                >
                                    {steps.map((label, key) => {
                                        return (
                                            <Step
                                                style={{ cursor: "pointer" }}
                                                onClick={() => {

                                                    if (label[0] === 'dc_abhnahmeprotokoll' && data?.project?.dc_product == 1 ||
                                                        label[0] === 'dc_reklmation_abhnahmeprotokoll' ||
                                                        label[0] === 'ac_reklmation_abhnahmeprotokoll' ||
                                                        //label[0] === 'dc_reklmation_abhnahmeprotokoll' && data?.project?.dc_product == 1 ||
                                                        //label[0] === 'ac_reklmation_abhnahmeprotokoll' && data?.project?.ac_product == 1 ||
                                                        label[0] === 'ac_abhnahmeprotokoll' && data?.project?.ac_product == 1
                                                    ) {
                                                        setType(label[0])
                                                        setActiveStep(key);
                                                        setReklamationId(label[1]);
                                                    }
                                                }} key={key}
                                            >
                                                <StepLabel>
                                                    {
                                                        label[0] == 'dc_abhnahmeprotokoll' ? t('DC Abnahmeprotokoll')
                                                            : label[0] == 'ac_abhnahmeprotokoll' ? t('AC Abnahmeprotokoll')
                                                                : label[0] == 'dc_reklmation_abhnahmeprotokoll' ? 'R-' + label[1]
                                                                    : label[0] == 'ac_reklmation_abhnahmeprotokoll' ? 'R-' + label[1]
                                                                        : ''
                                                    }
                                                </StepLabel>
                                            </Step>
                                        )
                                    }
                                    )}
                                </Stepper>

                            </Grid>
                        </Grid>
                    </SoftBox> */}
          <div className="step-progress plr-7rem d-none">
            <div className="step-wrapper">
              <div
                className={`step ${currentStep >= 0 ? "completed" : ""}`}
                id="step-1"
              >
                {currentStep > 0 ? <i className="fa fa-check"></i> : "1"}
              </div>
              <div
                className={`step-line ${currentStep > 0 ? "completed" : ""}`}
                id="line-1"
              >
                <span
                  className={`${
                    currentStep == 0 ? "" : "d-none-xs-cp-progress"
                  }`}
                >
                  DC Abnahmeprotokoll
                </span>
              </div>
            </div>

            <div className="step-wrapper" style={{ flexGrow: "0" }}>
              <div
                className={`step ${currentStep >= 1 ? "completed" : ""}`}
                id="step-2"
              >
                {currentStep > 1 ? <i className="fa fa-check"></i> : "2"}
              </div>
              <div>
                <span
                  className={`${
                    currentStep == 1 ? "" : "d-none-xs-cp-progress"
                  }`}
                >
                  AC Abnahmeprotokoll
                </span>
              </div>
            </div>
          </div>

          <div className="emb-4">{Components(activeStep)}</div>
          <br />
        </div>
        <div className="col-sm-12 col-md-12 col-lg-4 col-xl-4 col-xxl-3 pl-4 project-details-right-side">
          <div className="card">
            <div className="card-body">
              <div class="details">
                <h6 className="text-primary font-size-16 font-weight-500">
                  Projektinformationen
                </h6>

                <div className="mb-2">
                  <p className="font-size-14 text-secondary mb-0">
                    <i class="ph ph-clipboard-text font-size-20"></i>{" "}
                    <span className="position-relative bottom-4 left-4">
                      {t("Add")}
                    </span>
                  </p>
                  <div class="profile">
                    {/* <img
                                src="/assets/img/placeholder.png"
                                className="border-primary"
                                alt="Profile"
                                /> */}
                    <span className="font-size-14 text-primary">
                      {props.project_id ? props.project_id : "---"}
                    </span>
                  </div>
                </div>
              </div>

              {/*<Comisioning projectid={props.project_id} id={id} type={'dc'}></Comisioning>*/}
              

              <Timeline refresh={refresh} dcStatusEdit={true} />
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}

export default OrderProcessing;
