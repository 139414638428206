/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Switch from "@mui/material/Switch";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftAvatar from "components/SoftAvatar";

// Images
import burceMars from "assets/images/bruce-mars.jpg";
import callFetch from "helpers/callFetch";
import { useParams } from "react-router-dom";
import Cookies from 'js-cookie';

function Header({ imageUrl, data }) {
  const params = useParams()
  const [visible, setVisible] = useState(true);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [user, setUser] = useState({});
  const [datas, setDatas] = useState();
  const [refresh, setRefresh] = useState(0)
  const handleSetVisible = () => setVisible(!visible);


  useEffect(() => {
    if (params?.id) {
      callFetch("employees/" + params.id + "/edit", "GET", []).then((res) => {
        setDatas(res?.data)
        setUser(res?.userData)
      });
    }
  }, [params.id, refresh]);

  const handleChangeImage = (e) => {
    if (!e.target.files) return;
    onSubmit({
      attachment: e.target.files
    })
  }

  function uploadImage() {
    document.getElementById('imageUpload').click();
  }

  const onSubmit = (formData) => {
    formData._method = 'PUT';
    setUploadLoading(true)
    callFetch(`employees/${params?.id}`, "POST", formData, {}).then((res) => {
      setRefresh(refresh + 1)
      setUploadLoading(false)
    });
  };

  return (
    <>
      <SoftBox>
        <Grid container spacing={3} alignItems="center">
          <Grid item>
            <img
              src={user?.user?.photo ? Cookies.get('backend_url') + 'storage/avatar/' + user?.user?.photo : '/assets/img/placeholder.png'}
              alt="profile-image"
              className="avatar-cs box-shadow-none border-radius-100 border-primary"
              accept={'.jpg,.png,.jpeg'}
            />
            {true ? (
              <>
                {uploadLoading ? (
                  <i
                    className="fa-solid fa-spinner fa-spin"
                    style={{
                      position: 'absolute',
                      marginTop: '-21px',
                      marginLeft: '50px',
                      background: '#000000',
                      width: '25px',
                      height: '25px',
                      borderRadius: '50%',
                      color: '#ffffff',
                      fontSize: '14px',
                      textAlign: 'center',
                      lineHeight: '24px',
                      cursor: 'pointer'
                    }}
                  ></i>
                ) : (
                  <i
                    className="ph ph-camera border-primary"
                    onClick={() => uploadImage()}
                    style={{
                      position: 'absolute',
                      marginTop: '-33px',
                      marginLeft: '43px',
                      background: '#fff',
                      width: '32px',
                      height: '32px',
                      borderRadius: '5px',
                      color: '#208DFC',
                      fontSize: '20px',
                      textAlign: 'center', 
                      padding: '5px',
                      cursor: 'pointer'
                    }}
                  ></i>
                )}
              </>
            ) : ''}

            <form id="avatarForm">
              <input id="imageUpload" type="file" onChange={(e) => {
                handleChangeImage(e)
              }} />
            </form>
          </Grid>
        </Grid>
      </SoftBox>
    </>
  );
}

export default Header;
