import { React, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Navigate, useParams } from "react-router-dom";
import { callFetch } from "../../helpers/callFetch";
import { useTranslation } from 'react-i18next';
import { ref } from "yup";
import { jsonGroupData } from "pages/Article/data";
import SoftSnackbar from "components/SoftSnackbar";
import Cookies from 'js-cookie';
import FileUploader from 'components/FileUploader/index';
import { NavLink } from 'react-router-dom'

function CreateProduct() {
    let params = useParams();
    const { t } = useTranslation();
    const [saving, setSaving] = useState(false);
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [refresh, setRefresh] = useState(0);
    const [lieferanten, setlieferanten] = useState([]);
    const [category, setCategory] = useState('');
    const [articalCategory, setArticalCategory] = useState('');
    const [data, setData] = useState([]);
    const [selectedFile, setSelectedFile] = useState(null);
    const [attachments, setAttachments] = useState([]);

    const [errorSB, setErrorSB] = useState(false);
    const closeErrorSB = () => setErrorSB(false);
    const renderErrorSB = (
        <SoftSnackbar
            color="error"
            icon="error"
            title={t('The file must not be greater than 5 MB.')}
            //content={t("Changes saved successfully")}
            dateTime={t("")}
            open={errorSB}
            onClose={() => closeErrorSB(false)}
            close={() => closeErrorSB(false)}
            bgDanger
        />
    );

    // Filter subcategories based on the selected category
    const subcategories =
        jsonGroupData?.categories
            .find((category) => category.name === articalCategory)
            ?.group || [];

    const {
        register,
        handleSubmit,
        setError,
        setValue,
        clearErrors,
        reset,
        formState: { errors },
    } = useForm();

    useEffect(() => {
        callFetch("product/create", "GET", [], setError).then((res) => {
            if (!res.ok) return;
            setlieferanten(res?.data?.lieferanten)
        });
    }, [refresh]);

    useEffect(() => {
        callFetch("product/" + params.id + "/edit", "GET", [], setError).then((res) => {
            if (!res.ok) return;
            setData(res);
            setCategory(res?.data?.category ? res?.data?.category : '')
            setArticalCategory(res?.data?.article_category ? res?.data?.article_category : '')
        });
    }, []);
    useEffect(() => {
        if (!data?.data) return;

        for (let [key, value] of Object.entries(data.data)) {
            if (key == 'status') {
                if (value) {
                    setValue(key, true);
                } else {
                    setValue(key, false);
                }
            } else {
                if (key !== 'attachments') {
                    setValue(key, (value == null || value == 'null' ? "" : value));
                } else if (key === 'attachments') {
                    setAttachments(value ? JSON.parse(value) : [])
                }
            }
        }

    }, [data, lieferanten]);

    const onSubmit = (formData) => {
        setSaving(true);
        formData.status = (formData?.status ? 1 : 0);

        if (category === 'Dienstleistung') {
            formData.article_category = '';
            formData.article_group = '';
        }

        let formDataWithFiles = new FormData();

        Object.keys(formData).forEach((key) => {
            formDataWithFiles[key] = formData[key];
        });

        if (selectedFile.length > 0) {
            selectedFile.forEach((file, index) => {
                formDataWithFiles[`files[${index}]`] = file;
            });
        }

        formDataWithFiles.attachments = JSON.stringify(attachments);

        callFetch("product/" + params.id, "POST", formDataWithFiles, setError).then((res) => {
            setSaving(false);
            if (!res.ok) return;
            setSubmitSuccess(true);
        });
    };

    return submitSuccess ? <Navigate to='/zusatzprodukte' /> :
        <>
            <form className={`needs-validation ${Object.keys(errors).length ? "was-validated" : ""}`} onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
                <input type="hidden" defaultValue="PUT" {...register("_method")} />
                <div className="row" style={{ paddingBottom: '100px' }}>
                    <div className="col-md-8">
                        <div className="card">
                            <div className="card-header">
                                <h6 className="title">{t('Zusatzprodukte')}</h6>
                            </div>
                            <div className="card-body pb-3">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>{t('Produktnummber')} *</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder={t('')}
                                                {...register("identity", {
                                                    required: true,
                                                })}
                                                required
                                                readOnly
                                            />
                                            <div className="invalid-feedback">{errors.name && errors.name.message}</div>
                                        </div>
                                    </div>


                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>{t('Hersteller / Lieferanten')} *</label>
                                            <select
                                                className="form-control"
                                                {...register("lieferanten_id", {
                                                    required: true,
                                                })}
                                                required
                                            >
                                                <option value={''}>{t("Select Lieferanten")}</option>
                                                {lieferanten.map((lief) => (
                                                    <option value={lief?.id}>{lief?.lieferanten_nummer + (lief?.firma_name ? ' - ' + lief?.firma_name : '')}</option>
                                                ))}
                                            </select>
                                            <div className="invalid-feedback">{errors.lieferanten_id && errors.lieferanten_id.message}</div>
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group form-control">
                                            <div className="form-check form-switch d-flex align-items-center justify-content-between ps-0">
                                                <label>{t('Active')}</label>
                                                <input className="form-check-input" type="checkbox" {...register("status")} />
                                            </div>
                                            <div className="invalid-feedback">{errors.status && errors.status.message}</div>
                                        </div>
                                    </div>


                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label>{t('Produktname')} *</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder={t('')}
                                                {...register("name", {
                                                    required: true,
                                                })}
                                                required
                                            />
                                            <div className="invalid-feedback">{errors.name && errors.name.message}</div>
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>{t('DC- oder AC Produkt')} *</label>
                                            <select
                                                className="form-control"
                                                {...register("category", {
                                                    required: true,
                                                    onChange: (e) => {
                                                        setCategory(e.target.value);

                                                        if (e.target.value === 'Dienstleistung') {
                                                            setValue('article_category', '');
                                                            setArticalCategory('');
                                                        }
                                                    }
                                                })}
                                                required
                                            >
                                                <option value="">{t('auswählen')}</option>
                                                <option value="ac">{t('AC Produkt')}</option>
                                                <option value="dc">{t('DC Produkt')}</option>
                                                <option value="Dienstleistung">{t('Dienstleistung')}</option>
                                            </select>
                                            <div className="invalid-feedback">{errors.category && errors.category.message}</div>
                                        </div>
                                    </div>

                                    {category !== 'Dienstleistung' ? (
                                        <>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>{t('Kategorie')}</label>
                                                    <select
                                                        className="form-control"
                                                        {...register('article_category', {
                                                            required: false,
                                                            onChange: (e) => {
                                                                setArticalCategory(e.target.value);
                                                            }
                                                        })}
                                                        required={false}
                                                    >
                                                        <option value="">{t('----')}</option>
                                                        {
                                                            jsonGroupData && jsonGroupData?.categories?.map((category, index) => (
                                                                <option key={index} value={category?.name}>{t(category?.name)}</option>
                                                            ))
                                                        }
                                                    </select>
                                                    <div className="invalid-feedback">{errors.article_category && errors.article_category.message}</div>
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>{t('Gruppe')}</label>
                                                    <select className="form-control"
                                                        {...register('article_group', {
                                                            required: false,
                                                        })}
                                                        required={false}
                                                    >
                                                        <option value="">{t("----")}</option>
                                                        {
                                                            subcategories && subcategories?.map((group, index) => (
                                                                <option key={index} value={group}>{t(group)}</option>
                                                            ))
                                                        }

                                                    </select>
                                                    <div className="invalid-feedback">{errors.article_group && errors.article_group.message}</div>
                                                </div>
                                            </div>
                                        </>
                                    ) : ''}

                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>{t('Einheit')} *</label>
                                            <select
                                                className="form-control"
                                                {...register('unit', { required: true })}
                                                required
                                            >
                                                <option value="">{t("----")}</option>
                                                <option value="Meter">{t("Meter")}</option>
                                                <option value="Stück">{t("Stück")}</option>
                                                <option value="Kg">{t("Kg")}</option>
                                                <option value="Liter">{t("Liter")}</option>
                                                <option value="m2">{t("m2")}</option>
                                                <option value="%">%</option>
                                                <option value="watt">Watt (W)</option>

                                            </select>
                                            <div className="invalid-feedback">{errors.unit && errors.unit.message}</div>
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>{t('Einkaufspreis in €')} *</label>
                                            <input
                                                type="number"
                                                className="form-control"
                                                step={0.01}
                                                placeholder={t('00,00 €')}
                                                {...register("einkaufs_price", {
                                                    required: true,
                                                })}
                                                required
                                            />
                                            <div className="invalid-feedback">{errors.einkaufs_price && errors.einkaufs_price.message}</div>
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>{t('Verkaufspreis in €')} *</label>
                                            <input
                                                type="number"
                                                className="form-control"
                                                step={0.01}
                                                placeholder={t('00,00 €')}
                                                {...register("verkauf_price", {
                                                    required: true,
                                                })}
                                                required
                                            />
                                            <div className="invalid-feedback">{errors.verkauf_price && errors.verkauf_price.message}</div>
                                        </div>
                                    </div>


                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label>{t('Description')}</label>
                                            <textarea
                                                className="form-control"
                                                rows="4"
                                                placeholder={t('')}
                                                {...register("description")}></textarea>
                                            <div className="invalid-feedback">{errors.description && errors.description.message}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className="col-md-4 mt-3 mt-md-0">
                        <FileUploader
                            supportedFileType={['png', 'jpg', 'jpeg', 'pdf']}
                            maxFileSizeMB={10}//Mb 
                            multiple={true}
                            onChange={(files, type) => {
                                if (type === 'files') {
                                    setSelectedFile(files)
                                } else if (type === 'attachment') {
                                    setAttachments(files);
                                }
                            }}
                            attachments={attachments}
                            attachmentsBasePath={Cookies.get('backend_url') + 'storage/product/'}
                        />
                    </div>
                </div>

                <div className="card fixed-bottom mb-3 fixed-bottom-width-available" >
                    <div className="card-footer d-flex align-items-center justify-content-end gap-2">
                        <NavLink to="/zusatzprodukte" className="btn btn-secondary m-0 d-flex align-items-center justify-content-between gap-2">
                            <i className='ph ph-caret-left'></i>
                            {t('Back')}
                        </NavLink>

                        {saving ? (
                            <button type="button" className="btn btn-disabled m-0" disabled>
                                {t('Saving ...')}
                            </button>
                        ) : (
                            <button type="submit" className="btn btn-primary m-0">
                                {t('Save')}
                            </button>
                        )}
                    </div>
                </div>
            </form>
        </>
}

export default CreateProduct;