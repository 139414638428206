import React, { useRef } from "react";
import PropTypes from "prop-types";

// react-flatpickr components
import Flatpickr from "react-flatpickr";

// react-flatpickr styles
import "flatpickr/dist/flatpickr.css";

import { German } from "flatpickr/dist/l10n/de.js";

function SoftDatePicker({ input, onReady, buttonDisplay, className, fontSize = '16px', ...rest }) {
  const flatpickrRef = useRef(null); // Ref for Flatpickr instance

  const handleDateChooseOpen = () => {
    if (flatpickrRef && flatpickrRef?.current && flatpickrRef?.current?.flatpickr) {
      flatpickrRef.current.flatpickr.open(); // Open Flatpickr programmatically
    }
  };

  return (
    <>
      {buttonDisplay ? (
        <button
          className="btn btn-sm btn-secondary icon-brand btn-CalendarDots-icon m-0"
          onClick={handleDateChooseOpen}
        ></button>
      ) : ''}

      <div className="position-relative d-flex align-items-center">
        <i
          class="ph ph-calendar-dots text-primary  position-absolute start-0"
          style={{
            paddingLeft: '6px',
            fontSize: fontSize,
          }}
        ></i>
        <Flatpickr
          {...rest}
          className={className + ' date-picker'}
          placeholder={'DD.MM.YYYY'}
          options={{
            ...rest.options,
            dateFormat: 'd.m.Y',
            locale: {
              ...German, // Ensures Monday is the first day
              firstDayOfWeek: 1, // Explicitly set Monday as the first day
            },
            onReady: (selectedDates, dateStr, instance) => {
              const calendarContainer = instance.calendarContainer;

              if (calendarContainer) {
                calendarContainer.classList.add("my-custom-calendar");
              }

              const monthsContainer = calendarContainer.querySelector(".flatpickr-months");
              if (monthsContainer) {
                monthsContainer.innerHTML = "";

                // Create custom header
                const customHeader = document.createElement("div");
                customHeader.className = "d-flex align-items-center justify-content-between p-2";

                customHeader.innerHTML = `
                  <span class="current-month">${instance.formatDate(selectedDates[0] || new Date(), "F Y")}</span>
                  <div class="d-flex align-items-center justify-content-end gap-2">
                      <a type="button" class="prev-month btn-link btn-arrow-left-icon"></a>
                      <a type="button" class="next-month btn-link btn-arrow-right-icon"></a>
                  </div>
              `;

                monthsContainer.appendChild(customHeader);

                const prevButton = customHeader.querySelector(".prev-month");
                const nextButton = customHeader.querySelector(".next-month");
                const currentMonthSpan = customHeader.querySelector(".current-month");

                const updateCurrentMonth = () => {
                  currentMonthSpan.textContent = instance.formatDate(
                    new Date(instance.currentYear, instance.currentMonth),
                    "F Y"
                  );
                };

                // Attach events for navigation buttons
                prevButton.addEventListener("click", () => {
                  instance.changeMonth(-1);
                  updateCurrentMonth();
                });
                nextButton.addEventListener("click", () => {
                  instance.changeMonth(1);
                  updateCurrentMonth();
                });
              }
            },
            onMonthChange: (selectedDates, dateStr, instance) => {
              // Update custom header dynamically on month change
              const currentMonthSpan = instance.calendarContainer.querySelector(".current-month");
              if (currentMonthSpan) {
                currentMonthSpan.textContent = instance.formatDate(
                  new Date(instance.currentYear, instance.currentMonth),
                  "F Y"
                );
              }
            },
          }}
          ref={flatpickrRef}
        />
      </div>

    </>
  );
}

// Setting default values for the props of SoftDatePicker
SoftDatePicker.defaultProps = {
  input: {},
  onReady: null,
  buttonDisplay: false,
};

// Typechecking props for the SoftDatePicker
SoftDatePicker.propTypes = {
  input: PropTypes.objectOf(PropTypes.any),
  onReady: PropTypes.func,
  buttonDisplay: PropTypes.bool,
};

export default SoftDatePicker;
