import { React, useEffect, useState, useRef } from "react";
import { callFetch } from "../../../helpers/callFetch";
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import Tooltip from "@mui/material/Tooltip";
import Checkbox from "@mui/material/Checkbox";
import SoftAvatar from "components/SoftAvatar";
import dateFormat from "dateformat";
import { NavLink } from "react-router-dom";
import SoftDatePicker from "components/SoftDatePicker";
import Flatpickr from "react-flatpickr";
import SoftSelect from "components/SoftSelect";
import Select, { components } from 'react-select';
import styles from "components/SoftSelect/styles";
import DataTable from 'react-data-table-component';

function Index() {
    const { t } = useTranslation();
    const [error, setError] = useState({});
    const [createNew, setCreateNew] = useState(null);
    const [refresh, setRefresh] = useState(0);
    const [todo, setToDo] = useState([]);
    const [activeTab, setActiveTab] = useState(0);

    const [userSelectOpen, setUserSelectOpen] = useState(false);
    const [projectSelectOpen, setProjectSelectOpen] = useState(false);

    const [filderData, setFilderData] = useState({});
    const [users, setUsers] = useState([]);
    const [saving, setSaving] = useState(false);



    useEffect(() => {
        callFetch("get-project-filter-data", "GET", []).then((res) => {
            setFilderData(res.data);
        });

        callFetch("get-users-todo", "GET", []).then((res) => {
            setUsers(res);
        });

    }, []);

    useEffect(() => {
        loadToDo(activeTab);
    }, [refresh]);

    const handleCompleteToDo = (id) => {
        callFetch("todo-complete/" + id, "PUT", []).then((res) => {
            loadToDo(activeTab);
        });
    }

    const loadToDo = (status) => {
        callFetch("get-todo/" + status, "GET", []).then((res) => {
            setToDo(res);
        });
    }

    const onSubmit = () => {
        setSaving(true);

        var userIds = [];
        for (let [option_key, option] of Object.entries(createNew.users)) {
            userIds.push(option?.value)
        }

        let formData = {
            title: createNew?.title ?? '',
            project_id: createNew?.project_id ?? '',
            date: createNew?.date ?? '',
            users: JSON.stringify(userIds),
        }

        callFetch("store-to-do", "POST", formData).then((res) => {
            setSaving(false);

            if (!res.ok) return;

            setRefresh(prev => prev + 1);
            setCreateNew(null);

            const element = document.getElementById('aufgabenTable');
            if (element) {
                element.scrollTop = element.scrollHeigh; // Scroll to the bottom
            }
        });
    };

    const renderUsers = (users) => {
        return users?.map(({ photo: image, name }, index) => (
            <>
                <Tooltip key={name} title={name} placement="bottom">
                    <SoftAvatar
                        src={image?.length > 0 ? Cookies.get('backend_url') + 'storage/avatar/' + image : '/assets/img/placeholder.png'}
                        alt={name}
                        size="xs"
                        sx={({ palette: { white } }) => ({
                            cursor: "pointer",
                            position: "relative",
                            ml: index ? -1.8 : 0,

                            "&:hover, &:focus": {
                                zIndex: "10",
                            },
                        })}
                    />
                </Tooltip>
            </>
        ));
    };


    return <>

        <div className="card">
            <div className="card-header d-flex justify-content-between align-items-center">
                <h6 className='title'>{t('Aufgaben')}</h6>
                {activeTab === 0 && !createNew && (
                    <a
                        className="btn-link btn-plus-icon btn-icon-primary"
                        //data-bs-toggle="modal"
                        //data-bs-target="#staticBackdrop"
                        onClick={() => {
                            setCreateNew({
                                title: '',
                                project_id: '',
                                date: '',
                                users: [],
                            });

                            const element = document.getElementById('aufgabenTable');
                            if (element) {
                                element.scrollTop = 0; // Scroll to the top of the element
                            }
                        }}
                    >{t('Aufgabe Hinzufügen')}</a>
                )}
            </div>

            <div className="card-body">
                <ul className="nav nav-tabs border-0 gap-2">
                    <li className="nav-item">
                        <button onClick={() => { loadToDo(0); setActiveTab(0); }} className={`px-2 text-primary text-md nav-link ${activeTab === 0 ? 'active' : ''}`}>Offene Aufgaben</button>
                    </li>
                    <li className="nav-item">
                        <button onClick={() => { setCreateNew(null); loadToDo(1); setActiveTab(1) }} className={`px-2 text-primary text-md nav-link ${activeTab === 1 ? 'active' : ''}`}>Erledigte Aufgaben</button>
                    </li>
                </ul>

                {todo?.data?.length > 0 || createNew ? (
                    <>
                        <div
                            style={{
                                height: '340px',
                                overflow: 'auto',
                                scrollbarColor: '#aaa transparent',
                                scrollbarWidth: 'thin'
                            }}
                            id="aufgabenTable"
                        >
                            <DataTable
                                columns={[
                                    {
                                        name: t(''),
                                        width: '44px',
                                        cell: row => <><Checkbox disabled={!row?.id} checked={row.status} onChange={() => { handleCompleteToDo(row.id) }} /></>,
                                        sortable: false,
                                    },
                                    {
                                        name: t('Aufgabe Titel'),
                                        //maxWidth: '135px',
                                        cell: row => <>
                                            {row?.id ? (
                                                <Tooltip title={row?.title} placement="bottom">
                                                    <span
                                                        style={{
                                                            whiteSpace: 'nowrap',
                                                            overflow: 'hidden',
                                                            textOverflow: 'ellipsis',

                                                        }}
                                                    >
                                                        {row?.title}
                                                    </span>
                                                </Tooltip>
                                            ) : (
                                                <input
                                                    type="text"
                                                    className="form-control form-control-sm border-0"
                                                    placeholder={t('Aufgabe Titel')}
                                                    style={{
                                                        minWidth: '124px',
                                                    }}
                                                    value={createNew?.title}
                                                    onChange={(e) => {
                                                        setCreateNew((prevToDo) => {
                                                            return { ...prevToDo, title: e.target.value };
                                                        })
                                                    }}
                                                />
                                            )}
                                        </>,
                                        sortable: false,
                                    },
                                    {
                                        name: t('Project'),
                                        width: '132px',
                                        cell: row => <>{row?.project_id && <NavLink to={'/projects/' + row.project_id} className="text-brand-primary text-link">{row.project_id}</NavLink>}</>,
                                        sortable: false
                                    },
                                    {
                                        name: t('Date'),
                                        width: '132px',
                                        cell: row => <>{row.date && dateFormat(row?.date, "dd.mm.yyyy")}</>,
                                        sortable: false
                                    },
                                    {
                                        name: t('Empfänger'),
                                        width: '132px',
                                        cell: row => <>
                                            {row?.id ?
                                                <>
                                                    {row?.assigned_users && (
                                                        renderUsers(row.assigned_users)
                                                    )}
                                                </> : <></>}
                                        </>,
                                        sortable: false
                                    },
                                    {
                                        name: t('Schöpfer'),
                                        width: '130px',
                                        cell: row => <>
                                            {row?.id ?
                                                <>
                                                    {row?.creator && (
                                                        renderUsers([row.creator])
                                                    )}
                                                </> : <>
                                                    <div
                                                        className="d-flex align-items-center justify-content-end gap-1"
                                                        style={{
                                                            marginLeft: '-180px'
                                                        }}
                                                    >
                                                        <div
                                                            className="d-flex align-items-center justify-content-between gap-1"
                                                            style={{
                                                                //width: '128px'
                                                            }}
                                                        >
                                                            <Select
                                                                styles={styles('small', false, false)}
                                                                menuPortalTarget={document.body}
                                                                //size="small"
                                                                options={filderData?.projects ? filderData?.projects : []}
                                                                onChange={(e) => {
                                                                    setCreateNew((prevToDo) => {
                                                                        return { ...prevToDo, project_id: e.value };
                                                                    })
                                                                }}
                                                                menuIsOpen={projectSelectOpen}
                                                               //menuIsOpen
                                                                onMenuOpen={() => setProjectSelectOpen(true)}
                                                                onMenuClose={() => setProjectSelectOpen(false)}
                                                                isClearable
                                                                classNamePrefix="react_select"
                                                                components={{
                                                                    Option: (props) => {
                                                                        const { data, innerRef, innerProps } = props;
                                                                        return (
                                                                            <div
                                                                                ref={innerRef}
                                                                                {...innerProps}
                                                                                className="d-flex align-items-center justify-content-start text-primary react-select-item text-link cursor-pointer"
                                                                                style={{
                                                                                    width: '200px',
                                                                                }}
                                                                            >
                                                                                <span
                                                                                    style={{
                                                                                        whiteSpace: 'nowrap',
                                                                                        overflow: 'hidden',
                                                                                        textOverflow: 'ellipsis',
                                                                                    }}
                                                                                >{data.label}</span>
                                                                            </div>
                                                                        );
                                                                    },
                                                                    Control: ({ children, ...props }) => (
                                                                        <components.Control {...props}>
                                                                            <button
                                                                                type="button"
                                                                                className="btn btn-sm btn-secondary icon-brand btn-Briefcase-icon m-0"
                                                                                onClick={(e) => {
                                                                                    e.stopPropagation();
                                                                                    props.selectProps.menuIsOpen = !props.selectProps.menuIsOpen;
                                                                                    props.selectProps.onMenuOpen();
                                                                                }}
                                                                            >
                                                                            </button>
                                                                            <div className="visually-hidden">{children}</div>
                                                                        </components.Control>
                                                                    ),
                                                                }}
                                                            />

                                                            <div className="d-flex align-items-center justify-content-between">
                                                                <SoftDatePicker
                                                                    options={{
                                                                        dateFormat: "Y-m-d",
                                                                        weekNumbers: false,
                                                                        onChange: (date) => {
                                                                            if (date[0] ?? '') {
                                                                                let selectedDate = date[0].toLocaleDateString("en-CA");

                                                                                setCreateNew((prevToDo) => {
                                                                                    return { ...prevToDo, date: selectedDate };
                                                                                })
                                                                            }

                                                                        },
                                                                    }}

                                                                    className="form-control flatpickr-input visually-hidden"
                                                                    buttonDisplay={true}
                                                                />
                                                            </div>

                                                            <Select
                                                                styles={styles('small', false, false)}
                                                                menuPortalTarget={document.body}
                                                                //size="small"
                                                                options={users?.data ? users?.data : []}
                                                                onChange={(e) => {
                                                                    setCreateNew((prevToDo) => {
                                                                        return { ...prevToDo, users: e };
                                                                    })
                                                                }}
                                                                menuIsOpen={userSelectOpen}
                                                                onMenuOpen={() => setUserSelectOpen(true)}
                                                                onMenuClose={() => setUserSelectOpen(false)}
                                                                isClearable
                                                                classNamePrefix="react_select"
                                                                components={{
                                                                    Option: (props) => {
                                                                        const { data, innerRef, innerProps } = props;
                                                                        return (
                                                                            <div
                                                                                ref={innerRef}
                                                                                {...innerProps}
                                                                                className="d-flex align-items-center justify-content-start gap-2 my-2 text-primary react-select-item text-link cursor-pointer"
                                                                                style={{
                                                                                    width: '200px',
                                                                                }}
                                                                            >
                                                                                <SoftAvatar
                                                                                    src={'/assets/img/placeholder.png'}
                                                                                    size="xs"
                                                                                    sx={({ palette: { white } }) => ({
                                                                                        cursor: "pointer",
                                                                                        position: "relative",
                                                                                        // ml: -1.25,

                                                                                        "&:hover, &:focus": {
                                                                                            zIndex: "10",
                                                                                        },
                                                                                    })}
                                                                                />
                                                                                <span
                                                                                    style={{
                                                                                        whiteSpace: 'nowrap',
                                                                                        overflow: 'hidden',
                                                                                        textOverflow: 'ellipsis',
                                                                                    }}
                                                                                >{data.label}</span>
                                                                            </div>
                                                                        );
                                                                    },
                                                                    Control: ({ children, ...props }) => {
                                                                        return (
                                                                            <components.Control {...props}>
                                                                                <button
                                                                                    className="btn btn-sm btn-secondary icon-brand btn-user-plus-icon m-0"
                                                                                    onClick={(e) => {
                                                                                        e.stopPropagation();
                                                                                        props.selectProps.menuIsOpen = !props.selectProps.menuIsOpen;
                                                                                        props.selectProps.onMenuOpen();
                                                                                    }}
                                                                                >

                                                                                </button>
                                                                                <div className="visually-hidden">{children}</div>
                                                                            </components.Control>
                                                                        )
                                                                    }
                                                                }}
                                                                isMulti={true}
                                                            />
                                                        </div>

                                                        <div
                                                            className="d-flex align-items-center justify-content-start gap-1"
                                                            style={{
                                                                width: '173px'
                                                            }}
                                                        >
                                                            <button
                                                                className="btn btn-sm btn-secondary m-0"
                                                                onClick={() => {
                                                                    setCreateNew(null)
                                                                }}
                                                            >Abbrechen</button>

                                                            <button
                                                                className="btn btn-sm btn-primary m-0 m-0"
                                                                type="button"
                                                                onClick={() => {
                                                                    onSubmit();
                                                                }}
                                                            >Erstellen</button>
                                                        </div>
                                                    </div>

                                                </>}
                                        </>,
                                        sortable: false
                                    },
                                ]}
                                data={createNew && activeTab === 0 ? [createNew, ...(todo?.data || [])] : (todo?.data || [])}
                                className="py-3"
                            />
                        </div>
                    </>
                ) : (
                    <>
                        <div
                            className="d-flex align-items-center justify-content-center"
                            style={{
                                height: '336px'
                            }}
                        >
                            <div className="text-center">
                                <img
                                    src="/assets/icons/CalendarX.svg"
                                    style={{
                                        width: '48px',
                                        height: '52px',
                                        filter: 'brightness(0) saturate(100%) invert(35%) sepia(15%) saturate(765%) hue-rotate(182deg) brightness(91%) contrast(89%)'
                                    }}
                                    className="mb-2"
                                />

                                <p className="text-secondary text-md m-0 mb-2">Sie haben noch keine Aufgaben</p>
                                {activeTab === 0 && (
                                    <a
                                        className="btn-link btn-plus-icon btn-icon-primary"
                                        onClick={() => {
                                            setCreateNew({
                                                title: '',
                                                project_id: '',
                                                date: '',
                                                users: [],
                                            })
                                        }}
                                    >{t('Aufgabe Hinzufügen')}</a>
                                )}
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div >
    </>
}

export default Index;