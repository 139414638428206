import React from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";

const Empty = (props) => {
  const { t } = useTranslation();
  return (
    <>
      <div
        className="d-flex align-items-center justify-content-center"
        style={{
          height: props.height,
        }}
      >
        <div className="text-center">
         <i class="ph ph-clipboard text-secondary" style={{fontSize: '64px'}}></i>
          <p className="text-secondary text-md m-0 mb-2 mt-2">
            {props.text}
          </p>

          {props.btn == true ? <a
            className="btn-link btn-plus-icon btn-icon-primary"
            data-bs-toggle="modal"
            data-bs-target={props.modalTarget}
          >
            {props.btnText}
          </a> : '' }
          
        </div>
      </div>
    </>
  );
}

export default Empty
