import { React } from "react";
import Calendar from 'pages/calendar/Index';

function Index2() {

    return (
        <>
            <Calendar
                team_type={'ac,dc'}
                vkEventHide={true}
                editEvent={true}
                ordersToPlan={true}
            />
        </>
    );
}

export default Index2;
