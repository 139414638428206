import { React, useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { Navigate, useParams } from "react-router-dom";
import Cookies from 'js-cookie';
import dateFormat, { masks } from "dateformat";
import { NumericFormat } from 'react-number-format';
import callFetch from "../../../helpers/callFetch";
import CorrectionRequestView from "./CorrectionRequestView";

import SoftTypography from "components/SoftTypography";
import { NavLink } from "react-router-dom";

function TimelineNew({ projectid = 0, api_url, refresh, height = '100%' }) {
    const { t } = useTranslation();
    let params = useParams();

    //const [refresh, setRefresh] = useState(1);
    const [data, setData] = useState([]);


    useEffect(() => {
        if (!params.id) return;
        if (!api_url) return;

        callFetch("project/timelines-overview/" + (projectid ? projectid : params.id), "GET", [], {}, api_url).then((res) => {
            setData(res.data);
        });
    }, [api_url, projectid + refresh + params?.id + (refresh ?? 0)]);

    return (
        <>
            {data && data.project ? (
                <div className="card bg-secondary">
                    <div className="card-header bg-secondary pb-3">
                        <h3 className="title text-lg">{t('Project Status')}</h3>
                    </div>

                    <div
                        className="card-body pb-3 pt-1 scroll-cs"
                        style={{
                            height: height ? height : '100%'
                        }}
                    >
                        <div className="timeline timeline-one-side" data-timeline-axis-style="dotted">

                            {data.quations && data.quations.map((quation) => (
                                <>
                                    <div className="timeline-block mb-3">
                                        <span className="timeline-step">
                                            <span className="brand-icon-bg"><i className="icon-sola-check-circle icon-white"></i></span>
                                        </span>
                                        <div className="timeline-content">
                                            <div className="d-flex justify-content-between align-items-center">
                                                <h6 className="text-dark font-size-14 font-weight-500 mb-0">{t('Quotation')}</h6>
                                                <p className="text-secondary font-size-14  mb-0">{dateFormat(quation.created_at, "dd.mm.yyyy")}</p>
                                            </div>

                                            <hr className="mb-2 mt-2" />

                                            <div className="d-flex justify-content-between align-items-center">
                                                <p className="text-secondary font-size-14 mb-0">{t('Quotation Nr.')}</p>
                                                <p className="text-primary font-size-14 mb-0">{quation.id}</p>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            ))}

                            {data.orders && data.orders.map((order) => (
                                <>
                                    <div className="timeline-block mb-3">
                                        <span className="timeline-step">
                                            <span className="brand-icon-bg"><i className="icon-sola-check-circle icon-white"></i></span>
                                        </span>
                                        <div className="timeline-content">
                                            <div className="d-flex justify-content-between align-items-center">
                                                <h6 className="text-dark font-size-14 font-weight-500 mb-0">{t('Order')}</h6>
                                                <p className="text-secondary font-size-14  mb-0">{dateFormat(order.created_at, "dd.mm.yyyy")}</p>
                                            </div>

                                            <hr className="mb-2 mt-2" />

                                            <div className="d-flex justify-content-between align-items-center">
                                                <p className="text-secondary font-size-14 mb-0">{t('Order Nr.')}</p>
                                                <p className="text-primary font-size-14 mb-0">{order.id}</p>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            ))}

                            {data.assign_orders && data.assign_orders.map((assign_order) => (
                                <>
                                    {assign_order.dachmontag_elektromontage == 'Dachmontage' ? (
                                        <>
                                            <div className="timeline-block mb-3">
                                                <span className="timeline-step">
                                                    <span className="brand-icon-bg"><i className="icon-sola-check-circle icon-white"></i></span>
                                                </span>
                                                <div className="timeline-content">
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <h6 className="text-dark font-size-14 font-weight-500 mb-0">{assign_order.dachmontag_elektromontage}</h6>
                                                        <p className="text-secondary font-size-14  mb-0">{dateFormat(assign_order.created_at, "dd.mm.yyyy")} {dateFormat(assign_order.created_at, "HH:ss")}</p>
                                                    </div>
                                                </div>
                                            </div>



                                            {data.project.reclamations && data.project.reclamations.map((reclamation, index) => (
                                                <>
                                                    {reclamation?.department == 'Dachmontage' ? (
                                                        <div className="timeline-block mb-3">
                                                            <span className="timeline-step">
                                                                <span className="brand-icon-bg"><i className="icon-sola-check-circle icon-white"></i></span>
                                                            </span>
                                                            <div className="timeline-content">
                                                                <div className="d-flex justify-content-between align-items-center">
                                                                    <h6 className="text-dark font-size-14 font-weight-500 mb-0">{t('Reklamation')}</h6>
                                                                    <p className="text-secondary font-size-14  mb-0">{dateFormat(reclamation.created_at, "dd.mm.yyyy")} {dateFormat(reclamation.created_at, "HH:ss")}</p>
                                                                </div>

                                                                <hr className="mb-2 mt-2" />

                                                                <div className="d-flex justify-content-between align-items-center">
                                                                    <p className="text-secondary font-size-14 mb-0">{t('Rekla.Nr')}</p>
                                                                    <p className="text-primary font-size-14 mb-0">{'R-' + reclamation?.id}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ) : ''}
                                                </>
                                            ))}

                                            <div className="timeline-block mb-3">
                                                <span className="timeline-step">
                                                    <span className="brand-icon-bg"><i className="icon-sola-check-circle icon-white"></i></span>
                                                </span>
                                                <div className="timeline-content">
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <h6 className="text-dark font-size-14 font-weight-500 mb-0">{t('Beschaffung')}</h6>
                                                    </div>
                                                </div>
                                            </div>

                                            {data.project.reclamations && data.project.reclamations.map((reclamation, index) => (
                                                <>
                                                    {reclamation?.department == 'Einkauf' ? (
                                                        <>
                                                            <div className="timeline-block mb-3">
                                                                <span className="timeline-step">
                                                                    <span className="brand-icon-bg"><i className="icon-sola-check-circle icon-white"></i></span>
                                                                </span>
                                                                <div className="timeline-content">
                                                                    <div className="d-flex justify-content-between align-items-center">
                                                                        <h6 className="text-dark font-size-14 font-weight-500 mb-0">{t('Reklamation')}</h6>
                                                                        <p className="text-secondary font-size-14  mb-0">{dateFormat(reclamation.created_at, "dd.mm.yyyy")} {dateFormat(reclamation.created_at, "HH:ss")}</p>
                                                                    </div>

                                                                    <hr className="mb-2 mt-2" />

                                                                    <div className="d-flex justify-content-between align-items-center">
                                                                        <p className="text-secondary font-size-14 mb-0">{t('Rekla.Nr')}</p>
                                                                        <p className="text-primary font-size-14 mb-0">{'R-' + reclamation?.id}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                    ) : ''}
                                                </>
                                            ))}
                                        </>
                                    ) : ''
                                    }
                                </>
                            ))}


                            {data.invoices && data.invoices.map((invoice, invoice_serial) => (
                                <>
                                    <div className="timeline-block mb-3">
                                        <span className="timeline-step">
                                            <span className="brand-icon-bg"><i className="icon-sola-check-circle icon-white"></i></span>
                                        </span>
                                        <div className="timeline-content">
                                            <div className="d-flex justify-content-between align-items-center">
                                                <h6 className="text-dark font-size-14 font-weight-500 mb-0">{t('Invoice') + ' ' + JSON.parse(invoice?.product_details)?.amountPerchantage + '%'}</h6>
                                                <p className="text-secondary font-size-14  mb-0">{dateFormat(invoice.created_at, "dd.mm.yyyy")}</p>
                                            </div>

                                            <hr className="mb-2 mt-2" />

                                            <div className="d-flex justify-content-between align-items-center">
                                                <p className="text-secondary font-size-14 mb-0">{t('InvoiceNr.')}</p>
                                                <p className="text-primary font-size-14 mb-0">{invoice?.id}</p>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            ))}

                            {data.project.reclamations && data.project.reclamations.map((reclamation, index) => (
                                <>
                                    {reclamation?.department == 'Buchhaltung' ? (
                                        <>
                                            <div className="timeline-block mb-3">
                                                <span className="timeline-step">
                                                    <span className="brand-icon-bg"><i className="icon-sola-check-circle icon-white"></i></span>
                                                </span>
                                                <div className="timeline-content">
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <h6 className="text-dark font-size-14 font-weight-500 mb-0">{t('Reklamation')}</h6>
                                                        <p className="text-secondary font-size-14  mb-0">{dateFormat(reclamation.created_at, "dd.mm.yyyy")} {dateFormat(reclamation.created_at, "HH:ss")}</p>
                                                    </div>

                                                    <hr className="mb-2 mt-2" />

                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <p className="text-secondary font-size-14 mb-0">{t('Rekla.Nr')}</p>
                                                        <p className="text-primary font-size-14 mb-0">{reclamation?.id}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    ) : ''}
                                </>
                            ))}

                            {data.assign_orders && data.assign_orders.map((assign_order) => (
                                <>
                                    {assign_order.dachmontag_elektromontage == 'Elektromontage' ? (
                                        <div className="timeline-block mb-3">
                                            <span className="timeline-step">
                                                <span className="brand-icon-bg"><i className="icon-sola-check-circle icon-white"></i></span>
                                            </span>
                                            <div className="timeline-content">
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <h6 className="text-dark font-size-14 font-weight-500 mb-0">{assign_order.dachmontag_elektromontage}</h6>
                                                    <p className="text-secondary font-size-14  mb-0">{dateFormat(assign_order.created_at, "dd.mm.yyyy")} {dateFormat(assign_order.created_at, "HH:ss")}</p>
                                                </div>
                                            </div>
                                        </div>
                                    ) : ''
                                    }
                                </>
                            ))}

                            {data.project.reclamations && data.project.reclamations.map((reclamation, index) => (
                                <>
                                    {reclamation?.department == 'Elektromontage' ? (
                                        <div className="timeline-block mb-3">
                                            <span className="timeline-step">
                                                <span className="brand-icon-bg"><i className="icon-sola-check-circle icon-white"></i></span>
                                            </span>
                                            <div className="timeline-content">
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <h6 className="text-dark font-size-14 font-weight-500 mb-0">{t('Reklamation')}</h6>
                                                    <p className="text-secondary font-size-14  mb-0">{dateFormat(reclamation.created_at, "dd.mm.yyyy")} {dateFormat(reclamation.created_at, "HH:ss")}</p>
                                                </div>

                                                <hr className="mb-2 mt-2" />

                                                <div className="d-flex justify-content-between align-items-center">
                                                    <p className="text-secondary font-size-14 mb-0">{t('Rekla.Nr')}</p>
                                                    <p className="text-primary font-size-14 mb-0">{reclamation?.id}</p>
                                                </div>
                                            </div>
                                        </div>
                                    ) : ''}
                                </>
                            ))}

                            {data?.project?.fertigmeldung[0] ? (
                                <div className="timeline-block mb-3">
                                    <span className="timeline-step">
                                        <span className="brand-icon-bg"><i className="icon-sola-check-circle icon-white"></i></span>
                                    </span>
                                    <div className="timeline-content">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <h6 className="text-dark font-size-14 font-weight-500 mb-0">{t('Fertigmeldung')}</h6>
                                            <p className="text-secondary font-size-14  mb-0">{dateFormat(data?.project?.fertigmeldung[0].created_at, "dd.mm.yyyy")}</p>
                                        </div>
                                    </div>
                                </div>
                            ) : ''}

                        </div>
                    </div>
                </div>
            ) : <></>}
        </>
    )
}

export default TimelineNew;