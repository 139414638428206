import { React, useEffect, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { Navigate, useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import callFetch from "../../../helpers/callFetch"; 
import MiniStatisticsCard from "./Components/EEC/MiniStatisticsCard"; 

// @mui material components
import Grid from "@mui/material/Grid"; 
// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox"; 
import breakpoints from "assets/theme/base/breakpoints"; 
import TableEstimatedProductionConsumption from "./Components/EEC/TableEstimatedProductionConsumption"; 
import VideoComponent from "./Components/EEC/Video"; 
import EstimatedProduction from "./Components/EEC/EstimatedProduction";
import EstimatedProductionTwo from "./Components/EEC/EstimatedProductionTwo";
import EstimatedProductionThree from "./Components/EEC/EstimatedProductionThreeLineChart";
import { NumericFormat } from 'react-number-format'; 
import ProfitCalculatonCreate from './ProfitCalculation/Create';
import EmailForm from "./EmailForm";
function Wirtschaftlichkeitsberechnung(props) {
    const { t } = useTranslation();
    const [create, setCreate] = useState(false);
    const [resaleAngebot, setResaleAngebot] = useState(false);
    const addNewAngebot = () => {
        //console.log('addNewAngebot')
        setCreate(true);
        setResaleAngebot(true);
        setTimeout(() => {
            document.getElementById('editdstBtn').click();
        }, 1000)
    }

    const [refreshKey, setRefreshKey] = useState(0);

    // Function to trigger refresh
    const handleUpdate = () => {
        setRefreshKey((prevKey) => prevKey + 1); // Increment the key to force refresh
    };

    const {
        register,
        handleSubmit,
        setError,
        setValue,
        getValues,
        reset,
        formState: { errors },
    } = useForm();
    const [tabsOrientation, setTabsOrientation] = useState("horizontal");
    const [tabValue, setTabValue] = useState(1);
    let params = useParams();
    const [data, setData] = useState([]);
    const [profit_data, setProfitData] = useState([]);
    const [annual_output, setAnnualOutput] = useState(0);
    const [feedInTariff, setFeedInTariff] = useState(0);
    const [saving, setSaving] = useState(0);
    const [errorSB, setErrorSB] = useState(false);
    const [selectedQuationId, setSelectedQuationId] = useState('');
    const [quationRefresh, setQuationRefresh] = useState(0);
    const [refresh2, setRefresh2] = useState(0);
    const primaryAngebot = (primary, quation_id) => {
        var formData = {};
        formData.primary = (primary ? '1' : '0');
        formData.id = quation_id;
        formData.project_id = (props?.projectid ? props?.projectid : params.id);

        callFetch('primary-angebot', "POST", formData, setError).then((res) => {

        });
    }

    const editAngebot = (id) => {
        setSelectedQuationId(id);
        setCreate(false);
        setQuationRefresh(quationRefresh + 1);
    }

    useEffect(() => {
        // A function that sets the orientation state of the tabs.
        function handleTabsOrientation() {
        return window.innerWidth < breakpoints.values.sm
            ? setTabsOrientation("vertical")
            : setTabsOrientation("horizontal");
        }

        /** 
         The event listener that's calling the handleTabsOrientation function when resizing the window.
        */
        window.addEventListener("resize", handleTabsOrientation);

        // Call the handleTabsOrientation function to set the state with the initial value.
        handleTabsOrientation();

        // Remove event listener on cleanup
        return () => window.removeEventListener("resize", handleTabsOrientation);
    }, [tabsOrientation]);

 


    useEffect(() => {

        if(props?.isOpen){
                callFetch("economic-efficiency-calculation/" + (props?.projectid ? props?.projectid : params.id), "GET", []).then((res) => {

                    var resData = res?.data || {}; 
                    resData.quation = resData.quation || {};
                    console.log(resData);
                    setData(resData);
                    setProfitData(res?.profit_calc_data);
                    // console.log(res?.profit_calc_data?.productItems);
                    console.log(res?.profit_calc_data?.moduleWR);
                    // let annual_output_calc = ((data?.quation?.anzahl_module * 405) / 1000) * 1000;
                    // setAnnualOutput(annual_output_calc);

                    // let feedInTariffCalc = (annual_output_calc * (data?.quation?.einspeiseverguetung_preis_percent / 100));
                    // setFeedInTariff(feedInTariffCalc);

                    // let savingCalc = (annual_output_calc * ((100-data?.quation?.einspeiseverguetung_preis_percent) / 100));
                    // setSaving(savingCalc);
                    
    
                    // console.log(resData);
                
                });

            
                callFetch("get-module-info"+'/'+params.id, "GET", []).then((res) => { 
                    // Log "test" if any of them are null
                    if (
                        res?.data?.einspeiseverguetung_preis == null || res?.data?.einspeiseverguetung_preis === 0 ||
                        res?.data?.einspeiseverguetung_preis_percent == null || res?.data?.einspeiseverguetung_preis_percent === 0 ||
                        res?.data?.kaufpreisEigenverbrauch == null || res?.data?.kaufpreisEigenverbrauch === 0 ||
                        res?.data?.kaufpreisEigenverbrauch_percent == null || res?.data?.kaufpreisEigenverbrauch_percent === 0
                    ) {
                        document.getElementById('profitModal').click();
                    }
                    
                });
            } 
        
    }, [props?.isOpen, props?.value]);
 

    const handleSetTabValue = (event, newValue) => {
        //console.log(newValue);
        setTabValue(newValue);
        if(newValue == 1){
            // document.getElementById('wirtschaftlichkeitsberechnung').click();
        }else{
            document.getElementById('distribution').click();
        }
    };


    return (
        <>
        <p data-bs-toggle="modal" data-bs-target="#staticBackdropddd" id="profitModal"></p>
        <div className="row"> 
        <div className={props?.fullwidth != false ? "col-lg-12" : 'col-12'} id="angebot-from-area">
        
        <div className="card box-shadow-none bg-secondary">
            <div className="card-body pb-3">
                <SoftBox mb={1}>
                <Grid container spacing={1}>
                    <Grid item xs={12} md={6} lg={4}>
                        <MiniStatisticsCard
                        bgColor="white"
                        title={{ text: "Module", color: "#4D586E" }}
                        count={data?.quation?.anzahl_module}
                        icon={{ color: 'dark', component: <i class="ph ph-solar-panel"></i> }}
                        direction="right"
                        unit="Stück"
                        />
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                        <MiniStatisticsCard
                        bgColor="white"
                        title={{ text: "Speicher", color: "#4D586E" }}
                        count={data?.speicher}
                        icon={{ color: 'dark', component: <i class="ph ph-memory"></i> }}
                        direction="right"
                        unit="Stück"
                        />
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                        <MiniStatisticsCard
                        bgColor="white"
                        title={{ text: "Speicherkapazität", color: "#4D586E" }}
                        count={data?.speicher_watt}
                        icon={{ color: 'dark', component: <i class="ph ph-battery-full"></i> }}
                        direction="right"
                        unit="Kwh"
                        />
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                        <MiniStatisticsCard
                        bgColor="white"
                        title={{ text: "Wechselrichter", color: "#4D586E" }}
                        count={data?.wechselrichter}
                        icon={{ color: "dark", component: <i class="ph ph-car-battery"></i> }}
                        direction="right"
                        unit="Stück"
                        />
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                        <MiniStatisticsCard
                        bgColor="white"
                        title={{ text: "DC-Nennleistung", color: "#4D586E" }}
                        count={(<NumericFormat
                            value={Number(data?.module_watt)}
                            displayType="text"
                            thousandSeparator={"."}
                            decimalSeparator=","
                            decimalScale={2}
                            fixedDecimalScale
                            suffix={''}
                        />)}
                        icon={{ color: "dark", component:<i class="ph ph-lightning"></i> }}
                        direction="right"
                        unit="kWp"
                        />
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                        <MiniStatisticsCard
                        bgColor="white"
                        title={{ text: "AC-Leistung", color: "#4D586E" }}
                        count="15"
                        icon={{ color: "dark", component: <i class="ph ph-plug-charging"></i> }}
                        direction="right"
                        unit="kWe"
                        />
                    </Grid>
                </Grid>
            </SoftBox>

            {/* <SoftBox mt={5}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={12} lg={12}>
                        <ProductImages />
                    </Grid>
                </Grid>
            </SoftBox> */}
        
            <SoftBox mt={5} className="eec-video-right-text">
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6} lg={6}>
                        <VideoComponent></VideoComponent>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={12} lg={12}>
                                <div>
                                    <p className="font-size-16 font-wieght-500 text-primary">Modulleistung</p>
                                    <span className="text-primary font-size-14 font-weight-500 border-radius-4 pt-1 pb-1 ps-2 pe-2 bg-border me-2">{data?.module_watt} kWp</span>
                                    <span className="text-primary font-size-14 font-weight-500 border-radius-4 pt-1 pb-1 ps-2 pe-2 bg-border">{data?.Modulleistung_von_watt} Watt</span> 
                                </div> 

                                <div className="mt-4">
                                    <p className="font-size-16 font-wieght-500 text-primary">Über die Lebensdauer der Anlage</p>
                                    <div className="d-flex justify-content-between">
                                        <p className="text-secondary"> <i class="ph ph-car font-size-20"></i> Vermiedene Auto Km</p>
                                        <p className="text-primary font-weight-500">
                                        <NumericFormat
                                                    value={Number(data?.vermiedene_auto)}
                                                    displayType="text"
                                                    thousandSeparator={"."}
                                                    decimalSeparator=","
                                                    decimalScale={2}
                                                    fixedDecimalScale
                                                    suffix={''}
                                                />
                                        </p>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <p className="text-secondary"> <i class="ph ph-tree font-size-20"></i> Gepflanzte Bäume</p>
                                        <p className="text-primary font-weight-500">
                                        <NumericFormat
                                                    value={Number(data?.gepflanzte)}
                                                    displayType="text"
                                                    thousandSeparator={"."}
                                                    decimalSeparator=","
                                                    decimalScale={2}
                                                    fixedDecimalScale
                                                    suffix={''}
                                                />
                                        </p>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <p className="text-secondary"> <i class="ph ph-airplane font-size-20"></i> Vermiedene Langstreckenflüge</p>
                                        <p className="text-primary font-weight-500">
                                        <NumericFormat
                                                    value={Number(data?.langstreckenfluge)}
                                                    displayType="text"
                                                    thousandSeparator={"."}
                                                    decimalSeparator=","
                                                    decimalScale={2}
                                                    fixedDecimalScale
                                                    suffix={''}
                                                />
                                        </p>
                                    </div>
                                </div>
                            </Grid> 
                        </Grid>
                    </Grid>
                </Grid>
            </SoftBox>
            </div>
        </div>

        <SoftBox mt={5} mb={5}>
            <div className="bg-secondary p-3 border-radius-8">
                <p className="font-size-20 font-weight-600">Wirtschaftlichkeitsberechnung für Ihre Anlage</p>
                <p data-bs-toggle="modal" data-bs-target="#staticBackdropddd" className="font-size-14 font-weight-600 text-brand-primary">{t('Datenerfassung für die Wirtschaftlichkeitsberechnunung')}</p>
                <ul className="wir-data-list">
                    <li>
                        <div className="d-flex justify-content-between">
                            <p className="text-primary font-size-14">Modultyp</p>
                            <p className="text-primary font-size-14">{profit_data?.moduleWR?.name}</p>
                        </div>
                    </li>
                    <li>
                        <div className="d-flex justify-content-between">
                            <p className="text-primary font-size-14">Anzhl - Module</p>
                            <p className="text-primary font-size-14">{data?.quation?.anzahl_module}</p>
                        </div>
                    </li>
                    <li>
                        <div className="d-flex justify-content-between">
                            <p className="text-primary font-size-14">Gesamtleistung</p>
                            <p className="text-primary font-size-14">{data?.module_watt + ' kwp'}</p>
                        </div>
                    </li>
                    <li>
                        <div className="d-flex justify-content-between">
                            <p className="text-primary font-size-14">In Simulation ermittelte kWh/KWp p.a.</p>
                            <p className="text-primary font-size-14">{data?.quation?.sonnenstunden}</p>
                            {/* <p className="text-primary font-size-14">{data?.quation?.sonnenstunden}</p> */}
                        </div>
                    </li> 
                    <li>
                        <div className="d-flex justify-content-between">
                            <p className="text-primary font-size-14">Ergibt Jahresleistung der Anlage in kWh (nach gangiger Simulationssoftware)</p>
                            <p className="text-primary font-size-14">{(<NumericFormat
                                    value={profit_data?.annual_output_calc}
                                    displayType="text"
                                    // thousandSeparator={"."}
                                    decimalSeparator="."
                                    decimalScale={2}
                                    fixedDecimalScale
                                    suffix={' kWh'}
                            />)}</p>
                        </div>
                    </li>
                </ul>
            </div>

            <div className="bg-secondary p-3 border-radius-8 mt-2">
                <p className="font-size-20 font-weight-600">In Simulation ermittelte Einspeiseverqutung/Ersparnis (Eigenverbrauch berücksichtigt)</p>
                <ul className="wir-data-list">
                    <li>
                        <div className="d-flex justify-content-between">
                            <p className="text-primary font-size-14">Über 10 kW bis 40 kW</p>
                            <p className="text-primary font-size-14">{(<NumericFormat
                            value={profit_data?.feedInTariffCalc}
                            displayType="text"
                            // thousandSeparator={"."}
                            decimalSeparator="."
                            decimalScale={2}
                            fixedDecimalScale
                            suffix={' kWh'}
                    />)}</p>
                        </div>
                    </li>
                    <li>
                        <div className="d-flex justify-content-between">
                            <p className="text-primary font-size-14"></p>
                            <p className="text-primary font-size-14">
                                {data?.quation?.einspeiseverguetung_preis+' €/kWh'}
                            </p>
                        </div>
                    </li>
                    <li className="bg-secondary">
                        <div className="d-flex justify-content-between">
                            <p className="text-primary font-size-14">kWh nach EEG</p>
                            <p className="text-primary font-size-14 font-weight-500">
                                {(<NumericFormat
                                        value={profit_data?.eeg}
                                        displayType="text"
                                        thousandSeparator={"."}
                                        decimalSeparator=","
                                        decimalScale={2}
                                        fixedDecimalScale
                                        suffix={' €'}
                                />)}
                            </p>
                        </div>
                    </li>
                </ul>

                <ul className="wir-data-list mt-2">
                    <li>
                        <div className="d-flex justify-content-between">
                            <p className="text-primary font-size-14">Ersparnis aus Eigenverbrauch</p>
                            <p className="text-primary font-size-14">{(<NumericFormat
                            value={profit_data?.savingCalc}
                            displayType="text"
                            // thousandSeparator={"."}
                            decimalSeparator="."
                            decimalScale={2}
                            fixedDecimalScale
                            suffix={' kWh'}
                    />)} </p>
                        </div>
                    </li>
                    <li>
                        <div className="d-flex justify-content-between">
                            <p className="text-primary font-size-14"></p>
                            <p className="text-primary font-size-14">
                                 {data?.quation?.kaufpreisEigenverbrauch+ ' €/kWh'}
                            </p>
                        </div>
                    </li>
                    <li className="bg-secondary">
                        <div className="d-flex justify-content-between">
                            <p className="text-primary font-size-14"> kWh / Strompreis inkl. Grundpreis</p>
                            <p className="text-primary font-size-14 font-weight-500">
                                 {(<NumericFormat
                            value={profit_data?.grundpries}
                            displayType="text"
                            thousandSeparator={"."}
                            decimalSeparator=","
                            decimalScale={2}
                            fixedDecimalScale
                            suffix={' €'}
                    />)}
                            </p>
                        </div>
                    </li>
                </ul>

                <ul className="wir-data-list mt-2">
                    <li>
                        <div className="d-flex justify-content-between">
                            <p className="text-primary font-size-14">Einspeisevergütung + Ersparnis jährlich gesamt</p>
                            <p className="text-primary font-size-14">
                                {(<NumericFormat
                                    value={profit_data?.feed_in_tariff_annual_saving_total}
                                    displayType="text"
                                    thousandSeparator={"."}
                                    decimalSeparator=","
                                    decimalScale={2}
                                    fixedDecimalScale
                                    suffix={' €'}
                                />)}
                            </p>
                        </div>
                    </li>
                    <li>
                        <div className="d-flex justify-content-between">
                            <p className="text-primary font-size-14">Einspeisevergütung + Ersparnis monatlich gesamt</p>
                            <p className="text-primary font-size-14">
                                {(<NumericFormat
                                    value={profit_data?.feed_in_tariff_annual_saving_total_monthly}
                                    displayType="text"
                                    thousandSeparator={"."}
                                    decimalSeparator=","
                                    decimalScale={2}
                                    fixedDecimalScale
                                    suffix={' €'}
                                />)}
                            </p>
                        </div>
                    </li> 
                </ul>

                <ul className="wir-data-list mt-2">
                    <li className="bg-secondary">
                        <div className="d-flex justify-content-between">
                            <p className="text-primary font-size-14">Komplettpreis für anschlussfertige Photovoltaik-Anlage</p>
                            <p className="text-primary font-size-14 font-weight-500">
                                {(<NumericFormat
                                    value={Number(profit_data?.subTotal)}
                                    displayType="text"
                                    thousandSeparator={"."}
                                    decimalSeparator=","
                                    decimalScale={2}
                                    fixedDecimalScale
                                    suffix={' €'}
                                />)}
                            </p>
                        </div>
                    </li>
                    <li>
                        <div className="d-flex justify-content-between">
                            <p className="text-primary font-size-14">Mehrwertsteuer, {profit_data?.ust_position}% (kann vom Finanzamt erstattet werden)</p>
                            <p className="text-primary font-size-14">
                                {(<NumericFormat
                                    value={Number(profit_data?.ust_position_amount)}
                                    displayType="text"
                                    thousandSeparator={"."}
                                    decimalSeparator=","
                                    decimalScale={2}
                                    fixedDecimalScale
                                    suffix={' €'}
                                />)}
                            </p>
                        </div>
                    </li>
                    <li className="bg-secondary">
                        <div className="d-flex justify-content-between">
                            <p className="text-primary font-size-14">Gesamtsumme Brutto</p>
                            <p className="text-primary font-size-14 font-weight-500">
                                {(<NumericFormat
                                    value={Number(profit_data?.subTotal)}
                                    displayType="text"
                                    thousandSeparator={"."}
                                    decimalSeparator=","
                                    decimalScale={2}
                                    fixedDecimalScale
                                    suffix={' €'}
                                />)}
                            </p>
                        </div>
                    </li>
                </ul>

                <ul className="wir-data-list mt-2">
                    <li>
                        <div className="d-flex justify-content-between">
                            <p className="text-primary font-size-14">Einspeisertrag (netto) pro Jahr lt. Simulation</p>
                            <p className="text-primary font-size-14">
                                {(<NumericFormat
                                    value={profit_data?.feed_in_tariff_annual_saving_total}
                                    displayType="text"
                                    thousandSeparator={"."}
                                    decimalSeparator=","
                                    decimalScale={2}
                                    fixedDecimalScale
                                    suffix={' €'}
                                />)}
                            </p>
                        </div>
                    </li>
                    <li>
                        <div className="d-flex justify-content-between">
                            <p className="text-primary font-size-14">kWh Netzbezug pro Jahr in kWh</p>
                            <p className="text-primary font-size-14">
                                {(<NumericFormat
                                    value={profit_data?.grid_consumption_per_year}
                                    displayType="text"
                                    thousandSeparator={"."}
                                    decimalSeparator=","
                                    decimalScale={2}
                                    fixedDecimalScale
                                    suffix={' €'}
                                />)}
                            </p>
                        </div>
                    </li>
                    <li className="bg-secondary">
                        <div className="d-flex justify-content-between">
                            <p className="text-primary font-size-14">Anlagen-Ertrag p.a. 1</p>
                            <p className="text-primary font-size-14 font-weight-500">
                                {(<NumericFormat
                                    value={profit_data?.investment_yield}
                                    displayType="text"
                                    thousandSeparator={"."}
                                    decimalSeparator=","
                                    decimalScale={2}
                                    fixedDecimalScale
                                    suffix={' €'}
                                />)}
                            </p>
                        </div>
                    </li>
                </ul> 

            </div>

            <div className="bg-secondary p-3 border-radius-8 mt-2">
                <p className="font-size-20 font-weight-600">Ertrag im Verhältnis zur Nettoinvestition</p>
                <ul className="wir-data-list">
                    <li>
                        <div className="d-flex justify-content-between">
                            <p className="text-primary font-size-14">Das entspricht einem Ertragsverhältnis zur Nettoinvestition von</p>
                            <p className="text-primary font-size-14">
                                {(<NumericFormat
                                    value={profit_data?.roi}
                                    displayType="text"
                                    thousandSeparator={"."}
                                    decimalSeparator=","
                                    decimalScale={2}
                                    fixedDecimalScale
                                    suffix={' %'}
                                />)}
                            </p>
                        </div>
                    </li>
                    <li>
                        <div className="d-flex justify-content-between">
                            <p className="text-primary font-size-14">
                                Armotisierung der Anlage in Jahren
                            </p>
                            <p className="text-primary font-size-14">
                                {(<NumericFormat
                                    value={profit_data?.plant_in_years}
                                    displayType="text"
                                    thousandSeparator={"."}
                                    decimalSeparator=","
                                    decimalScale={2}
                                    fixedDecimalScale
                                    suffix={' Jahre'}
                                />)}
                            </p>
                        </div>
                    </li>
                    <li>
                        <div className="d-flex justify-content-between">
                            <p className="text-primary font-size-14">Geförderter Zeitraum (zzgl. Der Monate des Jahres der Inbetriebnahme)</p>
                            <p className="text-primary font-size-14">20 Jahre</p>
                        </div>
                    </li>
                    <li className="bg-success-light">
                        <div className="d-flex justify-content-between">
                            <p className="text-primary font-size-14">Einspeisevergütung und Ersparnis (3% Steigerung)  
                            über 20 Jahre Förderzeitraum</p>
                            <p className="text-primary font-size-14 font-weight-500">
                            {(<NumericFormat
                            value={profit_data?.feed_in_tariff_and_savings}
                            displayType="text"
                            thousandSeparator={"."}
                            decimalSeparator=","
                            decimalScale={2}
                            fixedDecimalScale
                            suffix={' €'}
                    />)}
                            </p>
                        </div>
                    </li>
                </ul>
            </div>


            <div className="bg-secondary p-3 border-radius-8 mt-2">
                <p className="font-size-20 font-weight-600">Geschätzte Produktion und Verbrauch</p>
                <Grid container spacing={1}>
                    <Grid item xs={12} md={6} lg={4}>
                        <MiniStatisticsCard
                        bgColor="white"
                        title={{ text: "Jährläche Energieproduktion", color: "#4D586E" }}
                        count={<NumericFormat value={Number(data?.roof_direction_module_efficiency)} displayType="text" thousandSeparator={"."} decimalSeparator="," decimalScale={2} fixedDecimalScale suffix={''}/>}
                        icon={{ color: 'dark', component: <i class="ph ph-solar-panel"></i> }}
                        direction="right"
                        unit="Stück"
                        />
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                        <MiniStatisticsCard
                        bgColor="white"
                        title={{ text: "Jährlicher Stromverbrauch", color: "#4D586E" }}
                        count={<NumericFormat value={Number(data?.quation?.eigenverbrauch)} displayType="text" thousandSeparator={"."} decimalSeparator="," decimalScale={2} fixedDecimalScale suffix={''}/>}
                        icon={{ color: 'dark', component: <i class="ph ph-plug-charging"></i> }}
                        direction="right"
                        unit="Stück"
                        />
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                        <MiniStatisticsCard
                        bgColor="white"
                        title={{ text: "Jährlicher Eigenverbauch", color: "#4D586E" }}
                        count={<NumericFormat value={Number(data?.quation?.own_consumption)} displayType="text" thousandSeparator={"."} decimalSeparator="," decimalScale={2} fixedDecimalScale suffix={''}/>}
                        icon={{ color: 'dark', component: <i class="ph ph-plugs-connected"></i> }}
                        direction="right"
                        unit="Stück"
                        />
                    </Grid>
                </Grid>
                <div className="mt-2 bg-white">
                    <TableEstimatedProductionConsumption isOpen={props?.isOpen} onUpdate={handleUpdate} value={props?.value}></TableEstimatedProductionConsumption>
                </div>
                <div className="mt-2 bg-white border-radius-8 p-3 border-primary">
                    <EstimatedProduction isOpen={props?.isOpen} refreshKey={refreshKey} value={props?.value}></EstimatedProduction>
                </div>
            </div>

            <div className="bg-secondary p-3 border-radius-8 mt-2">
                <p className="font-size-20 font-weight-600">Geschätzte Produktion und Verbrauch</p>
                <Grid container spacing={1}>
                    <Grid item xs={12} md={6} lg={4}>
                        <MiniStatisticsCard
                        bgColor="white"
                        title={{ text: "Jährliche Eigen...", color: "#4D586E" }}
                        count={<NumericFormat value={Number(data?.quation?.own_consumption)} displayType="text" thousandSeparator={"."} decimalSeparator="," decimalScale={2} fixedDecimalScale suffix={''}/>}
                        icon={{ color: 'dark', component: <i class="ph ph-plugs-connected"></i> }}
                        direction="right"
                        unit="Stück"
                        />
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                        <MiniStatisticsCard
                        bgColor="white"
                        title={{ text: "Notstrom-Zeit", color: "#4D586E" }}
                        count={data?.speicher_watt}
                        icon={{ color: 'dark', component: <i class="ph ph-battery-charging"></i> }}
                        direction="right"
                        unit="Stück"
                        />
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                        <MiniStatisticsCard
                        bgColor="white"
                        title={{ text: "Notstrom-Kapa...", color: "#4D586E" }}
                        count={data?.speicher_watt_clc}
                        icon={{ color: 'dark', component: <i class="ph ph-lightning"></i> }}
                        direction="right"
                        unit="Stück"
                        />
                    </Grid>
                </Grid>
                <div className="mt-2 bg-white border-radius-8 p-3 border-primary">
                    <EstimatedProductionTwo isOpen={props?.isOpen} value={props?.value}></EstimatedProductionTwo>   
                </div>
                <div className="mt-2 bg-white border-radius-8 p-3 border-primary">
                    <EstimatedProductionThree isOpen={props?.isOpen} value={props?.value}></EstimatedProductionThree>          
                </div>
            </div>
        </SoftBox> 
        {/* <SoftBox mt={5}>
            <div className="card p-3">
                <div className="card-body">
                    <SoftTypography variant="h4">Einspeisungen und Amortisation</SoftTypography>
                    <div className="row mt-4">
                        <div className="col-sm-4">
                            <div className="card" style={{boxShadow: 'none', border: '1px dashed #ddd'}}>
                                <div className="card-body text-center">
                                    <SoftTypography variant="caption" className="consumption-text" style={{color: '#208DFC', fontSize: '20px', fontWeight: 'bold'}}>20 Jahre Einnahmen</SoftTypography> 
                                    <br/>
                                    <br/>
                                    <SoftTypography variant="caption" fontWeight="bold" style={{fontSize: '24px', fontWeight: 'bold'}}>8.007 kWh</SoftTypography>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-4">
                        <div className="card" style={{boxShadow: 'none', border: '1px dashed #ddd'}}>
                                <div className="card-body text-center">
                                    <SoftTypography variant="caption" className="consumption-text" style={{color: '#208DFC', fontSize: '20px', fontWeight: 'bold'}}>  Amortisation des Solarsystems
  </SoftTypography> 
                                    <br/>
                                    <br/>
                                    <SoftTypography variant="caption" fontWeight="bold" style={{fontSize: '24px', fontWeight: 'bold'}}>15 Jahre, 11 Monate</SoftTypography>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-4">
                        <div className="card" style={{boxShadow: 'none', border: '1px dashed #ddd'}}>
                                <div className="card-body text-center">
                                    <SoftTypography variant="caption" className="consumption-text" style={{color: '#208DFC', fontSize: '20px', fontWeight: 'bold'}}>  Deckungsrate meiner Rechnung durch PV
  </SoftTypography> 
                                    <br/>
                                    <br/>
                                    <SoftTypography variant="caption" fontWeight="bold" style={{fontSize: '24px', fontWeight: 'bold'}}>100
                                    %</SoftTypography>
                                </div>
                            </div>
                        </div>
                    </div>

                    <br/>
                    <br/>
                    <div className="mt-5"></div>
                    <SoftTypography variant="h5" fontWeight="bold" fontSize="1.5rem">In Simulation ermittelte Einspeiseverqutung/Ersparnis (Eigenverbrauch berücksichtigt):</SoftTypography>
                    <div className="row mt-5 gx-8">
                        <div className="col-sm-6">
                            <div className="mt-3 d-flex justify-content-between">
                                <SoftTypography variant="h6" fontWeight="bold" fontSize="1.25rem">Über 10 kW bis 40 kW:</SoftTypography>
                                <div>
                                    <EECButton text="250 kWh" width="120px" padding="0px" bgColor="#208DFC"></EECButton> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <EECButton text="250 kWh" width="120px" padding="0px" bgColor="#718096"></EECButton>
                                </div>
                            </div>
                            <div className="mt-3 d-flex justify-content-between">
                                <SoftTypography variant="h6" fontWeight="bold" fontSize="1.25rem">Ersparnis aus Eigenverbrauch</SoftTypography>
                                <div>
                                    <EECButton text="14.000 €" width="120px" padding="0px" bgColor="#208DFC"></EECButton> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <EECButton text="14.000 €" width="120px" padding="0px" bgColor="#718096"></EECButton>
                                </div>
                            </div>
                            <div className="mt-3 d-flex justify-content-between">
                                <SoftTypography variant="h6" fontWeight="bold" fontSize="1.25rem">Ersparnis aus ihrem Cloudsystem</SoftTypography>
                                <div>
                                    <EECButton text="14.000 €" width="120px" padding="0px" bgColor="#208DFC"></EECButton> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <EECButton text="14.000 €" width="120px" padding="0px" bgColor="#718096"></EECButton>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="mt-3 d-flex justify-content-between">
                                <SoftTypography variant="h6" fontWeight="bold" fontSize="1.25rem">kWh nach EEG:</SoftTypography>
                                <EECButton text="15,75 €" width="100px" padding="0px" bgColor="#208DFC"></EECButton>
                            </div>
                            <div className="mt-3 d-flex justify-content-between">
                                <SoftTypography variant="h6" fontWeight="bold" fontSize="1.25rem">kWh / Strompreis inkl. Grundpreis:</SoftTypography>
                                <EECButton text="4.000,11 €" width="100px" padding="0px" bgColor="#208DFC"></EECButton>
                            </div>
                            <div className="mt-3 d-flex justify-content-between">
                                <SoftTypography variant="h6" fontWeight="bold" fontSize="1.25rem">kWh / Strompreis inkl. Grundpreis:</SoftTypography>
                                <EECButton text="6.000,33 €" width="100px" padding="0px" bgColor="#208DFC"></EECButton>
                            </div>
                        </div>
                    </div> 

                    <div className="row mt-5">
                        <div className="col-sm-12">
                            <TableEstimatedProductionConsumption></TableEstimatedProductionConsumption>
                        </div>
                    </div>
                    
                    <div className="row mt-5">
                        <div className="col-sm-12">
                            <ThinBarChart title="Consumption per day" chart={thinBarChartData} />
                        </div>
                    </div> 
                </div>
            </div>
        </SoftBox> */}

         </div>  
        </div> 
            {/* <ProfitCalculatonCreate></ProfitCalculatonCreate> */}
        </>
      );
}

export default Wirtschaftlichkeitsberechnung
