/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import { NavLink } from "react-router-dom";

function Transaction({ color, icon, name, description, value, link, linkText, data }) {

  return (
    <SoftBox key={name} component="li" style={{padding: '12px', borderRadius: '8px', position: 'relative'}} mb={1} className="bg-secondary">
      <SoftBox display="flex" justifyContent="space-between" alignItems="center">

        <SoftBox display="flex" alignItems="center">
          <SoftBox mr={2}>
            <div className="invoice-txn-icon border-primary">{icon}</div>
          </SoftBox>
          <SoftBox display="flex" flexDirection="column">
            <SoftTypography variant="button" fontWeight="medium" gutterBottom sx={{ fontSize: '0.875rem', overflow: 'hidden', maxWidth: '28ch', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}>
              {link ? (
                <>
                  <NavLink className={'text-link-brand text-underline'} to={link}>{linkText ? linkText : data?.id ? data?.id : ''}</NavLink>&nbsp;
                </>
              ) : ''}
              <span className="text-primary">{name}</span> 
            </SoftTypography>
            <SoftTypography variant="caption" className="font-size-14 text-secondary">
              {description}
            </SoftTypography>
          </SoftBox>
        </SoftBox>
        <SoftTypography  variant="button" fontSize="0.875rem" className={color + ' position-absolute right-10 bottom-10'} fontWeight="medium">
          {value}
        </SoftTypography>
      </SoftBox>
    </SoftBox>
  );
}

// Typechecking props of the Transaction
Transaction.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ]).isRequired,
  icon: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

export default Transaction;
