import { React, useEffect, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from 'react-i18next';
import callFetch from "../../../../../helpers/callFetch";
import flatpickr from "flatpickr";
import SoftSnackbar from "components/SoftSnackbar";
import Cookies from 'js-cookie';
import dateFormat from "dateformat";
// @mui material components
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
function LeadDetails(props) {
    const { t } = useTranslation();
    const [refesh3, setRefesh3] = useState(0);
    const [currentStage, setCurrentStage] = useState(0);
    const [successSB, setSuccessSB] = useState(false);
    const [errorSB, setErrorSB] = useState(false);
    const [menu, setMenu] = useState(null);
    const openMenu = (event) => setMenu(event.currentTarget);
    const closeMenu = () => setMenu(null);
    const closeErrorSB = () => setErrorSB(false);

    function updateStage(stage) {
        const formData = new FormData();
        formData.stage = stage;
        formData.id = props.id;
        callFetch("update-lifecycle-stage", "POST", formData, setError).then((res) => {
            setCurrentStage(res?.stage);
            setSuccessSB(true);
        });
    }

    useEffect(() => {
        setCurrentStage(props.stage);
    }, [props.stage]);

    const renderSuccessSB = (
        <SoftSnackbar
            color="success"
            icon="check"
            title={t('Success')}
            //content={t("Changes saved successfully")}
            dateTime={t("")}
            open={successSB}
            onClose={() => setSuccessSB(false)}
            close={() => setSuccessSB(false)}
            bgSuccess
        />
    );

    const renderErrorSB = (
        <SoftSnackbar
            color="error"
            icon="info"
            title={t('Nachricht, Notiz oder Info dürfen nicht leer sein')}
            //content={t("Changes saved successfully")}
            dateTime={t("")}
            open={errorSB}
            onClose={() => closeErrorSB(false)}
            close={() => closeErrorSB(false)}
            bgSuccess
        />
    );

    const {
        register,
        handleSubmit,
        setError,
        setValue,
        getValues,
        formState: { errors },
    } = useForm();


    return <> 

        <div className="mt-4 project-life-cycle-stage">
            <div className="d-flex justify-content-between align-items-center">
                <p className="font-size-14 text-secondary mb-0">
                    {t('Lyfescyle Stage')}
                </p>

                <div className="dropdown">
                <button
                    className="btn border dropdown-toggle bg-white font-weight-400"
                    type="button"
                    id="actionsDropdown"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                >
                    <span className="dropdown-btn-text text-primary font-weight-400">
                    {currentStage == 1 ? 'Neukunde'
                        : currentStage == 2 ? 'Kontaktiert'
                        : currentStage == 3 ? 'Interessiert'
                        : currentStage == 4 ? 'Qualifiziert'
                        : currentStage == 5 ? 'Angebot'
                        : currentStage == 6 ? 'Gewinn'
                        : currentStage == 7 ? 'Verlust'
                            : 'Neukunde'}
                    </span>
                </button>
                <ul className="dropdown-menu" aria-labelledby="actionsDropdown">
                    <li><a href="#" class="dropdown-item" onClick={() => {updateStage('2')}}>Kontaktiert</a></li>
                    <li><a href="#" class="dropdown-item" onClick={() => {updateStage('3')}}>Interessiert</a></li>
                    <li><a href="#" class="dropdown-item" onClick={() => {updateStage('4')}}>Qualifiziert</a></li>
                    <li><a href="#" class="dropdown-item" onClick={() => {updateStage('5')}}>Angebot</a></li>
                    <li><a href="#" class="dropdown-item" onClick={() => { updateStage('6')}}>Gewinn</a></li>
                    <li><a href="#" class="dropdown-item" onClick={() => {updateStage('7')}}>Verlust</a></li>
                </ul>
            </div>

                {/* <select
                    className="form-control-sm"
                    id="lyfecycleStage"
                    onChange={updateStage}
                >
                    <option value="Interessiert" >Interessiert</option>
                    <option value="Evaluation" selected="true">Evaluation</option>
                    <option value="Kauf">Kauf</option>
                    <option value="Verlust" selected="false">Verlust</option>
                    <option value="Abschluss">Abschluss</option>
                </select> */}
            </div>
            <div
                id="selectedStage"
                className="mb-1 font-size-20 text-primary font-weight-600"
            >
                {currentStage == 1 ? 'Neukunde'
                        : currentStage == 2 ? 'Kontaktiert'
                        : currentStage == 3 ? 'Interessiert'
                        : currentStage == 4 ? 'Qualifiziert'
                        : currentStage == 5 ? 'Angebot'
                        : currentStage == 6 ? 'Gewinn'
                        : currentStage == 7 ? 'Verlust'
                            : 'Neukunde'}
            </div>
            <div className="progress position-relative">
                <div className="progress-bar" role="progressbar" style={{ width: currentStage == 1 ? '15.28%' : currentStage == 2 ? '29.56%' : currentStage == 3 ? '43.84%' : currentStage == 4 ? '58.12%' : currentStage == 5 ? '73.4%' : currentStage == 6 ? '86.68%' : currentStage == 7 ? '100%' : '' }}></div>
                <div className="divider" style={{ left: '14.28%' }}></div>
                <div className="divider" style={{ left: '28.56%' }}></div>
                <div className="divider" style={{ left: '42.84%' }}></div>
                <div className="divider" style={{ left: '57.12%' }}></div>
                <div className="divider" style={{ left: '71.4%' }}></div>
                <div className="divider" style={{ left: '85.68%' }}></div>
                {/* <div className="marker" style={{ left: '33%' }}></div> */}
            </div>
        </div>


        {renderErrorSB}
        {renderSuccessSB}
    </>;
}

export default LeadDetails;
