import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Navigate, useParams } from "react-router-dom";
import { callFetch } from "../../../../helpers/callFetch";
import { useTranslation } from "react-i18next";
import dateFormat from "dateformat";
import flatpickr from "flatpickr";
import { NumericFormat } from "react-number-format";
import EmailForm from "./EmailFormInvoice";
import Invoice from "./Invoice";
import SoftAlert from "components/SoftAlert";
import Overview from "pages/project/Overview";
import Timeline from "../Timeline";
import SoftTypography from "components/SoftTypography";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import AllInvoices from "pages/finance/Invoice/IndexTable";
import AllPayments from "pages/finance/Payment/IndexTable";
import CreateInvoice from "pages/finance/Invoice/Create";
import IncomingInvoiceIndexTable from "pages/finance/EngangsInvoice/IncomingInvoiceIndexTable";
import Transactions from "pages/finance/EngangsInvoice/Transactions";

function Invoices(props) {
  let params = useParams();
  const { t } = useTranslation();
  const [saving, setSaving] = useState(false);
  const [update, setUpdate] = useState(0);
  const [adding, setAdding] = useState(false);
  const [data, setData] = useState([]);
  const [invoicePercenage, setInvoicePercenage] = useState([]);
  const [totalInvoicePercenage, setTotalInvoicePercenage] = useState(0);
  const [loadInv, setLoadInv] = useState(0);
  const [receivedError, setReceivedError] = useState(null);
  const [canCreateInvoice, setCanCreateInvoice] = useState(true);

  useEffect(() => {
    if (!params.id) return;
    callFetch("project/invoice/create/" + params.id, "GET", []).then((res) => {
      if (res?.data?.quations?.order?.id) {
        setData(res?.data);
      } else {
        toast(t("Fill Out Order"));
        document.getElementById("auftrag").click();
        return;
      }
    });
  }, [params.id]);

  /*
    useEffect(() => {
        if (!data.id) return;

        callFetch("get-invoices/" + data?.quations?.order?.id, "GET", []).then((res) => {
            setInvoices(res.invoices);
            setInvoicePercenage(res.invoicePercenage);
            setTotalInvoicePercenage(res.totalInvoicePercenage);
            setSaving(false);
        });
    }, [data, loadInv, update]);
    */

  /*
    const addInvoice = () => {
        setAdding(true);
        callFetch("add-invoice/" + data?.quations?.order?.id, "GET", []).then((res) => {
            setAdding(false);

            if (res.status == 'error') {
                setReceivedError(t(res.message));
            } else {
                setLoadInv(loadInv + 1);
            }
        });
    }
    */

  return (
    <>
      <div className="row">
        <div className="col-sm-12 col-md-12 col-lg-8 col-xl-8 col-xxl-9">
          <div className="card">
            <div className="card-body pb-3">
              <div
                className="accordion accordion-flush"
                id="accordionFlushExample"
              >
                <div className="accordion-item accordion-item bg-secondary p-3 border-radius-8">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseOne"
                      aria-expanded="false"
                      aria-controls="flush-collapseOne"
                    >
                      <span className="accordion-arrow">
                        <i className="ph ph-caret-right"></i>
                      </span>
                      <p className="font-size-20 font-weight-600 text-primary">
                        <span className="pl-2">Rechnung</span>
                      </p>
                    </button>
                  </h2>
                  <div
                    id="flush-collapseOne"
                    className="accordion-collapse collapse show"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                      <Overview
                        project_id={params?.id}
                        bg="white"
                        title={t("Invoice")}
                      />
                      <div className="row mt-3">
                        <div className="col-sm-6 mb-1">
                          <div className="card box-shadow-none">
                            <div className="card-body pb-3">
                              <p className="text-secondary font-size-14">
                                Angebotsnr
                              </p>
                              <hr />
                              <div className="d-flex justify-content-between align-items-center mb-1">
                                <p className="text-brand-primary font-size-14 mb-0 font-weight-600">
                                  3000005
                                </p>
                                <div>
                                  <span className="badge badge-success me-3">
                                    Angebot
                                  </span>
                                  <span className="text-secondary font-size-14 ml-3">
                                    14.11.2024
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6 mb-1">
                          <div className="card box-shadow-none">
                            <div className="card-body pb-3">
                              <div className="d-flex justify-content-between align-items-center">
                                <p className="text-secondary font-size-14 mb-0">
                                  {t("Projektnr.")}
                                </p>
                                <a className="text-brand-primary font-size-14">
                                  <i class="ph ph-info font-size-16 position-relative top-2"></i>{" "}
                                  Überblick
                                </a>
                              </div>
                              <hr />
                              <div className="d-flex justify-content-between align-items-center">
                                <p className="text-brand-primary font-size-14 mb-0 font-weight-600">
                                  {params.id}
                                </p>
                                <div>
                                  <span className="badge badge-success me-3">
                                    Project
                                  </span>
                                  <span className="text-secondary font-size-14 ml-3">
                                    14.11.2024
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="accordion accordion-flush mt-3"
                id="accordionFlushExample"
              >
                <div className="accordion-item accordion-item bg-secondary p-3 border-radius-8">
                  <h2 className="accordion-header">
                    <div className="d-flex justify-content-between align-content-center">
                      <div className="d-flex">
                        <button
                          className="accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseTwo"
                          aria-expanded="false"
                          aria-controls="flush-collapseTwo"
                        >
                          <span className="accordion-arrow">
                            <i className="ph ph-caret-right"></i>
                          </span>
                          <p className="font-size-20 font-weight-600 text-primary">
                            <span className="pl-2">All Rechnungen</span>
                          </p>
                        </button>
                      </div>
                      <div className="">
                        <button
                          type="button"
                          id="addBuchhaltung"
                          className="btn btn-primary m-0 float-start d-flex"
                          data-bs-toggle="modal"
                          data-bs-target="#createBuchhaltung"
                          disabled={canCreateInvoice ? false : true}
                        >
                          <i class="ph ph-plus me-2"></i> {t("Add Invoice")}
                        </button>
                      </div>
                    </div>
                  </h2>
                  <div
                    id="flush-collapseTwo"
                    className="accordion-collapse collapse show"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                      <div className="pt-3">
                        <AllInvoices
                          displayCreateBtn={true}
                          filterOptions={false}
                          canCreateInvoice={canCreateInvoice}
                          projectId={params?.id}
                          status={"all"}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="accordion accordion-flush mt-3"
                id="accordionFlushExample"
              >
                <div className="accordion-item accordion-item bg-secondary p-3 border-radius-8">
                  <h2 className="accordion-header">
                    <div className="d-flex justify-content-between align-content-center">
                      <div className="d-flex">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseThree"
                          aria-expanded="false"
                          aria-controls="flush-collapseThree"
                        >
                          <span className="accordion-arrow">
                            <i className="ph ph-caret-right"></i>
                          </span>
                          <p className="font-size-20 font-weight-600 text-primary">
                            <span className="pl-2">{t("All Payments")}</span>
                          </p>
                        </button>
                      </div>
                      <div className="">
                        <button
                          type="button"
                          className="btn btn-primary m-0 float-start d-flex"
                          onClick={() => {
                            document
                              .getElementById("createBuchhaltungPaymentBtn")
                              .click();
                          }}
                        >
                          <i class="ph ph-plus me-2"></i> {t("Add Payment")}
                        </button>
                      </div>
                    </div>
                  </h2>
                  <div
                    id="flush-collapseThree"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                      <div className="pt-3">
                        <AllPayments
                          canCreatePayment={true}
                          filterOptions={false}
                          projectId={params?.id}
                          filter={"All"}
                          editPopup={true}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="accordion accordion-flush mt-3"
                id="accordionFlushExample"
              >
                <div className="accordion-item accordion-item bg-secondary p-3 border-radius-8">
                  <h2 className="accordion-header">
                    <div className="d-flex justify-content-between align-content-center">
                      <div className="d-flex">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseFour"
                          aria-expanded="false"
                          aria-controls="flush-collapseFour"
                        >
                          <span className="accordion-arrow">
                            <i className="ph ph-caret-right"></i>
                          </span>
                          <p className="font-size-20 font-weight-600 text-primary">
                            <span className="pl-2">
                              {t("Eingangsrechnungen")}
                            </span>
                          </p>
                        </button>
                      </div>
                      <div>
                        <button
                          type="button"
                          className="btn btn-primary m-0 float-start d-flex"
                          data-bs-toggle="modal"
                          data-bs-target="#createIncommingInvoice"
                          onClick={() => {
                            //document.getElementById('createIncommingInvoiceBtn').click();
                          }}
                        >
                          <i class="ph ph-plus me-2"></i>{" "}
                          {t("Eingangsrechnungen Buchen")}
                        </button>
                      </div>
                    </div>
                  </h2>
                  <div
                    id="flush-collapseFour"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                      <div className="pt-3">
                        <IncomingInvoiceIndexTable
                          canCreate={true}
                          filterOptions={false}
                          projectId={params?.id}
                          filter={"All"}
                          editPopup={true}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-md-12 col-lg-4 col-xl-4 col-xxl-3 pl-4 project-details-right-side">
          <div className="card">
            <div className="card-body">
              <div class="details">
                <h6 className="text-primary font-size-16 font-weight-500">
                  Projektinformationen
                </h6>

                <div className="mb-2">
                  <p className="font-size-14 text-secondary mb-0">
                    <i className="icon-sola-user-circle-gear"></i>{" "}
                    <span className="position-relative bottom-4 left-4">
                      Lead Owner
                    </span>
                  </p>
                  <div class="profile">
                    <img
                      src="/assets/img/placeholder.png"
                      className="border-primary"
                      alt="Profile"
                    />
                    <span>Oliver Kahn</span>
                  </div>
                </div>
                <hr />
              </div>
              {params?.id ? <Transactions projectId={params?.id} /> : ""}

              <hr />

              <div className="pb-0">
                <h6>{t("Email")}</h6>
              </div>
              <div className="">
                <EmailForm
                  id={params.id}
                  pdf={true}
                  orderID={
                    data?.quations?.order?.id ? data?.quations?.order?.id : 0
                  }
                />
              </div>

              <Timeline refresh={loadInv} />
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade right-side"
        id="createBuchhaltung"
        tabindex="-1"
        role="dialog"
        aria-labelledby="createBuchhaltungLabel"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-slide-right modal-dialog-scrollable"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                id="cls-btn"
                data-bs-dismiss="modal"
                aria-label="Close"
                className=" lose btn btn-icon-only btn-rounded btn-link text-primary mb-0 me-3 btn-sm d-flex align-items-center justify-content-center"
                style={{ fontSize: "12px", position: "absolute", right: "0px" }}
              >
                {/* <i class="fa-sharp fa-solid fa-times icon icon-shape icon-sm  text-center d-flex align-items-center justify-content-center"></i> */}
                <i class="ph ph-x  text-center d-flex align-items-center justify-content-center text-primary font-size-20"></i>
              </button>
              <SoftTypography
                variant="button"
                fontSize="20px"
                fontWeight="600"
                style={{ color: "#273042" }}
              >
                {" "}
                {t("Add Payment")}
              </SoftTypography>
            </div>
            <CreateInvoice
              orderId={
                data?.quations?.order?.id ? data?.quations?.order?.id : 0
              }
              col="col-md-12"
              redirect={false}
              popupClose={true}
              invoiceListRefresh={true}
              ZahlungseingangeListRefresh={true}
              projektbezogene={false}
              canCreateInvoice={setCanCreateInvoice}
            />
            {/* </div> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default Invoices;
