// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Snackbar from "@mui/material/Snackbar";
import Fade from "@mui/material/Fade";


function SoftSnackbar({ color, icon, title, content, dateTime, close, ...rest }) {

  return (
    <Snackbar
      TransitionComponent={Fade}
      autoHideDuration={5000}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      {...rest}
    >

      <div
        className={`d-flex justify-content-between gap-2 p-3 border-radius notification-${color}`}
        style={{
          background: '#ffffff',
          boxShadow: '2px 4px 16px 0px #27304229',
          width: '440px'
        }}
      >
        <div className="d-flex justify-content-between gap-2">
          <i className={`notification-icon ph ph-${icon ? icon
            : color === 'success' ? 'check-circle'
              : color === 'info' ? 'info'
                : color === 'warning' ? 'warning'
                  : color === 'error' ? 'x-circle'
                    : 'info'
            } title-xl ${color === 'success' ? 'text-status-success-primary'
              : color === 'info' ? 'text-status-info-primary'
                : color === 'warning' ? 'text-status-warning-primary'
                  : color === 'error' ? 'text-status-error-primary'
                    : 'text-status-info-primary'
            }`}></i>
          <div className="d-flex flex-column gap-1">
            <p className="m-0 text-primary text-lg font-weight-600 text-capitalize">{title}</p>
            {content && <p className="m-0 text-secondary text-link">{content}</p>}
            {dateTime && <p className="m-0 text-secondary text-link">{dateTime}</p>}
          </div>
        </div>
        <i className="notification-icon ph ph-x text-xl text-secondary" onClick={close}></i>
      </div>
    </Snackbar>
  );
}

// Setting default values for the props of SoftSnackbar
SoftSnackbar.defaultProps = {
  color: "info",
};

// Typechecking props for SoftSnackbar
SoftSnackbar.propTypes = {
  color: PropTypes.oneOf([
    "success",
    "info",
    "warning",
    "error",
  ]),
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  dateTime: PropTypes.string.isRequired,
  content: PropTypes.node.isRequired,
  close: PropTypes.func.isRequired,
};

export default SoftSnackbar;
