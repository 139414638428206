import { React, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Icon from "@mui/material/Icon";
import Link from "@mui/material/Link";

// Soft UI Dashboard PRO React components
import SoftButton from "components/SoftButton";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import Cookies from 'js-cookie';

// Custom styles for the SidenavCard
import {
  card,
  cardContent,
  cardIconBox,
  cardIcon,
} from "examples/Sidenav/styles/sidenavCard";

import ivancik from "assets/images/solacloud_icon.png";

// Soft UI Dashboard PRO React context
import { useSoftUIController } from "context";

function SidenavCard() {
  const { t } = useTranslation();
  const [controller] = useSoftUIController();
  const { miniSidenav, sidenavColor } = controller;

  return (
    <Card
      sx={{
        background: '#208DFC',
        boxShadow: '0px 2px 2px 0px #3741540F',
        borderRadius: '8px',
        zIndex: 0
      }}
    >
      <SoftBox
        sx={{
          p: 2,
        }}
      >
        <SoftBox mb={1}>
          <SoftTypography
            style={{
              fontSize: '20px',
              color: '#FFFFFF',
              fontWeight: 600
            }}
          >
            {t('Need help?')}
          </SoftTypography>
        </SoftBox>

        <SoftBox mb={1}>
          <SoftTypography
            style={{
              fontSize: '14px',
              color: '#F8F9FA',
              fontWeight: 400
            }}
          >
            {t('Please check our docs')}
          </SoftTypography>
        </SoftBox>

        <img
          src={ivancik}
          style={{
            position: "absolute",
            width: "200px",
            opacity: "0.12",
            right: "-65px",
            bottom: "-25px",
            zIndex: '-1'
          }} />


        {JSON.parse(Cookies.get('permissions')).indexOf("SupR") !== -1 ? (
          <>
            <SoftBox mb={1}>
              <Link href="/support" 
              className="btn btn-sm btn-secondary btn-icon-primary m-0 d-flex align-items-center justify-content-center cursor-pointer"
              style={{
                zIndex: 1
              }}
              >
                <i className="ph ph-gear-six me-2"></i>
                {t('Support')}
              </Link>
            </SoftBox>
          </>
        ) : <></>}


        <SoftBox mb={1}>
          <Link href="/management" className="btn btn-sm btn-secondary-outline btn-icon-primary m-0 d-flex align-items-center justify-content-center cursor-pointer">
            <i className="ph ph-gear-six me-2"></i>
            {t('Videos')}
          </Link>
        </SoftBox>
      </SoftBox>
    </Card>
  );
}

export default SidenavCard;
