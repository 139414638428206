import { React, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Navigate } from "react-router-dom";
import { callFetch } from "../../helpers/callFetch";
import { useTranslation } from 'react-i18next';
import Swal from 'sweetalert2';

function Edit(props) {
    const { t } = useTranslation();
    const [data, setData] = useState([]);
    const [saving, setSaving] = useState(false);
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [timesChooseAable, setTimesChooseAable] = useState(false);

    const {
        register,
        handleSubmit,
        setError,
        setValue,
        getValues,
        formState: { errors },
    } = useForm();

    useEffect(() => {
        if (props?.id) {
            callFetch("absence/" + props?.id + "/edit", "GET", []).then((res) => {
                setData(res.data);

                for (let [key, value] of Object.entries(res?.data?.data)) {
                    setValue(key, (value == null || value == 'null' ? "" : value));
                }
            });
        } else {
            callFetch("absence/create", "GET", []).then((res) => {
                setData(res.data);
            });
        }
    }, [props?.id + props?.refresh]);

    useEffect(() => {
        if (data?.data) {
            setValue('time', data?.data?.time)
            if (data?.data?.start_date == data?.data?.end_date) {
                setTimesChooseAable(true);
            } else {
                setTimesChooseAable(false);
            }
        }
    }, [data]);

    const deleteAbsence = (id) => {

        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-primary m-0',
                cancelButton: 'btn btn-secondary m-0'
            },
            buttonsStyling: false
        });

        return swalWithBootstrapButtons.fire({
            title: t('Message'),
            text: t("Are you sure?"),
            // icon: 'warning',
            showCancelButton: true,
            confirmButtonText: t('Yes, do it!'),
            cancelButtonText: t('Cancel'),
            reverseButtons: true,
        }).then((result) => {
            if (result.isConfirmed) {

                var formData = new FormData();
                formData._method = 'DELETE';

                callFetch("absence/" + id, "POST", formData, setError).then((res) => {
                    if (!res.ok) return;
                    props.setReloadCalendarData(Math.floor(Math.random() * 10000000))
                    document.getElementById('absenceRequestEditClose').click();
                });
            }
        });
    }

    const onSubmit = (formData) => {
        setSaving(true);
        formData.time = (formData.time == 'null' || formData.time == null ? '' : formData?.time);
        formData.description = (formData.description == 'null' || formData.description == null ? '' : formData?.description);

        callFetch("absence/" + props?.id, "POST", formData, setError).then((res) => {
            setSaving(false);
            if (!res.ok) return;
            props.setReloadCalendarData(Math.floor(Math.random() * 10000000))
            document.getElementById('absenceRequestEditClose').click();
        });
    };

    return (
        <>
            <button
                type="button"
                id="absenceRequestEditBtn"
                className="btn bg-gradient-primary d-none"
                data-bs-toggle="modal"
                data-bs-target="#absenceRequestEdit"
            >{t('Absence Edit')}</button>

            <form className={`needs-validation ${Object.keys(errors).length ? "was-validated" : ""}`} onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">

                <input type="hidden" defaultValue="PUT" {...register("_method")} />

                <div className="modal fade right-side" id="absenceRequestEdit" tabindex="-1" role="dialog" aria-labelledby="absenceRequestEditLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-slide-right">
                        <div className="modal-content">
                            <div className='modal-header'>
                                <p className='title mb-0'>{t('Absence')}</p>
                                <button
                                    id="absenceRequestEditClose"
                                    type="button"
                                    class="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                ></button>
                            </div>

                            <div className="modal-body tb-ac-cs">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label>
                                                {t('Verkaufer')} *
                                            </label>
                                            <br />
                                            <select
                                                className="form-control"
                                                {...register("user_id", {
                                                    required: true,
                                                })}
                                                required
                                            >
                                                <option value="">--</option>
                                                {data && data.users ? data.users.map((user) => (
                                                    <option key={user.id} value={user.id}>{user.name}</option>
                                                )) : <></>}
                                            </select>
                                            <div className="invalid-feedback">{errors.user_id && errors.user_id.message}</div>
                                        </div>
                                    </div>

                                    <div className="col-12">
                                        <div className="form-group">
                                            <label>
                                                {t('Reason')} *
                                            </label>
                                            <br />
                                            <select
                                                className="form-control"
                                                {...register("reason", {
                                                    required: true,
                                                })}
                                                required
                                            >
                                                <option value="">--</option>
                                                <option value={'Krankheit'}>{t('Krankheit')}</option>
                                                <option value={'Urlaub'}>{t('Urlaub')}</option>
                                                <option value={'Nicht Verfügbar'}>{t('Nicht Verfügbar')}</option>
                                            </select>
                                            <div className="invalid-feedback">{errors.reason && errors.reason.message}</div>
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>
                                                {t('Start Date')} *
                                            </label>
                                            <br />
                                            <input
                                                className="form-control"
                                                type={'date'}
                                                {...register("start_date", {
                                                    required: true,
                                                    onChange: (e) => {
                                                        if (e.target.value == getValues('end_date')) {
                                                            setTimesChooseAable(true);
                                                        } else {
                                                            setTimesChooseAable(false);
                                                        }
                                                    }
                                                })}
                                                required
                                            />
                                            <div className="invalid-feedback">{errors.start_date && errors.start_date.message}</div>
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>
                                                {t('End Date')} *
                                            </label>
                                            <br />
                                            <input
                                                className="form-control"
                                                type={'date'}
                                                {...register("end_date", {
                                                    required: true,
                                                    onChange: (e) => {
                                                        if (e.target.value == getValues('start_date')) {
                                                            setTimesChooseAable(true);
                                                        } else {
                                                            setTimesChooseAable(false);
                                                        }
                                                    }
                                                })}
                                                required
                                            />
                                            <div className="invalid-feedback">{errors.end_date && errors.end_date.message}</div>
                                        </div>
                                    </div>

                                    <div className="col-12">
                                        <div className="form-group">
                                            <label>
                                                {t('Time')} *
                                            </label>
                                            <br />
                                            <select
                                                className="form-control"
                                                {...register("time", {
                                                    required: false,
                                                })}
                                            >
                                                <option value="">{t('Whole Day')}</option>
                                                {timesChooseAable ?
                                                    <>
                                                        <option value="08:00">08:00 - 10:00</option>
                                                        <option value="10:00">10:00 - 12:00</option>
                                                        <option value="12:00">12:00 - 14:00</option>
                                                        <option value="14:00">14:00 - 16:00</option>
                                                        <option value="16:00">16:00 - 18:00</option>
                                                        <option value="18:00">18:00 - 20:00</option>
                                                    </>
                                                    : ''
                                                }
                                            </select>
                                            <div className="invalid-feedback">{errors.quotation_id && errors.quotation_id.message}</div>
                                        </div>
                                    </div>

                                    <div className="col-12">
                                        <div className="form-group">
                                            <label>{t('Remark')}</label>
                                            <textarea
                                                className="form-control"
                                                rows="4"
                                                {...register("description")}></textarea>
                                            <div className="invalid-feedback">{errors.description && errors.description.message}</div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="modal-footer d-flex align-items-center w-100 gap-3">
                                <button
                                    type="button"
                                    className="btn btn-danger m-0 flex-grow-1"
                                    onClick={() => {
                                        deleteAbsence(props?.id);
                                    }}
                                >{t('Cancel Absence')}</button>

                                <button
                                    type="button"
                                    className="btn btn-secondary m-0 flex-grow-1"
                                    data-dismiss="modal"
                                    onClick={() => {
                                        document.getElementById('absenceRequestEditClose').click();
                                    }}
                                >
                                    Close
                                </button>

                                {saving ? (
                                    <button type="submit" className="btn btn-disabled mb-0 flex-grow-1" disabled>
                                        {t('Saving ...')}
                                    </button>
                                ) : (
                                    <button type="submit" className="btn btn-primary m-0 flex-grow-1">
                                        {t('Save')}
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </>
    );
}

export default Edit;
