import Grid from "@mui/material/Grid";
// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";

import { useEffect, useState } from "react";
import TimelineItem from "./TimelineComponent/TimelineItem";
import TimelineList from "./TimelineComponent/TimelineList";
import dateFormat, { masks } from "dateformat";
import { t } from "i18next";
const Timeline = ({ title, project_id, datas }) => {
    const [data, setData] = useState([]);
    useEffect(() => {
        setData(datas);
    }, [datas]);


    return (
        <>
            <div className="pt-3 px-2 pb-2">
                <div className="timeline timeline-one-side" data-timeline-axis-style="dotted">
                    <div className="timeline-block">
                        <span className="timeline-step">
                            <span className="brand-icon-bg-sm text-white"><i className="ph ph-check"></i></span>
                        </span>
                        <div className="timeline-content pt-0 border-0">
                            <p className="m-0 text-primary text-md">{t("Appointment")}</p>
                            <p className="m-0 text-secondary text-sm-normal">{data.project ? dateFormat(data.project.date, "dd.mm.yyyy") : '--'}</p>
                        </div>
                    </div>

                    {data?.quations?.length ? data.quations.map((quation) => (
                        <div className="timeline-block">
                            <span className="timeline-step">
                                <span className="brand-icon-bg-sm text-white"><i className="ph ph-check"></i></span>
                            </span>
                            <div className="timeline-content pt-0 border-0">
                                <p className="m-0 text-primary text-md">{t("Quotation")}</p>
                                <p className="m-0 text-secondary text-sm-normal">{dateFormat(quation.updated_at, "dd.mm.yyyy")}</p>
                            </div>
                        </div>
                    )) : <div className="timeline-block deactive">
                        <span className="timeline-step">
                            <i className="ph ph-circle font-size-20" style={{ borderRadius: '50%', border: '3px solid var(--border-primary)' }}></i>
                        </span>
                        <div className="timeline-content pt-0 border-0">
                            <p className="m-0 text-primary text-md">{t("Quotation")}</p>
                            <p className="m-0 text-secondary text-sm-normal">--</p>
                        </div>
                    </div>}

                    {data?.orders?.length ? data.orders.map((order) => (
                        <div className="timeline-block">
                            <span className="timeline-step">
                                <span className="brand-icon-bg-sm text-white"><i className="ph ph-check"></i></span>
                            </span>
                            <div className="timeline-content pt-0 border-0">
                                <p className="m-0 text-primary text-md">{t("Order")}</p>
                                <p className="m-0 text-secondary text-sm-normal">{dateFormat(order?.order_date, "dd.mm.yyyy")}</p>
                            </div>
                        </div>
                    )) : <div className="timeline-block deactive">
                        <span className="timeline-step">
                            <i className="ph ph-circle font-size-20" style={{ borderRadius: '50%', border: '3px solid var(--border-primary)' }}></i>
                        </span>
                        <div className="timeline-content pt-0 border-0">
                            <p className="m-0 text-primary text-md">{t("Order")}</p>
                            <p className="m-0 text-secondary text-sm-normal">--</p>
                        </div>
                    </div>}

                    {data?.invoices?.length ? data.invoices.map((invoice) => (
                        <div className="timeline-block">
                            <span className="timeline-step">
                                <span className="brand-icon-bg-sm text-white"><i className="ph ph-check"></i></span>
                            </span>
                            <div className="timeline-content pt-0 border-0">
                                <p className="m-0 text-primary text-md">{t("Invoice")}</p>
                                <p className="m-0 text-secondary text-sm-normal">{dateFormat(invoice?.created_at, "dd.mm.yyyy")}</p>
                            </div>
                        </div>
                    )) : <div className="timeline-block deactive">
                        <span className="timeline-step">
                            <i className="ph ph-circle font-size-20" style={{ borderRadius: '50%', border: '3px solid var(--border-primary)' }}></i>
                        </span>
                        <div className="timeline-content pt-0 border-0">
                            <p className="m-0 text-primary text-md">{t("Invoice")}</p>
                            <p className="m-0 text-secondary text-sm-normal">--</p>
                        </div>
                    </div>}

                    {data?.assign_orders?.length ? data.assign_orders.map((assign_order) => (
                        <div className="timeline-block">
                            <span className="timeline-step">
                                <span className="brand-icon-bg-sm text-white"><i className="ph ph-check"></i></span>
                            </span>
                            <div className="timeline-content pt-0 border-0">
                                <p className="m-0 text-primary text-md">{t(assign_order.dachmontag_elektromontage)}</p>
                                <p className="m-0 text-secondary text-sm-normal">{dateFormat(assign_order.created_at, "dd.mm.yyyy")}</p>
                            </div>
                        </div>
                    )) : (
                        <>
                            <div className="timeline-block deactive">
                                <span className="timeline-step">
                                    <i className="ph ph-circle font-size-20" style={{ borderRadius: '50%', border: '3px solid var(--border-primary)' }}></i>
                                </span>
                                <div className="timeline-content pt-0 border-0">
                                    <p className="m-0 text-primary text-md">{t("Dachmontage")}</p>
                                    <p className="m-0 text-secondary text-sm-normal">--</p>
                                </div>
                            </div>

                            <div className="timeline-block deactive">
                                <span className="timeline-step">
                                    <i className="ph ph-circle font-size-20" style={{ borderRadius: '50%', border: '3px solid var(--border-primary)' }}></i>
                                </span>
                                <div className="timeline-content pt-0 border-0">
                                    <p className="m-0 text-primary text-md">{t("Elektromontage")}</p>
                                    <p className="m-0 text-secondary text-sm-normal">--</p>
                                </div>
                            </div>
                        </>
                    )}

                </div>
            </div>

            {/*
            {data && <Grid>
                <Grid xs={12} lg={12} ml={8}>
                    <TimelineList title={title}>
                        {
                            data.project && <TimelineItem
                                color={"dark"}
                                icon={"notifications"}
                                title={t("Appointment")}
                                dateTime={data.project && dateFormat(data.project.date, "dd.mm.yyyy")}
                            />
                        }

                        {data.quations && data.quations.map((quation) => (
                            <TimelineItem
                                color={"dark"}
                                icon={"text_snippet"}
                                title={t("Quotation")}
                                dateTime={dateFormat(quation.updated_at, "dd.mm.yyyy")}
                            />
                        ))}

                        {data.orders && data.orders.map((order) => (
                            <TimelineItem
                                color={"dark"}
                                icon={"shopping_cart"}
                                title={t("Order")}
                                dateTime={dateFormat(order?.order_date, "dd.mm.yyyy")}
                            />
                        ))}

                        {data.invoices && data.invoices.map((invoice) => (
                            <TimelineItem
                                color={"dark"}
                                icon={"account_balance_wallet"}
                                title={t("Invoice")}
                                dateTime={dateFormat(invoice?.created_at, "dd.mm.yyyy")}
                            />
                        ))}

                        {data.assign_orders && data.assign_orders.map((assign_order) => (
                            <TimelineItem
                                color={"dark"}
                                icon={"house"}
                                title={t(assign_order.dachmontag_elektromontage)}
                                dateTime={dateFormat(assign_order.created_at, "dd.mm.yyyy")}
                            />
                        ))}
                    </TimelineList>
                </Grid>
            </Grid>}
           */}
        </>
    );
}

export default Timeline