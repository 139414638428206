import { React, useEffect, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { Navigate, useParams } from "react-router-dom";
import callFetch from "../../../helpers/callFetch";
import { useTranslation } from "react-i18next";
import FilesIndexTable from "./FilesIndexTable";
import CustomFiles from "./CustomFiles";
import Cookies from "js-cookie";
import { ToastContainer, toast } from "react-toastify";
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftDropzone from "components/SoftDropzone";
import Timeline from "./Timeline";
import ProjectStatus from "../ProjectStatus";
import EmailForm from "./EmailForm";
import ACZahlertermin from "./ACZahlertermin";

function Fertigmeldung(props) {
  let params = useParams();
  const { t } = useTranslation();
  const [saving, setSaving] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const [refresh2, setRefresh2] = useState(0);
  const [selectedFilesRefresh, setSelectedFilesRefresh] = useState(0);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [acZahlertermin, setAcZahlertermin] = useState([]);

  const {
    register,
    handleSubmit,
    setError,
    reset,
    setValue,
    unregister,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    setValue("id", params.id);
  }, [params.id]);

  const notify = () => toast("Success");
  const user = JSON.parse(Cookies.get("user"));
  const submitBtn = useRef();
  const [attachments, setAttachment] = useState([]);
  const [newDropzone, setNewDropzone] = useState(true);
  const [editForm, setEditForm] = useState(false);

  const handeleAttachment = (newData) => {
    let data = attachments;
    data[attachments.length] = newData;
    setAttachment(data);
    setValue("attachments", JSON.stringify(data));
  };

  const onSubmit = (formData) => {
    setSaving(true);
    formData.id = params.id;
    formData.for = "project_fertigmeldung";
    callFetch("custom-file-upload", "POST", formData, setError).then((res) => {
      setRefresh(refresh + 1);
      setAttachment([]);
      setValue("attachments", "");
      setSaving(false);
      if (!res.ok) return;
      setSubmitSuccess(true);
      clearForm();
      //notify();
    });
  };

  useEffect(() => {
    callFetch("ac-zahlertermin/" + params.id, "GET", []).then((res) => {
      setAcZahlertermin(res.data);
    });
  }, [params.id, refresh2]);

  const clearForm = () => {
    reset({
      id: 0,
      title: "",
      status: "",
      description: "",
      attachments: JSON.stringify([]),
    });

    setNewDropzone(true);
    setEditForm(false);
    setAttachment([]);
  };

  const dropZoneInitialize = (name = "dachansicht") => {
    return (
      <SoftDropzone
        key={"dfdf"}
        options={{
          dictDefaultMessage: t("Drop files here to upload"),
          //addRemoveLinks: true,
          acceptedFiles: ".jpeg,.jpg,.png,.pdf",
          action: Cookies.get("api_url") + "attachment",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + Cookies.get("token"),
          },
          processing: function () {
            document.body.classList.add("loading");
          },
          success: (file, response) => {
            document.body.classList.remove("loading");
            if (response.message == "success") {
              handeleAttachment(response.data);
            }
          },
          maxfilesexceeded: function (file) {
            this.removeAllFiles();
            this.addFile(file);
          },
          error: function (file, response) {
            document.body.classList.remove("loading");
          },
        }}
      />
    );
  };

  return (
    <>
      <div className="row">
        <div className="col-sm-12 col-md-12 col-lg-8 col-xl-8 col-xxl-9">
          <div className="row mb-2">
            <div className="col-lg-12 mt-lg-0">
              <div className="emb-4">
                {editForm == false ? (
                  <>
                    <form
                      id="createOrderProcesing"
                      className={`needs-validation ${
                        Object.keys(errors).length ? "was-validated" : ""
                      }`}
                      onSubmit={handleSubmit(onSubmit)}
                      noValidate
                      autoComplete="off"
                    >
                      <input
                        type="hidden"
                        defaultValue="POST"
                        {...register("_method")}
                      />
                      <input
                        type="hidden"
                        {...register("id", {
                          required: true,
                        })}
                      />
                      <div className="card">
                        <div className="card-body pb-3">
                          <div
                            className="accordion accordion-flush"
                            id="accordionFlushExample"
                          >
                            <div className="accordion-item accordion-item bg-secondary p-3 border-radius-8">
                              <h2 className="accordion-header">
                                <div className="d-flex justify-content-between align-items-center">
                                  <div className="d-flex">
                                    <button
                                      className="accordion-button collapsed"
                                      type="button"
                                      data-bs-toggle="collapse"
                                      data-bs-target="#flush-collapseOne"
                                      aria-expanded="false"
                                      aria-controls="flush-collapseOne"
                                    >
                                      <span className="accordion-arrow">
                                        <i className="ph ph-caret-right"></i>
                                      </span>
                                      <p className="font-size-20 font-weight-600 text-primary">
                                        <span className="pl-2">
                                          Fertigmeldung Dokumente Hochladen
                                        </span>
                                      </p>
                                    </button>
                                  </div>
                                  <div className="">
                                    <button
                                      type="button"
                                      data-bs-toggle="modal"
                                      data-bs-target="#ACZahlertermin"
                                      className="btn btn-secondary mb-0 d-flex align-items-center"
                                    >
                                      <i class="ph ph-calendar-dots font-size-20 me-2"></i>{" "}
                                      {t(
                                        acZahlertermin?.id
                                          ? "Zählertermin Ok"
                                          : "AC Zählertermin"
                                      )}
                                    </button>
                                  </div>
                                </div>
                              </h2>
                              <div
                                id="flush-collapseOne"
                                className="accordion-collapse collapse"
                                data-bs-parent="#accordionFlushExample"
                              >
                                <div className="accordion-body">
                                  <div>
                                    <SoftBox lineHeight={1} mt={2}>
                                      <SoftBox>
                                        <SoftBox
                                          display="flex"
                                          flexDirection="column"
                                          justifyContent="flex-end"
                                          height="100%"
                                        >
                                          {dropZoneInitialize()}
                                        </SoftBox>
                                      </SoftBox>
                                    </SoftBox>
                                    <SoftBox
                                      mt={2}
                                      display="flex"
                                      justifyContent="flex-end"
                                    >
                                      <a
                                        href="#"
                                        className="btn btn-secondary mx-2 mb-0"
                                        onClick={() => {
                                          clearForm();
                                        }}
                                      >
                                        {t("Cancel")}
                                      </a>

                                      {!saving && (
                                        <button
                                          type="submit"
                                          className="btn btn-primary mb-0"
                                        >
                                          {t("Save")}
                                        </button>
                                      )}
                                      {saving && (
                                        <button
                                          type="submit"
                                          className="btn btn-disabled"
                                          disabled
                                        >
                                          {t("Saving ...")}
                                        </button>
                                      )}
                                    </SoftBox>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div
                            className="accordion accordion-flush mt-3"
                            id="accordionFlushExample"
                          >
                            <div className="accordion-item accordion-item bg-secondary p-3 border-radius-8">
                              <h2 className="accordion-header">
                                <button
                                  className="accordion-button collapsed"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#flush-collapseTwo"
                                  aria-expanded="false"
                                  aria-controls="flush-collapseTwo"
                                >
                                  <span className="accordion-arrow">
                                    <i className="ph ph-caret-right"></i>
                                  </span>
                                  <p className="font-size-20 font-weight-600 text-primary">
                                    <span className="pl-2">PDF Dateien</span>
                                  </p>
                                </button>
                              </h2>
                              <div
                                id="flush-collapseTwo"
                                className="accordion-collapse collapse"
                                data-bs-parent="#accordionFlushExample"
                              >
                                <div className="accordion-body">
                                  <FilesIndexTable
                                    for={"project_fertigmeldung"}
                                    refresh={refresh}
                                    onlyCustomFiles={1}
                                    status={true}
                                    checkForEmail={true}
                                    setSelectedFilesRefresh={
                                      setSelectedFilesRefresh
                                    }
                                    setSelectedFiles={setSelectedFiles}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div
                            className="accordion accordion-flush mt-3"
                            id="accordionFlushExample"
                          >
                            <div className="accordion-item accordion-item bg-secondary p-3 border-radius-8">
                              <h2 className="accordion-header">
                                <button
                                  className="accordion-button collapsed"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#flush-collapseThree"
                                  aria-expanded="false"
                                  aria-controls="flush-collapseThree"
                                >
                                  <span className="accordion-arrow">
                                    <i className="ph ph-caret-right"></i>
                                  </span>
                                  <p className="font-size-20 font-weight-600 text-primary">
                                    <span className="pl-2">Bild</span>
                                  </p>
                                </button>
                              </h2>
                              <div
                                id="flush-collapseThree"
                                className="accordion-collapse collapse"
                                data-bs-parent="#accordionFlushExample"
                              >
                                <div className="accordion-body">
                                  <CustomFiles
                                    for={"project_fertigmeldung"}
                                    refresh={refresh}
                                    project_id={params.id}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </>
                ) : (
                  <></>
                )}
              </div>
              <br />
            </div>
          </div>
          <ToastContainer />
        </div>
        <div className="col-sm-12 col-md-12 col-lg-4 col-xl-4 col-xxl-3 pl-4 project-details-right-side">
          <div className="card mb-3">
            <div className="card-body p-3">
              <div class="details">
                <h6 className="text-primary font-size-16 font-weight-500">
                  Projektinformationen
                </h6>
                <div className="mb-2">
                  <p className="font-size-14 text-secondary mb-0">
                    <i class="ph ph-clipboard-text font-size-20"></i>{" "}
                    <span className="position-relative bottom-4 left-4">
                      Auftrag
                    </span>
                  </p>
                  <div class="profile">
                    {/* <img
                          src="/assets/img/placeholder.png"
                          className="border-primary"
                          alt="Profile"
                        /> */}
                    <span>400002</span>
                  </div>
                </div>
                <div className="mb-2">
                  <p className="font-size-14 text-secondary mb-0">
                    <i className="icon-sola-user-circle-gear"></i>{" "}
                    <span className="position-relative bottom-4 left-4">
                      Lead Owner
                    </span>
                  </p>
                  <div class="profile">
                    <img
                      src="/assets/img/placeholder.png"
                      className="border-primary"
                      alt="Profile"
                    />
                    <span>Oliver Kahn</span>
                  </div>
                </div>
                <hr />
              </div>
              <div className="mb-3">
                <div className="pb-0">
                  <h6>{t("Email")}</h6>
                </div>
                <div className="">
                  <EmailForm
                    id={params?.id}
                    type={"fertigmeldung_attchments"}
                    attachments={selectedFiles}
                    selectedFilesRefresh={selectedFilesRefresh}
                  />
                </div>
              </div>

              <Timeline refresh={refresh} />
            </div>
          </div>
        </div>
      </div>

      <ACZahlertermin
        setRefresh2={setRefresh2}
        acZahlertermin={acZahlertermin}
      />
    </>
  );
}

export default Fertigmeldung;
