import { React, useEffect, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { Navigate, Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import callFetch from "helpers/callFetch";
import Moment from 'react-moment';

import dateFormat from "dateformat";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import selectData from "layouts/pages/account/settings/components/BasicInfo/data/selectData";
import Cookies from 'js-cookie';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Swal from "sweetalert2";
import IndexTable from "./IndexTable";

const Index = (props) => { 

    return (
        <>
            <IndexTable projectId={props?.projectId}></IndexTable>
        </>
    )
}

export default Index