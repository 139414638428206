import { useEffect, useState, useRef } from "react";
import { NavLink } from "react-router-dom";
import callFetch from "helpers/callFetch";
import { useTranslation } from "react-i18next";
import DataTable from "react-data-table-component";
import deleteAlert from "helpers/deleteAlert";
import Cookies from "js-cookie";
import dateFormat from "dateformat";
import { NumericFormat } from "react-number-format";
import Icon from "@mui/material/Icon";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import SoftBox from "components/SoftBox";
import Flatpickr from "react-flatpickr";
import CreateGutschrift from "./gutschrift/Create";

function IndexTable(props) {
  const { t } = useTranslation();
  const [tabledata, setTableData] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [refresh, setRefresh] = useState(0);
  const [loading, setLoading] = useState(false);
  const fpt = useRef(null);
  const [startDateTerm, setStartDateTerm] = useState("");
  const [endDateTerm, setEndDateTerm] = useState("");
  const [overPaid, setOverPaid] = useState("");
  const [summe, setSumme] = useState({});
  const [orderid, setOrderId] = useState(0);
  const [parrentId, setParrentId] = useState(0);
  const [url_parameters, setUrlParameters] = useState("");

  var typingTimer;
  const [searchKey, setSearchKey] = useState("");

  const handleDateChangeTerm = (selectedDates) => {
    // Handle selected date range
    if (Object.keys(selectedDates).length <= 0) {
      setStartDateTerm("");
      setEndDateTerm("");
    } else {
      const [startDateTermX, endDateTermX] = selectedDates;
      setStartDateTerm(dateFormat(startDateTermX, "yyyy-mm-dd"));
      setEndDateTerm(dateFormat(endDateTermX, "yyyy-mm-dd"));
    }
  };

  const tableHeadings = [
    {
      name: t("Invoice Nr."),
      selector: (row) => (
        <>
          {row?.gutschrift == 1 ? (
            <>
              <NavLink
                to={"/gutschrift/" + row.id}
                className={"text-primary font-weight-bold"}
              >
                {row.id}
              </NavLink>
            </>
          ) : (
            <NavLink
              to={"/invoices/" + row.id}
              className={"text-primary font-weight-bold"}
            >
              {row.id}
            </NavLink>
          )}
        </>
      ),
      sortable: true,
    },
    {
      name: t("Project Nr."),
      selector: (row) => (
        <>
          {row?.quation?.project_id || row?.order?.quation?.project_id ? (
            <NavLink
              to={
                "/projects/" +
                (row?.quation?.project_id
                  ? row?.quation?.project_id
                  : row?.order?.quation?.project_id)
              }
              className="text-primary font-weight-bold"
            >
              {row?.quation?.project_id
                ? row?.quation?.project_id
                : row?.order?.quation?.project_id}
            </NavLink>
          ) : (
            "--"
          )}
        </>
      ),
    },
    {
      name: t("Client Name"),
      selector: (row) => (
        <>
          {row?.lieferanten?.id ? (
            <>{row?.lieferanten?.firma_name}</>
          ) : (
            <>
              {row?.quation?.project?.id
                ? row?.quation?.project?.vorname +
                  " " +
                  row?.quation?.project?.name
                : row?.order?.quation?.project?.vorname +
                  " " +
                  row?.order?.quation?.project?.name}
            </>
          )}
        </>
      ),
    },
    {
      name: t("Creator"),
      selector: (row) => (row.user && row.user.name ? row.user.name : ""),
    },
    {
      name: t("Date"),
      selector: (row) => dateFormat(row.date, "dd.mm.yyyy"),
    },
    {
      name: t("Abschlag"),
      selector: (row) => (
        <>
          {row?.gutschrift == 1 ? (
            "Gutschrift"
          ) : (
            <>
              {" "}
              {row?.lieferanten?.id ? (
                <>100% Rechnung</>
              ) : (
                <>{row?.amountPerchantage}% Rechnung</>
              )}
            </>
          )}
        </>
      ),
    },
    {
      name: (
        <>
          <span>
            {t("Invoice Amount")}
            <br />
            <span style={{ color: "rgb(114, 172, 39)" }}>
              <NumericFormat
                value={Number(summe?.total_netto ? summe?.total_netto : 0)}
                displayType="text"
                thousandSeparator={"."}
                decimalSeparator=","
                decimalScale={2}
                fixedDecimalScale
                suffix={" € "}
              />
            </span>
          </span>
        </>
      ),
      selector: (row) => (
        <>
          {row?.gutschrift == 1 ? (
            <NumericFormat
              value={Number(row?.brutto)}
              displayType="text"
              thousandSeparator={"."}
              decimalSeparator=","
              decimalScale={2}
              fixedDecimalScale
              suffix={" € "}
              prefix={" - "}
            />
          ) : (
            <NumericFormat
              value={Number(row?.netto)}
              displayType="text"
              thousandSeparator={"."}
              decimalSeparator=","
              decimalScale={2}
              fixedDecimalScale
              suffix={" € "}
            />
          )}
        </>
      ),
      width: "190px",
    },
    {
      name: t("Payment Status"),
      width: "150px",
      selector: (row) => (
        <>
          {/*(row.total_paid + row?.total_gutschrift).toFixed(2) >= (row.netto).toFixed(2) ? (
                        <>
                            <SoftBox display="flex" alignItems="center">
                                <SoftButton variant="outlined" color="success" size="small" iconOnly circular>
                                    <Icon sx={{ fontWeight: "bold" }}>done</Icon>
                                </SoftButton>
                                <SoftTypography variant="caption" fontWeight="medium" color="text" sx={{ lineHeight: 0 }}>
                                    &nbsp;&nbsp; {t('2/2 Getilgt')}
                                </SoftTypography>
                            </SoftBox>
                        </>
                    ) : (row.total_paid + row?.total_gutschrift).toFixed(2) === 0 ? (
                        <>
                            <SoftBox display="flex" alignItems="center">
                                <SoftButton variant="outlined" color="error" size="small" iconOnly circular>
                                    <Icon sx={{ fontWeight: "bold" }}>sync</Icon>
                                </SoftButton>
                                <SoftTypography variant="caption" fontWeight="medium" color="text" sx={{ lineHeight: 0 }}>
                                    &nbsp;&nbsp; {t('Open')}
                                </SoftTypography>
                            </SoftBox>
                        </>
                    ) : (row.total_paid + row?.total_gutschrift).toFixed(2) > 0 && (row.total_paid + row?.total_gutschrift).toFixed(2) < (row.netto).toFixed(2) ? (
                        <>
                            <SoftBox display="flex" alignItems="center">
                                <SoftButton variant="outlined" color="warning" size="small" iconOnly circular>
                                    <Icon sx={{ fontWeight: "bold" }}>done</Icon>
                                </SoftButton>
                                <SoftTypography variant="caption" fontWeight="medium" color="text" sx={{ lineHeight: 0 }}>
                                    &nbsp;&nbsp; {t('1/2 Getilgt')}
                                </SoftTypography>
                            </SoftBox>
                        </>
                    ) : ''*/}

          {(row.total_paid + row?.total_gutschrift).toFixed(2) >=
          row.netto.toFixed(2) ? (
            <>
              <span className="badge badge-success">{t("Paid")}</span>
            </>
          ) : (row.total_paid + row?.total_gutschrift).toFixed(2) <
              row.netto.toFixed(2) &&
            (row.total_paid + row?.total_gutschrift).toFixed(2) > 0 ? (
            <>
              <span className="badge badge-warning">{t("Partially")}</span>
            </>
          ) : (
            <>
              <span className="badge badge-danger">{t("Open")}</span>
            </>
          )}
        </>
      ),
    },
    {
      name: (
        <>
          <span>
            {t("Bezahlt")}
            <br />
            <span style={{ color: "rgb(114, 172, 39)" }}>
              <NumericFormat
                value={Number(summe?.total_paid ? summe?.total_paid : 0)}
                displayType="text"
                thousandSeparator={"."}
                decimalSeparator=","
                decimalScale={2}
                fixedDecimalScale
                suffix={" € "}
              />
            </span>
          </span>
        </>
      ),
      selector: (row) => (
        <>
          {row?.gutschrift == 0 ? (
            <NumericFormat
              value={Number(row.total_paid)}
              displayType="text"
              thousandSeparator={"."}
              decimalSeparator=","
              decimalScale={2}
              fixedDecimalScale
              suffix={" € "}
            />
          ) : (
            "--"
          )}
        </>
      ),
      sortable: true,
      width: "190px",
    },
    {
      name: (
        <>
          <span>
            {t("Ofiener Betrag")}
            <br />
            <span style={{ color: "#E53E3E" }}>
              <NumericFormat
                value={Number(
                  summe?.total_paid || summe?.total_netto
                    ? Number(summe?.total_netto) - Number(summe?.total_paid)
                    : 0
                )}
                displayType="text"
                thousandSeparator={"."}
                decimalSeparator=","
                decimalScale={2}
                fixedDecimalScale
                suffix={" € "}
              />
            </span>
          </span>
        </>
      ),
      selector: (row) => (
        <>
          {row?.gutschrift == 1 ? (
            <NumericFormat
              value={Number(row?.brutto)}
              displayType="text"
              thousandSeparator={"."}
              decimalSeparator=","
              decimalScale={2}
              fixedDecimalScale
              suffix={" € "}
              prefix={" - "}
            />
          ) : (
            <>
              <NumericFormat
                value={Number(row.netto) - Number(row.total_paid)}
                displayType="text"
                thousandSeparator={"."}
                decimalSeparator=","
                decimalScale={2}
                fixedDecimalScale
                suffix={" € "}
              />
            </>
          )}

          {row?.total_gutschrift ? (
            <span style={{ color: "#E53E3E" }}>
              (
              <NumericFormat
                value={Number(row?.total_gutschrift)}
                displayType="text"
                thousandSeparator={"."}
                decimalSeparator=","
                decimalScale={2}
                fixedDecimalScale
                suffix={" € "}
                prefix={" - "}
              />
              )
            </span>
          ) : (
            ""
          )}
        </>
      ),
      sortable: true,
      width: "190px",
    },
    {
      name: t("Mahnstufe"),
      selector: (row) => <>{row?.mahnstufe ? t(row?.mahnstufe) : ""}</>,
      width: "190px",
    },
    {
      name: t("Actions"),
      cell: (row) => (
        <>
          {JSON.parse(Cookies.get("permissions")).indexOf("IU") !== -1 ||
          JSON.parse(Cookies.get("permissions")).indexOf("ID") !== -1 ||
          JSON.parse(Cookies.get("permissions")).indexOf("ID") !== -1 ? (
            <div className="text-center dropstart">
              <a
                href="/"
                className="dropdown-toggle"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i className="fa fa-ellipsis-v text-xs"></i>
              </a>
              <ul className="dropdown-menu">
                {row?.gutschrift == 0 &&
                JSON.parse(Cookies.get("permissions")).indexOf("IC") !== -1 ? (
                  <>
                    <li>
                      <a
                        to={"#"}
                        className="dropdown-item"
                        data-bs-toggle="modal"
                        data-bs-target="#createGutschrift"
                        onClick={() => {
                          setOrderId(row?.auftrag_id);
                          setParrentId(row?.id);
                        }}
                      >
                        {t(
                          "Rechnung stornieren / Gutschrift zur Rechnung erstellen"
                        )}
                      </a>
                    </li>
                    <li>
                      <hr className="dropdown-divider" />
                    </li>
                  </>
                ) : (
                  <></>
                )}

                {JSON.parse(Cookies.get("permissions")).indexOf("IU") !== -1 ? (
                  <>
                    <li>
                      {row?.gutschrift == 1 ? (
                        <>
                          <NavLink
                            to={"/gutschrift/" + row.id}
                            className="dropdown-item"
                          >
                            {t("Edit")}
                          </NavLink>
                        </>
                      ) : (
                        <NavLink
                          to={"/invoices/" + row.id}
                          className="dropdown-item"
                        >
                          {t("Edit")}
                        </NavLink>
                      )}
                    </li>
                    <li>
                      <hr className="dropdown-divider" />
                    </li>
                  </>
                ) : (
                  <></>
                )}
                {JSON.parse(Cookies.get("permissions")).indexOf("ID") !== -1 ? (
                  <li>
                    <a
                      className="dropdown-item text-danger"
                      href="#0"
                      onClick={(e) =>
                        deleteAlert(e, "invoices", row.id, t).then((res) =>
                          setRefresh(refresh + 1)
                        )
                      }
                    >
                      {t("Delete")}
                    </a>
                  </li>
                ) : (
                  <></>
                )}
              </ul>
            </div>
          ) : (
            <></>
          )}
        </>
      ),
    },
  ];

  useEffect(() => {
    //setTableData([]);
    setLoading(true);
    var projectId = "";
    if (props?.projectId && props?.projectId > 0) {
      projectId = props?.projectId;
    }

    var query =
      "page=" + pageNumber + "&search=" + searchKey + "&projectId=" + projectId;

    if (props?.status) {
      query += "&status=" + props?.status;
    }

    if (props?.lieferantenId) {
      query += "&lieferantenId=" + props?.lieferantenId;
    }

    if (overPaid) {
      query += "&overPaid=" + overPaid;
    }

    if (startDateTerm && endDateTerm) {
      query += "&startDate=" + startDateTerm;
      query += "&endDate=" + endDateTerm;
    }

    setUrlParameters(query);

    callFetch("invoices?" + query, "GET", []).then((res) => {
      setTableData(res.data);
      setSumme(res.summe);
      setLoading(false);
    });
  }, [
    pageNumber,
    refresh,
    searchKey,
    startDateTerm,
    endDateTerm,
    overPaid,
    props?.lieferantenId,
    props?.projectId,
    props?.status,
  ]);

  useEffect(() => {
    setInterval(() => {
      console.log("setInterval");

      setRefresh(refresh + 1 * (Math.floor(Math.random() * 100) + 1));
    }, 15000);
  }, []);

  const handlePageChange = (page) => {
    setPageNumber(page);
  };

  function toPages(pages) {
    const results = [];

    for (let i = 1; i <= pages; i++) {
      results.push(i);
    }

    return results;
  }

  // RDT exposes the following internal pagination properties
  const BootyPagination = ({ onChangePage, currentPage }) => {
    const handleBackButtonClick = () => {
      onChangePage(currentPage - 1);
    };

    const handleNextButtonClick = () => {
      onChangePage(currentPage + 1);
    };

    const handlePageNumber = (e) => {
      onChangePage(Number(e.target.value));
    };

    const pages = tabledata.last_page;
    const pageItems = toPages(pages);
    const nextDisabled = currentPage === tabledata.last_page;
    const previosDisabled = currentPage === 1;

    return (
      <>
        <br />
        <p className="float-md-start pt-2 text-secondary text-xs font-weight-bolder ms-3">
          {t("Showing")} {tabledata.from} {t("to")} {tabledata.to} {t("of")}{" "}
          {tabledata.total} {t("entries")}
        </p>
        <nav className="float-md-end me-2">
          <ul className="pagination">
            <li className="page-item">
              <button
                className="page-link"
                onClick={handleBackButtonClick}
                disabled={previosDisabled}
                aria-disabled={previosDisabled}
                aria-label="previous page"
              >
                &#60;
              </button>
            </li>
            {tabledata?.links.map((page) => {
              //const className = (parseInt(page.label) === currentPage ? "page-item active" : "page-item");
              const className =
                page.active === true ? "page-item active" : "page-item";
              return (
                <li
                  key={page.label}
                  className={
                    className +
                    " " +
                    (parseInt(page.label) || page.label == "..."
                      ? ""
                      : "d-none")
                  }
                >
                  <button
                    className="page-link"
                    onClick={page.label != "..." ? handlePageNumber : ""}
                    value={page.label}
                    disabled={page.label == "..." ? true : false}
                  >
                    {page.label}
                  </button>
                </li>
              );
            })}
            <li className="page-item">
              <button
                className="page-link"
                onClick={handleNextButtonClick}
                disabled={nextDisabled}
                aria-disabled={nextDisabled}
                aria-label="next page"
              >
                &#62;
              </button>
            </li>
          </ul>
        </nav>
        <div className="clearfix"></div>
      </>
    );
  };

  

  return (
    <>
      <DataTable
        columns={tableHeadings}
        data={tabledata?.data}
        noDataComponent={
          loading ? t("Loading...") : t("There are no records to display")
        }
        className="data-table"
        // pagination
        highlightOnHover
        paginationComponentOptions={{ noRowsPerPage: true }}
        paginationServer
        paginationTotalRows={tabledata?.total}
        onChangePage={handlePageChange}
        // paginationComponent={BootyPagination}
        // subHeader
        subHeaderComponent={
          <>
            {props?.displayCreateBtn ? (
              <button
                type="button"
                id="addBuchhaltung"
                className="btn btn-primary m-0 float-start"
                data-bs-toggle="modal"
                data-bs-target="#createBuchhaltung"
                style={{
                  background: props?.canCreateInvoice ? "#CB0C9F" : "#CB0C9F",
                }}
                disabled={props?.canCreateInvoice ? false : true}
              >
                {t("Add Invoice")}
              </button>
            ) : (
              ""
            )}

            {props?.filterOptions !== false ? (
              <>
                <div className="form-check">
                  <label>{t("Over Paid")}</label>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    onChange={(e) => {
                      if (e.target.checked) {
                        setOverPaid("overPaid");
                      } else {
                        setOverPaid("");
                      }
                    }}
                  />
                </div>

                <Flatpickr
                  ref={fpt}
                  options={{
                    mode: "range",
                    dateFormat: "Y-m-d",
                  }}
                  placeholder={t("Select Date")}
                  className=" form-control w-sm-100 w-md-50 w-lg-15 ms-2 mt-2"
                  onChange={handleDateChangeTerm}
                />

                <input
                  type="text"
                  placeholder={t("Search...")}
                  className=" form-control w-sm-50 w-md-25 w-lg-15 ms-2 mt-1"
                  defaultValue={searchKey}
                  /*
                                    onChange={(e)=> {
                                        setSearchKey(e.target.value);
                                    }}
                                    */
                  onKeyUp={(e) => {
                    clearTimeout(typingTimer);
                    typingTimer = setTimeout(() => {
                      setTableData([]);
                      setSearchKey(e.target.value);
                    }, 1500);
                  }}
                  onKeyDown={(e) => {
                    clearTimeout(typingTimer);
                  }}
                />
              </>
            ) : (
              ""
            )}
          </>
        }
      />

      <div
        className="modal fade"
        id="createGutschrift"
        tabindex="-1"
        role="dialog"
        aria-labelledby="createGutschriftLabel"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-xl modal-dialog-centered"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header border-0">
              <h6
                className="modal-title"
                id="createGutschriftLabel"
                style={{ fontWeight: "bold" }}
              ></h6>
              <button
                type="button"
                id="createGutschriftClose"
                className="btn-close text-dark"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body p-0">
              <CreateGutschrift
                orderId={orderid}
                parrentId={parrentId}
                col="col-md-12"
                redirect={false}
                popupClose={true}
                invoiceListRefresh={true}
                //ZahlungseingangeListRefresh={true}
                //canCreateInvoice={setCanCreateInvoice}
              />
            </div>
          </div>
        </div>
      </div>

      <button
        id="refreshInvoiceList"
        onClick={() => {
          setRefresh(refresh + 1);
        }}
        className="d-none"
      >
        refreshInvoiceList
      </button>
      <a
        href={
          Cookies.get("backend_url") +
          "invoice-export?token=" +
          Cookies.get("token") +
          "&" +
          url_parameters
        }
        target="_blank"
        id="incoices-export-csv"
        className="d-none"
      >
        Export CSV
      </a>
    </>
  );
}

export default IndexTable;
