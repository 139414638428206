import { React, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { callFetch } from "../../helpers/callFetch";
import { useTranslation } from "react-i18next";
import deleteAlert from "../../helpers/deleteAlert";
import SoftTypography from "components/SoftTypography";

function CheckList(props) {
  const { t } = useTranslation();
  const [checklists, setChecklists] = useState([]);
  const [saving, setSaving] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    reset,
  } = useForm();

  let serial = 1;

  useEffect(() => {
    setChecklists(props?.checkLists);
  }, [refresh, props?.checkLists]);

  const onSubmit = (formData) => {
    setSaving(true);
    formData.ticket_id = props.id;
    callFetch("ticket-checklists", "POST", formData, setError).then((res) => {
      setSaving(false);
      props.refresh((refresh + 1) * Math.floor(Math.random() * 100));

      if (!res.ok) return;
      reset();
      setRefresh(refresh + 1);
    });
  };

  function doDesignationUpdate(e, id) {
    let name =
      e.target.parentNode.parentNode.parentNode.getElementsByClassName(
        "designation-input"
      )[0].value;
    callFetch("ticket-checklists/" + id, "POST", {
      title: name,
      _method: "PUT",
    }).then((res) => {
      if (!res.ok) return;
      props.refresh((refresh + 1) * Math.floor(Math.random() * 100));
      setRefresh(refresh + 1);
    });
  }

  return (
    <form
      className={`needs-validation ${
        Object.keys(errors).length ? "was-validated" : ""
      }`}
      onSubmit={handleSubmit(onSubmit)}
      noValidate
      autoComplete="off"
    >
      <div
        className="modal fade right-side"
        id="checklistModal"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-slide-right modal-dialog-scrollable"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
            <button
                type="button"
                id="designationModalLabel"
                data-bs-dismiss="modal"
                aria-label="Close"
                className=" lose btn btn-icon-only btn-rounded btn-link text-primary mb-0 me-3 btn-sm d-flex align-items-center justify-content-center"
                style={{ fontSize: "12px", position: "absolute", right: "0px" }}
              >
                {/* <i class="fa-sharp fa-solid fa-times icon icon-shape icon-sm  text-center d-flex align-items-center justify-content-center"></i> */}
                <i class="ph ph-x  text-center d-flex align-items-center justify-content-center text-primary font-size-20"></i>
              </button>
              <SoftTypography
                variant="button"
                fontSize="20px"
                fontWeight="600"
                style={{ color: "#273042" }}
              >
                {" "}
                {t("Aufgaben bearbeiten")}
              </SoftTypography> 
            </div>
            <div className="modal-body">
              <div
                className="table-responsive p-0 scroll-cs"
                style={{ maxHeight: "400px" }}
              >
                <table className="table align-items-center mb-0">
                  <thead>
                    <tr>
                      <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                        {t("#")}
                      </th>
                      <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                        {t("Title")}
                      </th>
                      <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                        {t("Actions")}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {checklists &&
                      checklists.map((checklist) => (
                        <tr key={checklist.id}>
                          <td className="align-middle text-center">
                            <p className="text-xs font-weight-bold mb-0">
                              {serial++}
                            </p>
                          </td>
                          <td
                            className="align-middle text-center text-sm"
                            style={{ width: "350px" }}
                          >
                            <input
                              className="form-control designation-input"
                              defaultValue={checklist.title}
                              placeholder={t("eg. Team Lead")}
                            />
                          </td>
                          <td className="align-middle text-center">
                            <p className="mt-3 mb-0">
                              <button
                                type="button"
                                className="width-35 btn btn-danger p-2 btn-sm"
                                onClick={(e) => {
                                  deleteAlert(
                                    e,
                                    "ticket-checklists",
                                    checklist.id,
                                    t
                                  ).then((res) => {
                                    setRefresh(refresh + 1);
                                    props.refresh(
                                      (refresh + 1) *
                                        Math.floor(Math.random() * 100)
                                    );

                                    console.log("delete");
                                  });
                                }}
                              >
                                <i className="fa fa-trash"></i>
                              </button>
                            </p>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
              <hr />
              <div className="row g-3">
                <div className="col-md-12">
                  <label>{t("Aufgabe hinzufügen")} *</label>
                  <input
                    type="text"
                    className="form-control mb-4"
                    placeholder={t("Aufgabenbeschreibung")}
                    {...register("title", {
                      required: true,
                    })}
                    required
                  />
                  <div className="invalid-feedback">
                    {errors.title && errors.title.message}
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <div className="d-flex justify-content-between w-100">
                <button
                  type="button"
                  className="btn btn-secondary mb-0 w-50 me-2"
                  data-bs-dismiss="modal"
                >
                  {t("Abbrechen")}
                </button>
                {!saving && (
                  <button type="submit" className="btn btn-primary mb-0 w-50">
                    {t("Save")}
                  </button>
                )}
                {saving && (
                  <button
                    type="submit"
                    className="btn btn-disabled mb-0 w-50"
                    disabled
                  >
                    {t("Saving ...")}
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}

export default CheckList;
