import React from "react";
import { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Navigate, useParams, NavLink } from "react-router-dom";
import Cookies from "js-cookie";
import { callFetch } from "../../../helpers/callFetch";
import Overview from "../../project/Overview";
import SoftTypography from "components/SoftTypography";
import { useForm } from "react-hook-form";
import SoftSnackbar from "components/SoftSnackbar";
import SoftBox from "components/SoftBox";
import { color } from "@mui/system";
import IndexTableProject from "./IndexTableProject";
import IndexTablePopUp from "./IndexTable";

function Index(props) {
  const { t } = useTranslation();
  const [ready, setReady] = useState(false);
  const bButtonRef = useRef(null);
  useEffect(() => {
    setReady(false);
    console.log("props");

    setTimeout(() => {
      setReady(true);
    }, 500);
  }, [props?.projectid]);

  const handleClick = () => {
    if (bButtonRef.current) {
      bButtonRef.current.click();
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="accordion accordion-flush" id="accordionFlushExample">
            <div className="accordion-item bg-secondary p-3 border-radius-8">
              <h2 className="accordion-header">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseOne"
                  aria-expanded="false"
                  aria-controls="flush-collapseOne"
                >
                  <span className="accordion-arrow">
                    <i className="ph ph-caret-right"></i>
                  </span>
                  <p className="font-size-20 font-weight-600 text-primary">
                    <span className="pl-2">Kundendaten</span>
                  </p>
                </button>
              </h2>
              <div
                id="flush-collapseOne"
                className="accordion-collapse collapse"
                data-bs-parent="#accordionFlushExample"
              >
                <div className="accordion-body">
                  <Overview
                    project_id={props?.projectid ? props?.projectid : 0}
                    bg="white"
                    title={t("Kundendaten")}
                    Wunschtermin={true}
                    Montagetermin={true}
                  ></Overview>

                  <div className="row mt-3">
                    <div className="col-sm-6 mb-3">
                      <div className="card box-shadow-none">
                        <div className="card-body pb-3">
                          <p className="text-secondary font-size-14">
                            Angebotsnr
                          </p>
                          <hr />
                          <div className="d-flex justify-content-between align-items-center mb-1">
                            <p className="text-brand-primary font-size-14 mb-0 font-weight-600">
                              3000005
                            </p>
                            <div>
                              <span className="badge badge-primary me-3">
                                Angebot
                              </span>
                              <span className="text-secondary font-size-14 ml-3">
                                14.11.2024
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 mb-3">
                      <div className="card box-shadow-none">
                        <div className="card-body pb-3">
                          <div className="d-flex justify-content-between align-items-center">
                            <p className="text-secondary font-size-14 mb-0">
                              {t("Projektnr.")}
                            </p>
                            <a className="text-brand-primary font-size-14">
                              <i class="ph ph-info font-size-16 position-relative top-2"></i>{" "}
                              Überblick
                            </a>
                          </div>
                          <hr />
                          <div className="d-flex justify-content-between align-items-center">
                            <p className="text-brand-primary font-size-14 mb-0 font-weight-600">
                              {props?.projectid}
                            </p>
                            <div>
                              <span className="badge badge-primary me-3">
                                Project
                              </span>
                              <span className="text-secondary font-size-14 ml-3">
                                14.11.2024
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="card box-shadow-none">
                        <div className="card-body pb-3">
                          <div className="d-flex justify-content-between align-items-center">
                            <p className="text-secondary font-size-14 mb-0">
                              {t("Wunschtermin")}
                            </p> 
                            <p className="text-secondary font-size-14 mb-0">
                              {t("Leer")}
                            </p> 
                          </div> 
                          <div className="d-flex justify-content-between align-items-center">
                            <p className="text-secondary font-size-14 mb-0">
                              {t("DC Montagetermin")}
                            </p> 
                            <p className="text-secondary font-size-14 mb-0">
                              {t("Leer")}
                            </p> 
                          </div> 
                          <div className="d-flex justify-content-between align-items-center">
                            <p className="text-secondary font-size-14 mb-0">
                              {t("AC Montagetermin")}
                            </p> 
                            <p className="text-secondary font-size-14 mb-0">
                              {t("Leer")}
                            </p> 
                          </div> 
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-12 mt-3">
          <div className="accordion accordion-flush" id="accordionFlushExample">
            <div className="accordion-item accordion-item bg-secondary p-3 border-radius-8">
              <h2 className="accordion-header">
                <div className="d-flex justify-content-between">
                  <div className="d-flex">
                    <button
                      className="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseTwo"
                      aria-expanded="false"
                      aria-controls="flush-collapseTwo"
                    >
                      <span className="accordion-arrow">
                        <i className="ph ph-caret-right"></i>
                      </span>
                      <p className="font-size-20 font-weight-600 text-primary">
                        <span className="pl-2">{t("Beschaffungsliste")}</span>
                      </p>
                    </button>
                  </div>
                  <div>
                    <button className="btn btn-primary mb-0" onClick={handleClick}>
                      <i class="ph ph-plus"></i>{" "}
                      <span className="position-relative top--4">
                        {t("Add")}
                      </span>
                    </button>
                  </div>
                </div>
              </h2>
              <div
                id="flush-collapseTwo"
                className="accordion-collapse collapse show"
                data-bs-parent="#accordionFlushExample"
              >
                <div className="accordion-body">
                    <div className="pt-2"></div>
                  {ready ? (
                    props.show == "popup" ? (
                      <IndexTablePopUp
                        project_id={props?.projectid ? props?.projectid : 0}
                      />
                    ) : (
                      <IndexTableProject
                        project_id={props?.projectid ? props?.projectid : 0}
                        products={props?.products}
                        ref={bButtonRef}
                      />
                    )
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* <SoftBox>
            <div className="card">
              <div className="card-header pb-0">
                <SoftTypography variant="h6">
                  {t("Beschaffungsliste")}
                </SoftTypography>
              </div>
              <div className="card-body px-1">
                {ready ? (
                  props.show == "popup" ? (
                    <IndexTablePopUp
                      project_id={props?.projectid ? props?.projectid : 0}
                    />
                  ) : (
                    <IndexTableProject
                      project_id={props?.projectid ? props?.projectid : 0}
                    />
                  )
                ) : (
                  ""
                )}
              </div>
            </div>
          </SoftBox> */}
        </div>
      </div>
    </>
  );
}

export default Index;
