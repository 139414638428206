import { React, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Navigate, useLocation, useParams } from "react-router-dom";
import { callFetch } from "../../../helpers/callFetch";
import { useTranslation } from 'react-i18next';
import DepartmentManageModal from "./DepartmentManageModal";
import DesignationManageModal from "./DesignationManageModal";
import ImagePopup from "./ImagePopup";
import SoftSnackbar from "components/SoftSnackbar";
import { GoogleMap, useLoadScript, Autocomplete } from '@react-google-maps/api';
import Header from "./components/Header";
import { NavLink } from 'react-router-dom'
import Grid from "@mui/material/Grid";
import SoftBox from "components/SoftBox";

function EmpolyeeAdd({ onImageSelect, nameChange, showData, title }) {
    const { t } = useTranslation();
    const params = useParams();
    const [designations, setDesignations] = useState([]);
    const [roles, setRoles] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [saving, setSaving] = useState(false);
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [refresh, setRefresh] = useState(0);
    const [refresh2, setRefresh2] = useState(0);
    const [selectedImage, setSelectedImage] = useState(null);
    const [rawImage, setRawImage] = useState(null);
    const [isModalVisible, setModalVisible] = useState(false);
    const [files, setFile] = useState();
    const [image, setImage] = useState();
    const [employeeId, setEmployeeId] = useState('');
    const [receivedError, setReceivedError] = useState(null);

    const {
        register,
        handleSubmit,
        setError,
        setValue,
        formState: { errors },
    } = useForm();

    const [autocomplete, setAutocomplete] = useState(null);
    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
        libraries: ["places"]
    })

    const onLoad = (autocomplete) => {
        //console.log('autocomplete: ', autocomplete);
        setAutocomplete(autocomplete);
    }

    const onPlaceChanged = () => {
        if (autocomplete !== null) {
            //console.log('onPlaceChanged')
            //console.log(autocomplete)

            var place = autocomplete.getPlace();
            //console.log('address_components');
            setValue('plz', '');
            setValue('nr', '');
            setValue('ort', '');
            setValue('address', '');

            if (place?.address_components) {
                for (let [key, value] of Object.entries(place?.address_components)) {
                    if (value?.types) {
                        if (value?.types.indexOf("postal_code") !== -1) {
                            setValue('plz', value?.long_name);
                        } else if (value?.types.indexOf("street_number") !== -1) {
                            setValue('nr', value?.long_name);
                        } else if (value?.types.indexOf("locality") !== -1) {
                            setValue('ort', value?.long_name);
                        } else if (value?.types.indexOf("route") !== -1) {
                            setValue('address', value?.long_name);
                        }
                    }
                }
            }
            setValue('lat', place.geometry.location.lat());
            setValue('lng', place.geometry.location.lng());
        } else {
            console.log('Autocomplete is not loaded yet!')
        }
    }

    //image crop modal
    const closeModal = () => {
        if (files) {
            const reader = new FileReader();
            reader.onload = () => {
                onImageSelect(URL.createObjectURL(files))
                setImage(files)
            }
            reader.readAsDataURL(files);
        }
        setModalVisible(false);
    }
    const openModal = () => {
        setModalVisible(true);
    };

    const croppedPhoto = async (photo) => {
        try {
            const response = await fetch(photo.url);
            const blob = await response.blob();
            // Create a File object from the Blob
            const file = new File([blob], 'photo.jpg', {
                type: 'image/jpeg', // Set the appropriate MIME type
                lastModified: new Date().getTime(), // Set the current timestamp
            });

            onImageSelect(URL.createObjectURL(file))
            setImage(file)
            setModalVisible(false);
        } catch (error) {
            console.error('Error fetching and creating file:', error);
        }
    }

    const handleImageChange = (event) => {
        const file = event.target.files[0]
        setFile(file)
        if (file) {
            setSelectedImage(URL.createObjectURL(file));
            const reader = new FileReader();
            reader.onload = () => {
                setSelectedImage(URL.createObjectURL(file));
                openModal();
            }
            reader.readAsDataURL(file);
        }
    }
    const handleInputChange = (event) => {
        const { name, value } = event.target;

        if (name) {
            let selectedOptionText = '';

            if (event.target.nodeName === 'SELECT') {
                const selectedOption = event.target.options[event.target.selectedIndex];
                selectedOptionText = selectedOption ? selectedOption.text : '';
            } else if (event.target.nodeName === 'INPUT' && event.target.type === 'text') {
                selectedOptionText = `${value}`; // Customize this value as needed
            }

            nameChange({
                ...showData,
                [name]: value,
                [name]: selectedOptionText,
            });
        }
    };
    useEffect(() => {
        callFetch("designations", "GET", []).then((res) => {
            setDesignations(res?.data);
            setRefresh2(refresh2 + 1)
        });

        callFetch("departments", "GET", []).then((res) => {
            setDepartments(res?.data);
        });

    }, [refresh])

    useEffect(() => {
        if (params?.id && refresh2) {
            callFetch("employees/create", "GET", []).then((res) => {
                setRoles(res?.data?.roles);
            });

            callFetch("employees/" + params.id + "/edit", "GET", []).then((res) => {
                setValue('employee_identity_number', res?.data?.employee_identity_number);
                setValue('name', res?.data?.name);
                setValue('email', res?.data?.email);
                setValue('mobile', (res?.data?.mobile == null || res?.data?.mobile == 'null' ? "" : res?.data?.mobile));
                setValue('role', res?.data?.role);
                setValue('gender', res?.data?.gender);
                setValue('department_id', res?.data?.department_id);
                setValue('joining_date', res?.data?.joining_date);
                setValue('date_of_birth', res?.data?.date_of_birth);
                setValue('address', (res?.data?.address == null || res?.data?.address == 'null' ? "" : res?.data?.address));
                setValue('plz', (res?.data?.plz == null || res?.data?.plz == 'null' ? "" : res?.data?.plz));
                setValue('nr', (res?.data?.nr == null || res?.data?.nr == 'null' ? "" : res?.data?.nr));
                setValue('ort', (res?.data?.ort == null || res?.data?.ort == 'null' ? "" : res?.data?.ort));
                setValue('full_address', (res?.data?.address == null || res?.data?.address == 'null' ? "" : res?.data?.address) + (res?.data?.nr && res?.data?.nr != 'null' ? ' ' + res?.data?.nr : ''));
            });
        } else {
            callFetch("employees/create", "GET", []).then((res) => {
                setValue('employee_identity_number', res.data.id);
                setRoles(res?.data?.roles);
            });
        }

    }, [refresh2, params?.id]);
    const [successSB, setSuccessSB] = useState(false);
    const openSuccessSB = () => setSuccessSB(true);
    const closeSuccessSB = () => setSuccessSB(false);
    const renderSuccessSB = (
        <SoftSnackbar
            color="success"
            icon="check"
            title="Success"
            content="Employee successfully updated"
            dateTime="Just Now"
            open={successSB}
            onClose={closeSuccessSB}
            close={closeSuccessSB}
            bgWhite
        />
    );


    const onSubmit = (formData) => {
        formData.photo = !image ? '' : image;
        setSaving(true);
        callFetch(params?.id ? `employees/${params?.id}` : "employees", "POST", formData, setError).then((res) => {
            setSaving(false);
            if (receivedError == null && res.message === 'success' && params?.id) {
                openSuccessSB();
            }
            setEmployeeId(res.employee_identity_number);
            if (!res.ok) return;
            setSubmitSuccess(true);
        });
    };

    if (loadError) return "Error loading maps"
    if (!isLoaded) return;// "Loading Maps"

    return submitSuccess && employeeId ? <Navigate to={'/employees/' + employeeId + '?step=1'} /> :
        <>
            <form className={`needs-validation ${Object.keys(errors).length ? "was-validated" : ""}`} onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">

                {
                    params?.id && <input type="hidden" defaultValue="PUT" {...register("_method")} />
                }

                <div className="container">
                    <div className="card">
                        <div className="card-body">
                            <div className="card mb-3 bg-secondary box-shadow-none">
                                <div className="card-header pb-0 bg-secondary">
                                    <p className="table-title-cs mb-0">{t('Kontakdaten')}</p>
                                </div>
                                <div className="card-body">

                                    <div className="d-flex justify-content-center">
                                        {params?.id ?
                                            <><Header imageUrl={onImageSelect} data={showData} /></>
                                            : (
                                                <>

                                                    <SoftBox>
                                                        <Grid container spacing={3} alignItems="center">
                                                            <Grid item>
                                                                <img
                                                                    src={image ? URL.createObjectURL(image) : '/assets/img/placeholder.png'}
                                                                    alt="profile-image"
                                                                    className="avatar-cs box-shadow-none border-radius-100 border-primary"
                                                                    accept={'.jpg,.png,.jpeg'}
                                                                />
                                                                <label
                                                                    htmlFor="imageUpload"
                                                                    className="ph ph-camera border-primary"
                                                                    //onClick={() => uploadImage()}
                                                                    style={{
                                                                        position: 'absolute',
                                                                        marginTop: '-33px',
                                                                        marginLeft: '43px',
                                                                        background: '#fff',
                                                                        width: '32px',
                                                                        height: '32px',
                                                                        borderRadius: '5px',
                                                                        color: '#208DFC',
                                                                        fontSize: '20px',
                                                                        textAlign: 'center',
                                                                        padding: '5px',
                                                                        cursor: 'pointer'
                                                                    }}
                                                                ></label>

                                                                <input
                                                                    id="imageUpload"
                                                                    type="file"
                                                                    {...register("photo")}
                                                                    onChange={(e) => {
                                                                        //handleImageChange(e)
                                                                        setImage(e.target.files[0])
                                                                    }}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </SoftBox>

                                                    {/*
                                                    <div className="col-md-6">
                                                        <label>{t('Profile Picture')}</label>
                                                        <input type="file" className="form-control" {...register("photo")} onChange={handleImageChange} />
                                                        <div className="invalid-feedback">{errors.photo && errors.photo.message}</div>
                                                    </div>
                                                    */}
                                                </>
                                            )
                                        }
                                    </div>

                                    <div className="row">
                                        <div className="col-12 col-md-6 col-lg-6">
                                            <div className="form-group mb-2">
                                                <label>
                                                    {t('Employee ID')} *
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder={t('eg. 123')}
                                                    {...register("employee_identity_number", {
                                                        required: true,
                                                    })}
                                                    required
                                                    readOnly
                                                />
                                                <div className="invalid-feedback">{errors.employee_identity_number && errors.employee_identity_number.message}</div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group mb-2">
                                                <label>
                                                    {t('Employee Name')} *
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder={t('eg. Jhon Doe')}
                                                    {...register("name", {
                                                        required: true,
                                                    })}
                                                    required
                                                    onChange={handleInputChange}
                                                />
                                                <div className="invalid-feedback">{errors.name && errors.name.message}</div>
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="form-group mb-2">
                                                <label>
                                                    {t('Employee Email')} *
                                                </label>
                                                <input
                                                    type="email"
                                                    className="form-control"
                                                    placeholder={t('eg. email@mail.com')}
                                                    {...register("email", {
                                                        required: true,
                                                    })}
                                                    required
                                                />
                                                <div className="invalid-feedback">{errors.email && errors.email.message}</div>
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="form-group mb-2">
                                                <label>
                                                    {t('Mobile')}
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder={t('eg. 98765432')}
                                                    {...register("mobile")} />
                                                <div className="invalid-feedback">{errors.mobile && errors.mobile.message}</div>
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="form-group mb-2">
                                                <label>
                                                    {t('Gender')}
                                                </label>
                                                <select
                                                    className="form-control"
                                                    {...register("gender")}>
                                                    <option value="">--</option>
                                                    <option value="Male">{t('Male')}</option>
                                                    <option value="Female">{t('Female')}</option>
                                                    <option value="Other">{t('Others')}</option>
                                                </select>
                                                <div className="invalid-feedback">{errors.gender && errors.gender.message}</div>
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="form-group mb-2">
                                                <label>
                                                    {t('Date of Birth')}
                                                </label>
                                                <input
                                                    type="date"
                                                    className="form-control flatpickr"
                                                    placeholder={t('eg. 16-04-2022')}
                                                    {...register("date_of_birth")} />
                                                <div className="invalid-feedback">{errors.date_of_birth && errors.date_of_birth.message}</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-6">
                                            <Autocomplete
                                                options={{
                                                    componentRestrictions: { country: "de" },
                                                    //fields: ["address_components", "geometry", "icon", "name"],
                                                    //types: ["establishment"]
                                                }}
                                                onLoad={onLoad}
                                                onPlaceChanged={onPlaceChanged}
                                            >
                                                <div className="form-group mb-2">
                                                    <label>{t('Straße')} *</label>
                                                    <input
                                                        type="text"
                                                        placeholder={t('')}
                                                        className="form-control"
                                                        {...register("full_address", {
                                                            required: false,
                                                        })}

                                                    />
                                                    <div className="invalid-feedback d-block">{errors.address ? errors.address.message : (errors.nr ? t('Please add a street nr.') : '')}</div>
                                                </div>
                                            </Autocomplete>
                                            <div className="form-group mb-2 d-none">
                                                <label>{t('Straße')} *</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder={t('')}
                                                    {...register("address", {
                                                        required: false,
                                                    })}
                                                />
                                                <div className="invalid-feedback">{errors.address && errors.address.message}</div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group mb-2">
                                                <label>{t('Nr')} *</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder={t('')}
                                                    {...register("nr", {
                                                        required: false,
                                                    })}
                                                    readOnly
                                                />
                                                <div className="invalid-feedback">{errors.nr && errors.nr.message}</div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group mb-2">
                                                <label>{t('PLZ')} *</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder={t('')}
                                                    {...register("plz", {
                                                        required: false,
                                                    })}
                                                    readOnly
                                                />
                                                <div className="invalid-feedback">{errors.plz && errors.plz.message}</div>
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="form-group mb-2">
                                                <label>{t('Ort')} *</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder={t('')}
                                                    {...register("ort", {
                                                        required: false,
                                                    })}
                                                    readOnly
                                                />
                                                <div className="invalid-feedback">{errors.ort && errors.ort.message}</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">

                                        <div className="col-md-6">
                                            <label>
                                                {t('Password')} *
                                            </label>
                                            <input
                                                type="password"
                                                className="form-control"
                                                placeholder={t('eg.') + " ********"}
                                                {...register("password", {
                                                    required: params?.id ? false : true,
                                                })}
                                                required={params?.id ? false : true}
                                            />
                                            <div className="invalid-feedback">{errors.password && errors.password.message}</div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="form-group mb-2">
                                                <label>
                                                    {t('Role')} *
                                                </label>
                                                <br />
                                                <select
                                                    className="form-control"
                                                    {...register("role", {
                                                        required: true,
                                                    })}
                                                    required onChange={handleInputChange}>
                                                    <option value="">--</option>
                                                    {roles && roles.map((role) => (
                                                        <option key={role.id} value={role.id}>{t(role.name)}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="form-group mb-0">
                                                <label>
                                                    {t('Department')} *
                                                </label>
                                                <br />
                                                <select
                                                    className="form-control"
                                                    {...register("department_id", {
                                                        required: true,
                                                    })}
                                                    required
                                                    onChange={handleInputChange}
                                                >
                                                    <option value="">--</option>
                                                    {departments && departments.map((department) => (
                                                        <option key={department.id} value={department.id}>{department.name}</option>
                                                    ))}
                                                </select>
                                                &nbsp;
                                                <div className="invalid-feedback">{errors.department_id && errors.department_id.message}</div>
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="form-group mb-0">
                                                <label>
                                                    {t('Joining Date')} *
                                                </label>
                                                <input
                                                    type="date"
                                                    className="form-control flatpickr"
                                                    placeholder={t('eg. 16-04-2022')}
                                                    {...register("joining_date", {
                                                        required: true,
                                                    })}
                                                    required
                                                />
                                                <div className="invalid-feedback">{errors.joining_date && errors.joining_date.message}</div>
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <button className="btn btn-secondary d-flex" type="button" data-bs-toggle="modal" data-bs-target="#departmentModal"><i className="ph ph-plus me-2"></i> {t('Add')}</button>
                                        </div>


                                        {/*
                                    <div className="col-12">
                                        {!saving && (
                                            <button type="submit" className="btn btn-primary">
                                                {t('Save')}
                                            </button>
                                        )}
                                        {saving && (
                                            <button type="submit" className="btn btn-disabled" disabled>
                                                {t('Saving ...')}
                                            </button>
                                        )}
                                    </div>
                                    */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="card fixed-bottom mb-3 fixed-bottom-width-available" >
                    <div className="card-footer d-flex align-items-center justify-content-end gap-2">

                        <NavLink to="/employees" className="btn btn-secondary m-0 d-flex align-items-center justify-content-between gap-2">
                            <i className='ph ph-caret-left'></i>
                            {t('Back')}
                        </NavLink>

                        {saving ? (
                            <button type="button" className="btn btn-disabled m-0" disabled>
                                {t('Saving ...')}
                            </button>
                        ) : (
                            <button type="submit" className="btn btn-primary m-0 d-flex align-items-center justify-content-between gap-2">
                                {t("Speichern und fortfahren")}
                                <i className='ph ph-caret-right'></i>
                            </button>
                        )}
                    </div>
                </div>
            </form>
            {/* Department, Designation Modal Start */}
            <DesignationManageModal refreshParent={() => setRefresh(refresh + 1)} />
            <DepartmentManageModal refreshParent={() => setRefresh(refresh + 1)} />
            {/* Department, Designation Modal End */}
            <ImagePopup showModal={isModalVisible} openModal={closeModal} photoURL={selectedImage} filePhoto={files} croppedPhoto={croppedPhoto} />

        </>;
}

export default EmpolyeeAdd;
