import { React, useState, useEffect } from "react";
import { NavLink, useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import IndexTable from "./IndexTable";
import Cookies from 'js-cookie';
import SoftSnackbar from "components/SoftSnackbar";

function EmployeeIndex() {
    const { t } = useTranslation();
    let { status } = useParams();
    const [successSB, setSuccessSB] = useState(false);
    const openSuccessSB = () => setSuccessSB(true);
    const closeSuccessSB = () => setSuccessSB(false);
    const renderSuccessSB = (
        <SoftSnackbar
            color="success"
            icon="check"
            title="Success"
            content="Changes saved successfully"
            dateTime="Just Now"
            open={successSB}
            onClose={closeSuccessSB}
            close={closeSuccessSB}
            bgWhite
        />
    );

    useEffect(() => {
        if (status == 'all') {
            openSuccessSB();
        }
    }, []);

    return (
        <>

            <div className='card'>
                <div className='card-header d-flex align-items-center justify-content-between gap-3'>
                    <h3 className='title'>{t('All Call Center')}</h3>
                    <div className='d-flex align-items-center justify-content-end gap-3'>
                        {JSON.parse(Cookies.get('permissions')).indexOf("CC") !== -1 ? (
                            <a target={"_blank"} href={Cookies.get('backend_url') + 'callcenter-export'} className="btn btn-secondary m-0 d-flex align-items-center justify-content-between gap-2">
                                <i className='ph ph-arrow-square-out'></i>
                                {t('Export CSV')}
                            </a>
                        ) : <></>}

                        {JSON.parse(Cookies.get('permissions')).indexOf("CC") !== -1 ? (
                            <NavLink to="/call-center/create" className="btn btn-primary m-0 d-flex align-items-center justify-content-between gap-2">
                                <i className='ph ph-plus'></i>
                                {t('New Call Center')}
                            </NavLink>
                        ) : <></>}
                    </div>
                </div>
                <div className="card-body pb-3">
                    <IndexTable />
                </div>
            </div>

            {renderSuccessSB}
        </>
    );
}

export default EmployeeIndex;
