import { React, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Navigate, useLocation, useParams, useSearchParams } from "react-router-dom";
import { callFetch } from "../../../helpers/callFetch";
import { useTranslation } from 'react-i18next';
import SoftBox from "components/SoftBox";
import { Grid } from "@mui/material";
// Settings page components
import Sidenav from "./components/Sidenav";
import Header from "./components/Header";
import BasicInfo from "./components/BasicInfo";
import MonthlySalary from "./components/MontlySalary/MonthlySalary";
import DeviceManagment from "./components/DeviceManagemt/DeviceManagment";
import StartDateExitDate from "./components/StartDateExitDate/StartDateExitDate";
import Breadcrumbs from "examples/Breadcrumbs";

const EmployeeEdit = () => {
  const { t } = useTranslation();
  const params = useParams();
  const [searchParams] = useSearchParams();
  const step = searchParams.get("step"); // Get the 'step' value from the URL
  const location = useLocation()
  const route = useLocation().pathname.split("/").slice(1);
  const [currentStep, setCurrentStep] = useState(0);
  const [designations, setDesignations] = useState([]);
  const [roles, setRoles] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [saving, setSaving] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const [selectedImageUrl, setSelectedImageUrl] = useState('');
  const [componentShwo, setComponentShwo] = useState("Kontaktdaten")
  const [employee, setEmployee] = useState({});
  const [showData, setShowData] = useState({
    name: '---',
    role: '---',
    department_id: '---',
  });
  const {
    register,
    handleSubmit,
    setError,
    setValue,
    formState: { errors },
  } = useForm();
  const handleImageSelect = (imageUrl) => {
    setSelectedImageUrl(imageUrl);
  };

  const handleRefresh = () => {
    setRefresh(refresh + 1)
  }
  const handleNameChange = (name) => {
    setShowData(name)
  };

  useEffect(() => {
    setCurrentStep(step ? parseInt(step) : 0)
  }, [step]);

  useEffect(() => {
    callFetch("employees/" + params.id + "/edit", "GET", []).then((res) => {
      setEmployee(res.data);
    });

    callFetch("designations", "GET", []).then((res) => {
      setDesignations(res?.data);
    });

    /*
    callFetch("roles", "GET", []).then((res) => {
        setRoles(res.data);
    });
    */

    callFetch("departments", "GET", []).then((res) => {
      setDepartments(res.data);
    });
  }, [refresh, params.id]);

  const onSubmit = (formData) => {
    setSaving(true);
    callFetch("employees", "POST", formData, setError).then((res) => {
      setSaving(false);
      if (!res.ok) return;
      setSubmitSuccess(true);
    });
  };

  const componentSwitch = (value) => {
    setComponentShwo(value)
  }
  return (
    <SoftBox mt={4}>
      <Breadcrumbs
        icon="Home"
        title={route[route.length - 1]}
        route={route}
        light={"light"}
      />
      <div className="mt-3 mb-3" style={{ paddingBottom: '100px' }}>
        <div className="container">
          <div className="step-progress">
            <div className="step-wrapper">
              <div
                className={`step ${currentStep >= 0 ? "completed" : ""}`}
                id="step-1"
              >
                {currentStep > 0 ? <i className="fa fa-check"></i> : "1"}
              </div>
              <div
                className={`step-line ${currentStep > 0 ? "completed" : ""}`}
                id="line-1"
              >
                <span
                  className={`${currentStep == 0 ? "" : "d-none-xs-cp-progress"}`}
                >
                  Kontaktdaten
                </span>
              </div>
            </div>

            <div className="step-wrapper">
              <div
                className={`step ${currentStep >= 1 ? "completed" : ""}`}
                id="step-2"
              >
                {currentStep > 1 ? <i className="fa fa-check"></i> : "2"}
              </div>
              <div
                className={`step-line ${currentStep > 1 ? "completed" : ""}`}
                id="line-2"
              >
                <span
                  className={`${currentStep == 1 ? "" : "d-none-xs-cp-progress"}`}
                >
                  Monatiches Gehalt
                </span>
              </div>
            </div>

            <div className="step-wrapper">
              <div
                className={`step ${currentStep >= 2 ? "completed" : ""}`}
                id="step-2"
              >
                {currentStep > 1 ? <i className="fa fa-check"></i> : "3"}
              </div>
              <div
                className={`step-line ${currentStep > 2 ? "completed" : ""}`}
                id="line-2"
              >
                <span
                  className={`${currentStep == 2 ? "" : "d-none-xs-cp-progress"}`}
                >
                  Geräteverwaltung
                </span>
              </div>
            </div>

            <div className="step-wrapper" style={{ flexGrow: "0" }}>
              <div
                className={`step ${currentStep >= 3 ? "completed" : ""}`}
                id="step-2"
              >
                {currentStep > 3 ? <i className="fa fa-check"></i> : "4"}
              </div>
              <div>
                <span
                  className={`${currentStep == 3 ? "" : "d-none-xs-cp-progress"}`}
                >
                  Beginndatum/Austrittsdatum
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="step-card">
          {currentStep === 0 && (
            <div className="step-content">
              {/* <h3>Step 1</h3> */}
              <BasicInfo onImageSelect={handleImageSelect} nameChangeValue={handleNameChange} showData={showData} />
            </div>
          )}

          {currentStep === 1 && (
            <div className="step-content">
              <MonthlySalary refreshData={handleRefresh} employee={employee} title={componentShwo} />
            </div>
          )}

          {currentStep === 2 && (
            <div className="step-content">
              <DeviceManagment employee={employee} title={componentShwo} refreshData={handleRefresh} />
            </div>
          )}

          {currentStep === 3 && (
            <div className="step-content">
              <StartDateExitDate refreshData={handleRefresh} employee={employee} title={componentShwo} />
            </div>
          )}
        </div>
      </div>
    </SoftBox>
  )
}

export default EmployeeEdit
