import { React, useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';

function FileUploader({ onChange, refreshKey= '', attachments = [], attachmentsBasePath = '', previewTitle = 'Uploaded Files', supportedFileType = ['png', 'jpg'], maxFileSizeMB = 1, multiple = false, onlyUploader = false, id = '', idPrefix = '' }) {
    const { t } = useTranslation();
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [validFiles, setValidFiles] = useState([]); // Fixed typo
    const [currentAttachments, setCurrentAttachments] = useState([]);

    const handleFileChange = (e) => {
        let files = Array.from(e.target.files);
        if (!multiple) {
            setUploadedFiles([])
            setValidFiles([])
            files = files.slice(0, 1); // Ensure only one file is selected if multiple is false
        }

        const newUploadedFiles = [];
        const newValidFiles = [];

        files.forEach(file => {
            const fileType = file.name.split('.').pop().toLowerCase();
            const fileSizeMB = file.size / (1024 * 1024);

            let finalStatus = '';
            if (!supportedFileType.includes(fileType)) {
                finalStatus = 'error';
            }

            if (fileSizeMB > maxFileSizeMB) {
                finalStatus = 'error';
            }

            const newFile = {
                name: file.name,
                progress: 0,
                status: finalStatus || 'uploading'
            };

            newUploadedFiles.push(newFile);

            if (!finalStatus) {
                newValidFiles.push(file);
            }

            // Simulate upload progress
            let progress = 0;
            const interval = setInterval(() => {
                progress += 25;
                setUploadedFiles((prevFiles) =>
                    prevFiles.map(f => f.name === file.name ? { ...f, progress } : f)
                );

                if (progress === 100) {
                    clearInterval(interval);
                    setUploadedFiles((prevFiles) =>
                        prevFiles.map(f => f.name === file.name ? { ...f, status: finalStatus || "completed" } : f)
                    );
                }
            }, 100);
        });

        setUploadedFiles((prevFiles) => [...prevFiles, ...newUploadedFiles]);
        setValidFiles((prevValid) => [...prevValid, ...newValidFiles]);

        e.target.value = "";
    };

    const handleFileRemove = (fileName, type) => {
        if (type === 'files') {
            setUploadedFiles((prevFiles) => prevFiles.filter(f => f.name !== fileName));
            setValidFiles((prevValid) => prevValid.filter(f => f.name !== fileName));
        } else if (type === 'attachment') {
            const updatedAttachments = currentAttachments.filter(att => att.fileName !== fileName);
            setCurrentAttachments(updatedAttachments);
            onChange(updatedAttachments, 'attachment');
        }
    };

    useEffect(() => {
        onChange(validFiles, 'files')
    }, [validFiles.length, validFiles]);

    useEffect(() => {
        setCurrentAttachments(attachments)
    }, [attachments.length]);

    useEffect(() => {
        if(!refreshKey) return;
        setUploadedFiles([]);
        setValidFiles([]);
        setCurrentAttachments([]);
    }, [refreshKey]);

    return (
        <>
            {onlyUploader ? (
                <>
                    <div className="form-group">
                        <label htmlFor={id ? id : "upload_file" + idPrefix} className="cursor-pointer upload_file">
                            <div>
                                <i className="ph ph-cloud-arrow-up text-brand-primary title-xl"></i>
                                <p className="m-0 text-brand-primary text-md font-weight-600 ">Click to upload</p>
                                <p className="m-0 text-secondary text-sm-normal">{supportedFileType.join(', ')}</p>
                                <p className="m-0 text-secondary text-sm-normal">(max. {maxFileSizeMB}MB)</p>
                            </div>
                        </label>
                        <input
                            id={id ? id : "upload_file" + idPrefix}
                            type="file"
                            className="form-control d-none"
                            onChange={handleFileChange}
                            multiple={multiple}
                            accept={supportedFileType.map(type => `.${type}`).join(',')}
                        />
                    </div>

                    {uploadedFiles.length > 0 && (
                        <>
                            <div className="form-group">
                                <h6 className="text-primary text-lg m-0">{previewTitle}</h6>
                                {uploadedFiles.map((file, index) => (
                                    <div key={index} className="bg-secondary border-radius d-flex align-items-center justify-content-between gap-2 p-12px mt-2">
                                        <i className={`ph ${file.status === "error" ? "ph-file-x text-status-error-primary" : "ph-file-text text-primary"} title-xl`}></i>
                                        <div className="flex-fill text-overflow-ellipsis">
                                            <div className="d-flex align-items-center justify-content-between gap-2">
                                                <p title={file.name} className={`text-overflow-ellipsis m-0 mb-1 text-link ${file.status === "error" ? "text-status-error-primary" : "text-primary"}`}>
                                                    {file.name}
                                                </p>
                                                <i className="ph ph-x text-primary text-xl cursor-pointer" onClick={() => handleFileRemove(file.name, 'files')}></i>
                                            </div>
                                            <div className="progress">
                                                <div
                                                    className={`progress-bar ${file.status === "error" ? "bg-danger" : file.progress === 100 ? "bg-success" : "bg-primary"}`}
                                                    style={{ width: `${file.progress}%` }}
                                                    aria-valuenow={file.progress}
                                                    aria-valuemin="0"
                                                    aria-valuemax="100"
                                                ></div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </>
                    )}
                </>
            ) : (
                <>
                    <div className="card">
                        <div className="card-header">
                            <h6 className="title">{t('Datenblätter etc.')}</h6>
                        </div>
                        <div className="card-body pb-3">
                            <label htmlFor="upload_file" className="cursor-pointer upload_file">
                                <div>
                                    <i className="ph ph-cloud-arrow-up text-brand-primary title-xl"></i>
                                    <p className="m-0 text-brand-primary text-md font-weight-600 ">Click to upload</p>
                                    <p className="m-0 text-secondary text-sm-normal">{supportedFileType.join(', ')}</p>
                                    <p className="m-0 text-secondary text-sm-normal">(max. {maxFileSizeMB}MB)</p>
                                </div>
                            </label>
                            <input
                                id="upload_file"
                                type="file"
                                className="form-control d-none"
                                onChange={handleFileChange}
                                multiple={multiple}
                                accept={supportedFileType.map(type => `.${type}`).join(',')}
                            />
                        </div>

                        {uploadedFiles.length > 0 && (
                            <>
                                <div className="card-body pb-3">
                                    <h6 className="text-primary text-lg m-0">Uploaded Files</h6>
                                    {uploadedFiles.map((file, index) => (
                                        <div key={index} className="bg-secondary border-radius d-flex align-items-center justify-content-between gap-2 p-12px mt-2">
                                            <i className={`ph ${file.status === "error" ? "ph-file-x text-status-error-primary" : "ph-file-text text-primary"} title-xl`}></i>
                                            <div className="flex-fill text-overflow-ellipsis">
                                                <div className="d-flex align-items-center justify-content-between gap-2">
                                                    <p title={file.name} className={`text-overflow-ellipsis m-0 mb-1 text-link ${file.status === "error" ? "text-status-error-primary" : "text-primary"}`}>
                                                        {file.name}
                                                    </p>
                                                    <i className="ph ph-x text-primary text-xl cursor-pointer" onClick={() => handleFileRemove(file.name, 'files')}></i>
                                                </div>
                                                <div className="progress">
                                                    <div
                                                        className={`progress-bar ${file.status === "error" ? "bg-danger" : file.progress === 100 ? "bg-success" : "bg-primary"}`}
                                                        style={{ width: `${file.progress}%` }}
                                                        aria-valuenow={file.progress}
                                                        aria-valuemin="0"
                                                        aria-valuemax="100"
                                                    ></div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </>
                        )}

                        {currentAttachments.length > 0 && (
                            <div className="card-body pb-3">
                                <h6 className="text-primary text-lg m-0">Attachments</h6>
                                {currentAttachments.map((file, index) => (
                                    <div key={index} className="bg-secondary border-radius d-flex align-items-center justify-content-between gap-2 p-12px mt-2">
                                        <i className="ph ph-file-text text-primary title-xl"></i>
                                        <div className="flex-fill text-overflow-ellipsis">
                                            <div className="d-flex align-items-center justify-content-between gap-2">
                                                <a
                                                    title={file.orginal_name}
                                                    className="text-overflow-ellipsis m-0 mb-1 text-link text-primary"
                                                    target="_blank"
                                                    href={attachmentsBasePath + file.fileName}
                                                >{file.orginal_name}</a>
                                                <i className="ph ph-x text-primary text-xl cursor-pointer" onClick={() => handleFileRemove(file.fileName, 'attachment')}></i>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </>
            )}
        </>

    );
}

export default FileUploader;
