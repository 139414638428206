import { React, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Navigate, useParams } from "react-router-dom";
import { callFetch } from "../../helpers/callFetch";
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import SoftTypography from "components/SoftTypography";
function Edit(props) {
    const { t } = useTranslation();
    const [designations, setDesignations] = useState([]);
    const [roles, setRoles] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [saving, setSaving] = useState(false);
    const [teamMembers, setTeamMembers] = useState(null);
    const [head, setHead] = useState([]);
    const [manager, setManager] = useState([]);
    const [frontofficeAggent, setFrontofficeAggent] = useState([]);
    const [data, setData] = useState(null);
    const [employees, setEmployees] = useState(null);
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [refresh, setRefresh] = useState(0);
    const [teamType, setTeamType] = useState('');

    const {
        register,
        handleSubmit,
        setError,
        setValue,
        getValues,
        reset,
        formState: { errors },
    } = useForm();

    const teamCreateData = (team_type = '') => {
        callFetch("teams/create?team_type=" + team_type, "GET", []).then((res) => {

            var editData = data;
            editData.manager = res.data.manager;
            editData.members = res.data.members;
            editData.headofSales = res.data.headofSales;
            setData(editData);
            setRefresh(refresh + 1);

        });
    }

    useEffect(() => {
        console.log('props?.id', props?.id);
        if (!props?.id) return;
        callFetch("teams/edit/" + props?.id, "GET", []).then((res) => {
            setData(res.data);
            const teamData = res.data.data;
            setValue('team_id', teamData.team_id);
            setValue('frontoffice_agent', teamData.frontoffice_agent);
            setValue('name', teamData.team_name);
            setValue('team_type', teamData.team_type);
            setTeamType(teamData.team_type);


            var allheads = [];
            var allManagers = [];
            var allTeamMembers = [];
            var allFrontofficeAggent = [];
            for (let [key, value] of Object.entries(teamData.head)) {
                if (value?.user?.id) {
                    allheads.push(value?.user);
                }
            }

            for (let [key, value] of Object.entries(teamData.manager)) {
                if (value?.user?.id) {
                    allManagers.push(value?.user);
                }
            }

            for (let [key, value] of Object.entries(teamData.teammember)) {
                if (value?.employe?.id) {
                    allTeamMembers.push(value?.employe);
                }
            }

            for (let [key, value] of Object.entries(teamData.frontoffice_aggent)) {
                if (value?.user?.id) {
                    allFrontofficeAggent.push(value?.user);
                }
            }

            setHead(allheads);
            setManager(allManagers);
            setTeamMembers(allTeamMembers);
            setFrontofficeAggent(allFrontofficeAggent);
        });
    }, [props?.id]);

    const onSubmit = (formData) => {
        if (!props?.id) return;
        setSaving(true);
        formData.team_id = props?.id;
        formData.head_of_sales = JSON.stringify(head);
        formData.team_manager = JSON.stringify(manager);
        formData.teamMembers = JSON.stringify(teamMembers);
        formData.frontoffice_agent = JSON.stringify(frontofficeAggent);
        callFetch("teams/update", "POST", formData, setError).then((res) => {
            setSaving(false);
            document.getElementById('editTeamClose').click();
            if (document.getElementById('refreshTeamList')) {
                document.getElementById('refreshTeamList').click();
            }
            if (!res.ok) return;
            setSubmitSuccess(true);
        });
    };

    return (
        <form className={`needs-validation ${Object.keys(errors).length ? "was-validated" : ""}`} onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
            <div class="modal fade right-side" id="editTeam">
                <div class="modal-dialog modal-dialog-slide-right modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button
                                type="button"
                                id="editTeamClose"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                className=" lose btn btn-icon-only btn-rounded btn-link text-primary mb-0 me-3 btn-sm d-flex align-items-center justify-content-center"
                                style={{
                                    fontSize: "12px",
                                    position: "absolute",
                                    right: "0px",
                                }}
                            >
                                {/* <i class="fa-sharp fa-solid fa-times icon icon-shape icon-sm  text-center d-flex align-items-center justify-content-center"></i> */}
                                <i class="ph ph-x  text-center d-flex align-items-center justify-content-center text-primary font-size-20"></i>
                            </button>
                            <SoftTypography
                                variant="button"
                                fontSize="20px"
                                fontWeight="600"
                                style={{ color: "#273042" }}
                            >
                                {" "}
                                {t("Add Team")}
                            </SoftTypography>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-12 col-md-12">
                                    <label>
                                        {t('Team ID')} *
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder={t('eg. 123')}
                                        {...register("team_id", {
                                            required: true,
                                        })}
                                        required
                                        readOnly
                                    />
                                    <div className="invalid-feedback">{errors.team_id && errors.team_id.message}</div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <label>
                                        {t('Team Name')} *
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control mb-4"
                                        placeholder={t('eg. Sales Team')}
                                        {...register("name", {
                                            required: true,
                                        })}
                                        required
                                    />
                                    <div className="invalid-feedback">{errors.name && errors.name.message}</div>
                                </div>

                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label>{t('Bereich auswählen')} *</label>
                                        <select
                                            className="form-control"
                                            {...register("team_type", {
                                                required: true,
                                                onChange: (e) => {
                                                    teamCreateData(getValues('team_type'));
                                                    setTeamType(e.target.value);
                                                    setHead([]);
                                                    setManager([]);
                                                    setTeamMembers([]);
                                                    setFrontofficeAggent([]);
                                                }
                                            })}
                                            required
                                        >
                                            <option value="">--</option>
                                            <option value="sales">{t('Sales Team')}</option>
                                            <option value="resell">{t('Resell Team')}</option>
                                            <option value="ac">{t('AC Team')}</option>
                                            <option value="dc">{t('DC Team')}</option>

                                        </select>
                                        <div className="invalid-feedback">{errors.team_type && errors.team_type.message}</div>
                                    </div>
                                </div>

                                {teamType ? (
                                    <>
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label>{t(teamType == 'sales' || teamType == 'resell' ? 'Head of Sales' : teamType == 'ac' ? 'Technical Manager AC' : teamType == 'dc' ? 'Technical Manager DC' : '')} hinzufügen *</label>
                                                <Select
                                                    placeholder={t('Select')}
                                                    options={data?.headofSales ? data?.headofSales : { value: '', label: '---' }}
                                                    value={head}
                                                    getOptionLabel={(option) => option?.name}
                                                    getOptionValue={(option) => option?.id}
                                                    onChange={(e) => {
                                                        setHead(e);
                                                        //setValue('teamMembers', JSON.stringify(e));
                                                    }}
                                                    isMulti
                                                />
                                                <div className="invalid-feedback">{errors.head_of_sales && errors.head_of_sales.message}</div>
                                            </div>
                                        </div>

                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label>{t('Team Manager')} hinzufügen</label>
                                                <Select
                                                    placeholder={t('Select')}
                                                    options={data?.manager ? data?.manager : { value: '', label: '---' }}
                                                    value={manager}
                                                    getOptionLabel={(option) => option?.name}
                                                    getOptionValue={(option) => option?.id}
                                                    onChange={(e) => {
                                                        setManager(e);
                                                    }}
                                                    isMulti
                                                />
                                                <div className="invalid-feedback">{errors.team_manager && errors.team_manager.message}</div>
                                            </div>
                                        </div>

                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label>{t('Team Members')} hinzufügen</label>
                                                <Select
                                                    placeholder={t('Select')}
                                                    options={data?.members ? data?.members : { value: '', label: '---' }}
                                                    value={teamMembers}
                                                    getOptionLabel={(option) => option?.name}
                                                    getOptionValue={(option) => option?.id}
                                                    onChange={(e) => {
                                                        setTeamMembers(e);
                                                    }}
                                                    isMulti
                                                />
                                                <div className="invalid-feedback">{errors.teamMembers && errors.teamMembers.message}</div>
                                            </div>
                                        </div>

                                        {teamType == 'sales' || teamType == 'resell' ? (
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label>{t('Innendienstmitarbeiter hinzufügen')}</label>
                                                    <Select
                                                        placeholder={t('Select')}
                                                        options={data?.frontoffice_agent ? data?.frontoffice_agent : { value: '', label: '---' }}
                                                        value={frontofficeAggent}
                                                        getOptionLabel={(option) => option?.name}
                                                        getOptionValue={(option) => option?.id}
                                                        onChange={(e) => {
                                                            setFrontofficeAggent(e);
                                                        }}
                                                        isMulti
                                                    />
                                                    <div className="invalid-feedback">{errors.frontoffice_agent && errors.frontoffice_agent.message}</div>
                                                </div>
                                            </div>
                                        ) : ''}

                                    </>
                                ) : ''}

                                <div className="col-12 mb-4 d-none">
                                    {!saving && (
                                        <button type="submit" className="btn btn-primary">
                                            {t('Save')}
                                        </button>
                                    )}
                                    {saving && (
                                        <button type="submit" className="btn btn-disabled" disabled>
                                            {t('Saving ...')}
                                        </button>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer border-radius-none">
                            <div className="d-flex justify-content-between w-100">
                                <button
                                    type="button"
                                    className="btn btn-secondary me-2 mb-0 w-50"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                >
                                    {t("Cancel")}
                                </button>

                                {!saving && (
                                    <button type="submit" className="btn btn-primary mb-0 w-50">
                                        {t("Save")}
                                    </button>
                                )}
                                {saving && (
                                    <button type="button" className="btn btn-disabled mb-0 w-50" disabled>
                                        {t("Saving ...")}
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    );
}

export default Edit;
