
import { forwardRef } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-select components
import Select, { components } from 'react-select';

// Soft UI Dashboard PRO React base styles
import colors from "assets/theme/base/colors";

// Custom styles for SoftSelect
import styles from "components/SoftSelect/styles";

const CustomDropdownIndicator = (props) => {
  const { menuIsOpen } = props.selectProps; // Detect if menu is open

  return (
    <components.DropdownIndicator {...props}>
      {menuIsOpen ? <i
        className="ph ph-caret-up text-primary"
        style={{
          fontSize: '20px'
        }}
      ></i> : <i
        className="ph ph-caret-down text-primary"
        style={{
          fontSize: '20px'
        }}
      ></i>}
    </components.DropdownIndicator>
  );
};

  const CustomMultiValueRemove = (props) => (
    <components.MultiValueRemove {...props}>
      <i className="ph ph-x" style={{ color: "#273042", fontSize: "14px" }}></i>
    </components.MultiValueRemove>
  );
  
  const CustomClearIndicator = (props) => (
    <components.ClearIndicator {...props}>
      <i className="ph ph-x" style={{ color: "#273042", fontSize: "20px" }}></i>
    </components.ClearIndicator>
  );

const SoftSelect = forwardRef(({ size, error, success, ...rest }, ref) => {
  const { light } = colors;

  return (
    <Select
      {...rest}
      ref={ref}
      styles={styles(size, error, success)}
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary25: light.main,
          primary: light.main,
        },
      })}
      components={{ DropdownIndicator: CustomDropdownIndicator, MultiValueRemove : CustomMultiValueRemove, ClearIndicator : CustomClearIndicator}}
    />
  );
});

// Setting default values for the props of SoftSelect
SoftSelect.defaultProps = {
  size: "medium",
  error: false,
  success: false,
};

// Typechecking props for the SoftSelect
SoftSelect.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),
  error: PropTypes.bool,
  success: PropTypes.bool,
};

export default SoftSelect;
