import React from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import LieferantenIndexTable from './LieferantenIndexTable'

const LieferantenIndex = () => {
    const { t } = useTranslation()
    return (
      <>
               
              <div className="row">
                  <div className="col-12">
                      <div className="card mb-4">
                          <div className="card-header pb-0">
                            <div className='d-flex justify-content-between align-items-center mb-3'>
                                <p className='table-title-cs'>{t('All Lieferanten')}</p>
                                <div>
                                <NavLink to="/lieferanten-partner/create" className="btn d-flex btn-primary mb-0">
                                    <i class="ph ph-plus font-size-20 me-2"></i> {t('Lieferanten Hinzufugen')}
                                </NavLink>
                                </div>
                            </div> 
                          </div>
                          <div className="card-body pt-0 pb-2">
                              <LieferantenIndexTable/>
                          </div>
                      </div>
                  </div>
              </div>
          </>
    )
}

export default LieferantenIndex