import { React, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import callFetch from "helpers/callFetch";
import SoftTypography from "components/SoftTypography";
import Divider from "@mui/material/Divider";
import SoftBox from "components/SoftBox";
import SidenavCard from "components/SidenavCard";

function Sidebar() {
  const { t } = useTranslation();
  const [lastMessage, setLastMessage] = useState([]);
  const [unreadMessage, setUnreadMessage] = useState(0);
  const [lagers, setLager] = useState([]);
  const [authId, setAuthId] = useState("");
  const [nowDrop, setNowDrop] = useState(window.location.href.split("/")[3]);
  const [routePaths, setRoutePaths] = useState([]);
  let navClassName = "nav-link";
  let activeClassName = "nav-link active";
  let dropdownClass = "collapse";
  let dropdownClassShow = "collapse show";

  useEffect(() => {
    callFetch("chatuser-sidebar", "GET", []).then((res) => {
      //   console.log(res.authId);
      setLastMessage(res.lastMessage);
      setAuthId(res.authId);
      setUnreadMessage(res?.unread_message || 0)
    });
  }, [routePaths]);

  useEffect(() => {
    callFetch("lager?select=id,lager_nr", "GET", []).then((res) => {
      setLager(res?.data);
    });
  }, []);

  useEffect(() => {
    const g_sidenav_pinned =
      document.getElementsByClassName("g-sidenav-pinned");
    if (g_sidenav_pinned.length) {
      document.getElementById("iconSidenav").click();
    }

    setRoutePaths(window.location.href.split("/").slice(3));
  }, [window.location.href]);

  return (
    <aside
      className="sidenav navbar navbar-vertical navbar-expand-xs border-0 border-radius my-3 fixed-start ms-3  bg-white"
      id="sidenav-main"
    >
      <div className="sidenav-header">
        <i
          className="fas fa-times p-3 cursor-pointer text-secondary opacity-5 position-absolute end-0 top-0 d-none d-xl-none"
          aria-hidden="true"
          id="iconSidenav"
        />
        <a
          className="navbar-brand m-0"
          href="/"
          style={{
            height: "100%",
            textAlign: "center",
            lineHeight: "75px",
          }}
        >
          <img
            src="/assets/img/solacloud_logo.png"
            className="navbar-brand-img"
            style={{ maxHeight: "16px" }}
            alt="main_logo"
          />
        </a>
      </div>

      <div
        className="collapse navbar-collapse w-auto"
        id="sidenav-collapse-main"
      >
        <ul className="navbar-nav">
          <li className="nav-item">
            <NavLink
              to="/dashboard"
              onClick={(e) => setNowDrop("dashboard")}
              className={({ isActive }) =>
                isActive ? activeClassName : navClassName
              }
            >
              <i className="icon-ChartPieSlice"></i>
              <span className="sidenav-normal">{t("Dashboard")}</span>
            </NavLink>
          </li>

          <li className="nav-item">
            <NavLink
              id="chat-link"
              to={
                !lastMessage
                  ? "/chat"
                  : lastMessage?.sender_id == authId
                  ? "/chat/" + lastMessage?.receiver_id + '/' + lastMessage?.receiver?.name
                  : "/chat/" + lastMessage?.sender_id + '/' + lastMessage?.sender?.name
              }
              onClick={(e) => setNowDrop("chat")}
              disabled={!lastMessage?.receiver_id}
              className={
                nowDrop === "chat"
                  ? activeClassName
                  : navClassName
              }
            >
              <i className="icon-EnvelopeSimple"></i>
              <span className="nav-link-text ms-1">{t("Message")}</span>
              {unreadMessage > 0 && <span className="notification-counter">{unreadMessage > 99 ? '+99' : unreadMessage}</span>}              
            </NavLink>
          </li>

          {JSON.parse(Cookies.get("permissions")).indexOf("TWFCal") !== -1 ? (
            <li className="nav-item mb-3">
              <NavLink
                to="/calendar"
                onClick={(e) => setNowDrop("")}
                className={({ isActive }) =>
                  isActive ? activeClassName : navClassName
                }
              >
                <i className="icon-CalendarDots"></i>
                <span className="nav-link-text ms-1">{t("Calendar")}</span>
              </NavLink>
            </li>
          ) : (
            <></>
          )}

          {JSON.parse(Cookies.get("permissions")).indexOf("PrR") !== -1 ||
          JSON.parse(Cookies.get("permissions")).indexOf("PrC") !== -1 ||
          JSON.parse(Cookies.get("permissions")).indexOf("QR") !== -1 ||
          JSON.parse(Cookies.get("permissions")).indexOf("OR") !== -1 ||
          JSON.parse(Cookies.get("permissions")).indexOf("CusR") !== -1 ||
          JSON.parse(Cookies.get("permissions")).indexOf("PR") !== -1 ||
          JSON.parse(Cookies.get("permissions")).indexOf("MR") !== -1 ||
          JSON.parse(Cookies.get("permissions")).indexOf("SR") !== -1 ||
          JSON.parse(Cookies.get("permissions")).indexOf("CR") !== -1 ||
          JSON.parse(Cookies.get("permissions")).indexOf("Reseller") !== -1 ||
          JSON.parse(Cookies.get("permissions")).indexOf("RecR") !== -1 ||
          JSON.parse(Cookies.get("permissions")).indexOf("TicR") !== -1 ||
          JSON.parse(Cookies.get("permissions")).indexOf("FSOv") !== -1 ? (
            <>
              <Divider />
              <SoftTypography
                display="block"
                variant="caption"
                fontWeight="bold"
                textTransform="uppercase"
                opacity={0.6}
                pl={4}
                mt={0}
                mb={1}
                ml={1}
              >
                {t("Crm")}
              </SoftTypography>
            </>
          ) : (
            <></>
          )}

          {JSON.parse(Cookies.get("permissions")).indexOf("PrR") !== -1 ||
          JSON.parse(Cookies.get("permissions")).indexOf("Fertigmeldung") !==
            -1 ||
          JSON.parse(Cookies.get("permissions")).indexOf("PrC") !== -1 ||
          JSON.parse(Cookies.get("permissions")).indexOf("RecR") !== -1 ? (
            <>
              <li className="nav-item d-flex flex-column gap-1">
                <a
                  data-bs-toggle="collapse"
                  href="#project-management"
                  className={
                    nowDrop === "project-management"
                      ? activeClassName
                      : navClassName
                  }
                  aria-controls="project-management"
                  role="button"
                  aria-expanded={nowDrop === "project-management"}
                >
                  <i className="icon-SolarRoof"></i>
                  <span className="nav-link-text ms-1">
                    {t("Project Management")}
                  </span>
                </a>
                <div
                  className={
                    nowDrop === "project-management"
                      ? dropdownClassShow
                      : dropdownClass
                  }
                  id="project-management"
                >
                  <ul className="nav ms-4 ps-3">
                    {Cookies.get("permissions").indexOf("StaCar") !== -1 ||
                    Cookies.get("permissions").indexOf("SPAnalyAllD") !== -1 ||
                    Cookies.get("permissions").indexOf("SPAnaly") !== -1 ? (
                      <li className="nav-item">
                        <NavLink
                          to="/projects-overview"
                          onClick={(e) => setNowDrop("project-management")}
                          className={({ isActive }) =>
                            isActive ? activeClassName : navClassName
                          }
                        >
                          <span className="sidenav-normal">
                            {t("Projektübersicht")}
                          </span>
                        </NavLink>
                      </li>
                    ) : (
                      <></>
                    )}

                    {JSON.parse(Cookies.get("permissions")).indexOf("PrR") !==
                    -1 ? (
                      <li className="nav-item">
                        <NavLink
                          to="/projects"
                          onClick={(e) => setNowDrop("project-management")}
                          className={({ isActive }) =>
                            isActive ? activeClassName : navClassName
                          }
                        >
                          <span className="sidenav-normal">
                            {t("Projects")}
                          </span>
                        </NavLink>
                      </li>
                    ) : (
                      <></>
                    )}

                    {JSON.parse(Cookies.get("permissions")).indexOf(
                      "Fertigmeldung"
                    ) !== -1 ? (
                      <li className="nav-item">
                        <NavLink
                          to="/fertigmeldung"
                          onClick={(e) => setNowDrop("project-management")}
                          className={({ isActive }) =>
                            isActive ? activeClassName : navClassName
                          }
                        >
                          <span className="sidenav-normal">
                            {t("Fertigmeldung")}
                          </span>
                        </NavLink>
                      </li>
                    ) : (
                      <></>
                    )}

                    {JSON.parse(Cookies.get("permissions")).indexOf("RecR") !==
                    -1 ? (
                      <>
                        <li className="nav-item">
                          <NavLink
                            to="reclamation"
                            onClick={(e) => setNowDrop("project-management")}
                            className={({ isActive }) =>
                              isActive ? activeClassName : navClassName
                            }
                          >
                            <span className="sidenav-normal">
                              {t("Reklamationen")}
                            </span>
                          </NavLink>
                        </li>
                      </>
                    ) : (
                      <></>
                    )}

                    {JSON.parse(Cookies.get("permissions")).indexOf("TicR") !==
                    -1 ? (
                      <>
                        <li className="nav-item">
                          <NavLink
                            to="ticket"
                            onClick={(e) => setNowDrop("project-management")}
                            className={({ isActive }) =>
                              isActive ? activeClassName : navClassName
                            }
                          >
                            <span className="sidenav-normal">
                              {t("Ticket")}
                            </span>
                          </NavLink>
                        </li>
                      </>
                    ) : (
                      <></>
                    )}
                  </ul>
                </div>
              </li>
            </>
          ) : (
            <></>
          )}

          {JSON.parse(Cookies.get("permissions")).indexOf("QR") !== -1 ||
          JSON.parse(Cookies.get("permissions")).indexOf("OR") !== -1 ||
          JSON.parse(Cookies.get("permissions")).indexOf("Reseller") !== -1 ? (
            <li className={"nav-item d-flex flex-column gap-1"}>
              <a
                data-bs-toggle="collapse"
                href="#sales-distribution"
                className={
                  nowDrop === "sales-distribution"
                    ? activeClassName
                    : navClassName
                }
                aria-controls="sales-distribution"
                role="button"
                aria-expanded={nowDrop === "sales-distribution"}
              >
                <i className="icon-Storefront"></i>
                <span className="nav-link-text ms-1">
                  {t("Sales & Distribution")}
                </span>
              </a>
              <div
                className={
                  nowDrop === "sales-distribution"
                    ? dropdownClassShow
                    : dropdownClass
                }
                id="sales-distribution"
              >
                <ul className="nav ms-4 ps-3">
                  {JSON.parse(Cookies.get("permissions")).indexOf("QR") !==
                    -1 ||
                  JSON.parse(Cookies.get("permissions")).indexOf("OR") !==
                    -1 ? (
                    <>
                      <li className="nav-item d-flex flex-column gap-1">
                        <a
                          data-bs-toggle="collapse"
                          href="#Distribution"
                          className={
                            nowDrop === "fieldserviceprojects_Elektromontage"
                              ? activeClassName
                              : navClassName
                          }
                          aria-controls="sales-distribution"
                          role="button"
                          aria-expanded={nowDrop === "sales-distribution"}
                        >
                          <span className="nav-link-text">
                            {t("Distribution")}
                          </span>
                        </a>
                        <div
                          className={
                            nowDrop === "Distribution"
                              ? dropdownClassShow
                              : dropdownClass
                          }
                          id="Distribution"
                        >
                          <ul className="nav">
                            {JSON.parse(Cookies.get("permissions")).indexOf(
                              "QR"
                            ) !== -1 ? (
                              <li className="nav-item">
                                <NavLink
                                  to="/quations"
                                  onClick={(e) =>
                                    setNowDrop("sales-distribution")
                                  }
                                  className={({ isActive }) =>
                                    isActive ? activeClassName : navClassName
                                  }
                                >
                                  <span className="sidenav-normal">
                                    {t("Angebote")}
                                  </span>
                                </NavLink>
                              </li>
                            ) : (
                              <></>
                            )}

                            {JSON.parse(Cookies.get("permissions")).indexOf(
                              "OR"
                            ) !== -1 ? (
                              <>
                                <li className="nav-item">
                                  <NavLink
                                    to="/orders"
                                    onClick={(e) =>
                                      setNowDrop("sales-distribution")
                                    }
                                    className={({ isActive }) =>
                                      isActive ? activeClassName : navClassName
                                    }
                                  >
                                    <span className="sidenav-normal">
                                      {t("Auftrag")}
                                    </span>
                                  </NavLink>
                                </li>
                              </>
                            ) : (
                              <></>
                            )}
                          </ul>
                        </div>
                      </li>
                    </>
                  ) : (
                    <></>
                  )}

                  {JSON.parse(Cookies.get("permissions")).indexOf(
                    "Reseller"
                  ) !== -1 ? (
                    <li className="nav-item d-flex flex-column gap-1">
                      <a
                        data-bs-toggle="collapse"
                        href="#reseller"
                        className={
                          nowDrop === "fieldserviceprojects_Elektromontage"
                            ? activeClassName
                            : navClassName
                        }
                        aria-controls="sales-distribution"
                        role="button"
                        aria-expanded={nowDrop === "distribution"}
                      >
                        <span className="nav-link-text">{t("Reseller")}</span>
                      </a>
                      <div
                        className={
                          nowDrop === "reseller"
                            ? dropdownClassShow
                            : dropdownClass
                        }
                        id="reseller"
                      >
                        <ul className="nav">
                          <li className="nav-item">
                            <NavLink
                              to="/resell-projekte"
                              onClick={(e) => setNowDrop("sales-distribution")}
                              className={({ isActive }) =>
                                isActive ? activeClassName : navClassName
                              }
                            >
                              <span className="sidenav-normal">
                                {t("Resell Projekte")}
                              </span>
                            </NavLink>
                          </li>
                          <li className="nav-item">
                            <NavLink
                              to="/storno-projekte"
                              onClick={(e) => setNowDrop("sales-distribution")}
                              className={({ isActive }) =>
                                isActive ? activeClassName : navClassName
                              }
                            >
                              <span className="sidenav-normal">
                                {t("Storno")}
                              </span>
                            </NavLink>
                          </li>
                        </ul>
                      </div>
                    </li>
                  ) : (
                    <></>
                  )}

                  {JSON.parse(Cookies.get("permissions")).indexOf("CusR") !==
                  -1 ? (
                    <>
                      <li className="nav-item">
                        <NavLink
                          to="/customers"
                          onClick={(e) => setNowDrop("sales-distribution")}
                          className={({ isActive }) =>
                            isActive ? activeClassName : navClassName
                          }
                        >
                          <span className="sidenav-normal">
                            {t("Customer")}
                          </span>
                        </NavLink>
                      </li>
                    </>
                  ) : (
                    <></>
                  )}
                </ul>
              </div>
            </li>
          ) : (
            <></>
          )}

          {JSON.parse(Cookies.get("permissions")).indexOf("FSOv") !== -1 ? (
            <li className={"nav-item d-flex flex-column gap-1 mb-3"}>
              <a
                data-bs-toggle="collapse"
                href="#field-service"
                className={
                  nowDrop === "field-service" ? activeClassName : navClassName
                }
                aria-controls="field-service"
                role="button"
                aria-expanded={nowDrop === "field-service"}
              >
                <i className="icon-Wrench"></i>
                <span className="nav-link-text ms-1">
                  {t("AC / DC Planung")}
                </span>
              </a>
              <div
                className={
                  nowDrop === "field-service" ||
                  nowDrop === "fieldserviceprojects_dachmontage" ||
                  nowDrop === "fieldserviceprojects_Elektromontage"
                    ? dropdownClassShow
                    : dropdownClass
                }
                id="field-service"
              >
                <ul className="nav ms-4 ps-3">
                  <>
                    {JSON.parse(Cookies.get("permissions")).indexOf("FSOv") !==
                    -1 ? (
                      <>
                        <li className="nav-item d-none">
                          <NavLink
                            to="/field-service/overview"
                            onClick={(e) => setNowDrop("field-service")}
                            className={({ isActive }) =>
                              isActive ? activeClassName : navClassName
                            }
                          >
                            <span className="sidenav-normal">
                              {t("Overview")}
                            </span>
                          </NavLink>
                        </li>

                        <li className="nav-item">
                          <NavLink
                            to="/kalendar-ac-dc"
                            onClick={(e) => setNowDrop("field-service")}
                            className={({ isActive }) =>
                              isActive ? activeClassName : navClassName
                            }
                          >
                            <span className="sidenav-normal">
                              {t("Kalendar AC / DC")}
                            </span>
                          </NavLink>
                        </li>

                                                {/*
                                                <li className="nav-item">
                                                    <NavLink to="/leitstand" onClick={(e) => setNowDrop('field-service')} className={({ isActive }) => isActive ? activeClassName : navClassName}>
                                                        <span className="sidenav-normal">{t('Report')}</span>
                                                    </NavLink>
                                                </li>
                                                */}
                                            </>
                                        ) : <></>}

                    {JSON.parse(Cookies.get("permissions")).indexOf(
                      "OnlyDCP"
                    ) !== -1 ? (
                      <>
                        <li className="nav-item d-flex flex-column gap-1">
                          <a
                            data-bs-toggle="collapse"
                            href="#fieldserviceprojects_dachmontage"
                            className={
                              nowDrop === "fieldserviceprojects_dachmontage"
                                ? activeClassName
                                : navClassName
                            }
                            aria-controls="field-service"
                            role="button"
                            aria-expanded={nowDrop === "field-service"}
                          >
                            <span className="nav-link-text">
                              {t("Dachmontage")}
                            </span>
                          </a>
                          <div
                            className={
                              nowDrop === "fieldserviceprojects_dachmontage"
                                ? dropdownClassShow
                                : dropdownClass
                            }
                            id="fieldserviceprojects_dachmontage"
                          >
                            <ul className="nav">
                              <li className="nav-item">
                                <NavLink
                                  to="/field-service/dachmontage/open"
                                  onClick={(e) =>
                                    setNowDrop(
                                      "fieldserviceprojects_dachmontage"
                                    )
                                  }
                                  className={({ isActive }) =>
                                    isActive ? activeClassName : navClassName
                                  }
                                >
                                  <span className="sidenav-normal">
                                    {t("Open Projects")}
                                  </span>
                                </NavLink>
                              </li>

                              <li className="nav-item">
                                <NavLink
                                  to="/field-service/dachmontage/done"
                                  onClick={(e) =>
                                    setNowDrop(
                                      "fieldserviceprojects_dachmontage"
                                    )
                                  }
                                  className={({ isActive }) =>
                                    isActive ? activeClassName : navClassName
                                  }
                                >
                                  <span className="sidenav-normal">
                                    {t("Planed Projects")}
                                  </span>
                                </NavLink>
                              </li>

                              <li className="nav-item">
                                <NavLink
                                  to="/field-service/dachmontage/revision"
                                  onClick={(e) =>
                                    setNowDrop(
                                      "fieldserviceprojects_dachmontage"
                                    )
                                  }
                                  className={({ isActive }) =>
                                    isActive ? activeClassName : navClassName
                                  }
                                >
                                  <span className="sidenav-normal">
                                    {t("Revision Request")}
                                  </span>
                                </NavLink>
                              </li>
                            </ul>
                          </div>
                        </li>
                      </>
                    ) : (
                      <></>
                    )}

                    {JSON.parse(Cookies.get("permissions")).indexOf(
                      "OnlyACP"
                    ) !== -1 ? (
                      <li className="nav-item d-flex flex-column gap-1">
                        <a
                          data-bs-toggle="collapse"
                          href="#fieldserviceprojects_Elektromontage"
                          className={
                            nowDrop === "fieldserviceprojects_Elektromontage"
                              ? activeClassName
                              : navClassName
                          }
                          aria-controls="field-service"
                          role="button"
                          aria-expanded={nowDrop === "field-service"}
                        >
                          <span className="nav-link-text">
                            {t("Elektromontage")}
                          </span>
                        </a>
                        <div
                          className={
                            nowDrop === "fieldserviceprojects_Elektromontage"
                              ? dropdownClassShow
                              : dropdownClass
                          }
                          id="fieldserviceprojects_Elektromontage"
                        >
                          <ul className="nav">
                            <li className="nav-item">
                              <NavLink
                                to="/field-service/elktromontage/open"
                                onClick={(e) =>
                                  setNowDrop(
                                    "fieldserviceprojects_Elektromontage"
                                  )
                                }
                                className={({ isActive }) =>
                                  isActive ? activeClassName : navClassName
                                }
                              >
                                <span className="sidenav-normal">
                                  {t("Open Projects")}
                                </span>
                              </NavLink>
                            </li>

                            <li className="nav-item">
                              <NavLink
                                to="/field-service/elktromontage/done"
                                onClick={(e) =>
                                  setNowDrop(
                                    "fieldserviceprojects_Elektromontage"
                                  )
                                }
                                className={({ isActive }) =>
                                  isActive ? activeClassName : navClassName
                                }
                              >
                                <span className="sidenav-normal">
                                  {t("Planed Projects")}
                                </span>
                              </NavLink>
                            </li>

                            <li className="nav-item">
                              <NavLink
                                to="/field-service/elktromontage/revision"
                                onClick={(e) =>
                                  setNowDrop(
                                    "fieldserviceprojects_Elektromontage"
                                  )
                                }
                                className={({ isActive }) =>
                                  isActive ? activeClassName : navClassName
                                }
                              >
                                <span className="sidenav-normal">
                                  {t("Revision Request")}
                                </span>
                              </NavLink>
                            </li>
                          </ul>
                        </div>
                      </li>
                    ) : (
                      <></>
                    )}
                  </>
                </ul>
              </div>
            </li>
          ) : (
            <></>
          )}

          {JSON.parse(Cookies.get("permissions")).indexOf("CanOver") !== -1 ||
          JSON.parse(Cookies.get("permissions")).indexOf("VkbEva") !== -1 ||
          JSON.parse(Cookies.get("permissions")).indexOf("ProEva") !== -1 ||
          JSON.parse(Cookies.get("permissions")).indexOf("IR") !== -1 ||
          JSON.parse(Cookies.get("permissions")).indexOf("Purchase") !== -1 ||
          JSON.parse(Cookies.get("permissions")).indexOf("PayR") !== -1 ? (
            <>
              <Divider />
              <SoftTypography
                display="block"
                variant="caption"
                fontWeight="bold"
                textTransform="uppercase"
                opacity={0.6}
                pl={4}
                mt={0}
                mb={1}
                ml={1}
              >
                {t("ERP")}
              </SoftTypography>
            </>
          ) : (
            <></>
          )}

          {JSON.parse(Cookies.get("permissions")).indexOf("IR") !== -1 ||
          JSON.parse(Cookies.get("permissions")).indexOf("PayR") !== -1 ? (
            <li className="nav-item d-flex flex-column gap-1 mb-3">
              <a
                data-bs-toggle="collapse"
                href="#management"
                className={
                  nowDrop === "management" ? activeClassName : navClassName
                }
                aria-controls="management"
                role="button"
                aria-expanded={nowDrop === "management"}
              >
                <i className="icon-Coins"></i>
                <span className="nav-link-text ms-1">{t("Management")}</span>
              </a>
              <div
                className={
                  nowDrop === "management" ? dropdownClassShow : dropdownClass
                }
                id="management"
              >
                <ul className="nav ms-4 ps-3">
                  {JSON.parse(Cookies.get("permissions")).indexOf("IR") !==
                  -1 ? (
                    <li className="nav-item d-flex flex-column gap-1">
                      <a
                        data-bs-toggle="collapse"
                        href="#finance-management"
                        className={
                          nowDrop === "finance-management"
                            ? activeClassName
                            : navClassName
                        }
                        aria-controls="finance-management"
                        role="button"
                        aria-expanded={nowDrop === "finance-management"}
                      >
                        <span className="nav-link-text">{t("Finance")}</span>
                      </a>
                      <div
                        className={
                          nowDrop === "finance-management"
                            ? dropdownClassShow
                            : dropdownClass
                        }
                        id="finance-management"
                      >
                        <ul className="nav">
                          {JSON.parse(Cookies.get("permissions")).indexOf(
                            "IR"
                          ) !== -1 ? (
                            <li className="nav-item d-flex flex-column gap-1">
                              <a
                                data-bs-toggle="collapse"
                                href="#invoice-management"
                                className={
                                  nowDrop === "invoice-management"
                                    ? activeClassName
                                    : navClassName
                                }
                                aria-controls="invoice-management"
                                role="button"
                                aria-expanded={nowDrop === "invoice-management"}
                              >
                                <span className="nav-link-text">
                                  {t("Invoices")}
                                </span>
                              </a>
                              <div
                                className={
                                  nowDrop === "invoice-management"
                                    ? dropdownClassShow
                                    : dropdownClass
                                }
                                id="invoice-management"
                              >
                                <ul className="nav ps-3">
                                  {JSON.parse(
                                    Cookies.get("permissions")
                                  ).indexOf("IR") !== -1 ? (
                                    <li className="nav-item">
                                      <NavLink
                                        to="/invoices"
                                        onClick={(e) =>
                                          setNowDrop("invoice-management")
                                        }
                                        className={({ isActive }) =>
                                          isActive
                                            ? activeClassName
                                            : navClassName
                                        }
                                      >
                                        <span className="sidenav-normal">
                                          {t("Invoices")}
                                        </span>
                                      </NavLink>
                                    </li>
                                  ) : (
                                    <></>
                                  )}

                                  {JSON.parse(
                                    Cookies.get("permissions")
                                  ).indexOf("IR") !== -1 ? (
                                    <li className="nav-item">
                                      <NavLink
                                        to="/open-invoice"
                                        onClick={(e) =>
                                          setNowDrop("invoice-management")
                                        }
                                        className={({ isActive }) =>
                                          isActive
                                            ? activeClassName
                                            : navClassName
                                        }
                                      >
                                        <span className="sidenav-normal">
                                          {t("Open Invoice")}
                                        </span>
                                      </NavLink>
                                    </li>
                                  ) : (
                                    <></>
                                  )}

                                  {JSON.parse(
                                    Cookies.get("permissions")
                                  ).indexOf("IR") !== -1 ? (
                                    <li className="nav-item">
                                      <NavLink
                                        to="/eingangsrechnungen"
                                        onClick={(e) =>
                                          setNowDrop("invoice-management")
                                        }
                                        className={({ isActive }) =>
                                          isActive
                                            ? activeClassName
                                            : navClassName
                                        }
                                      >
                                        <span className="sidenav-normal">
                                          {t("Eingangsrechnung")}
                                        </span>
                                      </NavLink>
                                    </li>
                                  ) : (
                                    <></>
                                  )}

                                  {JSON.parse(
                                    Cookies.get("permissions")
                                  ).indexOf("IR") !== -1 ? (
                                    <li className="nav-item">
                                      <NavLink
                                        to="payments"
                                        onClick={(e) =>
                                          setNowDrop("invoice-management")
                                        }
                                        className={({ isActive }) =>
                                          isActive
                                            ? activeClassName
                                            : navClassName
                                        }
                                      >
                                        <span className="sidenav-normal">
                                          {t("Payments")}
                                        </span>
                                      </NavLink>
                                    </li>
                                  ) : (
                                    <></>
                                  )}
                                </ul>
                              </div>
                            </li>
                          ) : (
                            <></>
                          )}

                          {JSON.parse(Cookies.get("permissions")).indexOf(
                            "PayR"
                          ) !== -1 ? (
                            <li className="nav-item">
                              <NavLink
                                to="/payrolls"
                                onClick={(e) =>
                                  setNowDrop("finance-management")
                                }
                                className={({ isActive }) =>
                                  isActive ? activeClassName : navClassName
                                }
                              >
                                <span className="sidenav-normal">
                                  {t("Payrolls")}
                                </span>
                              </NavLink>
                            </li>
                          ) : (
                            <></>
                          )}
                          {JSON.parse(Cookies.get("permissions")).indexOf(
                            "PayR"
                          ) !== -1 ? (
                            <li className="nav-item">
                              <NavLink
                                to="artikels"
                                onClick={(e) =>
                                  setNowDrop("finance-management")
                                }
                                className={({ isActive }) =>
                                  isActive ? activeClassName : navClassName
                                }
                              >
                                <span className="sidenav-normal">
                                  {t("Artikel")}
                                </span>
                              </NavLink>
                            </li>
                          ) : (
                            <></>
                          )}

                          {JSON.parse(Cookies.get("permissions")).indexOf(
                            "PayR"
                          ) !== -1 ? (
                            <li className="nav-item">
                              <NavLink
                                to="/bundle-preis"
                                onClick={(e) =>
                                  setNowDrop("finance-management")
                                }
                                className={({ isActive }) =>
                                  isActive ? activeClassName : navClassName
                                }
                              >
                                <span className="sidenav-normal">
                                  {t("Bundle Preis")}
                                </span>
                              </NavLink>
                            </li>
                          ) : (
                            <></>
                          )}
                        </ul>
                      </div>
                    </li>
                  ) : (
                    <></>
                  )}

                  {JSON.parse(Cookies.get("permissions")).indexOf(
                    "Purchase"
                  ) !== -1 ? (
                    <li className="nav-item d-flex flex-column gap-1">
                      <a
                        data-bs-toggle="collapse"
                        href="#purchase-management"
                        className={
                          nowDrop === "purchase-management"
                            ? activeClassName
                            : navClassName
                        }
                        aria-controls="purchase-management"
                        role="button"
                        aria-expanded={nowDrop === "purchase-management"}
                      >
                        <span className="nav-link-text">
                          {t("Purchase Management")}
                        </span>
                      </a>
                      <div
                        className={
                          nowDrop === "purchase-management"
                            ? dropdownClassShow
                            : dropdownClass
                        }
                        id="purchase-management"
                      >
                        <ul className="nav">
                          <li className="nav-item">
                            <NavLink
                              to="/dc-purchase-management"
                              onClick={(e) => setNowDrop("purchase-management")}
                              className={({ isActive }) =>
                                isActive ? activeClassName : navClassName
                              }
                            >
                              <span className="sidenav-normal">
                                {t("DC Purchase Management")}
                              </span>
                            </NavLink>
                          </li>
                          <li className="nav-item">
                            <NavLink
                              to="/ac-purchase-management"
                              onClick={(e) => setNowDrop("purchase-management")}
                              className={({ isActive }) =>
                                isActive ? activeClassName : navClassName
                              }
                            >
                              <span className="sidenav-normal">
                                {t("AC Purchase Management")}
                              </span>
                            </NavLink>
                          </li>
                          <li className="nav-item">
                            <NavLink
                              to="/einkauf"
                              onClick={(e) => setNowDrop("purchase-management")}
                              className={({ isActive }) =>
                                isActive ? activeClassName : navClassName
                              }
                            >
                              <span className="sidenav-normal">
                                {t("Einkauf")}
                              </span>
                            </NavLink>
                          </li>
                          <li className="nav-item">
                            <NavLink
                              to="/lieferanten-partner"
                              onClick={(e) => setNowDrop("purchase-management")}
                              className={({ isActive }) =>
                                isActive ? activeClassName : navClassName
                              }
                            >
                              <span className="sidenav-normal">
                                {t("Lieferanten & Partner")}
                              </span>
                            </NavLink>
                          </li>
                        </ul>
                      </div>
                    </li>
                  ) : (
                    <></>
                  )}

                  {JSON.parse(Cookies.get("permissions")).indexOf("PR") !==
                  -1 ? (
                    <li className="nav-item d-flex flex-column gap-1">
                      <a
                        data-bs-toggle="collapse"
                        href="#ware-house"
                        className={
                          nowDrop === "ware-house"
                            ? activeClassName
                            : navClassName
                        }
                        aria-controls="ware-house"
                        role="button"
                        aria-expanded={nowDrop === "ware-house"}
                      >
                        <span className="nav-link-text">
                          {t("Lagerverwaltung")}
                        </span>
                      </a>
                      <div
                        className={
                          nowDrop === "ware-house"
                            ? dropdownClassShow
                            : dropdownClass
                        }
                        id="ware-house"
                      >
                        <ul className="nav">
                          {JSON.parse(Cookies.get("permissions")).indexOf(
                            "PR"
                          ) !== -1 ? (
                            <>
                              {JSON.parse(Cookies.get("permissions")).indexOf(
                                "PC"
                              ) !== -1 ? (
                                <>
                                  <li class="nav-item d-flex flex-column gap-1">
                                    <a
                                      class="nav-link collapsed"
                                      data-bs-toggle="collapse"
                                      aria-expanded="false"
                                      href="#UnsereLager"
                                    >
                                      <span class="sidenav-mini-icon"> P </span>
                                      <span class="sidenav-normal">
                                        {" "}
                                        {t("Unsere Lager")}{" "}
                                        <b class="caret"></b>
                                      </span>
                                    </a>
                                    <div class="collapse" id="UnsereLager">
                                      <ul class="nav ps-3">
                                        <li className="nav-item">
                                          <NavLink
                                            to="/ware-houses"
                                            onClick={(e) =>
                                              setNowDrop("ware-house")
                                            }
                                            className={({ isActive }) =>
                                              isActive
                                                ? activeClassName
                                                : navClassName
                                            }
                                          >
                                            <span className="sidenav-normal">
                                              {t("Unsere Lager")}
                                            </span>
                                          </NavLink>
                                        </li>

                                        {lagers.map((lager) => (
                                          <li className="nav-item">
                                            <NavLink
                                              to={"/ware-houses/" + lager?.id}
                                              onClick={(e) =>
                                                setNowDrop("ware-house")
                                              }
                                              className={({ isActive }) =>
                                                isActive
                                                  ? activeClassName
                                                  : navClassName
                                              }
                                            >
                                              <span className="sidenav-normal">
                                                {lager?.lager_nr}
                                              </span>
                                            </NavLink>
                                          </li>
                                        ))}
                                      </ul>
                                    </div>
                                  </li>
                                </>
                              ) : (
                                <></>
                              )}

                              {JSON.parse(Cookies.get("permissions")).indexOf(
                                "PC"
                              ) !== -1 ? (
                                <>
                                  <li className="nav-item">
                                    <NavLink
                                      to="/ware-houses/goods-received"
                                      onClick={(e) => setNowDrop("ware-house")}
                                      className={({ isActive }) =>
                                        isActive
                                          ? activeClassName
                                          : navClassName
                                      }
                                    >
                                      <span className="sidenav-normal">
                                        {t("Wareneingang")}
                                      </span>
                                    </NavLink>
                                  </li>

                                  <li className="nav-item">
                                    <NavLink
                                      to="/ware-houses/goods-out"
                                      onClick={(e) => setNowDrop("ware-house")}
                                      className={({ isActive }) =>
                                        isActive
                                          ? activeClassName
                                          : navClassName
                                      }
                                    >
                                      <span className="sidenav-normal">
                                        {t("Warenausgang")}
                                      </span>
                                    </NavLink>
                                  </li>
                                </>
                              ) : (
                                <></>
                              )}
                            </>
                          ) : (
                            <></>
                          )}
                        </ul>
                      </div>
                    </li>
                  ) : (
                    <></>
                  )}

                  {JSON.parse(Cookies.get("permissions")).indexOf("PR") !==
                  -1 ? (
                    <li className="nav-item d-flex flex-column gap-1">
                      <a
                        data-bs-toggle="collapse"
                        href="#supply-chain"
                        className={
                          nowDrop === "supply-chain"
                            ? activeClassName
                            : navClassName
                        }
                        aria-controls="supply-chain"
                        role="button"
                        aria-expanded={nowDrop === "supply-chain"}
                      >
                        <span className="nav-link-text">{t("Products")}</span>
                      </a>
                      <div
                        className={
                          nowDrop === "supply-chain"
                            ? dropdownClassShow
                            : dropdownClass
                        }
                        id="supply-chain"
                      >
                        {JSON.parse(Cookies.get("permissions")).indexOf(
                          "PR"
                        ) !== -1 ? (
                          <ul class="nav">
                            {JSON.parse(Cookies.get("permissions")).indexOf(
                              "PC"
                            ) !== -1 ? (
                              <>
                                <li className="nav-item">
                                  <NavLink
                                    to="/products/create"
                                    onClick={(e) => setNowDrop("supply-chain")}
                                    className={({ isActive }) =>
                                      isActive ? activeClassName : navClassName
                                    }
                                  >
                                    <span className="sidenav-normal">
                                      {t("New Product")}
                                    </span>
                                  </NavLink>
                                </li>
                              </>
                            ) : (
                              <></>
                            )}

                            {JSON.parse(Cookies.get("permissions")).indexOf(
                              "MR"
                            ) !== -1 ? (
                              <>
                                <li className="nav-item">
                                  <NavLink
                                    to="/zusatzprodukte"
                                    onClick={(e) => setNowDrop("supply-chain")}
                                    className={({ isActive }) =>
                                      isActive ? activeClassName : navClassName
                                    }
                                  >
                                    <span className="sidenav-normal">
                                      {t("Zusatzprodukte")}
                                    </span>
                                  </NavLink>
                                </li>
                              </>
                            ) : (
                              <></>
                            )}

                            {JSON.parse(Cookies.get("permissions")).indexOf(
                              "MR"
                            ) !== -1 ? (
                              <>
                                <li className="nav-item">
                                  <NavLink
                                    to="/products/module"
                                    onClick={(e) => setNowDrop("supply-chain")}
                                    className={({ isActive }) =>
                                      isActive ? activeClassName : navClassName
                                    }
                                  >
                                    <span className="sidenav-normal">
                                      {t("Module")}
                                    </span>
                                  </NavLink>
                                </li>
                              </>
                            ) : (
                              <></>
                            )}

                            {JSON.parse(Cookies.get("permissions")).indexOf(
                              "SR"
                            ) !== -1 ? (
                              <>
                                <li className="nav-item">
                                  <NavLink
                                    to="/products/speicher"
                                    onClick={(e) => setNowDrop("supply-chain")}
                                    className={({ isActive }) =>
                                      isActive ? activeClassName : navClassName
                                    }
                                  >
                                    <span className="sidenav-normal">
                                      {t("Speicher")}
                                    </span>
                                  </NavLink>
                                </li>
                              </>
                            ) : (
                              <></>
                            )}

                            {JSON.parse(Cookies.get("permissions")).indexOf(
                              "SR"
                            ) !== -1 ? (
                              <>
                                <li className="nav-item">
                                  <NavLink
                                    to="/products/wechselrichter"
                                    onClick={(e) => setNowDrop("supply-chain")}
                                    className={({ isActive }) =>
                                      isActive ? activeClassName : navClassName
                                    }
                                  >
                                    <span className="sidenav-normal">
                                      {t("Wechselrichter")}
                                    </span>
                                  </NavLink>
                                </li>
                              </>
                            ) : (
                              <></>
                            )}
                          </ul>
                        ) : (
                          <></>
                        )}
                      </div>
                    </li>
                  ) : (
                    <></>
                  )}

                  {JSON.parse(Cookies.get("permissions")).indexOf("CR") !==
                  -1 ? (
                    <li className={"nav-item d-flex flex-column gap-1"}>
                      <a
                        data-bs-toggle="collapse"
                        href="#call-center"
                        className={
                          nowDrop === "call-center"
                            ? activeClassName
                            : navClassName
                        }
                        aria-controls="call-center"
                        role="button"
                        aria-expanded={nowDrop === "call-center"}
                      >
                        <span className="nav-link-text">
                          {t("Lead-Management")}
                        </span>
                      </a>
                      <div
                        className={
                          nowDrop === "call-center"
                            ? dropdownClassShow
                            : dropdownClass
                        }
                        id="call-center"
                      >
                        <ul className="nav ps-3">
                          {JSON.parse(Cookies.get("permissions")).indexOf(
                            "CC"
                          ) !== -1 ? (
                            <li className="nav-item">
                              <NavLink
                                to="/call-center/create"
                                onClick={(e) => setNowDrop("call-center")}
                                className={({ isActive }) =>
                                  isActive ? activeClassName : navClassName
                                }
                              >
                                <span className="sidenav-normal">
                                  {t("New Call Center")}
                                </span>
                              </NavLink>
                            </li>
                          ) : (
                            <></>
                          )}

                          {JSON.parse(Cookies.get("permissions")).indexOf(
                            "CR"
                          ) !== -1 ? (
                            <li className="nav-item">
                              <NavLink
                                to="/call-center"
                                onClick={(e) => setNowDrop("call-center")}
                                className={({ isActive }) =>
                                  isActive ? activeClassName : navClassName
                                }
                              >
                                <span className="sidenav-normal">
                                  {t("Call Center")}
                                </span>
                              </NavLink>
                            </li>
                          ) : (
                            <></>
                          )}
                        </ul>
                      </div>
                    </li>
                  ) : (
                    <></>
                  )}

                  {JSON.parse(Cookies.get("permissions")).indexOf("ER") !==
                    -1 ||
                  JSON.parse(Cookies.get("permissions")).indexOf("TeamR") !==
                    -1 ? (
                    <>
                      <li className={"nav-item d-flex flex-column gap-1"}>
                        <a
                          data-bs-toggle="collapse"
                          href="#staff"
                          className={
                            nowDrop === "staff" ? activeClassName : navClassName
                          }
                          aria-controls="staff"
                          role="button"
                          aria-expanded={nowDrop === "staff"}
                        >
                          <span className="nav-link-text">
                            {t("Staff Management")}
                          </span>
                        </a>
                        <div
                          className={
                            nowDrop === "staff"
                              ? dropdownClassShow
                              : dropdownClass
                          }
                          id="staff"
                        >
                          <ul className="nav">
                            {JSON.parse(Cookies.get("permissions")).indexOf(
                              "ER"
                            ) !== -1 ? (
                              <li className="nav-item">
                                <NavLink
                                  to="/employees"
                                  onClick={(e) => setNowDrop("staff")}
                                  className={({ isActive }) =>
                                    isActive ? activeClassName : navClassName
                                  }
                                >
                                  <span className="sidenav-normal">
                                    {t("Employees")}
                                  </span>
                                </NavLink>
                              </li>
                            ) : (
                              <></>
                            )}

                            {JSON.parse(Cookies.get("permissions")).indexOf(
                              "TeamR"
                            ) !== -1 ? (
                              <li className="nav-item d-flex flex-column gap-1">
                                <a
                                  data-bs-toggle="collapse"
                                  href="#teams"
                                  className={
                                    nowDrop === "teams"
                                      ? activeClassName
                                      : navClassName
                                  }
                                  aria-controls="call-center"
                                  role="button"
                                  aria-expanded={nowDrop === "teams"}
                                >
                                  <span className="nav-link-text">
                                    {t("Teams")}
                                  </span>
                                </a>
                                <div
                                  className={
                                    nowDrop === "teams"
                                      ? dropdownClassShow
                                      : dropdownClass
                                  }
                                  id="teams"
                                >
                                  <ul className="nav ps-3">
                                    <li className="nav-item">
                                      <NavLink
                                        to="/teams/sales"
                                        onClick={(e) => setNowDrop("teams")}
                                        className={({ isActive }) =>
                                          isActive
                                            ? activeClassName
                                            : navClassName
                                        }
                                      >
                                        <span className="sidenav-normal">
                                          {t("Sales Team")}
                                        </span>
                                      </NavLink>
                                    </li>
                                    <li className="nav-item">
                                      <NavLink
                                        to="/teams/resell"
                                        onClick={(e) => setNowDrop("teams")}
                                        className={({ isActive }) =>
                                          isActive
                                            ? activeClassName
                                            : navClassName
                                        }
                                      >
                                        <span className="sidenav-normal">
                                          {t("Resell Team")}
                                        </span>
                                      </NavLink>
                                    </li>
                                    <li className="nav-item">
                                      <NavLink
                                        to="/teams/ac"
                                        onClick={(e) => setNowDrop("teams")}
                                        className={({ isActive }) =>
                                          isActive
                                            ? activeClassName
                                            : navClassName
                                        }
                                      >
                                        <span className="sidenav-normal">
                                          {t("AC Team")}
                                        </span>
                                      </NavLink>
                                    </li>

                                    <li className="nav-item">
                                      <NavLink
                                        to="/teams/dc"
                                        onClick={(e) => setNowDrop("teams")}
                                        className={({ isActive }) =>
                                          isActive
                                            ? activeClassName
                                            : navClassName
                                        }
                                      >
                                        <span className="sidenav-normal">
                                          {t("DC Team")}
                                        </span>
                                      </NavLink>
                                    </li>
                                  </ul>
                                </div>
                              </li>
                            ) : (
                              <></>
                            )}
                          </ul>
                        </div>
                      </li>
                    </>
                  ) : (
                    <></>
                  )}
                </ul>
              </div>
            </li>
          ) : (
            <></>
          )}

          {JSON.parse(Cookies.get("permissions")).indexOf("CanOver") !== -1 ||
          JSON.parse(Cookies.get("permissions")).indexOf("VkbEva") !== -1 ||
          JSON.parse(Cookies.get("permissions")).indexOf("ProEva") !== -1 ? (
            <>
              <li className={"nav-item d-flex flex-column gap-1 d-none"}>
                <a
                  data-bs-toggle="collapse"
                  href="#controlling"
                  className={
                    nowDrop === "controlling" ? activeClassName : navClassName
                  }
                  aria-controls="controlling"
                  role="button"
                  aria-expanded={nowDrop === "controlling"}
                >
                  <i className="icon-ChartPieSlice"></i>
                  <span className="nav-link-text ms-1">{t("Controlling")}</span>
                </a>
                <div
                  className={
                    nowDrop === "controlling"
                      ? dropdownClassShow
                      : dropdownClass
                  }
                  id="controlling"
                >
                  <ul className="nav ms-4 ps-3">
                    {JSON.parse(Cookies.get("permissions")).indexOf(
                      "CanOver"
                    ) !== -1 ? (
                      <li className="nav-item">
                        <NavLink
                          to="/analyses-overview"
                          onClick={(e) => setNowDrop("controlling")}
                          className={({ isActive }) =>
                            isActive ? activeClassName : navClassName
                          }
                        >
                          <span className="sidenav-normal">
                            {t("Overview")}
                          </span>
                        </NavLink>
                      </li>
                    ) : (
                      <></>
                    )}
                    <li className="nav-item d-flex flex-column gap-1">
                      <a
                        data-bs-toggle="collapse"
                        href="#analyses"
                        className={
                          nowDrop === "analyses"
                            ? activeClassName
                            : navClassName
                        }
                        aria-controls="analyses"
                        role="button"
                        aria-expanded={nowDrop === "analyses"}
                      >
                        <span className="nav-link-text">{t("Analyses")}</span>
                      </a>
                      <div
                        className={
                          nowDrop === "analyses"
                            ? dropdownClassShow
                            : dropdownClass
                        }
                        id="analyses"
                      >
                        <ul className="nav">
                          {JSON.parse(Cookies.get("permissions")).indexOf(
                            "VkbEva"
                          ) !== -1 ? (
                            <li className="nav-item">
                              <NavLink
                                to="/analyses-vkb-evaluation"
                                onClick={(e) => setNowDrop("controlling")}
                                className={({ isActive }) =>
                                  isActive ? activeClassName : navClassName
                                }
                              >
                                <span className="sidenav-normal">
                                  {t("VkbEva")}
                                </span>
                              </NavLink>
                            </li>
                          ) : (
                            <></>
                          )}

                          {JSON.parse(Cookies.get("permissions")).indexOf(
                            "ProEva"
                          ) !== -1 ? (
                            <li className="nav-item">
                              <NavLink
                                to="/analyses-production-evaluation"
                                onClick={(e) => setNowDrop("controlling")}
                                className={({ isActive }) =>
                                  isActive ? activeClassName : navClassName
                                }
                              >
                                <span className="sidenav-normal">
                                  {t("Production evaluation")}
                                </span>
                              </NavLink>
                            </li>
                          ) : (
                            <></>
                          )}
                        </ul>
                      </div>
                    </li>
                  </ul>
                </div>
              </li>
            </>
          ) : (
            <></>
          )}

          {JSON.parse(Cookies.get("permissions")).indexOf("RR") !== -1 ? (
            <>
              <Divider />
              <SoftTypography
                display="block"
                variant="caption"
                fontWeight="bold"
                textTransform="uppercase"
                opacity={0.6}
                pl={4}
                mt={0}
                mb={1}
                ml={1}
              >
                {t("Einstellungen")}
              </SoftTypography>
              <li className="nav-item d-flex flex-column gap-1">
                <a
                  data-bs-toggle="collapse"
                  href="#user-settings"
                  className={
                    nowDrop === "user-settings" ? activeClassName : navClassName
                  }
                  aria-controls="user-settings"
                  role="button"
                  aria-expanded={nowDrop === "user-settings"}
                >
                  <i className="icon-GearSix"></i>
                  <span className="nav-link-text ms-1">{t("Settings")}</span>
                </a>
                <div
                  className={
                    nowDrop === "user-settings"
                      ? dropdownClassShow
                      : dropdownClass
                  }
                  id="user-settings"
                >
                  <ul className="nav ms-4 ps-3">
                    {JSON.parse(Cookies.get("permissions")).indexOf("RR") !==
                    -1 ? (
                      <>
                        <li className="nav-item">
                          <NavLink
                            to="/roles"
                            onClick={(e) => setNowDrop("user-settings")}
                            className={({ isActive }) =>
                              isActive ? activeClassName : navClassName
                            }
                          >
                            <span className="sidenav-normal">
                              {t("Settings")}
                            </span>
                          </NavLink>
                        </li>

                        <li className="nav-item">
                          <NavLink
                            to="/mahnstufe"
                            onClick={(e) => setNowDrop("user-settings")}
                            className={({ isActive }) =>
                              isActive ? activeClassName : navClassName
                            }
                          >
                            <span className="sidenav-normal">
                              {t("Mahnstufe")}
                            </span>
                          </NavLink>
                        </li>

                        <li className="nav-item d-flex flex-column gap-1">
                          <a
                            data-bs-toggle="collapse"
                            href="#email-settings"
                            className={
                              nowDrop === "email-settings"
                                ? activeClassName
                                : navClassName
                            }
                            aria-controls="email-settings"
                            role="button"
                            aria-expanded={nowDrop === "email-settings"}
                          >
                            <span className="nav-link-text">
                              {t("Email Setup")}
                            </span>
                          </a>
                          <div
                            className={
                              nowDrop === "email-settings"
                                ? dropdownClassShow
                                : dropdownClass
                            }
                            id="email-settings"
                          >
                            <ul className="nav ms-3 ps-1                                                                  ">
                              {JSON.parse(Cookies.get("permissions")).indexOf(
                                "RR"
                              ) !== -1 ? (
                                <>
                                  <li className="nav-item d-flex flex-column gap-1">
                                    <a
                                      data-bs-toggle="collapse"
                                      href="#emails-settings"
                                      className={
                                        nowDrop === "email-settings"
                                          ? activeClassName
                                          : navClassName
                                      }
                                      aria-controls="emails-settings"
                                      role="button"
                                      aria-expanded={
                                        nowDrop === "emails-settings"
                                      }
                                    >
                                      <span className="nav-link-text ms-1">
                                        {t("Emails")}
                                      </span>
                                    </a>
                                    <div
                                      className={
                                        nowDrop === "emails-settings"
                                          ? dropdownClassShow
                                          : dropdownClass
                                      }
                                      id="emails-settings"
                                    >
                                      <ul className="nav ms-1 ps-1">
                                        {JSON.parse(
                                          Cookies.get("permissions")
                                        ).indexOf("RR") !== -1 ? (
                                          <>
                                            <li className="nav-item">
                                              <NavLink
                                                to="/settings/emails/angebot/1/edit"
                                                onClick={(e) =>
                                                  setNowDrop("angebot-settings")
                                                }
                                                className={({ isActive }) =>
                                                  isActive
                                                    ? activeClassName
                                                    : navClassName
                                                }
                                              >
                                                <span className="sidenav-normal">
                                                  {"- " + t("Angebot")}
                                                </span>
                                              </NavLink>
                                            </li>
                                            <li className="nav-item">
                                              <NavLink
                                                to="/settings/emails/order/1/edit"
                                                onClick={(e) =>
                                                  setNowDrop("order-settings")
                                                }
                                                className={({ isActive }) =>
                                                  isActive
                                                    ? activeClassName
                                                    : navClassName
                                                }
                                              >
                                                <span className="sidenav-normal">
                                                  {"- " + t("Order")}
                                                </span>
                                              </NavLink>
                                            </li>
                                            <li className="nav-item">
                                              <NavLink
                                                to="/settings/emails/ac-planning/1/edit"
                                                onClick={(e) =>
                                                  setNowDrop(
                                                    "acPlanung-settings"
                                                  )
                                                }
                                                className={({ isActive }) =>
                                                  isActive
                                                    ? activeClassName
                                                    : navClassName
                                                }
                                              >
                                                <span className="sidenav-normal">
                                                  {"- " + t("AC Planung")}
                                                </span>
                                              </NavLink>
                                            </li>
                                            <li className="nav-item">
                                              <NavLink
                                                to="/settings/emails/dc-planning/1/edit"
                                                onClick={(e) =>
                                                  setNowDrop(
                                                    "dcPlanung-settings"
                                                  )
                                                }
                                                className={({ isActive }) =>
                                                  isActive
                                                    ? activeClassName
                                                    : navClassName
                                                }
                                              >
                                                <span className="sidenav-normal">
                                                  {"- " + t("DC Planung")}
                                                </span>
                                              </NavLink>
                                            </li>
                                            <li className="nav-item">
                                              <NavLink
                                                to="/settings/emails/dc-zuruckversetzen/1/edit"
                                                onClick={(e) =>
                                                  setNowDrop(
                                                    "dcZuruckversetzen-settings"
                                                  )
                                                }
                                                className={({ isActive }) =>
                                                  isActive
                                                    ? activeClassName
                                                    : navClassName
                                                }
                                              >
                                                <span className="sidenav-normal">
                                                  {"- " +
                                                    t("DC Zurückversetzen")}
                                                </span>
                                              </NavLink>
                                            </li>
                                            <li className="nav-item">
                                              <NavLink
                                                to="/settings/emails/ac-zuruckversetzen/1/edit"
                                                onClick={(e) =>
                                                  setNowDrop(
                                                    "acZuruckversetzen-settings"
                                                  )
                                                }
                                                className={({ isActive }) =>
                                                  isActive
                                                    ? activeClassName
                                                    : navClassName
                                                }
                                              >
                                                <span className="sidenav-normal">
                                                  {"- " +
                                                    t("AC Zurückversetzen")}
                                                </span>
                                              </NavLink>
                                            </li>
                                            <li className="nav-item">
                                              <NavLink
                                                to="/settings/emails/70-percent-invoice/1/edit"
                                                onClick={(e) =>
                                                  setNowDrop(
                                                    "70_percent_invoice-settings"
                                                  )
                                                }
                                                className={({ isActive }) =>
                                                  isActive
                                                    ? activeClassName
                                                    : navClassName
                                                }
                                              >
                                                <span className="sidenav-normal">
                                                  {"- " +
                                                    t("70% ") +
                                                    t("Invoice")}
                                                </span>
                                              </NavLink>
                                            </li>
                                            <li className="nav-item">
                                              <NavLink
                                                to="/settings/emails/30-percent-invoice/1/edit"
                                                onClick={(e) =>
                                                  setNowDrop(
                                                    "30_percent_invoice-settings"
                                                  )
                                                }
                                                className={({ isActive }) =>
                                                  isActive
                                                    ? activeClassName
                                                    : navClassName
                                                }
                                              >
                                                <span className="sidenav-normal">
                                                  {"- " +
                                                    t("30% " + t("Invoice"))}
                                                </span>
                                              </NavLink>
                                            </li>
                                            <li className="nav-item">
                                              <NavLink
                                                to="/settings/emails/70-percent-payment/1/edit"
                                                onClick={(e) =>
                                                  setNowDrop(
                                                    "70_percent_payment-settings"
                                                  )
                                                }
                                                className={({ isActive }) =>
                                                  isActive
                                                    ? activeClassName
                                                    : navClassName
                                                }
                                              >
                                                <span className="sidenav-normal">
                                                  {"- " +
                                                    t("70% " + t("Payment"))}
                                                </span>
                                              </NavLink>
                                            </li>
                                            <li className="nav-item">
                                              <NavLink
                                                to="/settings/emails/30-percent-payment/1/edit"
                                                onClick={(e) =>
                                                  setNowDrop(
                                                    "30_percent_payment-settings"
                                                  )
                                                }
                                                className={({ isActive }) =>
                                                  isActive
                                                    ? activeClassName
                                                    : navClassName
                                                }
                                              >
                                                <span className="sidenav-normal">
                                                  {"- " +
                                                    t("30% " + t("Payment"))}
                                                </span>
                                              </NavLink>
                                            </li>
                                            <li className="nav-item">
                                              <NavLink
                                                to="/settings/emails/liefertermin-module/1/edit"
                                                onClick={(e) =>
                                                  setNowDrop(
                                                    "liefertermin_module-settings"
                                                  )
                                                }
                                                className={({ isActive }) =>
                                                  isActive
                                                    ? activeClassName
                                                    : navClassName
                                                }
                                              >
                                                <span className="sidenav-normal">
                                                  {"- " +
                                                    t(t("Liefertermin Module"))}
                                                </span>
                                              </NavLink>
                                            </li>
                                            <li className="nav-item">
                                              <NavLink
                                                to="/settings/emails/liefertermin-speicher/1/edit"
                                                onClick={(e) =>
                                                  setNowDrop(
                                                    "liefertermin_speicher-settings"
                                                  )
                                                }
                                                className={({ isActive }) =>
                                                  isActive
                                                    ? activeClassName
                                                    : navClassName
                                                }
                                              >
                                                <span className="sidenav-normal">
                                                  {"- " +
                                                    t(
                                                      t("Liefertermin Speicher")
                                                    )}
                                                </span>
                                              </NavLink>
                                            </li>
                                            <li className="nav-item">
                                              <NavLink
                                                to="/settings/emails/liefertermin-grobhandler/1/edit"
                                                onClick={(e) =>
                                                  setNowDrop(
                                                    "liefertermin_grobhandler-settings"
                                                  )
                                                }
                                                className={({ isActive }) =>
                                                  isActive
                                                    ? activeClassName
                                                    : navClassName
                                                }
                                              >
                                                <span className="sidenav-normal">
                                                  {"- " +
                                                    t(
                                                      t(
                                                        "Liefertermin Großhändler"
                                                      )
                                                    )}
                                                </span>
                                              </NavLink>
                                            </li>
                                            <li className="nav-item">
                                              <NavLink
                                                to="/settings/emails/dc-abnahme/1/edit"
                                                onClick={(e) =>
                                                  setNowDrop(
                                                    "dc_abnahme-settings"
                                                  )
                                                }
                                                className={({ isActive }) =>
                                                  isActive
                                                    ? activeClassName
                                                    : navClassName
                                                }
                                              >
                                                <span className="sidenav-normal">
                                                  {"- " + t(t("DC Abnahme"))}
                                                </span>
                                              </NavLink>
                                            </li>
                                            <li className="nav-item">
                                              <NavLink
                                                to="/settings/emails/ac-abnahme/1/edit"
                                                onClick={(e) =>
                                                  setNowDrop(
                                                    "ac_abnahme-settings"
                                                  )
                                                }
                                                className={({ isActive }) =>
                                                  isActive
                                                    ? activeClassName
                                                    : navClassName
                                                }
                                              >
                                                <span className="sidenav-normal">
                                                  {"- " + t(t("AC Abnahme"))}
                                                </span>
                                              </NavLink>
                                            </li>
                                            <li className="nav-item">
                                              <NavLink
                                                to="/settings/emails/reclamation/1/edit"
                                                onClick={(e) =>
                                                  setNowDrop(
                                                    "reclamation-settings"
                                                  )
                                                }
                                                className={({ isActive }) =>
                                                  isActive
                                                    ? activeClassName
                                                    : navClassName
                                                }
                                              >
                                                <span className="sidenav-normal">
                                                  {"- " + t(t("Reclamation"))}
                                                </span>
                                              </NavLink>
                                            </li>
                                            <li className="nav-item">
                                              <NavLink
                                                to="/settings/emails/ticket/1/edit"
                                                onClick={(e) =>
                                                  setNowDrop("ticket-settings")
                                                }
                                                className={({ isActive }) =>
                                                  isActive
                                                    ? activeClassName
                                                    : navClassName
                                                }
                                              >
                                                <span className="sidenav-normal">
                                                  {"- " + t(t("Ticket"))}
                                                </span>
                                              </NavLink>
                                            </li>
                                          </>
                                        ) : (
                                          <></>
                                        )}
                                      </ul>
                                    </div>
                                  </li>
                                </>
                              ) : (
                                <></>
                              )}
                            </ul>
                          </div>
                        </li>
                      </>
                    ) : (
                      <></>
                    )}
                  </ul>
                </div>
              </li>
            </>
          ) : (
            <></>
          )}

          <SoftBox pt={2} my={2} mx={2} className="d-none">
            <SidenavCard />
          </SoftBox>

          <li className="nav-item d-none">
            <NavLink
              to="/application-settings"
              onClick={(e) => setNowDrop("")}
              className={({ isActive }) =>
                isActive ? activeClassName : navClassName
              }
            >
              <i className="fa fa-globe icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center"></i>
              <span className="nav-link-text ms-1">Application Settings</span>
            </NavLink>
          </li>
        </ul>
      </div>
    </aside>
  );
}

export default Sidebar;
