import { React, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Navigate, useParams } from "react-router-dom";
import { callFetch } from "../../../../helpers/callFetch";
import { useTranslation } from 'react-i18next';
import Switch from "@mui/material/Switch";
import SoftSnackbar from "components/SoftSnackbar";
import Cookies from 'js-cookie';
import SoftEditor from "components/SoftEditor";
import SmtpEdit from "pages/email/smtp/SmtpEdit";
function Email() {
    let params = useParams();
    const { t } = useTranslation();
    const [designations, setDesignations] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [roles, setRoles] = useState([]);
    const [saving, setSaving] = useState(false);
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [refresh, setRefresh] = useState(0);
    const [editDelay, setEditDelay] = useState(0);
    const [user, setUser] = useState([]);
    const [activeField, setActiveField] = useState('');
    const [description, setDescription] = useState('');
    const [verification, setVerification] = useState(0);
    const {
        register,
        handleSubmit,
        setError,
        setValue,
        formState: { errors },
    } = useForm();

    useEffect(() => {
        callFetch("get-application-settings", "GET", [], setError).then((res) => {
            if (res.data.order_overview_verification == 1) {
                setVerification(1);
            } else {
                setVerification(0);
            }
        });
    }, [])

    const [successSB, setSuccessSB] = useState(false);
    const closeSuccessSB = () => setSuccessSB(false);
    const renderSuccessSB = (
        <SoftSnackbar
            color="success"
            icon="check"
            title={t('Success')}
            //content={t("Changes saved successfully")}
            dateTime={t("")}
            open={successSB}
            onClose={() => closeSuccessSB(false)}
            close={() => closeSuccessSB(false)}
            bgSuccess
        />
    );


    const changeStatus = (field) => {
        if (field == 'verification') {
            if (verification == 1) {
                setVerification(0);
                onSubmit(0)
            } else {
                setVerification(1);
                onSubmit(1)
            }
        }
    }

    const onSubmit = (verification) => {
        setSaving(true);

        callFetch("update-otp-status-settings", "POST", {
            verification_status: verification
        }, setError).then((res) => {
            setSaving(false);
            setSuccessSB(true);
            if (!res.ok) return;
            //setSubmitSuccess(true);
        });
    };

    return (
        <>
            <div className="row col-md-6">
                <form className={`needs-validation ${Object.keys(errors).length ? "was-validated" : ""}`} onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
                    <h5 className="title mb-3">Verification Code</h5>
                    <div className="form-group form-control">
                        <div className="form-check d-flex align-items-center justify-content-between ps-0">
                            <label className="text-primary">{saving ? t('Saving ...') : t('Save')}</label>
                            {verification == '1' && <Switch defaultChecked onClick={() => changeStatus('verification')} />}
                            {verification != '1' && <Switch onClick={() => changeStatus('verification')} />}
                        </div>
                    </div>
                </form>
            </div>
            {renderSuccessSB}
        </>
    );
}

export default Email;
