import Swal from 'sweetalert2';
import callFetch from './callFetch';

function deleteAlert(e, url, id, t) {
    e.preventDefault();

    const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
            confirmButton: 'btn btn-primary m-0',
            cancelButton: 'btn btn-secondary m-0'
        },
        buttonsStyling: false
    });

    return swalWithBootstrapButtons.fire({
        title: t('Are you sure?'),
        html: `<div><i style="font-size: 40px;" class="ph ph-trash-simple text-status-error-primary mb-3"></i><h3 class="text-primary text-xl font-weight-600 m-0">${t('Are you sure?')}</h3> <p class="text-secondary text-lg font-weight-400 m-0">${t("You won't be able to revert this!")}</p></div>`,
        // icon: 'warning',
        showCancelButton: true,
        confirmButtonText: t('Yes, do it!'),
        cancelButtonText: t('Cancel'),
        reverseButtons: true,
    }).then((result) => {
        if (result.isConfirmed) {
            return callFetch(url + '/' + id, "POST", { '_method': 'DELETE' }, null).then((res) => {

                return res;

                /*if (res.ok)
                    swalWithBootstrapButtons.fire(
                        t('Success!'),
                        t('Your action has succeeded.'),
                        'success'
                    );
                else
                    swalWithBootstrapButtons.fire(
                        t('Error!'),
                        t('Unable to complete your action'),
                        'error'
                    );*/
            });
        }
    });
};

export default deleteAlert;
