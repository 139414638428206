import { React, useEffect, useState, useRef } from "react";
import { NavLink, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import Moment from "react-moment";
import callFetch from "../../helpers/callFetch";
import { useTranslation } from "react-i18next";
import LastMessage from "./LastMessage";
import NewMessage from "./NewMessage";
import Cookies from "js-cookie";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import { Grid, Menu, MenuItem } from "@mui/material";

import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Download from "yet-another-react-lightbox/plugins/download";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import Counter from "yet-another-react-lightbox/plugins/counter";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import "yet-another-react-lightbox/plugins/counter.css";

function Message() {
  const [participations, setParticipations] = useState([]);
  const [messages, setMessages] = useState([]);
  const [user, setUser] = useState([]);
  const { t } = useTranslation();
  let { id } = useParams();
  const [total, setTotal] = useState("");
  const [saving, setSaving] = useState(false);
  const [query, setQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [searchResult, setSearchResult] = useState([]);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [sendBtnStatus, setSendBtnStatus] = useState(false);
  const [customClass, setCustomClass] = useState("");
  const [file, setFile] = useState();
  const [searchKey, setSearchKey] = useState(0);

  const [images, setImages] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [currentImg, setCurrentImg] = useState(0);

  const [position, setPosition] = useState("bottom");
  const [width, setWidth] = useState(120);
  const [height, setHeight] = useState(80);
  const [border, setBorder] = useState(3);
  const [borderRadius, setBorderRadius] = useState(4);
  const [padding, setPadding] = useState(4);
  const [gap, setGap] = useState(16);
  const [showToggle, setShowToggle] = useState(false);

  const [maxZoomPixelRatio, setMaxZoomPixelRatio] = useState(10);
  const [zoomInMultiplier, setZoomInMultiplier] = useState(2);
  const [doubleTapDelay, setDoubleTapDelay] = useState(300);
  const [doubleClickDelay, setDoubleClickDelay] = useState(300);
  const [doubleClickMaxStops, setDoubleClickMaxStops] = useState(2);
  const [keyboardMoveDistance, setKeyboardMoveDistance] = useState(50);
  const [wheelZoomDistanceFactor, setWheelZoomDistanceFactor] = useState(100);
  const [pinchZoomDistanceFactor, setPinchZoomDistanceFactor] = useState(100);
  const [scrollToZoom, setScrollToZoom] = useState(true);

  const openImgsViewer = (index, event) => {
    setCurrentImg(index);
    setIsOpen(true);
  };

  // Function to get the array of image objects with src properties
  const generateImageArray = (data) => {
    const baseURL = Cookies.get("backend_url") + "storage/message_attachment/"; // Replace this with your actual base URL
    const imgs = [];

    data.forEach((item) => {
      imgs.push({ src: baseURL + item });
    });

    setImages(imgs);
  };

  const chatConversationRef = useRef(null); 
  const [isUserAtBottom, setIsUserAtBottom] = useState(true);
  const [newMessages, setNewMessages] = useState(false);

  const [results, setResults] = useState({
    projects: [],
    quations: [],
    orders: [],
    employees: [],
    invoices: [],
  });
  const [searchKeyMobile, setSearchKeyMobile] = useState(0);
  const {
    reset,
    resetField,
    register,
    handleSubmit,
    setError,
    setValue,
    getValues,
    formState: { errors },
  } = useForm();

  // const handleAutoScroll = () => {
  //   chatConversationRef.current.scrollTop =
  //   chatConversationRef.current.scrollHeight;
  // };

  const handleScroll = () => {
    if (!chatConversationRef.current) return;

    const { scrollTop, scrollHeight, clientHeight } = chatConversationRef.current;
    const isAtBottom = scrollTop + clientHeight >= scrollHeight - 20; // 20px tolerance

    setIsUserAtBottom(isAtBottom);
  };

  // Add event listener for scroll detection
  useEffect(() => {
    if (chatConversationRef.current) {
      chatConversationRef.current.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (chatConversationRef.current) {
        chatConversationRef.current.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  // Auto-scroll only if the user is at the bottom
  const scrollToBottom = () => {
    if (chatConversationRef.current && isUserAtBottom) {
      chatConversationRef.current.scrollTo({
        top: chatConversationRef.current.scrollHeight,
        behavior: "smooth",
      });
      setNewMessages(false); // Hide "New Messages" button
    }
  };


  // Auto-scroll when a new message arrives
  useEffect(() => {
    if (messages.length > 0) {
      scrollToBottom();
    }
  }, [messages]);

  // Show "New Messages" button if the user has scrolled up and new messages arrive
  useEffect(() => {
    if (!isUserAtBottom) {
      setNewMessages(true);
    }
  }, [messages]);

  // Function to manually scroll to bottom when "New Messages" button is clicked
  const handleNewMessageClick = () => {
    scrollToBottom();
  };

  const IdWitMessage = (message_id, related = "") => {
    setValue("message", related + "-" + message_id);
    handleSubmit(onSubmit)();
    // document.getElementById("sendMessageBtn").click();
    setIsPopupOpen(false);
  };

  useEffect(() => { 
    const interval = setInterval(() => {
      //   console.log(getValues("uid")); 
      if (isOpen === false) {
        callFetch("message/" + getValues("uid"), "GET", []).then((res) => {
          setMessages(res.data);
          // console.log(res.data);
          const attachmentsOnly = res?.data
            .filter((msg) => msg.attachment && msg.attachment.trim() !== "")
            .map((msg) => msg.attachment);

          // console.log(attachmentsOnly);
          generateImageArray(attachmentsOnly);

          setTotal(res.total);
        });

        callFetch("seen-messages", "GET", []).then((res) => {
          // console.log(res.unread_message);
        });
      }
    }, 8000);
    return () => clearInterval(interval);
  }, [isOpen]);

  //search users
  useEffect(() => {
    if (query) {
      callFetch("search-participation/" + query, "GET", []).then((res) => {
        setSearchResult(res.records);
        setLoading(true);
      });
    }else{
      setSearchResult([]);
      setLoading(false);
    }
  }, [query]);

  //file upload
  function handleChange(e) {
    const file = e.target.files[0];
    if (file) {
      setFile(URL.createObjectURL(file));

      // Ensure the file is registered in React Hook Form
      setValue("attachment", file, { shouldValidate: true });

      // Wait for state update before submitting
      setTimeout(() => {
        handleSubmit(onSubmit)();
      }, 100);
    }
  }

  useEffect(() => {
    callFetch("chat-participation", "GET", []).then((res) => {
      // console.log(res.data);
      setParticipations(res.contacts);
    });
  }, [messages, id]);

  useEffect(() => {
    setValue("uid", id);
    // console.log(id);
    callFetch("message/" + id, "GET", []).then((res) => {
      setMessages(res.data);
      console.log(res.data);
      setTotal(res.total);
    });

    callFetch("message/receiver/" + id, "GET", []).then((res) => {
      // console.log(res.data);
      setUser(res.data);
    });
    callFetch("message-seen/" + id, "GET", []).then((res) => {
      // console.log(res.id);
    });
  }, [id]);

  useEffect(() => {
    setValue("receiver_id", id);
  });

  const handleClick = () => {
    resetField("message");
    resetField("attachment");
  };

  const deleteMessage = (id) => {
    setMessages((prevMessages) =>
      prevMessages.filter((msg) => msg.id !== id)
    );
    setTotal(total-1);
    callFetch("delete-message/" + id, "PUT", []).then((res) => {
      // console.log("deleted");
    });
  };

  const deleteChat = (id) => {
    callFetch("delete-chat/" + id, "GET", []).then((res) => {});
  };

  const handleResponsive = () => {
    setCustomClass("user-chat-show");
  };

  const removeUserChat = () => {
    setCustomClass(" ");
  };


  function validateMessage(data) {
    const hasMessage = data.message && data.message.trim() !== "";
    const hasAttachment = data.attachment instanceof File || 
                          (data.attachment instanceof FileList && data.attachment.length > 0);
    const hasReceiver = data.receiver_id && data.receiver_id.trim() !== "";
  
    // 1. Both message and attachment are empty → return false
    if (!hasMessage && !hasAttachment) {
      console.error("❌ Both message and attachment are empty.");
      return false;
    }
  
    // 2. Message exists but receiver_id is missing → return false
    if (hasMessage && !hasReceiver) {
      console.error("❌ Message exists, but receiver_id is missing.");
      return false;
    }
  
    // 3. Attachment exists but receiver_id is missing → return false
    if (hasAttachment && !hasReceiver) {
      console.error("❌ Attachment exists, but receiver_id is missing.");
      return false;
    }
  
    // ✅ Passed all checks
    return true;
  }
  

  const onSubmit = (formData) => {
    console.log(formData);
    // if (!formData.message || formData.message.trim() === "") {
    //   return false;
    // }

    if(validateMessage(formData)){
      setSaving(true);
      callFetch("sent-message", "POST", formData, setError).then((res) => {
        callFetch("message/" + id, "GET", []).then((res) => {
          setMessages(res.data);
          // console.log(res.data);
          setTotal(res.total);
        });
        handleClick();
        setSendBtnStatus(false);
        setFile("");
        setSaving(false);
        setSubmitSuccess(true);
      });
    }else{
      return false;
    }    

    
  };

  useEffect(() => {
    const lastMessage = document.querySelector("#chat-messages li:last-child");
    if (lastMessage) {
      lastMessage.scrollIntoView({ behavior: "smooth", block: "end" });
    }
  }, [messages, saving]);

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [showBorder, setShowBorder] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [message, setMessage] = useState("");
  const [suggestions, setSuggestions] = useState([
    "Kunde: 200155 FireX Greßlehner GmbH",
    "Projekt: 500002 VHS Galileigasse",
    "Projekt: 500002 VHS Galileigasse",
  ]);

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
    setShowBorder(false);
  };

  const handleInputBorderColor = () => {
    setShowBorder(true);
  };

  const handleSendBtn = (text) => {
    if (text.length > 1) {
      setSendBtnStatus(true);
    } else {
      setSendBtnStatus(false);
    }
  };

  useEffect(() => {
    // setShowNotFound(false);
    if (searchKey.length > 0 || searchKeyMobile.length > 0) {
      const searchTerm = searchKey.length > 0 ? searchKey : searchKeyMobile;
      // setQuery(searchTerm);
      // console.log(searchTerm.trim());
      if (searchTerm.trim().length > 0) {
        // setShowClose(false);
        // setShowCloseMobile(false);
        // setLoading(true);
        // setShowNotFound(false);
        // setLoadingMobile(true);
        try {
          callFetch("global/search/" + searchTerm, "GET", []).then((res) => {
            if (res.status !== 401) {
              setResults(res.allData);
              console.log(res.allData);
              // setResultsMobile(res.allData);

              // setLoading(false);
              // setLoadingMobile(false);

              // setShowClose(true);
              // setShowCloseMobile(true);

              // setSearchData(res.data.data);
              // setSearchDataMobile(res.data.data);
              if (res.data.data.length == 0) {
                console.log("truenotfound");
                // setShowNotFound(true);
                // setMenu(false);
                // setMenuMobile(false);
              } else {
                // setMenu(true);
                // setMenuMobile(true);
              }
            } else {
              // setSearchData([]);
              // setSearchDataMobile([]);
              // setMenu(false);
              // setShowNotFound(false)
              // setMenuMobile(false);
              // setShowNotFound(false);
            }
          });
        } catch (error) {
          console.error("Error fetching search results:", error);
        }
      } else {
        // setShowNotFound(false);
        // setMenu(false);
        // setMenuMobile(false);
        setResults({
          projects: [],
          quations: [],
          orders: [],
          employees: [],
          invoices: [],
        });
      }
    } else {
      setSearchKey("");
      setResults({
        projects: [],
        quations: [],
        orders: [],
        employees: [],
        invoices: [],
      });
      // setSearchKeyMobile('')
      // setMenu(false)
      // setShowClose(false)
      // setShowCloseMobile(false)
      // setMenuMobile(false);
      // setSearchData([])
    }
  }, [searchKey]);

  let attachmentIndex = 0;

  return (
    <>
      <>
        <div className="layout-wrapper d-lg-flex chat">
          <div
            className="chat-leftsidebar me-lg-3 ms-lg-0"
            style={{ backgroundColor: "#fff" }}
          >
            <div className="tab-content">
              <div
                className="tab-pane fade show active"
                id="pills-chat"
                role="tabpanel"
                aria-labelledby="pills-chat-tab"
              >
                <div>
                  <div className="px-3 pt-3">
                    {/* <h4 className="mb-4" style={{position: 'relative'}}>{t('Message')} <NavLink to={'/chat/send-message'} className="btn btn-primary" style={{position: 'absolute', right: '0px', fontSize: '12px', padding: '8px'}}>{t('New Message')}</NavLink></h4> */}
                    {/* <div className="search-box chat-search-box">            
                                <div className="input-group mb-3 rounded-3">
                                    <div class="input-group flex-nowrap">
                                        <span class="input-group-text border-primary" id="addon-wrapping">@</span>
                                        <input type="text" class="form-control search-user" placeholder="Username" aria-label="Username" aria-describedby="addon-wrapping"/>
                                    </div> */}
                    {/* <input type="text" value={query} onChange={({ target }) => setQuery(target.value)} className="form-control bg-light" placeholder={t('Search Users')} aria-label="Search users" aria-describedby="basic-addon1" style={{ borderLeft:'none !important', borderRadius: '0px 5px 5px 0px !important' }}/> */}
                    {/* </div>  */}
                    {/* </div>   */}
                    <div class="search-container">
                      <div
                        class={
                          showBorder
                            ? "search-box border-primary"
                            : "search-box border-primary"
                        }
                      >
                        <span class="user-search-icon">
                          <img
                            src="../../assets/icons/MagnifyingGlass.svg"
                            className="position-relative top--2"
                          />
                        </span>
                        <input
                          type="text"
                          onChange={({ target }) => setQuery(target.value)}
                          value={query}
                          placeholder="User Suchen"
                        />
                      </div>
                      <button
                        class="add-button border-primary d-flex align-items-center justify-content-center"
                        data-bs-toggle="modal"
                        data-bs-target="#newMessageModal"
                      >
                        <img src="../../assets/icons/PlusHover.svg" />
                      </button>
                    </div>
                  </div>

                  <div className="px-3 pb-3" dir="ltr">
                    <div
                      className="owl-carousel owl-theme"
                      id="user-status-carousel"
                    >
                      <div className="item">
                        <a href="#" className="user-status-box">
                          <div className="avatar-xs mx-auto d-block chat-user-img online">
                            <img
                              src="assets/images/users/avatar-2.jpg"
                              alt="user-img"
                              className="img-fluid rounded-circle"
                            />
                            <span className="user-status"></span>
                          </div>

                          <h5 className="font-size-13 text-truncate mt-3 mb-1">
                            {t("Patrick")}
                          </h5>
                        </a>
                      </div>
                      <div className="item">
                        <a href="#" className="user-status-box">
                          <div className="avatar-xs mx-auto d-block chat-user-img online">
                            <img
                              src="assets/images/users/avatar-4.jpg"
                              alt="user-img"
                              className="img-fluid rounded-circle"
                            />
                            <span className="user-status"></span>
                          </div>

                          <h5 className="font-size-13 text-truncate mt-3 mb-1">
                            {t("Doris")}
                          </h5>
                        </a>
                      </div>

                      <div className="item">
                        <a href="#" className="user-status-box">
                          <div className="avatar-xs mx-auto d-block chat-user-img online">
                            <img
                              src="assets/images/users/avatar-5.jpg"
                              alt="user-img"
                              className="img-fluid rounded-circle"
                            />
                            <span className="user-status"></span>
                          </div>

                          <h5 className="font-size-13 text-truncate mt-3 mb-1">
                            {t("Emily")}
                          </h5>
                        </a>
                      </div>

                      <div className="item">
                        <a href="#" className="user-status-box">
                          <div className="avatar-xs mx-auto d-block chat-user-img online">
                            <img
                              src="assets/images/users/avatar-6.jpg"
                              alt="user-img"
                              className="img-fluid rounded-circle"
                            />
                            <span className="user-status"></span>
                          </div>

                          <h5 className="font-size-13 text-truncate mt-3 mb-1">
                            {t("Steve")}
                          </h5>
                        </a>
                      </div>

                      <div className="item">
                        <a href="#" className="user-status-box">
                          <div className="avatar-xs mx-auto d-block chat-user-img online">
                            <span className="avatar-title rounded-circle bg-soft-primary text-primary">
                              T
                            </span>
                            <span className="user-status"></span>
                          </div>

                          <h5 className="font-size-13 text-truncate mt-3 mb-1">
                            {t("Teresa")}
                          </h5>
                        </a>
                      </div>
                    </div>
                  </div>

                  <div>
                    {/* <h5 className="mb-3 px-3 font-size-16">{t('Recent')}</h5> */}

                    <div className="chat-message-list px-3" data-simplebar>
                      <ul className="list-unstyled chat-list chat-user-list">
                        {!loading &&
                          participations.map((user) => (
                            <li
                              className={id == user.id ? "active" : ""}
                              onClick={handleResponsive}
                            >
                              <NavLink
                                to={"/chat/" + user.id + "/" + user.name}
                              >
                                <div className="d-flex">
                                  <div className="chat-user-img online align-self-center me-3 ms-0">
                                    <img
                                      className="avatar avatar-sm"
                                      src={
                                        user.photo
                                          ? Cookies.get("backend_url") +
                                            "storage/avatar/" +
                                            user.photo
                                          : "/assets/img/placeholder.png"
                                      }
                                      alt=""
                                    />
                                    {/* <span className="user-status"></span> */}
                                  </div>

                                  <div className="flex-grow-1 overflow-hidden">
                                    <LastMessage
                                      userId={user.id}
                                      name={user.name}
                                    ></LastMessage>
                                  </div>
                                  <div className="font-size-11"></div>
                                  <div className="unread-message">
                                    <span className="badge badge-soft-danger rounded-pill"></span>
                                  </div>
                                  {/* <div className="dropdown align-self-start">
                                                                <a href="!0" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{borderTop: '0px'}}>
                                                                    <i className="ri-more-2-fill"></i>
                                                                </a>
                                                                <div className="dropdown-menu"> 
                                                                    <a className="dropdown-item" href="javascript::void(0)" onClick={() => deleteChat(user.id)}>{t('Delete')} <i className="ri-delete-bin-line float-end text-muted"></i></a>
                                                                </div>
                                                        </div> */}
                                </div>
                              </NavLink>
                            </li>
                          ))}

                        {loading &&
                          Array.isArray(searchResult) &&
                          searchResult.map((user) => (
                            <li className={id == user.id ? "active" : ""}>
                              <NavLink to={"/chat/" + user.id + "/" + user.name}>
                                <div className="d-flex">
                                  <div className="chat-user-img online align-self-center me-3 ms-0">
                                    <img
                                      className="avatar avatar-sm"
                                      src={
                                        user.photo
                                          ? Cookies.get("backend_url") +
                                            "storage/avatar/" +
                                            user.photo
                                          : "/assets/img/placeholder.png"
                                      }
                                      alt=""
                                    />
                                    {/* <span className="user-status"></span> */}
                                  </div>

                                  <div className="flex-grow-1 overflow-hidden">
                                    <LastMessage
                                      userId={user.id}
                                      name={user.name}
                                    ></LastMessage>
                                  </div>
                                  <div className="font-size-11"></div>
                                  <div className="unread-message">
                                    <span className="badge badge-soft-danger rounded-pill"></span>
                                  </div>
                                </div>
                              </NavLink>
                            </li>
                          ))}

                        {/* <li className="unread">
                                        <a href="#">
                                            <div className="d-flex">
                                                <div className="chat-user-img away align-self-center me-3 ms-0">
                                                    <img src="https://aui.atlassian.com/aui/latest/docs/images/avatar-person.svg" className="rounded-circle avatar-xs" alt=""/>
                                                    <span className="user-status"></span>
                                                </div>
                                                <div className="flex-grow-1 overflow-hidden">
                                                    <h5 className="text-truncate font-size-15 mb-1">Mark Messer</h5>
                                                    <p className="chat-user-message text-truncate mb-0"><i className="ri-image-fill align-middle me-1 ms-0"></i> Images</p>
                                                </div>
                                                <div className="font-size-11">12 min</div>        
                                                <div className="unread-message">
                                                    <span className="badge badge-soft-danger rounded-pill">02</span>
                                                </div>
                                            </div>
                                        </a>
                                    </li>
    

                                    <li className="active">
                                        <a href="#">
                                            <div className="d-flex">
                                                <div className="chat-user-img online align-self-center me-3 ms-0">
                                                    <img src="https://aui.atlassian.com/aui/latest/docs/images/avatar-person.svg" className="rounded-circle avatar-xs" alt=""/>
                                                    <span className="user-status"></span>
                                                </div>
                                                <div className="flex-grow-1 overflow-hidden">
                                                    <h5 className="text-truncate font-size-15 mb-1">Doris Brown</h5>
                                                    <p className="chat-user-message text-truncate mb-0">Nice to meet you</p>
                                                </div>    
                                                <div className="font-size-11">10:12 AM</div>
                                            </div>
                                        </a>
                                    </li>
                                    
                                    <li className="typing">
                                        <a href="#">                        
                                            <div className="d-flex">
                                                <div className="chat-user-img align-self-center online me-3 ms-0">
                                                    <div className="avatar-xs">
                                                        <span className="avatar-title rounded-circle bg-soft-primary text-primary">
                                                            A
                                                        </span>
                                                    </div>
                                                    <span className="user-status"></span>
                                                </div>
                                                <div className="flex-grow-1 overflow-hidden">
                                                    <h5 className="text-truncate font-size-15 mb-1">Albert Rodarte</h5>
                                                    <p className="chat-user-message text-truncate mb-0">typing<span className="animate-typing">
                                                        <span className="dot"></span>
                                                        <span className="dot"></span>
                                                        <span className="dot"></span>
                                                    </span></p>
                                                </div>
                                                <div className="font-size-11">04:56 PM</div>
                                            </div>  
                                        </a>
                                    </li> */}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              {/* End settings tab-pane */}
            </div>
            {/* end tab content */}
          </div>
          {/* end chat-leftsidebar */}

          {/* Start User chat */}
          <div className={"user-chat verflow-hidden " + customClass}>
            <div className="d-lg-flex">
              {/* start chat conversation section */}
              <div className="w-100 overflow-hidden position-relative">
                <div className="p-8-12 border-bottom user-chat-topbar">
                  <div className="row align-items-center">
                    <div className="col-sm-4 col-10">
                      <div className="d-flex align-items-center">
                        <div className="d-block d-lg-none me-2 ms-0">
                          <a
                            href="javascript:void(0);"
                            className="user-chat-remove text-muted font-size-16 p-2"
                            onClick={removeUserChat}
                          >
                            <i class="ph ph-caret-left font-size-20"></i>
                          </a>
                        </div>
                        <div className="me-3 ms-0">
                          <img
                            className="avatar avatar-sm"
                            src={
                              user?.photo
                                ? Cookies.get("backend_url") +
                                  "storage/avatar/" +
                                  user?.photo
                                : "/assets/img/placeholder.png"
                            }
                            alt=""
                          />
                        </div>
                        <div className="flex-grow-1 overflow-hidden">
                          <h5 className="font-size-14 mb-0 text-truncate font-weight-500">
                            <a
                              href="#0"
                              className="text-reset user-profile-show"
                            >
                              {user.name}
                            </a>
                          </h5>
                          <p className="font-size-12 mb-0">
                            zuletzt gesehen heute um 22:30 Uhr
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-8 col-2">
                      <ul className="list-inline user-chat-nav text-end mb-0">
                        <li className="list-inline-item">
                          <div className="dropdown">
                            <button
                              className="btn nav-btn mb-0"
                              type="button"
                              data-bs-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <img src="../../assets/icons/DotsThreeVertical.svg" />
                            </button>
                            <div className="dropdown-menu p-0 dropdown-menu-end dropdown-menu-md">
                              <div className="search-box p-2">
                                <input
                                  type="text"
                                  className="form-control bg-light border-0"
                                  placeholder="Search.."
                                />
                              </div>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                {/* end chat user head */}

                {/* start chat conversation */}
                <div
                  className="chat-conversation p-3"
                  style={{ overflow: "hidden scroll", scrollbarWidth: "thin" }}
                  ref={chatConversationRef}
                >
                  <ul className="list-unstyled mb-0" id="chat-messages">
                    {messages.map((message, key) => {
                      

                      let thisAttachmentIndex = null;
                      if (
                        message.attachment &&
                        message.attachment.trim() !== ""
                      ) {
                        thisAttachmentIndex = attachmentIndex;
                        attachmentIndex += 1;
                      }

                      if (message.sender_id === id) {
                        return (
                          <li id={key}>
                            <div className="conversation-list">
                              {/* <div className="chat-avatar">
                                                        <img className="avatar avatar-sm" src={user.photo ? Cookies.get('backend_url') + 'storage/avatar/' + user.photo : '/assets/img/placeholder.png'} alt="" />
                                                    </div> */}

                              <div className="user-chat-content">
                                <div className="ctext-wrap">
                                  <div className="ctext-wrap-content">
                                    <p className="mb-0">{message?.message}</p>
                                    {message?.attachment && (
                                      <a
                                        target="_blank"
                                        download
                                        href={
                                          Cookies.get("backend_url") +
                                          "storage/message_attachment/" +
                                          message?.attachment
                                        }
                                      >
                                        <picture
                                          onClick={(e) =>
                                            openImgsViewer(
                                              thisAttachmentIndex,
                                              e
                                            )
                                          }
                                          className="cursor-pointer"
                                        >
                                          <source
                                            srcset={
                                              Cookies.get("backend_url") +
                                              "storage/message_attachment/" +
                                              message?.attachment
                                            }
                                            type="image/webp"
                                          />
                                          <img
                                            style={{ maxWidth: "350px" }}
                                            src={
                                              Cookies.get("backend_url") +
                                              "storage/message_attachment/" +
                                              message?.attachment
                                            }
                                            alt="Chat Image"
                                          />
                                        </picture>
                                      </a>
                                    )}
                                    <p className="chat-time mb-0">
                                      <i className="ri-time-line align-middle"></i>{" "}
                                      <span className="align-middle">
                                        <Moment fromNow>
                                          {message?.created_at}
                                        </Moment>
                                      </span>
                                    </p>
                                  </div>
                                </div>
                                <div className="conversation-name">
                                  {message?.sender?.name}
                                </div>
                              </div>
                            </div>
                          </li>
                        );
                      }

                      return (
                        <li className="right" id={key}>
                          <div className="conversation-list">
                            {/* <div className="chat-avatar">
                                        <img className="avatar avatar-sm" src={message.sender.photo ? Cookies.get('backend_url') + 'storage/avatar/' + message.sender.photo : '/assets/img/placeholder.png'} alt="" />
                                    </div> */}

                            <div className="user-chat-content">
                              <div className="ctext-wrap">
                                <div className="ctext-wrap-content">
                                  {message?.message &&
                                  message?.message &&
                                  message?.message.split("-")[0] == "project" ? (
                                    <div>
                                      <div class="project-card">
                                        <div class="icon-container">
                                          <img src="../../assets/icons/ShareFatWhite.svg" />
                                        </div>
                                        <div class="project-info">
                                          <p class="project-title">Projekt:</p>
                                          <p class="project-number">
                                            {message?.message.split("-")[2]}
                                          </p>
                                        </div>
                                        <a
                                          target="_blank"
                                          href={
                                            "/projects/" +
                                            message?.message.split("-")[2]
                                          }
                                          class="open-button"
                                        >
                                          Öffnen
                                        </a>
                                      </div>
                                    </div>
                                  ) : (
                                    <p className="mb-0">{message?.message ? message?.message : ''}</p>
                                  )}

                                  {message?.attachment && (
                                    <picture
                                      onClick={(e) =>
                                        openImgsViewer(thisAttachmentIndex, e)
                                      }
                                      className="cursor-pointer"
                                    >
                                      <source
                                        srcset={
                                          Cookies.get("backend_url") +
                                          "storage/message_attachment/" +
                                          message?.attachment
                                        }
                                        type="image/webp"
                                      />
                                      <img
                                        style={{ maxWidth: "320px" }}
                                        src={
                                          Cookies.get("backend_url") +
                                          "storage/message_attachment/" +
                                          message?.attachment
                                        }
                                        alt="Chat Image"
                                      />
                                    </picture>
                                  )}
                                  <p className="chat-time mb-0">
                                    <i className="ri-time-line align-middle"></i>{" "}
                                    <span className="align-middle">
                                      <Moment fromNow>
                                        {message?.created_at}
                                      </Moment>
                                    </span>
                                  </p>
                                </div>

                                <div className="dropdown  align-self-start">
                                  <a
                                    href="0"
                                    role="button"
                                    data-bs-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="true"
                                  >
                                    <img src="../../assets/icons/DotsThreeVertical.svg" />
                                  </a>
                                  <div
                                    className="dropdown-menu chat-inner-delete-message-btn"
                                    data-popper-placement="bottom-start"
                                  >
                                     
                                    <a
                                      className="dropdown-item d-flex align-items-center p-2"
                                      href="javascript:void(0)"
                                      onClick={() => deleteMessage(message?.id)}
                                    >
                                     <i className="ph ph-trash-simple font-size-20 me-2 icon-danger"></i> <span className="text-danger">{t("Delete")}</span>
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                      );
                    })}
                    <li></li>
                  </ul>
                </div>
                {/* end chat conversation end */}

                {/* start chat input section */}
                <form onSubmit={handleSubmit(onSubmit)}>
                  <input type="hidden" {...register("receiver_id")} />
                  <div className="message-box">
                    <div
                      style={{
                        position: "relative",
                        display: "inline-block",
                        cursor: "pointer",
                      }}
                      className="me-3"
                    >
                      <input
                        {...register("attachment")}
                        type="file"
                        id="attachmentInput"
                        onChange={handleChange}
                        className=""
                        style={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          width: "100%",
                          height: "100%",
                          opacity: 0,
                          cursor: "pointer",
                        }}
                      />
                      <img
                        src="../../assets/icons/Paperclip.svg"
                        alt="Attach File"
                        style={{ cursor: "pointer" }}
                      />
                    </div>

                    {/* Plus Button */}
                    <div className="plus-button-wrapper">
                      <button
                        className="plus-btn"
                        type="button"
                        onClick={togglePopup}
                        title="More Options"
                      >
                        <img
                          src={
                            isPopupOpen
                              ? "../../assets/icons/PlusHover.svg"
                              : "../../assets/icons/Plus.svg"
                          }
                        />
                      </button>
                      {isPopupOpen && (
                        <div className={results.projects?.data?.length > 0 ? "popup-menu height-after" : "popup-menu height-before"}>
                          <div class="search-container">
                            <div
                              class={
                                showBorder
                                  ? "search-box border-brand-primary"
                                  : "search-box border-primary"
                              }
                            >
                              <span class="user-search-icon">
                                <img src="../../assets/icons/MagnifyingGlass.svg" />
                              </span>
                              <input
                                type="text"
                                onFocus={handleInputBorderColor}
                                value={searchKey ? searchKey : ""}
                                onChange={(e) => {
                                  setSearchKey(e.target.value);
                                }}
                                placeholder="Suchen"
                              />
                            </div>
                          </div>
                          <ul className="suggestion">
                            {results.projects?.data?.length > 0 && (
                              <>
                                {results.projects?.data?.map(
                                  (project, index) => {
                                    return (
                                      <li
                                        className="position-relative popup-item"
                                        key={index}
                                        onClick={() =>
                                          IdWitMessage(
                                            project?.id,
                                            "project-" + project?.id
                                          )
                                        }
                                      >
                                        <p className="mb-0 font-size-16">
                                          Projekt: {project?.id}{" "}
                                        </p>
                                        <span className="font-size-14">
                                          {project?.street +
                                            ", " +
                                            project?.plz +
                                            ", " +
                                            project?.ort}
                                        </span>

                                        <i class="ph ph-paper-plane-tilt msg-popup-icon-send"></i>
                                      </li>
                                    );
                                  }
                                )}
                              </>
                            )}
                          </ul>
                        </div>
                      )}
                    </div>

                    {/* Input Field */}
                    <input
                      type="text"
                      className="input-field"
                      placeholder={t("Geben Sie Ihre Nachricht ein")}
                      // value={message}
                      {...register("message")}
                      onChange={(e) => {
                        setValue("message", e.target.value, {
                          shouldValidate: true,
                        });
                        handleSendBtn(e.target.value);
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "Enter" && !e.shiftKey) {
                          e.preventDefault(); // Prevents new line (useful for chat apps)
                          handleSubmit(onSubmit)(); // Manually trigger form submission
                        }
                      }}
                    />

                    {/* Send Button */}
                    <button
                      type={sendBtnStatus ? "submit" : "button"}
                      className="send-btn"
                      id="sendMessageBtn"
                    >
                      {sendBtnStatus ? (
                        <i class="ph ph-paper-plane-right font-size-24 text-brand-primary position-relative top-2"></i>
                      ) : (
                        <i class="ph ph-paper-plane-right font-size-24 position-relative top-2"></i>
                      )}
                    </button>
                  </div>
                </form>
                {/* end chat input section */}
              </div>
              {/* end chat conversation section */}

              {/* start User profile detail sidebar */}
              <div className="user-profile-sidebar">
                <div className="px-3 px-lg-4 pt-3 pt-lg-4">
                  <div className="user-chat-nav text-end">
                    <button
                      type="button"
                      className="btn nav-btn"
                      id="user-profile-hide"
                    >
                      <i className="ri-close-line"></i>
                    </button>
                  </div>
                </div>

                <div className="text-center p-4 border-bottom">
                  <div className="mb-4">
                    <img
                      src="assets/images/users/avatar-4.jpg"
                      className="rounded-circle avatar-lg img-thumbnail"
                      alt=""
                    />
                  </div>

                  <h5 className="font-size-16 mb-1 text-truncate">
                    {t("Doris Brown")}
                  </h5>
                  <p className="text-muted text-truncate mb-1">
                    <i className="ri-record-circle-fill font-size-10 text-success me-1 ms-0"></i>{" "}
                    {t("Active")}
                  </p>
                </div>
                {/* End profile user */}

                {/* Start user-profile-desc */}
                <div className="p-4 user-profile-desc" data-simplebar>
                  <div className="text-muted">
                    <p className="mb-4">
                      If several languages coalesce, the grammar of the
                      resulting language is more simple and regular than that of
                      the individual.
                    </p>
                  </div>

                  <div className="accordion" id="myprofile">
                    <div className="accordion-item card border mb-2">
                      <div className="accordion-header" id="about3">
                        <button
                          className="accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#aboutprofile"
                          aria-expanded="true"
                          aria-controls="aboutprofile"
                        >
                          <h5 className="font-size-14 m-0">
                            <i className="ri-user-2-line me-2 ms-0 align-middle d-inline-block"></i>{" "}
                            About
                          </h5>
                        </button>
                      </div>
                      <div
                        id="aboutprofile"
                        className="accordion-collapse collapse show"
                        aria-labelledby="about3"
                        data-bs-parent="#myprofile"
                      >
                        <div className="accordion-body">
                          <div>
                            <p className="text-muted mb-1">Name</p>
                            <h5 className="font-size-14">Doris Brown</h5>
                          </div>

                          <div className="mt-4">
                            <p className="text-muted mb-1">Email</p>
                            <h5 className="font-size-14">adc@123.com</h5>
                          </div>

                          <div className="mt-4">
                            <p className="text-muted mb-1">Time</p>
                            <h5 className="font-size-14">11:40 AM</h5>
                          </div>

                          <div className="mt-4">
                            <p className="text-muted mb-1">Location</p>
                            <h5 className="font-size-14 mb-0">
                              California, USA
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item card border">
                      <div className="accordion-header" id="attachfile3">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#attachprofile"
                          aria-expanded="false"
                          aria-controls="attachprofile"
                        >
                          <h5 className="font-size-14 m-0">
                            <i className="ri-attachment-line me-2 ms-0 align-middle d-inline-block"></i>{" "}
                            Attached Files
                          </h5>
                        </button>
                      </div>
                      <div
                        id="attachprofile"
                        className="accordion-collapse collapse"
                        aria-labelledby="attachfile3"
                        data-bs-parent="#myprofile"
                      >
                        <div className="accordion-body">
                          <div className="card p-2 border mb-2">
                            <div className="d-flex align-items-center">
                              <div className="avatar-sm me-3 ms-0">
                                <div className="avatar-title bg-soft-primary text-primary rounded font-size-20">
                                  <i className="ri-file-text-fill"></i>
                                </div>
                              </div>
                              <div className="flex-grow-1">
                                <div className="text-start">
                                  <h5 className="font-size-14 mb-1">
                                    admin_v1.0.zip
                                  </h5>
                                  <p className="text-muted font-size-13 mb-0">
                                    12.5 MB
                                  </p>
                                </div>
                              </div>

                              <div className="ms-4 me-0">
                                <ul className="list-inline mb-0 font-size-18">
                                  <li className="list-inline-item">
                                    <a href="#" className="text-muted px-1">
                                      <i className="ri-download-2-line"></i>
                                    </a>
                                  </li>
                                  <li className="list-inline-item dropdown">
                                    <a
                                      className="dropdown-toggle text-muted px-1"
                                      href="#"
                                      role="button"
                                      data-bs-toggle="dropdown"
                                      aria-haspopup="true"
                                      aria-expanded="false"
                                    >
                                      <i className="ri-more-fill"></i>
                                    </a>
                                    <div className="dropdown-menu dropdown-menu-end">
                                      <a className="dropdown-item" href="#">
                                        Action
                                      </a>
                                      <a className="dropdown-item" href="#">
                                        Another action
                                      </a>
                                      <div className="dropdown-divider"></div>
                                      <a className="dropdown-item" href="#">
                                        {t("Delete")}
                                      </a>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>

                          <div className="card p-2 border mb-2">
                            <div className="d-flex align-items-center">
                              <div className="avatar-sm me-3 ms-0">
                                <div className="avatar-title bg-soft-primary text-primary rounded font-size-20">
                                  <i className="ri-image-fill"></i>
                                </div>
                              </div>
                              <div className="flex-grow-1">
                                <div className="text-start">
                                  <h5 className="font-size-14 mb-1">
                                    Image-1.jpg
                                  </h5>
                                  <p className="text-muted font-size-13 mb-0">
                                    4.2 MB
                                  </p>
                                </div>
                              </div>

                              <div className="ms-4 me-0">
                                <ul className="list-inline mb-0 font-size-18">
                                  <li className="list-inline-item">
                                    <a href="#" className="text-muted px-1">
                                      <i className="ri-download-2-line"></i>
                                    </a>
                                  </li>
                                  <li className="list-inline-item dropdown">
                                    <a
                                      className="dropdown-toggle text-muted px-1"
                                      href="#"
                                      role="button"
                                      data-bs-toggle="dropdown"
                                      aria-haspopup="true"
                                      aria-expanded="false"
                                    >
                                      <i className="ri-more-fill"></i>
                                    </a>
                                    <div className="dropdown-menu dropdown-menu-end">
                                      <a className="dropdown-item" href="#">
                                        Action
                                      </a>
                                      <a className="dropdown-item" href="#">
                                        Another action
                                      </a>
                                      <div className="dropdown-divider"></div>
                                      <a className="dropdown-item" href="#">
                                        {t("Delete")}
                                      </a>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>

                          <div className="card p-2 border mb-2">
                            <div className="d-flex align-items-center">
                              <div className="avatar-sm me-3 ms-0">
                                <div className="avatar-title bg-soft-primary text-primary rounded font-size-20">
                                  <i className="ri-image-fill"></i>
                                </div>
                              </div>
                              <div className="flex-grow-1">
                                <div className="text-start">
                                  <h5 className="font-size-14 mb-1">
                                    Image-2.jpg
                                  </h5>
                                  <p className="text-muted font-size-13 mb-0">
                                    3.1 MB
                                  </p>
                                </div>
                              </div>

                              <div className="ms-4 me-0">
                                <ul className="list-inline mb-0 font-size-18">
                                  <li className="list-inline-item">
                                    <a href="#" className="text-muted px-1">
                                      <i className="ri-download-2-line"></i>
                                    </a>
                                  </li>
                                  <li className="list-inline-item dropdown">
                                    <a
                                      className="dropdown-toggle text-muted px-1"
                                      href="#"
                                      role="button"
                                      data-bs-toggle="dropdown"
                                      aria-haspopup="true"
                                      aria-expanded="false"
                                    >
                                      <i className="ri-more-fill"></i>
                                    </a>
                                    <div className="dropdown-menu dropdown-menu-end">
                                      <a className="dropdown-item" href="#">
                                        Action
                                      </a>
                                      <a className="dropdown-item" href="#">
                                        Another action
                                      </a>
                                      <div className="dropdown-divider"></div>
                                      <a className="dropdown-item" href="#">
                                        {t("Delete")}
                                      </a>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>

                          <div className="card p-2 border mb-2">
                            <div className="d-flex align-items-center">
                              <div className="avatar-sm me-3 ms-0">
                                <div className="avatar-title bg-soft-primary text-primary rounded font-size-20">
                                  <i className="ri-file-text-fill"></i>
                                </div>
                              </div>
                              <div className="flex-grow-1">
                                <div className="text-start">
                                  <h5 className="font-size-14 mb-1">
                                    Landing-A.zip
                                  </h5>
                                  <p className="text-muted font-size-13 mb-0">
                                    6.7 MB
                                  </p>
                                </div>
                              </div>

                              <div className="ms-4 me-0">
                                <ul className="list-inline mb-0 font-size-18">
                                  <li className="list-inline-item">
                                    <a href="#" className="text-muted px-1">
                                      <i className="ri-download-2-line"></i>
                                    </a>
                                  </li>
                                  <li className="list-inline-item dropdown">
                                    <a
                                      className="dropdown-toggle text-muted px-1"
                                      href="#"
                                      role="button"
                                      data-bs-toggle="dropdown"
                                      aria-haspopup="true"
                                      aria-expanded="false"
                                    >
                                      <i className="ri-more-fill"></i>
                                    </a>
                                    <div className="dropdown-menu dropdown-menu-end">
                                      <a className="dropdown-item" href="#">
                                        Action
                                      </a>
                                      <a className="dropdown-item" href="#">
                                        Another action
                                      </a>
                                      <div className="dropdown-divider"></div>
                                      <a className="dropdown-item" href="#">
                                        {t("Delete")}
                                      </a>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* end profile-user-accordion */}
                  </div>
                  {/* end user-profile-desc */}
                </div>
                {/* end User profile detail sidebar */}
              </div>
            </div>
            {/* End User chat */}

            {/* audiocall Modal */}
            <div
              className="modal fade"
              id="audiocallModal"
              tabindex="-1"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-body">
                    <div className="text-center p-4">
                      <div className="avatar-lg mx-auto mb-4">
                        <img
                          src="assets/images/users/avatar-4.jpg"
                          alt=""
                          className="img-thumbnail rounded-circle"
                        />
                      </div>

                      <h5 className="text-truncate">Doris Brown</h5>
                      <p className="text-muted">Start Audio Call</p>

                      <div className="mt-5">
                        <ul className="list-inline mb-1">
                          <li className="list-inline-item px-2 me-2 ms-0">
                            <button
                              type="button"
                              className="btn btn-danger avatar-sm rounded-circle"
                              data-bs-dismiss="modal"
                            >
                              <span className="avatar-title bg-transparent font-size-20">
                                <i className="ri-close-fill"></i>
                              </span>
                            </button>
                          </li>
                          <li className="list-inline-item px-2">
                            <button
                              type="button"
                              className="btn btn-success avatar-sm rounded-circle"
                            >
                              <span className="avatar-title bg-transparent font-size-20">
                                <i className="ri-phone-fill"></i>
                              </span>
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* audiocall Modal */}

            {/* videocall Modal */}
            <div
              className="modal fade"
              id="videocallModal"
              tabindex="-1"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-body">
                    <div className="text-center p-4">
                      <div className="avatar-lg mx-auto mb-4">
                        <img
                          src="assets/images/users/avatar-4.jpg"
                          alt=""
                          className="img-thumbnail rounded-circle"
                        />
                      </div>

                      <h5 className="text-truncate">Doris Brown</h5>
                      <p className="text-muted mb-0">Start Video Call</p>

                      <div className="mt-5">
                        <ul className="list-inline mb-1">
                          <li className="list-inline-item px-2 me-2 ms-0">
                            <button
                              type="button"
                              className="btn btn-danger avatar-sm rounded-circle"
                              data-bs-dismiss="modal"
                            >
                              <span className="avatar-title bg-transparent font-size-20">
                                <i className="ri-close-fill"></i>
                              </span>
                            </button>
                          </li>
                          <li className="list-inline-item px-2">
                            <button
                              type="button"
                              className="btn btn-success avatar-sm rounded-circle"
                            >
                              <span className="avatar-title bg-transparent font-size-20">
                                <i className="ri-vidicon-fill"></i>
                              </span>
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* send message modal  */}
        <Lightbox
          index={currentImg}
          open={isOpen}
          close={() => setIsOpen(false)}
          slides={images.map((slide) => ({
            ...slide,
            download: `${slide.src}?download`,
          }))}
          plugins={[Download, Zoom, Counter, Thumbnails]}
          counter={{ container: { style: { top: "unset", bottom: 0 } } }}
          thumbnails={{
            position,
            width,
            height,
            border,
            borderRadius,
            padding,
            gap,
            showToggle,
          }}
          zoom={{
            maxZoomPixelRatio,
            zoomInMultiplier,
            doubleTapDelay,
            doubleClickDelay,
            doubleClickMaxStops,
            keyboardMoveDistance,
            wheelZoomDistanceFactor,
            pinchZoomDistanceFactor,
            scrollToZoom,
          }}
        />
        <div
          class="modal fade right-side"
          id="newMessageModal"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabindex="-1"
          aria-labelledby="newMessageModalLabel"
          aria-hidden="true"
        >
          <NewMessage></NewMessage>
        </div>
      </>
    </>
  );
}

export default Message;
