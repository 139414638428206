import { React, useEffect, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { Navigate, useParams, NavLink, useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import callFetch from "../../../helpers/callFetch";
import flatpickr from "flatpickr";
import SoftSnackbar from "components/SoftSnackbar";
import TimelineNew from "./TimelineNew";
import Cookies from 'js-cookie';
import { NumericFormat } from 'react-number-format';
import dateFormat from "dateformat";
import SignaturePad from 'react-signature-pad-wrapper'
// Images
import Grid from "@mui/material/Grid";
import BackgroundBlogCard from "examples/Cards/BlogCards/BackgroundBlogCard";
// Soft UI Dashboard PRO React components
import logoIcon from "assets/images/icon.png"
import ivancik from "assets/images/solacloud_icon.png";

function CallCenter(props) {
    let params = useParams();
    const { t } = useTranslation();
    const [data, setData] = useState([]);
    const [saving, setSaving] = useState(false);
    const location = useLocation()
    const [refresh, setRefresh] = useState(0);
    const [refresh2, setRefresh2] = useState(0);
    const [refesh3, setRefesh3] = useState(0);
    const [invoices, setInvoices] = useState([]);
    const [successSB, setSuccessSB] = useState(false);
    const [openPDF, setOpenPDF] = useState(false);
    const signaturePadRefClient = useRef(null);
    const [showPadClient, setShowPadClient] = useState(true);
    const [playAnimation, setPlayAnimation] = useState(true);
    const [signNew, setSignNew] = useState(false);
    const [id, setID] = useState(0);
    const [settings, setSettings] = useState([]);
    const [backend_url, setBackendUrl] = useState('');

        useEffect(() => {
            const searchParams = new URLSearchParams(location.search);
            if (searchParams?.get('url')) {
                setBackendUrl(searchParams?.get('url'));
            } else {
                setBackendUrl('');
            }
        }, [location])
        

    let tempAuth = Cookies.get('token') ? true : false;
    const [auth, setAuth] = useState(tempAuth);

    useEffect((auth) => {
        const checkAuth = () => {
            let tempAuth = Cookies.get('token') ? true : false;
            if (tempAuth !== auth)
                setAuth(tempAuth);
        }

        const authObserver = setInterval(checkAuth, 3000);

        return () => {
            clearInterval(authObserver);
        };
    }, []);

    useEffect(() => {
        if (!auth) return;

        callFetch("get-application-settings", "GET", [], setError).then((res) => {
            setSettings(res);
        });
    }, [auth])

    const openThePDF = () => {
        setOpenPDF(true);
        var url = "get-client-sign-order-overview/" + params?.id
        callFetch(url, "GET", []).then((res) => {
            if (res.quation?.client_sign_order_overview) {
                signaturePadRefClient.current.fromDataURL(res.quation?.client_sign_order_overview);
            }
        })
    }

    useEffect(() => { // useEffect hook
        if (playAnimation) {
            //document.body.classList.add('loading');
        }
        setTimeout(() => { // simulate a delay
            setPlayAnimation(false)
            //document.body.classList.remove('loading');
        }, 10000);
    }, [refresh]);
    const hideSignClient = () => {
        setShowPadClient(true);
    }

    const renderSuccessSB = (
        <SoftSnackbar
            color="success"
            icon="check"
            title={t('Success')}
            //content={t("Changes saved successfully")}
            dateTime={t("")}
            open={successSB}
            onClose={() => setSuccessSB(false)}
            close={() => setSuccessSB(false)}
            bgSuccess
        />
    );
    const {
        register,
        handleSubmit,
        setError,
        setValue,
        getValues,
        formState: { errors },
    } = useForm();


    const onSubmit = (formData) => {
        if (signaturePadRefClient.current.isEmpty()) {
            // alert('Sign please');
            setRefresh(refresh + 1);
            return false;
        }

        setSaving(true);

        formData.client_sign = signaturePadRefClient.current.toDataURL('image/png');
        formData.id = data?.data?.quations?.id;

        var url = "save-client-sign-order-overview";
        callFetch(url, "POST", formData, setError).then((res) => {
            setRefresh(refresh + 1);
            setTimeout(() => { setID(data?.data?.quations?.id); setSignNew(true) }, 5000);
            setTimeout(() => { setSignNew(false) }, 10000);
            setPlayAnimation(true);
            setSaving(false);

            if (res.status == 'success') {
                document.getElementById('successSignModalBtn').click()
            }
        });
    }

    //Edit Data load
    useEffect(() => {
        if (!Cookies.get('permissions')) return;

        flatpickr(".flat-pickr");


        if (params?.id) {
            //callFetch("projects/" + params.id + "/edit?date=" + getValues('date') + '&time=' + getValues('time'), "GET", []).then((res) => {
            callFetch("order-overview/" + params.id, "GET", []).then((res) => {
                setData(res);
                setInvoices(res.invoices);
                for (let [key, value] of Object.entries(res.data)) {
                    if (key == 'date') {
                        setValue(key, (value == null || value == 'null' ? "" : value));
                    } else if (key == 'plz') {
                        setValue(key, (value == null || value == 'null' ? "" : value));
                    } else if (key == 'customer') {

                    } else {
                        setValue(key, (value == null || value == 'null' ? "" : value));
                    }
                }
                setValue('full_address', res?.data?.street + ' ' + res?.data?.nr);
            });
        }
    }, [params?.id, refesh3]);


    return !auth ? <Navigate to='/' /> :
    <>
    <div className="container-fluid my-3">

        <div className="card">
            <div className="card-body d-flex align-items-center justify-content-start gap-3 pb-3">
                <img
                    src={logoIcon}
                    alt="main_logo"
                    style={{
                        height: '35px'
                    }}
                />
                <h1 className="title">Ihre Bestellübersicht</h1>
            </div>
        </div>

        {params?.id > 0 && (
            <>
                {openPDF ? (
                    <>
                        <Grid container spacing={2} mt={0}>
                            <Grid item xs={12} md={7.3}>
                                <div className="card">
                                    <div className="card-body pb-2">
                                        {saving === false ? (
                                            <iframe
                                                src={Cookies.get('backend_url') + 'quation/' + data?.data?.quations?.id + '#page=4'}
                                                style={{
                                                    width: '100%',
                                                    height: 'calc(100vh - 150px)',
                                                    background: '#2730420F'
                                                }}
                                            ></iframe>
                                        ) : ''}
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12} md={4.7}>
                                <div className="card">
                                    <div
                                        className="card-body"
                                        style={{
                                            height: 'calc(100vh - 192px)'
                                        }}
                                    >
                                        {/*!showPadClient ?
                                            <div>
                                                <div className="sign-pad">
                                                    <i className="fa fa-pencil" onClick={() => hideSignClient()} style={{ position: 'relative', top: '140px' }}></i>
                                                    <img style={{ width: '100%' }} src={data && data.order ? process.env.REACT_APP_STORAGE_URL + 'signs/' + data.order.client_sign : ''} alt="" />
                                                    <p style={{ borderTop: '1px solid #000', textAlign: 'center' }}>{t('Unterschrift Auftraggeber')}</p>
                                                </div>
                                                <button type="button" className="btn btn-outline-dark" onClick={() => { signaturePadRefClient.current.clear(); }}>
                                                    <i className="fa fa-eraser"></i> Radieren
                                                </button>
                                            </div> : ' '*/}
                                        {showPadClient ?
                                            <>
                                                <div className="sign-pad-new border border-radius">
                                                    <button
                                                        type="button"
                                                        className="btn btn-sm btn-secondary m-0 float-end"
                                                        onClick={() => { signaturePadRefClient.current.clear(); }}
                                                    >
                                                        <i className="ph ph-eraser text-secondary"></i>
                                                    </button>
                                                    <SignaturePad
                                                        options={{
                                                            minWidth: 1,
                                                            maxWidth: 1,
                                                            penColor: 'rgb(0, 0, 0)',
                                                            //backgroundColor: '#b9d1ff5e',
                                                        }}
                                                        canvasProps={{
                                                            style: {
                                                                backgroundColor: 'rgb(99 176 255 / 0%)',
                                                                width: '100%', // Full width
                                                                height: '165px' // Custom height
                                                            },
                                                        }}
                                                        ref={signaturePadRefClient} />
                                                    <p className="border-top m-0 text-primary text-center text-link">{t('Unterschrift Auftraggeber')}</p>
                                                </div>
                                            </>
                                            : ''}
                                    </div>
                                    <div className="card-footer border-top  d-flex align-items-center justify-content-end gap-3">
                                        <a
                                            onClick={() => setOpenPDF(false)}
                                            className="btn btn-secondary m-0 d-flex align-items-center justify-content-between gap-2"
                                        ><i className="ph ph-caret-left"></i> {t('Zurück')}</a>

                                        {saving ? (
                                            <button
                                                type="button"
                                                className="btn btn-disabled m-0"
                                                disabled
                                            >
                                                {t('Saving ...')}
                                            </button>
                                        ) : (
                                            <button
                                                type="button"
                                                id="saveSign"
                                                onClick={() => onSubmit({})}
                                                className="btn btn-primary m-0"
                                            >
                                                {t('Akzeptieren und Unterschreiben')}
                                            </button>
                                        )}
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </>
                ) : (
                    <>
                        <Grid container spacing={2} mt={0}>
                            <Grid item xs={12} md={7.3}>
                                <div className="card">
                                    <div className="card-header d-flex align-items-center justify-content-start gap-3 pb-0">
                                        <div
                                            style={{
                                                height: '64px',
                                                width: '64px',
                                                borderRadius: '50%',
                                                border: '1px solid #E7EBF3'
                                            }}
                                            className="d-flex align-items-center justify-content-center"
                                        >
                                            <i
                                                className="ph ph-solar-panel text-brand-primary"
                                                style={{
                                                    fontSize: '32px'
                                                }}
                                            ></i>
                                        </div>
                                        <div className="d-flex flex-column gap-2">
                                            <h1 className="title title-xl">Ihre Bestellübersicht</h1>
                                            <p className="text-secondary m-01">Maysun Solar</p>
                                        </div>
                                    </div>
                                    <div className="card-body pb-3">

                                        {data?.modules ? data?.modules.map((module, key) => (
                                            <>
                                                <div className="row mb-3">
                                                    <div className="col-md-4">
                                                        <div className="card bg-secondary border">
                                                            <div className="card-body pb-3 d-flex align-items-center justify-content-between">
                                                                <div>
                                                                    <p className="text-link text-secondary">Model</p>
                                                                    <h3 className="title">
                                                                        {module?.short_itle}
                                                                        {/*module?.name} | {module?.description ? JSON.parse(module?.description).Markenhersteller : ''*/}
                                                                    </h3>
                                                                </div>
                                                                <div
                                                                    style={{
                                                                        height: '40px',
                                                                        width: '40px',
                                                                        background: '#ffffff'
                                                                    }}
                                                                    className="d-flex align-items-center justify-content-center border-radius"
                                                                >
                                                                    <i
                                                                        className="ph ph-solar-roof text-secondary"
                                                                        style={{
                                                                            fontSize: '24px'
                                                                        }}
                                                                    ></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-4">
                                                        <div className="card bg-secondary border">
                                                            <div className="card-body pb-3 d-flex align-items-center justify-content-between">
                                                                <div>
                                                                    <p className="text-link text-secondary">Power</p>
                                                                    <h3 className="title"> {module?.watt}W</h3>
                                                                </div>
                                                                <div
                                                                    style={{
                                                                        height: '40px',
                                                                        width: '40px',
                                                                        background: '#ffffff'
                                                                    }}
                                                                    className="d-flex align-items-center justify-content-center border-radius"
                                                                >
                                                                    <i
                                                                        className="ph ph-lightning text-secondary"
                                                                        style={{
                                                                            fontSize: '24px'
                                                                        }}
                                                                    ></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-4">
                                                        <div className="card bg-secondary border">
                                                            <div className="card-body pb-3 d-flex align-items-center justify-content-between">
                                                                <div>
                                                                    <p className="text-link text-secondary">Abmessung</p>
                                                                    <h3 className="title">{module?.description ? JSON.parse(module?.description).Modulabmessung_width : ''} - {module?.description ? JSON.parse(module?.description).Modulabmessung_height : ''}mm</h3>
                                                                </div>
                                                                <div
                                                                    style={{
                                                                        height: '40px',
                                                                        width: '40px',
                                                                        background: '#ffffff'
                                                                    }}
                                                                    className="d-flex align-items-center justify-content-center border-radius"
                                                                >
                                                                    <i
                                                                        className="ph ph-ruler text-secondary"
                                                                        style={{
                                                                            fontSize: '24px'
                                                                        }}
                                                                    ></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )) : ''}

                                        {!data?.modules || !data?.modules.length ? (
                                            <div className="row mb-3">
                                                <div className="col-md-4">
                                                    <div className="card bg-secondary border">
                                                        <div className="card-body pb-3 d-flex align-items-center justify-content-between">
                                                            <div>
                                                                <p className="text-link text-secondary">Model</p>
                                                                <h3 className="title">--</h3>
                                                            </div>
                                                            <div
                                                                style={{
                                                                    height: '40px',
                                                                    width: '40px',
                                                                    background: '#ffffff'
                                                                }}
                                                                className="d-flex align-items-center justify-content-center border-radius"
                                                            >
                                                                <i
                                                                    className="ph ph-solar-roof text-secondary"
                                                                    style={{
                                                                        fontSize: '24px'
                                                                    }}
                                                                ></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-4">
                                                    <div className="card bg-secondary border">
                                                        <div className="card-body pb-3 d-flex align-items-center justify-content-between">
                                                            <div>
                                                                <p className="text-link text-secondary">Power</p>
                                                                <h3 className="title">--W</h3>
                                                            </div>
                                                            <div
                                                                style={{
                                                                    height: '40px',
                                                                    width: '40px',
                                                                    background: '#ffffff'
                                                                }}
                                                                className="d-flex align-items-center justify-content-center border-radius"
                                                            >
                                                                <i
                                                                    className="ph ph-lightning text-secondary"
                                                                    style={{
                                                                        fontSize: '24px'
                                                                    }}
                                                                ></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-4">
                                                    <div className="card bg-secondary border">
                                                        <div className="card-body pb-3 d-flex align-items-center justify-content-between">
                                                            <div>
                                                                <p className="text-link text-secondary">Abmessung</p>
                                                                <h3 className="title">-- - --mm</h3>
                                                            </div>
                                                            <div
                                                                style={{
                                                                    height: '40px',
                                                                    width: '40px',
                                                                    background: '#ffffff'
                                                                }}
                                                                className="d-flex align-items-center justify-content-center border-radius"
                                                            >
                                                                <i
                                                                    className="ph ph-ruler text-secondary"
                                                                    style={{
                                                                        fontSize: '24px'
                                                                    }}
                                                                ></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : ''}

                                        <div className="row mt-3">
                                            <div className="col-md-6">
                                                <TimelineNew
                                                    refresh={refresh2}
                                                    api_url={Cookies.get('api_url') || ''}
                                                    height={'482px'}
                                                />
                                            </div>
                                            <div className="col-md-6">
                                                <div className="card bg-secondary mb-2">
                                                    <div
                                                        className="card-body pb-2 scroll-cs"
                                                        style={{
                                                            height: '340px'
                                                        }}
                                                    >
                                                        <div className="border-bottom">
                                                            <h3 className="title text-lg mb-1">Lieferadresse {/*<span style={{ color: 'red', fontSize: '10px' }}>(Kundendaten löschen)</span>*/}</h3>

                                                            <div className="d-flex align-items-center justify-content-between">
                                                                <p className="text-link text-secondary">Name</p>
                                                                <p className="text-link text-primary">{data?.data?.vorname ?? ''}  {data?.data?.name ?? ''}</p>
                                                            </div>

                                                            <div className="d-flex align-items-center justify-content-between">
                                                                <p className="text-link text-secondary">Tel</p>
                                                                <p className="text-link text-primary">{(data?.data?.telefonnummer ? data?.data?.telefonnummer : '') + (data?.data?.mobilnummer ? (data?.data?.telefonnummer ? ', ' : '') + data?.data?.mobilnummer : '')}</p>
                                                            </div>

                                                            <div className="d-flex align-items-center justify-content-between">
                                                                <p className="text-link text-secondary">E-mail</p>
                                                                <p className="text-link text-primary">{data?.data?.email}</p>
                                                            </div>

                                                            <div className="d-flex align-items-center justify-content-between mb-1">
                                                                <p className="text-link text-secondary">Address</p>
                                                                <p className="text-link text-primary">{[data?.data?.street && `${data?.data?.street} ${data?.data?.nr}`, data?.data?.plz, data?.data?.ort].filter(Boolean).join(", ")}</p>
                                                            </div>
                                                        </div>

                                                        <div className="mt-2">
                                                            <h3 className="title text-lg mb-1">Rechnung Details</h3>

                                                            <div className="d-flex align-items-center justify-content-between">
                                                                <p className="text-link text-secondary">Rechnungsempfänger</p>
                                                                <p className="text-link text-primary">{data?.data?.vorname} {data?.data?.name}</p>
                                                            </div>

                                                            <div className="d-flex align-items-center justify-content-between">
                                                                <p className="text-link text-secondary">Tel</p>
                                                                <p className="text-link text-primary">{(data?.data?.telefonnummer ? data?.data?.telefonnummer : '') + (data?.data?.mobilnummer ? (data?.data?.telefonnummer ? ', ' : '') + data?.data?.mobilnummer : '')}</p>
                                                            </div>

                                                            <div className="d-flex align-items-center justify-content-between">
                                                                <p className="text-link text-secondary">E-mail</p>
                                                                <p className="text-link text-primary">{data?.data?.email}</p>
                                                            </div>

                                                            <div className="d-flex align-items-center justify-content-between mb-2">
                                                                <p className="text-link text-secondary">Address</p>
                                                                <p className="text-link text-primary">{[data?.data?.street && `${data?.data?.street} ${data?.data?.nr}`, data?.data?.plz, data?.data?.ort].filter(Boolean).join(", ")}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <BackgroundBlogCard
                                                    image={ivancik}
                                                    title={"750 € Prämie sichern"}
                                                    description={
                                                        <>
                                                            Empfehlen Sie Ihren Kunden {settings?.angebot?.company_name ? settings?.angebot?.company_name : '--'} weiter und erhalten Sie eine Sonderprämie i.h.v 750 EURO
                                                        </>
                                                    }
                                                    action={{
                                                        type: "button",
                                                        className: "btn btn-secondary btn-hover-white d-flex align-items-center justify-content-between m-0",
                                                        variant: 'contained',
                                                        route: "#",
                                                        label: "Erfahre mehr",
                                                        iconClass: 'ph ph-caret-right',
                                                        iconposition: 'right',
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12} md={4.7}>
                                <div className="card" >
                                    <div className="card-header">
                                        <h3 className="title">Auftrag Details</h3>
                                    </div>
                                    <div className="card-body scroll-cs" style={{ height: '217px' }}>
                                        {data?.data?.quations?.id && (
                                            <div
                                                className="d-flex align-items-center justify-content-between bg-secondary border-radius mb-2"
                                                style={{
                                                    padding: '12px'
                                                }}
                                            >
                                                <div className="d-flex align-items-center justify-content-between gap-2">
                                                    <i className="ph ph-file-pdf text-primary" style={{ fontSize: '24px' }}></i>
                                                    <p className="text-link text-primary m-0">Angebot-{data?.data?.quations?.id}</p>
                                                </div>
                                                <div className="d-flex align-items-center justify-content-between gap-2">
                                                    <p className="text-link text-secondary m-0">{dateFormat(data?.data?.quations?.created_at, "dd.mm.yyyy")}</p>
                                                    <a
                                                        className="btn btn-secondary btn-sm m-0 d-flex align-items-center justify-content-between gap-2"
                                                        target={"_blank"}
                                                        href={settings?.backend_url + 'quation/' + data?.data?.quations?.id}
                                                    >
                                                        Herunterladen
                                                        <i className="ph ph-download-simple text-brand-primary" style={{ fontSize: '20px' }}></i></a>
                                                </div>
                                            </div>
                                        )}

                                        {data?.data?.quations?.order?.id && (
                                            <div
                                                className="d-flex align-items-center justify-content-between bg-secondary border-radius mb-2"
                                                style={{
                                                    padding: '12px'
                                                }}
                                            >
                                                <div className="d-flex align-items-center justify-content-between gap-2">
                                                    <i className="ph ph-file-pdf text-primary" style={{ fontSize: '24px' }}></i>
                                                    <p className="text-link text-primary m-0">Auftrag-{data?.data?.quations?.order?.id}</p>
                                                </div>
                                                <div className="d-flex align-items-center justify-content-between gap-2">
                                                    <p className="text-link text-secondary m-0">{dateFormat(data?.data?.quations?.order?.created_at, "dd.mm.yyyy")}</p>
                                                    <a
                                                        className="btn btn-secondary btn-sm m-0 d-flex align-items-center justify-content-between gap-2"
                                                        target={"_blank"}
                                                        href={settings?.backend_url + 'order/' + data?.data?.quations?.order?.id}
                                                    >
                                                        Herunterladen
                                                        <i className="ph ph-download-simple text-brand-primary" style={{ fontSize: '20px' }}></i></a>
                                                </div>
                                            </div>
                                        )}

                                        {data?.data?.quations?.order?.invoices && data?.data?.quations?.order?.invoices.map((invoice, key) => (
                                            <div
                                                className="d-flex align-items-center justify-content-between bg-secondary border-radius mb-2"
                                                style={{
                                                    padding: '12px'
                                                }}
                                            >
                                                <div className="d-flex align-items-center justify-content-between gap-2">
                                                    <i className="ph ph-file-pdf text-primary" style={{ fontSize: '24px' }}></i>
                                                    <p className="text-link text-primary m-0">{key + 1}. Rechnung-{invoice.id}</p>
                                                </div>
                                                <div className="d-flex align-items-center justify-content-between gap-2">
                                                    <p className="text-link text-secondary m-0">{dateFormat(invoice.created_at, "dd.mm.yyyy")}</p>
                                                    <a
                                                        className="btn btn-secondary btn-sm m-0 d-flex align-items-center justify-content-between gap-2"
                                                        target={"_blank"}
                                                        href={settings?.backend_url + 'invoice/' + invoice.id}
                                                    >
                                                        Herunterladen
                                                        <i className="ph ph-download-simple text-brand-primary" style={{ fontSize: '20px' }}></i></a>
                                                </div>
                                            </div>
                                        ))}
                                    </div>

                                    <div className="card-header">
                                        <h3 className="title">Ihr Lieferant</h3>
                                    </div>
                                    <div className="card-body pb-5">
                                        <img
                                            src={settings?.backend_url + 'storage/attachments/' + settings?.angebot?.logo}
                                            alt=""
                                            className="my-3"
                                            style={{
                                                height: '40px',
                                                width: 'auto'
                                            }}
                                        />

                                        <p className="text-primary text-lg mt-3">{settings?.angebot?.company_name ? settings?.angebot?.company_name : '--'}</p>

                                        <div className="d-flex align-items-center justify-content-between">
                                            <p className="text-link text-secondary">Street</p>
                                            <p className="text-link text-primary">{settings?.angebot?.address ? settings?.angebot?.address : '--'} {settings?.angebot?.nr ? settings?.angebot?.nr : '--'}</p>
                                        </div>

                                        <div className="d-flex align-items-center justify-content-between">
                                            <p className="text-link text-secondary">Plz & Ort</p>
                                            <p className="text-link text-primary">{settings?.angebot?.plz ? settings?.angebot?.plz : '--'} {settings?.angebot?.ort ? settings?.angebot?.ort : '--'}</p>
                                        </div>

                                        <div className="d-flex align-items-center justify-content-between">
                                            <p className="text-link text-secondary">E-mail</p>
                                            <p className="text-link text-primary">{settings?.angebot?.email ? settings?.angebot?.email : '--'}</p>
                                        </div>

                                        <div className="d-flex align-items-center justify-content-between">
                                            <p className="text-link text-secondary">Tel</p>
                                            <p className="text-link text-primary">{settings?.angebot?.telephone ? settings?.angebot?.telephone : '--'}</p>
                                        </div>
                                    </div>

                                    <div className="card-footer border-top">
                                        <div className="d-flex align-items-center justify-content-between pb-2">
                                            <p className="text-link text-secondary m-0">Summe</p>
                                            <p className="text-link text-primary m-0"><NumericFormat value={data?.data?.quations?.gesami_netto ?? 0} displayType="text" thousandSeparator={"."} decimalSeparator="," decimalScale={2} /> € </p>
                                        </div>

                                        {invoices && invoices.map((invoice, key) => (
                                            <div className="d-flex align-items-center justify-content-between border-top py-2">
                                                <p className="text-link text-secondary m-0"> {key + 1}.Abschlag {invoice.amountPerchantage}% &nbsp;&nbsp;&nbsp;&nbsp; {invoice.status == 0 ? <span className="badge badge-danger">Zahlung offen</span> : <span className="badge badge-success">Zahlung erhalten</span>}</p>
                                                <p className="text-link text-primary m-0"><NumericFormat value={invoice.brutto ?? 0} displayType="text" thousandSeparator={"."} decimalSeparator="," decimalScale={2} /> €</p>
                                            </div>
                                        ))}

                                        <div className="d-flex align-items-center justify-content-between border-top py-2">
                                            <p className="text-link text-secondary m-0">Gesamtsumme Netto</p>
                                            <p className="text-link text-primary m-0"><NumericFormat value={data?.data?.quations?.gesami_netto ?? 0} displayType="text" thousandSeparator={"."} decimalSeparator="," decimalScale={2} /> €</p>
                                        </div>

                                        <div className="d-flex align-items-center justify-content-end border-top pt-3">
                                            <button
                                                onClick={() => openThePDF(true)}
                                                className="btn btn-primary m-0"
                                            >Angebot Unterschreiben</button>
                                        </div>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </>
                )}
            </>
        )}



        <div className="row">
            {renderSuccessSB}
        </div>

        <button className="d-none" id="callcenterRefesh3" onClick={() => { setRefesh3(refesh3 + 1); }}>refesh3</button>
    </div>

      

    <button
        type="button"
        id="successSignModalBtn"
        className="btn bg-gradient-primary d-none"
        data-bs-toggle="modal"
        data-bs-target="#successSignModal"
    >{t('Success Sign Modal')}</button>
    <div className="modal fade" id="successSignModal" tabindex="-1" role="dialog" aria-labelledby="companyProfileChooseLabel" aria-hidden="true">
        <div className="modal-dialog  modal-dialog-centered">
            <div className="modal-content">
                <div className="modal-header">
                    <h3 className="title m-0">{t('Message')}</h3>
                    <i id="successSignModalClose" className="ph ph-x cursor-pointer" data-bs-dismiss="modal" aria-label="Close"></i>
                </div>
                <div className="modal-body text-center">
                    <i className="ph ph-seal-check text-success" style={{ fontSize: '40px' }}></i>
                    <h3 className="text-xl font-weight-600 text-primary m-0">Herzlichen Glückwunsch!</h3>

                    <p className="text-secondary text-lg font-weight-400">Fantastisch! Ihre Unterschrift markiert den Beginn einer Zukunft mit sauberer Energie. Herzlichen Glückwunsch zur Wahl einer umweltfreundlichen Lösung für Ihr Zuhause.</p>
                </div>
                <div className="modal-footer">
                    <button
                        type="button"
                        onClick={() => setOpenPDF(false)}
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        className="btn btn-primary m-0">Zu Ihrer Übersicht</button>
                </div>
            </div>
        </div>
    </div>
</>;
}

export default CallCenter;
