import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import ProjectIndexTable from "./ProjectIndexTable";
import {
  useSoftUIController,
  setTransparentNavbar,
  setMiniSidenav,
  setOpenConfigurator,
} from "context";

import { Divider, Icon, Menu, MenuItem } from "@mui/material";
import SoftButton from "components/SoftButton";
import callFetch from "helpers/callFetch";

function ProjectIndex() {
  const { t } = useTranslation();
  const [readyToViewOtherTabs, setReadyToViewOtherTabs] = useState(false);
  const [controller, dispatch] = useSoftUIController();
  const handleConfiguratorOpen = () =>
    setOpenConfigurator(dispatch, !openConfigurator);
  const handleCloseConfigurator = () => setOpenConfigurator(dispatch, false);
  const { miniSidenav, transparentNavbar, fixedNavbar, openConfigurator } =
    controller;

  const [menu, setMenu] = useState(null);
  const [status, setStatus] = useState("");
  const [activeTab, setActiveTab] = useState(
    Cookies.get("user") && JSON.parse(Cookies.get("user"))?.rolename === "Bank"
      ? "Verkauft"
      : "all"
  );
  const [filderData, setFilderData] = useState([]);

  const openMenu = (event) => setMenu(event.currentTarget);
  const closeMenu = (statu = "") => {
    setMenu(null);
    setStatus(statu);
  };

  const renderMenu = (
    <Menu
      anchorEl={menu}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "left" }}
      open={Boolean(menu)}
      onClose={() => {
        closeMenu("");
      }}
      keepMounted
    >
      <MenuItem
        onClick={() => {
          closeMenu("Export CSV");
          document.getElementById("project-export-csv").click();
        }}
      >
        Projekte Export CSV
      </MenuItem>
      <MenuItem
        onClick={() => {
          closeMenu("Export XLSX");
          document.getElementById("project-export-xlsx").click();
        }}
      >
        Projekte Export XLSX
      </MenuItem>

      <MenuItem
        onClick={() => {
          closeMenu("Lead Export CSV");
          document.getElementById("lead-export-csv").click();
        }}
      >
        Lead Export CSV
      </MenuItem>

      <MenuItem
        onClick={() => {
          closeMenu("Lead Export XLSX");
          document.getElementById("lead-export-xlsx").click();
        }}
      >
        Lead Export XLSX
      </MenuItem>
    </Menu>
  );

  useEffect(() => {
    if (!readyToViewOtherTabs) return;
    callFetch("get-project-filter-data", "GET", []).then((res) => {
      setFilderData(res);
    });
  }, [readyToViewOtherTabs]);

  useEffect(() => {
    setTimeout(() => {
      setReadyToViewOtherTabs(true);
    }, 2000);
  }, []);

  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="card mb-4">
            <div className="card-header pb-0" onClick={handleCloseConfigurator}>
              <div className="d-flex flex-column flex-lg-row justify-content-between">
                <p className="table-title-cs mb-0">{t("All Projects")}</p>
                <div className="d-flex flex-column flex-lg-row"> 
                  {JSON.parse(Cookies.get("permissions")).indexOf("Import") !==
                  -1 ? (
                    <>
                      <button
                        className="btn btn-secondary me-2 d-flex align-items-center"
                        data-bs-toggle="modal"
                        data-bs-target="#projectimportModal"
                      >
                       <i class="ph ph-arrow-square-in font-size-20 me-1"></i> {t("Import XLSX")}
                      </button>
                      <button
                        className="btn btn-secondary me-2 d-none d-flex"
                        id="projectoutputModal-show-button"
                        data-bs-toggle="modal"
                        data-bs-target="#projectoutputModal"
                      >
                       <i class="ph ph-arrow-square-out font-size-20 me-1"></i> {t("Output XLSX")}
                      </button>

                      {JSON.parse(Cookies.get("user")).roleid == 1 ? (
                        <>
                          <button
                            className="btn btn-secondary me-2 d-flex"
                            data-bs-toggle="modal"
                            data-bs-target="#projectWithSalesimportModal"
                          >
                            {t("With Sales Import XLSX")}
                          </button>
                          <button
                            className="btn btn-secondary me-2 d-none d-flex"
                            id="projectWithSalesoutputModal-show-button"
                            data-bs-toggle="modal"
                            data-bs-target="#projectWithSalesoutputModal"
                          >
                           <i class="ph ph-arrow-square-out font-size-20"></i> {t("With Sales Output XLSX")}
                          </button>
                        </>
                      ) : (
                        ""
                      )}
                    </>
                  ) : (
                    <></>
                  )}

                  {JSON.parse(Cookies.get("permissions")).indexOf("Export") !==
                  -1 ? (
                    <>
                      <button className="btn btn-secondary d-flex align-items-center" onClick={openMenu}>
                        <i class="ph ph-arrow-square-out font-size-20 me-1"></i> {status ? status : t("Export")}
                      </button>
                      {renderMenu}
                    </>
                  ) : (
                    <></>
                  )}

{JSON.parse(Cookies.get("permissions")).indexOf("PrC") !==
                  -1 ? (
                    <NavLink
                      to="/projects-create"
                      className="btn btn-icon btn-primary ms-2 d-flex align-items-center"
                    >
                     <i className="ph ph-plus font-size-20 me-2"></i>  {t("Add Project")}
                    </NavLink>
                  ) : (
                    <></>
                  )}
                </div>
              </div> 
            </div>
            <div className="card-body px-0 pt-0 pb-2 projects-menu">
              <div style={{ padding: "0px 15px" }}>
                <ul
                  className="nav nav-tabs nav-underline tabs-res project-filter-tab"
                  id="myTab"
                  role="tablist"
                >
                  {Cookies.get("user") &&
                  JSON.parse(Cookies.get("user"))?.rolename === "Bank" ? (
                    <>
                      <li className="nav-item" role="presentation">
                        <button
                          onClick={() => {
                            setActiveTab("Verkauft");
                          }}
                          className={
                            "nav-link " +
                            (activeTab == "Verkauft" ? "active" : "")
                          }
                          id="profile-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#profile-tab-pane"
                          type="button"
                          role="tab"
                          aria-controls="profile-tab-pane"
                          aria-selected="false"
                          style={{ color: "#0048B1", fontSize: "0.875rem" }}
                        >
                          Verkauft
                        </button>
                      </li>
                    </>
                  ) : (
                    <>
                      <li className="nav-item" role="presentation">
                        <button
                          onClick={() => {
                            setActiveTab("all");
                          }}
                          className={
                            "nav-link " + (activeTab == "all" ? "active" : "")
                          }
                          id="home-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#home-tab-pane"
                          type="button"
                          role="tab"
                          aria-controls="home-tab-pane"
                          aria-selected="true"
                          style={{ color: "#0048B1", fontSize: "0.875rem" }}
                        >
                          Alle Projekte
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button
                          onClick={() => {
                            setActiveTab("Angebot");
                          }}
                          className="nav-link"
                          id="contact-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#contact-tab-pane"
                          type="button"
                          role="tab"
                          aria-controls="contact-tab-pane"
                          aria-selected="false"
                          style={{ color: "#0048B1", fontSize: "0.875rem" }}
                        >
                          Angebot
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button
                          onClick={() => {
                            setActiveTab("signierteangebote");
                          }}
                          className="nav-link"
                          id="signierteangebote-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#signierteangebote-tab-pane"
                          type="button"
                          role="tab"
                          aria-controls="signierteangebote-tab-pane"
                          aria-selected="false"
                          style={{ color: "#0048B1", fontSize: "0.875rem" }}
                        >
                          Signierte Angebote
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button
                          onClick={() => {
                            setActiveTab("Verkauft");
                          }}
                          className="nav-link"
                          id="profile-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#profile-tab-pane"
                          type="button"
                          role="tab"
                          aria-controls="profile-tab-pane"
                          aria-selected="false"
                          style={{ color: "#0048B1", fontSize: "0.875rem" }}
                        >
                          Verkauft
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button
                          onClick={() => {
                            setActiveTab("dc");
                          }}
                          className="nav-link"
                          id="contact-tab-dc"
                          data-bs-toggle="tab"
                          data-bs-target="#contact-tab-pane-dc"
                          type="button"
                          role="tab"
                          aria-controls="contact-tab-pan-dce"
                          aria-selected="false"
                          style={{ color: "#0048B1", fontSize: "0.875rem" }}
                        >
                          DC Planung
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button
                          onClick={() => {
                            setActiveTab("ac");
                          }}
                          className="nav-link"
                          id="contact-tab-ac"
                          data-bs-toggle="tab"
                          data-bs-target="#contact-tab-pane-ac"
                          type="button"
                          role="tab"
                          aria-controls="contact-tab-pane-ac"
                          aria-selected="false"
                          style={{ color: "#0048B1", fontSize: "0.875rem" }}
                        >
                          AC Planung
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button
                          onClick={() => {
                            setActiveTab("Fertigmeldung_erledigt");
                          }}
                          className="nav-link"
                          id="Fertigmeldung_erledigt"
                          data-bs-toggle="tab"
                          data-bs-target="#Fertigmeldung_erledigt-pane"
                          type="button"
                          role="tab"
                          aria-controls="Fertigmeldung_erledigt-pane"
                          aria-selected="false"
                          style={{ color: "#0048B1", fontSize: "0.875rem" }}
                        >
                          Fertigmeldung erledigt
                        </button>
                      </li>

                      <li className="nav-item" role="presentation">
                        <button
                          onClick={() => {
                            setActiveTab("deleted_projects");
                          }}
                          className="nav-link"
                          id="deleted_projects"
                          data-bs-toggle="tab"
                          data-bs-target="#deleted_projects-pane"
                          type="button"
                          role="tab"
                          aria-controls="deleted_projects-pane"
                          aria-selected="false"
                          style={{
                            color: "rgb(245, 11, 11)",
                            fontSize: "0.875rem",
                          }}
                        >
                          Gelöscht
                        </button>
                      </li>
                    </>
                  )}
                </ul>
              </div>
              <div className="tab-content" id="myTabContent">
                <div
                  className="tab-pane fade show active p-3"
                  id="home-tab-pane"
                  role="tabpanel"
                  aria-labelledby="home-tab"
                  tabindex="0"
                >
                  <ProjectIndexTable
                    filderData={filderData}
                    ready={true}
                    menu={activeTab}
                  />
                </div>
                {/*
                                <div className="tab-pane fade p-3" id="profile-tab-pane" role="tabpanel" aria-labelledby="profile-tab" tabindex="0">
                                    {readyToViewOtherTabs ? (
                                        <ProjectIndexTable filderData={filderData} ready={true} menu="1" />
                                    ) : ''}
                                </div>
                                <div className="tab-pane fade p-3" id="contact-tab-pane" role="tabpanel" aria-labelledby="contact-tab" tabindex="0">
                                    {readyToViewOtherTabs ? (
                                        <ProjectIndexTable filderData={filderData} ready={true} menu="3" />
                                    ) : ''}
                                </div>
                                <div className="tab-pane fade p-3" id="contact-tab-pane-dc" role="tabpanel" aria-labelledby="contact-tab-dc" tabindex="0">
                                    {readyToViewOtherTabs ? (
                                        <ProjectIndexTable filderData={filderData} ready={true} menu="dc" />
                                    ) : ''}
                                </div>
                                <div className="tab-pane fade p-3" id="contact-tab-pane-ac" role="tabpanel" aria-labelledby="contact-tab-ac" tabindex="0">
                                    {readyToViewOtherTabs ? (
                                        <ProjectIndexTable filderData={filderData} ready={true} menu="ac" />
                                    ) : ''}
                                </div>
                                <div className="tab-pane fade p-3" id="Fertigmeldung_erledigt-pane" role="tabpanel" aria-labelledby="contact-tab-ac" tabindex="0">
                                    {readyToViewOtherTabs ? (
                                        <ProjectIndexTable filderData={filderData} ready={true} menu="Fertigmeldung_erledigt" />
                                    ) : ''}
                                </div>
                                */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProjectIndex;
