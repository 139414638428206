import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Navigate } from "react-router-dom";
import callFetch from 'helpers/callFetch';
import { useTranslation } from 'react-i18next';
import dateFormat from "dateformat";
import flatpickr from "flatpickr";
import { NumericFormat } from 'react-number-format';
import SoftAlert from "components/SoftAlert";
import SoftSnackbar from "components/SoftSnackbar";
import { Switch } from '@mui/material'
import { NavLink } from 'react-router-dom'

const Create = () => {
    const { t } = useTranslation();
    const [data, setData] = useState([]);
    const [bundles, setBundles] = useState([{ bundle_name: '', price: '', items: [{ product: '', type: '', number: '' }] }]);
    const [saving, setSaving] = useState(false);
    const [disabledSubmitButton, setDisabledSubmitButton] = useState(false);
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [refresh, setRefresh] = useState(0);
    const [receivedError, setReceivedError] = useState(null);
    const [status, setStatus] = useState(true);
    const handleSetStatus = () => setStatus(!status);

    const {
        register,
        handleSubmit,
        setError,
        setValue,
        formState: { errors },
    } = useForm();

    const [messageSb, setMessageSB] = useState(false);
    const [messageSbColor, setMessageSbColor] = useState('success');
    const [messageSbTitle, setMessageSbTitle] = useState('Something wrong!');

    const renderMessageSB = (
        <SoftSnackbar
            color={messageSbColor}
            title={t(messageSbTitle)}
            //content={t("Notification Description")}
            open={messageSb}
            onClose={() => setMessageSB(false)}
            close={() => setMessageSB(false)}
        />
    );


    useEffect(() => {
        flatpickr(".flat-pickr");
        callFetch("bundle/create", "GET", []).then((res) => {
            setData(res?.data);
            setValue('bundle_nummer', res?.data?.bundlenummer);
            setRefresh(refresh + 1)
        });
    }, []);

    const onSubmit = (formData) => {
        setSaving(true);
        formData.bundles = JSON.stringify(bundles);
        formData.status = status ? 1 : 0;

        callFetch("bundle", "POST", formData, setError).then((res) => {
            setTimeout(() => {
                setSaving(false);
                if (res.ok) {
                    setMessageSbTitle('Bundle create successfully!');
                    setMessageSbColor('success');
                    setMessageSB(true);
                    setDisabledSubmitButton(true);
                } else {
                    setMessageSbTitle('Something Wrong!');
                    setMessageSbColor('error');
                    setMessageSB(true);
                }
                if (!res.ok) return;
                setTimeout(() => {
                    setSubmitSuccess(true);
                }, 3000)
            }, 2000)
        });
    };

    return submitSuccess ? <Navigate to={`/bundle-preis`} /> :
        <>
            <form className={`needs-validation ${Object.keys(errors).length ? "was-validated" : ""}`} onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
                <div className="row" style={{ paddingBottom: '100px' }}>
                    <div className="col-md-9">
                        <div className="card ">
                            <div className="card-header">
                                <h6 className="title">{t('Bundle Price')}</h6>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>{t('Bundle-Preis Nr.')} *</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder={t('eg: buldle-001')}
                                                {...register("bundle_nummer", {
                                                    required: true,
                                                })}
                                                readOnly
                                                required
                                            />
                                            <div className="invalid-feedback">{errors.bundle_nummer && errors.bundle_nummer.message}</div>
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <div className="form-control form-control-sm d-flex align-items-center justify-content-between mt-4">
                                                <label className="me-3">{t('Status')}</label>
                                                <Switch checked={status} onChange={handleSetStatus} />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>
                                                {t('Bundle Group Name')} *
                                            </label>
                                            <br />
                                            <input
                                                type="text"
                                                className="form-control"
                                                {...register("bundle_group_name", {
                                                    required: true,
                                                })}
                                                required
                                            />
                                            <div className="invalid-feedback">{errors.currency && errors.currency.message}</div>
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>
                                                {t('Currency')} *
                                            </label>
                                            <br />
                                            <select
                                                className="form-control"
                                                {...register("currency", {
                                                    required: true,
                                                })}
                                                required
                                            >
                                                <option value="EUR">EUR (€)</option>
                                                <option value="USD">USD ($)</option>
                                            </select>
                                            <div className="invalid-feedback">{errors.currency && errors.currency.message}</div>
                                        </div>
                                    </div>
                                </div>

                                {bundles.map((bundle, j) => (
                                    <div className="bg-secondary p-3 border-radius mb-3">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>{t('Bundle Name')} *</label>
                                                    <input
                                                        type="text"
                                                        placeholder={t("Enter Bundle Name")}
                                                        className="form-control"
                                                        //{...register(`bundles_${j}_bundle_name`, { required: true })}
                                                        value={bundles[j].bundle_name}
                                                        onChange={(e) => {
                                                            var updateBundle = bundles;
                                                            updateBundle[j].bundle_name = e.target.value;
                                                            setBundles(updateBundle);
                                                            setRefresh(refresh + 1);
                                                        }}
                                                        required
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>{t('Price')} *</label>
                                                    <input
                                                        type="number"
                                                        step={0.01}
                                                        placeholder="Enter Bundle Price"
                                                        className="form-control"
                                                        ///{...register(`bundles_${j}_price`, { required: true })}
                                                        value={bundles[j].price}
                                                        onChange={(e) => {
                                                            var updateBundle = bundles;
                                                            updateBundle[j].price = e.target.value;
                                                            setBundles(updateBundle);
                                                            setRefresh(refresh + 1);
                                                        }}
                                                        required
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                {bundles[j].items.map((bundle_item, i) => (
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label className="mb-0 pb-0">{t('Product')} *</label>
                                                                <select
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder={t('Select Product')}
                                                                    //{...register(`bundles_${j}_${i}_product`, { required: true })}
                                                                    value={bundles[j].items[i].type + '-' + bundles[j].items[i].product}
                                                                    onChange={(e) => {

                                                                        if (e.target.value) {
                                                                            var value = e.target.value;
                                                                            var sepValue = value.split('-');
                                                                        } else {
                                                                            var sepValue = ['', ''];
                                                                        }

                                                                        var updateBundle = bundles;
                                                                        updateBundle[j].items[i].product = sepValue[1];
                                                                        updateBundle[j].items[i].type = sepValue[0];
                                                                        setBundles(updateBundle);
                                                                        setRefresh(refresh + 1);
                                                                    }}
                                                                    required
                                                                >
                                                                    <option value="">--</option>
                                                                    {data?.module && data?.module?.map((modul, i) => (
                                                                        <option key={i} value={'module-' + modul.id}>{modul.name}</option>
                                                                    ))}
                                                                    {data?.speicher && data?.speicher?.map((speiche, i) => (
                                                                        <option key={i} value={'speicher-' + speiche.id}>{speiche.name}</option>
                                                                    ))}
                                                                    {data?.wechselrichter && data?.wechselrichter?.map((wechselrichte, i) => (
                                                                        <option key={i} value={'wechselrichter-' + wechselrichte.id}>{wechselrichte.name}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6 d-flex align-items-center justify-content-between gap-2">
                                                            <div className="form-group flex-fill">
                                                                <label className="mb-0 pb-0">{t('Anzahl')}*</label>
                                                                <input
                                                                    type="number"
                                                                    className="form-control"
                                                                   // {...register(`bundles_${j}_${i}_number`, { required: true })}
                                                                    value={bundles[j].items[i].number}
                                                                    onChange={(e) => {
                                                                        var updateBundle = bundles;
                                                                        updateBundle[j].items[i].number = e.target.value;
                                                                        setBundles(updateBundle);
                                                                        setRefresh(refresh + 1);
                                                                    }}
                                                                    required
                                                                />
                                                            </div>
                                                            <i
                                                                className="ph ph-trash-simple cursor-pointer text-danger text-xl mt-2"
                                                                onClick={() => {
                                                                    var updateBundles = bundles;
                                                                    var updateItems = updateBundles[j].items;

                                                                    if (updateItems.length === 1) {
                                                                        console.log('remove bundle= ' + j);
                                                                        updateBundles.splice(j, 1);
                                                                    } else {
                                                                        console.log('remove bundle item= ' + i);
                                                                        updateItems.splice(i, 1);
                                                                        updateBundles[j].items = updateItems;
                                                                    }

                                                                    setBundles(updateBundles);
                                                                    setRefresh(refresh + 1);
                                                                }}
                                                            ></i>
                                                        </div>
                                                    </div>
                                                ))}

                                                <a
                                                    class="btn-link btn-plus-icon btn-icon-primary"
                                                    onClick={(e) => {
                                                        var addBundleItem = bundles;
                                                        addBundleItem[j].items.push({ product: '', type: '', number: '' });
                                                        console.log('addBundleItem')
                                                        console.log(addBundleItem)
                                                        setBundles(addBundleItem);
                                                        setRefresh(refresh + 1);
                                                    }}
                                                >{t('Produkt')}</a>
                                            </div>
                                        </div>
                                    </div>
                                ))}

                                <button
                                    type="button"
                                    className="btn btn-secondary d-flex align-items-center justify-content-between gap-2"
                                    onClick={(e) => {
                                        var addBundle = bundles;
                                        addBundle.push({ bundle_name: '', price: '', items: [{ product: '', type: '', number: '' }] });
                                        setBundles(addBundle);
                                        setRefresh(refresh + 1);
                                    }}
                                ><i className="ph ph-plus"></i>{t('Add Bundle')}</button>

                                <div className="row mt-3">
                                    <div className="col-12">{receivedError && <SoftAlert color="error" dismissible> {receivedError} </SoftAlert>}</div>
                                </div>
                            </div>
                        </div>


                    </div>

                </div>

                <div className="card fixed-bottom mb-3 fixed-bottom-width-available" >
                    <div className="card-footer d-flex align-items-center justify-content-end gap-2">
                        <NavLink to="/bundle-preis" className="btn btn-secondary m-0 d-flex align-items-center justify-content-between gap-2">
                            <i className='ph ph-caret-left'></i>
                            {t('Back')}
                        </NavLink>

                        {saving ? (
                            <button type="button" className="btn btn-disabled m-0" disabled>
                                {t('Saving ...')}
                            </button>
                        ) : (
                            <button type="submit" className="btn btn-primary m-0" disabled={disabledSubmitButton}>
                                {t('Save')}
                            </button>
                        )}
                    </div>
                </div>
            </form>
            {renderMessageSB}</>;
}

export default Create