import { React, useEffect, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useParams, NavLink } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import callFetch from "../../../helpers/callFetch";
import flatpickr from "flatpickr";
import SoftSnackbar from "components/SoftSnackbar";
import TimelineNew from "./TimelineNew";
import Cookies from 'js-cookie';
import { NumericFormat } from 'react-number-format';
import Swal from 'sweetalert2';
import dateFormat from "dateformat";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";
import SoftAlert from "components/SoftAlert";
// Images
import BackgroundBlogCard from "examples/Cards/BlogCards/BackgroundBlogCard";
// @mui material components
import Grid from "@mui/material/Grid";
import SignaturePad from 'react-signature-pad-wrapper'

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import OtpInput from 'react-otp-input';
import Group_1000004630 from "assets/images/Group_1000004630.png";
import logoIcon from "assets/images/icon.png"
import ivancik from "assets/images/solacloud_icon.png";

function OrdersOverviewEmailConfirm(props) {
    let params = useParams();
    const location = useLocation()
    const [otp, setOtp] = useState('');
    const { t } = useTranslation();
    const [data, setData] = useState([]);
    const [saving, setSaving] = useState(false);
    const [refresh, setRefresh] = useState(0);
    const [refresh2, setRefresh2] = useState(0);
    const [refesh3, setRefesh3] = useState(0);
    const [invoices, setInvoices] = useState([]);
    const [successSB, setSuccessSB] = useState(false);
    const [confirmInfo, setConfirmInfo] = useState([]);
    const [invalidCode, setInvalidCode] = useState(false);
    const [showNotification, setShowNotification] = useState(false);
    const [steps, setSteps] = useState(1);
    const [resend, setResend] = useState(false);
    const [sending, setSending] = useState(false);
    const [openPDF, setOpenPDF] = useState(false);
    const signaturePadRefClient = useRef(null);
    const [showPadClient, setShowPadClient] = useState(true);
    const [playAnimation, setPlayAnimation] = useState(true);
    const [settings, setSettings] = useState([]);
    const [showButton, setShowButton] = useState(false);
    const [backend_url, setBackendUrl] = useState('');

    const openThePDF = () => {
        setOpenPDF(true);
        var url = "get-client-sign-order-overview/" + params?.id
        callFetch(url, "GET", [], {}, backend_url + '/api/v1/').then((res) => {
            if (res.quation?.client_sign_order_overview) {
                signaturePadRefClient.current.fromDataURL(res.quation?.client_sign_order_overview);
            }

        })
    }


    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        if (searchParams?.get('url')) {
            setBackendUrl(searchParams?.get('url'));
        } else {
            setBackendUrl('');
        }
    }, [location])


    useEffect(() => {
        if (!backend_url) return;

        callFetch("get-application-settings", "GET", [], setError, backend_url + '/api/v1/').then((res) => {
            setSettings(res);
            setShowButton(true);
        });
    }, [backend_url])

    useEffect(() => { // useEffect hook
        if (playAnimation) {
            //document.body.classList.add('loading');
        }
        setTimeout(() => { // simulate a delay
            setPlayAnimation(false)
            //document.body.classList.remove('loading');
        }, 10000);
    }, [refresh]);

    const renderSuccessSB = (
        <SoftSnackbar
            color="success"
            icon="check"
            title={t('Success')}
            //content={t("Changes saved successfully")}
            dateTime={t("")}
            open={successSB}
            onClose={() => setSuccessSB(false)}
            close={() => setSuccessSB(false)}
            bgSuccess
        />
    );
    const {
        register,
        handleSubmit,
        setError,
        setValue,
        getValues,
        formState: { errors },
    } = useForm();

    const hideSignClient = () => {
        setShowPadClient(true);
    }

    //Edit Data load
    useEffect(() => {
        if (!backend_url) return;

        flatpickr(".flat-pickr");
        if (params?.id) {
            callFetch("order-overview-info/" + params.id, "GET", [], {}, backend_url + '/api/v1/').then((res) => {
                setConfirmInfo(res);
                // console.log(res); 
            });
        }
    }, [params?.id, refesh3, backend_url]);

    const accept = () => {
        if (!backend_url) return;

        callFetch("order-overview-information-for-accept/" + params.id, "GET", [], {}, backend_url + '/api/v1/').then((res) => {
            setData(res);
            if (res.message == 'success') {
                setSteps(3);
                //openThePDF();
                setInvalidCode(false);
            }
        });
    }

    const sendVerificationCode = () => {
        setSending(true);
        callFetch("order-overview-send-verification-code/" + params.id, "GET", [], {}, backend_url + '/api/v1/').then((res) => {
            setTimeout(() => { setShowNotification(true); setSteps(2); setSending(false) }, 1000);
        });
    }

    const resendNewCode = () => {
        setShowNotification(false);
        callFetch("order-overview-resend-verification-code/" + params.id, "GET", [], {}, backend_url + '/api/v1/').then((res) => {
            setTimeout(() => { setShowNotification(true) }, 1000);
            setResend(true);
            setSteps(2);
            // document.getElementById("emailModalOneClose").click();
            // document.getElementById("emailModal2").click(); 
        });
    }

    const onSubmit = (formData) => {
        if (Number(otp) >= 1111) {
            var code = otp;
            callFetch("order-overview-verify-code/" + code + '/' + params.id, "GET", [], {}, backend_url + '/api/v1/').then((res) => {
                setData(res);
                setOtp('');
                if (res.message == 'success') {
                    let timerInterval;
                    Swal.fire({
                        //title: "Code prüfung...",
                        html: "Bitte warten Sie einen Moment, während wir Ihren Zugang überprüfen.",
                        timer: 4000,
                        timerProgressBar: true,
                        didOpen: () => {
                            Swal.showLoading();
                            timerInterval = setInterval(() => {
                                const content = Swal.getHtmlContainer();
                                if (content) {
                                    const b = content.querySelector("b");
                                    if (b) {
                                        b.textContent = Swal.getTimerLeft();
                                    }
                                }
                            }, 100);
                        },
                        willClose: () => {
                            clearInterval(timerInterval);
                        },
                    }).then((result) => {
                        /* Read more about handling dismissals below */
                        if (result.dismiss === Swal.DismissReason.timer) {
                            setSteps(3);
                        }
                    });
                    //openThePDF();
                    setInvalidCode(false);
                } else {
                    setInvalidCode(true);
                }

                // console.log(res); 
            });
        } else {

            if (signaturePadRefClient.current.isEmpty()) {
                // alert('Sign please');
                setRefresh(refresh + 1);
                return false;
            }

            setSaving(true);

            formData.client_sign = signaturePadRefClient.current.toDataURL('image/png');
            formData.id = data?.data?.quations?.id;

            var url = "save-client-sign-order-overview";
            callFetch(url, "POST", formData, setError, backend_url + '/api/v1/').then((res) => {
                setRefresh(refresh + 1);
                setPlayAnimation(true);
                setSaving(false);

                if (res.status == 'success') {
                    document.getElementById('successSignModalBtn').click()
                }
            });
        }

    }

    return (
        <>
            <div className="container-fluid my-3">

                <div className="card">
                    <div className="card-body d-flex align-items-center justify-content-start gap-3 pb-3">
                        <img
                            src={logoIcon}
                            alt="main_logo"
                            style={{
                                height: '35px'
                            }}
                        />
                        <h1 className="title">Ihre Bestellübersicht</h1>
                    </div>
                </div>

                {params?.id > 0 && (
                    <>
                        {openPDF ? (
                            <>
                                <Grid container spacing={2} mt={0}>
                                    <Grid item xs={12} md={7.3}>
                                        <div className="card">
                                            <div className="card-body pb-2">
                                                {saving === false ? (
                                                    <iframe
                                                        src={backend_url + '/quation/' + data?.data?.quations?.id + '#page=4'}
                                                        style={{
                                                            width: '100%',
                                                            height: 'calc(100vh - 150px)',
                                                            background: '#2730420F'
                                                        }}
                                                    ></iframe>
                                                ) : ''}
                                            </div>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} md={4.7}>
                                        <div className="card">
                                            <div
                                                className="card-body"
                                                style={{
                                                    height: 'calc(100vh - 192px)'
                                                }}
                                            >
                                                {/*!showPadClient ?
                                                    <div>
                                                        <div className="sign-pad">
                                                            <i className="fa fa-pencil" onClick={() => hideSignClient()} style={{ position: 'relative', top: '140px' }}></i>
                                                            <img style={{ width: '100%' }} src={data && data.order ? process.env.REACT_APP_STORAGE_URL + 'signs/' + data.order.client_sign : ''} alt="" />
                                                            <p style={{ borderTop: '1px solid #000', textAlign: 'center' }}>{t('Unterschrift Auftraggeber')}</p>
                                                        </div>
                                                        <button type="button" className="btn btn-outline-dark" onClick={() => { signaturePadRefClient.current.clear(); }}>
                                                            <i className="fa fa-eraser"></i> Radieren
                                                        </button>
                                                    </div> : ' '*/}
                                                {showPadClient ?
                                                    <>
                                                        <div className="sign-pad-new border border-radius">
                                                            <button
                                                                type="button"
                                                                className="btn btn-sm btn-secondary m-0 float-end"
                                                                onClick={() => { signaturePadRefClient.current.clear(); }}
                                                            >
                                                                <i className="ph ph-eraser text-secondary"></i>
                                                            </button>
                                                            <SignaturePad
                                                                options={{
                                                                    minWidth: 1,
                                                                    maxWidth: 1,
                                                                    penColor: 'rgb(0, 0, 0)',
                                                                    //backgroundColor: '#b9d1ff5e',
                                                                }}
                                                                canvasProps={{
                                                                    style: {
                                                                        backgroundColor: 'rgb(99 176 255 / 0%)',
                                                                        width: '100%', // Full width
                                                                        height: '165px' // Custom height
                                                                    },
                                                                }}
                                                                ref={signaturePadRefClient} />
                                                            <p className="border-top m-0 text-primary text-center text-link">{t('Unterschrift Auftraggeber')}</p>
                                                        </div>
                                                    </>
                                                    : ''}
                                            </div>
                                            <div className="card-footer border-top  d-flex align-items-center justify-content-end gap-3">
                                                <a
                                                    onClick={() => setOpenPDF(false)}
                                                    className="btn btn-secondary m-0 d-flex align-items-center justify-content-between gap-2"
                                                ><i className="ph ph-caret-left"></i> {t('Zurück')}</a>

                                                {saving ? (
                                                    <button
                                                        type="button"
                                                        className="btn btn-disabled m-0"
                                                        disabled
                                                    >
                                                        {t('Saving ...')}
                                                    </button>
                                                ) : (
                                                    <button
                                                        type="button"
                                                        id="saveSign"
                                                        onClick={() => onSubmit({})}
                                                        className="btn btn-primary m-0"
                                                    >
                                                        {t('Akzeptieren und Unterschreiben')}
                                                    </button>
                                                )}
                                            </div>
                                        </div>
                                    </Grid>
                                </Grid>
                            </>
                        ) : (
                            <>
                                <Grid container spacing={2} mt={0}>
                                    <Grid item xs={12} md={7.3}>
                                        <div className="card">
                                            <div className="card-header d-flex align-items-center justify-content-start gap-3 pb-0">
                                                <div
                                                    style={{
                                                        height: '64px',
                                                        width: '64px',
                                                        borderRadius: '50%',
                                                        border: '1px solid #E7EBF3'
                                                    }}
                                                    className="d-flex align-items-center justify-content-center"
                                                >
                                                    <i
                                                        className="ph ph-solar-panel text-brand-primary"
                                                        style={{
                                                            fontSize: '32px'
                                                        }}
                                                    ></i>
                                                </div>
                                                <div className="d-flex flex-column gap-2">
                                                    <h1 className="title title-xl">Ihre Bestellübersicht</h1>
                                                    <p className="text-secondary m-01">Maysun Solar</p>
                                                </div>
                                            </div>
                                            <div className="card-body pb-3">

                                                {data?.modules ? data?.modules.map((module, key) => (
                                                    <>
                                                        <div className="row mb-3">
                                                            <div className="col-md-4">
                                                                <div className="card bg-secondary border">
                                                                    <div className="card-body pb-3 d-flex align-items-center justify-content-between">
                                                                        <div>
                                                                            <p className="text-link text-secondary">Model</p>
                                                                            <h3 className="title">
                                                                                {module?.short_itle}
                                                                                {/*module?.name} | {module?.description ? JSON.parse(module?.description).Markenhersteller : ''*/}
                                                                            </h3>
                                                                        </div>
                                                                        <div
                                                                            style={{
                                                                                height: '40px',
                                                                                width: '40px',
                                                                                background: '#ffffff'
                                                                            }}
                                                                            className="d-flex align-items-center justify-content-center border-radius"
                                                                        >
                                                                            <i
                                                                                className="ph ph-solar-roof text-secondary"
                                                                                style={{
                                                                                    fontSize: '24px'
                                                                                }}
                                                                            ></i>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-md-4">
                                                                <div className="card bg-secondary border">
                                                                    <div className="card-body pb-3 d-flex align-items-center justify-content-between">
                                                                        <div>
                                                                            <p className="text-link text-secondary">Power</p>
                                                                            <h3 className="title"> {module?.watt}W</h3>
                                                                        </div>
                                                                        <div
                                                                            style={{
                                                                                height: '40px',
                                                                                width: '40px',
                                                                                background: '#ffffff'
                                                                            }}
                                                                            className="d-flex align-items-center justify-content-center border-radius"
                                                                        >
                                                                            <i
                                                                                className="ph ph-lightning text-secondary"
                                                                                style={{
                                                                                    fontSize: '24px'
                                                                                }}
                                                                            ></i>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-md-4">
                                                                <div className="card bg-secondary border">
                                                                    <div className="card-body pb-3 d-flex align-items-center justify-content-between">
                                                                        <div>
                                                                            <p className="text-link text-secondary">Abmessung</p>
                                                                            <h3 className="title">{module?.description ? JSON.parse(module?.description).Modulabmessung_width : ''} - {module?.description ? JSON.parse(module?.description).Modulabmessung_height : ''}mm</h3>
                                                                        </div>
                                                                        <div
                                                                            style={{
                                                                                height: '40px',
                                                                                width: '40px',
                                                                                background: '#ffffff'
                                                                            }}
                                                                            className="d-flex align-items-center justify-content-center border-radius"
                                                                        >
                                                                            <i
                                                                                className="ph ph-ruler text-secondary"
                                                                                style={{
                                                                                    fontSize: '24px'
                                                                                }}
                                                                            ></i>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                )) : ''}

                                                {!data?.modules || !data?.modules.length ? (
                                                    <div className="row mb-3">
                                                        <div className="col-md-4">
                                                            <div className="card bg-secondary border">
                                                                <div className="card-body pb-3 d-flex align-items-center justify-content-between">
                                                                    <div>
                                                                        <p className="text-link text-secondary">Model</p>
                                                                        <h3 className="title">--</h3>
                                                                    </div>
                                                                    <div
                                                                        style={{
                                                                            height: '40px',
                                                                            width: '40px',
                                                                            background: '#ffffff'
                                                                        }}
                                                                        className="d-flex align-items-center justify-content-center border-radius"
                                                                    >
                                                                        <i
                                                                            className="ph ph-solar-roof text-secondary"
                                                                            style={{
                                                                                fontSize: '24px'
                                                                            }}
                                                                        ></i>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-md-4">
                                                            <div className="card bg-secondary border">
                                                                <div className="card-body pb-3 d-flex align-items-center justify-content-between">
                                                                    <div>
                                                                        <p className="text-link text-secondary">Power</p>
                                                                        <h3 className="title">--W</h3>
                                                                    </div>
                                                                    <div
                                                                        style={{
                                                                            height: '40px',
                                                                            width: '40px',
                                                                            background: '#ffffff'
                                                                        }}
                                                                        className="d-flex align-items-center justify-content-center border-radius"
                                                                    >
                                                                        <i
                                                                            className="ph ph-lightning text-secondary"
                                                                            style={{
                                                                                fontSize: '24px'
                                                                            }}
                                                                        ></i>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-md-4">
                                                            <div className="card bg-secondary border">
                                                                <div className="card-body pb-3 d-flex align-items-center justify-content-between">
                                                                    <div>
                                                                        <p className="text-link text-secondary">Abmessung</p>
                                                                        <h3 className="title">-- - --mm</h3>
                                                                    </div>
                                                                    <div
                                                                        style={{
                                                                            height: '40px',
                                                                            width: '40px',
                                                                            background: '#ffffff'
                                                                        }}
                                                                        className="d-flex align-items-center justify-content-center border-radius"
                                                                    >
                                                                        <i
                                                                            className="ph ph-ruler text-secondary"
                                                                            style={{
                                                                                fontSize: '24px'
                                                                            }}
                                                                        ></i>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ) : ''}

                                                <div className="row mt-3">
                                                    <div className="col-md-6">
                                                        <TimelineNew
                                                            refresh={refresh2}
                                                            api_url={backend_url ? backend_url + '/api/v1/' : ''}
                                                            height={'482px'}
                                                        />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="card bg-secondary mb-2">
                                                            <div
                                                                className="card-body pb-2 scroll-cs"
                                                                style={{
                                                                    height: '340px'
                                                                }}
                                                            >
                                                                <div className="border-bottom">
                                                                    <h3 className="title text-lg mb-1">Lieferadresse {/*<span style={{ color: 'red', fontSize: '10px' }}>(Kundendaten löschen)</span>*/}</h3>

                                                                    <div className="d-flex align-items-center justify-content-between">
                                                                        <p className="text-link text-secondary">Name</p>
                                                                        <p className="text-link text-primary">{data?.data?.vorname ?? ''}  {data?.data?.name ?? ''}</p>
                                                                    </div>

                                                                    <div className="d-flex align-items-center justify-content-between">
                                                                        <p className="text-link text-secondary">Tel</p>
                                                                        <p className="text-link text-primary">{(data?.data?.telefonnummer ? data?.data?.telefonnummer : '') + (data?.data?.mobilnummer ? (data?.data?.telefonnummer ? ', ' : '') + data?.data?.mobilnummer : '')}</p>
                                                                    </div>

                                                                    <div className="d-flex align-items-center justify-content-between">
                                                                        <p className="text-link text-secondary">E-mail</p>
                                                                        <p className="text-link text-primary">{data?.data?.email}</p>
                                                                    </div>

                                                                    <div className="d-flex align-items-center justify-content-between mb-1">
                                                                        <p className="text-link text-secondary">Address</p>
                                                                        <p className="text-link text-primary">{[data?.data?.street && `${data?.data?.street} ${data?.data?.nr}`, data?.data?.plz, data?.data?.ort].filter(Boolean).join(", ")}</p>
                                                                    </div>
                                                                </div>

                                                                <div className="mt-2">
                                                                    <h3 className="title text-lg mb-1">Rechnung Details</h3>

                                                                    <div className="d-flex align-items-center justify-content-between">
                                                                        <p className="text-link text-secondary">Rechnungsempfänger</p>
                                                                        <p className="text-link text-primary">{data?.data?.vorname} {data?.data?.name}</p>
                                                                    </div>

                                                                    <div className="d-flex align-items-center justify-content-between">
                                                                        <p className="text-link text-secondary">Tel</p>
                                                                        <p className="text-link text-primary">{(data?.data?.telefonnummer ? data?.data?.telefonnummer : '') + (data?.data?.mobilnummer ? (data?.data?.telefonnummer ? ', ' : '') + data?.data?.mobilnummer : '')}</p>
                                                                    </div>

                                                                    <div className="d-flex align-items-center justify-content-between">
                                                                        <p className="text-link text-secondary">E-mail</p>
                                                                        <p className="text-link text-primary">{data?.data?.email}</p>
                                                                    </div>

                                                                    <div className="d-flex align-items-center justify-content-between mb-2">
                                                                        <p className="text-link text-secondary">Address</p>
                                                                        <p className="text-link text-primary">{[data?.data?.street && `${data?.data?.street} ${data?.data?.nr}`, data?.data?.plz, data?.data?.ort].filter(Boolean).join(", ")}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <BackgroundBlogCard
                                                            image={ivancik}
                                                            title={"750 € Prämie sichern"}
                                                            description={
                                                                <>
                                                                    Empfehlen Sie Ihren Kunden {settings?.angebot?.company_name ? settings?.angebot?.company_name : '--'} weiter und erhalten Sie eine Sonderprämie i.h.v 750 EURO
                                                                </>
                                                            }
                                                            action={{
                                                                type: "button",
                                                                className: "btn btn-secondary btn-hover-white d-flex align-items-center justify-content-between m-0",
                                                                variant: 'contained',
                                                                route: "#",
                                                                label: "Erfahre mehr",
                                                                iconClass: 'ph ph-caret-right',
                                                                iconposition: 'right',
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} md={4.7}>
                                        <div className="card" >
                                            <div className="card-header">
                                                <h3 className="title">Auftrag Details</h3>
                                            </div>
                                            <div className="card-body scroll-cs" style={{ height: '217px' }}>
                                                {data?.data?.quations?.id && (
                                                    <div
                                                        className="d-flex align-items-center justify-content-between bg-secondary border-radius mb-2"
                                                        style={{
                                                            padding: '12px'
                                                        }}
                                                    >
                                                        <div className="d-flex align-items-center justify-content-between gap-2">
                                                            <i className="ph ph-file-pdf text-primary" style={{ fontSize: '24px' }}></i>
                                                            <p className="text-link text-primary m-0">Angebot-{data?.data?.quations?.id}</p>
                                                        </div>
                                                        <div className="d-flex align-items-center justify-content-between gap-2">
                                                            <p className="text-link text-secondary m-0">{dateFormat(data?.data?.quations?.created_at, "dd.mm.yyyy")}</p>
                                                            <a
                                                                className="btn btn-secondary btn-sm m-0 d-flex align-items-center justify-content-between gap-2"
                                                                target={"_blank"}
                                                                href={settings?.backend_url + 'quation/' + data?.data?.quations?.id}
                                                            >
                                                                Herunterladen
                                                                <i className="ph ph-download-simple text-brand-primary" style={{ fontSize: '20px' }}></i></a>
                                                        </div>
                                                    </div>
                                                )}

                                                {data?.data?.quations?.order?.id && (
                                                    <div
                                                        className="d-flex align-items-center justify-content-between bg-secondary border-radius mb-2"
                                                        style={{
                                                            padding: '12px'
                                                        }}
                                                    >
                                                        <div className="d-flex align-items-center justify-content-between gap-2">
                                                            <i className="ph ph-file-pdf text-primary" style={{ fontSize: '24px' }}></i>
                                                            <p className="text-link text-primary m-0">Auftrag-{data?.data?.quations?.order?.id}</p>
                                                        </div>
                                                        <div className="d-flex align-items-center justify-content-between gap-2">
                                                            <p className="text-link text-secondary m-0">{dateFormat(data?.data?.quations?.order?.created_at, "dd.mm.yyyy")}</p>
                                                            <a
                                                                className="btn btn-secondary btn-sm m-0 d-flex align-items-center justify-content-between gap-2"
                                                                target={"_blank"}
                                                                href={settings?.backend_url + 'order/' + data?.data?.quations?.order?.id}
                                                            >
                                                                Herunterladen
                                                                <i className="ph ph-download-simple text-brand-primary" style={{ fontSize: '20px' }}></i></a>
                                                        </div>
                                                    </div>
                                                )}

                                                {data?.data?.quations?.order?.invoices && data?.data?.quations?.order?.invoices.map((invoice, key) => (
                                                    <div
                                                        className="d-flex align-items-center justify-content-between bg-secondary border-radius mb-2"
                                                        style={{
                                                            padding: '12px'
                                                        }}
                                                    >
                                                        <div className="d-flex align-items-center justify-content-between gap-2">
                                                            <i className="ph ph-file-pdf text-primary" style={{ fontSize: '24px' }}></i>
                                                            <p className="text-link text-primary m-0">{key + 1}. Rechnung-{invoice.id}</p>
                                                        </div>
                                                        <div className="d-flex align-items-center justify-content-between gap-2">
                                                            <p className="text-link text-secondary m-0">{dateFormat(invoice.created_at, "dd.mm.yyyy")}</p>
                                                            <a
                                                                className="btn btn-secondary btn-sm m-0 d-flex align-items-center justify-content-between gap-2"
                                                                target={"_blank"}
                                                                href={settings?.backend_url + 'invoice/' + invoice.id}
                                                            >
                                                                Herunterladen
                                                                <i className="ph ph-download-simple text-brand-primary" style={{ fontSize: '20px' }}></i></a>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>

                                            <div className="card-header">
                                                <h3 className="title">Ihr Lieferant</h3>
                                            </div>
                                            <div className="card-body pb-5">
                                                <img
                                                    src={settings?.backend_url + 'storage/attachments/' + settings?.angebot?.logo}
                                                    alt=""
                                                    className="my-3"
                                                    style={{
                                                        height: '40px',
                                                        width: 'auto'
                                                    }}
                                                />

                                                <p className="text-primary text-lg mt-3">{settings?.angebot?.company_name ? settings?.angebot?.company_name : '--'}</p>

                                                <div className="d-flex align-items-center justify-content-between">
                                                    <p className="text-link text-secondary">Street</p>
                                                    <p className="text-link text-primary">{settings?.angebot?.address ? settings?.angebot?.address : '--'} {settings?.angebot?.nr ? settings?.angebot?.nr : '--'}</p>
                                                </div>

                                                <div className="d-flex align-items-center justify-content-between">
                                                    <p className="text-link text-secondary">Plz & Ort</p>
                                                    <p className="text-link text-primary">{settings?.angebot?.plz ? settings?.angebot?.plz : '--'} {settings?.angebot?.ort ? settings?.angebot?.ort : '--'}</p>
                                                </div>

                                                <div className="d-flex align-items-center justify-content-between">
                                                    <p className="text-link text-secondary">E-mail</p>
                                                    <p className="text-link text-primary">{settings?.angebot?.email ? settings?.angebot?.email : '--'}</p>
                                                </div>

                                                <div className="d-flex align-items-center justify-content-between">
                                                    <p className="text-link text-secondary">Tel</p>
                                                    <p className="text-link text-primary">{settings?.angebot?.telephone ? settings?.angebot?.telephone : '--'}</p>
                                                </div>
                                            </div>

                                            <div className="card-footer border-top">
                                                <div className="d-flex align-items-center justify-content-between pb-2">
                                                    <p className="text-link text-secondary m-0">Summe</p>
                                                    <p className="text-link text-primary m-0"><NumericFormat value={data?.data?.quations?.gesami_netto ?? 0} displayType="text" thousandSeparator={"."} decimalSeparator="," decimalScale={2} /> € </p>
                                                </div>

                                                {invoices && invoices.map((invoice, key) => (
                                                    <div className="d-flex align-items-center justify-content-between border-top py-2">
                                                        <p className="text-link text-secondary m-0"> {key + 1}.Abschlag {invoice.amountPerchantage}% &nbsp;&nbsp;&nbsp;&nbsp; {invoice.status == 0 ? <span className="badge badge-danger">Zahlung offen</span> : <span className="badge badge-success">Zahlung erhalten</span>}</p>
                                                        <p className="text-link text-primary m-0"><NumericFormat value={invoice.brutto ?? 0} displayType="text" thousandSeparator={"."} decimalSeparator="," decimalScale={2} /> €</p>
                                                    </div>
                                                ))}

                                                <div className="d-flex align-items-center justify-content-between border-top py-2">
                                                    <p className="text-link text-secondary m-0">Gesamtsumme Netto</p>
                                                    <p className="text-link text-primary m-0"><NumericFormat value={data?.data?.quations?.gesami_netto ?? 0} displayType="text" thousandSeparator={"."} decimalSeparator="," decimalScale={2} /> €</p>
                                                </div>

                                                <div className="d-flex align-items-center justify-content-end border-top pt-3">
                                                    <button
                                                        onClick={() => openThePDF(true)}
                                                        className="btn btn-primary m-0"
                                                    >Angebot Unterschreiben</button>
                                                </div>
                                            </div>
                                        </div>
                                    </Grid>
                                </Grid>
                            </>
                        )}
                    </>
                )}



                <div className="row">
                    {renderSuccessSB}
                </div>

                <button className="d-none" id="callcenterRefesh3" onClick={() => { setRefesh3(refesh3 + 1); }}>refesh3</button>
            </div>

            {steps == 1 ? <div className="modal show custommodal" style={{ display: 'block' }} id="" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog  modal-dialog-centered" style={{ maxWidth: '752px', minHeight: '656px' }}>
                    <div className="modal-content" style={{ borderRadius: '30px', minHeight: '656px' }}>
                        <button type="button" id="emailModalOneClose" className="btn-close d-none" data-bs-dismiss="modal" aria-label="Close"></button>
                        <div className="modal-body" style={{ padding: '3.5rem' }}>
                            <SoftTypography variant="h4" color="#313860" fontSize="20px" fontWeight="bold">Herzlich Willkommen auf Solacloud! </SoftTypography>
                            <br />
                            <SoftTypography variant="caption" color="#313860" style={{ lineHeight: '20px', fontSize: '0.875rem', display: 'inline-block' }}>
                                Wir nehmen den Datenschutz sehr ernst und möchten Sie darüber informieren, dass alle personenbezogenen Daten, die im Rahmen dieses Projekts erfasst oder verarbeitet wurden, nach erfolgreichem Abschluss des Projekts umgehend und dauerhaft gelöscht werden. Es ist zu beachten, dass Kunden, die unser CRM-System nutzen, die Daten autonom in ihrem eigenen CRM-System speichern. Für diese Datenspeicherung ist der Kunde eigenverantwortlich.
                            </SoftTypography>
                            <br />
                            <br />
                            <SoftTypography variant="button" style={{ display: 'block', marginBottom: '12px', color: '#0048B1', fontSize: '16px' }} fontWeight="bold">
                                Ich bestätige hiermit, dass die angegebene E-Mail-Adresse mir gehört.
                            </SoftTypography>
                            <SoftTypography color="#313860" variant="caption" style={{ lineHeight: '20px', fontSize: '0.875rem', display: 'inline-block' }}>
                                Bestätigung des Empfängers: Ich bestätige, dass ich der vorgesehene Empfänger dieser Informationen bin und dass der Zugriff auf diese Daten im Einklang mit dem Zweck meiner Beziehung zum Hauptkunden steht.
                            </SoftTypography>
                            <br />
                            <br />
                            <SoftTypography style={{ display: 'block', color: '#0048B1', fontSize: '20px', textAlign: 'center' }} fontWeight="">{confirmInfo?.project?.email}</SoftTypography>
                            <br />
                            <SoftTypography color="#313860" variant="caption" style={{ lineHeight: '20px', fontSize: '0.875rem', display: 'inline-block' }}>
                                Für Anfragen und Anregungen können sie uns jederzeit und folgenden Kontaktdaten erreichen: support@solacloud.de
                            </SoftTypography>

                            <div className="row mt-5">
                                <div className="col-sm-12" style={{ textAlign: 'center' }}>
                                    {showButton ? settings?.data?.order_overview_verification == 0 ? sending ? <button className="btn btn-primary" style={{ backgroundColor: '#ddd', borderColor: '#ddd', marginBottom: '0px', color: '#000', fontSize: '0.875rem', }}>Verifizieren und Fortfahren...</button> : <button onClick={() => accept()} className="btn btn-primary" style={{ backgroundColor: '#CB0C9F', borderColor: '#CB0C9F', marginBottom: '0px', fontSize: '0.875rem' }}>Akzeptieren und fortfahren</button> : <button onClick={() => sendVerificationCode()} className="btn btn-primary" style={{ backgroundColor: '#CB0C9F', borderColor: '#CB0C9F', marginBottom: '0px', fontSize: '0.875rem' }}>Verifizieren und Fortfahren</button> : ''}
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div> : steps == 2 ? <div className="modal show custommodal" style={{ display: 'block' }} id="" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog  modal-dialog-centered" style={{ maxWidth: '752px' }}>
                    <div className="modal-content" style={{ borderRadius: '30px', minHeight: '656px' }}>
                        <button type="button" id="emailModalTwoClose" className="btn-close d-none" data-bs-dismiss="modal" aria-label="Close"></button>
                        <div className="modal-body" style={{ padding: '3.5rem' }}>
                            <SoftTypography variant="h4" color="#313860" fontSize="20px" fontWeight="bold">Herzlich Willkommen auf Solacloud! </SoftTypography>
                            <br />
                            <SoftTypography variant="caption" color="#313860" style={{ lineHeight: '20px', fontSize: '0.875rem', display: 'inline-block' }}>
                                Ihre Sicherheit liegt uns am Herzen. Um sicherzustellen, dass nur du Zugriff auf dein Konto und deine Daten hast, haben wir die 2-Schritt-Verifikation eingeführt. Bei diesem Prozess senden wir dir einen eindeutigen Code an deine registrierte E-Mail-Adresse. Durch die Eingabe dieses Codes bestätigst du, dass du tatsächlich der rechtmäßige Inhaber des Kontos und der E-Mail-Adresse bist. Dieser zusätzliche Schritt hilft uns, dein Konto vor unberechtigtem Zugriff zu schützen.
                            </SoftTypography>
                            <br />
                            <br />
                            {showNotification ? <SoftAlert color="info" style={{ textAlign: 'center' }} textAlign="center"> <Icon fontSize="small">info</Icon>&nbsp; {resend ? t('Code wurde erneut an Ihre E-Mail versendet.') : t('Bitte prüfen Sie Ihren E-Mail Postfach')}  </SoftAlert> : ''}
                            <SoftTypography color="#313860" variant="h2" style={{ display: 'block', textAlign: 'center' }} fontWeight="bold">
                                2-Step Verifikation
                            </SoftTypography>
                            <br />
                            <form className={`needs-validation ${Object.keys(errors).length ? "was-validated" : ""}`} onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off" id="editdst">
                                <div style={{ textAlign: 'center' }}>
                                    <OtpInput containerStyle={{ display: 'inline-block', textAlign: 'center' }} inputStyle={{ textAlign: 'center', fontSize: '24px', marginRight: '10px', width: '55px', height: '40px', border: '1px solid #ddd', borderRadius: '8px' }} skipDefaultStyles={true} value={otp} onChange={setOtp} numInputs={4} renderSeparator={<span> </span>} renderInput={(props, index) => <input {...register("code" + index)}  {...props} />} />
                                </div>
                                {invalidCode ? <span style={{ display: 'inline-block', width: '100%', padding: '8px 0px' }} className="text-danger text-center">{t('Ihr Code ist ungültig!')}</span> : ''}
                                <br />
                                <div className="row">
                                    <div className="col-sm-12" style={{ textAlign: 'center' }}>
                                        <button type="submit" className="btn btn-primary" style={{ backgroundColor: '#CB0C9F', borderColor: '#CB0C9F', marginBottom: '0px', width: '300px', fontSize: '0.875rem', }}>Bestätigen</button>
                                    </div>
                                </div>
                            </form>
                            <br />
                            <SoftTypography color="secondary" style={{ display: 'block', fontSize: '16px', textAlign: 'center' }} fontWeight="">{t('Keine E-Mail erhalten?')}<span style={{ color: '#252F40', cursor: 'pointer' }} onClick={() => resendNewCode()}> {t('Code erneut senden.')}</span> </SoftTypography>

                        </div>

                    </div>
                </div>
            </div> : steps == 3 ? '' : ''}




            <button
                type="button"
                id="successSignModalBtn"
                className="btn bg-gradient-primary d-none"
                data-bs-toggle="modal"
                data-bs-target="#successSignModal"
            >{t('Success Sign Modal')}</button>
            <div className="modal fade" id="successSignModal" tabindex="-1" role="dialog" aria-labelledby="companyProfileChooseLabel" aria-hidden="true">
                <div className="modal-dialog  modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h3 className="title m-0">{t('Message')}</h3>
                            <i id="successSignModalClose" className="ph ph-x cursor-pointer" data-bs-dismiss="modal" aria-label="Close"></i>
                        </div>
                        <div className="modal-body text-center">
                            <i className="ph ph-seal-check text-success" style={{ fontSize: '40px' }}></i>
                            <h3 className="text-xl font-weight-600 text-primary m-0">Herzlichen Glückwunsch!</h3>

                            <p className="text-secondary text-lg font-weight-400">Fantastisch! Ihre Unterschrift markiert den Beginn einer Zukunft mit sauberer Energie. Herzlichen Glückwunsch zur Wahl einer umweltfreundlichen Lösung für Ihr Zuhause.</p>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                onClick={() => setOpenPDF(false)}
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                className="btn btn-primary m-0">Zu Ihrer Übersicht</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default OrdersOverviewEmailConfirm;
