import { React, useState, useEffect } from "react";
import { NavLink, useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import callFetch from "../../../helpers/callFetch";


function NewAufgabe() {
    const { t } = useTranslation();

    function doSignout(e) {
        e.preventDefault();
        callFetch("signout", "POST", [], null).then((res) => {
            Cookies.remove("user");
            Cookies.remove("token");
            var newTabClose = document.getElementById("newTabClose");
            newTabClose.click();
        });
    }

    return (
        <>
            <button
                type="button"
                id="eventEditModalOpen"
                className="btn bg-gradient-primary d-none"
                data-bs-toggle="modal"
                data-bs-target="#eventEditModal"
            >
                View Calander Click Event Data
            </button>

            <div
                className="modal fade"
                id="newTabModal"
                tabindex="-1"
                role="dialog"
                aria-labelledby="newTabLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered modal-md" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="newTabLabel">
                                {t("Neue Aufgabe")}
                            </h5>
                            <button
                                type="button"
                                id="newTabClose"
                                className="btn-close text-dark"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            >
                            </button>
                        </div>
                        <div className="modal-body apps-icon-link">
                            <div className="grid grid-cols-3 gap-3">
                                {Cookies.get("permissions").indexOf("EC") !==
                                    -1 ? (
                                    <NavLink
                                        to="/employees/create"
                                        data-bs-dismiss="modal"
                                        className="bg-secondary p-3 text-center border-radius hover-brand-light text-hover-brand"
                                    >
                                        <i className="ph ph-users-three font-size-32 text-secondary"></i>
                                        <p className="m-0 text-lg text-primary">{t("Add Employee")}</p>
                                    </NavLink>
                                ) : (
                                    <></>
                                )}

                                <NavLink
                                    to="/chat/send-message"
                                    data-bs-dismiss="modal"
                                    className="bg-secondary p-3 text-center border-radius hover-brand-light text-hover-brand"
                                    >
                                        <i className="ph ph-clipboard font-size-32 text-secondary"></i>
                                        <p className="m-0 text-lg text-primary">{t("New Message")}</p>
                                </NavLink>

                                {Cookies.get("permissions").indexOf("PrC") !== -1 ? (
                                    <NavLink
                                        to="/projects-create"
                                        data-bs-dismiss="modal"
                                        className="bg-secondary p-3 text-center border-radius hover-brand-light text-hover-brand"
                                        >
                                            <i className="ph ph-briefcase font-size-32 text-secondary"></i>
                                            <p className="m-0 text-lg text-primary">{t("New Project")}</p>
                                    </NavLink>
                                ) : (
                                    <></>
                                )}

                                <NavLink
                                    to="/roles"
                                    data-bs-dismiss="modal"
                                    className="bg-secondary p-3 text-center border-radius hover-brand-light text-hover-brand"
                                    >
                                        <i className="ph ph-gear-six font-size-32 text-secondary"></i>
                                        <p className="m-0 text-lg text-primary">{t("Settings")}</p>
                                </NavLink>

                                {Cookies.get("permissions").indexOf("PC") !== -1 ? (
                                    <NavLink
                                        to="/products"
                                        data-bs-dismiss="modal"
                                        className="bg-secondary p-3 text-center border-radius hover-brand-light text-hover-brand"
                                    >
                                        <i className="ph ph-archive font-size-32 text-secondary"></i>
                                        <p className="m-0 text-lg text-primary">{t("New Product")}</p>
                                    </NavLink>
                                ) : (
                                    <></>
                                )}

                                {Cookies.get("permissions").indexOf("IC") !== -1 ? (
                                    <NavLink
                                        to="/invoices/create"
                                        data-bs-dismiss="modal"
                                        className="bg-secondary p-3 text-center border-radius hover-brand-light text-hover-brand"
                                    >
                                        <i className="ph ph-currency-circle-dollar font-size-32 text-secondary"></i>
                                        <p className="m-0 text-lg text-primary">{t("Create Invoice")}</p>
                                    </NavLink>
                                ) : (
                                    <></>
                                )}

                                {/*
                                {Cookies.get("permissions").indexOf("QC") !==
                                    -1 ? (
                                    <NavLink
                                        to="/quations/create"
                                        data-bs-dismiss="modal"
                                        className="bg-secondary p-3 text-center border-radius hover-brand-light"
                                    >
                                        <i className="ph ph-users-three font-size-32 text-secondary"></i>
                                        <p className="m-0 text-lg text-primary">{t("Neues Angebot")}</p>
                                    </NavLink>
                                ) : (
                                    <></>
                                )}


                                {Cookies.get("permissions").indexOf("OC") !== -1 ? (
                                    <NavLink
                                        to="/orders"
                                        data-bs-dismiss="modal"
                                        className="bg-secondary p-3 text-center border-radius hover-brand-light"
                                    >
                                        <i className="ph ph-users-three font-size-32 text-secondary"></i>
                                        <p className="m-0 text-lg text-primary">{t("Create Order")}</p>
                                    </NavLink>
                                ) : (
                                    <></>
                                )}

                                <NavLink
                                    to="#0"
                                    onClick={(e) => doSignout(e)}
                                    data-bs-dismiss="modal"
                                    className="bg-secondary p-3 text-center border-radius hover-brand-light"
                                    >
                                        <i className="ph ph-users-three font-size-32 text-secondary"></i>
                                        <p className="m-0 text-lg text-primary">Logout</p>
                                </NavLink>
                                */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default NewAufgabe;
