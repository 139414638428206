import React from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import WireHouseIndexTable from "./WireHouseIndexTable";

const WireHouseIndex = () => {
    const { t } = useTranslation();

    return (
        <>
            
            <div className="row">
                <div className="col-12">
                    <div className="card mb-4">
                        <div className="card-header pb-0"> 
                            <div className="d-sm-flex justify-content-between">
                                <p className="table-title-cs">{t('Unsere Lager')}</p>
                                <div className="d-flex">
                                    {JSON.parse(Cookies.get('permissions')).indexOf("MC") !== -1 ? (
                                        <NavLink to="/ware-houses/create" className="btn btn-icon btn-primary d-flex align-items-center">
                                           <i className="ph ph-plus font-size-20 me-2"></i> {t('Lager anlegen')}
                                        </NavLink>
                                    ) : <></>}
                                </div>
                            </div>
                        </div>
                        <div className="card-body pb-2">
                            <WireHouseIndexTable/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default WireHouseIndex