import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import callFetch from "../../../helpers/callFetch";
import { useTranslation } from 'react-i18next';
import deleteAlert from "../../../helpers/deleteAlert";
import SmtpEdit from "pages/email/smtp/SmtpEdit";
import SmtpCreate from "pages/email/smtp/SmtpCreate";
import DataTable from 'react-data-table-component';
import Cookies from 'js-cookie';
import SoftBadge from 'components/SoftBadge';

function SmtpIndex() {
    const { t } = useTranslation();
    const [resData, setResData] = useState(null);
    const [reIndex, setReIndex] = useState(0);
    const [id, setID] = useState(1);

    useEffect(() => {
        callFetch("smtp-configurations", "GET", [], null).then((res) => {
            if (!res?.message) return;
            setResData(res);
        });
    }, [reIndex]);

    return (
        <>
            <div className="d-flex align-items-center justify-content-between gap-2 mb-3">
                <h6 className="title">{t('All SMTP')}</h6>

                <button type="button" class="btn btn-primary m-0" data-bs-toggle="modal" data-bs-target="#exampleModal1">
                    {t('Add new SMTP')}
                </button>
            </div>

            <DataTable
                columns={[
                    {
                        name: t('ID'),
                        selector: smtp => <>{smtp?.id}</>,
                        sortable: false,
                        width: '48px'
                    },
                    {
                        name: t('Name'),
                        selector: smtp => <>{smtp?.sender_name}</>,
                        sortable: false,
                        width: '108px'
                    },
                    {
                        name: t('Email'),
                        selector: smtp => <>{smtp?.sender_email}</>,
                        sortable: false,
                        width: '108px'
                    },
                    {
                        name: t('Host'),
                        selector: smtp => <>{smtp?.smtp_host}</>,
                        sortable: false,
                        width: '108px'
                    },
                    {
                        name: t('Port'),
                        selector: smtp => <>{smtp?.smtp_port}</>,
                        sortable: false,
                        width: '80px'
                    },
                    {
                        name: t('User'),
                        selector: smtp => <>{smtp?.smtp_user}</>,
                        sortable: false,
                        width: '108px'
                    },
                    {
                        name: t('Password'),
                        selector: smtp => <>{smtp?.smtp_password}</>,
                        sortable: false,
                        width: '108px'
                    },
                    {
                        name: t('Status'),
                        selector: smtp => <>
                            <SoftBadge
                                variant="contained"
                                color={smtp.status === '1' ? 'success' : "error"}
                                badgeContent={smtp.status === '1' ? t('Active') : t('Inactive')}
                                container
                            />
                        </>,
                        sortable: false,
                        width: '80px'
                    },
                    {
                        name: t(''),
                        cell: smtp => <>
                            <div className="text-center dropstart">
                                <a href="/" className="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                    <i className="fa fa-ellipsis-v text-xs"></i>
                                </a>
                                <ul className="dropdown-menu">
                                    <li>
                                        <a className="dropdown-item" href="javascript::void(0)" onClick={() => setID(smtp.id)} data-bs-toggle="modal" data-bs-target="#exampleModalEdit">Edit</a>
                                    </li>
                                    <li><a className="dropdown-item text-danger" href="#0" onClick={(e) => deleteAlert(e, 'smtp-configurations', smtp.id, t).then(res => setReIndex(reIndex + 1))}>{t('Delete')}</a></li>
                                </ul>
                            </div>
                        </>,
                        sortable: true,
                        width: '44px'
                    },
                ]}
                data={resData?.data || []}
                noDataComponent={
                    <div className="d-flex align-items-center justify-content-center py-3" style={{ height: "100%" }}>
                        <div className="text-center">
                            <i className='ph ph-notepad data-table-no-record-icon'></i>
                            <p className="text-secondary text-md m-0">{t('There are no records to display')}</p>
                        </div>
                    </div>
                }
                className='data-table mb-3'
                highlightOnHover
                paginationComponentOptions={{ noRowsPerPage: true }}
            />


            <SmtpCreate setReIndex={setReIndex} />
            <SmtpEdit id={id} setReIndex={setReIndex} />
        </>
    );
}

export default SmtpIndex;
