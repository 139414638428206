import { useEffect, useState, useRef } from "react";
import Cookies from "js-cookie";
import Timeline from "../create/Timeline";
import AcDcMontageList from "./default/AcDcMontageList";
import AcDcMontage from "../create/OrderProcessing";
import { NavLink, useParams } from "react-router-dom";
function Index(props) {
  const [refresh2, setRefresh2] = useState(0);
  const [view, setView] = useState(0);
  let params = useParams();
  useEffect(() => {}, []);
  const openDcRef = useRef(null); // Reference to the button

  // Function to handle button click programmatically
  const handleOpenDc = () => {
    if (openDcRef.current) {
      openDcRef.current.click();
    }
  };
  return (
    <>
    {view == 0 ? <>
      <div className="row">
        <button
          className="d-none"
          id="opendc"
          ref={openDcRef}
          onClick={() => setView(1)}
        ></button>
        
            <div className="col-sm-12 col-md-12 col-lg-8 col-xl-8 col-xxl-9">
          <div className="card">
            <div className="card-body pb-3">
              <p className="font-size-20 font-weight-600">AC & DC Montage</p>
              <AcDcMontageList openDc={handleOpenDc}></AcDcMontageList>
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-md-12 col-lg-4 col-xl-4 col-xxl-3 pl-4 project-details-right-side">
          <div className="card">
            <div className="card-body">
              <div class="details">
                <h6 className="text-primary font-size-16 font-weight-500">
                  Projektinformationen
                </h6>

                <div className="mb-2">
                  <p className="font-size-14 text-secondary mb-0">
                    <i className="icon-sola-user-circle-gear"></i>{" "}
                    <span className="position-relative bottom-4 left-4">
                      Project Nr.
                    </span>
                  </p>
                  <div class="profile">
                    <span>{params?.id}</span>
                  </div>
                </div>

                <div className="mb-2">
                  <p className="font-size-14 text-secondary mb-0">
                    <i className="icon-sola-user-circle-gear"></i>{" "}
                    <span className="position-relative bottom-4 left-4">
                      Lead Owner
                    </span>
                  </p>
                  <div class="profile">
                    <img
                      src="/assets/img/placeholder.png"
                      className="border-primary"
                      alt="Profile"
                    />
                    <span>Oliver Kahn</span>
                  </div>
                </div>
              </div>
              <Timeline refresh={refresh2} />
            </div>
          </div>
        </div> 
      </div>
      </> : <>
        <AcDcMontage></AcDcMontage>
      </>}
    </>
  );
}

export default Index;
