/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

function MiniStatisticsCard({ bgColor, title, count, percentage, icon, direction, unit }) {
  return (
    <div className="card box-shadow-none border-primary">
      <div className="card-body pb-3">
        <SoftBox bgColor={bgColor} variant="gradient">
          <SoftBox>
            <Grid container alignItems="center"> 
              {false ? (
                <Grid item>
                  <SoftBox
                    variant="gradient"
                    bgColor={bgColor === "white" ? icon.color : "white"}
                    color={bgColor === "white" ? "white" : "dark"}
                    width="3rem"
                    height="3rem"
                    borderRadius="md"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    shadow="md"
                  >
                    <img src={icon.component} style={{height: '1.5rem', width: '1.8rem'}}/>
                  </SoftBox>
                </Grid>
              ) : null}
              <Grid item xs={8}>
                <SoftBox ml={false ? 2 : 0} lineHeight={1}>
                  <SoftTypography
                    variant="button" 
                    opacity={bgColor === "white" ? 1 : 0.7}
                    textTransform="capitalize"
                    fontWeight="400"
                    className={'ministatistics-title-eec'}
                    fontSize="0.875rem"
                    style={{color: '#A0AEC0'}}
                  >
                    {title.text}
                  </SoftTypography>
                  <SoftTypography
                    variant="h5"
                    fontWeight="600" 
                    style={{fontSize: '1.5rem', color: '#2D3748'}}
                  >
                    {count}{" "}
                    {/* <SoftTypography
                      variant={percentage.variant}
                      style={{color: '#48BB78'}}
                      fontSize="0.875rem"
                      fontWeight="bold"
                      className={'ministatistics-unit'}
                    >
                      {unit}
                    </SoftTypography>  */}
                  </SoftTypography>
                </SoftBox>
              </Grid> 
              {true ? (
                <Grid item xs={4} className="d-flex justify-content-end">
                   <div className="mini-statistics-icon">
                      {icon.component}
                   </div>
                </Grid>
              ) : null}
            </Grid>
          </SoftBox>
        </SoftBox>
      </div>
    </div>
  );
}

// Setting default values for the props of MiniStatisticsCard
MiniStatisticsCard.defaultProps = {
  bgColor: "white",
  title: {
    fontWeight: "600",
    text: "",
  },
  percentage: {
    color: "success",
    text: "",
  },
  direction: "right",
};

// Typechecking props for the MiniStatisticsCard
MiniStatisticsCard.propTypes = {
  bgColor: PropTypes.oneOf([
    "white",
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
  ]),
  title: PropTypes.PropTypes.shape({
    fontWeight: PropTypes.oneOf(["light", "regular", "medium", "bold"]),
    text: PropTypes.string,
  }),
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  percentage: PropTypes.shape({
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "white",
    ]),
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  icon: PropTypes.shape({
    color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
    component: PropTypes.node.isRequired,
  }).isRequired,
  direction: PropTypes.oneOf(["right", "left"]),
};

export default MiniStatisticsCard;
