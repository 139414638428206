import { React, useEffect, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { Navigate, useParams, NavLink } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import callFetch from "../../../helpers/callFetch";
import flatpickr from "flatpickr";
import Select from 'react-select';
import SoftSnackbar from "components/SoftSnackbar";
import Timeline from "./Timeline";
import Cookies from 'js-cookie';
import { GoogleMap, useLoadScript, Autocomplete } from '@react-google-maps/api';
import ProjectStatus from '../ProjectStatus';
import Swal from 'sweetalert2';
import dateFormat from "dateformat";
// @mui material components
import SoftDatePicker from "components/SoftDatePicker";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import SoftButton from "components/SoftButton";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftAvatar from "components/SoftAvatar";
import SoftSelect from "components/SoftSelect";
import ProjectPost from "./ProjectPost";
import defaultMapImg from "assets/images/default-map.png";
import FilesForOverView from "./FilesForOverView";
import ReclamationIndexTable from "pages/project/Services/IndexTable";
import IndexTableTickets from "pages/project/Services/IndexTableTickets";
import LeadDetails from "./Components/CustomerData/LeadDetails";
import Activitys from "./Components/Activitys";
import Map from "./Map";
function CreateProject(props) {
    let params = useParams();
    const { t } = useTranslation();
    const [data, setData] = useState([]);
    const [time, setTime] = useState('');
    const [activeTab, setActiveTab] = useState('');
    const [zipcode, setZipcode] = useState([]);
    const [selectedPlz, setSelectedPlz] = useState({ value: '', label: '---' });
    const [saving, setSaving] = useState(false);
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [selecteCustomertId, setSelecteCustomertId] = useState({ id: '' });
    const [selectedEmployee, setSelectedEmployee] = useState({ id: '' });
    const [refresh, setRefresh] = useState(0);
    const [refresh2, setRefresh2] = useState(0);
    const [refesh3, setRefesh3] = useState(0);
    const [projectId, setProjectId] = useState(0);
    const [create, setCreate] = useState(true);
    const [successSB, setSuccessSB] = useState(false);
    const [handleNextStatus, setHandleNextStatus] = useState(false);
    const [autocomplete, setAutocomplete] = useState(null);
    const [readOnly, setReadOnly] = useState(true);
    const [customers, setCustomers] = useState([]);
    const [employee, setEmployee] = useState([]);
    const [invoices, setInvoices] = useState([]);
    const [dc, setDC] = useState([]);
    const [ac, setAc] = useState([]);
    const [posts, setPosts] = useState();
    const [viewMode, setViewMode] = useState('overview');
    const [userLead, setUserLead] = useState([]);
    const [mapDisplay, setMapDisplay] = useState(false);
    const [mapZoom, setMapZoom] = useState(21);
    const [menu, setMenu] = useState(null);
    const [loadMap, setLoadMap] = useState(false);
    const openMenu = (event) => setMenu(event.currentTarget);
    const closeMenu = () => setMenu(null);
    const [mapCenter, setMapCenter] = useState({ lat: 0, lng: 0 });

    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
        libraries: ["places"]
    })

    const renderSuccessSB = (
        <SoftSnackbar
            color="success"
            icon="check"
            title={t('Success')}
            //content={t("Changes saved successfully")}
            dateTime={t("")}
            open={successSB}
            onClose={() => setSuccessSB(false)}
            close={() => setSuccessSB(false)}
            bgSuccess
        />
    );
    const {
        register,
        handleSubmit,
        setError,
        setValue,
        getValues,
        clearErrors,
        formState: { errors },
    } = useForm();

    const onLoad = (autocomplete) => {
        //console.log('autocomplete: ', autocomplete);
        setAutocomplete(autocomplete);
    }

    const onPlaceChanged = () => {
        if (autocomplete !== null) {
            //console.log('onPlaceChanged')
            //console.log(autocomplete)

            var place = autocomplete.getPlace();
            //console.log('address_components');
            setValue('plz', '');
            setValue('nr', '');
            setValue('ort', '');
            setValue('street', '');

            if (place?.address_components) {
                for (let [key, value] of Object.entries(place?.address_components)) {
                    if (value?.types) {
                        if (value?.types.indexOf("postal_code") !== -1) {
                            setValue('plz', value?.long_name);
                        } else if (value?.types.indexOf("street_number") !== -1) {
                            setValue('nr', value?.long_name);
                        } else if (value?.types.indexOf("locality") !== -1) {
                            setValue('ort', value?.long_name);
                        } else if (value?.types.indexOf("route") !== -1) {
                            setValue('street', value?.long_name);
                        }
                    }
                }
            }
            setValue('lat', place.geometry.location.lat());
            setValue('lng', place.geometry.location.lng());
        } else {
            console.log('Autocomplete is not loaded yet!')
        }
    }


    const storno = (e) => {
        if (!data?.data?.id) return;
        e.preventDefault();

        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-primary me-2',
                cancelButton: 'btn btn-danger'
            },
            buttonsStyling: false
        });

        return swalWithBootstrapButtons.fire({
            text: t("Are you sure?"),
            // icon: 'warning',
            showCancelButton: true,
            confirmButtonText: t('Yes, do it!'),
            cancelButtonText: t('Cancel')
        }).then((result) => {
            if (result.isConfirmed) {

                var formData = new FormData();
                formData.id = data?.data?.id;
                formData.stornieren = (data?.data?.stornieren == 0 ? 1 : data?.data?.stornieren == 1 ? 2 : 0);

                callFetch("project/stornieren", "POST", formData, {}).then((res) => {
                    // setSaving(false);
                    setRefesh3(refesh3 + 1);
                    // if (!res.ok) return;
                });

            }
        });
    }

    useEffect(() => {
        console.log('set default tab')
        if (JSON.parse(Cookies.get('permissions')).indexOf("notizen") !== -1) {
            setActiveTab('notizen');
        } else if (JSON.parse(Cookies.get('permissions')).indexOf("RecR") !== -1 || JSON.parse(Cookies.get('permissions')).indexOf("TicR") !== -1) {
            setActiveTab('service');
        } else if (JSON.parse(Cookies.get('permissions')).indexOf("docs") !== -1) {
            setActiveTab('documente');
        } else if (JSON.parse(Cookies.get('permissions')).indexOf("syinfo") !== -1) {
            setActiveTab('systeminfo');
        } else if (JSON.parse(Cookies.get('permissions')).indexOf("activity") !== -1) {
            setActiveTab('activity');
        }
    }, [viewMode]);

 


    //plz and ort load
    useEffect(() => {
        if (!data?.data?.id) return;

        setValue('customer_id', data?.data?.customer_id);
    }, [data]);

    useEffect(() => {
        if (!params?.customer) return;

        setValue('date', dateFormat(new Date(), "yyyy-mm-dd"));
        verkauferFeatch();

        for (let [key, e] of Object.entries(customers)) {
            if (e.id == params?.customer) {
                setValue('customer_id', e.id);
                setSelecteCustomertId({
                    id: e?.id,
                    vorname: e?.vorname,
                    name: e?.name,
                    customer_number: e?.customer_number,
                });

                for (let [key, value] of Object.entries(e)) {
                    if (key == 'id') {

                    } else {
                        setValue(key, (value == null || value == 'null' ? "" : value));
                    }
                }

                setValue('full_address', e?.street + (e?.nr && e?.nr != 'null' ? ' ' + e?.nr : ''));
            }
        }

        //setValue('customer_id', data?.data?.customer_id);
    }, [params?.customer, customers]);

    const timeOptions = [
        { value: '08:00', label: '08:00' },
        { value: '08:30', label: '08:30' },
        { value: '09:00', label: '09:00' },
        { value: '09:30', label: '09:30' },
        { value: '10:00', label: '10:00' },
        { value: '10:30', label: '10:30' },
        { value: '11:00', label: '11:00' },
        { value: '11:30', label: '11:30' },
        { value: '12:00', label: '12:00' },
        { value: '12:30', label: '12:30' },
        { value: '13:00', label: '13:00' },
        { value: '13:30', label: '13:30' },
        { value: '14:00', label: '14:00' },
        { value: '14:30', label: '14:30' },
        { value: '15:00', label: '15:00' },
        { value: '15:30', label: '15:30' },
        { value: '16:00', label: '16:00' },
        { value: '16:30', label: '16:30' },
        { value: '17:00', label: '17:00' },
        { value: '17:30', label: '17:30' },
        { value: '18:00', label: '18:00' },
        { value: '18:30', label: '18:30' },
        { value: '19:00', label: '19:00' },
        { value: '19:30', label: '19:30' },
        { value: '20:00', label: '20:00' },
        { value: '20:30', label: '20:30' },
        { value: '21:00', label: '21:00' },
        { value: '21:30', label: '21:30' },
        { value: '22:00', label: '22:00' }
    ];

    //Edit Data load
    useEffect(() => {
        flatpickr(".flat-pickr");

        if (JSON.parse(Cookies.get('permissions')).indexOf("PCCU") !== -1) {
            setReadOnly(false);
        } else {
            setReadOnly(true);
        }


        if (props?.update && props.update == true && params?.id) {
            setCreate(false);

            callFetch("projects/" + params.id + "/edit?viewMode=" + viewMode, "GET", []).then((res) => {
                setData(res);
                setMapCenter({ lat: parseFloat(res?.data?.lat), lng: parseFloat(res?.data?.lng) });
                // console.log(res);
                setUserLead(res.user_lead);
                setEmployee(res.employee);
                setSelectedEmployee({
                    id: res?.employee?.id,
                    user: {
                        name: res?.employee?.name,
                        id: res?.employee?.id
                    }
                });
                setInvoices(res.invoices);
                setDC(res.dc);
                setAc(res.ac);
                // setQuation(res.quation); 
                setValue('project_id', params.id);
                // console.log(res.quation);
                setCustomers(res?.customers);
                for (let [key, value] of Object.entries(res.data)) {
                    if (key == 'date') {
                        setValue(key, (value == null || value == 'null' ? "" : value));
                    } else if (key == 'plz') {
                        setValue(key, (value == null || value == 'null' ? "" : value));
                    } else if (key == 'customer') {
                        setSelecteCustomertId(value)
                    } else {
                        setValue(key, (value == null || value == 'null' ? "" : value));
                    }
                }
                setValue('full_address', res?.data?.street + (res?.data?.nr && res?.data?.nr != 'null' ? ' ' + res?.data?.nr : ''));
            });
        }
    }, [params?.id, refesh3, viewMode]);

    //verkaufer id set
    useEffect(() => {
        if (!data?.data?.verkaufer) return;
        setValue('verkaufer', data?.data?.verkaufer);
    }, [data]);

    //zipcode fetch
    useEffect(() => {
        for (let [zipcode_key, zipcode_value] of Object.entries(zipcode)) {
            if (zipcode_value.label == getValues('plz')) {
                setSelectedPlz(zipcode_value)
            }
        }
    }, [params.id, zipcode, refresh, getValues('plz')]);

    //verkaufer fetch base on date time
    const verkauferFeatch = () => {
        if (params?.id && props?.update && props.update == true) {
            callFetch("projects/" + params?.id + "/edit?date=" + getValues('date') + '&time=' + getValues('time') + '&viewMode=' + viewMode, "GET", []).then((res) => {
                setData({
                    employees: res.employees
                })
            });
        } else {

            callFetch("projects/create?date=" + getValues('date') + '&time=' + getValues('time'), "GET", []).then((res) => {
                setData(res.data);
                setCustomers(res?.data?.customers);
            });
        }
    }

    const onSubmit = (formData) => {
        setSaving(true);
        
        // var loadingElement = document.querySelector('.res-options .btn-primary-save');
        // loadingElement.classList.add("btn-secondary");
        // loadingElement.innerHTML = t('wird geladen...'); 
        callFetch(create == true ? "projects" : "projects/" + params?.id, "POST", formData, setError).then((res) => {
            setRefresh2(refresh2 + 1);

            if (res.project_id) {
                setProjectId(res.project_id);
            }

            setTimeout(() => {
                setSaving(false);

                // loadingElement.classList.remove("btn-secondary");
                // loadingElement.innerHTML = t('Save');


                if (!res.ok) return;

                document.body.scrollTop = 0; // For Safari
                document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
                // document.getElementById("main-content").scrollTop = 0;
                setViewMode('overview');

                setSuccessSB(true);
                setSubmitSuccess(true);
            }, 3000);
        });
    };

    useEffect(() => {
        if (params?.status == 'new') {
            setSuccessSB(true);
        }
    }, [params?.status]);


    const [currentStep, setCurrentStep] = useState(0);

    const steps = ["Step 1", "Step 2", "Step 3"];

    const handleNext = () => { 
        if (currentStep < steps.length) {
        setCurrentStep(currentStep + 1);
        } else {
        alert("Form submitted!");
        }
    };

    const handlePrev = () => {
        if (currentStep > 0) {
        setCurrentStep(currentStep - 1);
        }
    };


    if (loadError) return "Error loading maps"
    if (!isLoaded) return;// "Loading Maps"

    return submitSuccess && create ? <Navigate to={'/projects/' + projectId} /> :
        <>
            {params?.id > 0 ? '' :
                <>
                    <form className={`needs-validation cp-form ${Object.keys(errors).length ? "was-validated" : ""}`}   onSubmit={handleSubmit((formData) => {
                                // const selectedDate = getValues('date'); // Get the current value of the date field
                                // const timee = getValues('time'); // Get the current value of the date field
                                // var err = false;
                                // if (!selectedDate) {
                                //     setError('date', { type: 'manual', message: 'Date is required' });
                                //     err = true;
                                // }
                                
                                // if (!timee) {
                                //     setError('time', { type: 'manual', message: 'Time is required' });
                                //     err = true;
                                // }

                                // if (selectedEmployee.id === "" || selectedEmployee.id === null || selectedEmployee.id === undefined) {
                                //     setHandleNextStatus(true);
                                //     err = true;
                                // } 


                                // if(err){
                                //     return false;
                                // }

                                const selectedDate = getValues('date'); // Get the current value of the date field
                                const timee = getValues('time'); // Get the current value of the time field
                                const verkaufer = getValues('verkaufer'); // Get the current value of the time field 

                                console.log("Selected Date:", selectedDate);
                                console.log("Selected Time:", timee);
                                console.log("Selected Employee ID:", verkaufer);

                                let err = false;

                                // Check if date is missing
                                if (!selectedDate) {
                                    setError('date', { type: 'manual', message: 'Date is required' });
                                    err = true;
                                } else {
                                    clearErrors('date'); // Clear error if field is valid
                                }

                                // Check if time is missing
                                if (!timee) {
                                    setError('time', { type: 'manual', message: 'Time is required' });
                                    err = true;
                                } else {
                                    clearErrors('time'); // Clear error if field is valid
                                }
                                
                                if (!verkaufer) {
                                    setError('verkaufer', { type: 'manual', message: 'Time is required' });
                                    err = true;
                                } else {
                                    clearErrors('verkaufer'); // Clear error if field is valid
                                } 

                                // Stop further execution if there's an error
                                if (err) {
                                    console.log("Validation failed. Errors exist.");
                                    return false;
                                }

                                console.log("Validation passed. Proceeding to next step.");
 

                                if (currentStep < 2) {
                                    handleNext(); // Proceed to the next step
                                } else {
                                    onSubmit(formData); // Final form submission
                                }
                        })} noValidate autoComplete="off">
                        <div className="row g-3">

                            <div className="container mt-3 plr-7rem">
                                <div className="step-progress">
                                    <div className="step-wrapper">
                                        <div
                                            className={`step ${currentStep >= 0 ? "completed" : ""}`}
                                            id="step-1"
                                        >
                                            {currentStep > 0 ? <i className="fa fa-check"></i> : "1"}
                                        </div>
                                        <div className={`step-line ${currentStep > 0 ? "completed" : ""}`} id="line-1"><span className={`${currentStep == 0 ? "" : "d-none-xs-cp-progress"}`}>Termin</span></div>
                                        </div>
                                        <div className="step-wrapper">
                                            <div
                                                className={`step ${currentStep >= 1 ? "completed" : ""}`}
                                                id="step-2"
                                            >
                                                {currentStep > 1 ? <i className="fa fa-check"></i> : "2"}
                                            </div>
                                            <div className={`step-line ${currentStep > 1 ? "completed" : ""}`} id="line-2"><span  className={`${currentStep == 1 ? "" : "d-none-xs-cp-progress"}`}>Kundendaten</span></div>
                                        </div>
                                        <div className="step-wrapper" style={{flexGrow: '0'}}>
                                        <div
                                            className={`step ${currentStep >= 2 ? "completed" : ""}`}
                                            id="step-3"
                                        >
                                            {currentStep > 2 ? <i className="fa fa-check"></i> : "3"}
                                        </div>
                                        <div><span  className={`${currentStep == 2 ? "" : "d-none-xs-cp-progress"}`}>Projektdetails</span></div>
                                    </div>
                                </div>

                                <div className="step-card">
                                    {currentStep === 0 && (
                                    <div className="step-content">
                                        {/* <h3>Step 1</h3> */}
                                        <div className="col-sm-12 col-xl-12">
                                            <div className="card">
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-12 col-md-3 col-xl-3">
                                                            <div className="form-group solacloud-date">
                                                                <label htmlFor="example-date-input" className="form-control-label" style={{ color: errors.id ? "red" : "black" }}>{t('Date')}</label>
                                                                <SoftDatePicker
                                                                    {...register("date", {
                                                                        required: true,
                                                                        disabled: readOnly, 
                                                                    })}
                                                                    className={`form-control ${errors.date ? "is-invalid" : ""}`}  
                                                                    fontSize="20px"
                                                                    value={getValues('date') ? new Date(getValues('date')) : ""}
                                                                    onChange={(date) => {
                                                                        const selectedDate = date[0]
                                                                            ? date[0].toLocaleDateString('en-CA') // 'en-CA' gives 'YYYY-MM-DD'
                                                                            : "";
                                                                            setValue('date', selectedDate)

                                                                            verkauferFeatch();
                                                                        }} 
                                                                    required
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className="col-12 col-md-3 col-xl-3">
                                                            <div className="form-group">
                                                                <label>
                                                                    {t('Time')} *
                                                                </label>
                                                                <br />
                                                                <SoftSelect 
                                                                    {...register("time", {
                                                                        required: true,
                                                                        disabled: readOnly, 
                                                                    })}
                                                                    value={timeOptions.find(option => option.value == time) || null} 
                                                                    onChange={(e) => {
                                                                        setTime(e?.value);
                                                                        setValue('time', e?.value); 
                                                                        verkauferFeatch();
                                                                    }}
                                                                    options={timeOptions}
                                                                    placeholder="--"
                                                                    menuPortalTarget={document.body}
                                                                    menuPlacement="auto"
                                                                    error={errors.time ? true : false}
                                                                />
                                                                {/* <select
                                                                    className="form-control pl"
                                                                    {...register("time", {
                                                                        required: true,
                                                                        disabled: readOnly,
                                                                        onChange: (e) => {
                                                                            verkauferFeatch();
                                                                        }
                                                                    })}
                                                                    required
                                                                >
                                                                    <option value="">--</option>
                                                                    <option value="08:00">08:00</option>
                                                                    <option value="08:30">08:30</option>
                                                                    <option value="09:00">09:00</option>
                                                                    <option value="09:30">09:30</option>
                                                                    <option value="10:00">10:00</option>
                                                                    <option value="10:30">10:30</option>
                                                                    <option value="11:00">11:00</option>
                                                                    <option value="11:30">11:30</option>
                                                                    <option value="12:00">12:00</option>
                                                                    <option value="12:30">12:30</option>
                                                                    <option value="13:00">13:00</option>
                                                                    <option value="13:30">13:30</option>
                                                                    <option value="14:00">14:00</option>
                                                                    <option value="14:30">14:30</option>
                                                                    <option value="15:00">15:00</option>
                                                                    <option value="15:30">15:30</option>
                                                                    <option value="16:00">16:00</option>
                                                                    <option value="16:30">16:30</option>
                                                                    <option value="17:00">17:00</option>
                                                                    <option value="17:30">17:30</option>
                                                                    <option value="18:00">18:00</option>
                                                                    <option value="18:30">18:30</option>
                                                                    <option value="19:00">19:00</option>
                                                                    <option value="19:30">19:30</option>
                                                                    <option value="20:00">20:00</option>
                                                                    <option value="20:30">20:30</option>
                                                                    <option value="21:00">21:00</option>
                                                                    <option value="21:30">21:30</option>
                                                                    <option value="22:00">22:00</option>
                                                                </select> */}
                                                                {/* <div className="invalid-feedback">{errors.quotation_id && errors.quotation_id.message}</div> */}
                                                            </div>
                                                        </div>

                                                        <div className="col-12 col-md-6 col-xl-6">
                                                            <div className="form-group">
                                                                <label>
                                                                    {t('Verkaufer')} *
                                                                </label>
                                                                <br />
                                                                <SoftSelect 
                                                                    {...register("verkaufer", {
                                                                        required: true,
                                                                        disabled: readOnly, 
                                                                    })}
                                                                    placeholder={t('Select')}
                                                                    options={data?.employees ? data?.employees : []}
                                                                    getOptionLabel={(option) => ((option?.user?.name ? option?.user?.name : ''))}
                                                                    getOptionValue={(option) => option?.user?.id}
                                                                    value={selectedEmployee}
                                                                    onChange={(e) => {
                                                                        setValue('verkaufer', e.user.id);
                                                                        setSelectedEmployee({
                                                                            id: e?.user.id,
                                                                            user: {
                                                                                name: e?.user.name,
                                                                            },
                                                                        });
                                                                        clearErrors();
                                                                    }}
 
                                                                    required
                                                                    error={errors.verkaufer ? true : false}
                                                                />
                                                                <div className="invalid-feedback d-block">{errors.verkaufer ? t(errors.verkaufer.message) : ''}</div>
                                                            </div>
                                                        </div>

                                                        <div className="col-12">
                                                            <div className="form-group">
                                                                <label>
                                                                    {t('Beschreibung')}
                                                                </label>
                                                                <textarea
                                                                    className="form-control"
                                                                    rows="15"
                                                                    placeholder={t('eg. 1234 My street, City')}
                                                                    {...register("description", {
                                                                        disabled: readOnly,
                                                                    })}></textarea>
                                                                <div className="invalid-feedback">{errors.address && errors.address.message}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    )}

                                    {currentStep === 1 && (
                                    <div className="step-content">
                                          <div className="col-sm-12 col-xl-12">
                                            <div className="card">
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label>
                                                                    {t('Kunde')}
                                                                </label>
                                                                <br />

                                                                <SoftSelect
                                                                    placeholder={t('Select')}
                                                                    options={customers ? customers : {}}
                                                                    getOptionLabel={(option) => ((option.customer_number ? option.customer_number : '') + (option.vorname ? ' - ' + option.vorname : '') + ' ' + (option?.name ? option?.name : ''))}
                                                                    getOptionValue={(option) => option.id}
                                                                    value={selecteCustomertId}

                                                                    onChange={(e) => {
                                                                        setValue('customer_id', e.id);
                                                                        setSelecteCustomertId({
                                                                            id: e?.id,
                                                                            vorname: e?.vorname,
                                                                            name: e?.name,
                                                                            customer_number: e?.customer_number,
                                                                        });

                                                                        for (let [key, value] of Object.entries(e)) {
                                                                            if (key == 'id') {

                                                                            } else {
                                                                                console.log(key + ': ' + value)
                                                                                setValue(key, (value == null || value == 'null' ? "" : value));
                                                                            }
                                                                        }

                                                                        setValue('full_address', e?.street + (e?.nr && e?.nr != 'null' ? ' ' + e?.nr : ''));
                                                                    }}
                                                                    required
                                                                    styles={{
                                                                        fontSize: '0.875rem',
                                                                        control: (baseStyles, state) => ({
                                                                            ...baseStyles,
                                                                            fontSize: '0.875rem',
                                                                            borderColor: '#E7EBF3',
                                                                            height: '40px !important',
                                                                            minHeight: '40px !important',
                                                                            lineHeight: '24px',
                                                                            borderRadius: '5px'
                                                                        }),
                                                                    }}
                                                                />
                                                                <div className="invalid-feedback">{errors.customer_id && errors.customer_id.message}</div>
                                                            </div>
                                                        </div> 
                                                        <div className="col-md-6">
                                                            <div className="form-group solacloud-select icon-caret-down icon-right">
                                                                <label>{t('Anrede')} *</label>
                                                                <select
                                                                    className="form-control"
                                                                    {...register("anrede", {
                                                                        required: true,
                                                                        disabled: readOnly,
                                                                    })}
                                                                    required>
                                                                    <option value="">--</option>
                                                                    <option value="Herr">{t('Herr')}</option>
                                                                    <option value="Frau">{t('Frau')}</option>
                                                                </select>
                                                                <div className="invalid-feedback">{errors.anrede && errors.anrede.message}</div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label>{t('Vorname')} *</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder={t('')}
                                                                    {...register("vorname", {
                                                                        required: true,
                                                                        disabled: readOnly,
                                                                    })}
                                                                    required
                                                                />
                                                                <div className="invalid-feedback">{errors.vorname && errors.vorname.message}</div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label>{t('Name')} *</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder={t('')}
                                                                    {...register("name", {
                                                                        required: true,
                                                                        disabled: readOnly,
                                                                    })}
                                                                    required
                                                                />
                                                                <div className="invalid-feedback">{errors.name && errors.name.message}</div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <Autocomplete
                                                                options={{
                                                                    componentRestrictions: { country: "de" },
                                                                    //fields: ["address_components", "geometry", "icon", "name"],
                                                                    //types: ["establishment"]
                                                                }}
                                                                onLoad={onLoad}
                                                                onPlaceChanged={onPlaceChanged}
                                                            >
                                                                <div className="form-group">
                                                                    <label>{t('Straße')} *</label>
                                                                    <input
                                                                        type="text"
                                                                        placeholder={t('')}
                                                                        className="form-control"
                                                                        //defaultValue={getValues('street') ? getValues('street') + ' ' + getValues('nr') : ''}
                                                                        {...register("full_address", {
                                                                            required: false,
                                                                            disabled: readOnly,
                                                                        })}
                                                                        required

                                                                    />
                                                                    <div className="invalid-feedback d-block">{errors.street ? errors.street.message : (errors.nr ? t('Please add a street nr.') : '')}</div>
                                                                </div>
                                                            </Autocomplete>
                                                            <div className="form-group d-none">
                                                                <label>{t('Straße')} *</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder={t('')}
                                                                    {...register("street", {
                                                                        required: true,
                                                                        disabled: readOnly,
                                                                    })}
                                                                    required
                                                                />
                                                                <div className="invalid-feedback">{errors.street && errors.street.message}</div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label>{t('Nr')} *</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder={t('')}
                                                                    {...register("nr", {
                                                                        required: false,
                                                                        disabled: readOnly,
                                                                    })}
                                                                    readOnly
                                                                />
                                                                <div className="invalid-feedback">{errors.nr && errors.nr.message}</div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label>{t('PLZ')} *</label>

                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder={t('')}
                                                                    {...register("plz", {
                                                                        required: false,
                                                                        disabled: readOnly,
                                                                    })}
                                                                    readOnly
                                                                />
                                                                <div className="invalid-feedback">{errors.plz && errors.plz.message}</div>
                                                            </div>
                                                        </div>

                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label>{t('Ort')} *</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder={t('')}
                                                                    {...register("ort", {
                                                                        required: false,
                                                                        disabled: readOnly,
                                                                    })}
                                                                    readOnly
                                                                />
                                                                <div className="invalid-feedback">{errors.ort && errors.ort.message}</div>
                                                            </div>
                                                        </div>


                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label>{t('Firma')}</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder={t('')}
                                                                    {...register("firma", {
                                                                        disabled: readOnly,
                                                                    })} />
                                                                <div className="invalid-feedback">{errors.firma && errors.firma.message}</div>
                                                            </div>
                                                        </div>

                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label>{t('E-mail Adresse')}</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder={t('')}
                                                                    {...register("email", {
                                                                        required: false,
                                                                        disabled: readOnly,
                                                                        onChange: (e) => {
                                                                            //setCustomEmail(e.target.value);
                                                                        }
                                                                    })}
                                                                />
                                                                <div className="invalid-feedback">{errors.email && errors.email.message}</div>
                                                            </div>
                                                        </div>

                                                        {JSON.parse(Cookies.get('permissions')).indexOf("CanSCIn") !== -1 ? (
                                                            <>
                                                                <div className="col-md-6">
                                                                    <div className="form-group">
                                                                        <label>{t('Telefonnummer')}</label>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            placeholder={t('')}
                                                                            {...register("telefonnummer", {
                                                                                required: false,
                                                                                disabled: readOnly,
                                                                            })}
                                                                        />
                                                                        <div className="invalid-feedback">{errors.telefonnummer && errors.telefonnummer.message}</div>
                                                                    </div>
                                                                </div>

                                                                <div className="col-md-6">
                                                                    <div className="form-group">
                                                                        <label>{t('Mobilnummer')}</label>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            placeholder={t('')}
                                                                            {...register("mobilnummer", {
                                                                                required: false,
                                                                            })} 
                                                                        />
                                                                        <div className="invalid-feedback">{errors.mobilnummer && errors.mobilnummer.message}</div>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        ) : ''}

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    )}

                                    {currentStep === 2 && (
                                    <div className="step-content">
                                         <div className="col-sm-12 col-xl-12">
                                            <div className="card">
                                                <div className="card-body scroll-xl">
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label>{t('Entscheidungstrager')}</label>
                                                                <input
                                                                    type="text"
                                                                    placeholder="Entscheidungstrager"
                                                                    className="form-control "
                                                                    {...register("entscheidungstrager", {
                                                                        required: false,
                                                                        disabled: readOnly,
                                                                    })}
                                                                />
                                                                <div className="invalid-feedback">{errors.entscheidungstrager && errors.entscheidungstrager.message}</div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group solacloud-select icon-caret-down icon-right">
                                                                <label>{t('Erfahrung Mit PV')}</label>
                                                                <select
                                                                    className="form-control "
                                                                    {...register("erfahrung_mit_pv", {
                                                                        disabled: readOnly,
                                                                    })}
                                                                >
                                                                    <option value="">--</option>
                                                                    <option value="Yes">{t('Yes')}</option>
                                                                    <option value="No">{t('No')}</option>
                                                                </select>
                                                                <div className="invalid-feedback">{errors.erfahrung_mit_pv && errors.erfahrung_mit_pv.message}</div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label>{t('Gesamtverbrauch Kwh')}</label>
                                                                <input
                                                                    type="number"
                                                                    className="form-control "
                                                                    placeholder={t('Gesamtverbrauch Kwh')}
                                                                    {...register("gesamtverbrauch_kwh", {
                                                                        disabled: readOnly,
                                                                    })}

                                                                />
                                                                <div className="invalid-feedback">{errors.gesamtverbrauch_kwh && errors.gesamtverbrauch_kwh.message}</div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label>{t('Mtl. Abschlag In Euro')}</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control "
                                                                    placeholder={t('Monatlicher Abschlag In Euro')}
                                                                    {...register("monatlicher", {
                                                                        disabled: readOnly,
                                                                    })}

                                                                />
                                                                <div className="invalid-feedback">{errors.monatlicher && errors.monatlicher.message}</div>
                                                            </div>
                                                        </div>

                                                        <div className="col-md-6">
                                                            <div className="form-group solacloud-select icon-caret-down icon-right">
                                                                <label>{t('Stromabrechung vorh.')}</label>
                                                                <select
                                                                    className="form-control "
                                                                    {...register("stromabrechung_vorhanden", {
                                                                        disabled: readOnly,
                                                                    })}
                                                                >
                                                                    <option>--</option>
                                                                    <option value="Yes" >{t('Yes')}</option>
                                                                    <option value="No" >{t('No')}</option>
                                                                </select>
                                                                <div className="invalid-feedback">{errors.stromabrechung_vorhanden && errors.stromabrechung_vorhanden.message}</div>
                                                            </div>
                                                        </div>

                                                        <div className="col-md-6">
                                                            <div className="form-group solacloud-select icon-caret-down icon-right">
                                                            <label>{t('Dachausrichtung')}</label>
                                                            <select
                                                                className="form-control"
                                                                {...register("dachausrichtung", {
                                                                    required: false,
                                                                    disabled: readOnly
                                                                })}
                                                            >
                                                                <option value="">--</option>
                                                                <option value="Süd">{t('Süd')}</option>
                                                                <option value="S-O u. S-W">{t('S-O u. S-W')}</option>
                                                                <option value="Süd West">{t('Süd West')}</option>
                                                                <option value="West Süd">{t('West Süd')}</option>
                                                                <option value="West">{t('West')}</option>
                                                                <option value="WNW">{t('WNW')}</option>
                                                                <option value="SSO">{t('SSO')}</option>
                                                                <option value="Süd Ost">{t('Süd Ost')}</option>
                                                                <option value="OSO">{t('OSO')}</option>
                                                                <option value="Ost">{t('Ost')}</option>
                                                                <option value="ONO">{t('ONO')}</option>
                                                                <option value="Nord">{t('Nord')}</option>
                                                                <option value="Nord-Ost">{t('Nord-Ost')}</option>
                                                                <option value="Nord-West">{t('Nord-West')}</option>
                                                                <option value="Ost-West">{t('Ost-West')}</option>
                                                                <option value="Süd u. Nord">{t('Süd u. Nord')}</option>
                                                                <option value="West u. Ost">{t('West u. Ost')}</option>
                                                                <option value="Süd & Ost & West">{t('Süd & Ost & West')}</option>
                                                            </select>
                                                            </div> 
                                                            <div className="invalid-feedback">{errors.dachausrichtung && errors.dachausrichtung.message}</div>
                                                        </div>


                                                        <div className="col-md-6">
                                                            <div className="form-group solacloud-select icon-caret-down icon-right">
                                                                <label>{t('Dacheindeckung')}</label>
                                                                <select
                                                                    className="form-control "
                                                                    {...register("ziegelart", {
                                                                        required: false,
                                                                        disabled: readOnly,
                                                                    })}
                                                                >
                                                                    <option value="">--</option>
                                                                    <option value="Sonstiges">{t('Sonstiges')}</option>
                                                                    <option value="Ziegel">{t('Ziegel')}</option>
                                                                    <option value="Blech">{t('Blech')}</option>
                                                                    <option value="Kies">{t('Kies')}</option>
                                                                    <option value="Schiefer">{t('Schiefer')}</option>
                                                                    <option value="Schindel">{t('Schindel')}</option>
                                                                    <option value="Betumen">{t('Betumen')}</option>
                                                                    <option value="Gründach">{t('Gründach')}</option>
                                                                    <option value="Wellblech">{t('Wellblech')}</option>
                                                                    <option value="Trapezblech">{t('Trapezblech')}</option>
                                                                    <option value="Sandwiche">{t('Sandwiche')}</option>
                                                                </select>
                                                                <div className="invalid-feedback">{errors.dacheindeckung && errors.dacheindeckung.message}</div>
                                                            </div>
                                                        </div>

                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label>{t('E-Mobilitat')}</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control "
                                                                    placeholder={t('E-Mobilitat')}
                                                                    {...register("e_mobilitat", {
                                                                        disabled: readOnly,
                                                                    })}

                                                                />
                                                                <div className="invalid-feedback">{errors.e_mobilitat && errors.e_mobilitat.message}</div>
                                                            </div>
                                                        </div>

                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label>{t('Zeitraum (max.)')}</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control "
                                                                    placeholder={t('Zeitraum (max.)')}
                                                                    {...register("zeitraum_max", {
                                                                        disabled: readOnly,
                                                                    })}

                                                                />
                                                                <div className="invalid-feedback">{errors.zeitraum_max && errors.zeitraum_max.message}</div>
                                                            </div>
                                                        </div>

                                                        <div className="col-md-6">
                                                            <div className="form-group solacloud-select icon-caret-down icon-right">
                                                                <label>{t('Internet')}</label>
                                                                <select
                                                                    className="form-control "
                                                                    {...register("internet", {
                                                                        disabled: readOnly,
                                                                    })}
                                                                >
                                                                    <option value="">--</option>
                                                                    <option value="Yes">{t('Yes')}</option>
                                                                    <option value="No">{t('No')}</option>
                                                                </select>
                                                                <div className="invalid-feedback">{errors.internet && errors.internet.message}</div>
                                                            </div>
                                                        </div>

                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label>{t('Bestatiger Name')}</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control "
                                                                    {...register("bestatiger_name", {
                                                                        disabled: readOnly,
                                                                    })}
                                                                />
                                                                <div className="invalid-feedback">{errors.bestatiger_name && errors.bestatiger_name.message}</div>
                                                            </div>
                                                        </div>

                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label>{t('Termin vereinbart am')}</label>
                                                                <input
                                                                    type="date"
                                                                    className="form-control "
                                                                    {...register("termin_vereinbart_am", {
                                                                        disabled: readOnly,
                                                                    })}
                                                                />
                                                                <div className="invalid-feedback">{errors.termin_vereinbart_am && errors.termin_vereinbart_am.message}</div>
                                                            </div>
                                                        </div>

                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label>{t('Kundenalter')}</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control "
                                                                    {...register("kundenalter", {
                                                                        disabled: readOnly,
                                                                    })}
                                                                />
                                                                <div className="invalid-feedback">{errors.kundenalter && errors.kundenalter.message}</div>
                                                            </div>
                                                        </div>

                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label>{t('Personen im Haushalt')}</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control "
                                                                    {...register("personen_im_haushalt", {
                                                                        disabled: readOnly,
                                                                    })}
                                                                />
                                                                <div className="invalid-feedback">{errors.personen_im_haushalt && errors.personen_im_haushalt.message}</div>
                                                            </div>
                                                        </div>

                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label>{t('Heizungsart')}</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control "
                                                                    {...register("heizungsart", {
                                                                        disabled: readOnly,
                                                                    })}
                                                                />
                                                                <div className="invalid-feedback">{errors.heizungsart && errors.heizungsart.message}</div>
                                                            </div>
                                                        </div>

                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label>{t('Baujahr des Gebäudes')}</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control "
                                                                    {...register("baujahr_des_gebades", {
                                                                        disabled: readOnly,
                                                                    })}
                                                                />
                                                                <div className="invalid-feedback">{errors.baujahr_des_gebades && errors.baujahr_des_gebades.message}</div>
                                                            </div>
                                                        </div>

                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label>{t('Nutzbare Dachflache')}</label>
                                                                <input
                                                                    type="number"
                                                                    className="form-control "
                                                                    {...register("nutzbare_dachflache", {
                                                                        disabled: readOnly,
                                                                    })}
                                                                />
                                                                <div className="invalid-feedback">{errors.nutzbare_dachflache && errors.nutzbare_dachflache.message}</div>
                                                            </div>
                                                        </div>

                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label>{t('Werber')}</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control "
                                                                    {...register("werber", {
                                                                        disabled: readOnly,
                                                                    })}
                                                                />
                                                                <div className="invalid-feedback">{errors.werber && errors.werber.message}</div>
                                                            </div>
                                                        </div>

                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label>{t('Lead Uursprung')}</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control "
                                                                    {...register("lead_ursprung", {
                                                                        disabled: readOnly,
                                                                    })}
                                                                />
                                                                <div className="invalid-feedback">{errors.lead_ursprung && errors.lead_ursprung.message}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    )}
                                </div>

                                {/* <div className="navigation-card mt-5">
                                    <div className="btn-wrapper">
                                    <button
                                        className="btn btn-secondary"
                                        onClick={handlePrev}
                                        disabled={currentStep === 0}
                                    >
                                        Previous
                                    </button>
                                    <button className="btn btn-primary" onClick={handleNext}>
                                        {currentStep === steps.length - 1 ? "Finish" : "Next"}
                                    </button>
                                    </div>
                                </div> */}
                                

                            </div>  

                            <div className="fixed-bottom mb-3 fixed-bottom-width-available pe-3">
                                <div className="card">
                                    <div className="card-body text-end d-flex justify-content-end">
                                        <button className='btn btn-default d-flex align-items-center border-primary me-3 text-brand-primary' type="button"  onClick={handlePrev} disabled={currentStep === 0}> {currentStep == 0 ? ''  : <img className="me-2" src="/assets/icons/CaretLeftBrandPrimary.svg"/>} {t('Zurück')}</button>
                                        {!saving && (
                                            <button className='btn btn-primary d-flex align-items-center' type="submit">
                                                {currentStep == 2 ? <>{t('Projekt Erstellen')}</>   : <>{t('Weiter')} <img className="ms-2" src="/assets/icons/CaretRightWhite.svg"/></>}  
                                            </button>
                                        )}
                                        {saving && (
                                            <button className='btn btn-disabled d-flex justify-content-center align-items-center' type="button">
                                               {t('Saving...')}
                                            </button>
                                        )} 
                                    </div>
                                </div>
                            </div>

                            

                            <div className="col-12 d-none">
                                {JSON.parse(Cookies.get('permissions')).indexOf("PCCU") !== -1 ? (
                                    <div className="row g-3">
                                        <div className="col-12 my-4">
                                            {!saving && (
                                                <button type="submit" className="btn btn-primary" id="callSubmitBtn">
                                                    {t('Save')}
                                                </button>
                                            )}
                                            {saving && (
                                                <button type="button" className="btn btn-secondary" disabled>
                                                    {t('wird geladen...')}
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                ) : <></>}
                            </div>
                        </div>
                    </form>
                </>
            }

            {params.id > 0 && (
                <>
                    {viewMode != 'overview' ?
                        <>
                            <form className={`needs-validation ${Object.keys(errors).length ? "was-validated" : ""}`} onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
                                {create == false ? (
                                    <input type="hidden" defaultValue="PUT" {...register("_method")} />
                                ) : <></>}

                                <div className="row g-3">
                                    <div className="col-12">
                                        <a onClick={() => setViewMode('overview')} style={{ borderBottom: '1px solid rgb(0, 84, 152)', fontSize: '15px', color: 'rgb(0, 84, 152)', fontWeight: '700' }}><i className="fa-solid fa-arrow-left-long"></i> {t('Zurück')}</a>
                                    </div>
                                    <div className="col-sm-12 col-xl-3">
                                        <div className="card">
                                            <div className="card-body scroll-xl">
                                                <div className="row">
                                                    <div className="col-6 col-md-4 col-xl-6">
                                                        <div className="form-group">
                                                            <label htmlFor="example-date-input" className="form-control-label">{t('Date')}</label>
                                                            <input className="form-control"
                                                                {...register("date", {
                                                                    required: true,
                                                                    disabled: readOnly,
                                                                    onChange: (e) => {
                                                                        verkauferFeatch();
                                                                    }
                                                                })}
                                                                required
                                                                type="date" id="example-date-input" /> 
                                                        </div>
                                                    </div>

                                                    <div className="col-6 col-md-4 col-xl-6">
                                                        <div className="form-group solacloud-select icon-clock">
                                                            <label>
                                                                {t('Time')} *
                                                            </label>
                                                            <br />
                                                            <select
                                                                className="form-control"
                                                                {...register("time", {
                                                                    required: true,
                                                                    disabled: readOnly,
                                                                    onChange: (e) => {
                                                                        verkauferFeatch();
                                                                    }
                                                                })}
                                                                required
                                                            >
                                                                <option value="">--</option>
                                                                <option value="08:00">08:00</option>
                                                                <option value="08:30">08:30</option>
                                                                <option value="09:00">09:00</option>
                                                                <option value="09:30">09:30</option>
                                                                <option value="10:00">10:00</option>
                                                                <option value="10:30">10:30</option>
                                                                <option value="11:00">11:00</option>
                                                                <option value="11:30">11:30</option>
                                                                <option value="12:00">12:00</option>
                                                                <option value="12:30">12:30</option>
                                                                <option value="13:00">13:00</option>
                                                                <option value="13:30">13:30</option>
                                                                <option value="14:00">14:00</option>
                                                                <option value="14:30">14:30</option>
                                                                <option value="15:00">15:00</option>
                                                                <option value="15:30">15:30</option>
                                                                <option value="16:00">16:00</option>
                                                                <option value="16:30">16:30</option>
                                                                <option value="17:00">17:00</option>
                                                                <option value="17:30">17:30</option>
                                                                <option value="18:00">18:00</option>
                                                                <option value="18:30">18:30</option>
                                                                <option value="19:00">19:00</option>
                                                                <option value="19:30">19:30</option>
                                                                <option value="20:00">20:00</option>
                                                                <option value="20:30">20:30</option>
                                                                <option value="21:00">21:00</option>
                                                                <option value="21:30">21:30</option>
                                                                <option value="22:00">22:00</option>
                                                            </select>
                                                            <div className="invalid-feedback">{errors.quotation_id && errors.quotation_id.message}</div>
                                                        </div>
                                                    </div>

                                                    <div className="col-12 col-md-4 col-xl-12">
                                                        <div className="form-group">
                                                            <label>
                                                                {t('Verkaufer')} *
                                                            </label>
                                                            <br />
                                                            <SoftSelect
                                                                placeholder={t('Select')}
                                                                options={data?.employees ? data?.employees : []}
                                                                getOptionLabel={(option) => ((option?.user?.name ? option?.user?.name : ''))}
                                                                getOptionValue={(option) => option?.user?.id}
                                                                value={selectedEmployee}
                                                                onChange={(e) => {
                                                                    setValue('verkaufer', e.user.id);
                                                                    setSelectedEmployee({
                                                                        id: e?.user.id,
                                                                        user: {
                                                                            name: e?.user.name,
                                                                        },
                                                                    });
                                                                    clearErrors();
                                                                }}

                                                                required={true}
                                                                styles={{
                                                                    fontSize: '0.875rem',
                                                                    control: (baseStyles, state) => ({
                                                                        ...baseStyles,
                                                                        fontSize: '0.875rem',
                                                                    }),
                                                                }}
                                                            />
                                                            <div className="invalid-feedback d-block">{errors.verkaufer ? t(errors.verkaufer.message) : ''}</div>
                                                        </div>
                                                    </div>

                                                    <div className="col-12">
                                                        <div className="form-group">
                                                            <label>
                                                                {t('Beschreibung')}
                                                            </label>
                                                            <textarea
                                                                className="form-control"
                                                                rows="15"
                                                                placeholder={t('eg. 1234 My street, City')}
                                                                {...register("description", {
                                                                    disabled: readOnly,
                                                                })}></textarea>
                                                            <div className="invalid-feedback">{errors.address && errors.address.message}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-sm-12 col-xl-6">
                                        <div className="card">
                                            <div className="card-body scroll-xl">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>
                                                                {t('Kunde')}
                                                            </label>
                                                            <br />

                                                            <Select
                                                                placeholder={t('Select')}
                                                                options={customers ? customers : {}}
                                                                getOptionLabel={(option) => ((option.customer_number ? option.customer_number : '') + (option.vorname ? ' - ' + option.vorname : '') + ' ' + (option?.name ? option?.name : ''))}
                                                                getOptionValue={(option) => option.id}
                                                                value={selecteCustomertId}

                                                                onChange={(e) => {
                                                                    setValue('customer_id', e.id);
                                                                    setSelecteCustomertId({
                                                                        id: e?.id,
                                                                        vorname: e?.vorname,
                                                                        name: e?.name,
                                                                        customer_number: e?.customer_number,
                                                                    });

                                                                    for (let [key, value] of Object.entries(e)) {
                                                                        if (key == 'id') {

                                                                        } else {
                                                                            console.log(key + ': ' + value)
                                                                            setValue(key, (value == null || value == 'null' ? "" : value));
                                                                        }
                                                                    }

                                                                    setValue('full_address', e?.street + (e?.nr && e?.nr != 'null' ? ' ' + e?.nr : ''));
                                                                }}
                                                                required
                                                                styles={{
                                                                    fontSize: '0.875rem',
                                                                    control: (baseStyles, state) => ({
                                                                        ...baseStyles,
                                                                        fontSize: '0.875rem',
                                                                    }),
                                                                }}
                                                            />
                                                            <div className="invalid-feedback">{errors.customer_id && errors.customer_id.message}</div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6"></div>
                                                    <div className="col-md-2">
                                                        <div className="form-group">
                                                            <label>{t('Anrede')} *</label>
                                                            <select
                                                                className="form-control"
                                                                {...register("anrede", {
                                                                    required: true,
                                                                    disabled: readOnly,
                                                                })}
                                                                required>
                                                                <option value="">--</option>
                                                                <option value="Herr">{t('Herr')}</option>
                                                                <option value="Frau">{t('Frau')}</option>
                                                            </select>
                                                            <div className="invalid-feedback">{errors.anrede && errors.anrede.message}</div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-5">
                                                        <div className="form-group">
                                                            <label>{t('Vorname')} *</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder={t('')}
                                                                {...register("vorname", {
                                                                    required: true,
                                                                    disabled: readOnly,
                                                                })}
                                                                required
                                                            />
                                                            <div className="invalid-feedback">{errors.vorname && errors.vorname.message}</div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-5">
                                                        <div className="form-group">
                                                            <label>{t('Name')} *</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder={t('')}
                                                                {...register("name", {
                                                                    required: true,
                                                                    disabled: readOnly,
                                                                })}
                                                                required
                                                            />
                                                            <div className="invalid-feedback">{errors.name && errors.name.message}</div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-md-9">
                                                        <Autocomplete
                                                            options={{
                                                                componentRestrictions: { country: "de" },
                                                                //fields: ["address_components", "geometry", "icon", "name"],
                                                                //types: ["establishment"]
                                                            }}
                                                            onLoad={onLoad}
                                                            onPlaceChanged={onPlaceChanged}
                                                        >
                                                            <div className="form-group">
                                                                <label>{t('Straße')} *</label>
                                                                <input
                                                                    type="text"
                                                                    placeholder={t('')}
                                                                    className="form-control"
                                                                    //defaultValue={getValues('street') ? getValues('street') + ' ' + getValues('nr') : ''}
                                                                    {...register("full_address", {
                                                                        required: false,
                                                                        disabled: readOnly,
                                                                    })}

                                                                />
                                                                <div className="invalid-feedback d-block">{errors.street ? errors.street.message : (errors.nr ? t('Please add a street nr.') : '')}</div>
                                                            </div>
                                                        </Autocomplete>
                                                        <div className="form-group d-none">
                                                            <label>{t('Straße')} *</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder={t('')}
                                                                {...register("street", {
                                                                    required: true,
                                                                    disabled: readOnly,
                                                                })}
                                                                required
                                                            />
                                                            <div className="invalid-feedback">{errors.street && errors.street.message}</div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>{t('Nr')} *</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder={t('')}
                                                                {...register("nr", {
                                                                    required: false,
                                                                    disabled: readOnly,
                                                                })}
                                                                readOnly
                                                            />
                                                            <div className="invalid-feedback">{errors.nr && errors.nr.message}</div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>{t('PLZ')} *</label>

                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder={t('')}
                                                                {...register("plz", {
                                                                    required: false,
                                                                    disabled: readOnly,
                                                                })}
                                                                readOnly
                                                            />
                                                            <div className="invalid-feedback">{errors.plz && errors.plz.message}</div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>{t('Ort')} *</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder={t('')}
                                                                {...register("ort", {
                                                                    required: false,
                                                                    disabled: readOnly,
                                                                })}
                                                                readOnly
                                                            />
                                                            <div className="invalid-feedback">{errors.ort && errors.ort.message}</div>
                                                        </div>
                                                    </div>


                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>{t('Firma')}</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder={t('')}
                                                                {...register("firma", {
                                                                    disabled: readOnly,
                                                                })} />
                                                            <div className="invalid-feedback">{errors.firma && errors.firma.message}</div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>{t('E-mail Adresse')}</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder={t('')}
                                                                {...register("email", {
                                                                    required: false,
                                                                    disabled: readOnly,
                                                                    onChange: (e) => {
                                                                        //setCustomEmail(e.target.value);
                                                                    }
                                                                })}
                                                            />
                                                            <div className="invalid-feedback">{errors.email && errors.email.message}</div>
                                                        </div>
                                                    </div>

                                                    {JSON.parse(Cookies.get('permissions')).indexOf("CanSCIn") !== -1 ? (
                                                        <>
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <label>{t('Telefonnummer')}</label>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        placeholder={t('')}
                                                                        {...register("telefonnummer", {
                                                                            required: false,
                                                                            disabled: readOnly,
                                                                        })}
                                                                    />
                                                                    <div className="invalid-feedback">{errors.telefonnummer && errors.telefonnummer.message}</div>
                                                                </div>
                                                            </div>

                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <label>{t('Mobilnummer')}</label>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        placeholder={t('')}
                                                                        {...register("mobilnummer", {
                                                                            required: false,
                                                                            disabled: readOnly,
                                                                        })}
                                                                    />
                                                                    <div className="invalid-feedback">{errors.mobilnummer && errors.mobilnummer.message}</div>
                                                                </div>
                                                            </div>
                                                        </>
                                                    ) : ''}

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-sm-12 col-xl-3">
                                        <div className="card">
                                            <div className="card-body scroll-xl">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>{t('Entscheidungstrager')}</label>
                                                            <input
                                                                type="text"
                                                                placeholder="Entscheidungstrager"
                                                                className="form-control "
                                                                {...register("entscheidungstrager", {
                                                                    required: false,
                                                                    disabled: readOnly,
                                                                })}
                                                            />
                                                            <div className="invalid-feedback">{errors.entscheidungstrager && errors.entscheidungstrager.message}</div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>{t('Erfahrung Mit PV')}</label>
                                                            <select
                                                                className="form-control "
                                                                {...register("erfahrung_mit_pv", {
                                                                    disabled: readOnly,
                                                                })}
                                                            >
                                                                <option value="">--</option>
                                                                <option value="Yes">{t('Yes')}</option>
                                                                <option value="No">{t('No')}</option>
                                                            </select>
                                                            <div className="invalid-feedback">{errors.erfahrung_mit_pv && errors.erfahrung_mit_pv.message}</div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>{t('Gesamtverbrauch Kwh')}</label>
                                                            <input
                                                                type="number"
                                                                className="form-control "
                                                                placeholder={t('Gesamtverbrauch Kwh')}
                                                                {...register("gesamtverbrauch_kwh", {
                                                                    disabled: readOnly,
                                                                })}

                                                            />
                                                            <div className="invalid-feedback">{errors.gesamtverbrauch_kwh && errors.gesamtverbrauch_kwh.message}</div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>{t('Mtl. Abschlag In Euro')}</label>
                                                            <input
                                                                type="text"
                                                                className="form-control "
                                                                placeholder={t('Monatlicher Abschlag In Euro')}
                                                                {...register("monatlicher", {
                                                                    disabled: readOnly,
                                                                })}

                                                            />
                                                            <div className="invalid-feedback">{errors.monatlicher && errors.monatlicher.message}</div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>{t('Stromabrechung vorh.')}</label>
                                                            <select
                                                                className="form-control "
                                                                {...register("stromabrechung_vorhanden", {
                                                                    disabled: readOnly,
                                                                })}
                                                            >
                                                                <option>--</option>
                                                                <option value="Yes" >{t('Yes')}</option>
                                                                <option value="No" >{t('No')}</option>
                                                            </select>
                                                            <div className="invalid-feedback">{errors.stromabrechung_vorhanden && errors.stromabrechung_vorhanden.message}</div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <label>{t('Dachausrichtung')}</label>
                                                        <select
                                                            className="form-control "
                                                            {...register("dachausrichtung", {
                                                                required: false,
                                                                disabled: readOnly
                                                            })}
                                                        >
                                                            <option value="">--</option>
                                                            <option value="Süd">{t('Süd')}</option>
                                                            <option value="S-O u. S-W">{t('S-O u. S-W')}</option>
                                                            <option value="Süd West">{t('Süd West')}</option>
                                                            <option value="West Süd">{t('West Süd')}</option>
                                                            <option value="West">{t('West')}</option>
                                                            <option value="WNW">{t('WNW')}</option>
                                                            <option value="SSO">{t('SSO')}</option>
                                                            <option value="Süd Ost">{t('Süd Ost')}</option>
                                                            <option value="OSO">{t('OSO')}</option>
                                                            <option value="Ost">{t('Ost')}</option>
                                                            <option value="ONO">{t('ONO')}</option>
                                                            <option value="Nord">{t('Nord')}</option>
                                                            <option value="Nord-Ost">{t('Nord-Ost')}</option>
                                                            <option value="Nord-West">{t('Nord-West')}</option>
                                                            <option value="Ost-West">{t('Ost-West')}</option>
                                                            <option value="Süd u. Nord">{t('Süd u. Nord')}</option>
                                                            <option value="West u. Ost">{t('West u. Ost')}</option>
                                                            <option value="Süd & Ost & West">{t('Süd & Ost & West')}</option>
                                                        </select>
                                                        <div className="invalid-feedback">{errors.dachausrichtung && errors.dachausrichtung.message}</div>
                                                    </div>


                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>{t('Dacheindeckung')}</label>
                                                            <select
                                                                className="form-control "
                                                                {...register("ziegelart", {
                                                                    required: false,
                                                                    disabled: readOnly,
                                                                })}
                                                            >
                                                                <option value="">--</option>
                                                                <option value="Sonstiges">{t('Sonstiges')}</option>
                                                                <option value="Ziegel">{t('Ziegel')}</option>
                                                                <option value="Blech">{t('Blech')}</option>
                                                                <option value="Kies">{t('Kies')}</option>
                                                                <option value="Schiefer">{t('Schiefer')}</option>
                                                                <option value="Schindel">{t('Schindel')}</option>
                                                                <option value="Betumen">{t('Betumen')}</option>
                                                                <option value="Gründach">{t('Gründach')}</option>
                                                                <option value="Wellblech">{t('Wellblech')}</option>
                                                                <option value="Trapezblech">{t('Trapezblech')}</option>
                                                                <option value="Sandwiche">{t('Sandwiche')}</option>
                                                            </select>
                                                            <div className="invalid-feedback">{errors.dacheindeckung && errors.dacheindeckung.message}</div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>{t('E-Mobilitat')}</label>
                                                            <input
                                                                type="text"
                                                                className="form-control "
                                                                placeholder={t('E-Mobilitat')}
                                                                {...register("e_mobilitat", {
                                                                    disabled: readOnly,
                                                                })}

                                                            />
                                                            <div className="invalid-feedback">{errors.e_mobilitat && errors.e_mobilitat.message}</div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>{t('Zeitraum (max.)')}</label>
                                                            <input
                                                                type="text"
                                                                className="form-control "
                                                                placeholder={t('Zeitraum (max.)')}
                                                                {...register("zeitraum_max", {
                                                                    disabled: readOnly,
                                                                })}

                                                            />
                                                            <div className="invalid-feedback">{errors.zeitraum_max && errors.zeitraum_max.message}</div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>{t('Internet')}</label>
                                                            <select
                                                                className="form-control "
                                                                {...register("internet", {
                                                                    disabled: readOnly,
                                                                })}
                                                            >
                                                                <option value="">--</option>
                                                                <option value="Yes">{t('Yes')}</option>
                                                                <option value="No">{t('No')}</option>
                                                            </select>
                                                            <div className="invalid-feedback">{errors.internet && errors.internet.message}</div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>{t('Bestatiger Name')}</label>
                                                            <input
                                                                type="text"
                                                                className="form-control "
                                                                {...register("bestatiger_name", {
                                                                    disabled: readOnly,
                                                                })}
                                                            />
                                                            <div className="invalid-feedback">{errors.bestatiger_name && errors.bestatiger_name.message}</div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>{t('Termin vereinbart am')}</label>
                                                            <input
                                                                type="date"
                                                                className="form-control "
                                                                {...register("termin_vereinbart_am", {
                                                                    disabled: readOnly,
                                                                })}
                                                            />
                                                            <div className="invalid-feedback">{errors.termin_vereinbart_am && errors.termin_vereinbart_am.message}</div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>{t('Kundenalter')}</label>
                                                            <input
                                                                type="text"
                                                                className="form-control "
                                                                {...register("kundenalter", {
                                                                    disabled: readOnly,
                                                                })}
                                                            />
                                                            <div className="invalid-feedback">{errors.kundenalter && errors.kundenalter.message}</div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>{t('Personen im Haushalt')}</label>
                                                            <input
                                                                type="text"
                                                                className="form-control "
                                                                {...register("personen_im_haushalt", {
                                                                    disabled: readOnly,
                                                                })}
                                                            />
                                                            <div className="invalid-feedback">{errors.personen_im_haushalt && errors.personen_im_haushalt.message}</div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>{t('Heizungsart')}</label>
                                                            <input
                                                                type="text"
                                                                className="form-control "
                                                                {...register("heizungsart", {
                                                                    disabled: readOnly,
                                                                })}
                                                            />
                                                            <div className="invalid-feedback">{errors.heizungsart && errors.heizungsart.message}</div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>{t('Baujahr des Gebäudes')}</label>
                                                            <input
                                                                type="text"
                                                                className="form-control "
                                                                {...register("baujahr_des_gebades", {
                                                                    disabled: readOnly,
                                                                })}
                                                            />
                                                            <div className="invalid-feedback">{errors.baujahr_des_gebades && errors.baujahr_des_gebades.message}</div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>{t('Nutzbare Dachflache')}</label>
                                                            <input
                                                                type="number"
                                                                className="form-control "
                                                                {...register("nutzbare_dachflache", {
                                                                    disabled: readOnly,
                                                                })}
                                                            />
                                                            <div className="invalid-feedback">{errors.nutzbare_dachflache && errors.nutzbare_dachflache.message}</div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>{t('Werber')}</label>
                                                            <input
                                                                type="text"
                                                                className="form-control "
                                                                {...register("werber", {
                                                                    disabled: readOnly,
                                                                })}
                                                            />
                                                            <div className="invalid-feedback">{errors.werber && errors.werber.message}</div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>{t('Lead Uursprung')}</label>
                                                            <input
                                                                type="text"
                                                                className="form-control "
                                                                {...register("lead_ursprung", {
                                                                    disabled: readOnly,
                                                                })}
                                                            />
                                                            <div className="invalid-feedback">{errors.lead_ursprung && errors.lead_ursprung.message}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12 d-none">
                                        {JSON.parse(Cookies.get('permissions')).indexOf("PCCU") !== -1 ? (
                                            <div className="row g-3">
                                                <div className="col-12 my-4">
                                                    {!saving && (
                                                        <button type="submit" className="btn btn-primary" id="callSubmitBtn">
                                                            {t('Save')}
                                                        </button>
                                                    )}
                                                    {saving && (
                                                        <button type="button" className="btn btn-secondary" disabled>
                                                            {t('wird geladen...')}
                                                        </button>
                                                    )}
                                                </div>
                                            </div>
                                        ) : <></>}
                                    </div>
                                </div>
                            </form>
                        </>
                        :
                        <>
                            <div className="row mb-3 call-center-new">
                                <div className="col-sm-9 inner-main">
                                    <div className="card">
                                        <div className="card-body">
                                            <button onClick={() => setViewMode('edit')} className="btn btn-light pull-right btn-sm py-0 px-2" style={{ minWidth: '65px', height: '30px', marginRight: '15px' }}>
                                                <i className="fa fa-pencil"></i>&nbsp; {t('Edit')}
                                            </button>
                                            <div className="sales-kunde-responsive">
                                                <div className="row">
                                                    <div className="col-12">
                                                        <SoftBox display="flex" justifyContent="space-between" alignItems="flex-start">
                                                            <SoftBox display="flex" mr={2}>
                                                                <SoftAvatar src={employee?.photo ? Cookies.get('backend_url') + 'storage/avatar/' + employee?.photo : '/assets/img/placeholder.png'} size="md" variant="rounded" />
                                                                <SoftBox display="flex" flexDirection="column" justifyContent="center" ml={1}>
                                                                    <SoftTypography variant="button" fontWeight="medium" textTransform="capitalize" style={{ fontSize: '0.95rem', color: '#2D3748', lineHeight: '1.8' }}>
                                                                        {employee?.name}
                                                                    </SoftTypography>

                                                                    <SoftTypography variant="caption" color="text" style={{ fontSize: '13px', lineHeight: '10px' }}>
                                                                        {employee?.roles ? employee?.roles[0]?.name : ''} {employee?.photo}
                                                                    </SoftTypography>
                                                                </SoftBox>
                                                            </SoftBox>
                                                        </SoftBox>
                                                    </div>
                                                    <div className="col-sm-12 mt-4">
                                                        <div className="d-flex">


                                                            <SoftBox display="flex" justifyContent="space-between" alignItems="flex-start">
                                                                <SoftBox display="flex" mr={2}>
                                                                    <SoftBox display="flex" flexDirection="column" justifyContent="center" ml={1}>
                                                                        <SoftTypography variant="caption" style={{ color: '#A0AEC0', fontWeight: '600' }}>
                                                                            Kunde
                                                                        </SoftTypography>
                                                                        <SoftTypography variant="button" fontWeight="bold" textTransform="capitalize" style={{ fontSize: '0.875rem', color: '#344767', lineHeight: '1.8' }}>

                                                                            <NavLink to={'/customers/' + data?.data?.customer_id}>{data?.data?.vorname} {data?.data?.name}</NavLink>
                                                                        </SoftTypography>

                                                                        <SoftTypography variant="caption" color="text" style={{ fontSize: '0.75rem', color: '#718096' }}>
                                                                            <a className="text-primary" target="_blank" href={"https://www.google.com/maps/search/?api=1&query=" + getValues('street') + '+' + getValues('nr') + '+' + getValues('plz') + '+' + getValues('ort')} ><i className="fa fa-map-marker"></i>  {getValues('street')} {getValues('nr')}, {getValues('plz')}, {getValues('ort')}</a>
                                                                        </SoftTypography>

                                                                        <SoftTypography variant="caption" color="text" style={{ fontSize: '0.75rem', marginTop: '4px' }}>
                                                                            <i className="fa fa-envelope"></i>  <a href={'mailto:' + getValues('email')}><span style={{ color: '#0052CC' }}>{getValues('email')}</span></a>
                                                                        </SoftTypography>
                                                                        <SoftTypography variant="caption" color="text" style={{ fontSize: '0.75rem', marginTop: '4px' }}>
                                                                            <i className="fa fa-phone"></i> <span style={{ color: '#0052CC' }}> {<><a style={{ color: '#0052CC' }} href={'tel:' + (getValues('telefonnummer') ? getValues('telefonnummer') : '')}>{(getValues('telefonnummer') ? getValues('telefonnummer') : '')}</a>  <a style={{ color: '#0052CC' }} href={'tel:' + (getValues('mobilnummer') ? getValues('mobilnummer') : '')}>{(getValues('mobilnummer') ? (getValues('telefonnummer') ? ', ' : '') + getValues('mobilnummer') : '')}</a></>} </span>
                                                                        </SoftTypography>
                                                                    </SoftBox>
                                                                </SoftBox>
                                                            </SoftBox>

                                                            <SoftBox display="flex" justifyContent="space-between" alignItems="flex-start">
                                                                <SoftBox display="flex" mr={2}>
                                                                    <SoftBox display="flex" flexDirection="column" justifyContent="center" ml={1}>
                                                                        <SoftTypography variant="caption" style={{ color: '#A0AEC0', fontWeight: '600' }}>
                                                                            Termin
                                                                        </SoftTypography>


                                                                        <SoftTypography variant="caption" style={{ color: '#718096', fontWeight: '600', fontSize: '0.75rem', marginTop: '6px' }}>
                                                                            {dateFormat(data?.data?.date, "dd.mm.yyyy")}
                                                                        </SoftTypography>

                                                                        <SoftTypography variant="caption" style={{ color: '#718096', fontWeight: '600', fontSize: '0.75rem' }}>
                                                                            {data?.data?.time} Uhr
                                                                        </SoftTypography>
                                                                    </SoftBox>
                                                                </SoftBox>
                                                            </SoftBox>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="sales-kunde-desktop">
                                                <div className="d-flex" style={{ padding: '0px 0px', borderBottom: '1px solid #C4C4C4' }}>
                                                    <div className="d-flex justify-content-start" style={{ width: '220px', position: 'relative' }}>
                                                        {/* <span className="after-line"></span> */}
                                                        <SoftBox display="flex" justifyContent="space-between" alignItems="flex-start">
                                                            <SoftBox display="flex" mr={2}>
                                                                <SoftAvatar src={employee?.photo ? Cookies.get('backend_url') + 'storage/avatar/' + employee?.photo : '/assets/img/placeholder.png'} size="md" variant="rounded" />
                                                                <SoftBox display="flex" flexDirection="column" justifyContent="center" ml={1}>
                                                                    <SoftTypography variant="button" fontWeight="medium" textTransform="capitalize" style={{ fontSize: '0.95rem', color: '#2D3748', lineHeight: '1.8' }}>
                                                                        {employee?.name}
                                                                    </SoftTypography>

                                                                    <SoftTypography variant="caption" color="text" style={{ fontSize: '13px', lineHeight: '10px' }}>
                                                                        {employee?.roles ? employee?.roles[0]?.name : ''}
                                                                    </SoftTypography>
                                                                </SoftBox>
                                                            </SoftBox>
                                                        </SoftBox>
                                                    </div>
                                                    <div className="d-flex justify-content-start second-info">
                                                        <SoftBox display="flex" justifyContent="space-between" alignItems="flex-start">
                                                            <SoftBox display="flex" mr={2}>
                                                                <SoftBox display="flex" flexDirection="column" justifyContent="center" ml={1}>
                                                                    <SoftTypography variant="caption" style={{ color: '#A0AEC0', fontWeight: '600' }}>
                                                                        Termin
                                                                    </SoftTypography>


                                                                    <SoftTypography variant="caption" style={{ color: '#718096', fontWeight: '600', fontSize: '0.75rem', marginTop: '6px' }}>
                                                                        {dateFormat(data?.data?.date, "dd.mm.yyyy")}
                                                                    </SoftTypography>

                                                                    <SoftTypography variant="caption" style={{ color: '#718096', fontWeight: '600', fontSize: '0.75rem' }}>
                                                                        {data?.data?.time} Uhr
                                                                    </SoftTypography>
                                                                </SoftBox>
                                                            </SoftBox>
                                                        </SoftBox>
                                                    </div>
                                                    <div className="d-flex justify-content-start">
                                                        <SoftBox display="flex" justifyContent="space-between" alignItems="flex-start" style={{ position: 'relative', top: '-10px' }}>
                                                            <SoftBox display="flex" mr={2}>
                                                                <SoftBox display="flex" flexDirection="column" justifyContent="center" ml={1}>
                                                                    <SoftTypography variant="caption" style={{ color: '#A0AEC0', fontWeight: '600' }}>
                                                                        Kunde {data?.data?.customer?.customer_number}
                                                                    </SoftTypography>
                                                                    <SoftTypography variant="button" fontWeight="bold" textTransform="capitalize" style={{ fontSize: '0.875rem', color: '#344767', lineHeight: '1.8' }}>
                                                                        <NavLink style={{ color: '#0052CC' }} to={'/customers/' + data?.data?.customer_id}>{data?.data?.vorname} {data?.data?.name}</NavLink>
                                                                    </SoftTypography>

                                                                    <SoftTypography variant="caption" color="text" style={{ fontSize: '0.75rem', color: '#718096' }}>
                                                                        <i className="fa fa-map-marker"></i>   <a target="_blank" href={"https://www.google.com/maps/search/?api=1&query=" + getValues('street') + '+' + getValues('nr') + '+' + getValues('plz') + '+' + getValues('ort')}><span style={{ color: '#0052CC' }}> {getValues('street')} {getValues('nr')}, {getValues('plz')}, {getValues('ort')}</span></a>
                                                                    </SoftTypography>
                                                                </SoftBox>
                                                            </SoftBox>
                                                        </SoftBox>

                                                    </div>
                                                    <div className="d-flex justify-content-start info-end">
                                                        <SoftBox display="flex" justifyContent="space-between" alignItems="flex-start" style={{ position: 'relative', top: '-10px' }}>
                                                            <SoftBox display="flex" mr={2}>
                                                                <SoftBox display="flex" flexDirection="column" justifyContent="center" ml={1}>
                                                                    <SoftTypography variant="caption" color="text" style={{ fontSize: '0.75rem', marginTop: '20px' }}>
                                                                        <i className="fa fa-envelope"></i>  <a href={'mailto:' + getValues('email')}><span style={{ color: '#0052CC' }}>{getValues('email')}</span></a>
                                                                    </SoftTypography>
                                                                    <SoftTypography variant="caption" color="text" style={{ fontSize: '0.75rem', marginTop: '7px' }}>
                                                                        <i className="fa fa-phone"></i> <span style={{ color: '#0052CC' }}> {<><a style={{ color: '#0052CC' }} href={'tel:' + (getValues('telefonnummer') ? getValues('telefonnummer') : '')}>{(getValues('telefonnummer') ? getValues('telefonnummer') : '')}</a>  <a style={{ color: '#0052CC' }} href={'tel:' + (getValues('mobilnummer') ? getValues('mobilnummer') : '')}>{(getValues('mobilnummer') ? (getValues('telefonnummer') ? ', ' : '') + getValues('mobilnummer') : '')}</a></>} </span>
                                                                    </SoftTypography>
                                                                </SoftBox>
                                                            </SoftBox>
                                                        </SoftBox>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="d-flex lead-owner" style={{ padding: '15px 0px', borderBottom: '1px solid #C4C4C4' }}>
                                                <div className="d-flex justify-content-start" style={{ paddingTop: '12px' }}>
                                                    <SoftBox display="flex" justifyContent="space-between" alignItems="flex-start" style={{ width: '220px' }}>
                                                        <SoftBox display="flex" mr={2}>
                                                            <SoftBox display="flex" flexDirection="column" justifyContent="center" ml={1}>
                                                                <SoftTypography variant="caption" style={{ color: '#A0AEC0', fontWeight: '600' }}>
                                                                    Lead Owner
                                                                </SoftTypography>
                                                                <SoftTypography variant="button" fontWeight="medium" textTransform="capitalize" style={{ marginTop: '5px', color: '#2D3748' }}>
                                                                    {userLead?.name}
                                                                </SoftTypography>

                                                                <SoftTypography variant="caption" color="text">
                                                                    {userLead?.roles ? userLead?.roles[0]?.name : ''}
                                                                </SoftTypography>
                                                            </SoftBox>
                                                        </SoftBox>
                                                    </SoftBox>

                                                </div>
                                                <div className="" style={{ paddingTop: '12px', position: 'relative', top: '-8px' }}>
                                                    <div className="d-flex ">
                                                        <SoftBox display="flex" justifyContent="space-between" alignItems="flex-start" style={{ width: '180px' }}>
                                                            <SoftBox display="flex" mr={2}>
                                                                <SoftBox display="flex" flexDirection="column" justifyContent="center" ml={1}>
                                                                    <SoftTypography variant="caption" color="text" style={{ fontSize: '11px', color: '#344767' }}>
                                                                        Entscheidungsträger
                                                                    </SoftTypography>

                                                                    <SoftTypography variant="caption" fontWeight="bold" color="text" style={{ fontSize: '11px', color: '#344767' }}>
                                                                        {data?.data?.entscheidungstrager}
                                                                    </SoftTypography>
                                                                </SoftBox>
                                                            </SoftBox>
                                                        </SoftBox>
                                                        <SoftBox display="flex" justifyContent="space-between" alignItems="flex-start" style={{ paddingRight: '0px', width: '200px' }}>
                                                            <SoftBox display="flex" mr={2}>
                                                                <SoftBox display="flex" flexDirection="column" justifyContent="center" ml={1}>
                                                                    <SoftTypography variant="caption" color="text" style={{ fontSize: '11px', color: '#344767' }}>
                                                                        Stromabrechnung Vorhanden
                                                                    </SoftTypography>

                                                                    <SoftTypography variant="caption" fontWeight="bold" color="text" style={{ fontSize: '11px', color: '#344767' }}>
                                                                        {t(data?.data?.stromabrechung_vorhanden)}
                                                                    </SoftTypography>
                                                                </SoftBox>
                                                            </SoftBox>
                                                        </SoftBox>
                                                        <SoftBox display="flex" justifyContent="space-between" alignItems="flex-start" style={{ paddingRight: '0px', width: '180px' }}>
                                                            <SoftBox display="flex" mr={2}>
                                                                <SoftBox display="flex" flexDirection="column" justifyContent="center" ml={1}>
                                                                    <SoftTypography variant="caption" color="text" style={{ fontSize: '11px', color: '#344767' }}>
                                                                        E-Mobilität
                                                                    </SoftTypography>

                                                                    <SoftTypography variant="caption" fontWeight="bold" color="text" style={{ fontSize: '11px', color: '#344767' }}>
                                                                        {data?.data?.e_mobilitat}
                                                                    </SoftTypography>
                                                                </SoftBox>
                                                            </SoftBox>
                                                        </SoftBox>

                                                        <SoftBox display="flex" justifyContent="space-between" alignItems="flex-start" style={{ paddingRight: '0px', width: '180px' }}>
                                                            <SoftBox display="flex" mr={2}>
                                                                <SoftBox display="flex" flexDirection="column" justifyContent="center" ml={1}>
                                                                    <SoftTypography variant="caption" color="text" style={{ fontSize: '11px', color: '#344767' }}>
                                                                        Zeitraum (max.)
                                                                    </SoftTypography>

                                                                    <SoftTypography variant="caption" fontWeight="bold" color="text" style={{ fontSize: '11px', color: '#344767' }}>
                                                                        {data?.data?.zeitraum_max}
                                                                    </SoftTypography>
                                                                </SoftBox>
                                                            </SoftBox>
                                                        </SoftBox>
                                                        <SoftBox display="flex" justifyContent="space-between" alignItems="flex-start" style={{ paddingRight: '0px', width: '180px' }}>
                                                            <SoftBox display="flex" mr={2}>
                                                                <SoftBox display="flex" flexDirection="column" justifyContent="center" ml={1}>
                                                                    <SoftTypography variant="caption" color="text" style={{ fontSize: '11px', color: '#344767' }}>
                                                                        Mtl. Abschlag in EUR
                                                                    </SoftTypography>

                                                                    <SoftTypography variant="caption" fontWeight="bold" color="text" style={{ fontSize: '11px', color: '#344767' }}>
                                                                        {data?.data?.monatlicher}
                                                                    </SoftTypography>
                                                                </SoftBox>
                                                            </SoftBox>
                                                        </SoftBox>
                                                    </div>
                                                    <div className="d-flex" style={{ position: 'relative', top: '15px' }}>
                                                        <SoftBox display="flex" justifyContent="space-between" alignItems="flex-start" style={{ paddingRight: '0px', width: '180px' }}>
                                                            <SoftBox display="flex" mr={2}>
                                                                <SoftBox display="flex" flexDirection="column" justifyContent="center" ml={1}>
                                                                    <SoftTypography variant="caption" color="text" style={{ fontSize: '11px', color: '#344767' }}>
                                                                        Gesamtverbrauch Kwh
                                                                    </SoftTypography>

                                                                    <SoftTypography variant="caption" fontWeight="bold" color="text" style={{ fontSize: '11px', color: '#344767' }}>
                                                                        {data?.data?.gesamtverbrauch_kwh}
                                                                    </SoftTypography>
                                                                </SoftBox>
                                                            </SoftBox>
                                                        </SoftBox>
                                                        <SoftBox display="flex" justifyContent="space-between" alignItems="flex-start" style={{ paddingRight: '0px', width: '200px' }}>
                                                            <SoftBox display="flex" mr={2}>
                                                                <SoftBox display="flex" flexDirection="column" justifyContent="center" ml={1}>
                                                                    <SoftTypography variant="caption" color="text" style={{ fontSize: '11px', color: '#344767' }}>
                                                                        Dacheindeckung
                                                                    </SoftTypography>

                                                                    <SoftTypography variant="caption" fontWeight="bold" color="text" style={{ fontSize: '11px', color: '#344767' }}>
                                                                        {data?.data?.dachausrichtung}
                                                                    </SoftTypography>
                                                                </SoftBox>
                                                            </SoftBox>
                                                        </SoftBox>
                                                        <SoftBox display="flex" justifyContent="space-between" alignItems="flex-start" style={{ paddingRight: '0px', width: '180px' }}>
                                                            <SoftBox display="flex" mr={2}>
                                                                <SoftBox display="flex" flexDirection="column" justifyContent="center" ml={1}>
                                                                    <SoftTypography variant="caption" color="text" style={{ fontSize: '11px', color: '#344767' }}>
                                                                        Internet
                                                                    </SoftTypography>

                                                                    <SoftTypography variant="caption" fontWeight="bold" color="text" style={{ fontSize: '11px', color: '#344767' }}>
                                                                        {t(data?.data?.internet)}
                                                                    </SoftTypography>
                                                                </SoftBox>
                                                            </SoftBox>
                                                        </SoftBox>
                                                        <SoftBox display="flex" justifyContent="space-between" alignItems="flex-start" style={{ paddingRight: '0px', width: '180px' }}>
                                                            <SoftBox display="flex" mr={2}>
                                                                <SoftBox display="flex" flexDirection="column" justifyContent="center" ml={1}>
                                                                    <SoftTypography variant="caption" color="text" style={{ fontSize: '11px', color: '#344767' }}>
                                                                        Dachausrichtung
                                                                    </SoftTypography>

                                                                    <SoftTypography variant="caption" fontWeight="bold" color="text" style={{ fontSize: '11px', color: '#344767' }}>
                                                                        {data?.data?.dachausrichtung}
                                                                    </SoftTypography>
                                                                </SoftBox>
                                                            </SoftBox>
                                                        </SoftBox>
                                                        <SoftBox display="flex" justifyContent="space-between" alignItems="flex-start" style={{ paddingRight: '0px', width: '180px' }}>
                                                            <SoftBox display="flex" mr={2}>
                                                                <SoftBox display="flex" flexDirection="column" justifyContent="center" ml={1}>
                                                                    <SoftTypography variant="caption" color="text" style={{ fontSize: '11px', color: '#344767' }}>
                                                                        Erfahrung mit PV
                                                                    </SoftTypography>

                                                                    <SoftTypography variant="caption" fontWeight="bold" color="text" style={{ fontSize: '11px', color: '#344767' }}>
                                                                        {t(data?.data?.erfahrung_mit_pv)}
                                                                    </SoftTypography>
                                                                </SoftBox>
                                                            </SoftBox>
                                                        </SoftBox>
                                                    </div>
                                                </div>
                                            </div>
                                            <LeadDetails id={params.id} stage={data?.data?.lifecycle_stage} />
                                        </div>
                                    </div>
                                    <div className="mb-3 row mt-3">
                                        <div className="col-sm-12">
                                            <div className="card">
                                                <div className="card-body callcenter" style={{ padding: '15px' }}>
                                                    <ul className="nav nav-tabs nav-underline tabs-res" id="myTab" role="tablist" style={{ width: '97.5%' }}>

                                                        {JSON.parse(Cookies.get('permissions')).indexOf("notizen") !== -1 ? (
                                                            <li className="nav-item" role="presentation">
                                                                <button
                                                                    className="nav-link active"
                                                                    id="home-tab"
                                                                    data-bs-toggle="tab"
                                                                    data-bs-target="#home-tab-pane"
                                                                    type="button"
                                                                    role="tab"
                                                                    aria-controls="home-tab-pane"
                                                                    aria-selected="true"
                                                                    onClick={() => {
                                                                        setActiveTab('notizen');
                                                                    }}
                                                                >  Notizen</button>
                                                            </li>
                                                        ) : <></>}

                                                        {JSON.parse(Cookies.get('permissions')).indexOf("RecR") !== -1 || JSON.parse(Cookies.get('permissions')).indexOf("TicR") !== -1 ? (
                                                            <li className="nav-item" role="presentation">
                                                                <button
                                                                    className={"nav-link " + (JSON.parse(Cookies.get('permissions')).indexOf("notizen") === -1 ? 'active' : '')}
                                                                    id="service-tab"
                                                                    data-bs-toggle="tab"
                                                                    data-bs-target="#service-tab-pane"
                                                                    type="button"
                                                                    role="tab"
                                                                    aria-controls="service-tab-pane"
                                                                    aria-selected="false"
                                                                    onClick={() => {
                                                                        setActiveTab('service');
                                                                    }}
                                                                > Service</button>
                                                            </li>
                                                        ) : <></>}

                                                        {/* {JSON.parse(Cookies.get('permissions')).indexOf("docs") !== -1 ? ( */}
                                                        <li className="nav-item" role="presentation">
                                                            <button
                                                                className={"nav-link " + (JSON.parse(Cookies.get('permissions')).indexOf("notizen") === -1 && JSON.parse(Cookies.get('permissions')).indexOf("RecR") === -1 ? 'active' : '')}
                                                                id="document-tab"
                                                                data-bs-toggle="tab"
                                                                data-bs-target="#document-tab-pane"
                                                                type="button"
                                                                role="tab"
                                                                aria-controls="document-tab-pane"
                                                                aria-selected="false"
                                                                onClick={() => {
                                                                    setActiveTab('documente');
                                                                }}
                                                            > Dokumente</button>
                                                        </li>
                                                        {/* ) : <></>} */}
                                                        {JSON.parse(Cookies.get('permissions')).indexOf("syinfo") !== -1 ? (
                                                            <li className="nav-item" role="presentation">
                                                                <button
                                                                    className={"nav-link " + (JSON.parse(Cookies.get('permissions')).indexOf("docs") === -1 ? 'active' : '')}
                                                                    id="systeminfo-tab"
                                                                    data-bs-toggle="tab"
                                                                    data-bs-target="#systeminfo-tab-pane"
                                                                    type="button"
                                                                    role="tab"
                                                                    aria-controls="systeminfo-tab-pane"
                                                                    aria-selected="false"
                                                                    onClick={() => {
                                                                        setActiveTab('systeminfo');
                                                                    }}
                                                                > Systeminformationen</button>
                                                            </li>
                                                        ) : <></>}

                                                        {JSON.parse(Cookies.get('permissions')).indexOf("activity") !== -1 ? (
                                                            <>
                                                                <li className={"nav-item "} role="presentation">
                                                                    <button
                                                                        className={"nav-link " + (JSON.parse(Cookies.get('permissions')).indexOf("syinfo") === -1 ? 'active' : '')}
                                                                        id="activity-tab"
                                                                        data-bs-toggle="tab"
                                                                        data-bs-target="#activity-tab-pane"
                                                                        type="button"
                                                                        role="tab"
                                                                        aria-controls="activity-tab-pane"
                                                                        aria-selected="false"
                                                                        onClick={() => {
                                                                            setActiveTab('activity');
                                                                        }}
                                                                    > Aktivität</button>
                                                                </li>
                                                            </>
                                                        ) : <></>}

                                                    </ul>
                                                    <div className="tab-content" id="myTabContent">

                                                        {JSON.parse(Cookies.get('permissions')).indexOf("notizen") !== -1 ? (
                                                            <div className="tab-pane fade show active pt-3 tabs" id="home-tab-pane" role="tabpanel" aria-labelledby="home-tab" tabIndex="0">
                                                                {activeTab === 'notizen' ? <ProjectPost id={params.id} /> : ''}
                                                            </div>
                                                        ) : <></>}


                                                        {JSON.parse(Cookies.get('permissions')).indexOf("activity") !== -1 ? (
                                                            <div className={(JSON.parse(Cookies.get('permissions')).indexOf("syinfo") === -1 ? 'tab-pane fade show tabs active pt-3' : 'tab-pane fade pt-3 tabs')} id="activity-tab-pane" role="tabpanel" aria-labelledby="activity-tab" tabIndex="0">
                                                                <p style={{ fontWeight: 'bold', fontSize: '16px' }}>Aktivitäten</p>
                                                                {activeTab === 'activity' ? <Activitys projectId={params?.id} /> : ''}
                                                            </div>
                                                        ) : <></>}

                                                        {JSON.parse(Cookies.get('permissions')).indexOf("RecR") !== -1 || JSON.parse(Cookies.get('permissions')).indexOf("TicR") !== -1 ? (
                                                            <div className={(JSON.parse(Cookies.get('permissions')).indexOf("notizen") === -1 ? 'tab-pane fade tabs  show active p-3' : 'tab-pane tabs fade p-3')} id="service-tab-pane" role="tabpanel" aria-labelledby="service-tab" tabIndex="0">
                                                                <div className="row">
                                                                    <div className="col-sm-12">
                                                                        <SoftBox>
                                                                            {activeTab === 'service' ? (
                                                                                <div className="row">
                                                                                    <div className="col-sm-12">
                                                                                        <SoftTypography variant="h6" fontWeight="bold" style={{ color: '#0048B1', fontSize: '0.875rem' }}>Service Management:</SoftTypography>
                                                                                        <SoftTypography variant="caption" color="text" fontWeight="medium">Reklamationen & Tickets</SoftTypography>
                                                                                    </div>
                                                                                    <div className="col-sm-12">
                                                                                        <ReclamationIndexTable ready={true} viewPopup={true} pagination={false} projectId={params.id} />
                                                                                        <IndexTableTickets ready={true} viewPopup={true} pagination={false} projectId={params.id} />
                                                                                    </div>
                                                                                </div>
                                                                            ) : ''}
                                                                        </SoftBox>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ) : <></>}

                                                        {JSON.parse(Cookies.get('permissions')).indexOf("syinfo") !== -1 ? (
                                                            <div className={(JSON.parse(Cookies.get('permissions')).indexOf("docs") === -1 ? 'tab-pane tabs  fade show active p-3' : 'tab-pane tabs fade p-3')} id="systeminfo-tab-pane" role="tabpanel" aria-labelledby="systeminfo-tab" tabIndex="0">
                                                                <div className="row">
                                                                    <div className="col-sm-12">

                                                                        <SoftBox>
                                                                            <div className="row">
                                                                                <div className="col-sm-7"><SoftTypography variant="caption" fontWeight="bold" style={{ color: '#0048B1', fontSize: '0.875rem' }}>Systeminformationen:</SoftTypography></div>
                                                                                <div className="col-sm-5"><SoftTypography variant="caption" fontWeight="bold" style={{ color: '#0048B1', fontSize: '0.875rem' }}> Allgemein: </SoftTypography> </div>
                                                                            </div>

                                                                            <div className="row">
                                                                                <div className="col-sm-7">
                                                                                    <div className="row">
                                                                                        <div className="col-sm-3"><SoftTypography variant="caption" color="text" fontSize="11px" style={{ display: 'block', lineHeight: '1.4' }} fontWeight="regular">Speicher</SoftTypography></div>
                                                                                        <div className="col-sm-9"><SoftTypography variant="caption" color="text" fontSize="11px" style={{ display: 'block', lineHeight: '1.4' }} fontWeight="bold">{data?.data?.speicher_eingeben ? data.data.speicher_eingeben == 'yes' ? data.data.custom_speicher_name != null ? data.data.custom_speicher_name : '--' : data.data.speicher_name != null ? data.data.speicher_name : '--' : '--'}</SoftTypography> </div>
                                                                                    </div>
                                                                                    <div className="row">
                                                                                        <div className="col-sm-3"><SoftTypography variant="caption" color="text" fontSize="11px" style={{ display: 'block', lineHeight: '1.4' }} fontWeight="regular">Modul</SoftTypography></div>
                                                                                        <div className="col-sm-9"><SoftTypography variant="caption" color="text" style={{ lineHeight: '1.4', display: 'block' }} fontSize="11px" fontWeight="bold">{data?.data?.module_eingeben ? data.data.module_eingeben == 'yes' ? data.data.custom_module_short_title != null ? data.data.custom_module_short_title : '--' : data.data.module_short_title != null ? data.data.module_short_title : '--' : '--'}</SoftTypography> </div>
                                                                                    </div>
                                                                                    <div className="row">
                                                                                        <div className="col-sm-3"><SoftTypography variant="caption" color="text" fontSize="11px" style={{ display: 'block', lineHeight: '1.4' }} fontWeight="regular">Eig.verbr.</SoftTypography></div>
                                                                                        <div className="col-sm-9"><SoftTypography variant="caption" color="text" style={{ lineHeight: '1.4', display: 'block' }} fontSize="11px" fontWeight="bold">{data?.data?.own_consumption} Kw/h</SoftTypography> </div>
                                                                                    </div>
                                                                                    <div className="row">
                                                                                        <div className="col-sm-3"><SoftTypography variant="caption" color="text" fontSize="11px" style={{ display: 'block', lineHeight: '1.4' }} fontWeight="medium">Wechselr.</SoftTypography></div>
                                                                                        <div className="col-sm-9"><SoftTypography variant="caption" color="text" style={{ display: 'block', lineHeight: '1.4' }} fontSize="12px" fontWeight="bold">{data?.data?.wechselrichter_eingeben ? data.data.wechselrichter_eingeben == 'yes' ? data.data.custom_wechselrichter_titel != null ? data.data.custom_wechselrichter_titel : '--' : data.data.wechselrichter_name != null ? data.data.wechselrichter_name : '--' : '--'}</SoftTypography> </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-sm-5">
                                                                                    <div className="row">
                                                                                        <div className="col-sm-6"><SoftTypography variant="caption" color="text" style={{ display: 'block', lineHeight: '1.4' }} fontSize="11px" fontWeight="regular">Stromk. Jahr:</SoftTypography></div>
                                                                                        <div className="col-sm-6"><SoftTypography variant="caption" color="text" style={{ display: 'block', lineHeight: '1.4' }} fontSize="11px" fontWeight="bold">{data?.data?.eigenverbrauch} €</SoftTypography> </div>
                                                                                    </div>
                                                                                    <div className="row">
                                                                                        <div className="col-sm-6"><SoftTypography variant="caption" color="text" style={{ display: 'block', lineHeight: '1.4' }} fontSize="11px" fontWeight="regular">Stromv. Jahr:</SoftTypography></div>
                                                                                        <div className="col-sm-6"><SoftTypography variant="caption" color="text" style={{ display: 'block', lineHeight: '1.4' }} fontSize="11px" fontWeight="bold">{data?.data?.stromkosten} Kw/h</SoftTypography> </div>
                                                                                    </div>
                                                                                    <div className="row">
                                                                                        <div className="col-sm-6"><SoftTypography variant="caption" color="text" style={{ display: 'block', lineHeight: '1.4' }} fontSize="11px" fontWeight="medium">Kwh Preis:</SoftTypography></div>
                                                                                        <div className="col-sm-6"><SoftTypography variant="caption" color="text" style={{ display: 'block', lineHeight: '1.4' }} fontSize="11px" fontWeight="bold">{data?.data?.kwh_preis} €</SoftTypography> </div>
                                                                                    </div>
                                                                                    <div className="row">
                                                                                        <div className="col-sm-6"><SoftTypography variant="caption" color="text" style={{ display: 'block', lineHeight: '1.4' }} fontSize="11px" fontWeight="regular">Baufläche:</SoftTypography></div>
                                                                                        <div className="col-sm-6"><SoftTypography variant="caption" color="text" style={{ display: 'block', lineHeight: '1.4' }} fontSize="11px" fontWeight="bold">{data?.data?.ges_dachflache} qm</SoftTypography> </div>
                                                                                    </div>
                                                                                    <div className="row">
                                                                                        <div className="col-sm-6"><SoftTypography variant="caption" color="text" style={{ display: 'block', lineHeight: '1.4' }} fontSize="11px" fontWeight="regular">Dachtyp:</SoftTypography></div>
                                                                                        <div className="col-sm-6"><SoftTypography variant="caption" color="text" style={{ display: 'block', lineHeight: '1.4' }} fontSize="11px" fontWeight="bold">{data?.data?.dachtyp}</SoftTypography> </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </SoftBox>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ) : <></>}

                                                        {/* {JSON.parse(Cookies.get('permissions')).indexOf("docs") !== -1 ? ( */}
                                                        <div className={(JSON.parse(Cookies.get('permissions')).indexOf("notizen") === -1 && JSON.parse(Cookies.get('permissions')).indexOf("RecR") === -1 ? 'tab-pane tabs  fade show active p-3' : 'tab-pane tabs fade p-3')} id="document-tab-pane" role="tabpanel" aria-labelledby="document-tab" tabIndex="0">
                                                            <div className="row">
                                                                <div className="col-sm-12">
                                                                    {activeTab === 'documente' ? <FilesForOverView /> : ''}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* ) : <></>} */}

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-sm-3 inner-main">
                                    <div className="row mb-3">
                                        <div className="col-12">
                                            <div className={loadMap == true ? 'map-img-link-no-overlay' : 'map-img-link'} style={{ backgroundImage: 'url(/assets/img/map.png)' }}>
                                                {!loadMap && data?.data?.lat && <span onClick={() => setLoadMap(true)}> {t('Dachansicht öffnen')}  </span>}
                                                {loadMap && <Map lat={data?.data?.lat} lng={data?.data?.lng} />}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="card mb-3">
                                                <div className="card-body p-3">
                                                    <div className="d-flex">
                                                        <div>
                                                            <div className="icon icon-shape bg-gradient-dark text-center border-radius-md">
                                                                <i className="ni ni-money-coins text-lg opacity-10" aria-hidden="true" />
                                                            </div>
                                                        </div>
                                                        <div className="ms-3">
                                                            <div className="numbers">
                                                                <p className="text-sm mb-0 text-capitalize font-weight-bold">{t('Project Nr')}:</p>
                                                                <h5 className="font-weight-bolder mb-0">
                                                                    {params?.id ? params?.id : '-'}
                                                                </h5>
                                                            </div>
                                                        </div>

                                                        {data?.data?.id && data?.data?.stornieren != 0 ? (
                                                            <div className="text-end ms-3 mt-2">
                                                                <button
                                                                    className={"btn mb-0 " + (data?.data?.stornieren == 1 ? "btn-danger" : data?.data?.stornieren == 2 ? "btn-success" : "")}
                                                                    onClick={(e) => {
                                                                        storno(e);
                                                                    }}
                                                                >{t(data?.data?.stornieren == 1 ? "Projekt Storniert" : data?.data?.stornieren == 2 ? "Storno aufgehoben" : "Stornieren")}</button>
                                                            </div>
                                                        ) : ''}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="card mb-3">
                                                <div className="card-body p-3">
                                                    <h6 style={{ fontWeight: '700', fontSize: '0.875rem' }}>{t('Status')}</h6>
                                                    {params?.id ? <ProjectStatus resallerStatus={false} projectid={params?.id} /*status={data?.data?.status}*/ /> : ''}
                                                </div>
                                            </div>

                                            {JSON.parse(Cookies.get('permissions')).indexOf("reselStatus") !== -1 ? (
                                                <div className="card mb-3">
                                                    <div className="card-body p-3">
                                                        <h6 style={{ fontWeight: '700', fontSize: '0.875rem' }}>{t('Resale Status')}</h6>
                                                        {params?.id ? <ProjectStatus resallerStatus={true} salesStatus={false} projectid={params?.id} status={data?.data?.status} /> : ''}
                                                    </div>
                                                </div>
                                            ) : ''}
                                        </div>
                                    </div>
                                    <div>
                                        <Timeline refresh={refresh2} />
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                </>
            )}

            {renderSuccessSB}

            <button className="d-none" id="callcenterRefesh3" onClick={() => { setRefesh3(refesh3 + 1); }}>refesh3</button>
        </>;
}

export default CreateProject;
