import { useMemo, useEffect, useState, useRef } from "react";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import Grid from "@mui/material/Grid";
import Cookies from 'js-cookie';
import SoftSelect from "components/SoftSelect";
import DefaultDoughnutChart from "examples/Charts/DoughnutCharts/DefaultDoughnutChart";
import SoftBadgeDot from "components/SoftBadgeDot";
import { useTranslation } from 'react-i18next';

import callFetch from "../../../helpers/callFetch";
import { useForm } from "react-hook-form";

import { NumericFormat } from 'react-number-format';

import borders from "assets/theme/base/borders";
import dateFormat from "dateformat";
import { height } from "@mui/system";

function TechnicianSalesStatusPieChart(props) {
    const { borderWidth, borderColor } = borders;
    const { t } = useTranslation();
    const {
        register,
        handleSubmit,
        setError,
        setValue,
        getValues,
        formState: { errors },
    } = useForm();

    const fp = useRef(null);
    const [loading, setLoading] = useState(false);
    const [pieChartData, setPieChartData] = useState({});
    const [refresh, setRefresh] = useState(0);
    const [users, setUsers] = useState([]);
    const [data, SetData] = useState([]);
    const [verkaufer, setVerkaufer] = useState();
    const [teams, setTeams] = useState();
    const [allTeams, setAllTeams] = useState([]);
    const [filterData, setFilterData] = useState({
        verkaufer: 0,
        created_at: 'Today',
    });

    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    const handleDateChange = (selectedDates) => {
        // Handle selected date range 
        if (Object.keys(selectedDates).length <= 0) {
            setStartDate('');
            setEndDate('');
        } else {
            const [startDate, endDate] = selectedDates;
            setStartDate(dateFormat(startDate, "yyyy-mm-dd"));
            setEndDate(dateFormat(endDate, "yyyy-mm-dd"));
        }

        setRefresh(refresh + 1);
    };

    useEffect(() => {
        setLoading(true);
        if (!filterData?.created_at) return;

        filterData.startDate = startDate;
        filterData.endDate = endDate;

        callFetch("statistics/technician-sales-status-pie-chart", "POST", filterData, setError).then((res) => {
            SetData(res?.data);
            setPieChartData(res?.data?.pie_data);
            setUsers(res?.data?.users);
            setAllTeams(res?.data?.salesTeam);
            setLoading(false);
            if (!res.ok) return;

        });
    }, [refresh]);

    useEffect(() => {
        if (!props?.filterData?.created_at) return;

        if (props?.filterData?.created_at != filterData?.created_at) {
            var data = filterData;

            data.verkaufer = 0;
            data.team = JSON.stringify([]);

            data.created_at = props?.filterData?.created_at;


            setStartDate('')
            setEndDate('')
            setVerkaufer([]);
            setTeams([]);

            setFilterData(data);
            setRefresh(refresh + 1)
        }
    }, [props?.filterData?.created_at]);

    return (
        <>
            <div className="card">
                <div className="card-header">
                    <Grid container spacing={2}>
                        <Grid item xs={12} lg={6}>
                            <h5 className="title">Title chart</h5>
                        </Grid>

                        <Grid item xs={12} lg={6} className="d-flex gap-2 justify-content-between align-items-center">
                            <SoftSelect
                                placeholder={t('Select')}
                                options={[
                                    { value: 'Today', label: 'Today' },
                                    { value: 'Current Week', label: 'Current Week' },
                                    { value: 'Current Month', label: 'Current Month' },
                                    { value: 'All', label: 'All' },
                                ]}
                                value={filterData?.created_at ? { label: filterData?.created_at, value: filterData?.created_at } : { value: 'Today', label: 'Today' }}
                                size={'small'}
                                onChange={(e) => {
                                    var data = filterData;
                                    data.created_at = e.value;
                                    setFilterData(data);
                                    setRefresh(refresh + 1)
                                }}
                            />

                            {/*props?.title == 'All' ? (
                                <Flatpickr
                                    ref={fp}
                                    options={{
                                        mode: 'range',
                                        dateFormat: 'Y-m-d',
                                    }}
                                    onChange={handleDateChange}
                                    placeholder="Selec Date"
                                    className="form-control form-control-sm"
                                />
                            ) : ''*/}

                            <SoftSelect
                                size="small"
                                placeholder="VKB Name"
                                options={users}
                                value={verkaufer}
                                onChange={(e) => {
                                    console.log(e)

                                    var data = filterData;

                                    var verkaufer_ids = [];
                                    /*
                                    for (let [key, value] of Object.entries(e)) {
                                        verkaufer_ids.push(value.value)
                                    }
                                    */
                                    verkaufer_ids.push(e.value)

                                    data.verkaufer = JSON.stringify(verkaufer_ids);

                                    setFilterData(data);
                                    setVerkaufer(e);
                                    setRefresh(refresh + 1)
                                }}
                                //isMulti={true}
                            />
                            <SoftSelect
                                size="small"
                                placeholder="Sales Team"
                                options={allTeams}
                                value={teams}
                                getOptionLabel={(option) => ((option.team_name))}
                                getOptionValue={(option) => option.id}
                                onChange={(e) => {
                                    var data = filterData;

                                    var team_ids = [];
                                    /*
                                    for (let [key, value] of Object.entries(e)) {
                                        team_ids.push(value.id)
                                    }
                                    */
                                    team_ids.push(e.id)
                                    data.team = JSON.stringify(team_ids);

                                    setFilterData(data);
                                    setTeams(e);
                                    setRefresh(refresh + 1)
                                }}
                                //isMulti={true}
                            />
                        </Grid>
                    </Grid>
                </div>
                <div className="card-body">
                    <Grid container className="align-items-center justify-content-center" mb={5.5}>
                        <Grid item xs={12} sm={4} lg={4}>

                            <DefaultDoughnutChart
                                //height="180px" 
                                chart={pieChartData}
                                amount={<NumericFormat
                                    value={Number(data?.total_auftrage_ammount ? data?.total_auftrage_ammount : 0)}
                                    displayType="text"
                                    thousandSeparator={"."}
                                    decimalSeparator=","
                                    decimalScale={2}
                                    fixedDecimalScale
                                    suffix={' € '}
                                />}
                                amountTitle={'Umsatz in EUR'}
                            />
                        </Grid>
                        <Grid item xs={12} sm={8} lg={8} mt={1.6}>
                            <SoftBox px={0}
                                sx={{
                                    height: '214px',
                                    overflowY: 'auto'
                                }}
                            >
                                {pieChartData?.labels ?
                                    pieChartData?.labels?.map((status, index) =>
                                        <div
                                            className="d-flex mb-1"
                                            key={index}
                                        >
                                            <div className="d-flex" style={{ minWidth: '180px', width: '25%', textAlign: 'left' }}>
                                                <SoftBadgeDot borderRadius={'5px'} style={{ padding: '0px' }} size="lg" className="soft-badge-dot" color={pieChartData?.datasets?.backgroundColors[index]} />
                                                <span className="status-text text-overflow-ellipsis">{status}</span>
                                            </div>
                                            <div className="d-flex" style={{ minWidth: '78px', width: '35%', textAlign: 'left' }}><span className="font-size-14-rem">{pieChartData?.datasets?.data[index] + ' Stck.'}</span></div>
                                            <div className="d-flex" style={{ minWidth: '78px', width: '12%', textAlign: 'right' }}>
                                                <span className="font-size-14-rem">
                                                    {pieChartData?.datasets?.data[index]
                                                        ? ((pieChartData?.datasets?.data[index] / data?.totalProjects) * 100) === 100
                                                            ? '100 %'
                                                            : ((pieChartData?.datasets?.data[index] / data?.totalProjects) * 100).toFixed(2) + ' %'
                                                        : '0 %'}
                                                </span>
                                            </div>
                                        </div>
                                    ) : <></>}
                            </SoftBox>
                        </Grid>
                    </Grid>

                    <Grid container spacing={1} style={{ marginBottom: '16px' }}>
                        <Grid item xs={12} md={3}>
                            <SoftBox
                                borderRadius="8px"
                                //display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                                sx={{
                                    backgroundColor: '#F8F9FA',
                                    padding: '10px 9px'

                                }}
                            >
                                <SoftTypography sx={{
                                    fontSize: '14px',
                                    fontWeight: 400,
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                }}
                                    className={'text-secondary'}
                                >DC mit Montagetermin</SoftTypography>

                                <SoftTypography sx={{
                                    fontSize: '24px',
                                    fontWeight: 600
                                }}
                                    className={'text-primary mb-0'}
                                >{(data?.total_DC_mit_montagetermin >= 0 ? data?.total_DC_mit_montagetermin : 0)}</SoftTypography>
                            </SoftBox>
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <SoftBox
                                borderRadius="8px"
                                //display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                                sx={{
                                    backgroundColor: '#F8F9FA',
                                    padding: '10px 9px'
                                }}
                            >
                                <SoftTypography sx={{
                                    fontSize: '14px',
                                    fontWeight: 400,
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                }}
                                    className={'text-secondary'}
                                >DC ohne Montagetermin</SoftTypography>

                                <SoftTypography sx={{
                                    fontSize: '24px',
                                    fontWeight: 600
                                }}
                                    className={'text-primary'}
                                >{(data?.DCOhneMontagetermin >= 0 ? data?.DCOhneMontagetermin : 0)}</SoftTypography>
                            </SoftBox>
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <SoftBox
                                borderRadius="8px"
                                //display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                                sx={{
                                    backgroundColor: '#F8F9FA',
                                    padding: '10px 9px'
                                }}
                            >
                                <SoftTypography sx={{
                                    fontSize: '14px',
                                    fontWeight: 400,
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                }}
                                    className={'text-secondary'}
                                >AC mit Montagetermin</SoftTypography>

                                <SoftTypography sx={{
                                    fontSize: '24px',
                                    fontWeight: 600
                                }}
                                    className={'text-primary'}
                                >{(data?.total_AC_mit_montagetermin >= 0 ? data?.total_AC_mit_montagetermin : 0)}</SoftTypography>
                            </SoftBox>
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <SoftBox
                                borderRadius="8px"
                                //display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                                sx={{
                                    backgroundColor: '#F8F9FA',
                                    padding: '10px 9px'
                                }}
                            >
                                <SoftTypography sx={{
                                    fontSize: '14px',
                                    fontWeight: 400,
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                }}
                                    className={'text-secondary'}
                                >AC ohne Montagetermin</SoftTypography>

                                <SoftTypography sx={{
                                    fontSize: '24px',
                                    fontWeight: 600
                                }}
                                    className={'text-primary'}
                                >{(data?.AC_OhneMontagetermin >= 0 ? data?.AC_OhneMontagetermin : 0)}</SoftTypography>
                            </SoftBox>
                        </Grid>


                        {data && data?.sallerOfTheDay?.data?.length ? data?.sallerOfTheDay?.data.map((sallerOfTheDay, index) => (
                            <Grid item xs={12}>
                                <SoftTypography
                                    className="text-primary"
                                    sx={{
                                        fontSize: '20px',
                                        fontWeight: 600,
                                        paddingBottom: '16px'
                                    }}
                                >
                                    Nr. #{index + 1} Verkaufer des Tages
                                </SoftTypography>

                                <SoftBox
                                    borderRadius="8px"
                                    //display="flex"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    sx={{
                                        backgroundColor: '#F8F9FA',
                                        padding: '16px',
                                        marginTop: '11px'
                                    }}
                                >
                                    <SoftBox display="flex" alignItems="center">
                                        <SoftBox mr={1}>
                                            <img
                                                style={{ border: '0.125rem solid #E7EBF3' }}
                                                class="w-11 h-11 rounded-full"
                                                src={sallerOfTheDay?.verkaufer_photo?.length > 0 ? Cookies.get('backend_url') + 'storage/avatar/' + sallerOfTheDay?.verkaufer_photo : '/assets/img/placeholder.png'}
                                                alt="Jese image"
                                            />
                                        </SoftBox>
                                        <SoftBox display="flex" flexDirection="column">
                                            <SoftTypography variant="h6" fontWeight="medium" className="text-primary text-md">
                                                {sallerOfTheDay?.verkaufer_name}
                                            </SoftTypography>
                                            <SoftTypography variant="h6" fontWeight="medium" className="text-secondary"
                                                style={{
                                                    fontSize: '14px',
                                                    fontWeight: 400
                                                }}
                                            >
                                                {sallerOfTheDay?.total_order} Aufträge in Höhe von
                                                &nbsp;
                                                <NumericFormat
                                                    value={Number(sallerOfTheDay?.total_netto)}
                                                    displayType="text"
                                                    thousandSeparator={"."}
                                                    decimalSeparator=","
                                                    decimalScale={2}
                                                    fixedDecimalScale
                                                    suffix={' € '}
                                                    className="text-success text-md"
                                                />
                                            </SoftTypography>
                                        </SoftBox>
                                    </SoftBox>
                                </SoftBox>
                            </Grid>
                        )) : <>
                            <Grid item xs={12}>
                                <SoftTypography
                                    className="text-primary"
                                    sx={{
                                        fontSize: '20px',
                                        fontWeight: 600,
                                        paddingBottom: '16px'
                                    }}
                                >
                                    Nr. #1 Verkaufer des Tages
                                </SoftTypography>

                                <div
                                    className="d-flex align-items-center justify-content-center"
                                >
                                    <div className="text-center">
                                        <img
                                            src="/assets/icons/UserCircleDashed.svg"
                                            style={{
                                                width: '48px',
                                                height: '52px',
                                                filter: 'brightness(0) saturate(100%) invert(35%) sepia(15%) saturate(765%) hue-rotate(182deg) brightness(91%) contrast(89%)'
                                            }}
                                            className="mb-2"
                                        />

                                        <p className="text-secondary text-md m-0 mb-2">Es gibt noch keinen Verkaufer des Tages</p>
                                    </div>
                                </div>
                            </Grid>
                        </>}
                    </Grid>
                </div>
            </div>
        </>
    )
}

export default TechnicianSalesStatusPieChart;