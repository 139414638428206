import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import Dropzone from "dropzone";
import "dropzone/dist/dropzone.css";
import SoftBox from "../SoftBox";
import SoftDropzoneRoot from "./SoftDropzoneRoot";

function SoftDropzone({ options }) {
  const dropzoneRef = useRef();
  const [files, setFiles] = useState([]);

  useEffect(() => {
    Dropzone.autoDiscover = false;

    function createDropzone() {
      const dz = new Dropzone(dropzoneRef.current, {
        ...options,
        dictDefaultMessage: `
          <div class="dz-message" style="text-align: center; margin: 0px;"> 
            <i class="ph ph-cloud-arrow-up" style="display: block; margin: 0 auto;"></i>
            <p style="margin-top: 10px; color: #208DFC; font-weight: 600; font-size: 14px;">Datei auswählen</p>
            <small style="color: #4D586E; font-size: 0.75rem; display:block;">PDF, JPG, PNG, JPEG <br/> (max. 15MB)</small>
          </div>
        `,
      });

      // When a file is added
      dz.on("addedfile", (file) => {
        setFiles((prevFiles) => [
          ...prevFiles,
          { name: file.name, progress: 0, status: "uploading" },
        ]);
      });

      // Track progress
      dz.on("uploadprogress", (file, progress) => {
        setFiles((prevFiles) =>
          prevFiles.map((f) =>
            f.name === file.name ? { ...f, progress, status: "uploading" } : f
          )
        );
      });

      // Upload success
      dz.on("success", (file) => {
        setFiles((prevFiles) =>
          prevFiles.map((f) =>
            f.name === file.name ? { ...f, progress: 100, status: "success" } : f
          )
        );
      });

      // Handle errors
      dz.on("error", (file) => {
        setFiles((prevFiles) =>
          prevFiles.map((f) =>
            f.name === file.name ? { ...f, status: "error" } : f
          )
        );
      });

      return dz;
    }

    function removeDropzone() {
      if (Dropzone.instances.length > 0)
        Dropzone.instances.forEach((dz) => dz.destroy());
    }

    createDropzone();

    return () => removeDropzone();
  }, [options]);

  // Remove file
  const handleFileRemove = (fileName) => {
    setFiles((prevFiles) => prevFiles.filter((file) => file.name !== fileName));
  };

  return (
    <>
      {/* Dropzone */}
      <SoftDropzoneRoot
        style={{
          borderRadius: "8px",
          backgroundColor: "#ECF5FF",
          border: "1px dashed #208DFC",
        }}
        component="form"
        action={options.action}
        ref={dropzoneRef}
        className="dropzone"
      >
        <SoftBox className="fallback">
          <SoftBox component="input" name="file" type="file" multiple />
        </SoftBox>
      </SoftDropzoneRoot>

      {/* Custom CSS to Hide Name and Size */}
      <style>
        {`
          /* Hide file name and size in Dropzone preview */
          .dropzone .dz-details {
            display: none !important;
          }
        `}
      </style>

      {/* Uploaded Files List with Progress Bars */}
      {files.length > 0 && (
        <div className="form-group mt-3">
          <h6 className="text-primary text-lg m-0">Uploaded Files</h6>
          {files.map((file) => (
            <div
              key={file.name}
              className="bg-secondary border-radius d-flex align-items-center justify-content-between gap-2 p-12px mt-2"
            >
              <i
                className={`ph ${
                  file.status === "error"
                    ? "ph-file-x text-status-error-primary"
                    : "ph-file-text text-primary"
                } title-xl`}
              ></i>
              <div className="flex-fill text-overflow-ellipsis">
                <div className="d-flex align-items-center justify-content-between gap-2">
                  <p
                    title={file.name}
                    className={`text-overflow-ellipsis m-0 mb-1 text-link ${
                      file.status === "error"
                        ? "text-status-error-primary"
                        : "text-primary"
                    }`}
                  >
                    {file.name}
                  </p>
                  <i
                    className="ph ph-x text-primary text-xl cursor-pointer"
                    onClick={() => handleFileRemove(file.name)}
                  ></i>
                </div>
                <div className="progress">
                  <div
                    className={`progress-bar ${
                      file.status === "error"
                        ? "bg-danger"
                        : file.progress === 100
                        ? "bg-success"
                        : "bg-primary"
                    }`}
                    style={{ width: `${Math.round(file.progress)}%` }}
                    aria-valuenow={Math.round(file.progress)}
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  );
}

// Typechecking props
SoftDropzone.propTypes = {
  options: PropTypes.object.isRequired,
};

export default SoftDropzone;
