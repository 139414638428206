import React, { useEffect, useState, useRef } from 'react' 
import { useForm } from "react-hook-form";  
import ChatSendMessage from "./ChatSendMessage";
const NewMessage = ({setShow, id}) => { 
    const {
        register,
        handleSubmit,
        setError,
        setValue,
        formState: { errors },
    } = useForm();

    useEffect(() => { 
        
    }, [ ]);

    const handleClose = () => setShow(false);

    const chatSendMessageRef = useRef();

    const handleSendClick = () => {
        if (chatSendMessageRef.current) {
            chatSendMessageRef.current.triggerFormSubmit();
        }
    };

    return <>
        <div className="modal-dialog modal-dialog-slide-right">
            <div className="modal-content"> 
                <div className='modal-header'>
                    <p className='title mb-0'>Neue Nachricht</p>
                    <button type="button" class="btn-close" id='close-newmsg' data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body"> 
                    <ChatSendMessage ref={chatSendMessageRef}></ChatSendMessage>
                </div>
                <div className='modal-footer justify-content-start'>
                    <div className='d-flex justify-content-between w-100'>
                        {/* <button className='btn btn-default mb-0 w-50 border-primary me-3 text-brand-primary' data-bs-dismiss="modal">Stornieren</button> */}
                        <button className='btn btn-primary mb-0 w-100' onClick={handleSendClick}>Senden</button>
                    </div>
                </div> 
            </div>
        </div>
    </>
}

export default NewMessage