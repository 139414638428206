import { React, useEffect, useState } from 'react';
import { NavLink } from "react-router-dom";
import callFetch from "../../helpers/callFetch";
import { useTranslation } from 'react-i18next';
import DataTable from 'react-data-table-component';
import deleteAlert from "../../helpers/deleteAlert";
import Cookies from 'js-cookie';

function EmployeeIndexTable() {
    const { t } = useTranslation();
    const [employees, setEmployees] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [refresh, setRefresh] = useState(0);
    const [searchKey, setSearchKey] = useState("")
    const [searchData, setSearchData] = useState([])
    const [filterData, setFilterData] = useState([])
    const tableHeadings = [
        {
            name: t('Call Center ID'),
            sortable: true,
            selector: row => (
                <NavLink to={'/call-center/' + row.id} className={"text-primary font-weight-bold"}>
                    {row.id}
                </NavLink>
            )
        },
        {
            name: t('Call Center Name'),
            selector: row =>
                <div className="d-flex align-items-center justify-content-end gap-2">
                    <img className="avatar avatar-sm" src={row.user.photo ? process.env.REACT_APP_STORAGE_URL + row.user.photo : '/assets/img/placeholder.png'} alt="" />

                    <div className="">
                        <p className="text-primary text-link m-0">{row.user.name}</p>
                        <p className="text-primary text-link m-0">{row.designation && row.designation.name}</p>
                    </div>
                </div>
        },
        {
            name: t('Email'),
            selector: row => row.user.email
        },
        {
            name: t(''),
            width: '44px',
            cell: row => (
                <>
                    {JSON.parse(Cookies.get('permissions')).indexOf("CU") !== -1 ||
                        JSON.parse(Cookies.get('permissions')).indexOf("CD") !== -1 ? (
                        <div className="text-center dropstart">
                            <a href="/" className="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                <i className="fa fa-ellipsis-v text-xs"></i>
                            </a>
                            <ul className="dropdown-menu">
                                {JSON.parse(Cookies.get('permissions')).indexOf("CU") !== -1 ? (
                                    <li>
                                        <NavLink to={'/call-center/' + row.id} className="dropdown-item">
                                            {t('Edit')}
                                        </NavLink>
                                    </li>
                                ) : <></>}
                                <li><hr className="dropdown-divider" /></li>
                                {JSON.parse(Cookies.get('permissions')).indexOf("CD") !== -1 ? (
                                    <li><a className="dropdown-item text-danger" href="#0" onClick={(e) => deleteAlert(e, 'callcenter', row.id, t).then(res => setRefresh(refresh + 1))}>{t('Delete')}</a></li>
                                ) : <></>}
                            </ul>
                        </div>
                    ) : <></>}
                </>
            )
        }
    ];

    useEffect(() => {
        callFetch("callcenter?page=" + pageNumber, "GET", []).then((res) => {
            setEmployees(res.data);
            setSearchData(res.data.data)
            setFilterData(res.data.data)
        });
    }, [pageNumber, refresh]);

    useEffect(() => {
        const keys = ["id"]
        const data = searchData.filter((item) => {
            return keys.some((newItem) => {
                return (
                    item[newItem]
                        .toString()
                        .toLowerCase()
                        .indexOf(searchKey.toLowerCase()) > -1
                );
            });
        });
        setEmployees({
            total: employees.total,
            current_page: 1,
            to: data.length,
            from: employees.from,
            last_page: employees.last_page
        })
        setFilterData(data)
    }, [searchKey])

    const handlePageChange = page => {
        setPageNumber(page);
    }

    function toPages(pages) {
        const results = [];

        for (let i = 1; i <= pages; i++) {
            results.push(i);
        }

        return results;
    }

    // RDT exposes the following internal pagination properties
    const BootyPagination = ({
        onChangePage,
        currentPage
    }) => {
        const handleBackButtonClick = () => {
            onChangePage(currentPage - 1);
        };

        const handleNextButtonClick = () => {
            onChangePage(currentPage + 1);
        };

        const handlePageNumber = (e) => {
            onChangePage(Number(e.target.value));
        };

        const pages = employees.last_page;
        const pageItems = toPages(pages);
        const nextDisabled = currentPage === employees.last_page;
        const previosDisabled = currentPage === 1;

        return (
            <div className='table-pagination d-flex justify-content-between align-items-center pb-0'>
                <p className="entries">{employees.from} - {employees.to} {t('of')} {employees.total} {t('entries')}</p>
                <ul className="pagination ">
                    <li className="page-item">
                        <button
                            className="page-link"
                            onClick={handleBackButtonClick}
                            disabled={previosDisabled}
                            aria-disabled={previosDisabled}
                            aria-label="previous page"
                        >
                            <i className='ph ph-caret-double-left text-primary text-xl'></i>
                        </button>
                    </li>

                    <li className="page-item">
                        <button
                            className="page-link"
                            onClick={handleBackButtonClick}
                            disabled={previosDisabled}
                            aria-disabled={previosDisabled}
                            aria-label="previous page"
                        >
                            <i className='ph ph-caret-left text-primary text-xl'></i>
                        </button>
                    </li>

                    {employees?.links.map((page) => {
                        //const className = (parseInt(page.label) === currentPage ? "page-item active" : "page-item");
                        const className = (page.active === true ? "page-item active" : "page-item");
                        return (
                            <li key={page.label} className={className + ' ' + (parseInt(page.label) || page.label == '...' ? '' : 'd-none')}>
                                <button
                                    className="page-link"
                                    onClick={page.label != '...' ? handlePageNumber : ''}
                                    value={page.label}
                                    disabled={page.label == '...' ? true : false}
                                >
                                    {page.label}
                                </button>
                            </li>
                        );
                    })}

                    <li className="page-item">
                        <button
                            className="page-link"
                            onClick={handleNextButtonClick}
                            disabled={nextDisabled}
                            aria-disabled={nextDisabled}
                            aria-label="next page"
                        >
                            <i className='ph ph-caret-right text-primary text-xl'></i>
                        </button>
                    </li>

                    <li className="page-item">
                        <button
                            className="page-link"
                            onClick={handleNextButtonClick}
                            disabled={nextDisabled}
                            aria-disabled={nextDisabled}
                            aria-label="next page"
                        >
                            <i className='ph ph-caret-double-right text-primary text-xl'></i>
                        </button>
                    </li>
                </ul>
            </div>
        );
    };

    return (
        <>
            {/*
            <div className="d-flex align-items-center mb-3 position-relative" style={{ maxWidth: '280px' }}>
                <i
                    className="ph ph-magnifying-glass position-absolute start-0 ms-3"
                    style={{
                        color: '#4D586E',
                        fontSize: '20px',
                        top: '50%',
                        transform: 'translateY(-50%)'
                    }}
                ></i>
                <input
                    autoComplete="off"
                    className="form-control ps-5"
                    placeholder={t("Search...")}
                    value={searchKey}
                    onChange={(e) => setSearchKey(e.target.value)}
                />
            </div>
            */}
            <DataTable
                columns={tableHeadings}
                data={employees?.data}
                //noDataComponent={t('There are no records to display')}
                noDataComponent={
                    <div className="d-flex align-items-center justify-content-center py-3" style={{ height: "100%" }}>
                        <div className="text-center">
                            <i className='ph ph-notepad data-table-no-record-icon'></i>
                            <p className="text-secondary text-md m-0">{t('There are no records to display')}</p>
                        </div>
                    </div>
                }
                className='data-table'
                pagination
                highlightOnHover
                paginationComponentOptions={{ noRowsPerPage: true }}
                paginationServer
                paginationTotalRows={employees?.total}
                onChangePage={handlePageChange}
                paginationComponent={BootyPagination}
            />
        </>
    );
}

export default EmployeeIndexTable;
