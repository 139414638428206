import { React, useState } from "react";
import { useTranslation } from 'react-i18next';

import SmtpIndex from "pages/email/smtp/SmtpIndex";
import Email from "./Email";
import OTP from "./OTP";

function EmailSetting() {
    const { t } = useTranslation();
    const [smtp, setSmtp] = useState('index');
    return (
        <>
            <div className="card">
                <div className="card-header">
                    <ul class="nav nav-tabs border-0 gap-2" id="pills-tab" role="tablist" style={{ background: 'none' }}>
                        <li class="nav-item" role="presentation">
                            <button class="px-2 text-primary text-md nav-link active" id="pills-home-tabe" data-bs-toggle="pill" data-bs-target="#pills-homee" type="button" role="tab" aria-controls="pills-homee" aria-selected="true">E-Mail</button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button class="px-2 text-primary text-md nav-link" id="pills-profile-tabe" data-bs-toggle="pill" data-bs-target="#pills-profilee" type="button" role="tab" aria-controls="pills-profilee" aria-selected="false">SMTP Setup</button>
                        </li>
                        <li class="nav-item" role="presentation" style={{ maxWidth: '250px' }}>
                            <button class="px-2 text-primary text-md nav-link" id="pills-contact-tabe" data-bs-toggle="pill" data-bs-target="#pills-contactt" type="button" role="tab" aria-controls="pills-contactt" aria-selected="false">Verification Code</button>
                        </li>
                    </ul>
                </div>
                <div className="card-body">
                    <div class="tab-content" id="pills-tabContent">
                        <div class="tab-pane show active fade" id="pills-homee" role="tabpanel" aria-labelledby="pills-home-tabe" tabindex="0">
                            <h6 className="title mb-2">{t('Edit E-Mail Text')}</h6>
                            <Email />
                        </div>
                        <div class="tab-pane fade" id="pills-profilee" role="tabpanel" aria-labelledby="pills-profile-tabe" tabindex="0">
                            <SmtpIndex />
                        </div>
                        <div class="tab-pane fade" id="pills-contactt" role="tabpanel" aria-labelledby="pills-contact-tabe" tabindex="0">
                            <OTP />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default EmailSetting;
