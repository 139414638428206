import { useMemo, useEffect, useState, useRef } from "react";
import { useTranslation } from 'react-i18next'
// @mui material components
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import PropTypes from "prop-types";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import colors from "assets/theme/base/colors";
import borders from "assets/theme/base/borders";

import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

import callFetch from "../../../helpers/callFetch";
import { useForm } from "react-hook-form";
import { NumericFormat } from 'react-number-format';
import LoadingCard from "components/LoadingCard";

function TermineQouteTable(props) {
  const { t } = useTranslation()

  const {
    register,
    handleSubmit,
    setError,
    setValue,
    getValues,
    formState: { errors },
  } = useForm();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [refresh, setRefresh] = useState(0);
  //const [users, setUsers] = useState([]);
  const [filterData, setFilterData] = useState({
    verkaufer: 0,
    created_at: 'All',
  });

  const getMonthName = (monthNumber) => {
    const date = new Date();
    date.setMonth(monthNumber - 1);

    // Using the browser's default locale.
    return date.toLocaleString([], { month: 'long' });
  }

  useEffect(() => {
    if (!props?.filterData?.created_at) return;

    if (props?.filterData?.created_at != filterData?.created_at) {
      var data = filterData;
      data.created_at = props?.filterData?.created_at;
      setFilterData(data);
      setRefresh(refresh + 1)
    }
  }, [props?.filterData?.created_at]);


  useEffect(() => {
    setLoading(true);
    callFetch("statistics/termine-qoute-table", "POST", filterData, setError).then((res) => {
      setData(res.data);
      setLoading(false);
      if (!res.ok) return;
    });
  }, [refresh]);


  function PagesBodyCell({ rows, noBorder, color, sx, width, align }) {
    const { light } = colors;
    const { borderWidth } = borders;

    const renderRows = rows.map((row, index) => {
      return (
        <SoftBox
          key={row}
          component="td"
          xs={{ alignItems: "center", textAlign: "center" }}
          /*
          borderBottom={
            noBorder ? "none" : `${borderWidth[1]} solid ${light.main}`
          }
          */
          width={width && width[index] ? width[index] : "max-content"}
          textAlign={align && align[index] ? align[index] : "center"}
          pt={1}
          pb={1}
          pl={5}
          pr={5}
        // alignItems="center"
        >
          <SoftTypography
            display="block"
            variant="button"
            fontWeight="medium"
            color={color ? color : "dark"}
          >
            {row}
          </SoftTypography>
        </SoftBox>
      );
    });

    return <TableRow sx={sx} style={{ width: '100%', display: 'flex' }}>{renderRows}</TableRow>;
  }

  // Setting default values for the props for PagesBodyCell
  PagesBodyCell.defaultProps = {
    noBorder: false,
  };

  // Typechecking props for the PagesBodyCell
  PagesBodyCell.propTypes = {
    rows: PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    ).isRequired,
    noBorder: PropTypes.bool,
  };

  return (
    <>
      {loading ? (
        <LoadingCard
          sx={{ height: '440px' }}
        />
      ) : (
        <>
          <div className="card">
            <div className="card-header">
              <h3 className='title'>{t('Termine Qoute Table')}</h3>
            </div>
            <div className="card-body pb-3">
              <div class="table-responsive border border-radius">
                <table className="table">
                  <thead>
                    <tr>
                      <th >{t('Monat')}</th>
                      <th >{t("Termine 'nicht Bezahlt'")}</th>
                      <th >{t("Termine 'werden Bezahlt'")}</th>
                      <th >{t("Termine Q.")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.projects ? data?.projects.map((tr, index) => (
                      <>
                        <tr>
                          <td>{getMonthName(tr?.month)}</td>
                          <td>{(tr?.total_termine_unpaid > 0 ? tr?.total_termine_unpaid : '--')}</td>
                          <td>{(tr?.total_termine_paid > 0 ? tr?.total_termine_paid : '--')}</td>
                          <td>{(
                            Number.isInteger(((tr?.total_termine_paid / tr?.total_termine_unpaid) * 100)) ?

                              ((tr?.total_termine_paid / tr?.total_termine_unpaid) * 100) + '%' :
                              <>
                                {((tr?.total_termine_paid / tr?.total_termine_unpaid) * 100) ? (
                                  <NumericFormat
                                    value={((tr?.total_termine_paid / tr?.total_termine_unpaid) * 100)}
                                    displayType="text"
                                    thousandSeparator={"."}
                                    decimalSeparator=","
                                    decimalScale={2}
                                    fixedDecimalScale
                                    suffix={'%'}
                                  />
                                ) : '--'}
                              </>
                          )}
                          </td>
                        </tr>
                      </>
                    )) : ''}
                  </tbody>
                  <tfoot
                    style={{
                      borderTop: '2px solid #E7EBF3',
                      background: '#F8F9FA'
                    }}
                  >
                    <tr>
                      <td>{"In Total"}</td>
                      <td>{(data?.total?.total_termine_unpaid > 0 ? data?.total?.total_termine_unpaid : '--')}</td>
                      <td>{(data?.total?.total_termine_paid > 0 ? data?.total?.total_termine_paid : '--')}</td>
                      <td>{(
                        data?.total?.percentage ? (
                          <NumericFormat
                            value={data?.total?.percentage}
                            displayType="text"
                            thousandSeparator={"."}
                            decimalSeparator=","
                            decimalScale={2}
                            fixedDecimalScale
                            suffix={'%'}
                          />
                        ) : '--')}
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default TermineQouteTable;
