import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Navigate, useParams } from "react-router-dom";
import { callFetch } from "../../../../helpers/callFetch";
import { useTranslation } from "react-i18next";
import dateFormat from "dateformat";
import flatpickr from "flatpickr";
import Cookies from "js-cookie";

import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftDropzone from "components/SoftDropzone";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import SoftSnackbar from "components/SoftSnackbar";
// @mui material components
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

import EmailForm from "../EmailForm";
import DateForm from "./DateForm";
import Timeline from "../Timeline";

import { NumericFormat } from "react-number-format";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Overview from "../../Overview";
import OrderSign from "./OrderSign";

function getSteps() {
  return ["1. Order", "2. Digital Sign"];
}

function Order(props) {
  let params = useParams();
  const { t } = useTranslation();
  const errorNotify = () => toast("Please create Qoatation first");
  const errorNotify2 = () => toast("Angebot Bundle Deactive");
  const [errorData, setErrorData] = useState(false);
  const [currency, setCurrency] = useState("EUR");
  /*
    const [quationId, setQuationId] = useState(props.quationId);
    const [quation, setQuation] = useState({
        gesami_netto: 0,
        gesami_brutto: 0,
    });
    */

  const [activeStep, setActiveStep] = useState(0);
  // const steps = getSteps();
  const [contactPersons, setContactPersons] = useState([]);
  const [data, setData] = useState([]);
  const [emails, setEmails] = useState([
    { name: "", email: "", currentEmail: "" },
  ]);
  const [items, setItems] = useState([
    { id: 0, title: "", quantity: 0, price: 0, currentPrice: 0, total: 0 },
  ]);

  const truncateText = (text, length = 14) =>  text.length > length ? text.slice(0, length) + "..." : text;

  const [itemSummery, setItemSummery] = useState({
    items: [],
    emails: [],
    subTotal: 0,
    discountPercentage: 0,
    discount: 0,
    taxPercentage: 20,
    tax: 0,
    total: 0,
  });
  //const [order, setOrder] = useState(0);
  const [saving, setSaving] = useState(false);
  //const [fileUploading, setFileUploading] = useState(false);
  // const [submitSuccess, setSubmitSuccess] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const [refresh2, setRefresh2] = useState(0);
  const [refresh3, setRefresh3] = useState(0);
  const [refresh4, setRefresh4] = useState(0);
  const [currentAttachmentTab, setCurrentAttachmentTab] =
    useState("dachansicht");

  const [attachments, setAttachment] = useState([]);
  const [dachansicht, setDachansicht] = useState([]);
  const [dachsparren, setDachsparren] = useState([]);
  const [dachgiebel, setDachgiebel] = useState([]);
  const [gesamtansicht_von_haus_und_dach, setGesamtansicht_von_haus_und_dach] =
    useState([]);
  const [innendachansicht, setInnendachansicht] = useState([]);
  const [kompletter_zahlerschrank, setKompletter_zahlerschrank] = useState([]);
  const [zahler, setZahler] = useState([]);
  const [wechselrichter_standort, setWechselrichter_standort] = useState([]);
  const [bei_nachust, setBei_nachust] = useState([]);
  const [ziegel_mit_meterstab, setZiegel_mit_meterstab] = useState([]);

  const {
    register,
    handleSubmit,
    setError,
    setValue,
    getValues,
    clearErrors,
    formState: { errors },
  } = useForm();

  const handeleAttachment = (name, newData) => {
    //clearErrors();
    clearErrors(name);

    if (name == "dachansicht") {
      let data = dachansicht;
      data[dachansicht.length] = newData;
      setDachansicht(data);
      setValue(name, JSON.stringify(data));
    } else if (name == "dachsparren") {
      let data = dachsparren;
      data[dachsparren.length] = newData;
      setDachsparren(data);
      setValue(name, JSON.stringify(data));
    } else if (name == "dachgiebel") {
      let data = dachgiebel;
      data[dachgiebel.length] = newData;
      setDachgiebel(data);
      setValue(name, JSON.stringify(data));
    } else if (name == "gesamtansicht_von_haus_und_dach") {
      let data = gesamtansicht_von_haus_und_dach;
      data[gesamtansicht_von_haus_und_dach.length] = newData;
      setGesamtansicht_von_haus_und_dach(data);
      setValue(name, JSON.stringify(data));
    } else if (name == "innendachansicht") {
      let data = innendachansicht;
      data[innendachansicht.length] = newData;
      setInnendachansicht(data);
      setValue(name, JSON.stringify(data));
    } else if (name == "kompletter_zahlerschrank") {
      let data = kompletter_zahlerschrank;
      data[kompletter_zahlerschrank.length] = newData;
      setKompletter_zahlerschrank(data);
      setValue(name, JSON.stringify(data));
    } else if (name == "zahler") {
      let data = zahler;
      data[zahler.length] = newData;
      setZahler(data);
      setValue(name, JSON.stringify(data));
    } else if (name == "wechselrichter_standort") {
      let data = wechselrichter_standort;
      data[wechselrichter_standort.length] = newData;
      setWechselrichter_standort(data);
      setValue(name, JSON.stringify(data));
    } else if (name == "bei_nachust") {
      let data = bei_nachust;
      data[bei_nachust.length] = newData;
      setBei_nachust(data);
      setValue(name, JSON.stringify(data));
    } else if (name == "ziegel_mit_meterstab") {
      let data = ziegel_mit_meterstab;
      data[ziegel_mit_meterstab.length] = newData;
      setZiegel_mit_meterstab(data);
      setValue(name, JSON.stringify(data));
    }
  };

  const removeAttachment = (id) => {
    const beforeRemove = eval(currentAttachmentTab);
    const afterRemove = beforeRemove.filter((value) => {
      return value.id != id;
    });
    if (currentAttachmentTab == "dachansicht") {
      setDachansicht(afterRemove);
    } else if (currentAttachmentTab == "dachsparren") {
      setDachsparren(afterRemove);
    } else if (currentAttachmentTab == "dachgiebel") {
      setDachgiebel(afterRemove);
    } else if (currentAttachmentTab == "gesamtansicht_von_haus_und_dach") {
      setGesamtansicht_von_haus_und_dach(afterRemove);
    } else if (currentAttachmentTab == "innendachansicht") {
      setInnendachansicht(afterRemove);
    } else if (currentAttachmentTab == "kompletter_zahlerschrank") {
      setKompletter_zahlerschrank(afterRemove);
    } else if (currentAttachmentTab == "zahler") {
      setZahler(afterRemove);
    } else if (currentAttachmentTab == "wechselrichter_standort") {
      setWechselrichter_standort(afterRemove);
    } else if (currentAttachmentTab == "bei_nachust") {
      setBei_nachust(afterRemove);
    } else if (currentAttachmentTab == "ziegel_mit_meterstab") {
      setZiegel_mit_meterstab(afterRemove);
    }
    setValue(currentAttachmentTab, JSON.stringify(afterRemove));
  };

  useEffect(() => {
    var subTotal = 0;
    items.map((item) => {
      subTotal += item.total;
    });
    itemSummery.subTotal = subTotal;
    itemSummery.tax = parseFloat(
      (subTotal * (itemSummery.taxPercentage / 100)).toFixed(2)
    );
    itemSummery.discount = parseFloat(
      (subTotal * (itemSummery.discountPercentage / 100)).toFixed(2)
    );
    itemSummery.total = subTotal - itemSummery.discount + itemSummery.tax;
    itemSummery.items = items;
    setItemSummery(itemSummery);
    setRefresh2(refresh2 + 1);
  }, [refresh, props?.projectid ? props?.projectid : params.id]);

  useEffect(() => {}, [refresh2]);

  useEffect(() => {
    if (!(props?.projectid ? props?.projectid : params.id)) return;

    flatpickr(".flat-pickr");
    callFetch(
      "project/order/" +
        (props?.projectid ? props?.projectid : params.id) +
        "/edit",
      "GET",
      []
    ).then((res) => {
      setData(res.data); 
      console.log(res.data); 
      return;
    });
  }, [refresh3, props?.projectid ? props?.projectid : params.id]);

  useEffect(() => {
    if (!data.id) return;

    if (data?.quations?.order) {
      //update order
      for (let [key, value] of Object.entries(data?.quations?.order)) {
        if (key == "order_date") {
          setValue(key, dateFormat(value, "yyyy-mm-dd"));
        } else if (key == "netto" || key == "brutto") {
          setValue(key, Number(value));
        } else {
          setValue(key, value == null || value == "null" ? "" : value);
        }
      }

      setValue("quation_id", data?.quations?.id);
      setValue("status", 1);

      setDachansicht(
        data?.quations?.order?.dachansicht &&
          data?.quations?.order?.dachansicht != null &&
          data?.quations?.order?.dachansicht != "null"
          ? JSON.parse(data?.quations?.order?.dachansicht)
          : []
      );
      setDachsparren(
        data?.quations?.order?.dachsparren &&
          data?.quations?.order?.dachsparren != null &&
          data?.quations?.order?.dachsparren != "null"
          ? JSON.parse(data?.quations?.order?.dachsparren)
          : []
      );
      setDachgiebel(
        data?.quations?.order?.dachgiebel &&
          data?.quations?.order?.dachgiebel != null &&
          data?.quations?.order?.dachgiebel != "null"
          ? JSON.parse(data?.quations?.order?.dachgiebel)
          : []
      );
      setGesamtansicht_von_haus_und_dach(
        data?.quations?.order?.gesamtansicht_von_haus_und_dach &&
          data?.quations?.order?.gesamtansicht_von_haus_und_dach != null &&
          data?.quations?.order?.gesamtansicht_von_haus_und_dach != "null"
          ? JSON.parse(data?.quations?.order?.gesamtansicht_von_haus_und_dach)
          : []
      );
      setInnendachansicht(
        data?.quations?.order?.innendachansicht &&
          data?.quations?.order?.innendachansicht != null &&
          data?.quations?.order?.innendachansicht != "null"
          ? JSON.parse(data?.quations?.order?.innendachansicht)
          : []
      );
      setKompletter_zahlerschrank(
        data?.quations?.order?.kompletter_zahlerschrank &&
          data?.quations?.order?.kompletter_zahlerschrank != null &&
          data?.quations?.order?.kompletter_zahlerschrank != "null"
          ? JSON.parse(data?.quations?.order?.kompletter_zahlerschrank)
          : []
      );
      setZahler(
        data?.quations?.order?.zahler &&
          data?.quations?.order?.zahler != null &&
          data?.quations?.order?.zahler != "null"
          ? JSON.parse(data?.quations?.order?.zahler)
          : []
      );
      setWechselrichter_standort(
        data?.quations?.order?.wechselrichter_standort &&
          data?.quations?.order?.wechselrichter_standort != null &&
          data?.quations?.order?.wechselrichter_standort != "null"
          ? JSON.parse(data?.quations?.order?.wechselrichter_standort)
          : []
      );
      setBei_nachust(
        data?.quations?.order?.bei_nachust &&
          data?.quations?.order?.bei_nachust != null &&
          data?.quations?.order?.bei_nachust != "null"
          ? JSON.parse(data?.quations?.order?.bei_nachust)
          : []
      );
      setZiegel_mit_meterstab(
        data?.quations?.order?.ziegel_mit_meterstab &&
          data?.quations?.order?.ziegel_mit_meterstab != null &&
          data?.quations?.order?.ziegel_mit_meterstab != "null"
          ? JSON.parse(data?.quations?.order?.ziegel_mit_meterstab)
          : []
      );

      let pd =
        data?.quations?.order?.product_details &&
        data?.quations?.order?.product_details != null &&
        data?.quations?.order?.product_details != "null"
          ? JSON.parse(data?.quations?.order?.product_details)
          : [];
      //let cd = data?.quations?.order?.contact_person  && data?.quations?.order?.contact_person != null && data?.quations?.order?.contact_person != 'null' ? JSON.parse(data?.quations?.order?.customer.contact_person) : [];
      //setContactPersons(cd);
      //setQuationId(data?.quations?.order?.quation_id);
      //setEmails(pd.emails);
      setItems(pd.items);
      setItemSummery(pd);
    } else if (data?.quations && data?.bundleDeactive === false) {
      setValue("quation_id", data?.quations?.id);
      setValue("status", 1);

      if (Number(data?.quations?.netto_brotto) == 1) {
        // 1 means netto
        //setQuation(res.data.quation);
        setValue("netto", Number(data?.quations?.gesami_netto));
        setValue("brutto", Number(data?.quations?.gesami_netto));
        setValue("tax", 0); // by default tax will be 0%
      } else if (Number(data?.quations?.netto_brotto) == 0) {
        // 0 means brotto
        // setQuation(res.data.quation);
        setValue("netto", Number(data?.quations?.gesami_netto));
        setValue("brutto", Number(data?.quations?.gesami_brutto));
      }
      setRefresh2(refresh2 + 1);
    } else {
      if (data?.bundleDeactive === true) {
        //alert('Angebot bundle deactive')
        errorNotify2();
      } else {
        //alert('Please create Qoatation first')
        errorNotify();
      }

      setErrorData(true);

      if (document.getElementById("distribution_li")) {
        document.getElementById("distribution_li").click();
      }

      if (document.getElementById("distribution")) {
        document.getElementById("distribution").click();
      }

      if (document.getElementById("AngeboteSalesTab")) {
        document.getElementById("AngeboteSalesTab").click();
      }
    }
  }, [data, props?.projectid ? props?.projectid : params.id]);

  function deleteProduct() {
    var titems = [];
    items.map((t) => {
      if (!t) return;
      titems.push(t);
    });
    setItems(titems);
    setRefresh(refresh + 1);
  }

  function deleteEmail() {
    var temails = [];
    emails.map((e) => {
      if (!e && e !== "") return;
      temails.push(e);
    });
    setEmails(temails);
    itemSummery.emails = temails;
    setItemSummery(itemSummery);
    setRefresh2(refresh2 + 1);
  }

  const fileUploadTab = (name) => {
    setCurrentAttachmentTab(name);
  };

  const dropZoneInitialize = () => {
    return (
      <SoftDropzone
        options={{
          dictDefaultMessage: "",
          // addRemoveLinks: true,
          acceptedFiles: ".jpeg,.jpg,.png,.pdf,.doc,.docx",
          action: Cookies.get("api_url") + "attachment",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + Cookies.get("token"),
          },
          processing: function () {
            document.body.classList.add("loading");
          },
          success: (file, response) => {
            document.body.classList.remove("loading");
            if (response.message == "success") {
              handeleAttachment(currentAttachmentTab, response.data);
            }
          },
          error: function (file, response) {
            document.body.classList.remove("loading");
          },
        }}
      />
    );
  };

  const [successSB, setSuccessSB] = useState(false);
  const openSuccessSB = () => setSuccessSB(true);
  const closeSuccessSB = () => setSuccessSB(false);
  const [errorSB, setErrorSB] = useState(false);
  const closeErrorSB = () => setErrorSB(false);
  const [freigbenErrorMessage, setFreigbenErrorMessage] = useState(
    "Bitte geben Sie ein Wunschtermin ein andernfalls ist die DC Freigabe nicht möglich."
  );
  const [dcFreigbenErrorSB, setDcFreigbenErrorSB] = useState(false);
  const openDcFregebenErrorSB = () => setDcFreigbenErrorSB(true);
  const closDcFregebenErrorsSB = () => setDcFreigbenErrorSB(false);

  const renderSuccessSB = (
    <SoftSnackbar
      color="success" 
      title={t("Success")}
      //content={t("Changes saved successfully")}
      dateTime={t("")}
      open={successSB}
      onClose={closeSuccessSB}
      close={closeSuccessSB} 
    />
  );

  const renderErrorSB = (
    <SoftSnackbar
      color="info" 
      title={t("Create the order first")}
      //content={t("Changes saved successfully")}
      dateTime={t("")}
      open={errorSB}
      onClose={() => closeErrorSB(false)}
      close={() => closeErrorSB(false)} 
    />
  );

  const renderDcFreigebenErrorSB = (
    <SoftSnackbar
      color="error" 
      title={t(freigbenErrorMessage)}
      //content={t("Changes saved successfully")}
      dateTime={t("")}
      open={dcFreigbenErrorSB}
      onClose={closDcFregebenErrorsSB}
      close={closDcFregebenErrorsSB} 
    />
  );

  const onSubmit = (formData) => {
    setSaving(true);

    var loadingElement = document.querySelector(
      ".res-options .btn-primary-save"
    );

    if (loadingElement) {
      loadingElement.classList.add("btn-secondary");
      loadingElement.innerHTML = t("wird geladen...");
    }

    formData.total = 0;
    formData.product_details = JSON.stringify(itemSummery);
    formData.projectid = props?.projectid ? props?.projectid : params.id;

    callFetch(
      data?.quations?.order?.id
        ? "project/order/" + (props?.projectid ? props?.projectid : params.id)
        : "project/order",
      "POST",
      formData,
      setError
    ).then((res) => {
      setTimeout(() => {
        setSaving(false);

        if (loadingElement) {
          loadingElement.classList.remove("btn-secondary");
          loadingElement.innerHTML = t("Save");
        }

        if (document.getElementById("main-content")) {
          document.body.scrollTop = 0; // For Safari
          document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
          document.getElementById("main-content").scrollTop = 0;
        }

        if (res?.message == "success") {
          openSuccessSB();
          setRefresh2(refresh2 + 1);
          setRefresh3(refresh3 + 1);
        }
      }, 2000);
    });
  };

  useEffect(() => {
    setRefresh2(refresh2 + props.refresh);
  }, [props.refresh]);

  const [currentStep, setCurrentStep] = useState(0);

  const steps = ["Step 1", "Step 2", "Step 3"];

 

  return (
    <>
      <div className="row">
        <div className="col-sm-12 col-md-12 col-lg-8 col-xl-8 col-xxl-9">
          <div className="mt-3">
            <div className="step-progress plr-7rem">
              <div className="step-wrapper cursor-pointer" onClick={()=>setCurrentStep(0)}>
                <div
                  className={`step ${currentStep >= 0 ? "completed" : ""}`}
                  id="step-1"
                >
                  {currentStep > 0 ? <i className="fa fa-check"></i> : "1"}
                </div>
                <div
                  className={`step-line ${currentStep > 0 ? "completed" : ""}`}
                  id="line-1"
                >
                  <span
                    className={`${
                      currentStep == 0 ? "" : "d-none-xs-cp-progress"
                    }`}
                  >
                    Auftrag
                  </span>
                </div>
              </div>

              <div className="step-wrapper cursor-pointer" style={{ flexGrow: "0" }} onClick={()=>setCurrentStep(1)}>
                <div
                  className={`step ${currentStep >= 1 ? "completed" : ""}`}
                  id="step-2"
                >
                  {currentStep > 1 ? <i className="fa fa-check"></i> : "2"}
                </div>
                <div>
                  <span
                    className={`${
                      currentStep == 1 ? "" : "d-none-xs-cp-progress"
                    }`}
                  >
                    Digital Unterschreiben
                  </span>
                </div>
              </div>
            </div>

            <div className="step-card">
              {currentStep === 0 && (
                <div className="step-content">
                  {/* <h3>Step 1</h3> */}
                  <div className="card  inner-scroll-two  sc-pb">
                    <div className="card-body pb-3">
                      <div
                        className="accordion accordion-flush"
                        id="accordionFlushExample"
                      >
                        <div className="accordion-item bg-secondary p-3 border-radius-8">
                          <h2 className="accordion-header">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#flush-collapseOne"
                              aria-expanded="false"
                              aria-controls="flush-collapseOne"
                            >
                              <span className="accordion-arrow">
                                <i className="ph ph-caret-right"></i>
                              </span>
                              <p className="font-size-20 font-weight-600 text-primary">
                                <span className="pl-2">Kundendaten</span>
                              </p>
                            </button>
                          </h2>
                          <div
                            id="flush-collapseOne"
                            className="accordion-collapse collapse"
                            data-bs-parent="#accordionFlushExample"
                          >
                            <div className="accordion-body p-0">
                              {props?.overview != false ? (
                                <>
                                  <Overview
                                    bg="white"
                                    quation_id={data?.quations?.id}
                                    project_id={
                                      props?.projectid
                                        ? props?.projectid
                                        : params.id
                                    }
                                    title={t("Quotation")}
                                  ></Overview>
                                </>
                              ) : (
                                ""
                              )}

                              <div className="row mt-3">
                                <div className="col-sm-6 mb-1">
                                  <div className="card box-shadow-none">
                                    <div className="card-body pb-3">
                                      <p className="text-secondary font-size-14">
                                        Angebotsnr
                                      </p>
                                      <hr />
                                      {data?.allquations
                                      ? data?.allquations.map((quation, i) => (
                                      <div className="d-flex justify-content-between align-items-center mb-1">
                                        <p className="text-brand-primary font-size-14 mb-0 font-weight-600">
                                          {quation?.id}
                                        </p>
                                        <div>
                                          <span className="badge badge-info me-3">
                                            Angebot
                                          </span>
                                          <span className="text-secondary font-size-14 ml-3">
                                            {dateFormat( new Date(), "dd.mm.yyyy")}
                                          </span>
                                        </div>
                                      </div>
                                      )) : ""}
                                    </div>
                                  </div>
                                </div>
                                <div className="col-sm-6 mb-1">
                                  <div className="card box-shadow-none">
                                    <div className="card-body pb-3">
                                      <div className="d-flex justify-content-between align-items-center">
                                        <p className="text-secondary font-size-14 mb-0">
                                          {t("Projektnr.")}
                                        </p>
                                        <a className="text-brand-primary font-size-14">
                                          <i class="ph ph-info font-size-16 position-relative top-2"></i>{" "}
                                          Überblick
                                        </a>
                                      </div>
                                      <hr />
                                      <div className="d-flex justify-content-between align-items-center">
                                        <p className="text-brand-primary font-size-14 mb-0 font-weight-600">
                                          {params.id}
                                        </p>
                                        <div>
                                          <span className="badge badge-info me-3">
                                            Project
                                          </span>
                                          <span className="text-secondary font-size-14 ml-3">
                                            14.11.2024
                                          </span>
                                        </div>
                                      </div>
                                      {/* <div className="d-flex justify-content-between align-items-center mt-1">
                                    <p className="text-brand-primary font-size-14 mb-0 font-weight-600">
                                      5000010
                                    </p>
                                    <div>
                                      <span className="badge badge-primary me-3">
                                        Angebot
                                      </span>
                                      <span className="text-secondary font-size-14 ml-3">
                                        14.11.2024
                                      </span>
                                    </div>
                                  </div> */}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        className="accordion accordion-flush mt-3"
                        id="accordionFlushExamplee"
                      >
                        <div className="accordion-item bg-secondary p-3 border-radius-8">
                          <h2 className="accordion-header">
                            <button
                              className="accordion-button"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#flush-collapseTwo"
                              aria-expanded="false"
                              aria-controls="flush-collapseTwo"
                            >
                              <span className="accordion-arrow">
                                <i className="ph ph-caret-right"></i>
                              </span>
                              <p className="font-size-20 font-weight-600 text-primary">
                                <span className="pl-2">{t("New Order")}</span>
                              </p>
                            </button>
                          </h2>
                          <div
                            id="flush-collapseTwo"
                            className="accordion-collapse collapse show"
                            data-bs-parent="#accordionFlushExamplee"
                          >
                            <div className="accordion-body p-0">
                              <div className="row g-3">
                                <div className="col-md-6">
                                  <label className="text-disabled">
                                    {t("Order Nr.")} *
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder={t("eg: 400001")}
                                    {...register("id", {
                                      required: false,
                                    })}
                                    readOnly
                                  />
                                  <div className="invalid-feedback">
                                    {errors.id && errors.id.message}
                                  </div>
                                </div>

                                <div className="col-md-3 d-none">
                                  <div className="form-group">
                                    <label>{t("Angebot")} *</label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder={t("eg: 400001")}
                                      {...register("quation_id", {
                                        required: true,
                                      })}
                                      readOnly
                                      required
                                    />
                                    <div className="invalid-feedback">
                                      {errors.quation_id &&
                                        errors.quation_id.message}
                                    </div>
                                  </div>
                                </div>

                                <div className="col-md-4 d-none">
                                  <div className="form-group">
                                    <label>{t("Currency")} *</label>
                                    <br />
                                    <select
                                      className="form-control"
                                      {...register("currency", {
                                        required: true,
                                      })}
                                      onChange={(e) => {
                                        setCurrency(e.target.value);
                                      }}
                                      required
                                    >
                                      <option value="EUR">EUR (€)</option>
                                    </select>
                                    <div className="invalid-feedback">
                                      {errors.currency &&
                                        errors.currency.message}
                                    </div>
                                  </div>
                                </div>

                                <div className="col-md-6">
                                  <label>{t("Order Date")} *</label>
                                  <input
                                    type="date"
                                    className="form-control mb-3 flat-pickr"
                                    placeholder={t("eg. 2001-03-20")}
                                    defaultValue={dateFormat(
                                      new Date(),
                                      "yyyy-mm-dd"
                                    )}
                                    {...register("order_date", {
                                      required: true,
                                    })}
                                    required
                                  />
                                  <div className="invalid-feedback">
                                    {errors.order_date &&
                                      errors.order_date.message}
                                  </div>
                                </div>
                              </div>
                              <div className="card ">
                                <div className="card-body pb-3">
                                  <form
                                    className={`needs-validation ${
                                      Object.keys(errors).length
                                        ? "was-validated"
                                        : ""
                                    }`}
                                    onSubmit={handleSubmit(onSubmit)}
                                    noValidate
                                    autoComplete="off"
                                  >
                                    {data?.quations?.order?.id ? (
                                      <input
                                        type="hidden"
                                        defaultValue="PUT"
                                        {...register("_method")}
                                      />
                                    ) : (
                                      ""
                                    )}

                                    <input
                                      type="hidden"
                                      {...register("status")}
                                    />

                                    <div className="row">
                                      <div className="col-md-4">
                                        <ul
                                          className="order_upload_tabs"
                                          id="order_upload_tabs"
                                        >
                                          <li
                                            className={
                                              currentAttachmentTab ==
                                              "dachansicht"
                                                ? "active"
                                                : ""
                                            }
                                            onClick={() => {
                                              fileUploadTab("dachansicht");
                                            }}
                                          >
                                            {t("Hausansicht Front")}
                                          </li>
                                          <li
                                            className={
                                              currentAttachmentTab ==
                                              "dachsparren"
                                                ? "active"
                                                : ""
                                            }
                                            onClick={() => {
                                              fileUploadTab("dachsparren");
                                            }}
                                          >
                                            {t("Hausansicht Front 2")}
                                          </li>
                                          <li
                                            className={
                                              currentAttachmentTab ==
                                              "dachgiebel"
                                                ? "active"
                                                : ""
                                            }
                                            onClick={() => {
                                              fileUploadTab("dachgiebel");
                                            }}
                                          >
                                            {t("Hausansicht Taufhöhe")}
                                          </li>
                                          <li
                                            className={
                                              currentAttachmentTab ==
                                              "gesamtansicht_von_haus_und_dach"
                                                ? "active"
                                                : ""
                                            }
                                            onClick={() => {
                                              fileUploadTab(
                                                "gesamtansicht_von_haus_und_dach"
                                              );
                                            }}
                                          >
                                            {t("Hausansicht Rückseite")}
                                          </li>
                                          <li
                                            className={
                                              currentAttachmentTab ==
                                              "innendachansicht"
                                                ? "active"
                                                : ""
                                            }
                                            onClick={() => {
                                              fileUploadTab("innendachansicht");
                                            }}
                                          >
                                            {t("Innendachansicht")}
                                          </li>
                                          <li
                                            className={
                                              currentAttachmentTab ==
                                              "kompletter_zahlerschrank"
                                                ? "active"
                                                : ""
                                            }
                                            onClick={() => {
                                              fileUploadTab(
                                                "kompletter_zahlerschrank"
                                              );
                                            }}
                                          >
                                            {t("Kompletter Zählerschrank")}
                                          </li>
                                          <li
                                            className={
                                              currentAttachmentTab == "zahler"
                                                ? "active"
                                                : ""
                                            }
                                            onClick={() => {
                                              fileUploadTab("zahler");
                                            }}
                                          >
                                            {t("Zähler")}
                                          </li>
                                          <li
                                            className={
                                              currentAttachmentTab ==
                                              "wechselrichter_standort"
                                                ? "active"
                                                : ""
                                            }
                                            onClick={() => {
                                              fileUploadTab(
                                                "wechselrichter_standort"
                                              );
                                            }}
                                          >
                                            {t("Wechselrichter-Standort")}
                                          </li>
                                          <li
                                            className={
                                              currentAttachmentTab ==
                                              "bei_nachust"
                                                ? "active"
                                                : ""
                                            }
                                            onClick={() => {
                                              fileUploadTab("bei_nachust");
                                            }}
                                          >
                                            {t("Hausanschlusskasten")}
                                          </li>
                                          <li
                                            className={
                                              currentAttachmentTab ==
                                              "ziegel_mit_meterstab"
                                                ? "active"
                                                : ""
                                            }
                                            onClick={() => {
                                              fileUploadTab(
                                                "ziegel_mit_meterstab"
                                              );
                                            }}
                                          >
                                            {t("Ziegel mit Meterstab")}
                                          </li>
                                        </ul>
                                        {errors.dachansicht &&
                                        errors.dachansicht.message ? (
                                          <div className="invalid-feedback d-block font-weight-600">
                                            {t(
                                              "Bild erforderlich - Hausansicht Front"
                                            )}
                                          </div>
                                        ) : errors.dachsparren &&
                                          errors.dachsparren.message ? (
                                          <div className="invalid-feedback d-block font-weight-600">
                                            {t(
                                              "Bild erforderlich - Hausansicht Front 2"
                                            )}
                                          </div>
                                        ) : errors.dachgiebel &&
                                          errors.dachgiebel.message ? (
                                          <div className="invalid-feedback d-block font-weight-600">
                                            {t(
                                              "Bild erforderlich - Hausansicht Taufhöhe"
                                            )}
                                          </div>
                                        ) : errors.gesamtansicht_von_haus_und_dach &&
                                          errors.gesamtansicht_von_haus_und_dach
                                            .message ? (
                                          <div className="invalid-feedback d-block font-weight-600">
                                            {t(
                                              "Bild erforderlich - Hausansicht Rückseite"
                                            )}
                                          </div>
                                        ) : errors.innendachansicht &&
                                          errors.innendachansicht.message ? (
                                          <div className="invalid-feedback d-block font-weight-600">
                                            {t(
                                              "Bild erforderlich - Innendachansicht"
                                            )}
                                          </div>
                                        ) : errors.kompletter_zahlerschrank &&
                                          errors.kompletter_zahlerschrank
                                            .message ? (
                                          <div className="invalid-feedback d-block font-weight-600">
                                            {t(
                                              "Bild erforderlich - Kompletter Zählerschrank"
                                            )}
                                          </div>
                                        ) : errors.zahler &&
                                          errors.zahler.message ? (
                                          <div className="invalid-feedback d-block font-weight-600">
                                            {t("Bild erforderlich - Zähler")}
                                          </div>
                                        ) : errors.wechselrichter_standort &&
                                          errors.wechselrichter_standort
                                            .message ? (
                                          <div className="invalid-feedback d-block font-weight-600">
                                            {t(
                                              "Bild erforderlich - Wechselrichter-Standort"
                                            )}
                                          </div>
                                        ) : errors.bei_nachust &&
                                          errors.bei_nachust.message ? (
                                          <div className="invalid-feedback d-block font-weight-600">
                                            {t(
                                              "Bild erforderlich - Hausanschlusskasten"
                                            )}
                                          </div>
                                        ) : errors.ziegel_mit_meterstab &&
                                          errors.ziegel_mit_meterstab
                                            .message ? (
                                          <div className="invalid-feedback d-block font-weight-600">
                                            {t(
                                              "Bild erforderlich - Ziegel mit Meterstab"
                                            )}
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                      <div className="col-md-8">
                                        <SoftBox>
                                          <SoftBox
                                            display="flex"
                                            flexDirection="column"
                                            justifyContent="flex-end"
                                            height="100%"
                                          >
                                            {dropZoneInitialize()}
                                          </SoftBox>
                                        </SoftBox>

                                        <SoftBox
                                          pt={3}
                                          className="order-processing"
                                        >
                                          <Grid container spacing={3}>
                                            {eval(currentAttachmentTab) ? (
                                              eval(currentAttachmentTab).map(
                                                function (data, i) {
                                                  return (
                                                    <Grid
                                                      item
                                                      key={i}
                                                      className="text-center pl-0 position-relative"
                                                    >
                                                      <div>
                                                        <a
                                                          target="_blank"
                                                          download
                                                          href={
                                                            Cookies.get(
                                                              "backend_url"
                                                            ) +
                                                            "storage/attachments/" +
                                                            data.attachment
                                                          }
                                                        >
                                                          <img
                                                            src={
                                                              Cookies.get(
                                                                "backend_url"
                                                              ) +
                                                              "storage/attachments/" +
                                                              data.attachment
                                                            }
                                                          />
                                                        </a>
                                                      </div>
                                                      <a
                                                        className="dz-remove-attachment"
                                                        onClick={() => {
                                                          removeAttachment(
                                                            data.id
                                                          );
                                                        }}
                                                      >
                                                        <i class="ph ph-x"></i>
                                                      </a>
                                                    </Grid>
                                                  );
                                                }
                                              )
                                            ) : (
                                              <>
                                                <Grid></Grid>
                                              </>
                                            )}
                                          </Grid>
                                        </SoftBox>
                                      </div>
                                    </div>

                                    <div className="row mt-4">
                                      <div className="col-md-12 mt-0 mb-1">
                                        <div className="form-check">
                                          <label>{t("Gerüst Notwendig")}</label>
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            onChange={(e) => {
                                              if (
                                                data.quations.order === null
                                              ) {
                                                data.quations.order = {};
                                              }

                                              if (e.target.checked === true) {
                                                setValue(
                                                  "gerust_notwendig",
                                                  "JA"
                                                );
                                                data.quations.order.gerust_notwendig =
                                                  "JA";
                                              } else {
                                                setValue(
                                                  "gerust_notwendig",
                                                  "NEIN"
                                                );
                                                data.quations.order.gerust_notwendig =
                                                  "NEIN";
                                              }
                                              setData(data);
                                              setRefresh4(refresh4 + 3);
                                            }}
                                            checked={
                                              data?.quations?.order
                                                ?.gerust_notwendig === "JA"
                                                ? true
                                                : false
                                            }
                                          />
                                        </div>
                                      </div>

                                      <div className="col-md-12 mt-0 mb-1">
                                        <div className="form-check">
                                          <label>
                                            {t("Freileitung Isolierung")}
                                          </label>
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            onChange={(e) => {
                                              if (
                                                data.quations.order === null
                                              ) {
                                                data.quations.order = {};
                                              }

                                              if (e.target.checked === true) {
                                                setValue(
                                                  "frealeatung_isolierung",
                                                  "JA"
                                                );
                                                data.quations.order.frealeatung_isolierung =
                                                  "JA";
                                              } else {
                                                setValue(
                                                  "frealeatung_isolierung",
                                                  "NEIN"
                                                );
                                                data.quations.order.frealeatung_isolierung =
                                                  "NEIN";
                                              }
                                              setData(data);
                                              setRefresh4(refresh4 + 3);
                                            }}
                                            checked={
                                              data?.quations?.order
                                                ?.frealeatung_isolierung ===
                                              "JA"
                                                ? true
                                                : false
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>

                                    <div className="mt-4">
                                      <div
                                        className="row"
                                        style={{ padding: "0rem 0.875rem" }}
                                      >
                                        <div className="col-md-12 border border-radius-8">
                                          <div className="row">
                                            <div className="col-md-6"></div>

                                            <div className="col-md-6 align-center">
                                              <div className="d-flex justify-content-between align-items-center">
                                                <p className="text-secondary font-size-14 mb-0">
                                                  {t("Netto")}
                                                </p>
                                                <p className="text-primary font-size-14 mb-0">
                                                  <div
                                                    className="input-group align-center"
                                                    style={{
                                                      padding: "6px 0px",
                                                    }}
                                                  >
                                                    <input
                                                      style={{
                                                        height: "32px",
                                                        width: "140px",
                                                      }}
                                                      type="text"
                                                      {...register("netto", {
                                                        required: true,
                                                        onChange: (e) => {
                                                          setValue(
                                                            "brutto",
                                                            e.target.value
                                                              ? e.target.value
                                                              : 0
                                                          );
                                                          setRefresh(
                                                            refresh + 1
                                                          );
                                                        },
                                                      })}
                                                      className="form-control me-2"
                                                    />
                                                    <div className="input-group-append align-center">
                                                      <span
                                                        className=" font-size-14"
                                                        style={{
                                                          borde: "none",
                                                        }}
                                                      >
                                                        {currency == "EUR"
                                                          ? " € "
                                                          : " $ "}
                                                      </span>
                                                    </div>
                                                  </div>
                                                </p>
                                              </div>
                                              {/* <label className="d-block text-end font-size-14">
                                                {t("Netto")}
                                              </label> */}
                                            </div>
                                          </div>

                                          <div className="row border-top">
                                            <div className="col-md-6"></div>
                                            <div className="col-md-6 border-end align-center">
                                              <div className="d-flex justify-content-between align-items-center">
                                                <p className="text-secondary font-size-14 mb-0">
                                                  {t("Tax")}
                                                </p>
                                                <p
                                                  className="text-primary font-size-14 mb-0"
                                                  style={{ padding: "6px 0px" }}
                                                >
                                                  <input
                                                    style={{
                                                      width: "70px",
                                                      height: "32px",
                                                    }}
                                                    type="text"
                                                    {...register("tax", {
                                                      required: true,
                                                    })}
                                                    className="form-control"
                                                    readOnly
                                                  />
                                                </p>
                                                <p className="text-primary font-size-14 mb-0">
                                                  <NumericFormat
                                                    value={
                                                      getValues("brutto") -
                                                      getValues("netto")
                                                    }
                                                    displayType="text"
                                                    thousandSeparator={"."}
                                                    decimalSeparator=","
                                                    decimalScale={2}
                                                    fixedDecimalScale
                                                    suffix={
                                                      currency == "EUR"
                                                        ? " € "
                                                        : " $ "
                                                    }
                                                  />
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="row"
                                            style={{
                                              borderTop: "1px solid #E7EBF3",
                                              padding: "7px 0px",
                                            }}
                                          >
                                            <div className="col-md-6 align-center"></div>
                                            <div className="col-md-6 align-center">
                                              <div className="d-flex justify-content-between align-items-center">
                                                <p className="text-secondary font-size-14 mb-0">
                                                  {t("Gesamtsumme")}
                                                </p>
                                                <p className="text-primary font-size-14 mb-0">
                                                  <NumericFormat
                                                    value={getValues("brutto")}
                                                    displayType="text"
                                                    thousandSeparator={"."}
                                                    decimalSeparator=","
                                                    decimalScale={2}
                                                    fixedDecimalScale
                                                    suffix={
                                                      currency == "EUR"
                                                        ? " € "
                                                        : " $ "
                                                    }
                                                  />
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    {emails &&
                                      emails.map((email, i) => (
                                        <div
                                          key={i}
                                          className="row mt-3 d-none"
                                        >
                                          <div className="col-md-4">
                                            <label>{t("Name")}</label>
                                            <input
                                              type="text"
                                              className="form-control d-inline-block mt-1"
                                              value={email.name}
                                              onChange={(e) => {
                                                emails[i].name = e.target.value;
                                                setEmails(emails);
                                                itemSummery.emails = emails;
                                                setItemSummery(itemSummery);
                                                setRefresh2(refresh2 + 1);
                                              }}
                                              placeholder="Name"
                                            />
                                          </div>

                                          <div className="col-md-4">
                                            <label>{t("Email")}</label>
                                            <input
                                              type="email"
                                              style={{ width: "93%" }}
                                              className="form-control d-inline-block mt-1"
                                              value={email.email}
                                              onChange={(e) => {
                                                emails[i].email =
                                                  e.target.value;
                                                setEmails(emails);
                                                itemSummery.emails = emails;
                                                setItemSummery(itemSummery);
                                                setRefresh2(refresh2 + 1);
                                              }}
                                              placeholder="eg. email@mail.com"
                                            />
                                            &nbsp;
                                            <i
                                              className="fa-solid fa-circle-xmark text-danger"
                                              data-key={i}
                                              onClick={() => {
                                                delete emails[i];
                                                deleteEmail();
                                              }}
                                            ></i>
                                            <br />
                                          </div>
                                        </div>
                                      ))}
                                    <p
                                      className="btn btn-dark mt-2 d-none"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        setEmails([
                                          ...emails,
                                          {
                                            name: "",
                                            email: "",
                                            currentEmail: "",
                                          },
                                        ]);
                                        setRefresh2(refresh2 + 1);
                                      }}
                                    >
                                      <i className="fa-solid fa-circle-plus"></i>
                                      &nbsp;
                                      {t("Add Contact Person")}
                                    </p>

                                    <div className="row mt-3 d-none">
                                      <div className="col-md-12">
                                        {!saving && errorData == false && (
                                          <button
                                            type="submit"
                                            className="btn btn-primary"
                                            id="saveOrderBtn"
                                          >
                                            {t("Save")}
                                          </button>
                                        )}
                                        {saving && (
                                          <button
                                            type="button"
                                            className="btn btn-secondary"
                                            disabled
                                          >
                                            {t("wird geladen...")}
                                          </button>
                                        )}

                                        {data?.quations?.order?.id ? (
                                          <>
                                            <a
                                              target={"_blank"}
                                              rel="noreferrer"
                                              href={
                                                Cookies.get("backend_url") +
                                                "order/" +
                                                data?.quations?.order?.id
                                              }
                                              className="btn btn-outline-dark ms-2"
                                            >
                                              {t("Order PDF")}
                                            </a>

                                            {data?.dc_product == "1" &&
                                            data?.ac_product == "1" ? (
                                              <button
                                                type="button"
                                                className={
                                                  data?.quations?.order
                                                    ?.dc_approve == 1
                                                    ? "btn btn-danger ms-2"
                                                    : "btn btn-outline-danger ms-2"
                                                }
                                                onClick={() => {
                                                  if (data?.desired_assembly) {
                                                    setValue("dc_approve", 1);
                                                    document
                                                      .getElementById(
                                                        "saveOrderBtn"
                                                      )
                                                      .click();
                                                  } else {
                                                    setFreigbenErrorMessage(
                                                      "Bitte geben Sie ein Wunschtermin ein andernfalls ist die DC Freigabe nicht möglich."
                                                    );
                                                    openDcFregebenErrorSB();
                                                    console.log(
                                                      "please input data?.desired_assembly"
                                                    );
                                                  }
                                                }}
                                              >
                                                {data?.quations?.order
                                                  ?.dc_approve == 1
                                                  ? t("DC Approved")
                                                  : t("DC Approve")}
                                              </button>
                                            ) : (
                                              ""
                                            )}

                                            {data?.dc_product == "1" &&
                                            data?.ac_product == "0" ? (
                                              <button
                                                type="button"
                                                className={
                                                  data?.quations?.order
                                                    ?.dc_beschaffung_approve ==
                                                  1
                                                    ? "btn btn-danger ms-2"
                                                    : "btn btn-outline-danger ms-2"
                                                }
                                                onClick={() => {
                                                  if (data?.desired_assembly) {
                                                    setValue(
                                                      "dc_beschaffung_approve",
                                                      1
                                                    );
                                                    document
                                                      .getElementById(
                                                        "saveOrderBtn"
                                                      )
                                                      .click();
                                                  } else {
                                                    setFreigbenErrorMessage(
                                                      "Bitte geben Sie ein Wunschtermin ein andernfalls ist die DC Beschaffung Fregegeben nicht möglich."
                                                    );
                                                    openDcFregebenErrorSB();
                                                    console.log(
                                                      "please input data?.desired_assembly"
                                                    );
                                                  }
                                                }}
                                              >
                                                {data?.quations?.order
                                                  ?.dc_beschaffung_approve == 1
                                                  ? t(
                                                      "DC Beschaffung Fregegeben"
                                                    )
                                                  : t(
                                                      "DC Beschaffung Freigeben"
                                                    )}
                                              </button>
                                            ) : (
                                              ""
                                            )}

                                            {data?.dc_product == "0" &&
                                            data?.ac_product == "1" ? (
                                              <button
                                                type="button"
                                                className={
                                                  data?.quations?.order
                                                    ?.ac_beschaffung_approve ==
                                                  1
                                                    ? "btn btn-success ms-2"
                                                    : "btn btn-outline-success ms-2"
                                                }
                                                onClick={() => {
                                                  if (data?.desired_assembly) {
                                                    setValue(
                                                      "ac_beschaffung_approve",
                                                      1
                                                    );
                                                    document
                                                      .getElementById(
                                                        "saveOrderBtn"
                                                      )
                                                      .click();
                                                  } else {
                                                    setFreigbenErrorMessage(
                                                      "Bitte geben Sie ein Wunschtermin ein andernfalls ist die AC Beschaffung Fregegeben nicht möglich."
                                                    );
                                                    openDcFregebenErrorSB();
                                                    console.log(
                                                      "please input data?.desired_assembly"
                                                    );
                                                  }
                                                }}
                                              >
                                                {data?.quations?.order
                                                  ?.ac_beschaffung_approve == 1
                                                  ? t(
                                                      "AC Beschaffung Fregegeben"
                                                    )
                                                  : t(
                                                      "AC Beschaffung Freigeben"
                                                    )}
                                              </button>
                                            ) : (
                                              ""
                                            )}

                                            <button
                                              type="button"
                                              className="btn btn-secondary ms-2"
                                              onClick={() => {
                                                document
                                                  .getElementById("digitalSign")
                                                  .click();
                                                document.body.scrollTop = 0; // For Safari
                                                document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
                                                document.getElementById(
                                                  "main-content"
                                                ).scrollTop = 0;
                                              }}
                                            >
                                              {t("Unterschreiben")}
                                            </button>
                                          </>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    </div>
                                  </form>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {currentStep === 1 && (
                <div className="step-content">
                  <div className="col-sm-12 col-xl-12">
                    <div className="card inner-scroll-two sc-pb">
                      <div className="card-body">
                        <OrderSign></OrderSign>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        {props?.sidebar != false ? (
          <div className="col-sm-12 col-md-12 col-lg-4 col-xl-4 col-xxl-3 pl-4 project-details-right-side">
            <div className="card  inner-right-siderbar-scroll sc-pb">
              <div className="card-body">
                <div className="row">
                  <div className="col-sm-12">
                    {data?.quations?.order?.id ? (
                      <button
                        className="btn btn-light position-relative btn-brand-hover"
                        onClick={() => {
                          setCurrentStep(1);
                        }}
                        style={{
                          width: "100%",
                          textAlign: "left",
                        }}
                      >
                        {t("Unterschreiben")}
                        <span className="btn-icon-right">
                          <i class="ph ph-caret-right"></i>
                        </span>
                      </button>
                    ) : (
                      <button
                        className="btn btn-light position-relative btn-brand-hover"
                        onClick={() => {
                          setErrorSB(true);
                        }}
                        style={{ width: "100%", textAlign: "left" }}
                      >
                        {t("Unterschreiben")}
                        <span className="btn-icon-right">
                          <i class="ph ph-caret-right"></i>
                        </span>
                      </button>
                    )}
                  </div>
                  <div className="col-sm-12">
                    {data?.dc_product == "1" && data?.ac_product == "1" ? (
                      <button
                        className="mb-2 d-flex align-items-center btn btn-light position-relative btn-brand-hover"
                        onClick={() => {
                          if (data?.desired_assembly) {
                            setValue("dc_approve", 1);
                            document.getElementById("saveOrderBtn").click();
                          } else {
                            setFreigbenErrorMessage(
                              "Bitte geben Sie ein Wunschtermin ein andernfalls ist die DC Freigabe nicht möglich."
                            );
                            openDcFregebenErrorSB();
                            console.log("please input data?.desired_assembly");
                          }
                        }}
                        style={{ width: "100%", textAlign: "left" }}
                      >
                        {data?.quations?.order?.dc_approve == 1
                          ? <><i class="ph ph-check font-size-20 me-2"></i> {t("DC Approved")}</> 
                          : t("DC Approve")}
                        <span className="btn-icon-right">
                          <i class="ph ph-caret-right"></i>
                        </span>
                      </button>
                    ) : (
                      ""
                    )}

                    {data?.dc_product == "1" && data?.ac_product == "0" ? (
                      <button
                        type="button"
                        className={
                          data?.quations?.order?.dc_beschaffung_approve == 1
                            ? "btn btn-danger"
                            : "btn btn-outline-danger"
                        }
                        onClick={() => {
                          if (data?.desired_assembly) {
                            setValue("dc_beschaffung_approve", 1);
                            document.getElementById("saveOrderBtn").click();
                          } else {
                            setFreigbenErrorMessage(
                              "Bitte geben Sie ein Wunschtermin ein andernfalls ist die DC Beschaffung Fregegeben nicht möglich."
                            );
                            openDcFregebenErrorSB();
                            console.log("please input data?.desired_assembly");
                          }
                        }}
                        style={{ width: "100%" }}
                      >
                        {data?.quations?.order?.dc_beschaffung_approve == 1
                          ? t("DC Beschaffung Fregegeben")
                          : t("DC Beschaffung Freigeben")}
                      </button>
                    ) : (
                      ""
                    )}

                    {data?.dc_product == "0" && data?.ac_product == "1" ? (
                      <button
                        type="button"
                        className={
                          data?.quations?.order?.ac_beschaffung_approve == 1
                            ? "btn btn-success"
                            : "btn btn-outline-success"
                        }
                        onClick={() => {
                          if (data?.desired_assembly) {
                            setValue("ac_beschaffung_approve", 1);
                            document.getElementById("saveOrderBtn").click();
                          } else {
                            setFreigbenErrorMessage(
                              "Bitte geben Sie ein Wunschtermin ein andernfalls ist die AC Beschaffung Fregegeben nicht möglich."
                            );
                            openDcFregebenErrorSB();
                            console.log("please input data?.desired_assembly");
                          }
                        }}
                        style={{ width: "100%" }}
                      >
                        {data?.quations?.order?.ac_beschaffung_approve == 1
                          ? t("AC Beschaffung Fregegeben")
                          : t("AC Beschaffung Freigeben")}
                      </button>
                    ) : (
                      ""
                    )}

                    <hr />
                  </div>


                  <div className="col-sm-12">
                      <p className="font-size-16 font-weight-500 text-primary">
                        Auftrag Nr.
                      </p> 
                            <div 
                              className={"mb-2 d-flex justify-content-between align-items-center border-primary px-2 py-1 border-radius-5 project-details-right-side btn-brand-hover-with-border"}
                            >
                              <p className="text-brand-primary font-size-14 font-weight-500 mb-0">
                                {data?.quations?.order?.id}
                              </p>
                              <a className="profile w-50">
                                <img
                                  src="/assets/img/placeholder.png"
                                  className="border-primary"
                                  alt="Profile"
                                />
                                <span className="font-weight-500 font-size-14 text-capitalize">
                                  {data?.quations?.order?.user?.name}
                                </span>
                              </a>
                            </div> 
                      <hr />
                    </div>

                 

                  <div class="details">
                    <h6 className="text-primary font-size-16 font-weight-500">
                      Projektinformationen
                    </h6>

                    <div className="mb-2">
                      <p className="font-size-14 text-secondary mb-0">
                        <i class="ph ph-clipboard-text font-size-20"></i>{" "}
                        <span className="position-relative bottom-4 left-4">
                          Vertrieb
                        </span>
                      </p>
                      <div class="profile w-50">
                        <img
                          src="/assets/img/placeholder.png"
                          className="border-primary"
                          alt="Profile"
                        />
                        <span>{data?.salse_person?.name && truncateText(data?.salse_person?.name)}</span>
                      </div>
                    </div>
                    <div className="mb-2">
                      <p className="font-size-14 text-secondary mb-0">
                        <i className="icon-sola-user-circle-gear"></i>{" "}
                        <span className="position-relative bottom-4 left-4">
                          Lead Owner
                        </span>
                      </p>
                      <div class="profile w-50">
                        <img
                          src="/assets/img/placeholder.png"
                          className="border-primary"
                          alt="Profile"
                        />
                        <span>{data?.user?.name && truncateText(data?.user?.name)}</span>
                      </div>
                    </div>
                    <hr />
                  </div>

                 

                  {/* <div className="col-12">
                    <div className=" mb-3">
                      <div className=" p-3">
                        <div className="d-flex">
                          <div>
                            <div className="icon icon-shape bg-gradient-dark text-center border-radius-md">
                              <i
                                className="ni ni-money-coins text-lg opacity-10"
                                aria-hidden="true"
                              />
                            </div>
                          </div>
                          <div className="ms-3">
                            <div className="numbers">
                              <p className="text-sm mb-0 text-capitalize font-weight-bold">
                                {t("Order Nr.")}
                              </p>
                              <h5 className="font-weight-bolder mb-0">
                                {data?.quations?.order?.id ?? "---"}
                              </h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className=" mb-3">
                      <div className=" p-3">
                        <div className="d-flex">
                          <div>
                            <div className="icon icon-shape bg-gradient-dark text-center border-radius-md">
                              <i
                                className="ni ni-money-coins text-lg opacity-10"
                                aria-hidden="true"
                              />
                            </div>
                          </div>
                          <div className="ms-3">
                            <div className="numbers">
                              <p className="text-sm mb-0 text-capitalize font-weight-bold">
                                {t("Creator")}
                              </p>
                              <h5 className="font-weight-bolder mb-0">
                                {data?.quations?.order?.user?.name ?? "-"}
                              </h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>

                <DateForm
                  projectid={props?.projectid ? props?.projectid : params.id}
                ></DateForm>

                <div className=" mb-3">
                  <div className="pb-0">
                    <h6>{t("Email")}</h6>
                  </div>
                  <div className="">
                    <EmailForm
                      id={data?.quations?.order?.id}
                      type={"auftrag"}
                      email={data?.email}
                      projectid={
                        props?.projectid ? props?.projectid : params.id
                      }
                      pdf={true}
                    />
                  </div>
                </div>
                <Timeline refresh={refresh2} />
              </div>
            </div>
          </div>
        ) : (
          ""
        )}

        <div
          className="fixed-bottom mb-3 fixed-bottom-width-available pe-3" 
        >
          <div className="card">
            <div className="card-body text-end d-flex justify-content-end">
              {data?.quations?.id ? (
                <>
                  {JSON.parse(Cookies.get("permissions")).indexOf("PDU") !==
                  -1 ? (
                    <>
                      <a
                        className="btn btn-default border-primary me-3 text-brand-primary d-flex"
                        target={"_blank"}
                        rel="noreferrer"
                        href={
                            Cookies.get("backend_url") +
                            "order/" +
                            data?.quations?.order?.id
                          }
                      >
                      <i class="ph ph-file-pdf font-size-20 me-2"></i>  {t("Order PDF")}
                      </a>
                    </>
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <></>
              )}

              {!saving ? <button
                className="btn btn-primary"
                type="button"
                onClick={currentStep == 0 ? () => document.getElementById("saveOrderBtn").click() : () => document.getElementById("saveSign").click()}
              >
               {currentStep == 0 ? t("Create Invoice") : t("Save")} 
              </button> : <button
                className="btn btn-disabled"
                type="button"
                disabled
              > 
                {currentStep == 0 ? t("Creating Invoice...") : t("Save")} 
              </button>}
              
            </div>
          </div>
        </div>
        {renderSuccessSB}
        {renderErrorSB}
        {renderDcFreigebenErrorSB}
      </div>
      <ToastContainer />

      <button
        className="d-none"
        id="order-refresh-btn"
        onClick={() => setRefresh3(refresh3 + 1)}
      ></button>
    </>
  );
}

export default Order;
