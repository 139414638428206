import { React, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Navigate } from "react-router-dom";
import { callFetch } from "../../helpers/callFetch";
import { useTranslation } from 'react-i18next'
import TypeModal from './TypeModal';
import { NavLink } from 'react-router-dom'
import FileUploader from 'components/FileUploader/index';

function Create() {
    const { t } = useTranslation();
    const [saving, setSaving] = useState(false);
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [refresh, setRefresh] = useState(0);
    const [refresh2, setRefresh2] = useState(0);
    const [lieferanten, setlieferanten] = useState([]);
    const [speicherTypes, setSpeicherTypes] = useState([]);
    const [selectedFile, setSelectedFile] = useState(null);

    const {
        register,
        handleSubmit,
        setError,
        clearErrors,
        setValue,
        formState: { errors },
    } = useForm();

    useEffect(() => {
        callFetch("speicher/create", "GET", [], setError).then((res) => {
            if (!res.ok) return;
            setValue('speicher_identity', res?.data?.identity)
            setlieferanten(res?.data?.lieferanten)
        });
    }, [refresh]);

    useEffect(() => {
        callFetch("speicherTypes", "GET", []).then((res) => {
            setSpeicherTypes(res?.data);
        });
    }, [refresh2])

    const onSubmit = (formData) => {
        let formDataWithFiles = new FormData();

        Object.keys(formData).forEach((key) => {
            formDataWithFiles[key] = formData[key];
        });

        if (selectedFile.length > 0) {
            selectedFile.forEach((file, index) => {
                formDataWithFiles[`files[${index}]`] = file;
            });
        }

        setSaving(true);
        callFetch("speicher", "POST", formDataWithFiles, setError).then((res) => {
            setSaving(false);
            if (!res.ok) return;
            setSubmitSuccess(true);
        });
    };

    return submitSuccess ? <Navigate to='/products/speicher' /> :
        <>
            <form className={`needs-validation ${Object.keys(errors).length ? "was-validated" : ""}`} onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
                <div className="row" style={{ paddingBottom: '100px' }}>
                    <div className="col-md-8">
                        <div className="card">
                            <div className="card-header">
                                <h6 className="title">{t('Create Speicher')}</h6>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>{t('Speichernr.')} *</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder={t('')}
                                                {...register("speicher_identity", {
                                                    required: true,
                                                })}
                                                required
                                                readOnly
                                            />
                                            <div className="invalid-feedback">{errors.name && errors.name.message}</div>
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>{t('Hersteller / Lieferanten')} *</label>
                                            <select
                                                className="form-control"
                                                {...register("lieferanten_id", {
                                                    required: true,
                                                })}
                                                required
                                            >
                                                <option value={''}>{t("Select Lieferanten")}</option>
                                                {lieferanten.map((lief) => (
                                                    <option value={lief?.id}>{lief?.lieferanten_nummer + (lief?.firma_name ? ' - ' + lief?.firma_name : '')}</option>
                                                ))}
                                            </select>
                                            <div className="invalid-feedback">{errors.lieferanten_id && errors.lieferanten_id.message}</div>
                                        </div>
                                    </div>

                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label>{t('Speicher Bezeichnung')} *</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder={t('')}
                                                {...register("name", {
                                                    required: true,
                                                })}
                                                required
                                            />
                                            <div className="invalid-feedback">{errors.name && errors.name.message}</div>
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>{t('Typ')} *</label>
                                            <select
                                                className="form-control"
                                                {...register("type", {
                                                    required: true,
                                                })}
                                                required
                                            >
                                                <option value="">--</option>
                                                {speicherTypes && speicherTypes.map((speicherType) => (
                                                    <option key={speicherType.id} value={speicherType.id}>{speicherType.name}</option>
                                                ))}
                                            </select>
                                            <div className="invalid-feedback">{errors.type && errors.type.message}</div>
                                        </div>

                                        <a class="btn-link btn-plus-icon btn-icon-primary" data-bs-toggle="modal" data-bs-target="#speicherTypeModal">{t('Typ Hinzufügen')}</a>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>{t('kWh')} *</label>
                                            <input
                                                type="number"
                                                className="form-control"
                                                placeholder={t('')}
                                                {...register("watt", {
                                                    required: true,
                                                })}
                                                step={0.01}
                                                required
                                            />
                                            <div className="invalid-feedback">{errors.watt && errors.watt.message}</div>
                                        </div>
                                    </div>

                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label>{t('Description')}</label>
                                            <textarea
                                                className="form-control"
                                                rows="4"
                                                placeholder={t('')}
                                                {...register("description")}></textarea>
                                            <div className="invalid-feedback">{errors.description && errors.description.message}</div>
                                        </div>
                                    </div>

                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <div className="form-check">
                                                <label>{t('Ohne Energiespeicher')}</label>
                                                <input className="form-check-input" type="checkbox" value={"Yes"} {...register("without_energiespeichersystem")} />
                                            </div>
                                            <div className="invalid-feedback">{errors.without_energiespeichersystem && errors.without_energiespeichersystem.message}</div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                    <div className="col-md-4 mt-3 mt-md-0">
                        <FileUploader
                            supportedFileType={['png', 'jpg', 'jpeg', 'pdf']}
                            maxFileSizeMB={10}//Mb 
                            multiple={true}
                            onChange={(files, type) => {
                                if (type === 'files') {
                                    setSelectedFile(files)
                                }
                            }}
                        />
                    </div>
                </div>

                <div className="card fixed-bottom mb-3 fixed-bottom-width-available" >
                    <div className="card-footer d-flex align-items-center justify-content-end gap-2">
                        <NavLink to="/products/speicher" className="btn btn-secondary m-0 d-flex align-items-center justify-content-between gap-2">
                            <i className='ph ph-caret-left'></i>
                            {t('Back')}
                        </NavLink>

                        {saving ? (
                            <button type="button" className="btn btn-disabled m-0" disabled>
                                {t('Saving ...')}
                            </button>
                        ) : (
                            <button type="submit" className="btn btn-primary m-0">
                                {t('Save')}
                            </button>
                        )}
                    </div>
                </div>
            </form>

            <TypeModal refreshParent={() => setRefresh2(refresh2 + 1)} />
        </>;
}

export default Create;