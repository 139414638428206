import React from "react";
import { NavLink, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import IndexTable from "./IndexTable";
import IndexTableSalesPersion from "./IndexTableSalesPersion";
import Cookies from "js-cookie";
import * as XLSX from "xlsx";
import Create from "./Create";
function Index() {
  const { t } = useTranslation();
  let { type } = useParams();
  return (
    <>
      <div className="card mb-4">
        <div className='card-header d-flex align-items-center justify-content-between gap-3'>
          <h3 className='title'>{t("All Teams")}</h3>
          <div className='d-flex align-items-center justify-content-end gap-3'>
            {JSON.parse(Cookies.get("permissions")).indexOf("EC") !==
              -1 ? (
              <a
                data-bs-toggle="modal" data-bs-target="#createTeam"
                className="btn btn-primary m-0 d-flex align-items-center justify-content-between gap-2"
              >
                <i className='ph ph-plus'></i>
                {t("Add Team")}
              </a>
            ) : (
              <></>
            )}
          </div>
        </div>
        <div className="card-body pb-3">
          {type == "sales" || type == "resell" ? (
            <IndexTableSalesPersion type={type} />
          ) : (
            <IndexTable type={type} />
          )}
        </div>
      </div>

      {/* <ImportModal/> */}
      <div>
        <Create />
      </div>
    </>
  );
}

export default Index;
