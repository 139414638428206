import { React, useState, useEffect, useRef } from "react";
import { NavLink, useLocation } from "react-router-dom";
import Cookies from 'js-cookie';
import callFetch from "../helpers/callFetch";
import { t } from "i18next";
import Moment from "react-moment";
import moment from 'moment';
import 'moment/locale/de';
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import Search from './Search'
import SidenavCard from "components/SidenavCard";

moment.locale('de'); // Set the locale globally to German

function TopNav() {
    const [user, setUser] = useState(JSON.parse(Cookies.get('user')));
    const [refresh, setRefresh] = useState(0);
    const [refreshTwo, setRefreshTwo] = useState(0);
    const [participations, setParticipations] = useState([]);
    const [unreadMessage, setunreadMessage] = useState(0);
    const [notifications, setNotifications] = useState([]);
    const [unreadNotification, setUnreadNotification] = useState(0);
    const route = useLocation().pathname.split("/").slice(1);

    useEffect(() => {
        callFetch("chat-participation", "GET", []).then((res) => {
            setParticipations(res.contacts || []);
            setunreadMessage(res?.unread_message || 0)
        });

    }, [refresh]);

    useEffect(() => {
        callFetch("topnav-notifications", "GET", []).then((res) => {
            setNotifications(res.data);
            setUnreadNotification(res.unread_notifications);
        });

    }, [refreshTwo]);

    useEffect(() => {
        const interval = setInterval(() => {
            setRefresh(prev => prev + 1);
        }, 30000);
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            setRefreshTwo(prev => prev + 1);
        }, 300000);
        return () => clearInterval(interval);
    }, []);


    function doSignout(e) {
        e.preventDefault();

        callFetch('signout', 'POST', [], null).then(res => {
            Cookies.remove('user');
            Cookies.remove('token');
            window.location.href = process.env.REACT_APP_FRONTEND_URL;
        });
    }

    return (
        <>
            <nav className="navbar navbar-main navbar-expand-lg mt-3 top-1 px-0 mx-3 border-radius position-sticky blur shadow-blur left-auto z-index-sticky top-navbar" id="navbarBlur" navbar-scroll="true">
                <div className="container-fluid py-1 px-3">

                    <h1 className="h1 m-0">{decodeURIComponent(route[route.length - 1])}</h1>

                    <div className="collapse navbar-collapse" id="navbar">
                        <ul className="ms-auto navbar-nav d-flex align-items-center gap-3">
                            <li className="nav-item d-xl-none mx-3 d-flex align-items-center mobile-menu-icon" style={{ position: 'absolute', left: '-0px', top: '28px' }}>
                                <a className="nav-link" id="iconNavbarSidenav">
                                    <div className="sidenav-toggler-inner">
                                        <i className="sidenav-toggler-line" />
                                        <i className="sidenav-toggler-line" />
                                        <i className="sidenav-toggler-line" />
                                    </div>
                                </a>
                            </li>

                            <li className="nav-item">
                                <div className="dropdown">
                                    <Search />
                                </div>
                            </li>

                            <li className="nav-item">
                                <div className="dropdown">
                                    <a href="message" className="nav-link" data-bs-toggle="dropdown" aria-expanded="false">
                                        <i className="ph ph-chat-teardrop-text top-nav-icon ">
                                            {unreadMessage > 0 && (
                                                <SoftBox
                                                    component="i"
                                                    display="inline-block"
                                                    width={"8px"}
                                                    height={"8px"}
                                                    borderRadius="50%"
                                                    bgColor={'#FF465B'}
                                                    ml={-1}
                                                />
                                            )}
                                        </i>
                                    </a>
                                    <div className="dropdown-menu res-dropdown-menu dropdown-menu-end">
                                        <div className="card">
                                            <div class="card-header border-bottom">
                                                <h3 className="title text-lg">Messages</h3>
                                            </div>
                                            <div className="card-body">
                                                {participations ? participations.map((participation) => (
                                                    <>
                                                        <NavLink to={'/chat/' + participation.id + '/' + participation?.name}>
                                                            <SoftBox
                                                                display="flex"
                                                                justifyContent="space-between"
                                                                alignItems="top"
                                                                gap={1}
                                                                borderRadius="8px"
                                                                sx={{
                                                                    backgroundColor: '#F8F9FA',
                                                                    padding: '8px',
                                                                    height: '60px',
                                                                    '&:hover': {
                                                                        backgroundColor: 'var(--brand-light)',
                                                                        color: 'var(--gray-900)',
                                                                    },
                                                                }}
                                                                mb={1}
                                                            >
                                                                <SoftBox display="flex" alignItems="center">
                                                                    <img
                                                                        style={{
                                                                            border: '0.125rem solid #E7EBF3',
                                                                            height: '40px',
                                                                            width: '40px'
                                                                        }}
                                                                        class="rounded-full"
                                                                        src={user.photo ? Cookies.get('backend_url') + 'storage/avatar/' + user.photo : '/assets/img/placeholder.png'}
                                                                        alt="Jese image"
                                                                    />
                                                                </SoftBox>
                                                                <div
                                                                    className="d-flex flex-column"
                                                                    style={{
                                                                        width: '214px'
                                                                    }}
                                                                >
                                                                    <p className="text-md text-primary m-0">{participation.name}</p>
                                                                    <p className="text-link text-secondary m-0 text-overflow-ellipsis">{(participation?.sender_label ? participation?.sender_label + ': ' : '') + participation.message}</p>
                                                                </div>
                                                                <div
                                                                    className="d-flex align-items-center justify-content-between"
                                                                    style={{ height: '18px' }}
                                                                >
                                                                    <p className="text-sm text-secondary m-0 text-overflow-ellipsis"><Moment fromNow>{participation.created_at}</Moment></p>

                                                                    {participation.status != '1' ? (
                                                                        <SoftBox
                                                                            component="i"
                                                                            display="inline-block"
                                                                            width={"8px"}
                                                                            height={"8px"}
                                                                            borderRadius="50%"
                                                                            bgColor={'#FF465B'}
                                                                            ml={1}
                                                                        />
                                                                    ) : ''}
                                                                </div>
                                                            </SoftBox>
                                                        </NavLink>
                                                    </>
                                                )) : <></>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>

                            <li className="nav-item">
                                <div className="dropdown">
                                    <a href="notification" className="nav-link" data-bs-toggle="dropdown" aria-expanded="false">
                                        <i className="ph ph-bell cursor-pointer top-nav-icon">
                                            {unreadNotification > 0 && (
                                                <SoftBox
                                                    component="i"
                                                    display="inline-block"
                                                    width={"8px"}
                                                    height={"8px"}
                                                    borderRadius="50%"
                                                    bgColor={'#FF465B'}
                                                    ml={-1}
                                                />
                                            )}
                                        </i>
                                    </a>
                                    <div className="dropdown-menu res-dropdown-menu dropdown-menu-end">
                                        <div className="card">
                                            <div class="card-header border-bottom">
                                                <h3 className="title text-lg">Notifications</h3>
                                            </div>
                                            <div className="card-body">
                                                {notifications?.data ? notifications?.data.map((notification) => {
                                                    const title = notification.title;
                                                    const splitTitle = title.split('#');
                                                    var icon = '';
                                                    if (
                                                        notification.type === 'project' ||
                                                        notification.type === 'client_sign_order_overview'
                                                    ) {
                                                        icon = <i class="fa-solid fa-briefcase text-primary" style={{ fontSize: "12px" }}>  </i>;
                                                    } else if (notification.type == 'quation') {
                                                        icon = <i class="fa-solid fa-bag-shopping text-primary" style={{ fontSize: "12px" }}>  </i>;
                                                    } else if (notification.type == 'order') {
                                                        icon = <i class="fa-solid fa-bag-shopping text-primary" style={{ fontSize: "12px" }}>  </i>;
                                                    }
                                                    return (
                                                        <>
                                                            <NavLink to={'/projects/' + notification.task_id}>
                                                                <SoftBox
                                                                    display="flex"
                                                                    justifyContent="space-between"
                                                                    alignItems="top"
                                                                    gap={1}
                                                                    borderRadius="8px"
                                                                    sx={{
                                                                        backgroundColor: '#F8F9FA',
                                                                        padding: '8px',
                                                                        height: '60px',
                                                                        '&:hover': {
                                                                            backgroundColor: 'var(--brand-light)',
                                                                            color: 'var(--gray-900)',
                                                                        },
                                                                    }}
                                                                    mb={1}
                                                                >
                                                                    <SoftBox display="flex" alignItems="center">
                                                                        <img
                                                                            style={{
                                                                                border: '0.125rem solid #E7EBF3',
                                                                                height: '40px',
                                                                                width: '40px'
                                                                            }}
                                                                            class="rounded-full"
                                                                            src={notification?.photo?.length > 0 ? Cookies.get('backend_url') + 'storage/avatar/' + notification.photo : '/assets/img/placeholder.png'}
                                                                            alt="Jese image"
                                                                        />
                                                                    </SoftBox>
                                                                    <SoftBox
                                                                        display="flex"
                                                                        flexDirection="column"
                                                                        sx={{
                                                                            width: '214px'
                                                                        }}
                                                                    >
                                                                        <SoftTypography
                                                                            variant="h6"
                                                                            fontWeight="medium"
                                                                            className="text-primary text-md"
                                                                        >{notification.task_id}</SoftTypography>

                                                                        <SoftTypography
                                                                            variant="h6"
                                                                            fontWeight="medium"
                                                                            className="text-secondary"
                                                                            style={{
                                                                                fontSize: '14px',
                                                                                fontWeight: 400,
                                                                                whiteSpace: "nowrap",
                                                                                overflow: "hidden",
                                                                                textOverflow: "ellipsis"
                                                                            }}
                                                                        >{notification.title}</SoftTypography>
                                                                    </SoftBox>

                                                                    <div
                                                                        className="d-flex align-items-center justify-content-between"
                                                                        style={{ height: '18px' }}
                                                                    >
                                                                        <p className="text-sm text-secondary m-0 text-overflow-ellipsis"><Moment fromNow>{notification.created_at}</Moment></p>

                                                                        {notification.status === '0' ? (
                                                                            <SoftBox
                                                                                component="i"
                                                                                display="inline-block"
                                                                                width={"8px"}
                                                                                height={"8px"}
                                                                                borderRadius="50%"
                                                                                bgColor={'#FF465B'}
                                                                                ml={1}
                                                                            />
                                                                        ) : ''}
                                                                    </div>
                                                                </SoftBox>
                                                            </NavLink>
                                                        </>
                                                    )
                                                }) : <></>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li className="nav-item">
                                <div className="dropdown">
                                    <a
                                        href="profile"
                                        className="nav-link profile-dropdown-toggle dropdown-toggle"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                    >
                                        <img
                                            src={false ? Cookies.get('backend_url') + 'storage/avatar/' + user.photo : '/assets/img/placeholder.png'}
                                            style={{
                                                height: "40px",
                                                width: "40px",
                                                borderRadius: '50%',
                                                border: '1px solid #E7EBF3'
                                            }}
                                        />
                                    </a>
                                    <div className="dropdown-menu res-dropdown-menu dropdown-menu-end">
                                        <div className="card">
                                            <div className="card-body"
                                                style={{
                                                    maxWidth: '270px',
                                                    maxHeight: '395px'
                                                }}
                                            >
                                                <SoftBox
                                                    display="flex"
                                                    justifyContent="flex-start"
                                                    alignItems="center"
                                                    gap={1}
                                                    borderRadius="8px"
                                                    sx={{
                                                        height: '60px'
                                                    }}
                                                    mb={1}
                                                >
                                                    <SoftBox display="flex" alignItems="center">
                                                        <img
                                                            style={{
                                                                border: '0.125rem solid #E7EBF3',
                                                                height: '40px',
                                                                width: '40px'
                                                            }}
                                                            class="rounded-full"
                                                            src={false ? Cookies.get('backend_url') + 'storage/avatar/' + user.photo : '/assets/img/placeholder.png'}
                                                            alt="Jese image"
                                                        />
                                                    </SoftBox>
                                                    <SoftBox
                                                        display="flex"
                                                        flexDirection="column"
                                                        sx={{
                                                            width: '214px'
                                                        }}
                                                    >
                                                        <SoftTypography
                                                            variant="h6"
                                                            fontWeight="medium"
                                                            className="text-primary text-md"
                                                        >{user && user.name ? user.name : ''}</SoftTypography>

                                                        <SoftTypography
                                                            variant="h6"
                                                            fontWeight="medium"
                                                            className="text-secondary"
                                                            style={{
                                                                fontSize: '14px',
                                                                fontWeight: 400,
                                                                whiteSpace: "nowrap",
                                                                overflow: "hidden",
                                                                textOverflow: "ellipsis"
                                                            }}
                                                        >{user && user.email ? user.email : ''}</SoftTypography>
                                                    </SoftBox>
                                                </SoftBox>
                                                <div class="dropdown-divider"></div>

                                                {/*<a href="#0" className="dropdown-item">{t('Dashboard')}</a>*/}

                                                {Cookies.get('user') && JSON.parse(Cookies.get('user')).rolename == 'Call Center' || JSON.parse(Cookies.get('user')).rolename == 'DC Technician' ? (
                                                    <></>
                                                ) : <><NavLink to="/profile/settings" className="dropdown-item d-block text-start mb-1 d-flex align-items-center"><i className="ph ph-gear-six me-2"></i> <span className="text-brand-primary">{t('Settings')}</span></NavLink></>}

                                                <a href="#0" onClick={(e) => doSignout(e)} className="dropdown-item dropdown-item-danger d-block text-start mb-1 d-flex align-items-center "><i className="ph ph-sign-out me-2"></i> {t('Sign Out')}</a>

                                                <div class="dropdown-divider"></div>

                                                <SidenavCard />

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav >
        </>
    );
}

const badgeStyle = {
    fontSize: '13px',
    padding: '3px',
    height: '19px',
    width: '19px',
    position: 'relative',
    top: '3px',
    left: '-5px',
    backgroundColor: 'red',
    borderRadius: '50%',
    color: '#fff'
};

export default TopNav;
