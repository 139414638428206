import React from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import GoodsIndexTable from "./GoodsIndexTable";

const GoodsIndex = () => {
    const { t } = useTranslation();

    return (
        <> 
            <div className="row">
                <div className="col-12">
                    <div className="card mb-4">
                        <div className="card-header pb-0">
                        <div className="d-sm-flex justify-content-between">
                            <p className="table-title-cs mb-0">{t('Wareneingang')}</p>
                            <div>
                            {JSON.parse(Cookies.get('permissions')).indexOf("MC") !== -1 ? (
                                <NavLink to="/ware-houses/goods-received/create" className="btn btn-icon btn-primary d-flex align-items-center">
                                  <i className="ph ph-plus me-2"></i>  {t('Wareneingang buchen')}
                                </NavLink>
                            ) : <></>}
                            </div>
                        </div> 
                        </div>
                        <div className="card-body pb-2">
                            <GoodsIndexTable/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
  }
export default GoodsIndex