import { React, useEffect, useState } from 'react';
import callFetch from "../../helpers/callFetch";
import { useTranslation } from 'react-i18next';
import DataTable from 'react-data-table-component';
import { useForm } from "react-hook-form";
import { useLoadScript, Autocomplete } from '@react-google-maps/api';
import Swal from "sweetalert2";
import Checkbox from "@mui/material/Checkbox";

function IndexTableProject(props) {
    const { t } = useTranslation();
    const [saving, setSaving] = useState(false);
    const [loading, setLoading] = useState(false);
    const [validationLoading, setValidationLoading] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [tabledata, setTableData] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [deleteButton, setDeleteButton] = useState(false);
    const [refresh, setRefresh] = useState(0);
    const [refresh2, setRefresh2] = useState(0);
    const [searchKey, setSearchKey] = useState("")
    const [openMenu, setOpenMenu] = useState(null);
    const [assignedDateTimeUser, setAssignedDateTimeUser] = useState([]);
    const [project_Data, setProjectData] = useState([]);
    const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
    const handleCloseMenu = () => setOpenMenu(null);
    const [autocomplete, setAutocomplete] = useState({});

    const libraries = ["places"]
    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
        libraries
    })

    const {
        register,
        handleSubmit,
        setError,
        setValue,
        getValues,
        reset,
        watch,
        formState: { errors },
    } = useForm();


    useEffect(() => {
        setDeleteButton(false);
        if (getValues('selectedrow')) {
            for (let [selectedrow_key, selectedrow_value] of Object.entries(getValues('selectedrow'))) {
                if (selectedrow_value) {
                    setDeleteButton(true);
                }
            }
        }

    }, [refresh2]);

    const verkauferFeatch = () => {
        //callFetch("projects/create?date=" + getValues('date') + '&time=' + getValues('time'), "GET", []).then((res) => {
        callFetch("projects/create?date=&time=", "GET", []).then((res) => {
            setProjectData(res.data);
        });
    }

    const deleteSelectedItem = () => {
        setDeleting(true);
        var selectedValues = [];
        for (let [selectedrow_key, selectedrow_value] of Object.entries(getValues('selectedrow'))) {
            if (selectedrow_value) {
                selectedValues.push(selectedrow_key);
            }
        }

        let formData = {
            ids: JSON.stringify(selectedValues),
            type: props.type,
        };

        callFetch("import/delete", "POST", formData, setError).then((res) => {
            if (res.message == 'success') {
                for (let [selectedrow_key, selectedrow_value] of Object.entries(getValues('selectedrow'))) {
                    setValue("selectedrow[" + selectedrow_key + "]", false)
                }
                setDeleting(false);
                setRefresh(refresh + 1);
                setRefresh2(refresh + 1);
            }
        })
    }


    const validateDateTimeVerkaufer = (row_id = 0, first_time = false) => {
        if (first_time == true) {
            //showAlert();
        }
        setValidationLoading(true);
        reset({});

        setTimeout(() => {
            var dateTimeUserData = [];
            var total_projects = Object.keys(getValues('verkaufer')).length;

            for (let [selectedrow_key, selectedrow_value] of Object.entries(getValues('verkaufer'))) {


                if (first_time == true) {
                    var crrentRowDateTimeUser = getValues("date[" + selectedrow_key + "]") + '_' + getValues("time[" + selectedrow_key + "]") + '_' + getValues("verkaufer[" + selectedrow_key + "]");
                    if (dateTimeUserData.indexOf(crrentRowDateTimeUser) !== -1) {
                        setValue("verkaufer[" + selectedrow_key + "]", '');
                    } else {
                        dateTimeUserData.push(crrentRowDateTimeUser);
                    }

                    --total_projects;
                } else if (selectedrow_key == row_id) {

                    callFetch("projects/create?date=" + getValues("date[" + selectedrow_key + "]") + '&time=' + getValues("time[" + selectedrow_key + "]"), "GET", []).then((res) => {
                        var available_employees = [];
                        for (let [available_employee_key, available_employee_value] of Object.entries(res?.data?.employees)) {
                            available_employees.push(available_employee_value?.user?.id);
                        }

                        var dateTimeUser = getValues("date[" + selectedrow_key + "]") + '_' + getValues("time[" + selectedrow_key + "]") + '_' + getValues("verkaufer[" + selectedrow_key + "]");
                        if (available_employees.indexOf(Number(getValues("verkaufer[" + selectedrow_key + "]"))) !== -1) {
                            if (dateTimeUserData.indexOf(dateTimeUser) !== -1) {
                                setValue("verkaufer[" + selectedrow_key + "]", '');
                            } else {
                                dateTimeUserData.push(dateTimeUser);
                            }
                        } else {
                            setValue("verkaufer[" + selectedrow_key + "]", '');
                        }

                        --total_projects;
                        if (total_projects === 0) {
                            setValidationLoading(false);
                            //Swal.close();
                        }
                    });
                } else if (selectedrow_key != row_id) {
                    var crrentRowDateTimeUser = getValues("date[" + selectedrow_key + "]") + '_' + getValues("time[" + selectedrow_key + "]") + '_' + getValues("verkaufer[" + selectedrow_key + "]");
                    if (dateTimeUserData.indexOf(crrentRowDateTimeUser) !== -1) {
                        setValue("verkaufer[" + selectedrow_key + "]", '');
                    } else {
                        dateTimeUserData.push(crrentRowDateTimeUser);
                    }

                    --total_projects;
                }

                if (total_projects === 0) {
                    setValidationLoading(false);
                    // Swal.close();
                }
            }
        }, 500)
    }

    const onSubmitSave = () => {
        setSaving(true);
        reset({});

        setTimeout(() => {
            var formData = {
                filename: props.filename,
            }
            callFetch("import/" + props.type + "/save", "POST", formData, setError).then((res) => {
                if (res.message == 'success') {
                    setRefresh(refresh + 1);
                    setRefresh2(refresh + 1);
                    document.getElementById('outputModal-button-close').click();
                    setSaving(false);
                    window.location.reload();
                }
            });
        }, 1000)
    }


    const onSubmit = (formData) => {
        onSubmitSave();
    };

    const updateFileData = (formData) => {
        formData.type = props.type;
        setLoading(true);
        callFetch("import/update", "POST", formData, setError).then((res) => {
            if (res.message == 'success') {
                setLoading(false);
                //setRefresh(refresh+1);
                // setRefresh2(refresh+1);
                //document.getElementById('outputModal-button-close').click();
                //window.location.reload();
            }
        });
    }

    const onLoad = (data, id) => {
        var update_autocomplete = autocomplete;
        update_autocomplete[id] = data;
        setAutocomplete(update_autocomplete);
    }

    const onPlaceChanged = (id) => {
        if (autocomplete !== null) {
            var place = autocomplete[id].getPlace();
            setValue('nr[' + id + ']', '');
            setValue('plz[' + id + ']', '');
            setValue('ort[' + id + ']', '');
            setValue('street[' + id + ']', '');

            if (place?.address_components) {
                for (let [key, value] of Object.entries(place?.address_components)) {
                    if (value?.types) {
                        if (value?.types.indexOf("postal_code") !== -1) {
                            setValue('plz[' + id + ']', value?.long_name);
                            updateFileData({ id: id, plz: value?.long_name });
                        } else if (value?.types.indexOf("street_number") !== -1) {
                            setValue('nr[' + id + ']', value?.long_name);
                            updateFileData({ id: id, nr: value?.long_name });
                        } else if (value?.types.indexOf("locality") !== -1) {
                            setValue('ort[' + id + ']', value?.long_name);
                            updateFileData({ id: id, ort: value?.long_name });
                        } else if (value?.types.indexOf("route") !== -1) {
                            setValue('street[' + id + ']', value?.long_name);
                            updateFileData({ id: id, street: value?.long_name });
                        }
                    }
                }
            }
            setValue('lat[' + id + ']', place.geometry.location.lat());
            setValue('lng[' + id + ']', place.geometry.location.lng());

            updateFileData({ id: id, lat: place.geometry.location.lat() });
            updateFileData({ id: id, lng: place.geometry.location.lng() });

        } else {
            console.log('Autocomplete is not loaded yet!')
        }
    }

    const tableHeadings = [
        {
            //name: t('Project Nr.'),
            name: <>
                <Checkbox
                    defaultChecked={false}
                    onChange={(e) => {
                        if (e.target.checked) {
                            for (let [selectedrow_key, selectedrow_value] of Object.entries(getValues('selectedrow'))) {
                                setValue("selectedrow[" + selectedrow_key + "]", true)
                                console.log()
                            }
                        } else {
                            for (let [selectedrow_key, selectedrow_value] of Object.entries(getValues('selectedrow'))) {
                                setValue("selectedrow[" + selectedrow_key + "]", false)
                            }
                        }
                        setRefresh2(refresh2 + 1);
                    }}
                    disabled={loading}
                />
            </>,
            width: '44px',
            cell: row => (
                <>
                    <Checkbox
                        {...register(`selectedrow[${row.id}]`, {
                            onChange: (e) => {
                                setRefresh2(refresh2 + 1);
                            }
                        })}
                        checked={watch(`selectedrow[${row.id}]`) || false}
                    />
                </>),
            sortable: true,
        },
        {
            name: t('Date'),
            width: '150px',
            cell: row => (<>
                <input
                    className="form-control form-control-sm"
                    {...register("date[" + row.id + "]", {
                        required: true,
                        onChange: (e) => {
                            updateFileData({
                                id: row.id,
                                date: e.target.value
                            });
                            validateDateTimeVerkaufer(row.id);
                        }
                    })}
                    defaultValue={row.date}
                    required
                    type="date" />
            </>),
        },
        {
            name: t('Time'),
            width: '150px',
            cell: row => (
                <select
                    className="form-control form-control-sm"
                    {...register("time[" + row.id + "]", {
                        required: true,
                        onChange: (e) => {
                            updateFileData({
                                id: row.id,
                                time: e.target.value
                            });
                            validateDateTimeVerkaufer(row.id);
                        }
                    })}
                    required
                    defaultValue={row.time}
                >
                    <option value="">--</option>
                    <option value="08:00">08:00</option>
                    <option value="08:30">08:30</option>
                    <option value="09:00">09:00</option>
                    <option value="09:30">09:30</option>
                    <option value="10:00">10:00</option>
                    <option value="10:30">10:30</option>
                    <option value="11:00">11:00</option>
                    <option value="11:30">11:30</option>
                    <option value="12:00">12:00</option>
                    <option value="12:30">12:30</option>
                    <option value="13:00">13:00</option>
                    <option value="13:30">13:30</option>
                    <option value="14:00">14:00</option>
                    <option value="14:30">14:30</option>
                    <option value="15:00">15:00</option>
                    <option value="15:30">15:30</option>
                    <option value="16:00">16:00</option>
                    <option value="16:30">16:30</option>
                    <option value="17:00">17:00</option>
                    <option value="17:30">17:30</option>
                    <option value="18:00">18:00</option>
                    <option value="18:30">18:30</option>
                    <option value="19:00">19:00</option>
                    <option value="19:30">19:30</option>
                    <option value="20:00">20:00</option>
                    <option value="20:30">20:30</option>
                    <option value="21:00">21:00</option>
                    <option value="21:30">21:30</option>
                    <option value="22:00">22:00</option>
                </select>
            ),
        },
        {
            name: t('Verkaufer'),
            width: '150px',
            cell: row => (<>
                <select
                    className="form-control form-control-sm"
                    {...register("verkaufer[" + row.id + "]", {
                        required: true,
                        onChange: (e) => {
                            updateFileData({
                                id: row.id,
                                verkaufer: e.target.value
                            });
                            validateDateTimeVerkaufer(row.id);
                        }
                    })}
                    required
                    defaultValue={row.verkaufer}
                >
                    <option value="">--</option>
                    {project_Data && project_Data.employees ? project_Data.employees.map((employee) => (
                        <option key={employee.user.id} value={employee.user.id}>{employee.user.name}</option>
                    )) : <></>}
                </select>
            </>),
        },
        {
            name: t('Beschreibung'),
            width: '350px',
            cell: row => (<>
                <textarea
                    defaultValue={row.description}
                    className="form-control form-control-sm"
                    rows="2"
                    placeholder={t('z.B. Beschreibung über den Kunden...')}
                    {...register("description[" + row.id + "]", {
                        onChange: (e) => {
                            updateFileData({
                                id: row.id,
                                description: e.target.value
                            });
                        }
                    })}></textarea>
            </>),
        },
        {
            name: t('Anrede'),
            width: '150px',
            cell: row => <select
                className="form-control form-control-sm"
                {...register("anrede[" + row.id + "]", {
                    required: true,
                    onChange: (e) => {
                        updateFileData({
                            id: row.id,
                            anrede: e.target.value
                        });
                    }
                })}
                defaultValue={row.anrede}
                required>
                <option value="">--</option>
                <option value="Herr">{t('Herr')}</option>
                <option value="Frau">{t('Frau')}</option>
            </select>,
        },
        {
            name: t('Vorname'),
            width: '150px',
            cell: row => <input
                type="text"
                className="form-control form-control-sm"
                placeholder={t('')}
                {...register("vorname[" + row.id + "]", {
                    required: true,
                    onChange: (e) => {
                        updateFileData({
                            id: row.id,
                            vorname: e.target.value
                        });
                    }
                })}
                defaultValue={row.vorname}
                required
            />,
        },
        {
            name: t('Name'),
            width: '150px',
            cell: row => <input
                type="text"
                className="form-control form-control-sm"
                placeholder={t('')}
                {...register("name[" + row.id + "]", {
                    required: true,
                    onChange: (e) => {
                        updateFileData({
                            id: row.id,
                            name: e.target.value
                        });
                    }
                })}
                defaultValue={row.name}
                required
            />,
        },
        {
            name: t('Straße'),
            width: '280px',
            cell: row => (
                <>
                    <div
                        className='w-100'
                    >
                        <Autocomplete
                            options={{
                                componentRestrictions: { country: "de" },
                                //fields: ["address_components", "geometry", "icon", "name"],
                                //types: ["establishment"]
                            }}
                            onLoad={(autocomplete) => {
                                onLoad(autocomplete, row.id);
                            }}
                            onPlaceChanged={() => {
                                console.log('onPlaceChanged')
                                onPlaceChanged(row.id);
                            }}
                            className='w-100'
                            onUnmount={(autocomplete) => {
                                console.log('onUnmount');

                                console.log(autocomplete)
                            }}
                        >
                            <input
                                type="text"
                                placeholder={t('')}
                                className={"form-control form-control-sm"}
                                {...register("full_address[" + row.id + "]", {
                                    required: false,
                                })}
                                defaultValue={row.street + ' ' + (row.nr && row.nr != 'null' ? row.nr : '')}
                            />
                        </Autocomplete>
                        <div className='d-none'>
                            <input
                                type="text"
                                className="form-control form-control-sm"
                                placeholder={t('')}
                                {...register("street[" + row.id + "]", {
                                    required: false,
                                    onChange: (e) => {
                                        updateFileData({
                                            id: row.id,
                                            street: e.target.value
                                        });
                                    }
                                })}
                                defaultValue={row.street}
                                required
                            />

                            <input
                                type="text"
                                className="form-control form-control-sm"
                                placeholder={t('')}
                                {...register("nr[" + row.id + "]", {
                                    required: false,
                                    onChange: (e) => {
                                        updateFileData({
                                            id: row.id,
                                            nr: e.target.value
                                        });
                                    }
                                })}
                                defaultValue={row.nr}
                                required
                            />

                            <input
                                type="text"
                                className="form-control form-control-sm"
                                placeholder={t('')}
                                {...register("lat[" + row.id + "]", {
                                    required: false,
                                    onChange: (e) => {
                                        updateFileData({
                                            id: row.id,
                                            lat: e.target.value
                                        });
                                    }
                                })}
                                defaultValue={row.lat}
                                required
                            />
                            <input
                                type="text"
                                className="form-control form-control-sm"
                                placeholder={t('')}
                                {...register("lng[" + row.id + "]", {
                                    required: false,
                                    onChange: (e) => {
                                        updateFileData({
                                            id: row.id,
                                            lng: e.target.value
                                        });
                                    }
                                })}
                                defaultValue={row.lng}
                                required
                            />
                        </div>
                    </div>
                </>
            )

            ,
        },
        {
            name: t('PLZ'),
            width: '150px',
            cell: row => <input
                type="text"
                className="form-control form-control-sm"
                placeholder={t('')}
                {...register("plz[" + row.id + "]", {
                    required: true,
                    onChange: (e) => {
                        updateFileData({
                            id: row.id,
                            plz: e.target.value
                        });
                    }
                })}
                defaultValue={row.plz}
                required
                readOnly
            />,
        },
        {
            name: t('Ort'),
            width: '150px',
            cell: row => <input
                type="text"
                className="form-control form-control-sm"
                placeholder={t('')}
                {...register("ort[" + row.id + "]", {
                    required: true,
                    onChange: (e) => {
                        updateFileData({
                            id: row.id,
                            ort: e.target.value
                        });
                    }
                })}
                defaultValue={row.ort}
                required
                readOnly
            />,
        },
        {
            name: t('Telefonnummer'),
            width: '150px',
            cell: row => <input
                type="text"
                className="form-control form-control-sm"
                placeholder={t('')}
                {...register("telefonnummer[" + row.id + "]", {
                    required: true,
                    onChange: (e) => {
                        updateFileData({
                            id: row.id,
                            telefonnummer: e.target.value
                        });
                    }
                })}
                defaultValue={row.telefonnummer}
                required
            />,
        },
        {
            name: t('Mobilnummer'),
            width: '150px',
            cell: row => <input
                type="text"
                className="form-control form-control-sm"
                placeholder={t('')}
                {...register("mobilnummer[" + row.id + "]", {
                    required: false,
                    onChange: (e) => {
                        updateFileData({
                            id: row.id,
                            mobilnummer: e.target.value
                        });
                    }
                })}
                defaultValue={row.mobilnummer}
            />,
        },
        {
            name: t('Entscheidungstrager'),
            width: '150px',
            cell: row => (
                <>
                    <input
                        defaultValue={row.entscheidungstrager}
                        type="text"
                        placeholder="Entscheidungstrager"
                        className="form-control form-control-sm"
                        {...register("entscheidungstrager[" + row.id + "]", {
                            required: false,
                            onChange: (e) => {
                                updateFileData({
                                    id: row.id,
                                    entscheidungstrager: e.target.value
                                });
                            }
                        })}
                    />
                </>
            ),
        },
        {
            name: t('Gesamtverbrauch Kwh'),
            width: '150px',
            cell: row => <input
                type="text"
                className="form-control form-control-sm"
                placeholder={t('Gesamtverbrauch Kwh')}
                {...register("gesamtverbrauch_kwh[" + row.id + "]", {
                    required: false,
                    onChange: (e) => {
                        updateFileData({
                            id: row.id,
                            gesamtverbrauch_kwh: e.target.value
                        });
                    }
                })}
                defaultValue={row.gesamtverbrauch_kwh}
            />,
        },
        {
            name: t('Monatlicher Abschlag In Euro'),
            width: '150px',
            cell: row => <input
                type="text"
                className="form-control form-control-sm"
                placeholder={t('Monatlicher Abschlag In Euro')}
                {...register("monatlicher[" + row.id + "]", {
                    required: false,
                    onChange: (e) => {
                        updateFileData({
                            id: row.id,
                            monatlicher: e.target.value
                        });
                    }
                })}
                defaultValue={row.monatlicher}
            />,
        },
        {
            name: t('Stromabrechung Vorhanden'),
            width: '100px',
            cell: row => <select className="form-control form-control-sm"
                {...register("stromabrechung_vorhanden[" + row.id + "]", {
                    required: false,
                    onChange: (e) => {
                        updateFileData({
                            id: row.id,
                            stromabrechung_vorhanden: e.target.value
                        });
                    }
                })}
                defaultValue={row.stromabrechung_vorhanden}
            >
                <option>--</option>
                <option value="Yes" >{t('Yes')}</option>
                <option value="No" >{t('No')}</option>
            </select>,
        },
        {
            name: t('Dachausrichtung'),
            width: '150px',
            cell: row => <input
                type="text"
                className="form-control form-control-sm"
                placeholder={t('')}
                {...register("dachausrichtung[" + row.id + "]", {
                    required: false,
                    onChange: (e) => {
                        updateFileData({
                            id: row.id,
                            dachausrichtung: e.target.value
                        });
                    }
                })}
                defaultValue={row.dachausrichtung}

            />,
        },
        {
            name: t('Ziegelart'),
            width: '150px',
            cell: row => <input
                type="text"
                className="form-control form-control-sm"
                placeholder={t('Ziegelart')}
                {...register("ziegelart[" + row.id + "]", {
                    required: false,
                    onChange: (e) => {
                        updateFileData({
                            id: row.id,
                            ziegelart: e.target.value
                        });
                    }
                })}
                defaultValue={row.ziegelart}
            />,
        },
        {
            name: t('E-Mobilitat'),
            width: '150px',
            cell: row => <input
                type="text"
                className="form-control form-control-sm"
                placeholder={t('E-Mobilitat')}
                {...register("e_mobilitat[" + row.id + "]", {
                    required: false,
                    onChange: (e) => {
                        updateFileData({
                            id: row.id,
                            e_mobilitat: e.target.value
                        });
                    }
                })}
                defaultValue={row.e_mobilitat}

            />,
        },
        {
            name: t('Zeitraum (max.)'),
            width: '150px',
            cell: row => <input
                type="text"
                className="form-control form-control-sm"
                placeholder={t('Zeitraum (max.)')}
                {...register("zeitraum_max[" + row.id + "]", {
                    required: false,
                    onChange: (e) => {
                        updateFileData({
                            id: row.id,
                            zeitraum_max: e.target.value
                        });
                    }
                })}
                defaultValue={row.zeitraum_max}
            />,
        },
        {
            name: t('Internet'),
            width: '100px',
            cell: row => <select
                className="form-control form-control-sm"
                {...register("internet[" + row.id + "]", {
                    required: false,
                    onChange: (e) => {
                        updateFileData({
                            id: row.id,
                            internet: e.target.value
                        });
                    }
                })}
                defaultValue={row.internet}
            >
                <option value="">--</option>
                <option value="Yes">{t('Yes')}</option>
                <option value="No">{t('No')}</option>
            </select>,
        },
        {
            name: t('Erfahrung Mit PV'),
            width: '100px',
            cell: row => <select
                className="form-control form-control-sm"
                {...register("erfahrung_mit_pv[" + row.id + "]", {
                    required: false,
                    onChange: (e) => {
                        updateFileData({
                            id: row.id,
                            erfahrung_mit_pv: e.target.value
                        });
                    }
                })}
                defaultValue={row.erfahrung_mit_pv}
            >
                <option value="">--</option>
                <option value="Yes">{t('Yes')}</option>
                <option value="No">{t('No')}</option>
            </select>,
        },
        {
            name: t('Bestatiger Name'),
            width: '150px',
            cell: row => <input
                type="text"
                className="form-control form-control-sm"
                {...register("bestatiger_name[" + row.id + "]", {
                    required: false,
                    onChange: (e) => {
                        updateFileData({
                            id: row.id,
                            bestatiger_name: e.target.value
                        });
                    }
                })}
                defaultValue={row.bestatiger_name}
            />,
        },
        {
            name: t('Termin vereinbart am'),
            width: '150px',
            cell: row => <input
                type="text"
                className="form-control form-control-sm"
                {...register("termin_vereinbart_am[" + row.id + "]", {
                    required: false,
                    onChange: (e) => {
                        updateFileData({
                            id: row.id,
                            termin_vereinbart_am: e.target.value
                        });
                    }
                })}
                defaultValue={row.termin_vereinbart_am}
            />,
        },
        {
            name: t('Kundenalter'),
            width: '150px',
            cell: row => <input
                type="text"
                className="form-control form-control-sm"
                {...register("kundenalter[" + row.id + "]", {
                    required: false,
                    onChange: (e) => {
                        updateFileData({
                            id: row.id,
                            kundenalter: e.target.value
                        });
                    }
                })}
                defaultValue={row.kundenalter}
            />,
        },
        {
            name: t('Personen im Haushalt'),
            width: '150px',
            cell: row => <input
                type="text"
                className="form-control form-control-sm"
                {...register("personen_im_haushalt[" + row.id + "]", {
                    required: false,
                    onChange: (e) => {
                        updateFileData({
                            id: row.id,
                            personen_im_haushalt: e.target.value
                        });
                    }
                })}
                defaultValue={row.personen_im_haushalt}
            />,
        },
        {
            name: t('Heizungsart'),
            width: '150px',
            cell: row => <input
                type="text"
                className="form-control form-control-sm"
                {...register("heizungsart[" + row.id + "]", {
                    required: false,
                    onChange: (e) => {
                        updateFileData({
                            id: row.id,
                            heizungsart: e.target.value
                        });
                    }
                })}
                defaultValue={row.heizungsart}
            />,
        },
        {
            name: t('Baujahr des Gebäudes'),
            width: '150px',
            cell: row => <input
                type="text"
                className="form-control form-control-sm"
                {...register("baujahr_des_gebades[" + row.id + "]", {
                    required: false,
                    onChange: (e) => {
                        updateFileData({
                            id: row.id,
                            baujahr_des_gebades: e.target.value
                        });
                    }
                })}
                defaultValue={row.baujahr_des_gebades}
            />,
        },
        {
            name: t('Nutzbare Dachflache'),
            width: '150px',
            cell: row => <input
                type="text"
                className="form-control form-control-sm"
                {...register("nutzbare_dachflache[" + row.id + "]", {
                    required: false,
                    onChange: (e) => {
                        updateFileData({
                            id: row.id,
                            nutzbare_dachflache: e.target.value
                        });
                    }
                })}
                defaultValue={row.nutzbare_dachflache}
            />,
        },
        {
            name: t('Werber'),
            width: '150px',
            cell: row => <input
                type="text"
                className="form-control form-control-sm"
                {...register("werber[" + row.id + "]", {
                    required: false,
                    onChange: (e) => {
                        updateFileData({
                            id: row.id,
                            werber: e.target.value
                        });
                    }
                })}
                defaultValue={row.werber}
            />,
        },
        {
            name: t('Lead Uursprung'),
            width: '150px',
            cell: row => <input
                type="text"
                className="form-control form-control-sm"
                {...register("lead_ursprung[" + row.id + "]", {
                    required: false,
                    onChange: (e) => {
                        updateFileData({
                            id: row.id,
                            lead_ursprung: e.target.value
                        });
                    }
                })}
                defaultValue={row.lead_ursprung}
            />,
        },
    ];

    useEffect(() => {
        verkauferFeatch();
    }, [props.type]);

    useEffect(() => {

        if (!props.filename.length) return;

        if (props?.filename) {
            callFetch("import-data/" + props?.filename + '/' + props?.type + "?page=" + pageNumber, "GET", []).then((res) => {
                setTableData(res.data);
                validateDateTimeVerkaufer(0, true);
            });
        }


    }, [pageNumber, refresh, props.filename]);

    const handlePageChange = page => {
        setPageNumber(page);
    }

    function toPages(pages) {
        const results = [];

        for (let i = 1; i <= pages; i++) {
            results.push(i);
        }

        return results;
    }

    // RDT exposes the following internal pagination properties
    const BootyPagination = ({
        onChangePage,
        currentPage
    }) => {
        const handleBackButtonClick = () => {
            onChangePage(currentPage - 1);
        };

        const handleNextButtonClick = () => {
            onChangePage(currentPage + 1);
        };

        const handlePageNumber = (e) => {
            onChangePage(Number(e.target.value));
        };

        const pages = tabledata.last_page;
        const pageItems = toPages(pages);
        const nextDisabled = currentPage === tabledata.last_page;
        const previosDisabled = currentPage === 1;

        return (
            <>
                {tabledata.last_page > 1 && (
                    <div className='table-pagination d-flex justify-content-between align-items-center pb-0'>
                        <p className="entries">{tabledata.from} - {tabledata.to} {t('of')} {tabledata.total} {t('entries')}</p>
                        <ul className="pagination ">
                            <li className="page-item">
                                <button
                                    className="page-link"
                                    onClick={handleBackButtonClick}
                                    disabled={previosDisabled}
                                    aria-disabled={previosDisabled}
                                    aria-label="previous page"
                                >
                                    <i className='ph ph-caret-double-left text-primary text-xl'></i>
                                </button>
                            </li>

                            <li className="page-item">
                                <button
                                    className="page-link"
                                    onClick={handleBackButtonClick}
                                    disabled={previosDisabled}
                                    aria-disabled={previosDisabled}
                                    aria-label="previous page"
                                >
                                    <i className='ph ph-caret-left text-primary text-xl'></i>
                                </button>
                            </li>

                            {tabledata?.links.map((page) => {
                                //const className = (parseInt(page.label) === currentPage ? "page-item active" : "page-item");
                                const className = (page.active === true ? "page-item active" : "page-item");
                                return (
                                    <li key={page.label} className={className + ' ' + (parseInt(page.label) || page.label == '...' ? '' : 'd-none')}>
                                        <button
                                            className="page-link"
                                            onClick={page.label != '...' ? handlePageNumber : ''}
                                            value={page.label}
                                            disabled={page.label == '...' ? true : false}
                                        >
                                            {page.label}
                                        </button>
                                    </li>
                                );
                            })}

                            <li className="page-item">
                                <button
                                    className="page-link"
                                    onClick={handleNextButtonClick}
                                    disabled={nextDisabled}
                                    aria-disabled={nextDisabled}
                                    aria-label="next page"
                                >
                                    <i className='ph ph-caret-right text-primary text-xl'></i>
                                </button>
                            </li>

                            <li className="page-item">
                                <button
                                    className="page-link"
                                    onClick={handleNextButtonClick}
                                    disabled={nextDisabled}
                                    aria-disabled={nextDisabled}
                                    aria-label="next page"
                                >
                                    <i className='ph ph-caret-double-right text-primary text-xl'></i>
                                </button>
                            </li>
                        </ul>
                    </div>
                )}
            </>
        );
    };


    if (loadError) return "Error loading maps"
    if (!isLoaded) return;// "Loading Maps"

    return <>
        <form className={`needs-validation ${Object.keys(errors).length ? "was-validated" : ""}`} onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
            <div className="modal-body">
                <div className='d-flex align-items-center gap-2 mb-3'>
                    <input
                        type="date"
                        className='form-control'
                        style={{ maxWidth: '320px' }}
                        onChange={(e) => {
                            for (let [selectedrow_key, selectedrow_value] of Object.entries(getValues('selectedrow'))) {
                                if (selectedrow_value) {
                                    setValue("date[" + selectedrow_key + "]", e.target.value);
                                    updateFileData({
                                        id: selectedrow_key,
                                        date: e.target.value
                                    });
                                }
                            }
                        }}
                        disabled={loading}
                    />

                    {deleteButton ? (
                        <>
                            {deleting ? (
                                <button type='button' className='btn btn-danger mb-0' disabled>{t('Deleting ...')}</button>
                            ) : (
                                <button type='button' className='btn btn-danger mb-0' onClick={() => {
                                    deleteSelectedItem();
                                }}>{t('Delete')}</button>
                            )}
                        </>
                    ) : (
                        <button type='button' className='btn btn-danger mb-0' disabled>{t('Delete')}</button>
                    )}
                </div>

                <DataTable
                    columns={tableHeadings}
                    data={tabledata?.data}
                    noDataComponent={
                        <div className="d-flex align-items-center justify-content-center py-3" style={{ height: "100%" }}>
                            <div className="text-center">
                                <i className='ph ph-notepad data-table-no-record-icon'></i>
                                <p className="text-secondary text-md m-0">{t('There are no records to display')}</p>
                            </div>
                        </div>
                    }
                    className='data-table'
                    pagination
                    highlightOnHover
                    paginationComponentOptions={{ noRowsPerPage: true }}
                    paginationServer
                    paginationTotalRows={tabledata?.total}
                    onChangePage={handlePageChange}
                    paginationComponent={BootyPagination}
                />
            </div>

            <div className='modal-footer'>
                <button className='btn btn-secondary m-0 mx-2' onClick={() => {
                    document.getElementById('outputModal-button-close').click();
                }}>{t('Cancel')}</button>

                {loading == false ? (
                    <>
                        {!saving && (
                            <button type="submit" className="btn btn-primary m-0">
                                {t(props.type + ' upload')}
                            </button>
                        )}
                        {saving && (
                            <button type="submit" className="btn btn-disabled m-0" disabled>
                                {t(props.type + ' upload ...')}
                            </button>
                        )}
                    </>
                ) : (
                    <button type="submit" className="btn btn-disabled m-0" disabled>
                        {t('Loading ...')}
                    </button>
                )}
            </div>
        </form>
    </>
}

export default IndexTableProject;
