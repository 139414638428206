import { useMemo, useEffect, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, Link, useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import callFetch from "helpers/callFetch";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftEditor from "components/SoftEditor";


import Cookies from 'js-cookie';
import 'react-toastify/dist/ReactToastify.css';
import SignaturePad from 'react-signature-pad-wrapper';
import Timeline from "../../create/Timeline";
import SoftSnackbar from "components/SoftSnackbar";
import Comisioning from "../Comisioning";
import ComisioningModal from "../ComisioningModal";
import DropZoneTabs from 'components/DropZoneTabs/Index'
import Overview from '../../Overview';
import DCabnahmeSign from "../DCabnahmeSign";

const DCReklmationProtokoll = (props) => {
    const { t } = useTranslation();
    const [message, setMessage] = useState('Success');
    const [color, setColor] = useState('success');
    const params = useParams()
    const user = JSON.parse(Cookies.get('user'));
    const signaturePadRef = useRef(null);
    const submitBtn = useRef();
    const [saving, setSaving] = useState(false);
    const [modules, setModules] = useState([]);
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [refresh, setRefresh] = useState(0);
    const [editDelay, setEditDelay] = useState(0)
    const [description, setDescription] = useState("");
    const [attachments, setAttachment] = useState([]);
    const [data, setData] = useState([]);
    const [link, setLink] = useState(null)
    const [id, setID] = useState(0)
    const [newDropzone, setNewDropzone] = useState(true);
    const [editForm, setEditForm] = useState(false);
    const [tabFiles, setTabFiles] = useState({});
    const [strgItemRefresh, setStrgItemRefresh] = useState(0);
    const [strgItem, setStrgItem] = useState({ strg_1: '' });
    const [readOnly, setReadOnly] = useState(false);
    const [displayTab, setDisplayTab] = useState('form');

    const [dropZoneTabs, setDropZoneTabs] = useState([
        {
            name: 'DC_Montage_Bild_1',
            titel: 'DC Montage Bild 1',
            require: false
        }, {
            name: 'DC_Montage_Bild_2',
            titel: 'DC Montage Bild 2',
            require: false
        }, {
            name: 'DC_Montage_Bild_3',
            titel: 'DC Montage Bild 3',
            require: false
        }, {
            name: 'DC_Montage_Bild_4',
            titel: 'DC Montage Bild 4',
            require: false
        }, {
            name: 'DC_Montage_Bild_5',
            titel: 'DC Montage Bild 5',
            require: false
        }
    ]);

    const [reklmationDropZoneTabs, setReklmationDropZoneTabs] = useState([
        {
            name: 'reklamationFotos',
            titel: 'Reklamation Fotos',
            require: true
        },
    ]);

    const [dropZonValidationError, setDropZonValidationError] = useState('');

    const [successSB, setSuccessSB] = useState(false);
    const openSuccessSB = () => setSuccessSB(true);
    const closeSuccessSB = () => setSuccessSB(false);
    const renderSuccessSB = (
        <SoftSnackbar
            color={color}
            icon="check"
            title={t(message)}
            //content={t("Changes saved successfully")}
            dateTime={t("")}
            open={successSB}
            onClose={closeSuccessSB}
            close={closeSuccessSB}
            bgSuccess
        />
    );

    const {
        register,
        handleSubmit,
        setError,
        setValue,
        getValues,
        reset,
        formState: { errors },
    } = useForm();

    useEffect(() => {

        if (JSON.parse(Cookies.get('permissions')).indexOf("dabhU") == -1) {
            setReadOnly(true);
        }

        callFetch("module/all", "GET", []).then((res) => {
            setModules(res.data);
        });
    }, [0])

    useEffect(() => {
        setID(0);
        reset();

        if (props.project_id !== undefined) {
            callFetch("projectdistribution/" + props.project_id + "/edit?project_id=" + props.project_id, "GET", []).then((res) => {
                setValue('modultyp', res?.data?.quations?.modul);
                setValue('modulanzahl', res?.data?.quations?.anzahl_module);
                setEditDelay(1)
            });

            callFetch("dc_reklmation_abhnahmeprotokoll/" + props.project_id + "/edit?reclamation_id=" + props.reklamationId, "GET", []).then((res) => {
                if (res?.data?.dcabname && res?.data?.dcabname?.id) {
                    setTabFiles(JSON.parse(res?.data?.dcabname?.filesdata));

                    setID(res?.data?.dcabname?.id);

                    setStrgItem(JSON.parse(res?.data?.dcabname?.strg));
                    setDescription(res?.data?.dcabname.description && res?.data?.dcabname.description != 'null' ? res?.data?.dcabname.description : '')

                    if (JSON.parse(Cookies.get('permissions')).indexOf("dabhU") !== -1 && res?.data?.dcabname?.drawing) {
                        signaturePadRef.current.fromDataURL(res?.data?.dcabname?.drawing);
                    }

                    setValue('modultyp', res?.data?.dcabname?.modultyp)
                    setValue('modulanzahl', res?.data?.dcabname.modulanzahl)
                    setLink(res?.data?.pdf)
                    for (let [key, value] of Object.entries(res?.data?.dcabname)) {
                        if (key == 'filesdata') {
                            setTabFiles(JSON.parse(value));
                        } else if (key == 'strg') {
                            var strg = JSON.parse(value);
                            for (let [strg_key, strg_value] of Object.entries(strg)) {
                                setValue('strg[' + strg_key + ']', strg_value);
                            }
                        } else if (key == 'volt') {
                            var volt = JSON.parse(value);
                            for (let [volt_key, volt_value] of Object.entries(volt)) {
                                setValue('volt[' + volt_key + ']', volt_value);
                            }
                        } else {
                            setValue(key, (value == null || value == 'null' ? "" : value));
                        }
                    }
                    setStrgItemRefresh(strgItemRefresh + 1);
                } else {
                    //console.log('create new ahnahme for this reklamation')

                    if (res?.dcabnameprotokoll?.id) {
                        setTabFiles(JSON.parse(res?.dcabnameprotokoll?.filesdata));

                        //setID(res?.dcabnameprotokoll?.id);

                        setStrgItem(JSON.parse(res?.dcabnameprotokoll?.strg));
                        setDescription(res?.dcabnameprotokoll.description && res?.dcabnameprotokoll.description != 'null' ? res?.dcabnameprotokoll.description : '')


                        setValue('modultyp', res?.dcabnameprotokoll?.modultyp)
                        setValue('modulanzahl', res?.dcabnameprotokoll.modulanzahl)
                        setLink(res?.data?.pdf)
                        for (let [key, value] of Object.entries(res?.dcabnameprotokoll)) {
                            if (key == 'filesdata') {
                                setTabFiles(JSON.parse(value));
                            } else if (key == 'strg') {
                                var strg = JSON.parse(value);
                                for (let [strg_key, strg_value] of Object.entries(strg)) {
                                    setValue('strg[' + strg_key + ']', strg_value);
                                }
                            } else if (key == 'volt') {
                                var volt = JSON.parse(value);
                                for (let [volt_key, volt_value] of Object.entries(volt)) {
                                    setValue('volt[' + volt_key + ']', volt_value);
                                }
                            } else if (key == 'comisioning_status') {
                                //comisioning_status
                            } else {
                                setValue(key, (value == null || value == 'null' ? "" : value));
                            }
                        }
                        setStrgItemRefresh(strgItemRefresh + 1);
                    }
                }
            })
        }
    }, [refresh, props.reklamationId, props.project_id]);

    const setCallBackData = (data) => {
        setTabFiles(data);

        setDropZonValidationError('');
        //for (let [key, dropZoneTab] of Object.entries(dropZoneTabs)) {
        for (let [key, dropZoneTab] of Object.entries(reklmationDropZoneTabs)) {
            if (dropZoneTab?.require === true && (!data[dropZoneTab?.name] || data[dropZoneTab?.name]?.length <= 0)) {
                setDropZonValidationError(dropZoneTab?.titel + ' is required')
                return;
            }
        }
    }

    const onSubmit = (formData) => {
        if (readOnly) return;

        setDropZonValidationError('');
        for (let [key, dropZoneTab] of Object.entries(reklmationDropZoneTabs)) {
            if (dropZoneTab?.require === true && (!tabFiles[dropZoneTab?.name] || tabFiles[dropZoneTab?.name]?.length <= 0)) {
                setDropZonValidationError(dropZoneTab?.titel + ' is required')
                return;
            }
        }

        setSaving(true);

        var loadingElement = document.querySelector('.res-options .btn-primary-save');
        loadingElement.classList.add("btn-secondary");
        loadingElement.innerHTML = t('wird geladen...');

        formData.description = description;
        formData.sign = signaturePadRef.current.toDataURL('image/png');
        formData.filesdata = JSON.stringify(tabFiles);
        formData.strg = JSON.stringify(getValues('strg'));
        formData.volt = JSON.stringify(getValues('volt'));
        formData.reclamation_id = props.reklamationId;

        if (id == 0) {
            formData.project_id = props.project_id;
            formData.eidt_id = Cookies.get('dc_pdf');
        }

        //   Cookies.remove('dc_pdf')
        if (signaturePadRef.current.isEmpty()) {
            setColor('error')
            setMessage('Fill Out Skizze/Modulbelegung');
            setTimeout(() => {
                setSaving(false);

                loadingElement.classList.remove("btn-secondary");
                loadingElement.innerHTML = t('Save');

                openSuccessSB();
            }, 2000);
            return;
        }

        callFetch((id == 0 ? "dc_reklmation_abhnahmeprotokoll" : "dc_reklmation_abhnahmeprotokoll/" + id), "POST", formData, setError).then((res) => {
            if (!res.ok) {
                setSaving(false);
                return;
            };

            setData(res);
            setLink(res?.data?.pdf)
            setID(res?.data?.id)

            setColor('success')
            setMessage('Success');

            setTimeout(() => {
                setSaving(false);
                openSuccessSB();

                loadingElement.classList.remove("btn-secondary");
                loadingElement.innerHTML = t('Save');

                document.body.scrollTop = 0; // For Safari
                document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
                document.getElementById("main-content").scrollTop = 0;

                /*
                if (id == 0 || [1, 2, '1', '2'].indexOf(formData.comisioning_status) == -1) {
                    document.getElementById('ComisioningModalOpen').click();
                }*/
               
                if (!res.ok) return;
                setSubmitSuccess(true);
                setRefresh(refresh + 1)
                clearForm();

            }, 2000);
        });
    };
    const clearForm = () => {
        reset({
            id: 0,
            title: '',
            status: '',
            description: '',
            attachments: JSON.stringify([]),
        });

        setNewDropzone(true);
        setEditForm(false);
        setAttachment([]);
        setDescription("");
    }



    return (

        <>

            <div className="row">
                <div className="col-lg-9 inner-main">
                    <Overview className="mb-3" project_id={params?.id} title={t('DC Reklmation Abnahmeprotokoll')} ></Overview>
                    {displayTab === 'form' ? (
                        <form className={`needs-validation ${Object.keys(errors).length ? "was-validated" : ""}`} onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
                            <SoftBox>
                                {
                                    link !== null && <a className="opacity-0" target={"_blank"} id="dc_reklmation_abhnahmeprotokoll" href={link && link} ></a>
                                }

                                <input type="hidden" id="dcid" value={id} />

                                {id > 0 ? <input type="hidden" defaultValue="PUT" {...register("_method")} /> : ''}


                                <Card sx={{ overflow: "visible" }}>
                                    <SoftBox p={3}>
                                        <SoftTypography variant="h5">{t('DC Reklmation Abnahmeprotokoll')}</SoftTypography>
                                    </SoftBox>
                                    <SoftBox p={2} lineHeight={1}>
                                        <Grid mb={2} container spacing={2}>
                                            {
                                                Object.keys(strgItem).map(function (keyName, index) {
                                                    return (
                                                        <>
                                                            <Grid item xs={6} sm={6} md={2} lg={2}>
                                                                <label>Strg {(index + 1)}</label>
                                                                <input
                                                                    type="text" className="form-control"
                                                                    placeholder={"Strg " + (index + 1)}
                                                                    {...register('strg[' + keyName + ']', {
                                                                        required: true
                                                                    })}
                                                                    required
                                                                    readOnly={readOnly}
                                                                />
                                                            </Grid>

                                                            <Grid item xs={6} sm={6} md={2} lg={2}>
                                                                <label>Volt {(index + 1)}</label>
                                                                <input
                                                                    type="text" className="form-control"
                                                                    placeholder={"Volt " + (index + 1)}
                                                                    {...register('volt[' + keyName + ']', {
                                                                        required: true
                                                                    })}
                                                                    required
                                                                    readOnly={readOnly}
                                                                />
                                                            </Grid>
                                                        </>
                                                    )
                                                })
                                            }
                                            {JSON.parse(Cookies.get('permissions')).indexOf("dabhU") !== -1 ? (
                                                <Grid item xs={6} sm={6} md={2} lg={2}>
                                                    <a
                                                        className="btn btn-secondary mb-0"
                                                        style={{ marginTop: '22px' }}
                                                        onClick={() => {
                                                            var strgItems = strgItem;
                                                            strgItems['strg_' + ((Object.keys(strgItem).length + 1))] = '';
                                                            setStrgItem(strgItems);
                                                            setStrgItemRefresh(strgItemRefresh + 1)
                                                        }}
                                                    >{'+ ' + t('Add')}</a>
                                                </Grid>
                                            ) : ''}
                                        </Grid>


                                        <Grid my={2} container spacing={2}>
                                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                                <label>Modultyp</label>
                                                <select readOnly={readOnly} type="text" className=" form-control" {...register('modultyp', { required: true })} required>
                                                    <option>--</option>
                                                    {
                                                        modules && modules.map((modules, index) => {
                                                            return <option key={index} value={modules.id}>{modules.name}</option>
                                                        })
                                                    }

                                                </select>
                                                <div className="invalid-feedback">{errors.modultyp && errors.modultyp.message}</div>
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                                <label>Modulanzahl</label>
                                                <input readOnly={readOnly} type="number" className=" form-control" placeholder="Modulanzahl" {...register('modulanzahl', { required: true })} required />
                                                <div className="invalid-feedback">{errors.modulanzahl && errors.modulanzahl.message}</div>
                                            </Grid>
                                        </Grid>


                                        <Grid my={2} container spacing={2}>
                                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                                <label>Stecker System</label>
                                                <input readOnly={readOnly} type="text" className=" form-control" placeholder="Stecker System" {...register('stecker_system', { required: true })} required />
                                                <div className="invalid-feedback">{errors.stecker_system && errors.stecker_system.message}</div>

                                            </Grid>

                                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                                <label>Montagesystem</label>
                                                <input readOnly={readOnly} type="text" className=" form-control" placeholder="Montagesystem" {...register('montagesystem', { required: true })} required />
                                                <div className="invalid-feedback">{errors.montagesystem && errors.montagesystem.message}</div>

                                            </Grid>
                                        </Grid>

                                        <SoftBox
                                            display="flex"
                                            flexDirection="column"
                                            justifyContent="flex-end"
                                            height="100%"
                                        >
                                            <SoftBox mb={1} ml={0.5} mt={3} lineHeight={0} display="inline-block">
                                                <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                    {t('Description')}
                                                </SoftTypography>
                                            </SoftBox>
                                            {readOnly ? (
                                                <>
                                                    {description}
                                                </>
                                            ) : (
                                                <SoftEditor
                                                    value={description}
                                                    onChange={setDescription}
                                                />
                                            )}
                                        </SoftBox>
                                    </SoftBox>

                                </Card>
                            </SoftBox>

                            <SoftBox my={2}>
                                <Card>
                                    <SoftBox p={3}>
                                        <SoftTypography variant="h5">{t('Reklmation Files')}</SoftTypography>
                                    </SoftBox>

                                    <SoftBox px={3} pb={3}>
                                        <DropZoneTabs
                                            tabs={reklmationDropZoneTabs}
                                            defaultFiles={tabFiles}
                                            defaultTab={'reklamationFotos'}
                                            callBackData={setCallBackData}
                                            readOnly={readOnly}
                                            dropZonValidationError={dropZonValidationError}
                                        />
                                    </SoftBox>
                                </Card>
                            </SoftBox>


                            {JSON.parse(Cookies.get('permissions')).indexOf("dabhU") !== -1 ? (
                                <SoftBox my={2}>
                                    <Card sx={{ overflow: "visible" }}>
                                        <SoftBox p={3}>
                                            <SoftTypography variant="h5">{t(' Skizze/Modulbelegung')}</SoftTypography>
                                        </SoftBox>

                                        <SignaturePad options={{ minWidth: 1, maxWidth: 1, penColor: 'rgb(0, 0, 0)' }} ref={signaturePadRef} />

                                        <Grid container px={2}>
                                            <Grid item sx={12} md={12} display="flex" justifyContent="center">
                                                <button type="button" className="btn btn-outline-dark" onClick={() => { signaturePadRef.current.clear(); }}>
                                                    <i className="fa fa-eraser"></i>
                                                </button>
                                            </Grid>
                                        </Grid>

                                    </Card>
                                </SoftBox>
                            ) : <></>}

                            <SoftBox>
                                {JSON.parse(Cookies.get('permissions')).indexOf("dabhU") !== -1 ? (
                                    <>
                                        <a href="#" className="btn btn-danger mx-2 d-none" onClick={() => { clearForm() }}>
                                            {t('Cancel')}
                                        </a>
                                        {!saving && (
                                            <button id="dcAbname" type="submit" className="btn btn-primary">
                                                {t('Save')}
                                            </button>
                                        )}
                                        {saving && (
                                            <button type="button" className="btn btn-secondary" disabled>
                                                {t('wird geladen...')}
                                            </button>
                                        )}
                                    </>
                                ) : <></>}
                                <a target='_blank' className="btn btn-primary mx-2" href={Cookies.get('backend_url') + 'reclamation/dcabname/' + (id ? id : 'empty')}>{t('PDF')}</a>
                            </SoftBox>
                        </form>
                    ) : displayTab === 'sign' ?
                        <>
                            <DCabnahmeSign saveBtnDisplay={true} flashLoader={false} pdfDisplay={true} project_id={params?.id} reklamationId={props?.reklamationId} id={id} signFrom={'reklamation'} />
                        </>
                        : ''}
                </div>
                <div className="col-lg-3 inner-main">
                    <div className="row">
                        <div className="col-12">
                            <div className="card mb-3">
                                <div className="card-body p-3">
                                    <div className="d-flex">
                                        <div>
                                            <div className="icon icon-shape bg-gradient-dark text-center border-radius-md">
                                                <i className="ni ni-money-coins text-lg opacity-10" aria-hidden="true" />
                                            </div>
                                        </div>
                                        <div className="ms-3">
                                            <div className="numbers">
                                                <p className="text-sm mb-0 text-capitalize font-weight-bold">{t('Project Nr.')}</p>
                                                <h5 className="font-weight-bolder mb-0">
                                                    {props.project_id ? props.project_id : '---'}
                                                </h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {displayTab === 'form' ? (
                                <>
                                    {params?.id && id ?
                                        <button
                                            className="btn btn-primary"
                                            style={{ width: '100%', backgroundColor: '#CB0C9F', borderColor: '#CB0C9F' }}
                                            onClick={() => { setDisplayTab('sign') }}
                                        >{t('Unterschreiben')}</button>
                                        : <button
                                            className="btn btn-secondary"
                                            style={{ width: '100%' }}
                                        >{t('Unterschreiben')}</button>
                                    }
                                </>
                            ) : (
                                <button
                                    className="btn btn-primary"
                                    style={{ width: '100%', backgroundColor: '#CB0C9F', borderColor: '#CB0C9F' }}
                                    onClick={() => { setDisplayTab('form') }}
                                >{t('Edit Abnahmeprotokoll')}</button>
                            )}
                        </div>
                    </div>

                    {/*<Comisioning projectid={props.project_id} id={id} type={'dc'}></Comisioning>*/}
                    <ComisioningModal reklamationId={props?.reklamationId} abnahme={'reklmation'} getdataUrl={'dc_reklmation_abhnahmeprotokoll/'} saveDataUrl={''} projectid={props.project_id} id={id} type={'dc'} setRefresh={setRefresh} />

                    {/* <Timeline refresh={refresh} dcStatusEdit={true} /> */}
                </div>
                {renderSuccessSB}
            </div>
        </>
    )
}

export default DCReklmationProtokoll