import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Navigate } from "react-router-dom";
import { callFetch } from "../../../helpers/callFetch";
import { useTranslation } from "react-i18next";
import dateFormat from "dateformat";
import flatpickr from "flatpickr";
import { NumericFormat } from "react-number-format";
import SoftAlert from "components/SoftAlert";
import SoftSnackbar from "components/SoftSnackbar";
import { Checkbox } from "@mui/material";
import Select from "react-select";
import { Divider, Icon, Menu, MenuItem } from "@mui/material";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import LieferantentCreateModel from "pages/hr/Lieferanten/CreateModel";
import Transaction from "pages/finance/EngangsInvoice/Transactions";

const IncomingInviceCreate = (props) => {
  const { t } = useTranslation();
  const [quationId, setQuationId] = useState(0);
  const [auftragId, setAftragId] = useState(0);
  const [currency, setCurrency] = useState("EUR");
  const [contactPersons, setContactPersons] = useState([]);
  const [data, setData] = useState([]);
  const [emails, setEmails] = useState([
    { name: "", email: "", currentEmail: "" },
  ]);
  const [items, setItems] = useState([
    {
      id: 0,
      title: "",
      quantity: 1,
      price: 0,
      currentPrice: 0,
      total: 0,
      artikel: "",
    },
  ]);
  const [itemSummery, setItemSummery] = useState({
    items: [],
    emails: [],
    subTotal: 0,
    amountPerchantage: 30,
    discountPercentage: 0,
    discount: 0,
    taxPercentage: 19,
    tax: 0,
    total: 0,
  });
  const [quotation, setQuotation] = useState(0);
  const [saving, setSaving] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const [refresh2, setRefresh2] = useState(0);
  const [refresh3, setRefresh3] = useState(0);
  const [receivedError, setReceivedError] = useState(null);
  const [invoice_id, setInvoiceId] = useState(0);
  const [qq, setQqq] = useState([{ quantity: 0 }]);
  const [isShow, setIsShow] = useState(true);
  const [einkaufData, setEinkaufData] = useState([]);
  const [selectedProjectId, setSelectedProjectId] = useState({ id: "" });
  const {
    register,
    handleSubmit,
    setError,
    setValue,
    formState: { errors },
  } = useForm();

  const [menu, setMenu] = useState(null);
  const [status, setStatus] = useState("");

  const openMenu = (event) => setMenu(event.currentTarget);
  const closeMenu = (statu = "") => {
    setMenu(null);
    setStatus(statu);
  };

  const renderMenu = (
    <Menu
      anchorEl={menu}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "left" }}
      open={Boolean(menu)}
      onClose={() => {
        closeMenu("");
      }}
      keepMounted
    >
      <MenuItem
        onClick={() => {
          closeMenu("");
          document.getElementById("createLieferantentModalBtn").click();
        }}
      >
        {t("Lieferanten Hinzufugen")}
      </MenuItem>
    </Menu>
  );

  useEffect(() => {
    var subTotal = 0;
    items.map((item) => {
      subTotal += item.total;
    });
    itemSummery.subTotal = subTotal;
    itemSummery.amount = parseFloat(
      (itemSummery.amountPerchantage / 100) * itemSummery.subTotal
    );
    // setSubtotal(subTotal);
    setValue("amountPerchantage", itemSummery.amountPerchantage);
    setValue("tax", itemSummery.taxPercentage);
    itemSummery.tax = parseFloat(
      (itemSummery.amount * (itemSummery.taxPercentage / 100)).toFixed(2)
    );
    itemSummery.discount = parseFloat(
      (itemSummery.amount * (itemSummery.discountPercentage / 100)).toFixed(2)
    );
    itemSummery.total = subTotal - itemSummery.discount + itemSummery.tax;
    itemSummery.items = items;
    setRefresh2(refresh2 + 1);
  }, [refresh]);

  useEffect(() => {}, [refresh2]);

  useEffect(() => {
    if (quationId > 0) {
      callFetch("quation/" + quationId + "/edit", "GET", []).then((res) => {
        items[0].id = res.data.speicher;
        items[0].price = Number(res.data.gesami_netto);
        items[0].quantity = 1;
        items[0].currentPrice = Number(res.data.gesami_netto);
        items[0].total = items[0].currentPrice * items[0].quantity;

        setItems(items);
        setRefresh(refresh + 1);
        setRefresh2(refresh2 + 1);
      });
    } else if (auftragId > 0) {
      callFetch("order-add-invoice/" + auftragId, "GET", []).then((res) => {
        if (res.error == "100") {
          setReceivedError(t("Inovice has created for receivable amount"));
        }
        if (res?.data?.receivable) {
          items[0].id = 1;
          items[0].price = Number(res.data.order.netto);
          items[0].quantity = 1;
          items[0].currentPrice = Number(res.data.order.netto);
          items[0].total = items[0].currentPrice * items[0].quantity;

          setItems(items);
          setRefresh(refresh + 1);
          setRefresh2(refresh2 + 1);
          setValue("amountPerchantage", res?.data?.receivable);
          itemSummery.amountPerchantage = res?.data?.receivable;
          setItemSummery(itemSummery);
          setReceivedError(null);
        }
        // items[0].id = 1;
        // items[0].price = Number(res.data.order.netto);
        // items[0].quantity = 1;
        // items[0].currentPrice = Number(res.data.order.netto);
        // items[0].total = items[0].currentPrice * items[0].quantity;

        // setItems(items);
        // setRefresh(refresh + 1);
        // setRefresh2(refresh2 + 1);
      });
    }
  }, [quationId + auftragId]);

  useEffect(() => {
    flatpickr(".flat-pickr");
    callFetch("incoming-invoice/create", "GET", []).then((res) => {
      setData(res?.data);
      setValue("eingangs_rechungs_nummer", res?.data?.incommingInvouceNumber);
      setRefresh(refresh + 1);
    });
  }, [refresh3]);

  useEffect(() => {
    if (!props?.projectId) return;

    setValue("project_id", props?.projectId);
    setSelectedProjectId({
      id: props?.projectId,
      //vorname: e?.vorname,
      //name: e?.name,
      //telefonnummer: e?.telefonnummer,
    });
  }, [props?.projectId]);

  function deleteProduct() {
    var titems = [];
    items.map((t) => {
      if (!t) return;
      titems.push(t);
    });
    setItems(titems);
    setRefresh(refresh + 1);
  }

  function deleteEmail() {
    var temails = [];
    emails.map((e) => {
      if (!e && e !== "") return;
      temails.push(e);
    });
    setEmails(temails);
    itemSummery.emails = temails;
    setItemSummery(itemSummery);
    setRefresh2(refresh2 + 1);
  }

  const [successSB, setSuccessSB] = useState(false);
  const openSuccessSB = () => setSuccessSB(true);
  const closeSuccessSB = () => setSuccessSB(false);
  const renderSuccessSB = (
    <SoftSnackbar
      color="success"
      icon="check"
      title="Success"
      content="Incomming Invoice successfully created"
      dateTime="Just Now"
      open={successSB}
      onClose={closeSuccessSB}
      close={closeSuccessSB}
      bgWhite
    />
  );

  const onSubmit = (formData) => {
    setSaving(true);
    formData.brutto = itemSummery.total;
    // formData.quation_id = quationId;
    formData.product_details = JSON.stringify(itemSummery);
    callFetch("incoming-invoice", "POST", formData, setError).then((res) => {
      if (receivedError == null && res.message === "success") {
        openSuccessSB();
      }
      setSaving(false);

      if (!res.ok) return;

      if (document.getElementById("ZahlungseingangeListRefresh")) {
        document.getElementById("ZahlungseingangeListRefresh").click();
      }

      if (document.getElementById("InvoiceoverviewRefresh")) {
        document.getElementById("InvoiceoverviewRefresh").click();
      }

      if (props?.redirect == false) {
        if (props?.popupClose == true) {
          document.getElementById("createIncommingInvoiceClose").click();
        }
        if (props?.IncommingInvoiceRefresh == true) {
          document.getElementById("IncommingInvoiceRefresh").click();
        }
      } else {
        setSubmitSuccess(true);
      }
    });
  };

  return submitSuccess ? (
    <Navigate to={`/eingangsrechnungen`} />
  ) : (
    <>
      <form
        className={`needs-validation ${
          Object.keys(errors).length ? "was-validated" : ""
        }`}
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        autoComplete="off"
      >
        <div className="modal-header">
          <button
            type="button"
            id="cls-btn"
            data-bs-dismiss="modal"
            aria-label="Close"
            className=" lose btn btn-icon-only btn-rounded btn-link text-primary mb-0 me-3 btn-sm d-flex align-items-center justify-content-center"
            style={{ fontSize: "12px", position: "absolute", right: "0px" }}
          >
            {/* <i class="fa-sharp fa-solid fa-times icon icon-shape icon-sm  text-center d-flex align-items-center justify-content-center"></i> */}
            <i class="ph ph-x  text-center d-flex align-items-center justify-content-center text-primary font-size-20"></i>
          </button>
          <SoftTypography
            variant="button"
            fontSize="20px"
            fontWeight="600"
            style={{ color: "#273042" }}
          >
            {" "}
            {t("Add Payment")}
          </SoftTypography>
        </div>
        <div className="modal-body">
          <div className="row mt-2">
            <div className={props?.col ? props?.col : "col-md-9"}>
              <div className="">
                <div className="">
                  <div className="row g-3">
                    <div className="col-md-6 mt-0">
                      <div className="form-group mb-2">
                        <label className="text-disabled">
                          {t("EingangsRechnungennummer.")} *
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder={t("eg: 700001")}
                          {...register("eingangs_rechungs_nummer", {
                            required: true,
                          })}
                          readOnly
                          required
                        />
                        <div className="invalid-feedback">
                          {errors.eingangs_rechungs_nummer &&
                            errors.eingangs_rechungs_nummer.message}
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6 mt-0 ">
                      <div className="form-group mb-2">
                        <label>{t("Lieferant & Partner")} *</label>
                        <br />
                        <select
                          className="form-control"
                          {...register("lieferanten_id", {
                            required: true,
                          })}
                          required
                        >
                          <option value="">--</option>
                          {data?.lieferantan &&
                            data?.lieferantan?.map((item) => (
                              <option key={item.id} value={item.id}>
                                {item.lieferanten_nummer +
                                  " - " +
                                  (item?.firma_name ? item?.firma_name : "")}
                              </option>
                            ))}
                        </select>
                        <div className="invalid-feedback">
                          {errors.lieferanten_id &&
                            errors.lieferanten_id.message}
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6 mt-0">
                      <div className="form-group mb-2">
                        <label>{t("Rechungsnummer")} *</label>
                        <br />
                        <input
                          type="text"
                          className="form-control"
                          placeholder={t("Eingangsrechnungnummer")}
                          {...register("rechungs_nummer")}
                        />
                        <div className="invalid-feedback">
                          {errors.currency && errors.currency.message}
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6 mt-0">
                      <div className="form-group mb-2">
                        <label>{t("Rechnungsdatum")} *</label>
                        <input
                          type="date"
                          className="form-control flat-pickr"
                          placeholder={t("eg. 2001-03-20")}
                          defaultValue={dateFormat(new Date(), "yyyy-mm-dd")}
                          {...register("rechungs_date", {
                            required: true,
                          })}
                          required
                        />
                        <div className="invalid-feedback">
                          {errors.rechungs_date && errors.rechungs_date.message}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div
                      className={`${
                        isShow ? "col-md-12 mt-1" : "col-md-12 mt-1"
                      }`}
                    >
                      <div className="border-primary border-radius-5 p-3">
                        <div className="d-flex justify-content-between">
                          <p className="font-size-16 mb-0 text-primary">
                            {t("Projektbezogene Rechnung buchen")}
                          </p>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              onChange={(e) => setIsShow(!isShow)}
                              checked={isShow}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    {isShow && (
                      <div className="col-md-6 mt-0">
                        <label>{t("Projekte")} *</label>

                        <Select
                          placeholder={t("Select")}
                          options={data?.project ? data?.project : {}}
                          getOptionLabel={(option) =>
                            (option.id ? option.id : "") +
                            (option.vorname ? " - " + option.vorname : "") +
                            " " +
                            (option?.name ? option?.name : "")
                          }
                          getOptionValue={(option) => option.id}
                          value={selectedProjectId}
                          onChange={(e) => {
                            setValue("project_id", e.id);
                            setSelectedProjectId({
                              id: e?.id,
                              vorname: e?.vorname,
                              name: e?.name,
                              telefonnummer: e?.telefonnummer,
                            });
                          }}
                          required
                        />
                        {selectedProjectId?.id ? (
                          <p
                            className="mt-1"
                            style={{
                              fontSize: "12px",
                              textDecoration: "underline",
                              fontWeight: "500",
                            }}
                          >
                            {(selectedProjectId?.id
                              ? selectedProjectId?.id
                              : "") +
                              (selectedProjectId?.vorname
                                ? " - " + selectedProjectId?.vorname
                                : "") +
                              " " +
                              (selectedProjectId?.name
                                ? selectedProjectId?.name
                                : "") +
                              ", " +
                              (selectedProjectId?.telefonnummer
                                ? selectedProjectId?.telefonnummer
                                : "")}
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                    )}
                    <div
                      className={`${
                        isShow ? "col-md-6 mt-0" : "col-md-6 mt-0"
                      }`}
                    >
                      <label>{t("Liefer- Abwicklungsdatum")} *</label>
                      <input
                        type="date"
                        className="form-control mb-4 flat-pickr"
                        placeholder={t("eg. 2001-03-20")}
                        defaultValue={dateFormat(new Date(), "yyyy-mm-dd")}
                        {...register("processing_date", {
                          required: true,
                        })}
                        required
                      />
                      <div className="invalid-feedback">
                        {errors.processing_date &&
                          errors.processing_date.message}
                      </div>
                    </div>
                    <div
                      className={`${
                        isShow ? "col-md-6 mt-0" : "col-md-6 mt-0"
                      }`}
                    >
                      <label>{t("Zahlung Erfolgt")} *</label>
                      <select
                        className="form-control"
                        {...register("payment_made", { required: true })}
                        required
                      >
                        <option value="Ja">{t("Ja")}</option>
                        <option value="Nein">{t("Nein")}</option>
                      </select>
                      <div className="invalid-feedback">
                        {errors.payment_made && errors.payment_made.message}
                      </div>
                    </div>

                    <div className="col-md-6 mt-0">
                      <label>{t("Abteilung")} *</label>
                      <select
                        className="form-control mb-2"
                        {...register("abteilung", { required: true })}
                        required
                      >
                        <option value={""}>{t("Select")}</option>
                        <option value={"Dachmontage"}>Dachmontage</option>
                        <option value={"Elektromontage"}>Elektromontage</option>
                        <option value={"Kleinmaterial"}>Kleinmaterial</option>
                        <option value={"Module"}>Module</option>
                        <option value={"Speicher"}>Speicher</option>
                        <option value={"Wechselrichter"}>Wechselrichter</option>
                      </select>
                      <div className="invalid-feedback">
                        {errors.abteilung && errors.abteilung.message}
                      </div>
                    </div>
                  </div>

                  <div className="">
                    {items &&
                      items.map((item, i) => (
                        <div
                          key={i}
                          className="bg-secondary p-3 border-radius-8 mb-3 mt-2"
                        >
                          {/* {i > 0 && <hr />} */}
                          <div className="row">
                            <div className="col-md-12 mt-0">
                              <div>
                                <label>{t("Product")}</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder={t("Select Product")}
                                  onChange={(e) => {
                                    items[i].id = e.target.value;
                                    items[i].artikel = e.target.value;
                                    setItems(items);
                                    setRefresh(refresh + 1);
                                  }}
                                />
                                {/* <option value="">--</option>
                                                      {data?.artikel && data?.artikel?.map((product, i) => (
                                                          <option key={i} value={product.id}>{product.artikel_id}</option>
                                                      ))} */}
                              </div>
                            </div>
                            <div className="col-md-6 mt-2">
                              <div className="form-group">
                                <label>{t("Qty/Hrs")}</label>
                                <input
                                  type="number"
                                  className="form-control"
                                  placeholder="eg. 0.00"
                                  defaultValue={1}
                                  onChange={(e) => {
                                    let value = 0;
                                    if (
                                      e.target.value.length &&
                                      !isNaN(e.target.value)
                                    )
                                      value = e.target.value;
                                    if (value > 0) {
                                      setQqq(parseFloat(value));
                                    }
                                    items[i].quantity = parseFloat(value);
                                    items[i].total =
                                      items[i].currentPrice * items[i].quantity;
                                    setItems(items);
                                    setRefresh(refresh + 1);
                                  }}
                                />
                              </div>
                            </div>

                            <div className="col-md-6 mt-2">
                              <div className="form-group">
                                <label>{t("Betrag in € (Amount)")}</label>
                                <input
                                  type="number"
                                  className="form-control d-inline"
                                  placeholder="eg. 0.00"
                                  value={
                                    item.currentPrice ? item.currentPrice : ""
                                  }
                                  onChange={(e) => {
                                    let value = 0;
                                    if (
                                      e.target.value.length &&
                                      !isNaN(e.target.value)
                                    )
                                      value = e.target.value;
                                    items[i].currentPrice = parseFloat(value);
                                    items[i].total =
                                      items[i].currentPrice * items[i].quantity;
                                    setItems(items);
                                    setRefresh(refresh + 1);
                                  }}
                                />
                              </div>
                            </div>
                            <div>
                              <a
                                href="#"
                                className="text-danger d-flex align-items-center"
                                data-key={i}
                                onClick={() => {
                                  delete items[i];
                                  deleteProduct();
                                }}
                              >
                                <i class="ph ph-trash-simple text-danger font-size-20 me-2"></i>{" "}
                                Entfernen
                              </a>
                            </div>
                            {/* <div className="col-md-2">
                                              <label>{t('Unit Price')}</label>
                                              <input type="number" className="form-control" placeholder="eg. 0.00" value={item.currentPrice ? item.currentPrice : ''} onChange={(e) => {
                                                  let value = 0;
                                                  if (e.target.value.length && !isNaN(e.target.value))
                                                      value = e.target.value;
                                                  items[i].currentPrice = parseFloat(value);
                                                  items[i].total = items[i].currentPrice * items[i].quantity;
                                                  setItems(items);
                                                  setRefresh(refresh + 1);
                                              }} />
                                          </div> */}
                            {/* <div className="col-md-2">
                                              <label>{t('Amount')}</label>
                                              <input type="number" className="form-control d-inline" style={{ width: '84%' }} placeholder="eg. 0.00" value={item.total} readOnly />
                                              &nbsp;
                                              <i className="fa-solid fa-circle-xmark text-danger" data-key={i} onClick={() => { delete items[i]; deleteProduct(); }}></i>
                                          </div> */}
                          </div>
                        </div>
                      ))}

                    <div className="row mt-3">
                      <div className="col-md-4">
                        <a
                          className="btn btn-secondary align-content-center d-flex align-items-center"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setItems([
                              ...items,
                              {
                                id: 0,
                                title: "",
                                quantity: 0,
                                price: 0,
                                currentPrice: 0,
                                total: 0,
                              },
                            ]);
                            setRefresh(refresh + 1);
                          }}
                        >
                          <i class="ph ph-plus me-2"></i>
                          {t("Add Item")}
                        </a>
                      </div>
                    </div>

                    <div className="d-flex justify-content-between align-items-center">
                      <p className="font-size-14 text-secondary">
                        {t("Sub Total")}
                      </p>
                      <p className="font-size-14 text-primary">
                        <NumericFormat
                          value={itemSummery.subTotal}
                          displayType="text"
                          thousandSeparator={"."}
                          decimalSeparator=","
                          decimalScale={2}
                          fixedDecimalScale
                          suffix={currency == "EUR" ? " € " : " $ "}
                        />
                      </p>
                    </div>

                    <hr />

                    <div className="d-flex justify-content-between align-items-center">
                      <p className="font-size-14 text-secondary w-33">
                        {t("Discount")}
                      </p>
                      <div className="font-size-14 text-primary w-33">
                        <input
                          type="number"
                          className="form-control form-control-sm"
                          onChange={(e) => {
                            let value = 0;
                            if (e.target.value.length && !isNaN(e.target.value))
                              value = e.target.value;
                            itemSummery.discountPercentage = parseFloat(value);
                            setItemSummery(itemSummery);
                            setRefresh(refresh + 1);
                          }}
                        />
                      </div>
                      <p className="font-size-14 text-primary">
                        <NumericFormat
                          value={itemSummery.subTotal}
                          displayType="text"
                          thousandSeparator={"."}
                          decimalSeparator=","
                          decimalScale={2}
                          fixedDecimalScale
                          suffix={currency == "EUR" ? " € " : " $ "}
                        />
                      </p>
                    </div>

                    <hr />

                    <div className="d-flex justify-content-between align-items-center">
                      <p className="font-size-14 text-secondary w-33">
                        {t("Tax")}
                      </p>
                      <div className="w-33">
                        <select
                          {...register("tax")}
                          className="form-control form-control-sm"
                          onChange={(e) => {
                            let value = 19;
                            if (e.target.value.length && !isNaN(e.target.value))
                              value = e.target.value;
                            itemSummery.taxPercentage = parseFloat(value);
                            setItemSummery(itemSummery);
                            setRefresh(refresh + 1);
                          }}
                        >
                          <option value="19">19%</option>
                          <option value="0">0%</option>
                        </select>
                      </div>
                      <p className="font-size-14 text-primary">
                        <NumericFormat
                          value={itemSummery.tax}
                          displayType="text"
                          thousandSeparator={"."}
                          decimalSeparator=","
                          decimalScale={2}
                          fixedDecimalScale
                          suffix={currency == "EUR" ? " € " : " $ "}
                        />
                      </p>
                    </div>

                    <hr />

                    <div className="d-flex justify-content-between align-items-center">
                      <p className="font-size-14 text-secondary">
                        {t("Total")}
                      </p>
                      <p className="font-size-14 text-primary">
                        <NumericFormat
                          value={itemSummery.total}
                          displayType="text"
                          thousandSeparator={"."}
                          decimalSeparator=","
                          decimalScale={2}
                          fixedDecimalScale
                          suffix={currency == "EUR" ? " € " : " $ "}
                        />
                      </p>
                    </div>
                  </div>

                  {/*
                          <hr />
                          {emails && emails.map((email, i) => (
                              <div key={i} className="row mt-3">
                                  <div className="col-md-4">
                                      <label>{t('Name')}</label>
                                      <select className="form-control" value={email.name + '*' + email.email} onChange={(e) => {
                                          var cp = e.target.value.split('*');
                                          emails[i].name = cp[0];
                                          emails[i].email = cp[1];
                                          emails[i].currentEmail = cp[1];
                                          setEmails(emails);
                                          itemSummery.emails = emails;
                                          setItemSummery(itemSummery);
                                          setRefresh2(refresh2 + 1);
                                      }}>
                                          <option value="">--</option>
                                          {contactPersons && contactPersons.map((person, pi) => person.name && (
                                              <option key={pi} value={person.name + '*' + person.emails[0]}>{person.name}</option>
                                          ))}
                                      </select>
                                  </div>
                                  <div className="col-md-4">
                                      <label>{t('Email')}</label>
                                      <input type="email" style={{ width: '93%' }} className="form-control d-inline-block mt-1" value={email.currentEmail} onChange={(e) => {
                                          emails[i].currentEmail = e.target.value;
                                          setEmails(emails);
                                          itemSummery.emails = emails;
                                          setItemSummery(itemSummery);
                                          setRefresh2(refresh2 + 1);
                                      }} placeholder="eg. email@mail.com" />
                                      &nbsp;
                                      <i className="fa-solid fa-circle-xmark text-danger" data-key={i} onClick={() => { delete emails[i]; deleteEmail(); }}></i>

                                      <br />
                                  </div>
                              </div>
                          ))}
                          <p className="btn btn-dark mt-2" style={{ cursor: 'pointer' }} onClick={() => { setEmails([...emails, { name: '', email: '', currentEmail: '' }]); setRefresh2(refresh2 + 1); }}><i className="fa-solid fa-circle-plus"></i>&nbsp;{t('Add Contact Person')}</p>
                          */}

                  <div className="row mt-3">
                    <div className="col-md-5">
                      {receivedError && (
                        <SoftAlert color="error" dismissible>
                          {" "}
                          {receivedError}{" "}
                        </SoftAlert>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {props?.transections != false ? (
              <div className="col-md-3">
                {selectedProjectId?.id && isShow ? (
                  <Transaction projectId={selectedProjectId?.id} />
                ) : (
                  ""
                )}
              </div>
            ) : (
              ""
            )}
            {renderSuccessSB}

            <LieferantentCreateModel setRefresh={setRefresh3} />
          </div>
        </div>
        <div className="modal-footer">
          <div className="d-flex justify-content-between w-100">
            <button
              onClick={() => {
                closeMenu("");
                document.getElementById("createLieferantentModalBtn").click();
              }}
              type="button"
              className="btn btn-secondary mb-0 w-50 me-3"
            >
              {t("Lieferanten Hinzufugen")}
            </button>
            {!saving ? (
              <button type="submit" className="btn btn-primary w-50 mb-0">
                {t("Save")}
              </button>
            ) : (
              <button type="submit" className="btn btn-disabled mb-0" disabled>
                {t("Saving ...")}
              </button>
            )}
          </div>
        </div>
      </form>
    </>
  );
};

export default IncomingInviceCreate;
