import { React, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip } from 'chart.js';
import callFetch from "../../../../../../helpers/callFetch";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip);

const EstimatedProduction = ({ refreshKey, isOpen, value }) => {
  let params = useParams();

  const [originalData, setOriginalData] = useState({});
  const [visibleDatasets, setVisibleDatasets] = useState({
    produktion: true,
    verbrauch: true,
    eigenverbrauch: true,
  });

  useEffect(() => {
    if(!isOpen) return;
    callFetch("estimated-production-consumption/" + params.id, "GET", []).then((res) => {
      const resData = res?.response;

      const data = {
        labels: ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
        datasets: [
          {
            label: 'Produktion kWh',
            data: resData.produktion,
            backgroundColor: '#FF465B', // Green
            borderRadius: 4,
            borderSkipped: false,
            maxBarThickness: 8,
            hidden: !visibleDatasets.produktion,
          },
          {
            label: 'Verbrauch kWh',
            data: resData.verbrauch,
            backgroundColor: '#48BB78', // Blue
            borderRadius: 4,
            borderSkipped: false,
            maxBarThickness: 8,
            hidden: !visibleDatasets.verbrauch,
          },
          {
            label: 'Eigenverbrauch kWh',
            data: resData.eigenverbrauch,
            backgroundColor: '#208DFC', // Red
            borderRadius: 4,
            borderSkipped: false,
            maxBarThickness: 8,
            hidden: !visibleDatasets.eigenverbrauch,
          },
        ],
      };

      setOriginalData(data);

    }).catch(error => {
       console.log('error');
    });
  }, [params.id, refreshKey, isOpen, value]);

  // Function to toggle dataset visibility
  const handleCheckboxChange = (dataset) => {
    setVisibleDatasets((prev) => ({
      ...prev,
      [dataset]: !prev[dataset],
    }));
  };

  // Chart options
  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false, // Hide default Chart.js legend
      },
    },
    scales: {
      x: {
        categoryPercentage: 0.6,
        barPercentage: 0.6,
        grid: {
          drawBorder: false,
          display: true,
          drawOnChartArea: true,
          drawTicks: false,
          borderDash: [5, 5],
        },
        ticks: {
          display: true,
          padding: 10,
          color: "#9ca2b7",
          font: {
            size: 12,
            family: "Open Sans",
          },
        },
      },
      y: {
        beginAtZero: true,
        grid: {
          drawBorder: false,
          display: true,
          drawOnChartArea: true,
          drawTicks: false,
          borderDash: [5, 5],
        },
        ticks: {
          display: true,
          padding: 10,
          color: "#9ca2b7",
          font: {
            size: 12,
            family: "Open Sans",
          },
        },
      },
    },
  };

  return (
    <div>
      {/* Title Above Checkboxes */}
      <p className="mb-2 font-size-16 font-weight-600">Production and Consumption Data</p>

      {/* Bootstrap Checkbox Controls */}
      <div className="d-flex gap-3 mb-3">
        {/* Produktion kWh Checkbox */}
        <div className="form-check d-flex align-items-center">
          <input
            className="produktion form-check-input"
            type="checkbox"
            checked={visibleDatasets.produktion}
            onChange={() => handleCheckboxChange("produktion")}
            style={{
              appearance: "none",
              width: "18px",
              height: "18px",
              borderRadius: "4px",
              backgroundColor: visibleDatasets.produktion ? "#48BB78" : "transparent",
              cursor: "pointer",
            }}
          />
          <label className="form-check-label ms-2 text-primary position-relative top-2">
            Produktion kWh
          </label>
        </div>

        {/* Verbrauch kWh Checkbox */}
        <div className="form-check d-flex align-items-center">
          <input
            className="form-check-input verbrauch"
            type="checkbox"
            checked={visibleDatasets.verbrauch}
            onChange={() => handleCheckboxChange("verbrauch")}
            style={{
              appearance: "none",
              width: "18px",
              height: "18px",
              borderRadius: "4px",
              backgroundColor: visibleDatasets.verbrauch ? "#208DFC" : "transparent",
              cursor: "pointer",
            }}
          />
          <label className="form-check-label ms-2 text-primary position-relative top-2">
            Verbrauch kWh
          </label>
        </div>

        {/* Eigenverbrauch kWh Checkbox */}
        <div className="form-check d-flex align-items-center">
          <input
            className="form-check-input eigenverbrauch"
            type="checkbox"
            checked={visibleDatasets.eigenverbrauch}
            onChange={() => handleCheckboxChange("eigenverbrauch")}
            style={{
              appearance: "none",
              width: "18px",
              height: "18px",
              borderRadius: "4px",
              backgroundColor: visibleDatasets.eigenverbrauch ? "#E53E3E" : "transparent",
              cursor: "pointer",
            }}
          />
          <label className="form-check-label ms-2 text-primary position-relative top-2">
            Eigenverbrauch kWh
          </label>
        </div>
      </div>

      {/* Bar Chart */}
      <Bar
        data={{
          ...originalData,
          datasets: originalData.datasets?.map((dataset) => ({
            ...dataset,
            hidden: !visibleDatasets[dataset.label.split(" ")[0].toLowerCase()], // Toggle datasets based on state
          })),
        }}
        options={options}
      />
    </div>
  );
};

export default EstimatedProduction;
