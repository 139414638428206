import React from "react";
import { useEffect, useState, useRef } from "react";
import {
  useNavigate,
  Navigate,
  useParams,
  useLocation,
} from "react-router-dom";
import { useForm } from "react-hook-form";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import callFetch from "../../helpers/callFetch";
import Cookies from "js-cookie";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import dateFormat, { masks } from "dateformat";
import Timeline from "../reclamation/Timeline";
import CheckList from "./CheckList";
import Edit from "./Edit";
import ReclamationCreate from "../reclamation/Create";
import Comment from "components/Comments";
import { Divider } from "@mui/material";
import Breadcrumbs from "examples/Breadcrumbs";
function Show(props) {
  let params = useParams();
  const { t } = useTranslation();
  const [attachments, setAttachment] = useState([]);
  const [refresh, setRefresh] = useState(0);
  const [data, setData] = useState([]);
  const [seen, setSeen] = useState([]);
  const [saving, setSaving] = useState(false);
  const [ticketEditModal, setTicketEditModal] = useState(false);
  const [checkedChecklists, setCheckedChecklists] = useState([]);
  const location = useLocation();
  const [activeTab, setActiveTab] = useState("notizen");
  const route = useLocation().pathname.split("/").slice(1);
  let { rID } = useParams();
  const {
    register,
    handleSubmit,
    reset,
    setError,
    setValue,
    formState: { errors },
  } = useForm();

  const changeSeenStatus = () => {
    callFetch(
      "update-ticket-seen/" +
        (props?.viewTicket?.id ? props?.viewTicket?.id : params.id),
      "GET",
      []
    ).then((res) => {
      setSeen(res.seen);
    });
  };

  const hideReclamationModal = () => {
    var manageCheckListModal = document.getElementById(
      "createCheckListModalOpen"
    );
    manageCheckListModal.click();
  };

  useEffect(() => {
    if (!(props?.viewTicket?.id ? props?.viewTicket?.id : params.id)) return;

    callFetch(
      "ticket/" + (props?.viewTicket?.id ? props?.viewTicket?.id : params.id),
      "GET",
      []
    ).then((res) => {
      if (res?.data) {
        setData(res.data);
      } else {
        setData([]);
      }

      if (res?.data?.seen) {
        setSeen(res?.data?.seen);
      } else {
        setSeen([]);
      }

      if (res?.data?.attachments) {
        setAttachment(JSON.parse(res?.data?.attachments));
      } else {
        setAttachment([]);
      }
    });
  }, [props?.viewTicket?.id ? props?.viewTicket?.id : params.id, refresh]);

  const statusUpdate = () => {
    var formData = {};
    formData.status = data?.status ? 0 : 1;
    formData._method = "PUT";

    setSaving(true);
    callFetch(
      "ticket/" + (props?.viewTicket?.id ? props?.viewTicket?.id : params.id),
      "POST",
      formData,
      setError
    ).then((res) => {
      setSaving(false);
      setRefresh(refresh + 1);
      if (!res.ok) return;
    });
  };

  const checkListStatusUpdate = (id) => {
    var formData = {};
    formData.id = id;
    formData._method = "PUT";

    setSaving(true);
    callFetch("checklist-status-update", "POST", formData, setError).then(
      (res) => {
        setSaving(false);
        setRefresh(refresh + 1);
        if (!res.ok) return;
      }
    );
  };

  const checkListStatusUpdateUnchecked = (id) => {
    var formData = {};
    formData.id = id;
    formData._method = "PUT";

    setSaving(true);
    callFetch("checklist-status-update", "POST", formData, setError).then(
      (res) => {
        setSaving(false);
        setRefresh(refresh + 1);
        if (!res.ok) return;
      }
    );
  };

  return (
    <>
      <Breadcrumbs
        icon="Home"
        title={route[route.length - 1]}
        route={route}
        light={""}
      />
      <div className="mt-3"></div>
      <div className="row">
        <div className="col-sm-12 col-md-12 col-lg-8 col-xl-8 col-xxl-9 mb-3">
          <div className="card">
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <p className="font-size-14 text-secondary mb-0">Ticket</p>
                  <p className="font-size-20 text-primary font-weight-600 mb-0">
                    {data?.title}
                  </p>
                </div>
                <div className="d-flex">
                  {JSON.parse(Cookies.get("permissions")).indexOf("TicU") !==
                  -1 ? (
                    <>
                      {seen == 0 ? (
                        <a
                          href="#"
                          class="btn btn-secondary"
                          onClick={() => changeSeenStatus()}
                          style={{ marginRight: "5px" }}
                        >
                          <i class="ph ph-eye"></i>
                        </a>
                      ) : (
                        <a
                          href="#"
                          class="btn btn-secondary"
                          onClick={() => changeSeenStatus()}
                          style={{ marginRight: "5px" }}
                        >
                          <i class="ph ph-eye-slash"></i>
                        </a>
                      )}
                    </>
                  ) : (
                    <></>
                  )}

                  {JSON.parse(Cookies.get("permissions")).indexOf("TicU") !==
                  -1 ? (
                    <>
                      {JSON.parse(Cookies.get("permissions")).indexOf(
                        "RecC"
                      ) !== -1 ? (
                        <a
                          className="btn btn-primary ms-2 mb-0 d-flex"
                          data-bs-toggle="modal"
                          data-bs-target="#createReclamationModalOpenticket"
                        >
                          <i class="ph ph-note-pencil me-2"></i>{" "}
                          {t("Reklamation Generieren")}
                        </a>
                      ) : (
                        <></>
                      )}
                    </>
                  ) : (
                    <></>
                  )}

                  {props?.editButton !== false ? (
                    <div class="dropdown ms-2">
                      <button
                        class="btn border"
                        type="button"
                        id="dotsDropdown"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <img
                          src="/assets/icons/DotsThreeVertical.svg"
                          className="dropdown-btn-empty-icon-img"
                        />
                      </button>
                      <ul class="dropdown-menu" aria-labelledby="dotsDropdown">
                        <li>
                          <a
                            class="dropdown-item"
                            onClick={() => {
                              setTicketEditModal(true);
                            }}
                            data-bs-toggle="modal"
                            data-bs-target="#TicketModalEdit"
                            href="#"
                          >
                            <i className="icon-sola-pencil-simple position-relative top-3"></i>{" "}
                            <span className="position-relative left-4">
                              {t("Bearbeiten")}
                            </span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>

              <div className="mt-3">
              {JSON.parse(Cookies.get("permissions")).indexOf(
                        "TicU"
                      ) !== -1 ? (
                        <div className="mt-0">
                          {data?.status == 1 ? (
                            <button
                              type="button"
                              className="btn btn-success mb-0 d-flex"
                              onClick={() => {
                                statusUpdate();
                              }}
                              style={{ 
                                backgroundColor: "#48BB78",
                                boxShadow: "none",
                              }}
                            >
                              <i class="ph ph-check-circle font-size-20 me-2"></i> 
                              Erledigt
                            </button>
                          ) : (
                            <>
                              <button
                                type="button"
                                className="btn btn-secondary mb-0 d-flex"
                                onClick={() => {
                                  statusUpdate();
                                }}
                                style={{ 
                                  boxShadow: "none",
                                }}
                              >
                                <i class="ph ph-check font-size-20 me-2"></i>
                                {t("Open")}
                              </button>
                            </>
                          )} 
                        </div>
                      ) : (
                        <></>
                      )}
              </div>

              <div className="bg-secondary border-radius-8 p-3 mt-3">
                <p className="font-size-16 font-weight-500 mb-0 text-primary mb-2">
                  Beschreibung{" "}
                </p>
                <p className="font-size-14 text-secondary">
                  {data && data.description}
                </p>
              </div>

              <div className="bg-secondary border-radius-8 p-3 mt-3">
                <p className="font-size-16 font-weight-500 mb-0 text-primary mb-2">
                  Attachements
                </p>
                <div className="">
                  <div className="row">
                    {attachments.map(function (data, i) {
                      return (
                        <div className="col-sm-3">
                          <div>
                            <a
                              target="_blank"
                              download
                              href={
                                Cookies.get("backend_url") +
                                "storage/attachments/" +
                                data?.attachment
                              }
                            >
                              {["jpeg", "jpg", "png"].indexOf(
                                data?.extension
                              ) !== -1 ? (
                                <img
                                  style={{
                                    height: "170px",
                                    width: "170px",
                                    objectFit: "contain",
                                    borderRadius: "8px",
                                  }}
                                  src={
                                    Cookies.get("backend_url") +
                                    "storage/attachments/" +
                                    data?.attachment
                                  }
                                />
                              ) : (
                                <div className="placeholder">
                                  {data?.extension}
                                </div>
                              )}
                            </a>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>

              <div className="mb-3 row mt-3">
                <div className="col-sm-12">
                  <div className="">
                    <div className=" callcenter">
                      <ul
                        className="nav nav-tabs nav-underline tabs-res"
                        id="myTab"
                        role="tablist"
                        style={{ width: "97.5%" }}
                      >
                        {JSON.parse(Cookies.get("permissions")).indexOf(
                          "notizen"
                        ) !== -1 ? (
                          <li className="nav-item" role="presentation">
                            <button
                              className="nav-link active"
                              id="home-tab"
                              data-bs-toggle="tab"
                              data-bs-target="#home-tab-pane"
                              type="button"
                              role="tab"
                              aria-controls="home-tab-pane"
                              aria-selected="true"
                              onClick={() => {
                                setActiveTab("notizen");
                              }}
                            >
                              {" "}
                              Notizen
                            </button>
                          </li>
                        ) : (
                          <></>
                        )}

                        {JSON.parse(Cookies.get("permissions")).indexOf(
                          "notizen"
                        ) !== -1 ? (
                          <li className="nav-item" role="presentation">
                            <button
                              className="nav-link"
                              id="auf-tab"
                              data-bs-toggle="tab"
                              data-bs-target="#auf-tab-pane"
                              type="button"
                              role="tab"
                              aria-controls="auf-tab-pane"
                              aria-selected="true"
                              onClick={() => {
                                setActiveTab("aufgaben");
                              }}
                            >
                              {" "}
                              Aufgaben
                            </button>
                          </li>
                        ) : (
                          <></>
                        )}
                      </ul>
                      <div className="tab-content" id="myTabContent">
                        {JSON.parse(Cookies.get("permissions")).indexOf(
                          "notizen"
                        ) !== -1 ? (
                          <div
                            className="tab-pane fade show active pt-3 tabs"
                            id="home-tab-pane"
                            role="tabpanel"
                            aria-labelledby="home-tab"
                            tabIndex="0"
                          >
                            {activeTab === "notizen" ? (
                              <Comment
                                id={
                                  props?.viewTicket?.id
                                    ? props?.viewTicket?.id
                                    : params.id
                                }
                                from={"ticket"}
                              />
                            ) : (
                              ""
                            )}
                          </div>
                        ) : (
                          <></>
                        )}

                        <div
                          className="tab-pane fade pt-3 tabs"
                          id="auf-tab-pane"
                          role="tabpanel"
                          aria-labelledby="auf-tab"
                          tabIndex="0"
                        >
                          <div className="border-primary border-radius-8 p-3">
                            <div className="d-flex justify-content-between align-items-center">
                              <p className="font-size-20 font-weight-600 mb-0">
                                Aufgaben
                              </p>
                              <a
                                class="btn-link btn-plus-icon btn-icon-primary"
                                onClick={hideReclamationModal}
                                id="checkListModalOpen"
                              >
                                Aufgabe hinzufügen
                              </a>
                            </div>

                            <ul
                              className="nav nav-tabs nav-underline tabs-res mt-3"
                              id="myTab"
                              role="tablist"
                            >
                              <li className="nav-item" role="presentation">
                                <button
                                  className="nav-link active"
                                  id="open-tab"
                                  data-bs-toggle="tab"
                                  data-bs-target="#open-tab-pane"
                                  type="button"
                                  role="tab"
                                  aria-controls="open-tab-pane"
                                  aria-selected="true"
                                >
                                  Offene Aufgaben
                                </button>
                              </li>
                              <li className="nav-item" role="presentation">
                                <button
                                  className="nav-link"
                                  id="completed-tab"
                                  data-bs-toggle="tab"
                                  data-bs-target="#completed-tab-pane"
                                  type="button"
                                  role="tab"
                                  aria-controls="completed-tab-pane"
                                  aria-selected="false"
                                >
                                  Erledigte Aufgaben
                                </button>
                              </li>
                            </ul>

                            <div className="tab-content" id="myTabContent">
                              <div
                                className="tab-pane fade show active pt-3 tabs"
                                id="open-tab-pane"
                                role="tabpanel"
                                aria-labelledby="open-tab"
                                tabIndex="0"
                              >
                                {data?.checklists &&
                                  data?.checklists.map((checklist) => (
                                    <>
                                      {checklist.status == 1 ? (
                                        <></>
                                      ) : (
                                        <div class="form-check">
                                          <input
                                            class="form-check-input"
                                            onChange={() => {
                                              const isChecked =
                                                checkedChecklists.includes(
                                                  checklist.id
                                                );
                                              if (isChecked) {
                                                // Remove from checkedChecklists if already checked
                                                setCheckedChecklists((prev) =>
                                                  prev.filter(
                                                    (id) => id !== checklist.id
                                                  )
                                                );
                                                checkListStatusUpdateUnchecked(
                                                  checklist.id
                                                ); // Add your update function here if needed
                                              } else {
                                                // Add to checkedChecklists if not checked
                                                setCheckedChecklists((prev) => [
                                                  ...prev,
                                                  checklist.id,
                                                ]);
                                                checkListStatusUpdate(
                                                  checklist.id
                                                ); // Add your update function here if needed
                                              }
                                            }}
                                            type="checkbox"
                                            id={"customCheck-" + checklist.id}
                                          />
                                          <SoftTypography
                                            variant="p"
                                            className="text-justify"
                                            color="text"
                                            style={{ textAlign: "justify" }}
                                          >
                                            {checklist.title}
                                          </SoftTypography>
                                        </div>
                                      )}
                                    </>
                                  ))}
                              </div>

                              <div
                                className="tab-pane fade pt-3 tabs"
                                id="completed-tab-pane"
                                role="tabpanel"
                                aria-labelledby="completed-tab"
                                tabIndex="0"
                              >
                                 {data?.checklists &&
                                  data?.checklists.map((checklist) => (
                                    <>
                                      {checklist.status == 1 ? (
                                        <div class="form-check">
                                          <input
                                            class="form-check-input"
                                            checked
                                            onChange={() => {
                                              const isChecked =
                                                checkedChecklists.includes(
                                                  checklist.id
                                                );
                                              if (isChecked) {
                                                // Remove from checkedChecklists if already checked
                                                setCheckedChecklists((prev) =>
                                                  prev.filter(
                                                    (id) => id !== checklist.id
                                                  )
                                                );
                                                checkListStatusUpdateUnchecked(
                                                  checklist.id
                                                ); // Add your update function here if needed
                                              } else {
                                                // Add to checkedChecklists if not checked
                                                setCheckedChecklists((prev) => [
                                                  ...prev,
                                                  checklist.id,
                                                ]);
                                                checkListStatusUpdate(
                                                  checklist.id
                                                ); // Add your update function here if needed
                                              }
                                            }}
                                            type="checkbox"
                                            id={"customCheck-" + checklist.id}
                                          />
                                          <SoftTypography
                                            variant="p"
                                            className="text-justify"
                                            color="text"
                                            style={{ textAlign: "justify" }}
                                          >
                                            {checklist.title}
                                          </SoftTypography>
                                        </div>
                                      ) : (
                                         <></>
                                      )}
                                    </>
                                  ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
        <div className="col-sm-12 col-md-12 col-lg-4 col-xl-4 col-xxl-3 pl-4 project-details-right-side">
          <div className="card">
            <div className="card-body">
              <div class="details">
                <h6 className="text-primary font-size-16 font-weight-500">
                  Ticketinformationen
                </h6>

                <div className="mb-2">
                  <p className="font-size-14 text-secondary mb-0">
                    <i class="ph ph-clipboard-text font-size-20"></i>
                    <span className="position-relative bottom-4 left-4">
                      Ticket Nr.
                    </span>
                  </p>
                  <div class="profile">
                    <span>T-{data && data.id}</span>
                    <span style={{ fontWeight: "300", fontStyle: "italic" }}>
                      {data?.assignorderprocessing_id
                        ? "DC-" + data?.assignorderprocessing_id
                        : ""}
                    </span>
                  </div>
                </div>

                <div className="mb-2">
                  <p className="font-size-14 text-secondary mb-0">
                    <span className="position-relative left-2">
                      Priorität ist
                    </span>
                  </p>
                  <div class="profile">
                    <span className="badge badge-danger">{data?.priority}</span>
                  </div>
                </div>

                {data?.project_id ? (
                  <div className="mb-2">
                    <p className="font-size-14 text-secondary mb-0">
                      <span className="position-relative left-2">Projekt</span>
                    </p>
                    <div class="profile">
                      <NavLink
                        to={"/projects/" + data?.project_id}
                        className="text-brand-primary"
                      >
                        {data?.project_id}
                      </NavLink>
                    </div>
                  </div>
                ) : (
                  "--"
                )}

                <div className="mb-2">
                  <p className="font-size-14 text-secondary mb-0">
                    <span className="position-relative left-2">Frist</span>
                  </p>
                  <div class="profile">
                    <span className="text-primary">
                      {" "}
                      {dateFormat(data && data.start_date, "dd.mm.yyyy")} -{" "}
                      {dateFormat(data && data.end_date, "dd.mm.yyyy")}
                    </span>
                  </div>
                </div>

                <hr style={{ margin: "12px 0px" }} />
                <h6 className="text-primary font-size-16 font-weight-500">
                  Mitglied
                </h6>

                <div className="mb-2">
                  <p className="font-size-14 text-secondary mb-0 w-50">
                    <i class="ph ph-user-circle font-size-20"></i>
                    <span className="position-relative bottom-4 left-4">
                      Ticket erstellt von
                    </span>
                  </p>
                  <a class="profile w-50">
                    <img
                      src="/assets/img/placeholder.png"
                      className="border-primary"
                      alt="Profile"
                    />
                    <span>{data?.user?.name}</span>
                  </a>
                </div>

                <div className="mb-2">
                  <p className="font-size-14 text-secondary mb-0 w-50">
                    <i class="ph ph-user-circle font-size-20"></i>
                    <span className="position-relative bottom-4 left-4">
                      Kunde
                    </span>
                  </p>
                  <a class="profile w-50">
                    <img
                      src="/assets/img/placeholder.png"
                      className="border-primary"
                      alt="Profile"
                    />
                    <span>
                      {" "}
                      {(data?.project?.vorname
                        ? data?.project?.vorname
                        : "--") +
                        " " +
                        (data?.project?.name ? data?.project?.name : "--")}
                    </span>
                  </a>
                </div>

                <hr style={{ margin: "12px 0px" }} />
              </div>
              <p className="font-size-16 font-weight-500 text-primary mb-3">
                {t("Status Information")}
              </p>
              <Timeline refresh={refresh} projectid={data?.project_id} />
            </div>
          </div>
        </div>
      </div>

      {/* checklist modal */}
      <button
        type="button"
        class="btn bg-gradient-primary d-none"
        data-bs-toggle="modal"
        id="createCheckListModalOpen"
        data-bs-target="#checklistModal"
      ></button>
      <button
        type="button"
        class="btn bg-gradient-primary d-none"
        data-bs-toggle="modal"
        id="editReclamationModalOpen"
        data-bs-target="#TicketModalEdit"
      ></button>
      <CheckList
        refresh={setRefresh}
        checkLists={data?.checklists}
        id={props?.viewTicket?.id ? props?.viewTicket?.id : params.id}
      />
      <Edit
        refresh={setRefresh}
        id={props?.viewTicket?.id ? props?.viewTicket?.id : params.id}
        ticketEditModal={ticketEditModal}
      />
      <ReclamationCreate
        setrefresh={setRefresh}
        popupPrefix={"ticket"}
        projectid={0}
        ticket={data}
      />
    </>
  );
}

export default Show;
