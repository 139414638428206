import { useEffect, useState, React } from "react";
// @mui material components
import Card from "@mui/material/Card";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Setting pages components
import TableCell from "layouts/pages/account/settings/components/TableCell";

// Soft UI Dashboard PRO React components
import { useTranslation } from "react-i18next";
import { useCallFetchv2 } from 'helpers/callFetchv2';
import SoftSelect from "components/SoftSelect";

function Status() {
    const { t } = useTranslation();
    const callFetch = useCallFetchv2();
    const [saving, setSaving] = useState(false);
    const [refresh, setRefresh] = useState(0);
    const [data, setData] = useState({});
    const [openId, setOpenId] = useState(0);
    const [statusTab, setStatusTab] = useState('sale');

    const handleColorPickerToggle = (id) => {
        if (openId == id) {
            setOpenId(0);
        } else {
            setOpenId(id);
        }
    };

    const updateTeamColor = (id, code, bgcode, old) => {
        onSubmit({
            sales_status_id: id,
            color: code
        })
        setOpenId(0);
    };

    const checkColorCodeExists = (color) => {
        return data?.sales_statuses.some(sales_statuse => sales_statuse.color === color);
    }

    useEffect(() => {
        callFetch("status-setting?type=" + statusTab, "GET", []).then((res) => {
            if (res?.message === 'success') {
                setData(res?.data);
            }
        });
    }, [refresh, statusTab]);

    const addBertriebsstatuse = () => {
        var formData = {}
        formData.type = statusTab;

        callFetch("create-sales-status", "POST", formData, {}).then((res) => {
            setSaving(false);
            if (!res.ok) return;
            setRefresh(refresh + 1)
        });
    }

    const removeVertriebsstatuse = (id) => {
        callFetch("delete-sales-status/" + id, "POST", {}, {}).then((res) => {
            setSaving(false);
            if (!res.ok) return;
            setRefresh(refresh + 1)
        });
    }

    const onSubmit = (formData) => {
        setSaving(true);
        callFetch("status-setting-update", "POST", formData, {}).then((res) => {
            setSaving(false);
            if (!res.ok) return;
            setRefresh(refresh + 1)
        });
    };

    return (
        <>

            <div className="card p-3 mb-3">
                <div className="card-body bg-secondary border-radius pb-3">
                    <h3 className="title mb-2">{t('Status Einstellungen')}</h3>
                    <p className="text-link text-secondary">Choose how you receive notifications. These notification settings apply to the things you’re watching.</p>


                    {data?.applicationStatusSetting?.status_rating_mandatory == 1 ? (
                        <>
                            <div className="row">
                                <div className="col-md-6">
                                    <label> Ab</label>
                                    <SoftSelect
                                        defaultValue={[
                                            { value: "24", label: "24 Stunden" },
                                        ]}
                                        options={[
                                            { value: "24", label: "24 Stunden" },
                                        ]}
                                    />
                                    <p className="text-sm-normal">ist der Verkäufer dazu verpflichtet einen Status abzugeben.</p>
                                </div>
                            </div>
                        </>
                    ) : ''}

                    <div className="d-flex align-items-center justify-content-between gap-2 border border-radius-5 p-12px py-2 bg-white mt-3">
                        <div>
                            <p className="text-primary text-lg">{t('Status Bewertung verpflichtend aktiviert')}</p>
                            <p className="text-secondary text-link m-0">
                                Jeder Verkäufer wird verpflichtet einen Status abzugeben. Falls der Verkäufer einen Status nicht abgibt ist Nutzung des Systems
                                solange der Status nicht abgegeben wird deaktiv.
                            </p>
                        </div>
                        <div className="form-check form-switch">
                            <input
                                className="form-check-input"
                                checked={data?.applicationStatusSetting?.status_rating_mandatory == 1 ? true : false}
                                type="checkbox"
                                onChange={(e) => {
                                    onSubmit({
                                        status_rating_mandatory: (e.target.checked ? 1 : 0)
                                    })
                                }}
                            />
                        </div>
                    </div>

                    <div className="d-flex align-items-center justify-content-between gap-2 border border-radius-5 p-12px py-2 bg-white mt-3">
                        <div>
                            <p className="text-primary text-lg">{t('Nur erster Status wird an Lead Owner weitergeleitet')}</p>
                            <p className="text-secondary text-link m-0">
                                Wenn diese Funktion aktiviert wird, wird nur der erste Status den der Verkäufer angibt and Ihren Call Center oder Lead Owner
                                weitergeleitet. Alle weiteren Statusänderungen -aktualisierungen werden nicht weitergeleitet.
                            </p>
                        </div>
                        <div className="form-check form-switch">
                            <input
                                className="form-check-input"
                                checked={data?.applicationStatusSetting?.first_status_forwarded_to_lead_owner == 1 ? true : false}
                                type="checkbox"
                                onChange={(e) => {
                                    onSubmit({
                                        first_status_forwarded_to_lead_owner: (e.target.checked ? 1 : 0)
                                    })
                                }}
                            />
                        </div>
                    </div>
                </div>

                <div className="card-header">
                    <h3 className="title mb-2">{t('Vertriebsstatuse')}</h3>
                    <p className="text-link text-secondary">Folgende Statuse werden im System angezeigt und sind vom Verkaufer auswahlbar.</p>

                    <ul className="nav nav-tabs border-0 gap-2" role="tablist">
                        <li className="nav-item" role="presentation">
                            <button onClick={() => setStatusTab('sale')} className={"px-2 text-primary text-md nav-link " + (statusTab === 'sale' ? 'active' : '')} id="sales-status" data-bs-toggle="tab" data-bs-target="#sales-status-pane" type="button" role="tab" aria-controls="sales-status-pane" aria-selected="true" style={{ color: '#0048B1', fontSize: '0.875rem' }}>Sales Status</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button onClick={() => setStatusTab('resale')} className={"px-2 text-primary text-md nav-link " + (statusTab === 'resale' ? 'active' : '')} id="resales-status" data-bs-toggle="tab" data-bs-target="#resales-status-pane" type="button" role="tab" aria-controls="resales-status-pane" aria-selected="false" style={{ color: '#0048B1', fontSize: '0.875rem' }}>Resale Status</button>
                        </li>
                    </ul>
                </div>



                {data?.sales_statuses?.map((sales_status, index) => (
                    <div className="card-body bg-secondary border-radius pb-3 mt-3">
                        <h5 className="title mb-2">{sales_status?.order_number}.</h5>

                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Status Title</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={sales_status?.name}
                                        readOnly={sales_status?.can_change === 1 ? false : true}
                                        onBlur={(e) => {
                                            if (!sales_status?.can_change) return;
                                            onSubmit({
                                                sales_status_id: sales_status?.id,
                                                name: e.target.value
                                            })
                                        }}
                                        onChange={(e) => {
                                            const newData = { ...data }; // Create a shallow copy of the `data` object
                                            newData.sales_statuses[index] = {
                                                ...sales_status, // Copy the current sales_status
                                                name: e.target.value // Update the name
                                            };
                                            setData(newData); // Set the updated state
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Status Color</label>
                                    <div className="form-control color-picker-grid">
                                        <span onClick={() => updateTeamColor(sales_status.id, 'team-color-1', 'team-bg-color-1', sales_status.color)} className={`color ${sales_status?.color === 'team-color-1' ? 'selected' : ''}`} style={{ backgroundColor: '#D68B00' }}></span>
                                        <span onClick={() => updateTeamColor(sales_status.id, 'team-color-2', 'team-bg-color-2', sales_status.color)} className={`color ${sales_status?.color === 'team-color-2' ? 'selected' : ''}`} style={{ backgroundColor: '#48BB78' }}></span>
                                        <span onClick={() => updateTeamColor(sales_status.id, 'team-color-3', 'team-bg-color-3', sales_status.color)} className={`color ${sales_status?.color === 'team-color-3' ? 'selected' : ''}`} style={{ backgroundColor: '#CB0C9F' }}></span>
                                        <span onClick={() => updateTeamColor(sales_status.id, 'team-color-4', 'team-bg-color-4', sales_status.color)} className={`color ${sales_status?.color === 'team-color-4' ? 'selected' : ''}`} style={{ backgroundColor: '#668650' }}></span>
                                        <span onClick={() => updateTeamColor(sales_status.id, 'team-color-5', 'team-bg-color-5', sales_status.color)} className={`color ${sales_status?.color === 'team-color-5' ? 'selected' : ''}`} style={{ backgroundColor: '#2424B2' }}></span>
                                        <span onClick={() => updateTeamColor(sales_status.id, 'team-color-6', 'team-bg-color-6', sales_status.color)} className={`color ${sales_status?.color === 'team-color-6' ? 'selected' : ''}`} style={{ backgroundColor: '#7E5A59' }}></span>
                                        <span onClick={() => updateTeamColor(sales_status.id, 'team-color-7', 'team-bg-color-7', sales_status.color)} className={`color ${sales_status?.color === 'team-color-7' ? 'selected' : ''}`} style={{ backgroundColor: '#ABB521' }}></span>
                                        <span onClick={() => updateTeamColor(sales_status.id, 'team-color-8', 'team-bg-color-8', sales_status.color)} className={`color ${sales_status?.color === 'team-color-8' ? 'selected' : ''}`} style={{ backgroundColor: '#2D3748' }}></span>
                                        <span onClick={() => updateTeamColor(sales_status.id, 'team-color-9', 'team-bg-color-9', sales_status.color)} className={`color ${sales_status?.color === 'team-color-9' ? 'selected' : ''}`} style={{ backgroundColor: '#00C6D6' }}></span>
                                        <span onClick={() => updateTeamColor(sales_status.id, 'team-color-10', 'team-bg-color-10', sales_status.color)} className={`color ${sales_status?.color === 'team-color-10' ? 'selected' : ''}`} style={{ backgroundColor: '#D1063D' }}></span>
                                        <span onClick={() => updateTeamColor(sales_status.id, 'team-color-11', 'team-bg-color-11', sales_status.color)} className={`color ${sales_status?.color === 'team-color-11' ? 'selected' : ''}`} style={{ backgroundColor: '#5F00CC' }}></span>
                                        <span onClick={() => updateTeamColor(sales_status.id, 'team-color-12', 'team-bg-color-12', sales_status.color)} className={`color ${sales_status?.color === 'team-color-12' ? 'selected' : ''}`} style={{ backgroundColor: '#DBBA00' }}></span>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="form-group form-control">
                                    <div className="form-check d-flex align-items-center justify-content-between ps-0">
                                        <label className="text-primary">Feedback</label>
                                        <input
                                            className="form-check-input"
                                            checked={sales_status?.feedback == 1 ? true : false}
                                            type="checkbox"
                                            onChange={(e) => {
                                                onSubmit({
                                                    sales_status_id: sales_status?.id,
                                                    feedback: (e.target.checked ? 1 : 0)
                                                })
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="form-group form-control">
                                    <div className="form-check d-flex align-items-center justify-content-between ps-0">
                                        <label className="text-primary">Lead Owner wird bezahlt</label>
                                        <input
                                            className="form-check-input"
                                            checked={sales_status?.lead_owner_wird_bezhlt == 1 ? true : false}
                                            type="checkbox"
                                            onChange={(e) => {
                                                onSubmit({
                                                    sales_status_id: sales_status?.id,
                                                    lead_owner_wird_bezhlt: (e.target.checked ? 1 : 0)
                                                })
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="form-group form-control">
                                    <div className="form-check d-flex align-items-center justify-content-between ps-0">
                                        <label className="text-primary">Bild verpflichtend</label>
                                        <input
                                            className="form-check-input"
                                            checked={sales_status?.picture_required == 1 ? true : false}
                                            type="checkbox"
                                            onChange={(e) => {
                                                onSubmit({
                                                    sales_status_id: sales_status?.id,
                                                    picture_required: (e.target.checked ? 1 : 0)
                                                })
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="form-group form-control">
                                    <div className="form-check d-flex align-items-center justify-content-between ps-0">
                                        <label className="text-primary">Erststatus</label>
                                        <input
                                            className="form-check-input"
                                            checked={sales_status?.erststatus == 1 ? true : false}
                                            type="checkbox"
                                            onChange={(e) => {
                                                onSubmit({
                                                    sales_status_id: sales_status?.id,
                                                    erststatus: (e.target.checked ? 1 : 0)
                                                })
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>




                        {sales_status?.can_change === 1 ? (
                            <div className="d-flex">
                                <a
                                    href="#"
                                    onClick={() => removeVertriebsstatuse(sales_status?.id)}
                                    className="btn m-0 text-status-error-primary d-flex align-items-center justify-content-between gap-1"
                                ><i className="ph ph-trash-simple text-xl"></i>{t('Entfernen')}</a>
                            </div>
                        ) : ''}

                    </div>
                ))}

                <div className="card-body">
                    <button
                        className="btn btn-secondary d-flex align-items-center justify-content-between gap-2"
                        onClick={(e) => { addBertriebsstatuse() }}
                    ><i className="ph ph-plus"></i>Add Vertriebsstatuse</button>
                </div>
            </div>

            <div className="row">
                <div className="col-md-6">
                    <div className="card">
                        <div className="card-header">
                            <h5 className="title">{t('Status')}</h5>
                        </div>
                        <div className="card-body pb-3">
                            <p className="text-secondary text-link m-0">Bitte beachten Sie folgendes:</p>
                            <ul className="m-0">
                                <li><p className="text-secondary text-link m-0">Graue Statuse können nicht verändert werden.</p></li>
                                <li><p className="text-secondary text-link m-0">Statuse die hier angelegt werden sind im Home-Dashboard ersichtlich</p></li>
                                <li><p className="text-secondary text-link m-0">Falls Sie einen Status Löschen gehen alle früheren Daten verloren.</p></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="card">
                        <div className="card-header">
                            <h5 className="title">{t('Mitarbeiterkennung')}</h5>
                        </div>
                        <div className="card-body pb-3">
                            <label>Mitarbeiterkennung deaktivieren</label>
                            <div className="form-check form-switch float-end">
                                <input
                                    className="form-check-input"
                                    checked={data?.applicationStatusSetting?.mitarbeiterkennung_optional == 1 ? true : false}
                                    type="checkbox"
                                    onChange={(e) => {
                                        onSubmit({
                                            mitarbeiterkennung_optional: (e.target.checked ? 1 : 0)
                                        })
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Status;