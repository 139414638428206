/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useMemo, useEffect, useState, React } from "react";
// @mui material components
import Card from "@mui/material/Card";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import Switch from "@mui/material/Switch";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Setting pages components
import TableCell from "layouts/pages/account/settings/components/TableCell";

// Soft UI Dashboard PRO React components
import { useTranslation } from "react-i18next";
import { useCallFetchv2 } from 'helpers/callFetchv2';

function Notifications() {

  const [user, setUser] = useState([]);
  const { t } = useTranslation();
  const callFetch = useCallFetchv2();
  const [isUpdate, setIsUpdate] = useState(false);
  const [projectStatus, setProjectStatus] = useState('0');
  const [orderStatus, setOrderStatus] = useState('0');
  const [offerStatus, setOfferStatus] = useState('0');
  const [orderProcessingStatus, setOrderProcessingStatus] = useState('0');
  useEffect(() => {
    callFetch("get-notification-status", "GET", []).then((res) => {
      setProjectStatus(res.user.alert_project_notification);
      setOrderStatus(res.user.alert_order_notification);
      setOfferStatus(res.user.alert_offer_notification);
      setOrderProcessingStatus(res.user.alert_status_orderprocessing_notification);
    });
  }, []);

  const updateStatus = (type) => {
    if (type == 'project') {
      if (projectStatus == '1') {
        setProjectStatus('0');
      } else {
        setProjectStatus('1');
      }
    }

    if (type == 'order') {
      if (projectStatus == '1') {
        setOrderStatus('0');
      } else {
        setOrderStatus('1');
      }
    }

    if (type == 'offer') {
      if (projectStatus == '1') {
        setOfferStatus('0');
      } else {
        setOfferStatus('1');
      }
    }

    if (type == 'status_order_processing') {
      if (projectStatus == '1') {
        setOrderProcessingStatus('0');
      } else {
        setOrderProcessingStatus('1');
      }
    }
    callFetch("update-notification-status/" + type, "POST", []).then((res) => {
    });
  };

  return (
    <>

      <div className="card">
        <div className="card-header">
          <h3 className="title mb-2">{t('Notifications')}</h3>
          <p className="text-link text-secondary">Choose how you receive notifications. These notification settings apply to the things you’re watching. a</p>
        </div>
        <div className="card-body">
          <div className="d-flex align-items-center justify-content-between gap-2 border border-radius-5 p-12px py-2 bg-white mb-3">
            <div>
              <p className="text-primary text-lg">{t('New Project')}</p>
              <p className="text-secondary text-link m-0">
                Notify when another user mentions you in a comment
              </p>
            </div>
            <div className="form-check form-switch">
              {projectStatus != '0' && (
                <input className="form-check-input" checked type="checkbox" onClick={() => updateStatus('project')} />
              )}
              {projectStatus == '0' && (
                <input className="form-check-input" type="checkbox" onClick={() => updateStatus('project')} />
              )}
            </div>
          </div>

          <div className="d-flex align-items-center justify-content-between gap-2 border border-radius-5 p-12px py-2 bg-white mb-3">
            <div>
              <p className="text-primary text-lg">{t('New Orders')}</p>
              <p className="text-secondary text-link m-0">
                Notify when another user comments your item.
              </p>
            </div>
            <div className="form-check form-switch">
              {orderStatus != '0' && (
                <input className="form-check-input" checked type="checkbox" onClick={() => updateStatus('order')} />
              )}
              {orderStatus == '0' && (
                <input className="form-check-input" type="checkbox" onClick={() => updateStatus('order')} />
              )}
            </div>
          </div>

          <div className="d-flex align-items-center justify-content-between gap-2 border border-radius-5 p-12px py-2 bg-white mb-3">
            <div>
              <p className="text-primary text-lg">{t('New Offer')}</p>
              <p className="text-secondary text-link m-0">
                Notify when another user follows you.
              </p>
            </div>
            <div className="form-check form-switch">
              {offerStatus != '0' && (
                <input className="form-check-input" checked type="checkbox" onClick={() => updateStatus('offer')} />
              )}
              {offerStatus == '0' && (
                <input className="form-check-input" type="checkbox" onClick={() => updateStatus('offer')} />
              )}
            </div>
          </div>

          <div className="d-flex align-items-center justify-content-between gap-2 border border-radius-5 p-12px py-2 bg-white mb-3">
            <div>
              <p className="text-primary text-lg">{t('New Status / Order Processing')}</p>
              <p className="text-secondary text-link m-0">
                Notify when another user follows you.
              </p>
            </div>
            <div className="form-check form-switch">
              {orderProcessingStatus != '0' && (
                <input className="form-check-input" checked type="checkbox" onClick={() => updateStatus('status_order_processing')} />
              )}
              {orderProcessingStatus == '0' && (
                <input className="form-check-input" type="checkbox" onClick={() => updateStatus('status_order_processing')} />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Notifications;