import { React, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Navigate, useParams } from "react-router-dom";
import { useCallFetchv2 } from 'helpers/callFetchv2';
import { useTranslation } from 'react-i18next';
import Switch from "@mui/material/Switch";
import Cookies from 'js-cookie';
import ImagePopup from "pages/hr/employee/ImagePopup";
import FileUploader from 'components/FileUploader/index';

function CompanyInfo() {
    let params = useParams();
    const { t } = useTranslation();
    const callFetch = useCallFetchv2();
    const [attachment, setAttachments] = useState('');
    const [saving, setSaving] = useState(false);
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [user, setUser] = useState([]);
    const [companyName, setCompanyName] = useState('');
    const [address, setAddress] = useState('');
    const [plz, setPlz] = useState('');
    const [ort, setOrt] = useState('');
    const [nr, setNr] = useState('');
    const [gerichtstand, setGerichtstand] = useState('');
    const [steuernummer, setSteuernummer] = useState('');
    const [telephone, setTelephone] = useState('');
    const [mobile, setMobile] = useState('');
    const [website, setWebsite] = useState('');
    const [bank, setBank] = useState('');
    const [bic, setBic] = useState('');
    const [email, setEmail] = useState('');
    const [iban, setIban] = useState('');
    const [openingTimeFrom, setOpeningTimeFrom] = useState('');
    const [openingTimeTo, setOpeningTimeTo] = useState('');
    const [openingDayFrom, setOpeningDayFrom] = useState('');
    const [openingDayTo, setOpeningDayTo] = useState('');
    const [bankdaten, setBankdaten] = useState(0);
    const [gerichtstandStatus, setTerichtstandStatus] = useState(0);
    const [offnungszeiten, setOffnungszeiten] = useState(0);
    const [pdf, setPDFValue] = useState('angebot');
    const [sameAsAngebot, setSameAsAngebot] = useState(false);

    const [selectedImage, setSelectedImage] = useState(null);
    const [files, setFile] = useState();
    const [isModalVisible, setModalVisible] = useState(false);
    const [image, setImage] = useState();

    const handleImageChange = (event) => {
        if(!event) return;
        const file = event[0]
        setFile(file)
        if (file) {
            setSelectedImage(URL.createObjectURL(file));
            const reader = new FileReader();
            reader.onload = () => {
                setSelectedImage(URL.createObjectURL(file));
                setAttachments(URL.createObjectURL(file));
                setImage(file)
                //openModal();
            }
            reader.readAsDataURL(file);
        }
    }

    const closeModal = () => {
        if (files) {
            const reader = new FileReader();
            reader.onload = () => {
                setAttachments(URL.createObjectURL(files))
                setImage(files)
            }
            reader.readAsDataURL(files);
        }
        setModalVisible(false);
    }

    const openModal = () => {
        setModalVisible(true);
    };

    const croppedPhoto = async (photo) => {
        try {
            const response = await fetch(photo.url);
            const blob = await response.blob();
            // Create a File object from the Blob
            const file = new File([blob], 'photo.jpg', {
                type: 'image/jpeg', // Set the appropriate MIME type
                lastModified: new Date().getTime(), // Set the current timestamp
            });

            setAttachments(URL.createObjectURL(file))
            setImage(file)
            setModalVisible(false);
        } catch (error) {
            console.error('Error fetching and creating file:', error);
        }
    }

    const {
        register,
        handleSubmit,
        setError,
        setValue,
        reset,
        getValues,
        formState: { errors },
    } = useForm();

    useEffect(() => {
        callFetch("load-settings/" + (sameAsAngebot ? 'angebot' : pdf), "GET", []).then((res) => {
            setUser(res.data);
            if (res.data?.pdf) {
                setCompanyName(res.data?.company_name);
                setAddress(res.data?.address);
                setPlz(res.data?.plz);
                setOrt(res.data?.ort);
                setNr(res.data?.nr);
                setAttachments(Cookies.get('backend_url') + 'storage/attachments/' + res.data?.logo);
                setGerichtstand(res.data?.gerichtstand);
                setSteuernummer(res.data?.tax_number);
                setTelephone(res.data?.telephone);
                setMobile(res.data?.office_phone);
                setWebsite(res.data?.website);
                setBankdaten(res.data?.show_bank_info == 'null' ? 0 : res.data?.show_bank_info);
                setTerichtstandStatus(res.data?.gerichtstand_show == 'null' ? 0 : res.data?.gerichtstand_show);
                setOffnungszeiten(res.data?.opening_hour_show == 'null' ? 0 : res.data?.opening_hour_show);
                setBank(res.data?.bank);
                setBic(res.data?.bank_bic);
                setEmail(res.data?.email);
                setIban(res.data?.bank_iban);
                setOpeningTimeFrom(res.data?.opening_time_from);
                setOpeningTimeTo(res.data?.opening_time_to);
                setOpeningDayFrom(res.data?.opening_day_from);
                setOpeningDayTo(res.data?.opening_day_to);

                for (let [key, value] of Object.entries(res?.data)) {
                    setValue(key, (value == null || value == 'null' ? "" : value));
                }
                if (sameAsAngebot) {
                    setValue('same_as_angebot', 1);
                }
            } else {

                reset()

                setCompanyName('');
                setAddress('');
                setPlz('');
                setAttachments('');
                setOrt('');
                setNr('');
                setGerichtstand('');
                setSteuernummer('');
                setTelephone('');
                setMobile('');
                setWebsite('');
                setBankdaten(0);
                setTerichtstandStatus(0);
                setOffnungszeiten(0);
                setBank('');
                setBic('');
                setEmail('');
                setIban('');
                setOpeningTimeFrom('');
                setOpeningTimeTo('');
                setOpeningDayFrom('');
                setOpeningDayTo('');
            }

        });
    }, [pdf, sameAsAngebot]);

    const setPDF = (pdf) => {
        setValue('pdf', pdf);
        setImage('');
        setPDFValue(pdf);
    }

    const changeStatus = (field) => {
        if (field == 'bank') {
            if (bankdaten == 1) {
                setBankdaten(0);
                setValue('show_bank_info', 0);
            } else {
                setBankdaten(1);
                setValue('show_bank_info', 1);
            }
        }
        else if (field == 'gerich') {
            if (gerichtstandStatus == 1) {
                setTerichtstandStatus(0);
                setValue('gerichtstand_show', 0);
            } else {
                setTerichtstandStatus(1);
                setValue('gerichtstand_show', 1);
            }
        }
        else if (field == 'offnung') {
            if (offnungszeiten == 1) {
                setOffnungszeiten(0);
                setValue('opening_hour_show', 0);
            } else {
                setOffnungszeiten(1);
                setValue('opening_hour_show', 1);
            }
        }
    }

    const onSubmit = (formData) => {
        formData.pdf = pdf;
        if (image) {
            formData.attachments = !image ? '' : image;
        }

        formData.same_as_angebot = (sameAsAngebot || formData?.same_as_angebot == true ? 1 : 0);

        setSaving(true);
        callFetch("update-settings", "POST", formData, setError).then((res) => {
            setSaving(false);
            if (!res.ok) return;
            setSubmitSuccess(true);
        });
    };

    return (
        <>
            <form
                className={`needs-validation ${Object.keys(errors).length ? "was-validated" : ""}`}
                onSubmit={handleSubmit(onSubmit)}
                noValidate
                autoComplete="off"
            >
                <div className="row" style={{ paddingBottom: '100px' }}>
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-header">
                                <ul class="nav nav-tabs border-0 gap-2" id="pills-tab" role="tablist">
                                    <li class="nav-item" role="presentation">
                                        <button class={`px-2 text-primary text-md nav-link ${pdf === 'angebot' ? 'active' : ''}`} type="button" onClick={() => setPDF('angebot')}>Angebot</button>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <button class={`px-2 text-primary text-md nav-link ${pdf === 'auftrag' ? 'active' : ''}`} type="button" onClick={() => setPDF('auftrag')}>Auftrag</button>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <button class={`px-2 text-primary text-md nav-link ${pdf === 'rechnung' ? 'active' : ''}`} type="button" onClick={() => setPDF('rechnung')}>Rechnung</button>
                                    </li>
                                </ul>
                            </div>
                            <div className="card-body pb-3">
                                <div className="d-flex flex-column flex-md-row gap-3">
                                    <div className="w-100 w-md-50 bg-secondary p-3 border-radius">
                                        <h6 className="title mb-2">{t('Firmendaten')}</h6>

                                        <div className="row scroll-cs" style={{ maxHeight: '470px' }}>
                                            {pdf !== 'angebot' ? (
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <div className="form-control">
                                                            <div className="form-check d-flex align-items-center justify-content-between ps-0">
                                                                <label className="text-primary">{t('Same as Angebot')}</label>
                                                                <input
                                                                    type="checkbox"
                                                                    className="form-check-input"
                                                                    {...register("same_as_angebot", {
                                                                        required: false,
                                                                    })}
                                                                    onChange={(e) => {
                                                                        setSameAsAngebot(e.target.checked);
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="invalid-feedback">{errors.same_as_angebot && errors.same_as_angebot.message}</div>
                                                    </div>
                                                </div>
                                            ) : ''}

                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label>{t('Companpy Name')}</label>
                                                    <input
                                                        onKeyUp={(e) => setCompanyName(e.target.value)}
                                                        type="text"
                                                        className="form-control "
                                                        placeholder={t('Companpy Name')}
                                                        {...register("company_name", {
                                                            required: true,
                                                        })}
                                                        required
                                                    />
                                                    <div className="invalid-feedback">{errors.identity_number && errors.identity_number.message}</div>
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>{t('Address')}</label>
                                                    <input
                                                        type="text"
                                                        onKeyUp={(e) => setAddress(e.target.value)}
                                                        className="form-control "
                                                        placeholder={t('Address')}
                                                        {...register("address", {
                                                            required: true,
                                                        })}
                                                        required
                                                    />
                                                    <div className="invalid-feedback">{errors.name && errors.name.message}</div>
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>{t('Nr.')}</label>
                                                    <input
                                                        type="email"
                                                        onKeyUp={(e) => setNr(e.target.value)}
                                                        className="form-control "
                                                        placeholder={t('Nr.')}
                                                        {...register("nr", {
                                                            required: true,
                                                        })}
                                                        required
                                                    />
                                                    <div className="invalid-feedback">{errors.email && errors.email.message}</div>
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>{t('Ort')}</label>
                                                    <input
                                                        type="text"
                                                        onKeyUp={(e) => setOrt(e.target.value)}
                                                        className="form-control "
                                                        placeholder={t('Ort')}
                                                        {...register("ort")} />
                                                    <div className="invalid-feedback">{errors.mobile && errors.mobile.message}</div>
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>{t('PLZ')}</label>
                                                    <input
                                                        type="text"
                                                        onKeyUp={(e) => setPlz(e.target.value)}
                                                        className="form-control "
                                                        placeholder={t('PLZ')}
                                                        {...register("plz")} />
                                                    <div className="invalid-feedback">{errors.mobile && errors.mobile.message}</div>
                                                </div>
                                            </div>


                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>{t('Telephone Phone')}</label>
                                                    <input
                                                        type="text"
                                                        onKeyUp={(e) => setTelephone(e.target.value)}
                                                        className="form-control "
                                                        placeholder={t('Telephone Phone')}
                                                        {...register("telephone")} />
                                                    <div className="invalid-feedback">{errors.mobile && errors.mobile.message}</div>
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>{t('Mobile Phone')}</label>
                                                    <input
                                                        type="text"
                                                        onKeyUp={(e) => setMobile(e.target.value)}
                                                        className="form-control "
                                                        placeholder={t('Mobile Phone')}
                                                        {...register("office_phone")} />
                                                    <div className="invalid-feedback">{errors.mobile && errors.mobile.message}</div>
                                                </div>
                                            </div>

                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label>{t('Fax')}</label>
                                                    <input
                                                        type="text"
                                                        className="form-control "
                                                        placeholder={t('Fax')}
                                                        {...register("fex")} />
                                                    <div className="invalid-feedback">{errors.mobile && errors.mobile.message}</div>
                                                </div>
                                            </div>

                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label>{t('E-mail')}</label>
                                                    <input
                                                        type="text"
                                                        onKeyUp={(e) => setEmail(e.target.value)}
                                                        className="form-control "
                                                        placeholder={t('E-mail')}
                                                        {...register("email")} />
                                                    <div className="invalid-feedback">{errors.mobile && errors.mobile.message}</div>
                                                </div>
                                            </div>

                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label>{t('Website')}</label>
                                                    <input
                                                        type="text"
                                                        onKeyUp={(e) => setWebsite(e.target.value)}
                                                        className="form-control "
                                                        placeholder={t('Website')}
                                                        {...register("website")} />
                                                    <div className="invalid-feedback">{errors.mobile && errors.mobile.message}</div>
                                                </div>
                                            </div>


                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <div className="form-control">
                                                        <div className="d-flex align-items-center justify-content-between ps-0">
                                                            <label className="text-primary">{t('Bankdaten')}</label>
                                                            {bankdaten == '1' && <Switch className="my-0" defaultChecked onClick={() => changeStatus('bank')} />}
                                                            {bankdaten != '1' && <Switch className="my-0" onClick={() => changeStatus('bank')} />}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {bankdaten != '1' && <>
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label>{t('Bankname')}</label>
                                                        <input
                                                            type="text"
                                                            readOnly
                                                            onKeyUp={(e) => setBank(e.target.value)}
                                                            className="form-control "
                                                            placeholder={t('Bankname')}
                                                            {...register("bank")} />
                                                        <div className="invalid-feedback">{errors.mobile && errors.mobile.message}</div>
                                                    </div>
                                                </div>

                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label>{t('BIC')}</label>
                                                        <input
                                                            type="text"
                                                            readOnly
                                                            onKeyUp={(e) => setBic(e.target.value)}
                                                            className="form-control "
                                                            placeholder={t('BIC')}
                                                            {...register("bank_bic")} />
                                                        <div className="invalid-feedback">{errors.mobile && errors.mobile.message}</div>
                                                    </div>
                                                </div>

                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label>{t('IBAN')}</label>
                                                        <input
                                                            type="text"
                                                            readOnly
                                                            onKeyUp={(e) => setIban(e.target.value)}
                                                            className="form-control "
                                                            placeholder={t('IBAN')}
                                                            {...register("bank_iban")} />
                                                        <div className="invalid-feedback">{errors.mobile && errors.mobile.message}</div>
                                                    </div>
                                                </div>
                                            </>}

                                            {bankdaten == '1' && <>
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label>{t('Bankname')}</label>
                                                        <input
                                                            type="text"
                                                            onKeyUp={(e) => setBank(e.target.value)}
                                                            className="form-control "
                                                            placeholder={t('Bankname')}
                                                            {...register("bank")} />
                                                        <div className="invalid-feedback">{errors.mobile && errors.mobile.message}</div>
                                                    </div>
                                                </div>

                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label>{t('BIC')}</label>
                                                        <input
                                                            type="text"
                                                            onKeyUp={(e) => setBic(e.target.value)}
                                                            className="form-control "
                                                            placeholder={t('BIC')}
                                                            {...register("bank_bic")} />
                                                        <div className="invalid-feedback">{errors.mobile && errors.mobile.message}</div>
                                                    </div>
                                                </div>

                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label>{t('IBAN')}</label>
                                                        <input
                                                            type="text"
                                                            onKeyUp={(e) => setIban(e.target.value)}
                                                            className="form-control "
                                                            placeholder={t('IBAN')}
                                                            {...register("bank_iban")} />
                                                        <div className="invalid-feedback">{errors.mobile && errors.mobile.message}</div>
                                                    </div>
                                                </div>
                                            </>}

                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <div className="form-control">
                                                        <div className="d-flex align-items-center justify-content-between ps-0">
                                                            <label className="text-primary">{t('Gerichtstand')}</label>
                                                            {gerichtstandStatus == '1' && <Switch className="my-0" defaultChecked onClick={() => changeStatus('gerich')} />}
                                                            {gerichtstandStatus != '1' && <Switch className="my-0" onClick={() => changeStatus('gerich')} />}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>



                                            {gerichtstandStatus == '1' && <>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label>{t('Gerichtstand')}</label>
                                                        <input
                                                            type="text"
                                                            onKeyUp={(e) => setGerichtstand(e.target.value)}
                                                            className="form-control "
                                                            placeholder={t('Gerichtstand')}
                                                            {...register("gerichtstand")} />
                                                        <div className="invalid-feedback">{errors.mobile && errors.mobile.message}</div>
                                                    </div>
                                                </div>

                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label>{t('Steuernummer')}</label>
                                                        <input
                                                            type="text"
                                                            onKeyUp={(e) => setSteuernummer(e.target.value)}
                                                            className="form-control "
                                                            placeholder={t('Steuernummer')}
                                                            {...register("tax_number")} />
                                                        <div className="invalid-feedback">{errors.mobile && errors.mobile.message}</div>
                                                    </div>
                                                </div>
                                            </>}

                                            {gerichtstandStatus != '1' && <>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label>{t('Gerichtstand')}</label>
                                                        <input
                                                            type="text"
                                                            readOnly
                                                            onKeyUp={(e) => setGerichtstand(e.target.value)}
                                                            className="form-control "
                                                            placeholder={t('Gerichtstand')}
                                                            {...register("gerichtstand")} />
                                                        <div className="invalid-feedback">{errors.mobile && errors.mobile.message}</div>
                                                    </div>
                                                </div>

                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label>{t('Steuernummer')}</label>
                                                        <input
                                                            type="text"
                                                            readOnly
                                                            onKeyUp={(e) => setSteuernummer(e.target.value)}
                                                            className="form-control "
                                                            placeholder={t('Steuernummer')}
                                                            {...register("tax_number")} />
                                                        <div className="invalid-feedback">{errors.mobile && errors.mobile.message}</div>
                                                    </div>
                                                </div>
                                            </>}

                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <div className="form-control">
                                                        <div className="d-flex align-items-center justify-content-between ps-0">
                                                            <label className="text-primary">{t('Öffnungszeiten')}</label>
                                                            {offnungszeiten == '1' && <Switch className="my-0" defaultChecked onClick={() => changeStatus('offnung')} />}
                                                            {offnungszeiten != '1' && <Switch className="my-0" onClick={() => changeStatus('offnung')} />}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {offnungszeiten == '1' && <>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label>{t('Opening day from')}</label>
                                                        <input
                                                            type="text"
                                                            onKeyUp={(e) => setOpeningDayFrom(e.target.value)}
                                                            className="form-control "
                                                            placeholder={t('Mo')} {...register("opening_day_from")} />
                                                        <div className="invalid-feedback">{errors.mobile && errors.mobile.message}</div>
                                                    </div>
                                                </div>

                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label>{t('Opening time from')}</label>
                                                        <input
                                                            type="text"
                                                            onKeyUp={(e) => setOpeningTimeFrom(e.target.value)}
                                                            className="form-control "
                                                            placeholder={t('00.00')}
                                                            {...register("opening_time_from")} />
                                                        <div className="invalid-feedback">{errors.mobile && errors.mobile.message}</div>
                                                    </div>
                                                </div>

                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label>{t('Opening day to')}</label>
                                                        <input
                                                            type="text"
                                                            onKeyUp={(e) => setOpeningDayTo(e.target.value)}
                                                            className="form-control "
                                                            placeholder={t('Fr')} {...register("opening_day_to")} />
                                                        <div className="invalid-feedback">{errors.mobile && errors.mobile.message}</div>
                                                    </div>
                                                </div>

                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label>{t('Opening time to')}</label>
                                                        <input
                                                            type="text"
                                                            onKeyUp={(e) => setOpeningTimeTo(e.target.value)}
                                                            className="form-control "
                                                            placeholder={t('00.00')}
                                                            {...register("opening_time_to")} />
                                                        <div className="invalid-feedback">{errors.mobile && errors.mobile.message}</div>
                                                    </div>
                                                </div>
                                            </>}

                                            {offnungszeiten != '1' && <>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label>{t('Opening day from')}</label>
                                                        <input
                                                            type="text"
                                                            readOnly
                                                            onKeyUp={(e) => setOpeningDayFrom(e.target.value)}
                                                            className="form-control "
                                                            placeholder={t('Mo')} {...register("opening_day_from")} />
                                                        <div className="invalid-feedback">{errors.mobile && errors.mobile.message}</div>
                                                    </div>
                                                </div>

                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label>{t('Opening time from')}</label>
                                                        <input
                                                            type="text"
                                                            readOnly
                                                            onKeyUp={(e) => setOpeningTimeFrom(e.target.value)}
                                                            className="form-control "
                                                            placeholder={t('00.00')}
                                                            {...register("opening_time_from")} />
                                                        <div className="invalid-feedback">{errors.mobile && errors.mobile.message}</div>
                                                    </div>
                                                </div>

                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label>{t('Opening day to')}</label>
                                                        <input
                                                            type="text"
                                                            readOnly
                                                            onKeyUp={(e) => setOpeningDayTo(e.target.value)}
                                                            className="form-control "
                                                            placeholder={t('Fr')} {...register("opening_day_to")} />
                                                        <div className="invalid-feedback">{errors.mobile && errors.mobile.message}</div>
                                                    </div>
                                                </div>

                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label>{t('Opening time to')}</label>
                                                        <input
                                                            type="text"
                                                            readOnly
                                                            onKeyUp={(e) => setOpeningTimeTo(e.target.value)}
                                                            className="form-control "
                                                            placeholder={t('00.00')}
                                                            {...register("opening_time_to")} />
                                                        <div className="invalid-feedback">{errors.mobile && errors.mobile.message}</div>
                                                    </div>
                                                </div>
                                            </>}

                                            <div className="col-md-12">
                                                <label>{t('Profile Picture')}</label>
                                                <FileUploader
                                                    supportedFileType={['jpg', 'png', 'jpeg']}
                                                    maxFileSizeMB={10}//Mb 
                                                    multiple={false}
                                                    onChange={(files, type) => {
                                                        if (type === 'files') {
                                                            handleImageChange(files)
                                                        }
                                                    }}
                                                    previewTitle={""}
                                                    onlyUploader={true}
                                                    id={"companyLogoUpload"}
                                                    refreshKey={pdf}
                                                />
                                                <div className="invalid-feedback">{errors.photo && errors.photo.message}</div>
                                                {/*
                                                <FileUploader
                                                    supportedFileType={['png', 'jpg', 'jpeg', 'pdf']}
                                                    maxFileSizeMB={10}//Mb 
                                                    multiple={true}
                                                    onChange={(files, type) => {
                                                        //if (type === 'files') {
                                                        //    setSelectedFile(files)
                                                        //} else if (type === 'attachment') {
                                                            //setAttachments(files);
                                                        //}
                                                    }}
                                                    //attachments={attachments}
                                                    //attachmentsBasePath={Cookies.get('backend_url') + 'storage/product/'}
                                                />
                                                */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="w-100 w-md-50 bg-secondary p-3 border-radius">
                                        <h6 className="title mb-2">{t('Preview')}</h6>
                                        <div className="row">
                                            <div className="col-sm-7 mb-3">
                                                <img src={attachment} style={{ height: 'auto', maxWidth: '70%' }} />
                                            </div>
                                            <div className="col-sm-5 mb-3">
                                                {companyName && <p style={{ fontSize: '10px', fontWeight: 'bold' }}>{companyName}</p>}
                                                {address || nr ? <p className="text-overflow-ellipsis" style={{ fontSize: '10px', margin: '0px' }}>{address}, {nr}</p> : ''}
                                                {plz || ort ? <p className="text-overflow-ellipsis" style={{ fontSize: '10px', margin: '0px' }}>PLZ: {plz}, Ort: {ort}</p> : ''}
                                                {telephone && <p className="text-overflow-ellipsis" style={{ fontSize: '10px' }}><strong>T</strong>: {telephone}</p>}
                                                {mobile && <p className="text-overflow-ellipsis" style={{ fontSize: '10px', margin: '0px' }}><strong>M</strong>: {mobile}</p>}
                                                {email && <p className="text-overflow-ellipsis" style={{ fontSize: '10px' }}><strong>E</strong>: {email}</p>}
                                                {website && <p className="text-overflow-ellipsis" style={{ fontSize: '10px', margin: '0px' }}><strong>W</strong>: {website}</p>}
                                            </div>
                                            <div className="col-sm-12 mt-5">
                                                <p style={{ fontSize: '10px', fontWeight: 'bold' }}>Ihr persönliches Angebot</p>
                                            </div>
                                            <div className="col-sm-7"></div>
                                            <div className="col-sm-5">
                                                <p style={{ fontSize: '10px', color: '#A0AEC0' }}>Ort, XX.XX.XXXX</p>
                                            </div>
                                            <div className="col-sm-12" style={{ margin: '77px 0px' }}>
                                                <hr style={{ width: '50%' }} />
                                                <hr />
                                                <hr />
                                                <hr />
                                                <hr />
                                                <hr />
                                                <hr />
                                            </div>
                                            <div className="col-sm-4">
                                                <p className="pdf-preview-footer-text text-overflow-ellipsis">{companyName}</p>
                                                <p className="pdf-preview-footer-text text-overflow-ellipsis">{address}, {nr}</p>
                                                <p className="pdf-preview-footer-text text-overflow-ellipsis">PLZ {plz}, Ort {ort}</p>
                                                {gerichtstandStatus == '1' && <> <p className="pdf-preview-footer-text text-overflow-ellipsis">Gerichtsstand {gerichtstand}</p>
                                                    <p className="pdf-preview-footer-text text-overflow-ellipsis">Amtsgericht {steuernummer}</p></>}
                                                {gerichtstandStatus != '1' && <> </>}

                                            </div>
                                            <div className="col-sm-4">
                                                <p className="pdf-preview-footer-text text-overflow-ellipsis"><strong></strong></p>
                                                {telephone && <p className="pdf-preview-footer-text text-overflow-ellipsis"><strong>T:</strong> {telephone}</p>}
                                                {email && <p className="pdf-preview-footer-text text-overflow-ellipsis"><strong>E:</strong> {email}</p>}
                                                {website && <p className="pdf-preview-footer-text text-overflow-ellipsis"><strong>W:</strong> {website}</p>}

                                                {offnungszeiten == '1' && <p className="pdf-preview-footer-text text-overflow-ellipsis"><strong>Öffnungszeiten:</strong> {openingDayFrom} - {openingDayTo}: {openingTimeFrom} bis {openingTimeTo} Uhr</p>}
                                            </div>
                                            <div className="col-sm-4">
                                                <p className="pdf-preview-footer-text text-overflow-ellipsis"></p>
                                                <p className="pdf-preview-footer-text text-overflow-ellipsis"></p>

                                                {bankdaten == '1' && <>
                                                    <p className="pdf-preview-footer-text text-overflow-ellipsis">{bank}</p>
                                                    <p className="pdf-preview-footer-text text-overflow-ellipsis">BIC: {bic}</p>
                                                    <p className="pdf-preview-footer-text text-overflow-ellipsis">IBAN: {iban}</p>
                                                </>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="card fixed-bottom mb-3 fixed-bottom-width-available">
                    <div className="card-footer d-flex align-items-center justify-content-end gap-2">
                        {saving ? (
                            <button type="button" className="btn btn-disabled m-0" disabled>
                                {t('Saving ...')}
                            </button>
                        ) : (
                            <button type="submit" className="btn btn-primary m-0">
                                {t('Save')}
                            </button>
                        )}
                    </div>
                </div>
            </form>

            <ImagePopup showModal={isModalVisible} openModal={closeModal} photoURL={selectedImage} filePhoto={files} croppedPhoto={croppedPhoto} />
        </>
    );
}

export default CompanyInfo;
