import { React, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Navigate, useParams } from "react-router-dom";
import callFetch from "../../../helpers/callFetch";
import { useTranslation } from 'react-i18next';

function ACZahlertermin(props) {
    let params = useParams();
    const { t } = useTranslation();
    const [saving, setSaving] = useState(false);
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [refresh, setRefresh] = useState(0);
    const {
        register,
        handleSubmit,
        setError,
        setValue,
        unregister,
        formState: { errors },
    } = useForm();


    useEffect(() => {
        setValue('project_id', params.id);

        if (props?.acZahlertermin?.date) {
            setValue('date', props?.acZahlertermin?.date);
            setValue('time', props?.acZahlertermin?.time);
        }

    }, [params?.id, props]);

    const onSubmit = (formData) => {
        setSaving(true);
        callFetch("ac-zahlertermin/save", "POST", formData, setError).then((res) => {
            setSaving(false);
            props.setRefresh2((Math.random() * 1000) + 1000);
            if (!res.ok) return;
            setSubmitSuccess(true);
            document.getElementById('ACZahlerterminClose').click();
        });
    };

    return (
        <>
            <form className={`needs-validation ${Object.keys(errors).length ? "was-validated" : ""}`} onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
                <input type="hidden" {...register("project_id")} />

                <div className="modal fade right-side" id="ACZahlertermin" tabIndex="-1" role="dialog" aria-labelledby="ACZahlertermin" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-slide-right modal-dialog-scrollable" role="document">
                        <div className="modal-content" >
                            <div className="modal-header">
                                <h5 className="modal-title" id="ACZahlerterminLabel">{t('Fertigmeldung')}</h5>
                                <button type="button" className="btn-close text-dark" id="ACZahlerterminClose" data-bs-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true"></span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-md-6">
                                        <label>Datum *</label>
                                        <input
                                            type="date"
                                            className="form-control mb-2 flatpickr"
                                            placeholder="z.B. 16-04-2022"
                                            {...register('date', {
                                                onChange: () => {
                                                    //document.getElementById('WunschterminSave').click();
                                                },
                                                required: true,
                                            })}
                                            //min={minDate}
                                            required
                                        />
                                        <div className="invalid-feedback"> </div>
                                    </div>
                                    <div className="col-md-6">
                                        <label>{t('Time')} *</label>
                                        <input
                                            type="time"
                                            className="form-control mb-2 flatpickr"
                                            placeholder="z.B. 16-04-2022"
                                            {...register('time', {
                                                onChange: () => {
                                                    //document.getElementById('WunschterminSave').click();
                                                },
                                                required: true,
                                            })}
                                            //min={minDate}
                                            required
                                        />
                                        <div className="invalid-feedback"> </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                {!saving && (
                                    <button type="submit" className="btn btn-primary mb-0">
                                        {t('Save')}
                                    </button>
                                )}
                                {saving && (
                                    <button type="submit" className="btn btn-disabled mb-0">
                                        {t('Save...')}
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </>
    );
}

export default ACZahlertermin;
