import { React, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Navigate, useParams } from "react-router-dom";
import { callFetch } from "../../../../helpers/callFetch";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftSnackbar from "components/SoftSnackbar";
function Create({ value, onUpdate, refreshh }) {
  const { t } = useTranslation();
  const [filderData, setFilderData] = useState([]);
  const [users, setUsers] = useState([]);
  const [refresh, setRefresh] = useState(refreshh);
  const [moduleTyp, setModuleType] = useState("");
  const [saving, setSaving] = useState(false);
  let params = useParams();

  const updateHandler = () => {
    // Do something, then notify the parent
    onUpdate(Math.random());
  };

  const {
    register,
    handleSubmit,
    reset,
    setError,
    setValue,
    getValues,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    callFetch("get-module-info" + "/" + params.id, "GET", []).then((res) => {
      // setData(res.data);
      if (!res?.data) return;
      console.log(res.data);
      setValue("modulanzahl", res?.data?.anzahl_module);
      setValue("sonnenstunden", res?.data?.sonnenstunden); 
      setValue("gesamtleistung", res?.ges);
      setValue("eigenverbrauch", res?.data?.eigenverbrauch);
      setValue("own_consumption", res?.data?.own_consumption);
      setValue("kwh_preis", res?.data?.kwh_preis);
      setModuleType(res?.moduleWR?.name);
      setValue("modultyp", res?.moduleWR?.name);
      setValue("stromkosten", res?.data?.stromkosten);
      setValue(
        "einspeiseverguetung_preis",
        res?.data?.einspeiseverguetung_preis
      );
      setValue("notstrom_kapazitat", res?.data?.notstrom_kapazitat);
      setValue("modulwirkungsgrad", res?.data?.modulwirkungsgrad);
      setValue(
        "einspeiseverguetung_preis_percent",
        res?.data?.einspeiseverguetung_preis_percent
      );
      setValue("kaufpreisEigenverbrauch", res?.data?.kaufpreisEigenverbrauch);
      setValue(
        "kaufpreisEigenverbrauch_percent",
        res?.data?.kaufpreisEigenverbrauch_percent
      );
    });
  }, [refreshh, refresh]);

  const [successSB, setSuccessSB] = useState(false);
  const openSuccessSB = () => setSuccessSB(true);
  const closeSuccessSB = () => setSuccessSB(false);
  const renderSuccessSB = (
    <SoftSnackbar
      color="success"
      title="Success"
      content="Successfully updated"
      open={successSB}
      onClose={closeSuccessSB}
      close={closeSuccessSB}
    />
  );

  //Form Submit
  const onSubmit = (formData) => {
    setSaving(true);
    formData.id = params.id;
    callFetch(
      "update-quation-profit-clc-data",
      "POST",
      formData,
      setError
    ).then((res) => {
      setSaving(false);
      openSuccessSB();
      updateHandler();
      document.getElementById("cls-btn").click();
      setRefresh(refresh + 1);
    });
  };

  return (
    <>
      <div
        class="modal fade right-side"
        id="staticBackdropddd"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-slide-right modal-dialog-scrollable">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div class="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  id="cls-btn"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  className=" lose btn btn-icon-only btn-rounded btn-link text-primary mb-0 me-3 btn-sm d-flex align-items-center justify-content-center"
                  style={{
                    fontSize: "12px",
                    position: "absolute",
                    right: "0px",
                  }}
                >
                  {/* <i class="fa-sharp fa-solid fa-times icon icon-shape icon-sm  text-center d-flex align-items-center justify-content-center"></i> */}
                  <i class="ph ph-x  text-center d-flex align-items-center justify-content-center text-primary font-size-20"></i>
                </button>
                <SoftTypography
                  variant="button"
                  fontSize="20px"
                  fontWeight="600"
                  style={{ color: "#273042" }}
                >
                  {" "}
                  Datenerfassung für die Wirtschaftlichk...{" "}
                </SoftTypography>
              </div>
              <div
                class="modal-body pt-3 pb-3"
                style={{ overflow: "scrooll", maxHeight: "86vh" }}
              >
                <div className=""></div>
                <div className="row">
                  {/* Modultyp */}
                  <div className="col-sm-12">
                    {/* <div className="form-group mb-2 d-none">
                      <label className="me-2 mb-0">Modultyp</label>
                      <select
                        className="form-control"
                        {...register("modultyp")}
                      >
                        <option value="">-</option>
                        <option value={moduleTyp} selected="true">{moduleTyp}</option>
                      </select>
                    </div> */}
                    <div
                      class="accordion accordion-flush"
                      id="accordionFlushExample"
                    >
                      <div class="accordion-item">
                        <h2 class="accordion-header">
                          <button
                            class="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseOnee"
                            aria-expanded="false"
                            aria-controls="flush-collapseOnee"
                          >
                            <span class="accordion-arrow">
                              <i class="ph ph-caret-right"></i>
                            </span>
                            <p className="font-size-16 font-weight-500 text-primary">
                              <span className="pl-2">Modultyp</span>{" "}
                            </p>
                          </button>
                        </h2>
                        <div
                          id="flush-collapseOnee"
                          class="accordion-collapse collapse"
                          data-bs-parent="#accordionFlushExample"
                        >
                          <div class="accordion-body">
                            <div class="details-content">
                              <div className="mb-2 mt-2">
                                <span className="font-size-14 text-secondary">
                                  Entscheidungsträger
                                </span>
                                <span className="font-size-14 text text-light">
                                  {" "}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Modulanzahl */}
                  <div className="col-sm-12 d-none">
                    <div className="form-group mb-2">
                      <label className="me-2 mb-0">Modulanzahl</label>
                      <input
                        className="form-control"
                        {...register("modulanzahl")}
                      />
                    </div>
                  </div>

                  {/* Gesamtleistung */}
                  <div className="col-sm-12">
                    <div className="form-group mb-2">
                      <label className="me-2 mb-0">Gesamtleistung kwp</label>
                      <input
                        type="text"
                        className="form-control"
                        {...register("gesamtleistung")}
                      />
                    </div>
                  </div>

                  {/* In Simulation kWh/kwp */}
                  <div className="col-sm-12">
                    <div className="form-group mb-2">
                      <label className="me-2 mb-0">Eigenverbauch kwh</label>
                      <input
                        type="text"
                        className="form-control"
                        {...register("own_consumption", {
                          required: true,
                        })}
                        required
                      />
                      <div className="invalid-feedback">
                        {errors.own_consumption &&
                          errors.own_consumption.message}
                      </div>
                    </div>
                  </div>

                  <div className="col-sm-12">
                    <div className="form-group mb-2">
                      <label className="me-2 mb-0">In Simulation kWh/kwp</label>
                      <input
                        type="text"
                        className="form-control"
                        {...register("sonnenstunden")}
                      />
                    </div>
                  </div>

                  {/* Einspeisevergütung Preis / kWh */}
                  <div className="col-sm-6">
                    <div className="form-group mb-2">
                      <label className="me-2 mb-0">
                        Einspeisevergütung Preis / kWh
                      </label>
                      {/* <div className="input-group"> */}
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Amount e.g 3300"
                        {...register("einspeiseverguetung_preis")}
                      />{" "}
                    </div>
                  </div>

                  <div className="col-sm-6">
                    <div className="form-group mb-2">
                      <label className="mt-2">Abdeckung</label>
                      <select
                        className="form-control"
                        {...register("einspeiseverguetung_preis_percent")}
                      >
                        <option>--</option>
                        <option value={10}>10%</option>
                        <option value={20}>20%</option>
                        <option value={30}>30%</option>
                        <option value={40}>40%</option>
                        <option value={50}>50%</option>
                        <option value={60}>60%</option>
                        <option value={70}>70%</option>
                        <option value={80}>80%</option>
                        <option value={90}>90%</option>
                        <option value={100}>100%</option>
                      </select>{" "}
                    </div>
                  </div>

                  {/* Kaufpreis für Eigenverbrauch / kWh */}
                  <div className="col-sm-6">
                    <div className="form-group mb-2">
                      <label className="me-2 mb-0">
                        Kaufpreis für Eigenverbrauch / kWh
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Amount e.g 3300"
                        {...register("kaufpreisEigenverbrauch")}
                      />{" "}
                    </div>
                  </div>

                  <div className="col-sm-6">
                    <div className="form-group mb-2">
                      <label className="mt-2">Abdeckung</label>
                      <select
                        className="form-control"
                        {...register("kaufpreisEigenverbrauch_percent")}
                      >
                        <option>--</option>
                        <option value={10}>10%</option>
                        <option value={20}>20%</option>
                        <option value={30}>30%</option>
                        <option value={40}>40%</option>
                        <option value={50}>50%</option>
                        <option value={60}>60%</option>
                        <option value={70}>70%</option>
                        <option value={80}>80%</option>
                        <option value={90}>90%</option>
                        <option value={100}>100%</option>
                      </select>{" "}
                    </div>
                  </div>

                  {/* Stromverbrauch in kWh */}
                  <div className="col-sm-12">
                    <div className="form-group mb-2">
                      <label className="me-2 mb-0">Stromverbrauch in kWh</label>
                      <input
                        type="text"
                        className="form-control"
                        {...register("eigenverbrauch")}
                      />
                    </div>
                  </div>

                  {/* Stromkosten / Jahr */}
                  <div className="col-sm-12">
                    <div className="form-group mb-2">
                      <label className="me-2 mb-0">Stromkosten / Jahr</label>
                      <input
                        type="text"
                        className="form-control"
                        {...register("stromkosten")}
                      />
                    </div>
                  </div>

                  {/* Aktueller Strompreis des Kunden €/kWh */}
                  <div className="col-sm-12">
                    <div className="form-group mb-2">
                      <label className="me-2 mb-0">
                        Aktueller Strompreis des Kunden €/kWh
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        {...register("kwh_preis")}
                      />
                    </div>
                  </div>

                  <div className="col-sm-12">
                    <div className="form-group mb-2">
                      <label className="me-2 mb-0">Notstrom Kapazitat</label>
                      <select
                        className="form-control"
                        {...register("notstrom_kapazitat")}
                      >
                        <option>--</option>
                        <option value={0.1}>10%</option>
                        <option value={0.2}>20%</option>
                        <option value={0.3}>30%</option>
                        <option value={0.4}>40%</option>
                        <option value={0.5}>50%</option>
                        <option value={0.6}>60%</option>
                        <option value={0.7}>70%</option>
                        <option value={0.8}>80%</option>
                        <option value={0.9}>90%</option>
                        <option value={1}>100%</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <div className="form-group mb-2">
                      <label className="me-2 mb-0">Modulwirkungsgrad </label>
                      <select
                        className="form-control"
                        {...register("modulwirkungsgrad")}
                      >
                        <option>--</option>
                        <option value={0.1}>10%</option>
                        <option value={0.2}>20%</option>
                        <option value={0.3}>30%</option>
                        <option value={0.4}>40%</option>
                        <option value={0.5}>50%</option>
                        <option value={0.6}>60%</option>
                        <option value={0.7}>70%</option>
                        <option value={0.8}>80%</option>
                        <option value={0.9}>90%</option>
                        <option value={1}>100%</option>
                      </select>
                    </div>
                  </div>
                </div>
                {/* {saving ? (
                  <button
                    className="btn btn-secondary"
                    disabled
                    style={{ float: "right", width: "110px" }}
                  >
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  </button>
                ) : (
                  <button className="btn to-do-modal-btn">
                    {t("Erstellen")}
                  </button>
                )}  */}
              </div>

              <div className="modal-footer">
                <div className="d-flex justify-content-between w-100">
                  <button
                    type="button"
                    className="btn btn-secondary w-50 mx-2 mb-0"
                    id="modalClose"
                    data-bs-dismiss="modal"
                  >
                    {t("Abbrechen")}
                  </button>
                  {!saving && (
                    <button type="submit" className="btn btn-primary mb-0 w-50">
                      {t("Send")}
                    </button>
                  )}
                  {saving && (
                    <button
                      type="submit"
                      className="btn btn-disabled mb-0 w-50"
                      disabled
                    >
                      {t("Sending ...")}
                    </button>
                  )}
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      {renderSuccessSB}
    </>
  );
}

export default Create;
