import { React, useState, useEffect } from "react";
import { NavLink, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import EmployeeIndexTable from "./EmployeeIndexTable";
import Cookies from "js-cookie";
import SoftSnackbar from "components/SoftSnackbar";

function EmployeeIndex() {
  const { t } = useTranslation();
  let { status } = useParams();

  const [successSB, setSuccessSB] = useState(false);
  const openSuccessSB = () => setSuccessSB(true);
  const closeSuccessSB = () => setSuccessSB(false);
  const renderSuccessSB = (
    <SoftSnackbar
      color="success"
      icon="check"
      title="Success"
      content="Changes saved successfully"
      dateTime="Just Now"
      open={successSB}
      onClose={closeSuccessSB}
      close={closeSuccessSB}
      bgWhite
    />
  );

  useEffect(() => {
    console.log(status);
    if (status == "all") {
      openSuccessSB();
    }
  }, []);

  return (
    <>
      <div className="card">
        <div className='card-header d-flex align-items-center justify-content-between gap-3'>
          <h3 className='title'>{t("All Employees")}</h3>
          <div className='d-flex align-items-center justify-content-end gap-3'>
            {Cookies.get("permissions").indexOf("Import") !== -1 ? (
              <>
                <button
                  className="btn btn-secondary m-0 d-flex align-items-center justify-content-between gap-2"
                  data-bs-toggle="modal"
                  data-bs-target="#employeeimportModal"
                >
                  <i class="ph ph-arrow-square-in"></i>
                  {t("Import XLSX")}
                </button>

                <button
                  className="btn btn-secondary mx-3 d-none"
                  id="employeeoutputModal-show-button"
                  data-bs-toggle="modal"
                  data-bs-target="#employeeoutputModal"
                >
                  <i class="ph ph-arrow-square-in font-size-20"></i>  {t("Output XLSX")}
                </button>
              </>
            ) : (
              <></>
            )}
            {Cookies.get("permissions").indexOf("EC") !== -1 ? (
              <a
                target={"_blank"}
                rel="noreferrer"
                href={Cookies.get("backend_url") + "employee-export"}
                className="btn btn-secondary m-0 d-flex align-items-center justify-content-between gap-2"
              >
                <i class="ph ph-arrow-square-out"></i>
                {t("Export CSV")}
              </a>
            ) : (
              <></>
            )}

            {Cookies.get("permissions").indexOf("EC") !== -1 ? (
              <NavLink
                to="/employees/create"
                className="btn btn-primary m-0 d-flex align-items-center justify-content-between gap-2"
              >
                <i class="ph ph-plus"></i>
                {t("Add Employee")}
              </NavLink>
            ) : (
              <></>
            )}
          </div>
        </div>
        <div className="card-body pb-3 projects-menu">
          
            <ul
              class="nav nav-tabs border-0 gap-2"
              id="myTab"
              role="tablist"
            >
              <li class="nav-item" role="presentation">
                <button
                  class="px-2 text-primary text-md nav-link active"
                  id="active-user-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#active-user-tab-pane"
                  type="button"
                  role="tab"
                  aria-controls="active-user-tab-pane"
                  aria-selected="true"
                  style={{ color: "#0048B1", fontSize: "0.875rem" }}
                >
                  {t("Aktive User")}
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button
                  class="px-2 text-primary text-md nav-link"
                  id="inactive-user-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#inactive-user-tab-pane"
                  type="button"
                  role="tab"
                  aria-controls="inactive-user-tab-pane"
                  aria-selected="false"
                  style={{ color: "#0048B1", fontSize: "0.875rem" }}
                >
                  {t("Deaktive User")}
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button
                  class="px-2 text-primary text-md nav-link "
                  id="all-user-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#all-user-tab-pane"
                  type="button"
                  role="tab"
                  aria-controls="all-user-tab-pane"
                  aria-selected="false"
                  style={{ color: "#0048B1", fontSize: "0.875rem" }}
                >
                  {t("All")}
                </button>
              </li>
            </ul>
   
          <div class="tab-content mt-3" id="myTabContent">
            <div
              class="tab-pane fade show active"
              id="active-user-tab-pane"
              role="tabpanel"
              aria-labelledby="active-user-tab"
              tabindex="0"
            >
              <EmployeeIndexTable status={"aktivieren"} />
            </div>
            <div
              class="tab-pane fade"
              id="inactive-user-tab-pane"
              role="tabpanel"
              aria-labelledby="inactive-user-tab"
              tabindex="0"
            >
              <EmployeeIndexTable status={"deaktivieren"} />
            </div>
            <div
              class="tab-pane fade"
              id="all-user-tab-pane"
              role="tabpanel"
              aria-labelledby="all-user-tab"
              tabindex="0"
            >
              <EmployeeIndexTable status={""} />
            </div>
          </div>
        </div>
      </div>
      {renderSuccessSB}
    </>
  );
}

export default EmployeeIndex;
