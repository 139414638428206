import { React, useEffect, useState } from 'react';
import callFetch from "../../helpers/callFetch";
import { useTranslation } from 'react-i18next';
import DataTable from 'react-data-table-component';
import { useForm } from "react-hook-form";
import Checkbox from "@mui/material/Checkbox";

function IndexTableProjectWithSalses(props) {
    const { t } = useTranslation();
    const [saving, setSaving] = useState(false);
    const [loading, setLoading] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [tabledata, setTableData] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [deleteButton, setDeleteButton] = useState(false);
    const [refresh, setRefresh] = useState(0);
    const [refresh2, setRefresh2] = useState(0);
    const [searchKey, setSearchKey] = useState("")
    const [openMenu, setOpenMenu] = useState(null);
    const [assignedDateTimeUser, setAssignedDateTimeUser] = useState([]);
    const [project_Data, setProjectData] = useState([]);
    const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
    const handleCloseMenu = () => setOpenMenu(null);
    const [salesStatus, setSalesStatus] = useState([]);

    const {
        register,
        handleSubmit,
        setError,
        setValue,
        getValues,
        reset,
        watch,
        formState: { errors },
    } = useForm();

    useEffect(() => {
        callFetch("get-sales-status", "GET", []).then((res) => {
            setSalesStatus(res?.data)
        });
    }, []);

    useEffect(() => {
        setDeleteButton(false);
        if (getValues('selectedrow')) {
            for (let [selectedrow_key, selectedrow_value] of Object.entries(getValues('selectedrow'))) {
                if (selectedrow_value) {
                    setDeleteButton(true);
                }
            }
        }

    }, [refresh2]);

    const verkauferFeatch = () => {
        //callFetch("projects/create?date=" + (getValues('date') || '') + '&time=' + (getValues('time') || ''), "GET", []).then((res) => {
        callFetch("projects/create?date=" + '&time=', "GET", []).then((res) => {
            setProjectData(res.data);
        });
    }

    const deleteSelectedItem = () => {
        setDeleting(true);
        var selectedValues = [];
        for (let [selectedrow_key, selectedrow_value] of Object.entries(getValues('selectedrow'))) {
            if (selectedrow_value) {
                selectedValues.push(selectedrow_key);
            }
        }

        let formData = {
            ids: JSON.stringify(selectedValues),
            type: props.type,
        };

        callFetch("import/delete", "POST", formData, setError).then((res) => {
            if (res.message == 'success') {
                for (let [selectedrow_key, selectedrow_value] of Object.entries(getValues('selectedrow'))) {
                    setValue("selectedrow[" + selectedrow_key + "]", false)
                }
                setDeleting(false);
                setRefresh(refresh + 1);
                setRefresh2(refresh + 1);
            }
        })
    }


    const validateDateTimeVerkaufer = (row_id = 0) => {
        /*
        reset({});

        setTimeout(() =>{
            var dateTimeUserData = [];
            for (let [selectedrow_key, selectedrow_value] of Object.entries(getValues('verkaufer'))) {
                callFetch("projects/create?date="+getValues("date["+selectedrow_key+"]")+'&time='+getValues("time["+selectedrow_key+"]"), "GET", []).then((res) => {                        
                    var available_employees = [];
                    for (let [available_employee_key, available_employee_value] of Object.entries(res?.data?.employees)) {
                        available_employees.push(available_employee_value?.user?.id);
                    }

                    var dateTimeUser = getValues("date["+selectedrow_key+"]")+'_'+getValues("time["+selectedrow_key+"]")+'_'+getValues("verkaufer["+selectedrow_key+"]");
                    if(available_employees.indexOf(Number(getValues("verkaufer["+selectedrow_key+"]"))) !== -1){                            
                        if(dateTimeUserData.indexOf(dateTimeUser) !== -1){
                            setValue("verkaufer["+selectedrow_key+"]", '');                            
                        }else{
                            dateTimeUserData.push(dateTimeUser);
                        }
                    }else{
                        setValue("verkaufer["+selectedrow_key+"]", '');    
                    }
                    
                });
            }
            
        },300)
        */
    }

    const onSubmitSave = () => {
        setSaving(true);
        reset({});

        setTimeout(() => {
            var formData = {
                filename: props.filename,
            }
            callFetch("import/" + props.type + "/save", "POST", formData, setError).then((res) => {
                setSaving(false);
                if (res.message == 'success') {
                    setRefresh(refresh + 1);
                    setRefresh2(refresh + 1);
                    document.getElementById('outputModal-button-close').click();
                    window.location.reload();
                }
            });
        }, 1000)
    }


    const onSubmit = (formData) => {
        onSubmitSave();
    };

    const updateFileData = (formData) => {
        formData.type = props.type;
        setLoading(true);
        callFetch("import/update", "POST", formData, setError).then((res) => {
            if (res.message == 'success') {
                setLoading(false);
            }
        });
    }

    const tableHeadings = [
        {
            //name: t('Project Nr.'),
            name: <>
                <Checkbox
                    defaultChecked={false}
                    onChange={(e) => {
                        if (e.target.checked) {
                            for (let [selectedrow_key, selectedrow_value] of Object.entries(getValues('selectedrow'))) {
                                setValue("selectedrow[" + selectedrow_key + "]", true)
                                console.log()
                            }
                        } else {
                            for (let [selectedrow_key, selectedrow_value] of Object.entries(getValues('selectedrow'))) {
                                setValue("selectedrow[" + selectedrow_key + "]", false)
                            }
                        }
                        setRefresh2(refresh2 + 1);
                    }}
                    disabled={loading}
                />
            </>,
            width: '44px',
            cell: row => (
                <>
                    <Checkbox
                        {...register(`selectedrow[${row.id}]`, {
                            onChange: (e) => {
                                setRefresh2(refresh2 + 1);
                            }
                        })}
                        checked={watch(`selectedrow[${row.id}]`) || false}
                    />
                </>
            ),
            sortable: true,
        },
        {
            name: t('Date'),
            width: '150px',
            cell: row => (<>
                <input
                    className="form-control form-control-sm"
                    {...register("date[" + row.id + "]", {
                        required: true,
                        onChange: (e) => {
                            updateFileData({
                                id: row.id,
                                date: e.target.value
                            });
                            validateDateTimeVerkaufer(row.id);
                        }
                    })}
                    defaultValue={row.date}
                    required
                    type="date" />
            </>),
        },
        {
            name: t('Anrede'),
            width: '150px',
            cell: row => <select
                className="form-control form-control-sm"
                {...register("anrede[" + row.id + "]", {
                    required: false,
                    onChange: (e) => {
                        updateFileData({
                            id: row.id,
                            anrede: e.target.value
                        });
                    }
                })}
                defaultValue={row.anrede}
            >
                <option value="">--</option>
                <option value="Herr">{t('Herr')}</option>
                <option value="Frau">{t('Frau')}</option>
            </select>,
        },
        {
            name: t('Vorname'),
            width: '150px',
            cell: row => <input
                type="text"
                className="form-control form-control-sm"
                placeholder={t('')}
                {...register("vorname[" + row.id + "]", {
                    required: true,
                    onChange: (e) => {
                        updateFileData({
                            id: row.id,
                            vorname: e.target.value
                        });
                    }
                })}
                defaultValue={row.vorname}
                required
            />,
        },
        {
            name: t('Name'),
            width: '150px',
            cell: row => <input
                type="text"
                className="form-control form-control-sm"
                placeholder={t('')}
                {...register("name[" + row.id + "]", {
                    required: false,
                    onChange: (e) => {
                        updateFileData({
                            id: row.id,
                            name: e.target.value
                        });
                    }
                })}
                defaultValue={row.name}
            />,
        },
        {
            name: t('Straße'),
            width: '150px',
            cell: row => <input
                type="text"
                className="form-control form-control-sm"
                placeholder={t('')}
                {...register("street[" + row.id + "]", {
                    required: true,
                    onChange: (e) => {
                        updateFileData({
                            id: row.id,
                            street: e.target.value
                        });
                    }
                })}
                defaultValue={row.street}
                required
            />,
        },
        {
            name: t('Nr'),
            width: '150px',
            cell: row => <input
                type="text"
                className="form-control form-control-sm"
                placeholder={t('')}
                {...register("nr[" + row.id + "]", {
                    required: false,
                    onChange: (e) => {
                        updateFileData({
                            id: row.id,
                            nr: e.target.value
                        });
                    }
                })}
                defaultValue={row.nr}
            />,
        },
        {
            name: t('PLZ'),
            width: '150px',
            cell: row => <input
                type="text"
                className="form-control form-control-sm"
                placeholder={t('')}
                {...register("plz[" + row.id + "]", {
                    required: true,
                    onChange: (e) => {
                        updateFileData({
                            id: row.id,
                            plz: e.target.value
                        });
                    }
                })}
                defaultValue={row.plz}
                required
            />,
        },
        {
            name: t('Ort'),
            width: '150px',
            cell: row => <input
                type="text"
                className="form-control form-control-sm"
                placeholder={t('')}
                {...register("ort[" + row.id + "]", {
                    required: true,
                    onChange: (e) => {
                        updateFileData({
                            id: row.id,
                            ort: e.target.value
                        });
                    }
                })}
                defaultValue={row.ort}
                required
            />,
        },
        {
            name: t('Telefonnummer'),
            width: '150px',
            cell: row => <input
                type="text"
                className="form-control form-control-sm"
                placeholder={t('')}
                {...register("telefonnummer[" + row.id + "]", {
                    required: true,
                    onChange: (e) => {
                        updateFileData({
                            id: row.id,
                            telefonnummer: e.target.value
                        });
                    }
                })}
                defaultValue={row.telefonnummer}
                required
            />,
        },
        {
            name: t('Time'),
            width: '150px',
            cell: row => (
                <select
                    className="form-control form-control-sm"
                    {...register("time[" + row.id + "]", {
                        required: true,
                        onChange: (e) => {
                            updateFileData({
                                id: row.id,
                                time: e.target.value
                            });
                            validateDateTimeVerkaufer(row.id);
                        }
                    })}
                    required
                    defaultValue={row.time}
                >
                    <option value="">--</option>
                    <option value="08:00">08:00</option>
                    <option value="08:30">08:30</option>
                    <option value="09:00">09:00</option>
                    <option value="09:30">09:30</option>
                    <option value="10:00">10:00</option>
                    <option value="10:30">10:30</option>
                    <option value="11:00">11:00</option>
                    <option value="11:30">11:30</option>
                    <option value="12:00">12:00</option>
                    <option value="12:30">12:30</option>
                    <option value="13:00">13:00</option>
                    <option value="13:30">13:30</option>
                    <option value="14:00">14:00</option>
                    <option value="14:30">14:30</option>
                    <option value="15:00">15:00</option>
                    <option value="15:30">15:30</option>
                    <option value="16:00">16:00</option>
                    <option value="16:30">16:30</option>
                    <option value="17:00">17:00</option>
                    <option value="17:30">17:30</option>
                    <option value="18:00">18:00</option>
                    <option value="18:30">18:30</option>
                    <option value="19:00">19:00</option>
                    <option value="19:30">19:30</option>
                    <option value="20:00">20:00</option>
                    <option value="20:30">20:30</option>
                    <option value="21:00">21:00</option>
                    <option value="21:30">21:30</option>
                    <option value="22:00">22:00</option>
                </select>
            ),
        },
        {
            name: t('Verkaufer'),
            width: '250px',
            cell: row => (<>
                <select
                    className="form-control form-control-sm"
                    {...register("verkaufer[" + row.id + "]", {
                        required: true,
                        onChange: (e) => {
                            updateFileData({
                                id: row.id,
                                verkaufer: e.target.value
                            });
                            validateDateTimeVerkaufer(row.id);
                        }
                    })}
                    required
                    defaultValue={row.verkaufer}
                >
                    <option value="">--</option>
                    {project_Data && project_Data.employees ? project_Data.employees.map((employee) => (
                        <option key={employee.user.id} value={employee.user.id}>{employee.user.name}</option>
                    )) : <></>}
                </select>
            </>),
        },
        {
            name: t('Angebot Summe'),
            width: '150px',
            cell: row => <input
                type="number"
                className="form-control form-control-sm"
                placeholder={t('')}
                {...register("angebot_netto[" + row.id + "]", {
                    required: true,
                    onChange: (e) => {
                        updateFileData({
                            id: row.id,
                            angebot_netto: e.target.value
                        });
                    }
                })}
                defaultValue={row.angebot_netto}
                required
            />,
        },
        {
            name: t('Auftrag Summe'),
            width: '150px',
            cell: row => <input
                type="number"
                className="form-control form-control-sm"
                placeholder={t('')}
                {...register("auftrag_netto[" + row.id + "]", {
                    required: true,
                    onChange: (e) => {
                        updateFileData({
                            id: row.id,
                            auftrag_netto: e.target.value
                        });
                    }
                })}
                defaultValue={row.auftrag_netto}
                required
            />,
        },
        {
            name: t('Status'),
            width: '250px',
            cell: row =>
                <select
                    className="form-control form-control-sm"
                    {...register("status[" + row.id + "]", {
                        required: true,
                        onChange: (e) => {
                            updateFileData({
                                id: row.id,
                                status: e.target.value
                            });
                        }
                    })}
                    defaultValue={row.status}
                    required
                >
                    <option value="">--</option>
                    {salesStatus && salesStatus.map((status) => (
                        <option key={status.id} value={status.id}>{status.id + '-' + t(status.name)}</option>
                    ))}
                </select>,
        },
        /*
        {
            name: t('Firma'),
            width: '150px',
            cell: row =><input
                type="text"
                className="form-control form-control-sm"
                placeholder={t('')}
                {...register("firma["+row.id+"]", {
                    required: true,
                    onChange: (e) => {
                        updateFileData({
                            id: row.id,
                            firma: e.target.value
                        });
                    }
                })}
                defaultValue={row.firma}
                required
            />,
        },
        */
        {
            name: t('Modul Hersteller'),
            width: '150px',
            cell: row => <input
                type="text"
                className="form-control form-control-sm"
                placeholder={t('')}
                {...register("modul[" + row.id + "]", {
                    required: true,
                    onChange: (e) => {
                        updateFileData({
                            id: row.id,
                            modul: e.target.value
                        });
                    }
                })}
                defaultValue={row.modul}
                required
            />,
        },
        {
            name: t('Watt-Leistung'),
            width: '150px',
            cell: row => <input
                type="number"
                className="form-control form-control-sm"
                placeholder={t('')}
                {...register("watt[" + row.id + "]", {
                    required: true,
                    onChange: (e) => {
                        updateFileData({
                            id: row.id,
                            watt: e.target.value
                        });
                    }
                })}
                defaultValue={row.watt}
                required
            />,
        },
        {
            name: t('kWp'),
            width: '150px',
            cell: row => <input
                type="number"
                className="form-control form-control-sm"
                placeholder={t('')}
                {...register("kwp[" + row.id + "]", {
                    required: true,
                    onChange: (e) => {
                        updateFileData({
                            id: row.id,
                            kwp: e.target.value
                        });
                    }
                })}
                defaultValue={row.kwp}
                required
            />,
        },
        {
            name: t('Speicher'),
            width: '150px',
            cell: row => <input
                type="text"
                className="form-control form-control-sm"
                placeholder={t('')}
                {...register("speicher[" + row.id + "]", {
                    required: true,
                    onChange: (e) => {
                        updateFileData({
                            id: row.id,
                            speicher: e.target.value
                        });
                    }
                })}
                defaultValue={row.speicher}
                required
            />,
        },
        {
            name: t('DC Montage Datum'),
            width: '150px',
            cell: row => <input
                type="date"
                className="form-control form-control-sm"
                placeholder={t('')}
                {...register("dc_montage_date[" + row.id + "]", {
                    required: true,
                    onChange: (e) => {
                        updateFileData({
                            id: row.id,
                            dc_montage_date: e.target.value
                        });
                    }
                })}
                defaultValue={row.dc_montage_date}
                required
            />,
        },
        {
            name: t('DC Techniker'),
            width: '250px',
            cell: row =>
                <select
                    className="form-control form-control-sm"
                    {...register("dc_technician[" + row.id + "]", {
                        required: true,
                        onChange: (e) => {
                            updateFileData({
                                id: row.id,
                                dc_technician: e.target.value
                            });
                        }
                    })}
                    required
                    defaultValue={row.dc_technician}
                >
                    <option value="">--</option>
                    {project_Data && project_Data.employees ? project_Data.employees.map((employee) => (
                        <option key={employee.user.id} value={employee.user.id}>{employee.user.name}</option>
                    )) : <></>}
                </select>,
        },
        {
            name: t('AC Montage Datum'),
            width: '150px',
            cell: row => <input
                type="date"
                className="form-control form-control-sm"
                placeholder={t('')}
                {...register("ac_montage_date[" + row.id + "]", {
                    required: true,
                    onChange: (e) => {
                        updateFileData({
                            id: row.id,
                            ac_montage_date: e.target.value
                        });
                    }
                })}
                defaultValue={row.ac_montage_date}
                required
            />,
        },
        {
            name: t('AC Techniker'),
            width: '250px',
            cell: row =>
                <select
                    className="form-control form-control-sm"
                    {...register("ac_technician[" + row.id + "]", {
                        required: true,
                        onChange: (e) => {
                            updateFileData({
                                id: row.id,
                                ac_technician: e.target.value
                            });
                        }
                    })}
                    required
                    defaultValue={row.ac_technician}
                >
                    <option value="">--</option>
                    {project_Data && project_Data.employees ? project_Data.employees.map((employee) => (
                        <option key={employee.user.id} value={employee.user.id}>{employee.user.name}</option>
                    )) : <></>}
                </select>,
        },
    ];

    useEffect(() => {
        if (!props.filename.length) return;

        if (props?.filename) {
            verkauferFeatch();
            callFetch("import-data/" + props?.filename + '/' + props?.type + "?page=" + pageNumber, "GET", []).then((res) => {
                setTableData(res.data);

                validateDateTimeVerkaufer();
                // console.log(res.data);
            });
        }

    }, [pageNumber, refresh, props.filename]);

    const handlePageChange = page => {
        setPageNumber(page);
    }

    function toPages(pages) {
        const results = [];

        for (let i = 1; i <= pages; i++) {
            results.push(i);
        }

        return results;
    }

    // RDT exposes the following internal pagination properties
    const BootyPagination = ({
        onChangePage,
        currentPage
    }) => {
        const handleBackButtonClick = () => {
            onChangePage(currentPage - 1);
        };

        const handleNextButtonClick = () => {
            onChangePage(currentPage + 1);
        };

        const handlePageNumber = (e) => {
            onChangePage(Number(e.target.value));
        };

        const pages = tabledata.last_page;
        const pageItems = toPages(pages);
        const nextDisabled = currentPage === tabledata.last_page;
        const previosDisabled = currentPage === 1;

        return (
            <>
                {tabledata.last_page > 1 && (
                    <div className='table-pagination d-flex justify-content-between align-items-center pb-0'>
                        <p className="entries">{tabledata.from} - {tabledata.to} {t('of')} {tabledata.total} {t('entries')}</p>
                        <ul className="pagination ">
                            <li className="page-item">
                                <button
                                    className="page-link"
                                    onClick={handleBackButtonClick}
                                    disabled={previosDisabled}
                                    aria-disabled={previosDisabled}
                                    aria-label="previous page"
                                >
                                    <i className='ph ph-caret-double-left text-primary text-xl'></i>
                                </button>
                            </li>

                            <li className="page-item">
                                <button
                                    className="page-link"
                                    onClick={handleBackButtonClick}
                                    disabled={previosDisabled}
                                    aria-disabled={previosDisabled}
                                    aria-label="previous page"
                                >
                                    <i className='ph ph-caret-left text-primary text-xl'></i>
                                </button>
                            </li>

                            {tabledata?.links.map((page) => {
                                //const className = (parseInt(page.label) === currentPage ? "page-item active" : "page-item");
                                const className = (page.active === true ? "page-item active" : "page-item");
                                return (
                                    <li key={page.label} className={className + ' ' + (parseInt(page.label) || page.label == '...' ? '' : 'd-none')}>
                                        <button
                                            className="page-link"
                                            onClick={page.label != '...' ? handlePageNumber : ''}
                                            value={page.label}
                                            disabled={page.label == '...' ? true : false}
                                        >
                                            {page.label}
                                        </button>
                                    </li>
                                );
                            })}

                            <li className="page-item">
                                <button
                                    className="page-link"
                                    onClick={handleNextButtonClick}
                                    disabled={nextDisabled}
                                    aria-disabled={nextDisabled}
                                    aria-label="next page"
                                >
                                    <i className='ph ph-caret-right text-primary text-xl'></i>
                                </button>
                            </li>

                            <li className="page-item">
                                <button
                                    className="page-link"
                                    onClick={handleNextButtonClick}
                                    disabled={nextDisabled}
                                    aria-disabled={nextDisabled}
                                    aria-label="next page"
                                >
                                    <i className='ph ph-caret-double-right text-primary text-xl'></i>
                                </button>
                            </li>
                        </ul>
                    </div>
                )}
            </>
        );
    };

    return <>
        <form className={`needs-validation ${Object.keys(errors).length ? "was-validated" : ""}`} onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
            <div className="modal-body">
                <div className='d-flex align-items-center gap-2 mb-3'>
                    <input
                        type="date"
                        className='form-control'
                        style={{ maxWidth: '320px' }}
                        onChange={(e) => {
                            for (let [selectedrow_key, selectedrow_value] of Object.entries(getValues('selectedrow'))) {
                                if (selectedrow_value) {
                                    setValue("date[" + selectedrow_key + "]", e.target.value);
                                    updateFileData({
                                        id: selectedrow_key,
                                        date: e.target.value
                                    });
                                }
                            }
                        }}
                        disabled={loading}
                    />

                    {deleteButton ? (
                        <>
                            {deleting ? (
                                <button type='button' className='btn btn-danger mb-0' disabled>{t('Deleting ...')}</button>
                            ) : (
                                <button type='button' className='btn btn-danger mb-0' onClick={() => {
                                    deleteSelectedItem();
                                }}>{t('Delete')}</button>
                            )}
                        </>
                    ) : (
                        <button type='button' className='btn btn-danger mb-0' disabled>{t('Delete')}</button>
                    )}
                </div>
                <DataTable
                    columns={tableHeadings}
                    data={tabledata?.data}
                    noDataComponent={
                        <div className="d-flex align-items-center justify-content-center py-3" style={{ height: "100%" }}>
                            <div className="text-center">
                                <i className='ph ph-notepad data-table-no-record-icon'></i>
                                <p className="text-secondary text-md m-0">{t('There are no records to display')}</p>
                            </div>
                        </div>
                    }
                    className='data-table'
                    pagination
                    highlightOnHover
                    paginationComponentOptions={{ noRowsPerPage: true }}
                    paginationServer
                    paginationTotalRows={tabledata?.total}
                    onChangePage={handlePageChange}
                    paginationComponent={BootyPagination}
                />
            </div>

            <div className='modal-footer'>
                <button className='btn btn-secondary m-0 mx-2' onClick={() => {
                    document.getElementById('outputModal-button-close').click();
                }}>{t('Cancel')}</button>

                {loading == false ? (
                    <>
                        {!saving && (
                            <button type="submit" className="btn btn-primary m-0">
                                {t(props.type + ' upload')}
                            </button>
                        )}
                        {saving && (
                            <button type="submit" className="btn btn-disabled m-0" disabled>
                                {t(props.type + ' upload ...')}
                            </button>
                        )}
                    </>
                ) : (
                    <button type="submit" className="btn btn-disabled m-0" disabled>
                        {t('Loading ...')}
                    </button>
                )}
            </div>
        </form>
    </>
}

export default IndexTableProjectWithSalses;
