import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import callFetch from "../../../helpers/callFetch";
import { useTranslation } from "react-i18next";
import DataTable from "react-data-table-component";
import deleteAlert from "../../../helpers/deleteAlert";
import Cookies from "js-cookie";
import dateFormat from "dateformat";
import { NumericFormat } from "react-number-format";
import Create from "pages/finance/EngangsInvoice/IncomingInvoiceCreate";
import Empty from "components/Solacloud/Empty";
const IncomingInvoiceIndexTable = (props) => {
  const { t } = useTranslation();
  const [tabledata, setTableData] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [refresh, setRefresh] = useState(0);

  var typingTimer;
  const [searchKey, setSearchKey] = useState("");

  const tableHeadings = [
    {
      name: t("Rechungsnummer."),
      selector: (row) => (
        <NavLink
          to={"/eingangsrechnungen/" + row?.eingangs_rechungs_nummer}
          className={"text-primary font-weight-bold"}
        >
          {"ER-" + row?.eingangs_rechungs_nummer}
        </NavLink>
      ),
      sortable: true,
    },
    {
      name: t("Project Nr."),
      selector: (row) => (
        <NavLink
          to={"/projects/" + row.project_id}
          className="text-primary font-weight-bold"
        >
          {row.project_id}
        </NavLink>
      ),
    },
    {
      name: t("Lieferant & Partner"),
      selector: (row) =>
        row?.lieferanten?.firma_name ? row?.lieferanten?.firma_name : "",
    },
    {
      name: t("Date"),
      selector: (row) => dateFormat(row.created_at, "dd.mm.yyyy"),
    },
    {
      name: t("Invoice Amount"),
      selector: (row) => (
        <>
          <NumericFormat
            value={Number(JSON.parse(row?.product_details)?.total)}
            displayType="text"
            thousandSeparator={"."}
            decimalSeparator=","
            decimalScale={2}
            fixedDecimalScale
            suffix={row.currency == "EUR" ? " € " : " € "}
          />
        </>
      ),
    },
    {
      name: t("Abteilung"),
      selector: (row) => row?.abteilung,
    },
    {
      name: t("Actions"),
      cell: (row) => (
        <>
          {JSON.parse(Cookies.get("permissions")).indexOf("IU") !== 1 ||
          JSON.parse(Cookies.get("permissions")).indexOf("ID") !== 1 ? (
            <div className="text-center dropstart">
              <a
                href="/"
                className="dropdown-toggle"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i className="fa fa-ellipsis-v text-xs"></i>
              </a>
              <ul className="dropdown-menu">
                {JSON.parse(Cookies.get("permissions")).indexOf("IU") !== 1 ? (
                  <>
                    <li>
                      <NavLink
                        to={
                          "/eingangsrechnungen/" + row.eingangs_rechungs_nummer
                        }
                        className="dropdown-item"
                      >
                        {t("Edit")}
                      </NavLink>
                    </li>
                    <li>
                      <hr className="dropdown-divider" />
                    </li>
                  </>
                ) : (
                  <></>
                )}
                {JSON.parse(Cookies.get("permissions")).indexOf("ID") !== 1 ? (
                  <li>
                    <a
                      className="dropdown-item text-danger"
                      href="#0"
                      onClick={(e) =>
                        deleteAlert(e, "incoming-invoice", row.id, t).then(
                          (res) => setRefresh(refresh + 1)
                        )
                      }
                    >
                      {t("Delete")}
                    </a>
                  </li>
                ) : (
                  <></>
                )}
              </ul>
            </div>
          ) : (
            <></>
          )}
        </>
      ),
    },
  ];

  useEffect(() => {
    var projectId = "";
    if (props?.projectId && props?.projectId > 0) {
      projectId = props?.projectId;
    }

    callFetch(
      "incoming-invoice?page=" +
        pageNumber +
        "&search=" +
        searchKey +
        "&projectId=" +
        projectId,
      "GET",
      []
    ).then((res) => {
      setTableData(res.data);
    });
  }, [pageNumber, refresh, searchKey, props?.projectId]);

  const handlePageChange = (page) => {
    setPageNumber(page);
  };

  function toPages(pages) {
    const results = [];

    for (let i = 1; i <= pages; i++) {
      results.push(i);
    }

    return results;
  }

  // RDT exposes the following internal pagination properties
  const BootyPagination = ({ onChangePage, currentPage }) => {
    const handleBackButtonClick = () => {
      onChangePage(currentPage - 1);
    };

    const handleNextButtonClick = () => {
      onChangePage(currentPage + 1);
    };

    const handlePageNumber = (e) => {
      onChangePage(Number(e.target.value));
    };

    const pages = tabledata.last_page;
    const pageItems = toPages(pages);
    const nextDisabled = currentPage === tabledata.last_page;
    const previosDisabled = currentPage === 1;

    return (
      <>
        <br />
        <p className="float-md-start pt-2 text-secondary text-xs font-weight-bolder ms-3">
          {t("Showing")} {tabledata.from} {t("to")} {tabledata.to} {t("of")}{" "}
          {tabledata.total} {t("entries")}
        </p>
        <nav className="float-md-end me-2">
          <ul className="pagination">
            <li className="page-item">
              <button
                className="page-link"
                onClick={handleBackButtonClick}
                disabled={previosDisabled}
                aria-disabled={previosDisabled}
                aria-label="previous page"
              >
                &#60;
              </button>
            </li>
            {pageItems.map((page) => {
              const className =
                page === currentPage ? "page-item active" : "page-item";

              return (
                <li key={page} className={className}>
                  <button
                    className="page-link"
                    onClick={handlePageNumber}
                    value={page}
                  >
                    {page}
                  </button>
                </li>
              );
            })}
            <li className="page-item">
              <button
                className="page-link"
                onClick={handleNextButtonClick}
                disabled={nextDisabled}
                aria-disabled={nextDisabled}
                aria-label="next page"
              >
                &#62;
              </button>
            </li>
          </ul>
        </nav>
        <div className="clearfix"></div>
      </>
    );
  };

  return (
    <>
      {tabledata?.data?.length > 0 ? (
        <>
          <DataTable
            columns={tableHeadings}
            data={tabledata?.data}
            noDataComponent={t("There are no records to display")}
            className="data-table"
            // pagination
            highlightOnHover
            paginationComponentOptions={{ noRowsPerPage: true }}
            paginationServer
            paginationTotalRows={tabledata?.total}
            onChangePage={handlePageChange}
            paginationComponent={BootyPagination}
            // subHeader
            subHeaderComponent={
              <>
                {props?.canCreate ? (
                  <button
                    type="button"
                    className="btn btn-primary m-0 float-start"
                    data-bs-toggle="modal"
                    data-bs-target="#createIncommingInvoice"
                    onClick={() => {
                      //document.getElementById('createIncommingInvoiceBtn').click();
                    }}
                    style={{ background: "#CB0C9F" }}
                  >
                    {t("Eingangsrechnungen Buchen")}
                  </button>
                ) : (
                  ""
                )}

                {props?.filterOptions !== false ? (
                  <input
                    type="text"
                    placeholder={t("Search...")}
                    className=" form-control w-sm-50 w-md-25 w-lg-15 ms-2 mt-1"
                    defaultValue={searchKey}
                    /*
                                onChange={(e)=> {
                                    setSearchKey(e.target.value);
                                }}
                                */
                    onKeyUp={(e) => {
                      clearTimeout(typingTimer);
                      typingTimer = setTimeout(() => {
                        setTableData([]);
                        setSearchKey(e.target.value);
                      }, 1500);
                    }}
                    onKeyDown={(e) => {
                      clearTimeout(typingTimer);
                    }}
                  />
                ) : (
                  ""
                )}
              </>
            }
          />
        </>
      ) : (
        <>
          <Empty modalTarget="#createIncommingInvoice" text="Keine Datensätze vorhanden" btnText="Eingangsrechnungen Buchen" height="200px"></Empty>
        </>
      )}

      <button
        id="IncommingInvoiceRefresh"
        onClick={() => {
          setRefresh(refresh + 1);
        }}
        className="d-none"
      >
        IncommingInvoiceRefresh
      </button>

      <div
        className="modal fade right-side"
        id="createIncommingInvoice"
        tabindex="-1"
        role="dialog"
        aria-labelledby="createIncommingInvoiceLabel"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-slide-right modal-dialog-scrollable"
          role="document"
        >
          <div className="modal-content"> 
              <Create
                projectId={props?.projectId}
                col="col-md-12"
                transections={false}
                redirect={false}
                popupClose={true}
                IncommingInvoiceRefresh={true}
              /> 
          </div>
        </div>
      </div>
    </>
  );
};

export default IncomingInvoiceIndexTable;
