/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import { fontSize, fontWeight, height } from "@mui/system";

function BackgroundBlogCard({ color, image, title, description, action }) {
  return (
    <Card raised
      style={{
        background: '#208DFC',
        boxShadow: '0px 2px 2px 0px #3741540F',
        borderRadius: '8px'
      }}
    >
      <SoftBox
        sx={({
          functions: { linearGradient, rgba },
          palette: { gradients },
          borders: { borderRadius },
        }) => ({
          //backgroundImage: `url(${image})`,
          backgroundSize: "197px",
          //borderRadius: borderRadius.lg,
          p: 2,
          backgroundRepeat: "no-repeat",
          backgroundPositionX: "right",
          backgroundPositionY: "bottom",
          //marginRight: "-35px",
          // marginBottom: "-25px",
          //height: '213px'
        })}
      >
        <SoftBox mb={1}>
          <SoftTypography
            style={{
              fontSize: '20px',
              color: '#FFFFFF',
              fontWeight: 600
            }}
          >
            {title}
          </SoftTypography>
        </SoftBox>
        <SoftBox mb={1}>
          <SoftTypography
            style={{
              fontSize: '14px',
              color: '#F8F9FA',
              fontWeight: 400
            }}
          >
            {description}
          </SoftTypography>
        </SoftBox>

        {action.type === "internal" ? (
          <Link to={action.route} className="btn btn-secondary btn-hover-white btn-plus-icon btn-icon-primary m-0">
            {action.label}
          </Link>
        ) : action.type === "popup" ? (
          <button
            className={action?.className ? action?.className : "btn-soft-cstm"}
            data-bs-toggle="modal"
            data-bs-target={`#${action?.targetId || ''}`}
          >
            {action.label}
            {action?.iconposition === 'right' && <i className={action?.iconClass}></i>}
          </button>
        ) : (
          <button
            className={action?.className ? action?.className : "btn-soft-cstm"}
            component="a"
            href={action.route}
            target="_blank"
          >
            {action.label}
            {action?.iconposition === 'right' && <i className={action?.iconClass}></i>}
          </button>
        )}

        <img
          src={image}
          style={{
            position: "absolute",
            width: "240px",
            height: "174px",
            opacity: "0.12",
            right: "-35px",
            bottom: "-35px",
          }} />
      </SoftBox>
    </Card>
  );
}

// Setting default values for the props of BackgroundBlogCard
BackgroundBlogCard.defaultProps = {
  color: "dark",
};

// Typechecking props for the BackgroundBlogCard
BackgroundBlogCard.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.node.isRequired,
  action: PropTypes.shape({
    type: PropTypes.oneOf(["external", "internal"]).isRequired,
    route: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  }).isRequired,
};

export default BackgroundBlogCard;
