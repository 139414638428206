import { React, useEffect, useState } from "react"; 
// @mui material components
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import { NavLink } from "react-router-dom";
// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import callFetch from "../../helpers/callFetch";
// Data
import Cookies from 'js-cookie';
import LastMessage from "./LastMessage";
import { useTranslation } from "react-i18next";
import NewMessage from "./NewMessage"; 
function Index() {
  const { t } = useTranslation();
  const [participations, setParticipations] = useState([]);
  const [query, setQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [searchResult, setSearchResult] = useState([]);
  const [showBorder, setShowBorder] = useState(false);
  const [customClass, setCustomClass] = useState('');
  const handleResponsive = () => {
    setCustomClass('user-chat-show')
  }

 
  useEffect(() => {
    if (query) {
        // setLoading(true);
        // setSearchResult([]);
        callFetch("search-participation/"+query, "GET", []).then((res) => {
            setSearchResult(res.records);
            setLoading(true);
          });
    //   setLoading(true); 
    //   searchByTitle({ title: query, fetchCached })
    //     .then(({ data }) => {
    //       setLoading(false);
    //       setMovies(data.movies);
    //       setQueryTime(data.duration_in_milliseconds);
    //     })
    //     .catch((error) => {
    //       handleError(error);
    //       setLoading(false);
    //     });
    }
  }, [query]);

  useEffect(() => {
  callFetch("chat-participation", "GET", []).then((res) => {
    //   console.log(res.contacts);
      setParticipations(res.contacts);
  });
  }, []);
  return (
  <>
    <>
    <div className="layout-wrapper d-lg-flex chat">  
    <div className="chat-leftsidebar me-lg-3 ms-lg-0" style={{backgroundColor: '#fff'}}>
    <div className="tab-content">  
        <div className="tab-pane fade show active" id="pills-chat" role="tabpanel" aria-labelledby="pills-chat-tab">

            <div>
                <div className="px-3 pt-3">
                    {/* <h4 className="mb-4" style={{position: 'relative'}}>{t('Message')} <NavLink to={'/chat/send-message'} className="btn btn-primary" style={{position: 'absolute', right: '0px', fontSize: '12px', padding: '8px'}}>{t('New Message')}</NavLink></h4> */}
                    {/* <div className="search-box chat-search-box">            
                        <div className="input-group mb-3 rounded-3">
                            <div class="input-group flex-nowrap">
                                <span class="input-group-text border-primary" id="addon-wrapping">@</span>
                                <input type="text" class="form-control search-user" placeholder="Username" aria-label="Username" aria-describedby="addon-wrapping"/>
                            </div> */}
                            {/* <input type="text" value={query} onChange={({ target }) => setQuery(target.value)} className="form-control bg-light" placeholder={t('Search Users')} aria-label="Search users" aria-describedby="basic-addon1" style={{ borderLeft:'none !important', borderRadius: '0px 5px 5px 0px !important' }}/> */}
                        {/* </div>  */}
                    {/* </div>   */}
                    <div class="search-container">
                        <div class={ showBorder ? "search-box border-primary" : "search-box border-primary"}>
                            <span class="user-search-icon">
                                <img src="../../assets/icons/MagnifyingGlass.svg"/>
                            </span>
                            <input type="text" onChange={({ target }) => setQuery(target.value)} value={query} placeholder="User Suchen"/>
                        </div>
                        <button class="add-button border-primary d-flex align-items-center justify-content-center" data-bs-toggle="modal" data-bs-target="#newMessageModal">
                        <img src="../../assets/icons/PlusHover.svg"/>
                        </button>
                    </div>
                </div>  

            
                <div className="px-3 pb-3" dir="ltr">

                    <div className="owl-carousel owl-theme" id="user-status-carousel">
                        <div className="item">
                            <a href="#" className="user-status-box">
                                <div className="avatar-xs mx-auto d-block chat-user-img online">
                                    <img src="assets/images/users/avatar-2.jpg" alt="user-img" className="img-fluid rounded-circle"/>
                                    <span className="user-status"></span>
                                </div>

                                <h5 className="font-size-13 text-truncate mt-3 mb-1">{t('Patrick')}</h5>
                            </a>
                        </div>
                        <div className="item">
                            <a href="#" className="user-status-box">
                                <div className="avatar-xs mx-auto d-block chat-user-img online">
                                    <img src="assets/images/users/avatar-4.jpg" alt="user-img" className="img-fluid rounded-circle"/>
                                    <span className="user-status"></span>
                                </div>

                                <h5 className="font-size-13 text-truncate mt-3 mb-1">{t('Doris')}</h5>
                            </a>
                        </div>

                        <div className="item">
                            <a href="#" className="user-status-box">
                                <div className="avatar-xs mx-auto d-block chat-user-img online">
                                    <img src="assets/images/users/avatar-5.jpg" alt="user-img" className="img-fluid rounded-circle"/>
                                    <span className="user-status"></span>
                                </div>

                                <h5 className="font-size-13 text-truncate mt-3 mb-1">{t('Emily')}</h5>
                            </a>
                        </div>

                        <div className="item">
                            <a href="#" className="user-status-box">
                                <div className="avatar-xs mx-auto d-block chat-user-img online">
                                    <img src="assets/images/users/avatar-6.jpg" alt="user-img" className="img-fluid rounded-circle"/>
                                    <span className="user-status"></span>
                                </div>

                                <h5 className="font-size-13 text-truncate mt-3 mb-1">{t('Steve')}</h5>
                            </a>
                        </div>

                        <div className="item">
                            <a href="#" className="user-status-box">
                                <div className="avatar-xs mx-auto d-block chat-user-img online">
                                    <span className="avatar-title rounded-circle bg-soft-primary text-primary">
                                        T
                                    </span>
                                    <span className="user-status"></span>
                                </div>

                                <h5 className="font-size-13 text-truncate mt-3 mb-1">{t('Teresa')}</h5>
                            </a>
                        </div>

                    </div> 
                </div> 

                <div>
                    {/* <h5 className="mb-3 px-3 font-size-16">{t('Recent')}</h5> */}

                    <div className="chat-message-list px-3" data-simplebar>

                        <ul className="list-unstyled chat-list chat-user-list">
                        {!loading && (
                                participations.map((user) => (
                                    <li onClick={handleResponsive}>
                                        <NavLink to={'/chat/'+user.id+'/'+user?.name}>
                                            <div className="d-flex">                            
                                                <div className="chat-user-img online align-self-center me-3 ms-0">
                                                <img className="avatar avatar-sm" src={user.photo ? Cookies.get('backend_url') + 'storage/avatar/' + user.photo : '/assets/img/placeholder.png'} alt="" />
                                                    {/* <span className="user-status"></span> */}
                                                </div>
                                                        
                                                <div className="flex-grow-1 overflow-hidden">
                                                    <h5 className="text-truncate font-size-14 mb-1 font-wieght-500 position-relative">{user.name} <span className="font-size-12 position-absolute last-message-time">09:10</span></h5>
                                                    <p className="chat-user-message text-truncate mb-0">
                                                        <LastMessage userId={user.id}></LastMessage>
                                                    </p> 
                                                </div>
                                                <div className="font-size-11"></div>
                                                <div className="unread-message">
                                                    <span className="badge badge-soft-danger rounded-pill"></span>
                                                </div>
                                                {/* <div className="dropdown align-self-start">
                                                        <a href="!0" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{borderTop: '0px'}}>
                                                            <i className="ri-more-2-fill"></i>
                                                        </a>
                                                        <div className="dropdown-menu"> 
                                                            <a className="dropdown-item" href="javascript::void(0)" onClick={() => deleteChat(user.id)}>{t('Delete')} <i className="ri-delete-bin-line float-end text-muted"></i></a>
                                                        </div>
                                                </div> */}
                                            </div>
                                        </NavLink>
                                    </li>
                                ))
                            )}

                            {loading && (
                                Array.isArray(searchResult) && searchResult.map((user) => (
                                    <li>
                                        <NavLink to={'/chat/'+user.id+'/'+user?.name}>
                                            <div className="d-flex">                            
                                                <div className="chat-user-img online align-self-center me-3 ms-0">
                                                <img className="avatar avatar-sm" src={user.photo ? Cookies.get('backend_url') + 'storage/avatar/' + user.photo : '/assets/img/placeholder.png'} alt="" />
                                                    {/* <span className="user-status"></span> */}
                                                </div>
                                                        
                                                <div className="flex-grow-1 overflow-hidden">
                                                    <h5 className="text-truncate font-size-15 mb-1">{user.name}</h5>
                                                    <p className="chat-user-message text-truncate mb-0">
                                                        <LastMessage userId={user.id}></LastMessage>
                                                    </p>
                                                </div>
                                                <div className="font-size-11"></div>
                                                <div className="unread-message">
                                                    <span className="badge badge-soft-danger rounded-pill"></span>
                                                </div>
                                            </div>
                                        </NavLink>
                                    </li>
                                ))
                            )}

                            {/* <li className="unread">
                                <a href="#">
                                    <div className="d-flex">
                                        <div className="chat-user-img away align-self-center me-3 ms-0">
                                            <img src="https://aui.atlassian.com/aui/latest/docs/images/avatar-person.svg" className="rounded-circle avatar-xs" alt=""/>
                                            <span className="user-status"></span>
                                        </div>
                                        <div className="flex-grow-1 overflow-hidden">
                                            <h5 className="text-truncate font-size-15 mb-1">Mark Messer</h5>
                                            <p className="chat-user-message text-truncate mb-0"><i className="ri-image-fill align-middle me-1 ms-0"></i> Images</p>
                                        </div>
                                        <div className="font-size-11">12 min</div>        
                                        <div className="unread-message">
                                            <span className="badge badge-soft-danger rounded-pill">02</span>
                                        </div>
                                    </div>
                                </a>
                            </li>


                            <li className="active">
                                <a href="#">
                                    <div className="d-flex">
                                        <div className="chat-user-img online align-self-center me-3 ms-0">
                                            <img src="https://aui.atlassian.com/aui/latest/docs/images/avatar-person.svg" className="rounded-circle avatar-xs" alt=""/>
                                            <span className="user-status"></span>
                                        </div>
                                        <div className="flex-grow-1 overflow-hidden">
                                            <h5 className="text-truncate font-size-15 mb-1">Doris Brown</h5>
                                            <p className="chat-user-message text-truncate mb-0">Nice to meet you</p>
                                        </div>    
                                        <div className="font-size-11">10:12 AM</div>
                                    </div>
                                </a>
                            </li>
                            
                            <li className="typing">
                                <a href="#">                        
                                    <div className="d-flex">
                                        <div className="chat-user-img align-self-center online me-3 ms-0">
                                            <div className="avatar-xs">
                                                <span className="avatar-title rounded-circle bg-soft-primary text-primary">
                                                    A
                                                </span>
                                            </div>
                                            <span className="user-status"></span>
                                        </div>
                                        <div className="flex-grow-1 overflow-hidden">
                                            <h5 className="text-truncate font-size-15 mb-1">Albert Rodarte</h5>
                                            <p className="chat-user-message text-truncate mb-0">typing<span className="animate-typing">
                                                <span className="dot"></span>
                                                <span className="dot"></span>
                                                <span className="dot"></span>
                                            </span></p>
                                        </div>
                                        <div className="font-size-11">04:56 PM</div>
                                    </div>  
                                </a>
                            </li> */}

                        </ul>
                    </div>
                </div> 
            </div> 
        </div>  
        
        {/* End settings tab-pane */}
    </div>
    {/* end tab content */}

    </div>
    {/* end chat-leftsidebar */}

    {/* Start User chat */}
    <div className="user-chat w-100 overflow-hidden">
        <div className="d-lg-flex">

            {/* start chat conversation section */}
            <div className="w-100 overflow-hidden position-relative"> 
                {/* start chat conversation */}
                <div className="chat-conversation-2 p-3 d-flex align-items-center justify-content-center">
                    <p className="text-center text-secondary font-weight-500">Select someone and start messaging!</p>
                </div>
                {/* end chat conversation end */} 
            </div>
            {/* end chat conversation section */}

            {/* start User profile detail sidebar */}
            <div className="user-profile-sidebar">
                <div className="px-3 px-lg-4 pt-3 pt-lg-4">
                    <div className="user-chat-nav text-end">
                        <button type="button" className="btn nav-btn" id="user-profile-hide">
                            <i className="ri-close-line"></i>
                        </button>
                    </div>
                </div>

                <div className="text-center p-4 border-bottom">
                    <div className="mb-4">
                        <img src="assets/images/users/avatar-4.jpg" className="rounded-circle avatar-lg img-thumbnail" alt=""/>
                    </div>

                    <h5 className="font-size-16 mb-1 text-truncate">Doris Brown</h5>
                    <p className="text-muted text-truncate mb-1"><i className="ri-record-circle-fill font-size-10 text-success me-1 ms-0"></i> Active</p>
                </div>
                {/* End profile user */}

                {/* Start user-profile-desc */}
                <div className="p-4 user-profile-desc" data-simplebar>
                    <div className="text-muted">
                        <p className="mb-4">If several languages coalesce, the grammar of the resulting language is more simple and regular than that of the individual.</p>
                    </div>

                    <div className="accordion" id="myprofile">
    
                        <div className="accordion-item card border mb-2">
                            <div className="accordion-header" id="about3">
                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#aboutprofile" aria-expanded="true" aria-controls="aboutprofile">
                                    <h5 className="font-size-14 m-0">
                                        <i className="ri-user-2-line me-2 ms-0 align-middle d-inline-block"></i> About
                                    </h5>
                                </button>
                            </div>
                            <div id="aboutprofile" className="accordion-collapse collapse show" aria-labelledby="about3" data-bs-parent="#myprofile">
                                <div className="accordion-body">
                                    <div>
                                        <p className="text-muted mb-1">Name</p>
                                        <h5 className="font-size-14">Doris Brown</h5>
                                    </div>

                                    <div className="mt-4">
                                        <p className="text-muted mb-1">Email</p>
                                        <h5 className="font-size-14">adc@123.com</h5>
                                    </div>

                                    <div className="mt-4">
                                        <p className="text-muted mb-1">Time</p>
                                        <h5 className="font-size-14">11:40 AM</h5>
                                    </div>

                                    <div className="mt-4">
                                        <p className="text-muted mb-1">Location</p>
                                        <h5 className="font-size-14 mb-0">California, USA</h5>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="accordion-item card border">
                            <div className="accordion-header" id="attachfile3">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#attachprofile" aria-expanded="false" aria-controls="attachprofile">
                                    <h5 className="font-size-14 m-0">
                                        <i className="ri-attachment-line me-2 ms-0 align-middle d-inline-block"></i> Attached Files
                                    </h5>
                                </button>
                            </div>
                            <div id="attachprofile" className="accordion-collapse collapse" aria-labelledby="attachfile3" data-bs-parent="#myprofile">
                                <div className="accordion-body">
                                    <div className="card p-2 border mb-2">
                                        <div className="d-flex align-items-center">
                                            <div className="avatar-sm me-3 ms-0">
                                                <div className="avatar-title bg-soft-primary text-primary rounded font-size-20">
                                                    <i className="ri-file-text-fill"></i>
                                                </div>
                                            </div>
                                            <div className="flex-grow-1">
                                                <div className="text-start">
                                                    <h5 className="font-size-14 mb-1">admin_v1.0.zip</h5>
                                                    <p className="text-muted font-size-13 mb-0">12.5 MB</p>
                                                </div>
                                            </div>

                                            <div className="ms-4 me-0">
                                                <ul className="list-inline mb-0 font-size-18">
                                                    <li className="list-inline-item">
                                                        <a href="#" className="text-muted px-1">
                                                            <i className="ri-download-2-line"></i>
                                                        </a>
                                                    </li>
                                                    <li className="list-inline-item dropdown">
                                                        <a className="dropdown-toggle text-muted px-1" href="#" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                            <i className="ri-more-fill"></i>
                                                        </a>
                                                        <div className="dropdown-menu dropdown-menu-end">
                                                            <a className="dropdown-item" href="#">Action</a>
                                                            <a className="dropdown-item" href="#">Another action</a>
                                                            <div className="dropdown-divider"></div>
                                                            <a className="dropdown-item" href="#">Delete</a>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card p-2 border mb-2">
                                        <div className="d-flex align-items-center">
                                            <div className="avatar-sm me-3 ms-0">
                                                <div className="avatar-title bg-soft-primary text-primary rounded font-size-20">
                                                    <i className="ri-image-fill"></i>
                                                </div>
                                            </div>
                                            <div className="flex-grow-1">
                                                <div className="text-start">
                                                    <h5 className="font-size-14 mb-1">Image-1.jpg</h5>
                                                    <p className="text-muted font-size-13 mb-0">4.2 MB</p>
                                                </div>
                                            </div>

                                            <div className="ms-4 me-0">
                                                <ul className="list-inline mb-0 font-size-18">
                                                    <li className="list-inline-item">
                                                        <a href="#" className="text-muted px-1">
                                                            <i className="ri-download-2-line"></i>
                                                        </a>
                                                    </li>
                                                    <li className="list-inline-item dropdown">
                                                        <a className="dropdown-toggle text-muted px-1" href="#" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                            <i className="ri-more-fill"></i>
                                                        </a>
                                                        <div className="dropdown-menu dropdown-menu-end">
                                                            <a className="dropdown-item" href="#">Action</a>
                                                            <a className="dropdown-item" href="#">Another action</a>
                                                            <div className="dropdown-divider"></div>
                                                            <a className="dropdown-item" href="#">Delete</a>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card p-2 border mb-2">
                                        <div className="d-flex align-items-center">
                                            <div className="avatar-sm me-3 ms-0">
                                                <div className="avatar-title bg-soft-primary text-primary rounded font-size-20">
                                                    <i className="ri-image-fill"></i>
                                                </div>
                                            </div>
                                            <div className="flex-grow-1">
                                                <div className="text-start">
                                                    <h5 className="font-size-14 mb-1">Image-2.jpg</h5>
                                                    <p className="text-muted font-size-13 mb-0">3.1 MB</p>
                                                </div>
                                            </div>

                                            <div className="ms-4 me-0">
                                                <ul className="list-inline mb-0 font-size-18">
                                                    <li className="list-inline-item">
                                                        <a href="#" className="text-muted px-1">
                                                            <i className="ri-download-2-line"></i>
                                                        </a>
                                                    </li>
                                                    <li className="list-inline-item dropdown">
                                                        <a className="dropdown-toggle text-muted px-1" href="#" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                            <i className="ri-more-fill"></i>
                                                        </a>
                                                        <div className="dropdown-menu dropdown-menu-end">
                                                            <a className="dropdown-item" href="#">Action</a>
                                                            <a className="dropdown-item" href="#">Another action</a>
                                                            <div className="dropdown-divider"></div>
                                                            <a className="dropdown-item" href="#">Delete</a>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card p-2 border mb-2">
                                        <div className="d-flex align-items-center">
                                            <div className="avatar-sm me-3 ms-0">
                                                <div className="avatar-title bg-soft-primary text-primary rounded font-size-20">
                                                    <i className="ri-file-text-fill"></i>
                                                </div>
                                            </div>
                                            <div className="flex-grow-1">
                                                <div className="text-start">
                                                    <h5 className="font-size-14 mb-1">Landing-A.zip</h5>
                                                    <p className="text-muted font-size-13 mb-0">6.7 MB</p>
                                                </div>
                                            </div>

                                            <div className="ms-4 me-0">
                                                <ul className="list-inline mb-0 font-size-18">
                                                    <li className="list-inline-item">
                                                        <a href="#" className="text-muted px-1">
                                                            <i className="ri-download-2-line"></i>
                                                        </a>
                                                    </li>
                                                    <li className="list-inline-item dropdown">
                                                        <a className="dropdown-toggle text-muted px-1" href="#" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                            <i className="ri-more-fill"></i>
                                                        </a>
                                                        <div className="dropdown-menu dropdown-menu-end">
                                                            <a className="dropdown-item" href="#">Action</a>
                                                            <a className="dropdown-item" href="#">Another action</a>
                                                            <div className="dropdown-divider"></div>
                                                            <a className="dropdown-item" href="#">Delete</a>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    {/* end profile-user-accordion */}
                </div>
                {/* end user-profile-desc */}
            </div>
            {/* end User profile detail sidebar */}
        </div>
    </div>
    {/* End User chat */}

    {/* audiocall Modal */}
    <div className="modal fade" id="audiocallModal" tabindex="-1" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
                <div className="modal-body">
                    <div className="text-center p-4">
                        <div className="avatar-lg mx-auto mb-4">
                            <img src="assets/images/users/avatar-4.jpg" alt="" className="img-thumbnail rounded-circle"/>
                        </div>

                        <h5 className="text-truncate">Doris Brown</h5>
                        <p className="text-muted">Start Audio Call</p>

                        <div className="mt-5">
                            <ul className="list-inline mb-1">
                                <li className="list-inline-item px-2 me-2 ms-0">
                                    <button type="button" className="btn btn-danger avatar-sm rounded-circle" data-bs-dismiss="modal">
                                        <span className="avatar-title bg-transparent font-size-20">
                                            <i className="ri-close-fill"></i>
                                        </span>
                                    </button>
                                </li>
                                <li className="list-inline-item px-2">
                                    <button type="button" className="btn btn-success avatar-sm rounded-circle">
                                        <span className="avatar-title bg-transparent font-size-20">
                                            <i className="ri-phone-fill"></i>
                                        </span>
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>                        
            </div>
        </div>
    </div>
    {/* audiocall Modal */}

    {/* videocall Modal */}
    <div className="modal fade" id="videocallModal" tabindex="-1" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
                <div className="modal-body">
                    <div className="text-center p-4">
                        <div className="avatar-lg mx-auto mb-4">
                            <img src="assets/images/users/avatar-4.jpg" alt="" className="img-thumbnail rounded-circle"/>
                        </div>

                        <h5 className="text-truncate">Doris Brown</h5>
                        <p className="text-muted mb-0">Start Video Call</p>

                        <div className="mt-5">
                            <ul className="list-inline mb-1">
                                <li className="list-inline-item px-2 me-2 ms-0">
                                    <button type="button" className="btn btn-danger avatar-sm rounded-circle" data-bs-dismiss="modal">
                                        <span className="avatar-title bg-transparent font-size-20">
                                            <i className="ri-close-fill"></i>
                                        </span>
                                    </button>
                                </li>
                                <li className="list-inline-item px-2">
                                    <button type="button" className="btn btn-success avatar-sm rounded-circle">
                                        <span className="avatar-title bg-transparent font-size-20">
                                            <i className="ri-vidicon-fill"></i>
                                        </span>
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> 
</div>
</div>
 {/* send message modal  */}
 <div class="modal fade right-side" id="newMessageModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="newMessageModalLabel" aria-hidden="true">
        <NewMessage></NewMessage>
    </div>
    </>
  </>
 
  );
}

export default Index;
