import React from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import GoodsIndexTable from "./GoodsIndexTable";

const GoodsIndex = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="card mb-4">
            <div className="card-header pb-0"> 
              <div className="d-sm-flex justify-content-between">
                <p className="table-title-cs mb-0">{t("Warenausgang")}</p>
                <div>
                  {JSON.parse(Cookies.get("permissions")).indexOf("MC") !==
                  -1 ? (
                    <a
                      data-bs-toggle="modal" data-bs-target="#staticBackdrop"
                      className="btn btn-icon btn-primary d-flex align-items-center"
                    >
                     <i className="ph ph-plus font-size-20 me-2"></i> {t("Warenausgang buchen")}
                    </a>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
            <div className="card-body pb-3 pt-0">
              <GoodsIndexTable />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default GoodsIndex;
