import { React, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Navigate, useParams } from "react-router-dom";
import { callFetch } from "../../helpers/callFetch";
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { NavLink } from 'react-router-dom'

function EmployeeEdit() {
    let params = useParams();
    const { t } = useTranslation();
    const [members, setMembers] = useState([]);
    const [selectedMembers, setSelectedMembers] = useState([]);
    const [designations, setDesignations] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [saving, setSaving] = useState(false);
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [refresh, setRefresh] = useState(0);
    const [editDelay, setEditDelay] = useState(0);
    const {
        register,
        handleSubmit,
        setError,
        setValue,
        formState: { errors },
    } = useForm();

    useEffect(() => {
        callFetch("designations", "GET", []).then((res) => {
            setDesignations(res.data);
            setEditDelay(editDelay + 1);
        });

        callFetch("departments", "GET", []).then((res) => {
            setDepartments(res.data);
            setEditDelay(editDelay + 1);
        });
    }, [refresh]);

    useEffect(() => {
        if (editDelay > 0)
            callFetch("callcenter/" + params.id + "/edit", "GET", []).then((res) => {
                setMembers(res?.members);
                for (let [key, value] of Object.entries(res.data)) {
                    setValue(key, (value == null || value == 'null' ? "" : value));
                }
                setSelectedMembers(res.data.members ? JSON.parse(res.data.members) : null);
            });
    }, [editDelay]);

    const onSubmit = (formData) => {
        setSaving(true);
        callFetch("callcenter/" + params.id, "POST", formData, setError).then((res) => {
            setSaving(false);
            if (!res.ok) return;
            setSubmitSuccess(true);
        });
    };

    return submitSuccess ? <Navigate to='/call-center' /> :
        <form className={`needs-validation ${Object.keys(errors).length ? "was-validated" : ""}`} onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
            <input type="hidden" defaultValue="PUT" {...register("_method")} />

            <div className="row justify-content-center" style={{ paddingBottom: '100px' }}>
                <div className="col-12 col-md-9">
                    <div className="card">
                        <div className="card-header">
                            <h6 className="title">{t('Edit Call Center')}</h6>
                        </div>
                        <div className="card-body pb-3">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>
                                            {t('Call Center ID')} *
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder={t('eg. 123')}
                                            {...register("identity_number", {
                                                required: true,
                                            })}
                                            required
                                            readOnly
                                        />
                                        <div className="invalid-feedback">{errors.identity_number && errors.identity_number.message}</div>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>
                                            {t('Call Center Name')} *
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder={t('eg. Jhon Doe')}
                                            {...register("name", {
                                                required: true,
                                            })}
                                            required
                                        />
                                        <div className="invalid-feedback">{errors.name && errors.name.message}</div>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>
                                            {t('Call Center Email')} *
                                        </label>
                                        <input
                                            type="email"
                                            className="form-control"
                                            placeholder={t('eg. email@mail.com')}
                                            {...register("email", {
                                                required: true,
                                            })}
                                            required
                                        />
                                        <div className="invalid-feedback">{errors.email && t(errors.email.message)}</div>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>
                                            {t('Password')} *
                                        </label>
                                        <input
                                            type="password"
                                            className="form-control"
                                            placeholder={t('eg.') + " ********"}
                                            {...register("password", {
                                                required: true,
                                            })}
                                            required
                                        />
                                        <div className="invalid-feedback">{errors.password && t(errors.password.message)}</div>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>
                                            {t('Mobile')}
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder={t('eg. 98765432')}
                                            {...register("mobile")} />
                                        <div className="invalid-feedback">{errors.mobile && errors.mobile.message}</div>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>{t('Members')}</label>
                                        <Select
                                            placeholder={t('Select')}
                                            options={members ? members : { value: '', label: '---' }}
                                            value={selectedMembers}
                                            onChange={(e) => {
                                                setSelectedMembers(e);
                                                setValue('members', JSON.stringify(e));
                                            }}
                                            isMulti
                                        />
                                        <div className="invalid-feedback">{errors.selectedMembers && errors.selectedMembers.message}</div>
                                    </div>
                                </div>

                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label>
                                            {t('Address')}
                                        </label>
                                        <textarea
                                            className="form-control"
                                            rows="4"
                                            placeholder={t('eg. 1234 My street, City')}
                                            {...register("address")}></textarea>
                                        <div className="invalid-feedback">{errors.address && errors.address.message}</div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="card fixed-bottom mb-3 fixed-bottom-width-available" >
                <div className="card-footer d-flex align-items-center justify-content-end gap-2">
                    <NavLink to="/call-center" className="btn btn-secondary m-0 d-flex align-items-center justify-content-between gap-2">
                        <i className='ph ph-caret-left'></i>
                        {t('Back')}
                    </NavLink>

                    {saving ? (
                        <button type="button" className="btn btn-disabled m-0" disabled>
                            {t('Saving ...')}
                        </button>
                    ) : (
                        <button type="submit" className="btn btn-primary m-0">
                            {t('Save')}
                        </button>
                    )}
                </div>
            </div>
        </form>;
}

export default EmployeeEdit;
