import { React, useMemo, useEffect, useState, useRef } from "react";

// @mui material components
import { Grid, Menu, MenuItem, Typography } from "@mui/material";

//import OperationalPlan from "./OperationalPlan";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import callFetch from "helpers/callFetch";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import dateFormat, { masks } from "dateformat";
import { NavLink } from "react-router-dom";
//import ProjectPlannedTechnicianLocation from "pages/FindNearest/ProjectPlannedTechnicianLocation";
import SoftSnackbar from "components/SoftSnackbar";
import DatePicker from "react-multi-date-picker";
import RoutePlane from "./RoutePlan/Index";
import Tooltip from "@mui/material/Tooltip";
import Cookies from 'js-cookie';
//import FindNearestUsersByProject from 'pages/FindNearest/UsersByProject'
import ReclamationShow from 'pages/reclamation/Show';
import DataTable from 'react-data-table-component';
import SoftSelect from "components/SoftSelect";
import Checkbox from "@mui/material/Checkbox";

// Returns the ISO week of the date.
Date.prototype.getWeek = function () {
    var date = new Date(this.getTime());
    date.setHours(0, 0, 0, 0);
    // Thursday in current week decides the year.
    date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7);
    // January 4 is always in week 1.
    var week1 = new Date(date.getFullYear(), 0, 4);
    // Adjust to Thursday in week 1 and count number of weeks from date to week1.
    return 1 + Math.round(((date.getTime() - week1.getTime()) / 86400000 - 3 + (week1.getDay() + 6) % 7) / 7);
}

function OrdersToPlan(props) {
    const { t } = useTranslation();
    const [refresh, setRefresh] = useState(0);
    const [data, setData] = useState([]);
    const [distanceDuration, setDistanceDuration] = useState({});
    const [employees, setEmployees] = useState([]);
    const [dc_technician, setDc_technician] = useState([]);
    const [ac_technician, setAc_technician] = useState([]);
    const [totalPlan, setTotalPlan] = useState(0);
    const [totalDuedPlan, setTotalDuedPlan] = useState(0);
    const [saving, setSaving] = useState(false);
    const [planSaving, setPlanSaving] = useState(false);
    const [refresh2, setRefresh2] = useState(0);
    const [refresh3, setRefresh3] = useState(0);
    const [refresh4, setRefresh4] = useState(0);
    const [planSubmitButton, setPlanSubmitButton] = useState(false);
    const [showPlanProjectRoute, setShowPlanProjectRoute] = useState(0);
    const [showPlanProjectRouteMitarbeiter, setShowPlanProjectRouteMitarbeiter] = useState(0);
    const [showPlanProjectRouteDate, setShowPlanProjectRouteDate] = useState('');
    const [showPlanProjectRouteTime, setShowPlanProjectRouteTime] = useState('');
    const [refreshNearestMitarbeiter, setRefreshNearestMitarbeiter] = useState(0);
    const [invalidScheduleProjectids, setInvalidScheduleProjectids] = useState([]);
    const [messageSb, setMessageSB] = useState(false);
    const [messageSbColor, setMessageSbColor] = useState('success');
    const [messageSbTitle, setMessageSbTitle] = useState('Something wrong!');
    const [messageSbIcon, setMessageSbIcon] = useState('check');
    const [planType, setPlanType] = useState((JSON.parse(Cookies.get('permissions')).indexOf("OnlyDCP") !== -1 ? 'dcPlan' : JSON.parse(Cookies.get('permissions')).indexOf("OnlyACP") !== -1 ? 'acPlan' : ''));
    const [planForm, setPlanForm] = useState('Project');
    const [sortBy, setSortBy] = useState('Date');
    const [showmap, setShowmap] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [absenceDate, setAbsenceDate] = useState([]);
    const [routeNumber, setRouteNumber] = useState('');
    const [routePlan, setRoutePlan] = useState([]);
    const [showRoutePlanScreen, setShowRoutePlanScreen] = useState(false);
    const [viewRelamation, setViewRelamation] = useState({});
    const [search, setSearch] = useState('');
    const [searching, setSearching] = useState(false);
    const [selectedProjects, setSelectedProjects] = useState([]);
    const [kiPlanung, setKiPlanung] = useState(false);

    const [currentStep, setCurrentStep] = useState(0);
    const steps = ["Step 1", "Step 2", "Step 3"];

    const [expandedRows, setExpandedRows] = useState({}); // Track expanded state

    const {
        register,
        handleSubmit,
        setError,
        setValue,
        getValues,
        reset,
        formState: { errors },
    } = useForm();

    const renderMessageSB = (
        <SoftSnackbar
            color={messageSbColor}
            backgroundColor={'success'}
            icon={messageSbIcon}
            title={t(messageSbTitle)}
            //content={t("Changes saved successfully")}
            dateTime={t("")}
            open={messageSb}
            onClose={() => setMessageSB(false)}
            close={() => setMessageSB(false)}
        //bgWhite={messageSbColor}
        />
    );

    const toggleRowExpand = (rowId) => {
        setExpandedRows(prev => ({
            ...prev,
            [rowId]: !prev[rowId], // Toggle row expansion
        }));
    };

    useEffect(() => {
        if (!endDate || !startDate) return;

        var routePlanData = [];
        for (let [key, value] of Object.entries(data)) {

            if (value?.plan_form == 'Project') {
                var dateRange = { start: value?.assembly_date, end: value?.assembly_date };

                if (!showRoutePlanScreen) {
                    if ((dateRange?.start >= startDate && dateRange?.start <= endDate) || (startDate >= dateRange?.start && startDate <= dateRange?.end)) {
                        routePlanData.push(value);
                        setValue("project_nr[" + value?.id + "]", true);
                        setRefresh2(refresh2 + 1);
                    } else if (kiPlanung) {
                        setValue("project_nr[" + value?.id + "]", false);
                        setRefresh2(refresh2 + 1);
                    }
                }
            }
        }

        setRoutePlan(routePlanData);

    }, [startDate, endDate, absenceDate, routeNumber]);

    useEffect(() => {

        reset({});
        setData([]);
        setSelectedProjects([]);
        callFetch("projects/orders-to-plan?planType=" + planType + "&sortBy=" + sortBy + "&search=" + search, "GET", []).then((res) => {

            if (res?.data?.data) {
                setData(res.data.data);
                setTotalDuedPlan(res.data.to);
                setTotalPlan(res.data.to);

                for (let [key, value] of Object.entries(res.data.data)) {
                    if (value.icon == 'DC') {
                        setValue("dachmontag_elektromontage[" + value.id + "]", 'Dachmontage')
                    } else if (value.icon == 'AC') {
                        setValue("dachmontag_elektromontage[" + value.id + "]", 'Elektromontage')
                    }
                    if (selectedProjects.indexOf((value.id).toString()) !== -1) {
                        setValue("project_nr[" + value.id + "]", true)
                    }
                }

                setRefresh4(refresh4);
            }
        });
    }, [refresh, planType, sortBy]);

    useEffect(() => {
        callFetch("assign-order-processing/create", "GET", []).then((res) => {
            if (planType == 'stornoPlan') {
                setEmployees(res?.data?.dc_technician);
            } else if (planType == 'dcPlan' || planType == 'dcReclamationPlan') {
                setEmployees(res?.data?.dc_technician);
            } else if (planType == 'acPlan' || planType == 'acReclamationPlan') {
                setEmployees(res?.data?.ac_technician);
            } else {
                setEmployees(res?.data?.ac_technician);
            }
        });
    }, [planType]);

    useEffect(() => {
        setTotalDuedPlan(0);
        setPlanSubmitButton(false);
        if (getValues('project_nr')) {
            var selectedids = [];
            var plan_date = [];
            var plan_time = [];
            var dachmontag_elektromontage = [];
            var plan_form = [];
            var remining = 0;

            if (searching) {
                var selectProject = selectedProjects;
            } else {
                var selectProject = [];
            }

            for (let [selectedrow_key, selectedrow_value] of Object.entries(getValues('project_nr'))) {
                if (selectedrow_value) {
                    setPlanSubmitButton(true);
                    selectedids.push(selectedrow_key);
                    plan_date.push(getValues('date[' + selectedrow_key + ']'));
                    plan_time.push(getValues('time[' + selectedrow_key + ']'));
                    dachmontag_elektromontage.push(getValues('dachmontag_elektromontage[' + selectedrow_key + ']'));
                    plan_form.push(getValues('plan_form[' + selectedrow_key + ']'));

                    selectProject.push(selectedrow_key);
                    setSelectedProjects(selectProject);
                } else {
                    remining++;
                }
            }
            setTotalDuedPlan(remining);
        }
    }, [refresh2]);

    useEffect(() => {
        setTotalDuedPlan(0);
        setPlanSubmitButton(false);
        if (getValues('project_nr')) {
            var remining = 0;
            if (searching) {
                var selectProject = selectedProjects;
            } else {
                var selectProject = [];
            }
            for (let [selectedrow_key, selectedrow_value] of Object.entries(getValues('project_nr'))) {
                if (selectedrow_value) {
                    setPlanSubmitButton(true);
                    selectProject.push(selectedrow_key);
                    setSelectedProjects(selectProject);
                } else {
                    remining++;
                }
            }
            setTotalDuedPlan(remining);
        }
    }, [refresh3, refresh4]);

    const validationSuccess = () => {
        var assigned_technician_validation_check = [];
        var invalidScheduleProjects = [];

        for (let [selectedrow_key, selectedrow_value] of Object.entries(getValues('project_nr'))) {
            if (selectedrow_value) {
                var current_plan_technician_schedule = getValues('miltarbeiter[' + selectedrow_key + ']') + '_' + getValues('date[' + selectedrow_key + ']') + '_' + getValues('time[' + selectedrow_key + ']');

                if (assigned_technician_validation_check.indexOf(current_plan_technician_schedule) !== -1) {
                    invalidScheduleProjects.push(Number(selectedrow_key));
                } else {
                    assigned_technician_validation_check.push(current_plan_technician_schedule);
                }
            }
        }

        setInvalidScheduleProjectids(invalidScheduleProjects);

        if (invalidScheduleProjects.length) {
            return false;
        } else {
            return true;
        }
    }


    const redayToplanSubmit = (ready_to_plan, plan_form, id, project_id) => {
        callFetch("ready-to-plan", "POST", {
            ready_to_plan: ready_to_plan ? 'yes' : 'no',
            plan_form: plan_form,
            id: id,
            project_id: project_id,
        }).then((res) => {

            //setRefresh(refresh + 1)
        });
    }

    const handleNext = () => {
        if (currentStep < steps.length - 1) {
            setCurrentStep(currentStep + 1);
        } else {
            alert("Form submitted!");
        }
    };

    const handlePrev = () => {
        if (currentStep > 0) {
            setCurrentStep(currentStep - 1);
        }
    };

    useEffect(() => {
        setCurrentStep(showRoutePlanScreen ? 1 : 0);
    }, [showRoutePlanScreen]);

    const onSubmit = (data) => {

    }

    let timer;
    const waitTime = 2000;

    return (
        <>

            <Grid container spacing={3}>
                <Grid item xs={12} mb={5}>
                    <div className="card">
                        <div className="card-header">
                            <h6 className="title">Plan hinzufügen</h6>
                        </div>

                        <div className="card-body">
                            <div
                                className="step-progress mb-0"
                                style={{
                                    maxWidth: '618px',
                                    margin: '0 auto'
                                }}
                            >
                                <div className="step-wrapper">
                                    <div
                                        className={`step ${currentStep >= 0 ? "completed" : ""}`}
                                        id="step-1"
                                    >
                                        {currentStep > 0 ? <i className="fa fa-check"></i> : "1"}
                                    </div>
                                    <div className={`step-line ${currentStep > 0 ? "completed" : ""}`} id="line-1"><span className={`${currentStep == 0 ? "" : "d-none-xs-cp-progress"}`}>Offene Planungen</span></div>
                                </div>

                                <div className="step-wrapper" style={{ flexGrow: '0' }}>
                                    <div
                                        className={`step ${currentStep >= 1 ? "completed" : ""}`}
                                        id="step-3"
                                    >
                                        {currentStep > 1 ? <i className="fa fa-check"></i> : "3"}
                                    </div>
                                    <div><span className={`${currentStep == 1 ? "" : "d-none-xs-cp-progress"}`}>Planung</span></div>
                                </div>
                            </div>
                        </div>

                        {showRoutePlanScreen == false && (
                            <>
                                <>
                                    {/*
                                <div className="card-body bg-secondary m-3 mb-0 border-radius">
                                    <div className="row">
                                        <div className="col-12 col-md-6">
                                            <div className="form-check form-control mt-4 ps-2">
                                                <label htmlFor="kiPlanung">KI Planung</label>
                                                <input
                                                    className="form-check-input float-end"
                                                    type="checkbox"
                                                    id="kiPlanung"
                                                    onChange={(e) => {
                                                        setKiPlanung(e.target.checked);
                                                    }}
                                                    checked={kiPlanung}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-12 col-md-6">
                                            <div className="form-group">
                                                <label>
                                                    {t('Anzahl der Routen')}
                                                </label>
                                                <select
                                                    className="form-control"
                                                    onChange={(e) => {
                                                        setRouteNumber(e.target.value);
                                                    }}
                                                >
                                                    <option value="">{t('Routenanzahl festlegen')}</option>
                                                    {[...Array(20)].map((x, index) =>
                                                        <option value={(index + 1)}>{(index + 1)}</option>
                                                    )}
                                                </select>
                                                <div className="invalid-feedback">{errors.gender && errors.gender.message}</div>
                                            </div>
                                        </div>

                                        {kiPlanung ? (
                                            <>
                                                <div className="col-12 col-md-6">
                                                    <div className="form-group d-grid">
                                                        <label>{t('Abwesenheit anlegen')}</label>
                                                        <DatePicker
                                                            inputClass="form-control flatpick"
                                                            format="DD.MM.YYYY"
                                                            multiple
                                                            //value={values}
                                                            //onChange={(array) => setValues(array)}
                                                            onChange={(e) => {
                                                                console.log(e)
                                                                setAbsenceDate(e);
                                                            }}
                                                            placeholder={t("Abwesenheit anlegen")}
                                                        />

                                                        <div className="invalid-feedback">{errors.gender && errors.gender.message}</div>
                                                    </div>
                                                </div>
                                            </>
                                        ) : ''}
                                    </div>
                                </div>
                                */}
                                </>

                                <div className="card-body bg-secondary m-3 mb-0 pb-3 border-radius">
                                    <h6 className="text-lg text-primary">Aufträge zu Planen ({totalDuedPlan})</h6>

                                    {!saving && (
                                        <>
                                            <div className="d-flex align-items-center justify-content-between gap-2">
                                                <div
                                                    className="d-flex align-items-center w-50 w-md-40 w-xl-30"
                                                >
                                                    <i
                                                        className="ph ph-magnifying-glass"
                                                        style={{
                                                            marginRight: -25,
                                                            zIndex: 1,
                                                            color: '#4D586E',
                                                            fontSize: '20px'
                                                        }}
                                                    ></i>
                                                    <input
                                                        autoComplete="off"
                                                        id="searchInput"
                                                        className="form-control header-search-field"
                                                        placeholder={t("Search...")}
                                                        value={search}
                                                        onChange={(e) => {

                                                            setSearching(true);
                                                            clearTimeout(timer);
                                                            setSearch(e.target.value);

                                                            timer = setTimeout(() => {
                                                                setRefresh(refresh + 1);
                                                            }, waitTime);
                                                        }}
                                                    />
                                                </div>

                                                <div className="d-flex align-items-center justify-content-between gap-2 w-50 w-md-60 w-xl-40">
                                                    <SoftSelect
                                                        placeholder={t('Sortieren nach')}
                                                        options={[
                                                            { label: t('Sortieren nach'), value: '' },
                                                            { label: t('Datum'), value: 'Date' },
                                                            { label: t('Distanz'), value: 'Distance' },
                                                            { label: t('Datum & Distanz'), value: 'Date_Distance' },
                                                        ]}
                                                        value={
                                                            sortBy
                                                                ? { label: t(sortBy === 'Date' ? 'Datum' : sortBy === 'Distance' ? 'Distanz' : 'Datum & Distanz'), value: sortBy }
                                                                : { label: t('Sortieren nach'), value: '' }
                                                        }
                                                        onChange={(selected) => {
                                                            setSortBy(selected.value);
                                                        }}
                                                        isDisabled={showRoutePlanScreen}
                                                    />

                                                    <SoftSelect
                                                        placeholder="Select Plan"
                                                        options={[
                                                            { label: "All", value: "all" },
                                                            ...(JSON.parse(Cookies.get("permissions")).includes("OnlyDCP")
                                                                ? [
                                                                    { label: "Storno", value: "stornoPlan" },
                                                                    { label: "DC Plan", value: "dcPlan" },
                                                                    { label: "DC Reclamation Plan", value: "dcReclamationPlan" },
                                                                ]
                                                                : []),
                                                            ...(JSON.parse(Cookies.get("permissions")).includes("OnlyACP")
                                                                ? [
                                                                    { label: "AC Plan", value: "acPlan" },
                                                                    { label: "AC Reclamation Plan", value: "acReclamationPlan" },
                                                                    { label: "AC Fertigmeldung", value: "ACFertigmeldung" },
                                                                ]
                                                                : []),
                                                        ]}
                                                        value={
                                                            planType
                                                                ? {
                                                                    label:
                                                                        planType === "all"
                                                                            ? "All"
                                                                            : planType === "stornoPlan"
                                                                                ? "Storno"
                                                                                : planType === "dcPlan"
                                                                                    ? "DC Plan"
                                                                                    : planType === "dcReclamationPlan"
                                                                                        ? "DC Reclamation Plan"
                                                                                        : planType === "acPlan"
                                                                                            ? "AC Plan"
                                                                                            : planType === "acReclamationPlan"
                                                                                                ? "AC Reclamation Plan"
                                                                                                : "AC Fertigmeldung",
                                                                    value: planType,
                                                                }
                                                                : { label: "Select Plan", value: "" }
                                                        }
                                                        onChange={(selected) => {
                                                            setSelectedProjects([]);
                                                            setSearch("");
                                                            setSearching(false);
                                                            setPlanType(selected.value);
                                                            setDistanceDuration({});
                                                        }}
                                                        isDisabled={showRoutePlanScreen}
                                                    />
                                                </div>
                                            </div>
                                        </>
                                    )}

                                    <form className={`needs-validation ${Object.keys(errors).length ? "was-validated" : ""}`} onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
                                        <DataTable
                                            persistTableHead={true}
                                            expandableRows
                                            expandableRowsHideExpander={true} //Hide default expand icon
                                            expandableRowExpanded={(row) => expandedRows[row.id] || false} // Control expansion state
                                            expandableRowsComponent={({ data: item }) =>
                                                <>
                                                    <div className="p-3 m-3 bg-secondary border-radius col-12 col-lg-7">
                                                        {item?.plan_form == 'Reclamation' ? (
                                                            <>
                                                                {item?.products ? JSON.parse(item?.products).map((product, product_index) => (
                                                                    <>
                                                                        <div className="d-flex align-items-center justify-content-between gap-2 mt-2">
                                                                            <div className="d-flex align-items-center justify-content-between gap-2 ">
                                                                                <p className="text-link text-secondary">
                                                                                    {product?.title}
                                                                                </p>
                                                                            </div>
                                                                            <p className="text-link text-primary">{product?.quantity + ' Stück'}</p>
                                                                        </div>
                                                                    </>
                                                                )) : ''}

                                                                <div className="d-flex align-items-center justify-content-between gap-2 mt-2">
                                                                    <div className="d-flex align-items-center justify-content-between gap-2 ">
                                                                        <p className="text-link text-secondary">
                                                                            {t('Dachtyp')}
                                                                        </p>
                                                                    </div>
                                                                    <p className="text-link text-primary">{item?.dachtyp}</p>
                                                                </div>

                                                                <div className="d-flex align-items-center justify-content-between gap-2 mt-2">
                                                                    <div className="d-flex align-items-center justify-content-between gap-2 ">
                                                                        <p className="text-link text-secondary">
                                                                            {t('Vorh. Techniker')}
                                                                        </p>
                                                                    </div>
                                                                    <p className="text-link text-primary">{item?.prevelektromontage?.employee?.name ? item?.prevelektromontage?.employee?.name : item?.prevdachmontag?.employee?.name ? item?.prevdachmontag?.employee?.name : '--'}</p>
                                                                </div>

                                                                <div className="d-flex align-items-center justify-content-between gap-2 mt-2">
                                                                    <div className="d-flex align-items-center justify-content-between gap-2 ">
                                                                        <p className="text-link text-secondary">
                                                                            {t('Project')}
                                                                        </p>
                                                                    </div>
                                                                    <p className="text-link text-primary">{item?.project_nr}</p>
                                                                </div>
                                                            </>
                                                        ) : (
                                                            <>
                                                                {item?.newQuation === 'yes' ? (
                                                                    <>
                                                                        {item?.productItems && item?.productItems != 'null' ? JSON.parse(item?.productItems)?.items?.map((productItem, j) => (
                                                                            <>
                                                                                {productItem?.type == 'bundle' && productItem.item.id ? (
                                                                                    <>
                                                                                        <p
                                                                                            style={{
                                                                                                fontSize: '0.875rem',
                                                                                                fontWeight: 'bold',
                                                                                                color: '#2D3748'
                                                                                            }}
                                                                                            className="m-0 p-0 mt-0"
                                                                                        >{productItem.item?.label}</p>
                                                                                        {productItem.item?.items ? productItem.item?.items.map((item, item_index) => (
                                                                                            <>
                                                                                                <div className="d-flex align-items-center justify-content-between gap-2 mt-2">
                                                                                                    <div className="d-flex align-items-center justify-content-between gap-2 ">
                                                                                                        <p className="text-link text-secondary">
                                                                                                            {item[item?.type]?.name}
                                                                                                        </p>

                                                                                                        <Tooltip
                                                                                                            title={item[item?.type]?.lieferdatum ? ('PO-' + item[item?.type]?.po_number + ': ' + item[item?.type]?.lieferdatum) : ''}
                                                                                                            placement="bottom"
                                                                                                        >
                                                                                                            <p className={`badge ${item[item?.type]?.lieferdatum ? 'badge-success-light' : 'badge-secondary text-primary text-sm '} m-0`}>
                                                                                                                <i className="ph ph-truck me-1"></i>
                                                                                                                {item[item?.type]?.lieferdatum ? `Lieferdaturm: ${item[item?.type]?.lieferdatum}` : 'Nicht vorhanden'}
                                                                                                            </p>
                                                                                                        </Tooltip>
                                                                                                    </div>
                                                                                                    <p className="text-link text-primary">{item?.number + ' Stück'}</p>
                                                                                                </div>
                                                                                            </>
                                                                                        )) : ''}
                                                                                    </>
                                                                                ) : productItem?.type == 'product' ? (
                                                                                    <>
                                                                                        <div className="d-flex align-items-center justify-content-between gap-2 mt-2">
                                                                                            <div className="d-flex align-items-center justify-content-between gap-2 ">
                                                                                                <p className="text-link text-secondary">
                                                                                                    {productItem?.item?.label}
                                                                                                </p>

                                                                                                <Tooltip
                                                                                                    title={productItem?.item?.lieferdatum ? ('PO-' + productItem?.item?.po_number + ': ' + productItem?.item?.lieferdatum) : ''}
                                                                                                    placement="bottom"
                                                                                                >
                                                                                                    <p className={`badge ${productItem?.item?.lieferdatum ? 'badge-success-light' : 'badge-secondary text-primary text-sm '} m-0`}>
                                                                                                        <i className="ph ph-truck me-1"></i>
                                                                                                        {productItem?.item?.lieferdatum ? `Lieferdaturm: ${productItem?.item?.lieferdatum}` : 'Nicht vorhanden'}
                                                                                                    </p>
                                                                                                </Tooltip>
                                                                                            </div>
                                                                                            <p className="text-link text-primary">{productItem?.quantity + ' Stück'}</p>
                                                                                        </div>
                                                                                    </>
                                                                                ) : (
                                                                                    <></>
                                                                                )}
                                                                            </>
                                                                        )) : ''}
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <SoftTypography className="p-0" style={{ fontSize: '0.75rem' }} variant="caption"><span >{t('Modul')}</span>: <strong>{item?.anzahl_module + ' Stck. | ' + (item?.module_eingeben == 'no' ? item?.module_short_title : item?.custom_module_short_title)}</strong></SoftTypography><br />
                                                                        <SoftTypography className="p-0" style={{ fontSize: '0.75rem' }} variant="caption"><span >{t('Speicher')}</span>: <strong>{item?.speicher_eingeben == 'no' ? item?.speicher_name : item?.custom_speicher_name}</strong></SoftTypography><br />
                                                                    </>
                                                                )}

                                                                <div className="d-flex align-items-center justify-content-start gap-2 mt-2">
                                                                    <p className="text-link text-secondary m-0">{t('Dachtyp')}:</p>
                                                                    <p className="text-link text-primary m-0">{item?.dachtyp}</p>
                                                                </div>

                                                                {item?.plan_form == 'ACFertigmeldung' && (
                                                                    <div className="d-flex align-items-center justify-content-start gap-2 mt-2">
                                                                        <p className="text-link text-secondary m-0">{t('Vorh. Techniker')}:</p>
                                                                        <p className="text-link text-primary m-0">{item?.prevelektromontage?.employee?.name ? item?.prevelektromontage?.employee?.name : item?.prevdachmontag?.employee?.name ? item?.prevdachmontag?.employee?.name : '--'}</p>
                                                                    </div>
                                                                )}
                                                            </>
                                                        )}
                                                    </div>
                                                </>
                                            }
                                            columns={[
                                                {
                                                    name: <>
                                                        {/*Check all*/}
                                                        <Checkbox
                                                            defaultChecked={false}
                                                            onChange={(e) => {
                                                                const isChecked = e.target.checked;

                                                                // Update selectedProjects based on the "Select All" checkbox status
                                                                if (isChecked) {
                                                                    const allSelectedProjects = data.map((item) => item.id.toString());
                                                                    setSelectedProjects(allSelectedProjects); // Select all projects
                                                                } else {
                                                                    setSelectedProjects([]); // Unselect all projects
                                                                }

                                                                for (let [selectedrow_key, selectedrow_value] of Object.entries(getValues('project_nr'))) {
                                                                    setValue("project_nr[" + selectedrow_key + "]", e.target.checked);
                                                                    setRefresh2(refresh2 + 1);
                                                                }

                                                                setRefresh2(refresh2 + 1);
                                                            }}
                                                        />
                                                    </>,
                                                    width: '44px',
                                                    cell: item => <>
                                                        {/*individual*/}
                                                        <Checkbox
                                                            checked={selectedProjects.includes((item?.id).toString())}
                                                            onChange={(e) => {
                                                                setValue("project_nr[" + item?.id + "]", e.target.checked)


                                                                if (e.target.checked == false) {
                                                                    var selectProject = selectedProjects;
                                                                    selectProject = selectProject.filter(val => val !== ((item?.id).toString()));
                                                                    setSelectedProjects(selectProject);
                                                                }

                                                                setRefresh3(refresh3 + 1);
                                                                validationSuccess();
                                                            }}
                                                        />

                                                        <input
                                                            type="hidden"
                                                            {...register("project_nr[" + item.id + "]", {
                                                                required: false,
                                                            })}
                                                        />
                                                    </>
                                                },
                                                {
                                                    name: "",
                                                    cell: (row) => <>
                                                        <i
                                                            onClick={() => toggleRowExpand(row.id)}
                                                            style={{
                                                                fontSize: '20px',
                                                                cursor: 'pointer'
                                                            }}
                                                            className={expandedRows[row.id] ? "ph ph-caret-down" : "ph ph-caret-right"}
                                                        ></i>
                                                    </>,
                                                    width: "44px",
                                                    expander: true,
                                                },
                                                {
                                                    name: t('Projekt'),
                                                    width: '132px',
                                                    selector: item => <>
                                                        {/*item?.id && <NavLink to={'/projects/' + item.id} className="text-brand-primary text-link">{item.id}</NavLink>*/}


                                                        {item?.plan_form == 'Reclamation' ? (
                                                            <a onClick={() => {
                                                                setViewRelamation({
                                                                    id: item?.id,
                                                                    projectId: item?.project_nr
                                                                })
                                                            }}
                                                                data-bs-toggle="modal"
                                                                data-bs-target="#reclimationView"
                                                                className="text-brand-primary text-link"
                                                            >
                                                                {'R-' + item.id}
                                                            </a>
                                                        ) : (
                                                            <>
                                                                <NavLink to={'/projects/' + (item?.plan_form == 'ACFertigmeldung' ? item.project_id : item.id)} className="text-brand-primary text-link">{(item?.plan_form == 'ACFertigmeldung' ? item.project_id : item.id)}</NavLink>
                                                            </>
                                                        )}


                                                        <input
                                                            type="hidden"
                                                            {...register("plan_form[" + item.id + "]", {
                                                                required: true,
                                                            })}
                                                            value={item?.plan_form}
                                                        />
                                                    </>,
                                                    sortable: false,
                                                },
                                                {
                                                    name: t('Adresse'),
                                                    // width: '132px',
                                                    cell: item => <>
                                                        <p className="text-link text-primary text-overflow-ellipsis">{item?.street + ' ' + item?.nr + ', ' + item?.plz_ort}</p>
                                                    </>,
                                                    sortable: false,
                                                },
                                                {
                                                    name: t('Wunschtermin'),
                                                    // width: '140px',
                                                    cell: item => <>{item.assembly_date ? dateFormat(item?.assembly_date, "dd.mm.yyyy") : '--'}</>,
                                                    sortable: false,
                                                },
                                                {
                                                    name: t('Kunde'),
                                                    //width: '140px',
                                                    cell: item => <>{item?.name}</>,
                                                    sortable: false,
                                                },
                                                {
                                                    name: t('Bereit zur Planung'),
                                                    width: '86px',
                                                    cell: (item, index) => <>
                                                        <div class="form-check form-switch float-end">
                                                            <input
                                                                class="form-check-input"
                                                                type="checkbox"
                                                                onChange={(e) => {
                                                                    redayToplanSubmit(e.target.checked, item?.plan_form, item?.id, (item?.plan_form == 'Reclamation' ? item?.project_nr : item?.plan_form == 'ACFertigmeldung' ? item.project_id : item.id));
                                                                    var updateData = data;
                                                                    updateData[index].ready_to_plan = e.target.checked ? 'yes' : 'no';
                                                                    setData(updateData);
                                                                }}
                                                                defaultChecked={item?.ready_to_plan === 'yes' ? true : false}
                                                            />
                                                        </div>
                                                    </>,
                                                    sortable: false,
                                                },
                                                {
                                                    name: t('Days'),
                                                    width: '140px',
                                                    cell: item => <>
                                                        <input type="hidden"
                                                            defaultValue={item?.plan_hours ? item?.plan_hours : item?.plan_form == 'Reclamation' ? '3' : item?.anzahl_module <= 22 ? '12' : '24'}
                                                            {...register("hours[" + item?.id + "]", {
                                                                required: false,
                                                            })}
                                                        />
                                                        <SoftSelect
                                                            size="small"
                                                            placeholder="Select Hours"
                                                            options={[
                                                                { label: "3 Stunden", value: "3" },
                                                                { label: "6 Stunden", value: "6" },
                                                                { label: "9 Stunden", value: "9" },
                                                                { label: "1 Tage", value: "12" },
                                                                { label: "2 Tage", value: "24" },
                                                            ]}
                                                            value={{
                                                                label:
                                                                    item?.plan_hours
                                                                        ? Number(item?.plan_hours) === 12
                                                                            ? "1 Tage"
                                                                            : Number(item?.plan_hours) === 24
                                                                                ? "2 Tage"
                                                                                : `${item?.plan_hours} Stunden`
                                                                        : item?.plan_form === "Reclamation"
                                                                            ? "3 Stunden"
                                                                            : item?.anzahl_module <= 22
                                                                                ? "1 Tage"
                                                                                : "2 Tage",
                                                                value: String(
                                                                    item?.plan_hours ??
                                                                    (item?.plan_form === "Reclamation"
                                                                        ? 3
                                                                        : item?.anzahl_module <= 22
                                                                            ? 12
                                                                            : 24)
                                                                ),
                                                            }}
                                                            onChange={(selected) => {
                                                                var formData = {
                                                                    related: item?.plan_form,
                                                                    plan_type: item?.icon,
                                                                    related_id: item?.id,
                                                                    hours: selected.value,
                                                                };

                                                                callFetch("plan-cache", "POST", formData, setError).then((res) => { });

                                                                // Use setValue to update the form field in react-hook-form
                                                                setValue(`hours[${item?.id}]`, selected.value);

                                                                setData(prevData =>
                                                                    prevData.map(row =>
                                                                        row.id === item.id
                                                                            ? { ...row, plan_hours: selected.value }
                                                                            : row
                                                                    )
                                                                );
                                                            }}
                                                            menuPortalTarget={document.body}
                                                            menuPlacement="auto"
                                                        />
                                                        {/*
                                                        <select
                                                            className="form-control"
                                                            defaultValue={item?.plan_hours ? item?.plan_hours : item?.plan_form == 'Reclamation' ? '3' : item?.anzahl_module <= 22 ? '12' : '24'}
                                                            {...register("hours[" + item?.id + "]", {
                                                                required: false,
                                                            })}
                                                            style={{
                                                                height: '22px',
                                                                paddingBottom: '0px',
                                                                paddingTop: '0px',
                                                                fontSize: '11px'
                                                            }}
                                                            onChange={(e) => {
                                                                console.log(e.target.value)

                                                                var formData = {};
                                                                formData.related = item?.plan_form;
                                                                formData.plan_type = item?.icon;
                                                                formData.related_id = item?.id;
                                                                formData.hours = e.target.value;

                                                                callFetch("plan-cache", "POST", formData, setError).then((res) => {

                                                                });
                                                            }}
                                                        >
                                                            <option value={3}>3 Stunden</option>
                                                            <option value={6}>6 Stunden</option>
                                                            <option value={9}>9 Stunden</option>
                                                            <option value={12}>1 Tage</option>
                                                            <option value={24}>2 Tage</option>
                                                        </select>
                                                        */}
                                                    </>,
                                                    sortable: false,
                                                },
                                                {
                                                    name: t(''),
                                                    width: '44px',
                                                    cell: item => <>
                                                        <div className="text-center dropstart">
                                                            <a href="/" className="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                                                <i className="fa fa-ellipsis-v text-xs"></i>
                                                            </a>
                                                            <ul className="dropdown-menu">
                                                                <li>Example 1</li>
                                                                <li><hr className="dropdown-divider" /></li>
                                                                <li>Example 2</li>
                                                            </ul>
                                                        </div>
                                                    </>,
                                                    sortable: false,
                                                }
                                            ]}
                                            noDataComponent={
                                                <div className="d-flex align-items-center justify-content-center py-3" style={{ height: "100%" }}>
                                                    <div className="text-center">
                                                        <i className='ph ph-notepad data-table-no-record-icon'></i>
                                                        <p className="text-secondary text-md m-0">{t('There are no records to display')}</p>
                                                    </div>
                                                </div>
                                            }
                                            data={data}
                                            className="py-3"
                                        />
                                    </form>
                                </div>
                            </>
                        )}

                        {getValues('project_nr')}

                        {showRoutePlanScreen && (
                            <RoutePlane
                                employees={employees}
                                projectIds={getValues('project_nr')}
                                //hours={getValues('hours')}
                                planType={planType}
                                plan_form={getValues('plan_form')}
                                startDate={startDate}
                                endDate={endDate}
                                routeNumber={routeNumber}
                                /*routePlan={routePlan}*/
                                absenceDate={absenceDate}
                                refresh={setRefresh}
                                setRefresh={props?.setRefresh}
                                setShowRoutePlanScreen={setShowRoutePlanScreen}
                                setPlanSaving={setPlanSaving}
                                allData={data}
                            />
                        )}

                        <div
                            className="card-footer py-2 card-shadow d-flex align-items-center justify-content-end gap-2">

                            {showRoutePlanScreen ? (
                                <>
                                    <button
                                        type="button"
                                        className="btn btn-secondary m-0 d-flex align-items-center justify-content-end gap-2"
                                        onClick={() => {
                                            setShowRoutePlanScreen(false);
                                            handlePrev()
                                        }}
                                    >
                                        <i className="ph ph-caret-left"></i>
                                        {t('Back')}
                                    </button>

                                    {!planSaving ? (
                                        <button
                                            type="button"
                                            className="btn btn-primary m-0"
                                            onClick={() => {
                                                document.getElementById('routePlanSave').click();
                                            }}
                                        >
                                            {t('Save')}
                                        </button>
                                    ) : (
                                        <button type="button" className="btn btn-disabled m-0" disabled>
                                            {t('Saving ...')}
                                        </button>
                                    )}
                                </>
                            ) : (<>
                                <button
                                    type="button"
                                    className="btn btn-primary m-0 d-flex align-items-center justify-content-end gap-2"
                                    onClick={() => {
                                        if (!selectedProjects?.length) return;
                                        setShowRoutePlanScreen(true);
                                        handleNext()
                                    }}
                                    disabled={!selectedProjects?.length ? true : false}
                                >
                                    {t('Next')}
                                    <i className="ph ph-caret-right"></i>
                                </button>
                            </>)}
                        </div>
                    </div >
                </Grid >
            </Grid >


            <div
                className="modal fade"
                id="reclimationView"
                tabindex="-1"
                role="dialog"
                aria-labelledby="reclimationViewLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered modal-xl p-0" role="document">
                    <div className="modal-content">
                        <div className="modal-body p-0">
                            <ReclamationShow viewRelamation={viewRelamation} fullwidth={true} />
                        </div>
                    </div>
                </div>
            </div>

            <button className="d-none" id="planHinzufugenRefresh" onClick={() => { setRefresh(refresh + 1) }}>Refresh</button>

            {renderMessageSB}
        </>
    );
}

export default OrdersToPlan;
