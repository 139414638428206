import { React, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Bar } from 'react-chartjs-2';
import { useForm } from "react-hook-form";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip } from 'chart.js';
import callFetch from "../../../../../../helpers/callFetch";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip);

const EstimatedProductionTwo = (props) => {
  let params = useParams();

  const [allDatasets, setAllDatasets] = useState([]);
  const [labels, setLabels] = useState([]);
  const [visibleDatasets, setVisibleDatasets] = useState({
    vor_solar: true,
    mit_solar: true,
    solar_und_speicher: true,
  });

  const { register, setValue } = useForm();
  const [kwhPreis, setKwhPreis] = useState(0);
  const [refresh, setRefresh] = useState(0);

  const updateKwhPreis = (preis) => {
    var formData = new FormData();
    formData.preis = preis;
    formData.id = props?.projectid ? props?.projectid : params.id;
    callFetch("update-kwh-preis", "POST", formData).then(() => {
      setRefresh(refresh + 1);
    });
  };

  useEffect(() => {
    if(!props?.isOpen) return;
    callFetch("estimated-production-consumption/" + (props?.projectid ? props?.projectid : params.id), "GET", []).then((res) => {
      const resData = res?.response_data_two;
      setKwhPreis(res?.kwh_preis);
      setValue('kwh_preis', res?.kwh_preis);

      const datasets = [
        {
          label: 'Vor Solar',
          key: 'vor_solar',
          data: resData.vor_solar,
          backgroundColor: '#FF465B', // Green
          borderRadius: 4,
          borderSkipped: false,
          maxBarThickness: 8,
        },
        {
          label: 'Mit Solar',
          key: 'mit_solar',
          data: resData.mit_solar,
          backgroundColor: '#48BB78', // Blue
          borderRadius: 4,
          borderSkipped: false,
          maxBarThickness: 8,
        },
        {
          label: 'Solar und Speicher',
          key: 'solar_und_speicher',
          data: resData.solar_und_speicher,
          backgroundColor: '#208DFC', // Red
          borderRadius: 4,
          borderSkipped: false,
          maxBarThickness: 8,
        },
      ];

      setAllDatasets(datasets);
      setLabels([
        'Januar', 'Februar', 'März', 'April', 'Mai', 'Juni',
        'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember',
      ]);
    }).catch(error => {
      console.error('Error fetching data:', error);
    });
  }, [props?.projectid, params.id, refresh, props?.isOpen, props?.value]);

  // Toggle dataset visibility
  const handleCheckboxChange = (datasetKey) => {
    const updatedVisibility = {
      ...visibleDatasets,
      [datasetKey]: !visibleDatasets[datasetKey],
    };

    // Ensure at least one dataset is always visible
    if (Object.values(updatedVisibility).some(value => value)) {
      setVisibleDatasets(updatedVisibility);
    }
  };

  // Generate dataset list based on visibility state
  const filteredDatasets = allDatasets.map(dataset => ({
    ...dataset,
    hidden: !visibleDatasets[dataset.key],
  }));

  // Chart options
  const options = {
    responsive: true,
    plugins: {
      legend: { display: false }, // Hide default Chart.js legend
    },
    scales: {
      x: {
        categoryPercentage: 0.6,
        barPercentage: 0.6,
        grid: {
          drawBorder: false,
          display: true,
          drawOnChartArea: true,
          drawTicks: false,
          borderDash: [5, 5],
        },
      },
      y: {
        beginAtZero: true,
        grid: {
          drawBorder: false,
          display: true,
          drawOnChartArea: true,
          drawTicks: false,
          borderDash: [5, 5],
        },
      },
    },
  };

  return (
    <div style={{ position: "relative" }}>
      {/* Title & Input Field */}
      <div className="d-flex justify-content-between align-items-center">
        <p className="text-primary font-weight-600 font-size-16 mb-2">Production and Consumption Data</p>
        <div>
          <input
            type="number"
            className="form-control form-control-sm d-inline-block"
            placeholder="Enter kWh Preis"
            defaultValue={kwhPreis}
            {...register('kwh_preis')}
            onChange={(e) => updateKwhPreis(e.target.value)}
            style={{ marginLeft: "10px", width: "150px" }}
          />
        </div>
      </div>

      {/* Bootstrap Checkbox Controls */}
      <div className="d-flex gap-3 mb-3">
        {/* Vor Solar Checkbox */}
        <div className="form-check d-flex align-items-center">
          <input
            className="form-check-input produktion"
            type="checkbox"
            checked={visibleDatasets.vor_solar}
            onChange={() => handleCheckboxChange("vor_solar")}
            style={{
              appearance: "none",
              width: "18px",
              height: "18px",
              borderRadius: "4px", 
              backgroundColor: visibleDatasets.vor_solar ? "#48BB78" : "transparent",
              cursor: "pointer",
            }}
          />
          <label className="form-check-label ms-2 text-primary position-relative top-2">
            Vor Solar
          </label>
        </div>

        {/* Mit Solar Checkbox */}
        <div className="form-check d-flex align-items-center">
          <input
            className="form-check-input verbrauch"
            type="checkbox"
            checked={visibleDatasets.mit_solar}
            onChange={() => handleCheckboxChange("mit_solar")}
            style={{
              appearance: "none",
              width: "18px",
              height: "18px",
              borderRadius: "4px", 
              backgroundColor: visibleDatasets.mit_solar ? "#208DFC" : "transparent",
              cursor: "pointer",
            }}
          />
          <label className="form-check-label ms-2 text-primary position-relative top-2">
            Mit Solar
          </label>
        </div>

        {/* Solar und Speicher Checkbox */}
        <div className="form-check d-flex align-items-center">
          <input
            className="form-check-input eigenverbrauch"
            type="checkbox"
            checked={visibleDatasets.solar_und_speicher}
            onChange={() => handleCheckboxChange("solar_und_speicher")}
            style={{
              appearance: "none",
              width: "18px",
              height: "18px",
              borderRadius: "4px", 
              backgroundColor: visibleDatasets.solar_und_speicher ? "#E53E3E" : "transparent",
              cursor: "pointer",
            }}
          />
          <label className="form-check-label ms-2 text-primary position-relative top-2">
            Solar und Speicher
          </label>
        </div>
      </div>

      {/* Bar Chart */}
      <Bar data={{ labels, datasets: filteredDatasets }} options={options} />
    </div>
  );
};

export default EstimatedProductionTwo;
 