import { Card, Grid } from '@mui/material'
import SoftBox from 'components/SoftBox'
import SoftTypography from 'components/SoftTypography'
import callFetch from 'helpers/callFetch';
import Cookies from 'js-cookie';
import React, { useState, useRef, useEffect } from 'react'
import { useForm } from "react-hook-form";
import { useNavigate, Link, useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import SignaturePad from 'react-signature-pad-wrapper';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Timeline from "../../create/Timeline";
import DropZoneTabs from 'components/DropZoneTabs/Index';
import Overview from '../../Overview';
import ACabnahmeSign from '../ACabnahmeSign';
import ComisioningModal from "../ComisioningModal";
import SoftEditor from "components/SoftEditor";

const ACAbnahmeprotokoll = (props) => {
    const { t } = useTranslation();
    const [saving, setSaving] = useState(false);
    const notify = () => toast("Success");
    const signaturePadRef = useRef(null);
    const [link, setLink] = useState(null)
    const [modules, setModules] = useState([]);
    const [id, setID] = useState(0);
    const [tabFiles, setTabFiles] = useState({});
    const params = useParams();
    const [strgItemRefresh, setStrgItemRefresh] = useState(0);
    const [refresh, setRefresh] = useState(0);
    const [description, setDescription] = useState("");
    const [descriptionDokumentation, setDescriptionDokumentation] = useState("");
    const [descriptionKundeneinweisung, setDescriptionKundeneinweisung] = useState("");
    const [descriptionMangel, setDescriptionMangel] = useState("");
    const [descriptionPlausibilitatskontrolle, setDescriptionPlausibilitatskontrolle] = useState("");
    const [descriptionSichtprufung, setDescriptionSichtprufung] = useState("");
    const [message, setMessage] = useState('Success');
    const [color, setColor] = useState('success');
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [data, setData] = useState([]);
    const [successSB, setSuccessSB] = useState(false);
    const [strgItem, setStrgItem] = useState({ strg_1: '' });
    const [voltItem, setVoltItem] = useState({ strg_1: '' });

    const [dropZoneTabs, setDropZoneTabs] = useState([
        {
            name: 'wechselrichter_und_umgebung',
            titel: 'Wechselrichter und Umgebung',
            require: true
        }, {
            name: 'anschlussbereich_wechselrichter',
            titel: 'Anschlussbereich Wechselrichter',
            require: true
        }, {
            name: 'installierte_batterien_und_umgebung',
            titel: 'Installierte Batterien und Umgebung',
            require: true
        }, {
            name: 'zahlerschrank_geoffneter_zustand',
            titel: 'Zählerschrank (geöffneter Zustand)',
            require: true
        }, {
            name: 'verbraucher_sls_schalter_nh_sicherung',
            titel: 'Verbraucher SLS Schalter (NH Sicherung)',
            require: true
        }, {
            name: 'ac_uberspannungsschutz_ac_uss',
            titel: 'AC Überspannungsschutz 1&2 (AC USS)',
            require: true
        }, {
            name: 'leitungsschutz_des_wechselrichter',
            titel: 'Leitungsschutz des Wechselrichter',
            require: true
        }, {
            name: 'smart_meter',
            titel: 'Smart Meter',
            require: true
        }, {
            name: 'pv_Anlage_zahlerschrank',
            titel: 'PV Anlage Zählerschrank',
            require: true
        },
        {
            name: 'werte',
            titel: 'Eingestellte Werte',
            require: true
        },
        {
            name: 'foto_chos_phi',
            titel: 'Foto Chos Phi / Länderspezifische Einstellung',
            require: true
        },
        {
            name: 'foto_einsp',
            titel: 'Foto Einspeiserregelung',
            require: true
        },
        {
            name: 'foto_zahlerstand',
            titel: 'Foto Zählerstand zum Zeitpunkt der Anlagenerrichtung',
            require: true
        },
        {
            name: 'foto_von_der',
            titel: 'Foto von der laufenden Anlage',
            require: true
        },
        {
            name: 'zahlerschrank_mit',
            titel: 'Zählerschrank mit Berührungsschutz',
            require: true
        },
        {
            name: 'zahlerschrank_ohne',
            titel: 'Zählerschrank ohne Berührungsschutz',
            require: true
        },
    ]);
    const [dropZonValidationError, setDropZonValidationError] = useState('');

    const openSuccessSB = () => setSuccessSB(true);
    const {
        register,
        handleSubmit,
        setError,
        setValue,
        getValues,
        reset,
        formState: { errors },
    } = useForm();

    useEffect(() => {
        callFetch("module/all", "GET", []).then((res) => {
            setModules(res.data);
        });
    }, [0]);

    useEffect(() => {
        if (props.project_id !== undefined) {
            callFetch("projectdistribution/" + props.project_id + "/edit?project_id=" + props.project_id, "GET", []).then((res) => {
                setValue('modultyp', res?.data?.quations?.modul);
                setValue('modulanzahl', res?.data?.quations?.anzahl_module);
                //setValue('wechselrichter', res?.data?.quations?.anzahl_module);

                if (res?.data?.quations?.wechselrichter_eingeben == 'no') {
                    setValue('wechselrichter', res?.data?.quations?.wechselrichter?.name);
                } else {
                    setValue('wechselrichter', res?.data?.quations?.wechselrichter_titel);
                }
            });

            callFetch("ac_abnahmeprotokoll/" + props.project_id + "/edit", "GET", []).then((res) => {

                //setStrgItem();

                if (res?.data?.strg) {
                    setStrgItem(JSON.parse(res?.data?.strg));
                    setVoltItem(JSON.parse(res?.data?.volt));
                }

                if (res?.data?.dcabname) {
                    var payload = res?.data?.dcabname?.payload ? (typeof res?.data?.dcabname?.payload === 'string' ? JSON.parse(res?.data?.dcabname?.payload) : res?.data?.dcabname?.payload) : {};

                    setTabFiles(JSON.parse(res?.data?.dcabname?.filesdata));
                    setID(res?.data?.dcabname?.id);
                    setDescription(res?.data?.dcabname.description && res?.data?.dcabname.description != 'null' ? res?.data?.dcabname.description : '')
                    setDescriptionDokumentation(res?.data?.dcabname.dokumentation_description && res?.data?.dcabname.dokumentation_description != 'null' ? res?.data?.dcabname.dokumentation_description : '')
                    setDescriptionKundeneinweisung(res?.data?.dcabname.kundeneinweisung_description && res?.data?.dcabname.kundeneinweisung_description != 'null' ? res?.data?.dcabname.kundeneinweisung_description : '')
                    setDescriptionMangel(res?.data?.dcabname.description_mangel && res?.data?.dcabname.description_mangel != 'null' ? res?.data?.dcabname.description_mangel : '')
                    setDescriptionPlausibilitatskontrolle(res?.data?.dcabname.plausibilitatskontrolle_description && res?.data?.dcabname.plausibilitatskontrolle_description != 'null' ? res?.data?.dcabname.plausibilitatskontrolle_description : '')
                    setDescriptionSichtprufung(res?.data?.dcabname.sichtprufung_description && res?.data?.dcabname.sichtprufung_description != 'null' ? res?.data?.dcabname.sichtprufung_description : '')

                    setValue('modultyp', res?.data?.dcabname?.modultyp)
                    setValue('modulanzahl', res?.data?.dcabname.modulanzahl)
                    setLink(res?.data?.pdf)
                    for (let [key, value] of Object.entries(res?.data?.dcabname)) {
                        if (key == 'payload') {
                            for (let [key, value] of Object.entries(payload)) {
                                setValue(key, (value == null || value == 'null' ? "" : (value === 'false' || value === '0' ? 0 : value === 'true' || value === '1' ? 1 : value)));
                                if (key == 'verbaut_ja') {
                                    if (value == 1) {
                                        setVerbaut(true);
                                    }
                                }
                                if (key == 'smarter_ja') {
                                    if (value == 1) {
                                        setSmarter(true);
                                    }
                                }

                                if (key == 'sls_verbaut_ja') {
                                    if (value == 1) {
                                        setSls(true);
                                    }
                                }

                                if (key == 'ausgebaut_ja') {
                                    if (value == 1) {
                                        seAusgebaut(true);
                                    }
                                }
                                if (key == 'anzahl_ja') {
                                    if (value == 1) {
                                        setAnzahl(true);
                                    }
                                }
                                if (key == 'erdung_ja') {
                                    if (value == 1) {
                                        setErdung(true);
                                    }
                                }
                                if (key == 'vorhanden_ja') {
                                    if (value == 1) {
                                        setVorhanden(true);
                                    }
                                }
                                if (key == 'ausfuhrung_ja') {
                                    if (value == 1) {
                                        setAusfuhrung(true);
                                    }
                                }
                                if (key == 'strangsicherungen_ja') {
                                    if (value == 1) {
                                        setStrangsicherungen(true);
                                    }
                                }
                                if (key == 'uberspannungsableiter_ja') {
                                    if (value == 1) {
                                        setUberspannungsableiter(true);
                                    }
                                }
                                if (key == 'modulanschlubleitung_ja') {
                                    if (value == 1) {
                                        setModulanschlubleitung(true);
                                    }
                                }
                                if (key == 'freischalter_ja') {
                                    if (value == 1) {
                                        setFreischalter(true);
                                    }
                                }
                                if (key == 'fehlerstromschutzschalter_ja') {
                                    if (value == 1) {
                                        setFehlerstromschutzschalter(true);
                                    }
                                }
                                if (key == 'sicherung_ja') {
                                    if (value == 1) {
                                        setSicherung(true);
                                    }
                                }
                                if (key == 'funktions_ja') {
                                    if (value == 1) {
                                        setFunktions(true);
                                    }
                                }
                            }
                        } else if (key == 'filesdata') {
                            setTabFiles(JSON.parse(value));
                        } else {
                            setValue(key, (value == null || value == 'null' ? "" : value));
                        }
                    }
                    setStrgItemRefresh(strgItemRefresh + 1);
                }
            })
        }
    }, [props.project_id, refresh]);

    const setCallBackData = (data) => {
        setTabFiles(data);

        setDropZonValidationError('');
        for (let [key, dropZoneTab] of Object.entries(dropZoneTabs)) {
            if (dropZoneTab?.require === true && (!data[dropZoneTab?.name] || data[dropZoneTab?.name]?.length <= 0)) {
                setDropZonValidationError(dropZoneTab?.titel + ' is required')
                return;
            }
        }
    }

    const [verbaut, setVerbaut] = useState();
    const handleVerbaut = (e) => {
        setVerbaut(e.target.checked);
    }

    const [anzahl, setAnzahl] = useState();
    const handleAnzahl = (e) => {
        setAnzahl(e.target.checked);
    }

    const [erdung, setErdung] = useState();
    const handleErdung = (e) => {
        setErdung(e.target.checked);
    }

    const [vorhanden, setVorhanden] = useState();
    const handleVorhanden = (e) => {
        setVorhanden(e.target.checked);
    }

    const [ausfuhrung, setAusfuhrung] = useState();
    const handleAusfuhrung = (e) => {
        setAusfuhrung(e.target.checked);
    }

    const [strangsicherungen, setStrangsicherungen] = useState();
    const handleStrangsicherungen = (e) => {
        setStrangsicherungen(e.target.checked);
    }

    const [uberspannungsableiter, setUberspannungsableiter] = useState();
    const handleUberspannungsableiter = (e) => {
        setUberspannungsableiter(e.target.checked);
    }

    const [modulanschlubleitung, setModulanschlubleitung] = useState();
    const handleModulanschlubleitung = (e) => {
        setModulanschlubleitung(e.target.checked);
    }

    const [freischalter, setFreischalter] = useState();
    const handleFreischalter = (e) => {
        setFreischalter(e.target.checked);
    }

    const [fehlerstromschutzschalter, setFehlerstromschutzschalter] = useState();
    const handleFehlerstromschutzschalter = (e) => {
        setFehlerstromschutzschalter(e.target.checked);
    }

    const [sicherung, setSicherung] = useState();
    const handleSicherung = (e) => {
        setSicherung(e.target.checked);
    }

    const [funktions, setFunktions] = useState();
    const handleFunktions = (e) => {
        setFunktions(e.target.checked);
    }

    const [smarter, setSmarter] = useState();
    const handleSmarter = (e) => {
        setSmarter(e.target.checked);
    }

    const [sls, setSls] = useState();
    const handleChangeSLS = (e) => {
        setSls(e.target.checked);
    }

    const [ausgebaut, seAusgebaut] = useState();
    const handleAusgebaut = (e) => {
        seAusgebaut(e.target.checked);
    }

    const onSubmit = (formData) => {
        setDropZonValidationError('');
        for (let [key, dropZoneTab] of Object.entries(dropZoneTabs)) {
            if (dropZoneTab?.require === true && (!tabFiles[dropZoneTab?.name] || tabFiles[dropZoneTab?.name]?.length <= 0)) {
                setDropZonValidationError(dropZoneTab?.titel + ' is required')
                return;
            }
        }

        setSaving(true);

        var loadingElement = document.querySelector('.res-options .btn-primary-save');
        loadingElement.classList.add("btn-secondary");
        loadingElement.innerHTML = t('wird geladen...');
        // formData.sign = signaturePadRef.current.toDataURL('image/png');
        formData.filesdata = JSON.stringify(tabFiles);
        formData.description = description;

        formData.sichtprufung_description = descriptionSichtprufung;
        formData.plausibilitatskontrolle_description = descriptionPlausibilitatskontrolle;
        formData.description_mangel = descriptionMangel;
        formData.dokumentation_description = descriptionDokumentation;
        formData.kundeneinweisung_description = descriptionKundeneinweisung;


        if (id >= 1) {

        } else {
            formData.project_id = props.project_id;
            formData.eidt_id = Cookies.get('ac_pdf');
        }

        callFetch((id > 0 ? "ac_abnahmeprotokoll/" + id : "ac_abnahmeprotokoll"), "POST", formData, setError).then((res) => {

            //setData(res);
            //setLink(res?.data?.pdf);

            if (!id) {
                setID(res?.data?.id);

                setTimeout(() => {
                    //document.getElementById('ComisioningModalOpen').click();
                    document.getElementById('saveSign').click();
                }, 2000);
            }

            setColor('success')
            setMessage('Success');

            setTimeout(() => {

                setSaving(false);
                openSuccessSB();

                loadingElement.classList.remove("btn-secondary");
                loadingElement.innerHTML = t('Save');

                document.body.scrollTop = 0; // For Safari
                document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
                document.getElementById("main-content").scrollTop = 0;

                if (!res.ok) return;
                setSubmitSuccess(true);
                setRefresh(refresh + 1)
                clearForm();

            }, 2000);

        });
    }
    const clearForm = () => {
        reset({
            id: 0,
            title: '',
            status: '',
            description: '',
            attachments: JSON.stringify([]),
        });
    }
    return (
        <>
            <div className=''>
                <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12'>
                    {/* <Overview className="mb-3" project_id={params?.id} title={t('AC Abnahmeprotokoll')} ></Overview> */}

                    <form id="createOrderProcesing" className={`needs-validation ${Object.keys(errors).length ? "was-validated" : ""}`} onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
                        {
                            link !== null && <a className="opacity-0" target={"_blank"} id="dc_abnahmeprotokoll" href={link && link} ></a>
                        }

                        <input type="hidden" id="acid" value={id} />

                        {id > 0 ? <input type="hidden" defaultValue="PUT" {...register("_method")} /> : ''}

                        <div className='card'>
                            <div className='card-body pb-3'>
                                <div className="accordion accordion-flush" id="accordionFlushExample">
                                    <div className="accordion-item accordion-item bg-secondary p-3 border-radius-8">
                                        <h2 className="accordion-header">
                                            <button
                                                className="accordion-button"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#flush-collapseOne"
                                                aria-expanded="false"
                                                aria-controls="flush-collapseOne"
                                            >
                                                <span className="accordion-arrow">
                                                    <i className="ph ph-caret-right"></i>
                                                </span>
                                                <p className="font-size-20 font-weight-600 text-primary">
                                                    <span className="pl-2">AC Abnahmeprotokoll</span>
                                                </p>
                                            </button>
                                        </h2>
                                        <div
                                            id="flush-collapseOne"
                                            className="accordion-collapse collapse show"
                                            data-bs-parent="#accordionFlushExample"
                                        >
                                            <div className="accordion-body">
                                            <Grid container spacing={2}>
                                                    <Grid item xs={12} sm={12} md={6} lg={6} >
                                                        <SoftBox mb={2}>
                                                            <SoftTypography variant="h5">{t('Anlagenstandort')}</SoftTypography>
                                                        </SoftBox>
                                                        <label className="mb-1">StraBe, Hausnummer</label>
                                                        <input type="text" className=" form-control" placeholder="StraBe, Hausnummer" {...register('anlagenstandort_strabe', { required: true })} required />
                                                    </Grid>

                                                    <Grid item xs={12} sm={12} md={6} lg={6} >
                                                        <SoftBox mb={2}>
                                                            <SoftTypography variant="h5">{t('Abnahmezeit')}</SoftTypography>
                                                        </SoftBox>
                                                        <label className="mb-1">Abnahmezeit</label>
                                                        <input type="text" className=" form-control" placeholder="Abnahmezeit" {...register('abnahmezeit', { required: true })} required />
                                                    </Grid>
                                                </Grid>

                                                <Grid container spacing={2} style={{ marginTop: '0px' }}>
                                                    <Grid item xs={12} sm={12} md={6} lg={6} >
                                                        <label className="mb-1">PLZ, Ort:</label>
                                                        <input type="text" className=" form-control" placeholder="Anlagenleistung in kwp" {...register('anlagen_plz_ort', { required: true })} required />
                                                    </Grid>

                                                </Grid>

                                                <Grid my={2} container spacing={2}>
                                                    {
                                                        Object.keys(strgItem).map(function (keyName, index) {
                                                            return (
                                                                <>
                                                                    <Grid item xs={6} sm={6} md={2} lg={2}>
                                                                        <label className="mb-1">Strg {(index + 1)}</label>
                                                                        <input
                                                                            type="text" className="form-control"
                                                                            placeholder={"Strg " + (index + 1)}
                                                                            /*
                                                                            {...register('strg[' + keyName + ']', {
                                                                                required: true
                                                                            })}
                                                                            */
                                                                            value={strgItem[keyName]}
                                                                            required
                                                                            readOnly
                                                                        />
                                                                    </Grid>

                                                                    <Grid item xs={6} sm={6} md={2} lg={2}>
                                                                        <label className="mb-1">Volt {(index + 1)}</label>
                                                                        <input
                                                                            type="text" className="form-control"
                                                                            placeholder={"Volt " + (index + 1)}
                                                                            /*
                                                                            {...register('volt[' + keyName + ']', {
                                                                                required: true
                                                                            })}
                                                                            */
                                                                            value={voltItem[keyName]}
                                                                            required
                                                                            readOnly
                                                                        />
                                                                    </Grid>
                                                                </>
                                                            )
                                                        })
                                                    }
                                                </Grid>

                                                <Grid my={2} container spacing={2}>
                                                    <Grid item xs={12} sm={12} md={6} lg={6} >
                                                        <label className="mb-1">Modultyp (hersteller, typ)</label>
                                                        <select className="form-control" {...register('modultyp', { required: true })} required>
                                                            <option>--</option>
                                                            {
                                                                modules && modules.map((modules, index) => {
                                                                    return <option key={index} value={modules.id}>{modules.name}</option>
                                                                })
                                                            }
                                                        </select>
                                                    </Grid>

                                                    <Grid item xs={12} sm={12} md={6} lg={6} >
                                                        <label className="mb-1">Anlagenleistung in kwp</label>
                                                        <input type="text" className=" form-control" placeholder="Anlagenleistung in kwp" {...register('anlagenleistung', { required: true })} required />
                                                    </Grid>
                                                </Grid>

                                                <Grid my={2} container spacing={2}>
                                                    <Grid item xs={12} sm={12} md={6} lg={6} >
                                                        <label className="mb-1">Modulanzahl (parallel × reihe)</label>
                                                        <input type="number" className=" form-control" placeholder="Modulanzahl" {...register('modulanzahl', { required: true })} required />
                                                    </Grid>

                                                    <Grid item xs={12} sm={12} md={6} lg={6} >
                                                        <label className="mb-1">Wechselrichter (Hersteller, typ, nennleistung)</label>
                                                        <input type="text" className=" form-control" placeholder="Wechselrichter" {...register('wechselrichter', { required: false })} />
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={6} lg={6} >
                                                        <label className="mb-1">Potenzialausgleich/Erdung</label>
                                                        <input type="text" className=" form-control" placeholder="Potenzialausgleich/Erdung" {...register('potenzialausgleich', { required: true })} required />
                                                    </Grid>

                                                </Grid>

                                                <Grid my={2} container spacing={2} >

                                                    <Grid item xs={12} sm={12} md={12} lg={12} display="flex" alignItems="center" justifyContent="space-between">
                                                        <Grid container spacing={2} >
                                                            <Grid item xs={12} sm={12} md={6} lg={6} >
                                                                <SoftTypography variant="p"  fontSize="14px">Anzahl der Stränge pro Wechselrichter, Anzahl der Module pro Strang</SoftTypography>
                                                            </Grid>
                                                            <Grid item xs={12} sm={12} md={3} lg={3} >
                                                                <div className="d-flex">
                                                                    <div className="form-check d-inline-block">
                                                                        <input
                                                                            type="checkbox"
                                                                            {...register("anzahl_ja")}

                                                                            className="form-check-input  ms-1"
                                                                            value={1}
                                                                            onChange={(e) => handleAnzahl(e)}
                                                                        />
                                                                    </div>
                                                                    <label className="mt-2 uppercase" >JA</label>
                                                                    {anzahl && <input  {...register("anzahl_text")} className='d-inline-block form-control' style={{ position: 'relative', left: '8px', top: '-8px' }} placeholder='Type' />}
                                                                </div>
                                                                <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                                            </Grid>
                                                            <Grid item xs={12} sm={12} md={3} lg={3} >
                                                                <div className=" d-flex align-items-center">
                                                                    <div className="form-check d-inline-block">
                                                                        <input
                                                                            type="checkbox"
                                                                            {...register("anzahl_nein")}

                                                                            className="form-check-input  ms-1"
                                                                            value={1}
                                                                        />
                                                                    </div>
                                                                    <label className="mt-2 uppercase" >NEIN</label>
                                                                </div>
                                                                <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid item xs={12} sm={12} md={12} lg={12} display="flex" alignItems="center" justifyContent="space-between">
                                                        <Grid container spacing={2} >
                                                            <Grid item xs={12} sm={12} md={6} lg={6} >
                                                                <SoftTypography variant="p"  fontSize="14px">Potentialausgleich / Erdung: Ausführung, Installationsort, Bemerkung</SoftTypography>
                                                            </Grid>
                                                            <Grid item xs={12} sm={12} md={3} lg={3} >
                                                                <div className="d-flex">
                                                                    <div className="form-check d-inline-block">
                                                                        <input
                                                                            type="checkbox"
                                                                            {...register("erdung_ja")}

                                                                            className="form-check-input  ms-1"
                                                                            value={1}
                                                                            onChange={(e) => handleErdung(e)}
                                                                        />
                                                                    </div>
                                                                    <label className="mt-2 uppercase" >JA</label>
                                                                    {erdung && <input  {...register("erdung_text")} className='d-inline-block form-control' style={{ position: 'relative', left: '8px', top: '-8px' }} placeholder='Type' />}
                                                                </div>
                                                                <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                                            </Grid>
                                                            <Grid item xs={12} sm={12} md={3} lg={3} >
                                                                <div className=" d-flex align-items-center">
                                                                    <div className="form-check d-inline-block">
                                                                        <input
                                                                            type="checkbox"
                                                                            {...register("erdung_nein")}

                                                                            className="form-check-input  ms-1"
                                                                            value={1}
                                                                        />
                                                                    </div>
                                                                    <label className="mt-2 uppercase" >NEIN</label>
                                                                </div>
                                                                <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid item xs={12} sm={12} md={12} lg={12} display="flex" alignItems="center" justifyContent="space-between">
                                                        <Grid container spacing={2} >
                                                            <Grid item xs={12} sm={12} md={6} lg={6} >
                                                                <SoftTypography variant="p"  fontSize="14px">Äußerer Blitzschutz vorhanden?</SoftTypography>
                                                            </Grid>
                                                            <Grid item xs={12} sm={12} md={3} lg={3} >
                                                                <div className="d-flex">
                                                                    <div className="form-check d-inline-block">
                                                                        <input
                                                                            type="checkbox"
                                                                            {...register("vorhanden_ja")}

                                                                            className="form-check-input  ms-1"
                                                                            value={1}
                                                                            onChange={(e) => handleVorhanden(e)}
                                                                        />
                                                                    </div>
                                                                    <label className="mt-2 uppercase" >JA</label>
                                                                    {vorhanden && <input  {...register("vorhanden_text")} className='d-inline-block form-control' style={{ position: 'relative', left: '8px', top: '-8px' }} placeholder='Type' />}
                                                                </div>
                                                                <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                                            </Grid>
                                                            <Grid item xs={12} sm={12} md={3} lg={3} >
                                                                <div className=" d-flex align-items-center">
                                                                    <div className="form-check d-inline-block">
                                                                        <input
                                                                            type="checkbox"
                                                                            {...register("vorhanden_nein")}

                                                                            className="form-check-input  ms-1"
                                                                            value={1}
                                                                        />
                                                                    </div>
                                                                    <label className="mt-2 uppercase" >NEIN</label>
                                                                </div>
                                                                <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid item xs={12} sm={12} md={12} lg={12} display="flex" alignItems="center" justifyContent="space-between">
                                                        <Grid container spacing={2} >
                                                            <Grid item xs={12} sm={12} md={6} lg={6} >
                                                                <SoftTypography variant="p"  fontSize="14px">Wenn Ja: Ausführung, Installationsort, Bemerkung:</SoftTypography>
                                                            </Grid>
                                                            <Grid item xs={12} sm={12} md={3} lg={3} >
                                                                <div className="d-flex">
                                                                    <div className="form-check d-inline-block">
                                                                        <input
                                                                            type="checkbox"
                                                                            {...register("ausfuhrung_ja")}

                                                                            className="form-check-input  ms-1"
                                                                            value={1}
                                                                            onChange={(e) => handleAusfuhrung(e)}
                                                                        />
                                                                    </div>
                                                                    <label className="mt-2 uppercase" >JA</label>
                                                                    {ausfuhrung && <input  {...register("ausfuhrung_text")} className='d-inline-block form-control' style={{ position: 'relative', left: '8px', top: '-8px' }} placeholder='Type' />}
                                                                </div>
                                                                <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                                            </Grid>
                                                            <Grid item xs={12} sm={12} md={3} lg={3} >
                                                                <div className=" d-flex align-items-center">
                                                                    <div className="form-check d-inline-block">
                                                                        <input
                                                                            type="checkbox"
                                                                            {...register("ausfuhrung_nein")}

                                                                            className="form-check-input  ms-1"
                                                                            value={1}
                                                                        />
                                                                    </div>
                                                                    <label className="mt-2 uppercase" >NEIN</label>
                                                                </div>
                                                                <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid item xs={12} sm={12} md={12} lg={12} display="flex" alignItems="center" justifyContent="space-between">
                                                        <Grid container spacing={2} >
                                                            <Grid item xs={12} sm={12} md={6} lg={6} >
                                                                <SoftTypography variant="p"  fontSize="14px">Strangsicherungen (Hersteller,Typ, Spannung/Strom):</SoftTypography>
                                                            </Grid>
                                                            <Grid item xs={12} sm={12} md={3} lg={3} >
                                                                <div className="d-flex">
                                                                    <div className="form-check d-inline-block">
                                                                        <input
                                                                            type="checkbox"
                                                                            {...register("strangsicherungen_ja")}

                                                                            className="form-check-input  ms-1"
                                                                            value={1}
                                                                            onChange={(e) => handleStrangsicherungen(e)}
                                                                        />
                                                                    </div>
                                                                    <label className="mt-2 uppercase" >JA</label>
                                                                    {strangsicherungen && <input  {...register("strangsicherungen_text")} className='d-inline-block form-control' style={{ position: 'relative', left: '8px', top: '-8px' }} placeholder='Type' />}
                                                                </div>
                                                                <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                                            </Grid>
                                                            <Grid item xs={12} sm={12} md={3} lg={3} >
                                                                <div className=" d-flex align-items-center">
                                                                    <div className="form-check d-inline-block">
                                                                        <input
                                                                            type="checkbox"
                                                                            {...register("strangsicherungen_nein")}

                                                                            className="form-check-input  ms-1"
                                                                            value={1}
                                                                        />
                                                                    </div>
                                                                    <label className="mt-2 uppercase" >NEIN</label>
                                                                </div>
                                                                <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid item xs={12} sm={12} md={12} lg={12} display="flex" alignItems="center" justifyContent="space-between">
                                                        <Grid container spacing={2} >
                                                            <Grid item xs={12} sm={12} md={6} lg={6} >
                                                                <SoftTypography variant="p"  fontSize="14px">Überspannungsableiter DC seitig und AC seitig(wenn vorhanden):</SoftTypography>
                                                            </Grid>
                                                            <Grid item xs={12} sm={12} md={3} lg={3} >
                                                                <div className="d-flex">
                                                                    <div className="form-check d-inline-block">
                                                                        <input
                                                                            type="checkbox"
                                                                            {...register("uberspannungsableiter_ja")}

                                                                            className="form-check-input  ms-1"
                                                                            value={1}
                                                                            onChange={(e) => handleUberspannungsableiter(e)}
                                                                        />
                                                                    </div>
                                                                    <label className="mt-2 uppercase" >JA</label>
                                                                    {uberspannungsableiter && <input  {...register("uberspannungsableiter_text")} className='d-inline-block form-control' style={{ position: 'relative', left: '8px', top: '-8px' }} placeholder='(jeweils
            Hersteller,Typ,Nennspannung)' />}
                                                                </div>
                                                                <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                                            </Grid>
                                                            <Grid item xs={12} sm={12} md={3} lg={3} >
                                                                <div className=" d-flex align-items-center">
                                                                    <div className="form-check d-inline-block">
                                                                        <input
                                                                            type="checkbox"
                                                                            {...register("uberspannungsableiter_nein")}

                                                                            className="form-check-input  ms-1"
                                                                            value={1}
                                                                        />
                                                                    </div>
                                                                    <label className="mt-2 uppercase" >NEIN</label>
                                                                </div>
                                                                <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid item xs={12} sm={12} md={12} lg={12} display="flex" alignItems="center" justifyContent="space-between">
                                                        <Grid container spacing={2} >
                                                            <Grid item xs={12} sm={12} md={6} lg={6} >
                                                                <SoftTypography variant="p"  fontSize="14px"> Modulanschlußleitung, Gleichstromhauptleitung (jeweils Hersteller,Typ, Querschnitt):</SoftTypography>
                                                            </Grid>
                                                            <Grid item xs={12} sm={12} md={3} lg={3} >
                                                                <div className="d-flex">
                                                                    <div className="form-check d-inline-block">
                                                                        <input
                                                                            type="checkbox"
                                                                            {...register("modulanschlubleitung_ja")}

                                                                            className="form-check-input  ms-1"
                                                                            value={1}
                                                                            onChange={(e) => handleModulanschlubleitung(e)}
                                                                        />
                                                                    </div>
                                                                    <label className="mt-2 uppercase" >JA</label>
                                                                    {modulanschlubleitung && <input  {...register("modulanschlubleitung_text")} className='d-inline-block form-control' style={{ position: 'relative', left: '8px', top: '-8px' }} placeholder='Type' />}
                                                                </div>
                                                                <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                                            </Grid>
                                                            <Grid item xs={12} sm={12} md={3} lg={3} >
                                                                <div className=" d-flex align-items-center">
                                                                    <div className="form-check d-inline-block">
                                                                        <input
                                                                            type="checkbox"
                                                                            {...register("modulanschlubleitung_nein")}

                                                                            className="form-check-input  ms-1"
                                                                            value={1}
                                                                        />
                                                                    </div>
                                                                    <label className="mt-2 uppercase" >NEIN</label>
                                                                </div>
                                                                <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid item xs={12} sm={12} md={12} lg={12} display="flex" alignItems="center" justifyContent="space-between">
                                                        <Grid container spacing={2} >
                                                            <Grid item xs={12} sm={12} md={6} lg={6} >
                                                                <SoftTypography variant="p"  fontSize="14px"> DC-Freischalter:(Hersteller,Typ)</SoftTypography>
                                                            </Grid>
                                                            <Grid item xs={12} sm={12} md={3} lg={3} >
                                                                <div className="d-flex">
                                                                    <div className="form-check d-inline-block">
                                                                        <input
                                                                            type="checkbox"
                                                                            {...register("freischalter_ja")}

                                                                            className="form-check-input  ms-1"
                                                                            value={1}
                                                                            onChange={(e) => handleFreischalter(e)}
                                                                        />
                                                                    </div>
                                                                    <label className="mt-2 uppercase" >JA</label>
                                                                    {freischalter && <input  {...register("freischalter_text")} className='d-inline-block form-control' style={{ position: 'relative', left: '8px', top: '-8px' }} placeholder='Type' />}
                                                                </div>
                                                                <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                                            </Grid>
                                                            <Grid item xs={12} sm={12} md={3} lg={3} >
                                                                <div className=" d-flex align-items-center">
                                                                    <div className="form-check d-inline-block">
                                                                        <input
                                                                            type="checkbox"
                                                                            {...register("freischalter_nein")}

                                                                            className="form-check-input  ms-1"
                                                                            value={1}
                                                                        />
                                                                    </div>
                                                                    <label className="mt-2 uppercase" >NEIN</label>
                                                                </div>
                                                                <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid item xs={12} sm={12} md={12} lg={12} display="flex" alignItems="center" justifyContent="space-between">
                                                        <Grid container spacing={2} >
                                                            <Grid item xs={12} sm={12} md={6} lg={6} >
                                                                <SoftTypography variant="p"  fontSize="14px">AC-seitiger Fehlerstromschutzschalter (RCD-Schalter) wenn vorhanden:</SoftTypography>
                                                            </Grid>
                                                            <Grid item xs={12} sm={12} md={3} lg={3} >
                                                                <div className="d-flex">
                                                                    <div className="form-check d-inline-block">
                                                                        <input
                                                                            type="checkbox"
                                                                            {...register("fehlerstromschutzschalter_ja")}

                                                                            className="form-check-input  ms-1"
                                                                            value={1}
                                                                            onChange={(e) => handleFehlerstromschutzschalter(e)}
                                                                        />
                                                                    </div>
                                                                    <label className="mt-2 uppercase" >JA</label>
                                                                    {fehlerstromschutzschalter && <input  {...register("fehlerstromschutzschalter_text")} className='d-inline-block form-control' style={{ position: 'relative', left: '8px', top: '-8px' }} placeholder='Type' />}
                                                                </div>
                                                                <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                                            </Grid>
                                                            <Grid item xs={12} sm={12} md={3} lg={3} >
                                                                <div className=" d-flex align-items-center">
                                                                    <div className="form-check d-inline-block">
                                                                        <input
                                                                            type="checkbox"
                                                                            {...register("fehlerstromschutzschalter_nein")}

                                                                            className="form-check-input  ms-1"
                                                                            value={1}
                                                                        />
                                                                    </div>
                                                                    <label className="mt-2 uppercase" >NEIN</label>
                                                                </div>
                                                                <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid item xs={12} sm={12} md={12} lg={12} display="flex" alignItems="center" justifyContent="space-between">
                                                        <Grid container spacing={2} >
                                                            <Grid item xs={12} sm={12} md={6} lg={6} >
                                                                <SoftTypography variant="p"  fontSize="14px">AC-seitige Sicherung:</SoftTypography>
                                                            </Grid>
                                                            <Grid item xs={12} sm={12} md={3} lg={3} >
                                                                <div className="d-flex">
                                                                    <div className="form-check d-inline-block">
                                                                        <input
                                                                            type="checkbox"
                                                                            {...register("sicherung_ja")}

                                                                            className="form-check-input  ms-1"
                                                                            value={1}
                                                                            onChange={(e) => handleSicherung(e)}
                                                                        />
                                                                    </div>
                                                                    <label className="mt-2 uppercase" >JA</label>
                                                                    {sicherung && <input  {...register("sicherung_text")} className='d-inline-block form-control' style={{ position: 'relative', left: '8px', top: '-8px' }} placeholder='Hersteller, Typ,Spannung/Strom' />}
                                                                </div>
                                                                <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                                            </Grid>
                                                            <Grid item xs={12} sm={12} md={3} lg={3} >
                                                                <div className=" d-flex align-items-center">
                                                                    <div className="form-check d-inline-block">
                                                                        <input
                                                                            type="checkbox"
                                                                            {...register("sicherung_nein")}

                                                                            className="form-check-input  ms-1"
                                                                            value={1}
                                                                        />
                                                                    </div>
                                                                    <label className="mt-2 uppercase" >NEIN</label>
                                                                </div>
                                                                <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid item xs={12} sm={12} md={12} lg={12} display="flex" alignItems="center" justifyContent="space-between">
                                                        <Grid container spacing={2} >
                                                            <Grid item xs={12} sm={12} md={6} lg={6} >
                                                                <SoftTypography variant="p"  fontSize="14px">Funktions-,Ertrags-,Daten(fern)überwachung vorhanden?</SoftTypography>
                                                            </Grid>
                                                            <Grid item xs={12} sm={12} md={3} lg={3} >
                                                                <div className="d-flex">
                                                                    <div className="form-check d-inline-block">
                                                                        <input
                                                                            type="checkbox"
                                                                            {...register("funktions_ja")}

                                                                            className="form-check-input  ms-1"
                                                                            value={1}
                                                                            onChange={(e) => handleFunktions(e)}
                                                                        />
                                                                    </div>
                                                                    <label className="mt-2 uppercase" >JA</label>
                                                                    {funktions && <input  {...register("funktions_text")} className='d-inline-block form-control' style={{ position: 'relative', left: '8px', top: '-8px' }} placeholder='Type' />}
                                                                </div>
                                                                <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                                            </Grid>
                                                            <Grid item xs={12} sm={12} md={3} lg={3} >
                                                                <div className=" d-flex align-items-center">
                                                                    <div className="form-check d-inline-block">
                                                                        <input
                                                                            type="checkbox"
                                                                            {...register("funktions_nein")}

                                                                            className="form-check-input  ms-1"
                                                                            value={1}
                                                                        />
                                                                    </div>
                                                                    <label className="mt-2 uppercase" >NEIN</label>
                                                                </div>
                                                                <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>



                                                    <Grid item xs={12} sm={12} md={12} lg={12} display="flex" alignItems="center" justifyContent="space-between">
                                                        <Grid container spacing={2} >
                                                            <Grid item xs={12} sm={12} md={6} lg={2} >
                                                                <SoftTypography variant="p"  fontSize="14px">Hersteller,Typ:</SoftTypography>
                                                            </Grid>
                                                            <Grid item xs={12} sm={12} md={9} lg={10} style={{ paddingRight: '10px;' }}>
                                                                <div className="d-flex" style={{ paddingRight: '10px;' }}>
                                                                    <input  {...register("hersteller_text")} className='d-inline-block form-control' style={{ position: 'relative', top: '-8px' }} placeholder='Type' />
                                                                </div>
                                                                <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid item xs={12} sm={12} md={12} lg={12} display="flex" alignItems="center" justifyContent="space-between">
                                                        <Grid container spacing={2} >
                                                            <Grid item xs={12} sm={12} md={6} lg={6} >
                                                                <SoftTypography variant="p"  fontSize="14px">mit der Funktions-, Ertrags-, Daten(fern)überwachung betraute Person/Firma:</SoftTypography>
                                                            </Grid>
                                                            <Grid item xs={12} sm={12} md={3} lg={3} >
                                                                <div className="d-flex">
                                                                    <div className="form-check d-inline-block">
                                                                        <input
                                                                            type="checkbox"
                                                                            {...register("mitder_funktions_ja")}

                                                                            className="form-check-input  ms-1"
                                                                            value={1}
                                                                            onChange={(e) => handleVerbaut(e)}
                                                                        />
                                                                    </div>
                                                                    <label className="mt-2 uppercase" >JA</label>
                                                                    {verbaut && <input  {...register("mitder_funktions_text")} className='d-inline-block form-control' style={{ position: 'relative', left: '8px', top: '-8px' }} placeholder='Type' />}
                                                                </div>
                                                                <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                                            </Grid>
                                                            <Grid item xs={12} sm={12} md={3} lg={3} >
                                                                <div className=" d-flex align-items-center">
                                                                    <div className="form-check d-inline-block">
                                                                        <input
                                                                            type="checkbox"
                                                                            {...register("mitder_funktions_nein")}

                                                                            className="form-check-input  ms-1"
                                                                            value={1}
                                                                        />
                                                                    </div>
                                                                    <label className="mt-2 uppercase" >NEIN</label>
                                                                </div>
                                                                <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid item xs={12} sm={12} md={12} lg={12} display="flex" alignItems="center" justifyContent="space-between">
                                                        <Grid container spacing={2} >
                                                            <Grid item xs={12} sm={12} md={6} lg={6} >
                                                                <SoftTypography variant="p"  fontSize="14px" >Isolationswiderstand des PV-Generators:</SoftTypography>
                                                            </Grid>
                                                            <Grid item xs={12} sm={12} md={3} lg={3} >
                                                                <div className=" d-flex align-items-center">
                                                                    <div className="form-check d-inline-block">
                                                                        <input
                                                                            type="checkbox"
                                                                            {...register("isolationswiderstand_pv_ordnung")}

                                                                            className="form-check-input  ms-1"
                                                                            value={1}
                                                                        />
                                                                    </div>
                                                                    <label className="mt-2 uppercase" >in Ordnung</label>

                                                                </div>
                                                                <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                                            </Grid>
                                                            <Grid item xs={12} sm={12} md={3} lg={3} >
                                                                <div className=" d-flex align-items-center">
                                                                    <div className="form-check d-inline-block">
                                                                        <input
                                                                            type="checkbox"
                                                                            {...register("isolationswiderstand_pv_beanstandet")}

                                                                            className="form-check-input  ms-1"
                                                                            value={1}
                                                                        />
                                                                    </div>
                                                                    <label className="mt-2 uppercase" >beanstandet</label>
                                                                </div>
                                                                <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid item xs={12} sm={12} md={12} lg={12} display="flex" alignItems="center" justifyContent="space-between">
                                                        <Grid container spacing={2} >
                                                            <Grid item xs={12} sm={12} md={6} lg={6} >
                                                                <SoftTypography variant="p"  fontSize="14px" >Isolationswiderstand der DC-Hauptleitung (nicht zutreffendes bitte streichen): </SoftTypography>
                                                            </Grid>
                                                            <Grid item xs={12} sm={12} md={3} lg={3} >
                                                                <div className=" d-flex align-items-center">
                                                                    <div className="form-check d-inline-block">
                                                                        <input
                                                                            type="checkbox"
                                                                            {...register("isolationswiderstand_dc_ordnung")}

                                                                            className="form-check-input  ms-1"
                                                                            value={1}
                                                                        />
                                                                    </div>
                                                                    <label className="mt-2 uppercase" >in Ordnung</label>

                                                                </div>
                                                                <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                                            </Grid>
                                                            <Grid item xs={12} sm={12} md={3} lg={3} >
                                                                <div className=" d-flex align-items-center">
                                                                    <div className="form-check d-inline-block">
                                                                        <input
                                                                            type="checkbox"
                                                                            {...register("isolationswiderstand_dc_beanstandet")}

                                                                            className="form-check-input  ms-1"
                                                                            value={1}
                                                                        />
                                                                    </div>
                                                                    <label className="mt-2 uppercase" >beanstandet</label>
                                                                </div>
                                                                <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                </Grid>

                                                <SoftBox
                                                    display="flex"
                                                    flexDirection="column"
                                                    justifyContent="flex-end"
                                                    height="100%"
                                                >
                                                    <SoftBox mb={1} ml={0.5} mt={3} lineHeight={0} display="inline-block">
                                                        <SoftTypography component="label" variant="caption" >
                                                            {t('Description')}
                                                        </SoftTypography>
                                                    </SoftBox>
                                                    {false ? (
                                                        <>
                                                            {description}
                                                        </>
                                                    ) : (
                                                        <div className='card box-shadow-none'>
                                                            <div className='card-body p-0'>
                                                                <SoftEditor
                                                                value={description}
                                                                onChange={setDescription}
                                                            />
                                                            </div>
                                                        </div>
                                                    )}
                                                </SoftBox>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="accordion accordion-flush mt-3" id="accordionFlushExample">
                                    <div className="accordion-item accordion-item bg-secondary p-3 border-radius-8">
                                        <h2 className="accordion-header">
                                            <button
                                                className="accordion-button collapsed"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#flush-collapseTwo"
                                                aria-expanded="false"
                                                aria-controls="flush-collapseTwo"
                                            >
                                                <span className="accordion-arrow">
                                                    <i className="ph ph-caret-right"></i>
                                                </span>
                                                <p className="font-size-20 font-weight-600 text-primary">
                                                    <span className="pl-2">Sichtprüfung, Abgleichung mit der Planung</span>
                                                </p>
                                            </button>
                                        </h2>
                                        <div
                                            id="flush-collapseTwo"
                                            className="accordion-collapse collapse"
                                            data-bs-parent="#accordionFlushExample"
                                        >
                                            <div className="accordion-body">
                                            <SoftBox>
                                    <Grid item xs={12} sm={12} md={12} lg={12} display="flex" alignItems="center" justifyContent="space-between">
                                        <Grid container spacing={2} >
                                            <Grid item xs={12} sm={12} md={6} lg={6} >
                                                <SoftTypography variant="p"  fontSize="14px" >Anlagenmontage an Anlage, Dach, Gebäude, usw.:</SoftTypography>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={3} lg={3} >
                                                <div className=" d-flex align-items-center">
                                                    <div className="form-check d-inline-block">
                                                        <input
                                                            type="checkbox"
                                                            {...register("anlagenmontage_ordnung")}

                                                            className="form-check-input  ms-1"
                                                            value={1}
                                                            onChange={(e) => handleAusgebaut(e)}
                                                        />
                                                    </div>
                                                    <label className="mt-2 uppercase" >in Ordnung</label>

                                                </div>
                                                <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={3} lg={3} >
                                                <div className=" d-flex align-items-center">
                                                    <div className="form-check d-inline-block">
                                                        <input
                                                            type="checkbox"
                                                            {...register("anlagenmontage_beanstandet")}

                                                            className="form-check-input  ms-1"
                                                            value={1}
                                                        />
                                                    </div>
                                                    <label className="mt-2 uppercase" >beanstandet</label>
                                                </div>
                                                <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12} lg={12} display="flex" alignItems="center" justifyContent="space-between">
                                        <Grid container spacing={2} >
                                            <Grid item xs={12} sm={12} md={6} lg={6} >
                                                <SoftTypography variant="p"  fontSize="14px" >Montagesystem, Dachdurchdringungen/Abdichtungen:</SoftTypography>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={3} lg={3} >
                                                <div className=" d-flex align-items-center">
                                                    <div className="form-check d-inline-block">
                                                        <input
                                                            type="checkbox"
                                                            {...register("montagesystem_ordnung")}

                                                            className="form-check-input  ms-1"
                                                            value={1}
                                                        />
                                                    </div>
                                                    <label className="mt-2 uppercase" >in Ordnung</label>

                                                </div>
                                                <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={3} lg={3} >
                                                <div className=" d-flex align-items-center">
                                                    <div className="form-check d-inline-block">
                                                        <input
                                                            type="checkbox"
                                                            {...register("montagesystem_beanstandet")}

                                                            className="form-check-input  ms-1"
                                                            value={1}
                                                        />
                                                    </div>
                                                    <label className="mt-2 uppercase" >beanstandet</label>
                                                </div>
                                                <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12} lg={12} display="flex" alignItems="center" justifyContent="space-between">
                                        <Grid container spacing={2} >
                                            <Grid item xs={12} sm={12} md={6} lg={6} >
                                                <SoftTypography variant="p"  fontSize="14px" >Verkabelung / Leitungsführung:</SoftTypography>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={3} lg={3} >
                                                <div className=" d-flex align-items-center">
                                                    <div className="form-check d-inline-block">
                                                        <input
                                                            type="checkbox"
                                                            {...register("verkabelung_ordnung")}

                                                            className="form-check-input  ms-1"
                                                            value={1}
                                                        />
                                                    </div>
                                                    <label className="mt-2 uppercase" >in Ordnung</label>

                                                </div>
                                                <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={3} lg={3} >
                                                <div className=" d-flex align-items-center">
                                                    <div className="form-check d-inline-block">
                                                        <input
                                                            type="checkbox"
                                                            {...register("verkabelung_beanstandet")}

                                                            className="form-check-input  ms-1"
                                                            value={1}
                                                        />
                                                    </div>
                                                    <label className="mt-2 uppercase" >beanstandet</label>
                                                </div>
                                                <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12} lg={12} display="flex" alignItems="center" justifyContent="space-between">
                                        <Grid container spacing={2} >
                                            <Grid item xs={12} sm={12} md={6} lg={6} >
                                                <SoftTypography variant="p"  fontSize="14px" >Wechselrichter, Wechselrichterfunktion:</SoftTypography>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={3} lg={3} >
                                                <div className=" d-flex align-items-center">
                                                    <div className="form-check d-inline-block">
                                                        <input
                                                            type="checkbox"
                                                            {...register("wechselrichter_ordnung")}

                                                            className="form-check-input  ms-1"
                                                            value={1}
                                                        />
                                                    </div>
                                                    <label className="mt-2 uppercase" >in Ordnung</label>

                                                </div>
                                                <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={3} lg={3} >
                                                <div className=" d-flex align-items-center">
                                                    <div className="form-check d-inline-block">
                                                        <input
                                                            type="checkbox"
                                                            {...register("wechselrichter_beanstandet")}

                                                            className="form-check-input  ms-1"
                                                            value={1}
                                                        />
                                                    </div>
                                                    <label className="mt-2 uppercase" >beanstandet</label>
                                                </div>
                                                <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12} lg={12} display="flex" alignItems="center" justifyContent="space-between">
                                        <Grid container spacing={2} >
                                            <Grid item xs={12} sm={12} md={6} lg={6} >
                                                <SoftTypography variant="p"  fontSize="14px" >Funktionsprüfung der Schutzeinrichtungen:</SoftTypography>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={3} lg={3} >
                                                <div className=" d-flex align-items-center">
                                                    <div className="form-check d-inline-block">
                                                        <input
                                                            type="checkbox"
                                                            {...register("funktionsprafung_ordnung")}

                                                            className="form-check-input  ms-1"
                                                            value={1}
                                                        />
                                                    </div>
                                                    <label className="mt-2 uppercase" >in Ordnung</label>

                                                </div>
                                                <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={3} lg={3} >
                                                <div className=" d-flex align-items-center">
                                                    <div className="form-check d-inline-block">
                                                        <input
                                                            type="checkbox"
                                                            {...register("funktionsprafung_beanstandet")}

                                                            className="form-check-input  ms-1"
                                                            value={1}
                                                        />
                                                    </div>
                                                    <label className="mt-2 uppercase" >beanstandet</label>
                                                </div>
                                                <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12} lg={12} display="flex" alignItems="center" justifyContent="space-between">
                                        <Grid container spacing={2} >
                                            <Grid item xs={12} sm={12} md={6} lg={6} >
                                                <SoftTypography variant="p"  fontSize="14px" >Einspeisekontrolle am Zähler:</SoftTypography>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={3} lg={3} >
                                                <div className=" d-flex align-items-center">
                                                    <div className="form-check d-inline-block">
                                                        <input
                                                            type="checkbox"
                                                            {...register("einspeisekontrolle_ordnung")}

                                                            className="form-check-input  ms-1"
                                                            value={1}
                                                        />
                                                    </div>
                                                    <label className="mt-2 uppercase" >in Ordnung</label>

                                                </div>
                                                <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={3} lg={3} >
                                                <div className=" d-flex align-items-center">
                                                    <div className="form-check d-inline-block">
                                                        <input
                                                            type="checkbox"
                                                            {...register("einspeisekontrolle_beanstandet")}

                                                            className="form-check-input  ms-1"
                                                            value={1}
                                                        />
                                                    </div>
                                                    <label className="mt-2 uppercase" >beanstandet</label>
                                                </div>
                                                <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12} lg={12} display="flex" alignItems="center" justifyContent="space-between">
                                        <Grid container spacing={2} >
                                            <Grid item xs={12} sm={12} md={6} lg={6} >
                                                <SoftTypography variant="p"  fontSize="14px" >Sauberkeit (Abfallentsorgung, Installationsorte sauber):</SoftTypography>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={3} lg={3} >
                                                <div className=" d-flex align-items-center">
                                                    <div className="form-check d-inline-block">
                                                        <input
                                                            type="checkbox"
                                                            {...register("sauberkeit_ordnung")}

                                                            className="form-check-input  ms-1"
                                                            value={1}
                                                        />
                                                    </div>
                                                    <label className="mt-2 uppercase" >in Ordnung</label>

                                                </div>
                                                <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={3} lg={3} >
                                                <div className=" d-flex align-items-center">
                                                    <div className="form-check d-inline-block">
                                                        <input
                                                            type="checkbox"
                                                            {...register("sauberkeit_beanstanded")}

                                                            className="form-check-input  ms-1"
                                                            value={1}
                                                        />
                                                    </div>
                                                    <label className="mt-2 uppercase" >beanstandet</label>
                                                </div>
                                                <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <SoftBox
                                        display="flex"
                                        flexDirection="column"
                                        justifyContent="flex-end"
                                        height="100%"
                                    >
                                        <SoftBox mb={1} ml={0.5} mt={3} lineHeight={0} display="inline-block">
                                            <SoftTypography component="label" variant="caption" >
                                                {t('Sonstige Bemerkungen:')}
                                            </SoftTypography>
                                        </SoftBox>
                                        {false ? (
                                            <>
                                                {descriptionSichtprufung}
                                            </>
                                        ) : (
                                            <div className="card box-shadow-none">
                                                <div className='card-body p-0'>
                                                <SoftEditor
                                                        value={descriptionSichtprufung}
                                                        onChange={setDescriptionSichtprufung}
                                                    />
                                                </div>
                                            </div>
                                        )}
                                    </SoftBox>
                                </SoftBox>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="accordion accordion-flush mt-3" id="accordionFlushExample">
                                    <div className="accordion-item accordion-item bg-secondary p-3 border-radius-8">
                                        <h2 className="accordion-header">
                                            <button
                                                className="accordion-button collapsed"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#flush-collapseThree"
                                                aria-expanded="false"
                                                aria-controls="flush-collapseThree"
                                            >
                                                <span className="accordion-arrow">
                                                    <i className="ph ph-caret-right"></i>
                                                </span>
                                                <p className="font-size-20 font-weight-600 text-primary">
                                                    <span className="pl-2">Plausibilitätskontrolle</span>
                                                </p>
                                            </button>
                                        </h2>
                                        <div
                                            id="flush-collapseThree"
                                            className="accordion-collapse collapse"
                                            data-bs-parent="#accordionFlushExample"
                                        >
                                            <div className="accordion-body">
                                            <SoftBox>
                                                <Grid item xs={12} sm={12} md={12} lg={12} display="flex" alignItems="center" justifyContent="space-between">
                                                    <Grid container spacing={2} >
                                                        <Grid item xs={12} sm={12} md={6} lg={3} >
                                                            <SoftTypography variant="p"  fontSize="14px" >Anlage voll funktionstüchtig:</SoftTypography>
                                                        </Grid>
                                                        <Grid item xs={12} sm={12} md={3} lg={1} >
                                                            <div className=" d-flex align-items-center">
                                                                <div className="form-check d-inline-block">
                                                                    <input
                                                                        type="checkbox"
                                                                        {...register("funktionstuchtig_ja")}

                                                                        className="form-check-input  ms-1"
                                                                        value={1}
                                                                    />
                                                                </div>
                                                                <label className="mt-2 uppercase" >Ja</label>
                                                            </div>
                                                            <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                                        </Grid>
                                                        <Grid item xs={12} sm={12} md={3} lg={1} >
                                                            <div className=" d-flex align-items-center">
                                                                <div className="form-check d-inline-block">
                                                                    <input
                                                                        type="checkbox"
                                                                        {...register("funktionstuchtig_nein")}

                                                                        className="form-check-input  ms-1"
                                                                        value={1}
                                                                    />
                                                                </div>
                                                                <label className="mt-2 uppercase" >Nein</label>
                                                            </div>
                                                            <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                                <div mt={2} style={{ marginBottom: '15px' }}>
                                                    Einstrahlung <input type="text" {...register("einstrahlung")} style={{ padding: '0.35rem 0.75rem', fontSize: '0.875rem', border: '1px solid #E7EBF3', transition: 'box-shadow 0.15s ease, border-color 0.15s ease', borderRadius: '0.5rem' }} />  W/m² (+/- 200 Wm²)
                                                </div>

                                                <div mt={2} style={{ marginBottom: '15px' }}>
                                                    Außentemperatur <input type="text" {...register("aubentemperatur")} style={{ padding: '0.35rem 0.75rem', fontSize: '0.875rem', border: '1px solid #E7EBF3', transition: 'box-shadow 0.15s ease, border-color 0.15s ease', borderRadius: '0.5rem' }} />  C° (+/- 5 Grad Celsius)
                                                </div>

                                                <div mt={2} style={{ marginBottom: '15px' }}>
                                                    Anlagenleistung DC <input type="text" {...register("anlagenleistung_dc")} style={{ padding: '0.35rem 0.75rem', fontSize: '0.875rem', border: '1px solid #E7EBF3', transition: 'box-shadow 0.15s ease, border-color 0.15s ease', borderRadius: '0.5rem' }} />  W (+/- 10% Anzeige Wechselrichter Display)
                                                </div>

                                                <div mt={2}>
                                                    Anlagenleistung AC <input type="text" {...register("anlagenleistung_ac")} style={{ padding: '0.35rem 0.75rem', fontSize: '0.875rem', border: '1px solid #E7EBF3', transition: 'box-shadow 0.15s ease, border-color 0.15s ease', borderRadius: '0.5rem' }} />  W (+/- 10% Anzeige Wechselrichter Display)
                                                </div>

                                                <SoftBox
                                                    display="flex"
                                                    flexDirection="column"
                                                    justifyContent="flex-end"
                                                    height="100%"
                                                >
                                                    <SoftBox mb={1} ml={0.5} mt={3} lineHeight={0} display="inline-block">
                                                        <SoftTypography component="label" variant="caption" >
                                                            {t('Sonstige Bemerkungen:')}
                                                        </SoftTypography>
                                                    </SoftBox>
                                                    {false ? (
                                                        <>
                                                            {descriptionPlausibilitatskontrolle}
                                                        </>
                                                    ) : (
                                                        <div className='card box-shadow-none'>
                                                            <div className='card-body p-0'>
                                                            <SoftEditor
                                                            value={descriptionPlausibilitatskontrolle}
                                                            onChange={setDescriptionPlausibilitatskontrolle}
                                                        />
                                                            </div>
                                                        </div>
                                                    )}
                                                </SoftBox>

                                            </SoftBox>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="accordion accordion-flush mt-3" id="accordionFlushExample">
                                <div className="accordion-item accordion-item bg-secondary p-3 border-radius-8">
                                    <h2 className="accordion-header">
                                        <button
                                            className="accordion-button collapsed"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#flush-collapseFour"
                                            aria-expanded="false"
                                            aria-controls="flush-collapseFour"
                                        >
                                            <span className="accordion-arrow">
                                                <i className="ph ph-caret-right"></i>
                                            </span>
                                            <p className="font-size-20 font-weight-600 text-primary">
                                                <span className="pl-2">{t('5. Messwerte')}</span>
                                            </p>
                                        </button>
                                    </h2>
                                    <div
                                        id="flush-collapseFour"
                                        className="accordion-collapse collapse"
                                        data-bs-parent="#accordionFlushExample"
                                    >
                                        <div className="accordion-body">
                                        <SoftBox  >
                                            <Grid item xs={12} sm={12} md={12} lg={12} display="flex" alignItems="center" justifyContent="space-between">
                                                <Grid container spacing={2} >

                                                    <Grid item xs={12} sm={12} md={3} lg={3} >
                                                        <div className=" d-flex align-items-center">
                                                            <div className="form-check d-inline-block" style={{ paddingLeft: '0px;' }}>
                                                                <input
                                                                    type="checkbox"
                                                                    {...register("kundeneigener")}

                                                                    className="form-check-input"
                                                                    value={1}
                                                                    style={{ paddingLeft: '0px;' }} />
                                                            </div>
                                                            <label className="mt-2 uppercase" >Kundeneigener Zähler</label>

                                                        </div>
                                                        <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                                    </Grid>

                                                    <Grid item xs={12} sm={12} md={3} lg={3} >
                                                        <div className=" d-flex align-items-center">
                                                            <div className="form-check d-inline-block" style={{ paddingLeft: '0px;' }}>
                                                                <input
                                                                    type="checkbox"
                                                                    {...register("netzbetreibers_messwerte")}

                                                                    className="form-check-input"
                                                                    value={1}
                                                                    style={{ paddingLeft: '0px;' }} />
                                                            </div>
                                                            <label className="mt-2 uppercase" >Zähler des Netzbetreibers</label>
                                                        </div>
                                                        <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                            <div style={{ marginBottom: '15px', marginTop: '8px;' }}>
                                                Zählernummer Einspeisezähler <input type="text" {...register("einspeisezahler")} style={{ padding: '0.35rem 0.75rem', fontSize: '0.875rem', border: '1px solid #E7EBF3', transition: 'box-shadow 0.15s ease, border-color 0.15s ease', borderRadius: '0.5rem' }} />  Zählerstand: <input type="text" {...register("einspeisezahler_zahlerstand")} style={{ padding: '0.35rem 0.75rem', fontSize: '0.875rem', border: '1px solid #E7EBF3', transition: 'box-shadow 0.15s ease, border-color 0.15s ease', borderRadius: '0.5rem' }} />  kWh
                                            </div>

                                            <div style={{ marginBottom: '15px' }}>
                                                Zählernummer PV-Bezugszähler: <input type="text" {...register("pvbezugszahler")} style={{ padding: '0.35rem 0.75rem', fontSize: '0.875rem', border: '1px solid #E7EBF3', transition: 'box-shadow 0.15s ease, border-color 0.15s ease', borderRadius: '0.5rem' }} />  Zählerstand: <input type="text" {...register("pvbezugszahler_zahlerstand")} style={{ padding: '0.35rem 0.75rem', fontSize: '0.875rem', border: '1px solid #E7EBF3', transition: 'box-shadow 0.15s ease, border-color 0.15s ease', borderRadius: '0.5rem' }} />  kWh
                                            </div>


                                            <div className='bg-white border-radius-5'>
                                                <table className="table table-bordered">
                                                    <tr>
                                                        <td style={{ border: '1px solid #eee', width: '33.33%', padding: '5px' }}>
                                                            <strong >Wechselrichter</strong>
                                                            <p style={{ padding: '0px;', margin: '0px' }}>(Typ/Seriennr.)</p>
                                                        </td>
                                                        <td style={{ border: '1px solid #eee', width: '33.33%', padding: '5px' }}>
                                                            <strong >Leerlaufspannung UL in V</strong>
                                                            <p style={{ padding: '0px;', margin: '0px' }}>(Generator / Strang 1/2/3)</p>
                                                        </td>
                                                        <td style={{ border: '1px solid #eee', width: '33.33%', padding: '5px' }}>
                                                            <strong >Kurzschlussstrom Ik in A</strong>
                                                            <p style={{ padding: '0px;', margin: '0px' }}>(Strang 1 / Strang 2 / Strang 3 …)</p>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ border: '1px solid #eee', width: '33.33%', padding: '5px' }}>
                                                            <input type="text" {...register("wechselrichter_1")} className="form-control" />
                                                        </td>
                                                        <td style={{ border: '1px solid #eee', width: '33.33%', padding: '5px' }}>
                                                            <input type="text" {...register("leerlaufspannung_1")} className="form-control" />
                                                        </td>
                                                        <td style={{ border: '1px solid #eee', width: '33.33%', padding: '5px' }}>
                                                            <input type="text" {...register("kurzs_1")} className="form-control" />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ border: '1px solid #eee', width: '33.33%', padding: '5px' }}>
                                                            <input type="text" {...register("wechselrichter_2")} className="form-control" />
                                                        </td>
                                                        <td style={{ border: '1px solid #eee', width: '33.33%', padding: '5px' }}>
                                                            <input type="text" {...register("leerlaufspannung_2")} className="form-control" />
                                                        </td>
                                                        <td style={{ border: '1px solid #eee', width: '33.33%', padding: '5px' }}>
                                                            <input type="text" {...register("kurzs_2")} className="form-control" />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ border: '1px solid #eee', width: '33.33%', padding: '5px' }}>
                                                            <input type="text" {...register("wechselrichter_3")} className="form-control" />
                                                        </td>
                                                        <td style={{ border: '1px solid #eee', width: '33.33%', padding: '5px' }}>
                                                            <input type="text" {...register("leerlaufspannung_3")} className="form-control" />
                                                        </td>
                                                        <td style={{ border: '1px solid #eee', width: '33.33%', padding: '5px' }}>
                                                            <input type="text" {...register("kurzs_3")} className="form-control" />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ border: '1px solid #eee', width: '33.33%', padding: '5px' }}>
                                                            <input type="text" {...register("wechselrichter_4")} className="form-control" />
                                                        </td>
                                                        <td style={{ border: '1px solid #eee', width: '33.33%', padding: '5px' }}>
                                                            <input type="text" {...register("leerlaufspannung_4")} className="form-control" />
                                                        </td>
                                                        <td style={{ border: '1px solid #eee', width: '33.33%', padding: '5px' }}>
                                                            <input type="text" {...register("kurzs_4")} className="form-control" />
                                                        </td>
                                                    </tr>
                                                </table>
                                            </div>

                                            <Grid item xs={12} sm={12} md={12} lg={12} display="flex" alignItems="center" justifyContent="space-between">
                                                <Grid container spacing={2} >
                                                    <Grid item xs={12} sm={12} md={6} lg={3} >
                                                        <SoftTypography variant="p"  fontSize="14px" >Zusatzblätter beigefügt</SoftTypography>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={3} lg={1} >
                                                        <div className=" d-flex align-items-center">
                                                            <div className="form-check d-inline-block">
                                                                <input
                                                                    type="checkbox"
                                                                    {...register("zusatzblatter_ja")}

                                                                    className="form-check-input  ms-1"
                                                                    value={1}
                                                                />
                                                            </div>
                                                            <label className="mt-2 uppercase" >Ja</label>
                                                        </div>
                                                        <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={3} lg={1} >
                                                        <div className=" d-flex align-items-center">
                                                            <div className="form-check d-inline-block">
                                                                <input
                                                                    type="checkbox"
                                                                    {...register("zusatzblatter_nein")}

                                                                    className="form-check-input  ms-1"
                                                                    value={1}
                                                                />
                                                            </div>
                                                            <label className="mt-2 uppercase" >Nein</label>
                                                        </div>
                                                        <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                        </SoftBox>
                                        </div>
                                    </div>
                                </div>
                        </div>


                                        <div className="accordion accordion-flush mt-3" id="accordionFlushExample">
                                            <div className="accordion-item accordion-item bg-secondary p-3 border-radius-8">
                                                <h2 className="accordion-header">
                                                    <button
                                                        className="accordion-button collapsed"
                                                        type="button"
                                                        data-bs-toggle="collapse"
                                                        data-bs-target="#flush-collapseFive"
                                                        aria-expanded="false"
                                                        aria-controls="flush-collapseFive"
                                                    >
                                                        <span className="accordion-arrow">
                                                            <i className="ph ph-caret-right"></i>
                                                        </span>
                                                        <p className="font-size-20 font-weight-600 text-primary">
                                                            <span className="pl-2">Mängel, Nachbesserungen, sonstige Bemerkungen</span>
                                                        </p>
                                                    </button>
                                                </h2>
                                                <div
                                                    id="flush-collapseFive"
                                                    className="accordion-collapse collapse"
                                                    data-bs-parent="#accordionFlushExample"
                                                >
                                                    <div className="accordion-body">
                                                    <SoftBox >
                                                        <SoftBox
                                                            display="flex"
                                                            flexDirection="column"
                                                            justifyContent="flex-end"
                                                            height="100%"
                                                        >
                                                            <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                                                <SoftTypography component="label" variant="caption" >
                                                                    {t('unstrittige Mängel, strittige Mängel und evtl. auszuführende Nachbesserungen sind als solche gekennzeichnet aufzuzählen und mit Ausführungsfristen zu versehen:')}
                                                                </SoftTypography>
                                                            </SoftBox>
                                                            {false ? (
                                                                <>
                                                                    {descriptionMangel}
                                                                </>
                                                            ) : (
                                                                <div className='card box-shadow-none'>
                                                                    <div className='card-body p-0'>
                                                                        <SoftEditor
                                                                            value={descriptionMangel}
                                                                            onChange={setDescriptionMangel}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </SoftBox>
                                                    </SoftBox>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>                 


                                        <div className="accordion accordion-flush mt-3" id="accordionFlushExample">
                                            <div className="accordion-item accordion-item bg-secondary p-3 border-radius-8">
                                                <h2 className="accordion-header">
                                                    <button
                                                        className="accordion-button collapsed"
                                                        type="button"
                                                        data-bs-toggle="collapse"
                                                        data-bs-target="#flush-collapseSix"
                                                        aria-expanded="false"
                                                        aria-controls="flush-collapseSix"
                                                    >
                                                        <span className="accordion-arrow">
                                                            <i className="ph ph-caret-right"></i>
                                                        </span>
                                                        <p className="font-size-20 font-weight-600 text-primary">
                                                            <span className="pl-2">{t('7. Dokumentation ')}</span>
                                                        </p>
                                                    </button>
                                                </h2>
                                                <div
                                                    id="flush-collapseSix"
                                                    className="accordion-collapse collapse"
                                                    data-bs-parent="#accordionFlushExample"
                                                >
                                                    <div className="accordion-body">
                                                    <SoftBox>
                                                        <Grid item xs={12} sm={12} md={12} lg={12} display="flex" alignItems="center" justifyContent="space-between">
                                                            <Grid container spacing={2} >
                                                                <Grid item xs={12} sm={12} md={6} lg={4} >
                                                                    <SoftTypography variant="p"  fontSize="14px" >Anlagendokumentation liegt vollständig vor:</SoftTypography>
                                                                </Grid>
                                                                <Grid item xs={12} sm={12} md={3} lg={1} >
                                                                    <div className=" d-flex align-items-center">
                                                                        <div className="form-check d-inline-block">
                                                                            <input
                                                                                type="checkbox"
                                                                                {...register("anlagendokumentation_ja")}

                                                                                className="form-check-input  ms-1"
                                                                                value={1}
                                                                            />
                                                                        </div>
                                                                        <label className="mt-2 uppercase" >Ja</label>

                                                                    </div>
                                                                    <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                                                </Grid>
                                                                <Grid item xs={12} sm={12} md={3} lg={1} >
                                                                    <div className=" d-flex align-items-center">
                                                                        <div className="form-check d-inline-block">
                                                                            <input
                                                                                type="checkbox"
                                                                                {...register("anlagendokumentation_nein")}

                                                                                className="form-check-input  ms-1"
                                                                                value={1}
                                                                            />
                                                                        </div>
                                                                        <label className="mt-2 uppercase" >Nein</label>
                                                                    </div>
                                                                    <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid item xs={12} sm={12} md={12} lg={12} display="flex" alignItems="center" justifyContent="space-between">
                                                            <Grid container spacing={2} >
                                                                <Grid item xs={12} sm={12} md={12} lg={12} >
                                                                    <div className=" d-flex align-items-center">
                                                                        <div className="form-check d-inline-block">
                                                                            <input
                                                                                type="checkbox"
                                                                                {...register("konformit")}

                                                                                className="form-check-input  ms-1"
                                                                                value={1}
                                                                            />
                                                                        </div>
                                                                        <label className="uppercase" style={{ marginTop: '12px' }}> Konformitätserklärung</label>
                                                                    </div>
                                                                    <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                                                </Grid>

                                                                <Grid item xs={12} sm={12} md={12} lg={12} style={{ marginTop: '-25px' }}>
                                                                    <div className=" d-flex align-items-center">
                                                                        <div className="form-check d-inline-block">
                                                                            <input
                                                                                type="checkbox"
                                                                                {...register("nnbedenklichkeit")}

                                                                                className="form-check-input  ms-1"
                                                                                value={1}
                                                                            />
                                                                        </div>
                                                                        <label className="uppercase" style={{ marginTop: '12px' }}> Unbedenklichkeitsbescheinigungen</label>
                                                                    </div>
                                                                    <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                                                </Grid>

                                                                <Grid item xs={12} sm={12} md={12} lg={12} style={{ marginTop: '-25px' }}>
                                                                    <div className=" d-flex align-items-center">
                                                                        <div className="form-check d-inline-block">
                                                                            <input
                                                                                type="checkbox"
                                                                                {...register("bescheinigung")}

                                                                                className="form-check-input  ms-1"
                                                                                value={1}
                                                                            />
                                                                        </div>
                                                                        <label className="uppercase" style={{ marginTop: '12px' }}>Bescheinigung zur selbsttätigen Freischaltstelle nach VDE 0126 Teil 1-1 </label>
                                                                    </div>
                                                                    <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                                                </Grid>

                                                                <Grid item xs={12} sm={12} md={12} lg={12} style={{ marginTop: '-25px' }}>
                                                                    <div className=" d-flex align-items-center">
                                                                        <div className="form-check d-inline-block">
                                                                            <input
                                                                                type="checkbox"
                                                                                {...register("technische")}

                                                                                className="form-check-input  ms-1"
                                                                                value={1}
                                                                            />
                                                                        </div>
                                                                        <label className="uppercase" style={{ marginTop: '12px' }}> Technische Unterlagen und Datenblätter der wesentlichen Komponente</label>
                                                                    </div>
                                                                    <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                                                </Grid>

                                                                <Grid item xs={12} sm={12} md={12} lg={12} style={{ marginTop: '-25px' }}>
                                                                    <div className=" d-flex align-items-center">
                                                                        <div className="form-check d-inline-block">
                                                                            <input
                                                                                type="checkbox"
                                                                                {...register("messprotokolle")}

                                                                                className="form-check-input  ms-1"
                                                                                value={1}
                                                                            />
                                                                        </div>
                                                                        <label className="uppercase" style={{ marginTop: '12px' }}> Messprotokolle (so vorhanden)</label>
                                                                    </div>
                                                                    <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                                                </Grid>

                                                                <Grid item xs={12} sm={12} md={12} lg={12} style={{ marginTop: '-25px' }}>
                                                                    <div className=" d-flex align-items-center">
                                                                        <div className="form-check d-inline-block">
                                                                            <input
                                                                                type="checkbox"
                                                                                {...register("seriennummern")}

                                                                                className="form-check-input  ms-1"
                                                                                value={1}
                                                                            />
                                                                        </div>
                                                                        <label className="uppercase" style={{ marginTop: '12px' }}>Seriennummern der installierten Module </label>
                                                                    </div>
                                                                    <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                                                </Grid>

                                                                <Grid item xs={12} sm={12} md={12} lg={12} style={{ marginTop: '-25px' }}>
                                                                    <div className=" d-flex align-items-center">
                                                                        <div className="form-check d-inline-block">
                                                                            <input
                                                                                type="checkbox"
                                                                                {...register("zertifikate")}

                                                                                className="form-check-input  ms-1"
                                                                                value={1}
                                                                            />
                                                                        </div>
                                                                        <label className="uppercase" style={{ marginTop: '12px' }}>Zertifikate und Garantiebescheinigungen </label>
                                                                    </div>
                                                                    <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                                                </Grid>

                                                                <Grid item xs={12} sm={12} md={12} lg={12} style={{ marginTop: '-25px' }}>
                                                                    <div className=" d-flex align-items-center">
                                                                        <div className="form-check d-inline-block">
                                                                            <input
                                                                                type="checkbox"
                                                                                {...register("verschaltungsplan")}

                                                                                className="form-check-input  ms-1"
                                                                                value={1}
                                                                            />
                                                                        </div>
                                                                        <label className="uppercase" style={{ marginTop: '12px' }}>Verschaltungsplan und Dachplan mit Modulverschattung und
                                                                            Wechselrichterzuordnung </label>
                                                                    </div>
                                                                    <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                                                </Grid>

                                                                <Grid item xs={12} sm={12} md={12} lg={12} style={{ marginTop: '-25px' }}>
                                                                    <div className=" d-flex align-items-center">
                                                                        <div className="form-check d-inline-block">
                                                                            <input
                                                                                type="checkbox"
                                                                                {...register("betriebsanleitung")}

                                                                                className="form-check-input  ms-1"
                                                                                value={1}
                                                                            />
                                                                        </div>
                                                                        <label className="uppercase" style={{ marginTop: '12px' }}> Betriebsanleitung insb. Wechselrichter</label>
                                                                    </div>
                                                                    <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                                                </Grid>

                                                                <Grid item xs={12} sm={12} md={12} lg={12} style={{ marginTop: '-25px' }}>
                                                                    <div className=" d-flex align-items-center">
                                                                        <div className="form-check d-inline-block">
                                                                            <input
                                                                                type="checkbox"
                                                                                {...register("montageanleitungen")}

                                                                                className="form-check-input  ms-1"
                                                                                value={1}
                                                                            />
                                                                        </div>
                                                                        <label className="uppercase" style={{ marginTop: '12px' }}> Montageanleitungen der wesentlichen Komponenten</label>
                                                                    </div>
                                                                    <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                                                </Grid>

                                                                <Grid item xs={12} sm={12} md={12} lg={12} style={{ marginTop: '-25px' }}>
                                                                    <div className=" d-flex align-items-center">
                                                                        <div className="form-check d-inline-block">
                                                                            <input
                                                                                type="checkbox"
                                                                                {...register("netzbetreibers")}

                                                                                className="form-check-input  ms-1"
                                                                                value={1}
                                                                            />
                                                                        </div>
                                                                        <label className="uppercase" style={{ marginTop: '12px' }}>Inbetriebnahmeprotokoll des Netzbetreibers </label>
                                                                    </div>
                                                                    <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                                                </Grid>

                                                                <Grid item xs={12} sm={12} md={12} lg={12} style={{ marginTop: '-25px' }}>
                                                                    <div className=" d-flex align-items-center">
                                                                        <div className="form-check d-inline-block">
                                                                            <input
                                                                                type="checkbox"
                                                                                {...register("service_bzw")}

                                                                                className="form-check-input  ms-1"
                                                                                value={1}
                                                                            />
                                                                        </div>
                                                                        <label className="uppercase" style={{ marginTop: '12px' }}>Service bzw. Notfalltelefonnummern</label>
                                                                    </div>
                                                                    <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                                                </Grid>
                                                                <Grid item xs={12} sm={12} md={12} lg={12} style={{ marginTop: '0px' }}>
                                                                    <SoftBox
                                                                        display="flex"
                                                                        flexDirection="column"
                                                                        justifyContent="flex-end"
                                                                        height="100%"
                                                                    >
                                                                        <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                                                            <SoftTypography component="label" variant="caption" >
                                                                                {t('Sonstige Bemerkungen:')}
                                                                            </SoftTypography>
                                                                        </SoftBox>
                                                                        {false ? (
                                                                            <>
                                                                                {descriptionDokumentation}
                                                                            </>
                                                                        ) : (
                                                                            <div className='card box-shadow-none'>
                                                                                <div className='card-body p-0'>
                                                                                    <SoftEditor
                                                                                        value={descriptionDokumentation}
                                                                                        onChange={setDescriptionDokumentation}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                    </SoftBox>

                                                                </Grid>
 

                                                                <Grid item xs={12} sm={12} md={12} lg={12} style={{ marginTop: '0px' }}>
                                                                    <SoftBox >
                                                                        <SoftBox style={{ marginBottom: '5px' }}>
                                                                            <SoftTypography variant="h5">{t('8. Kundeneinweisung ')}</SoftTypography>
                                                                        </SoftBox>

                                                                        <SoftTypography style={{ marginBottom: '14px' }} component="label" variant="caption" fontWeight="">
                                                                            {t('Eine Einweisung für den Kunden hat stattgefunden')}
                                                                        </SoftTypography>

                                                                        <SoftBox
                                                                            display="flex"
                                                                            flexDirection="column"
                                                                            justifyContent="flex-end"
                                                                            height="100%"
                                                                        >
                                                                            <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                                                                <SoftTypography component="label" variant="caption" fontWeight="500">
                                                                                    {t('Sonstige Bemerkungen:')}
                                                                                </SoftTypography>
                                                                            </SoftBox>
                                                                            {false ? (
                                                                                <>
                                                                                    {descriptionKundeneinweisung}
                                                                                </>
                                                                            ) : (
                                                                                <div className='card box-shadow-none'>
                                                                                    <div className='card-body p-0'>
                                                                                        <SoftEditor
                                                                                            value={descriptionKundeneinweisung}
                                                                                            onChange={setDescriptionKundeneinweisung}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                        </SoftBox>
                                                                    </SoftBox>
                                                                </Grid>


                                                            </Grid>
                                                        </Grid>
                                                    </SoftBox>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="accordion accordion-flush mt-3" id="accordionFlushExample">
                                            <div className="accordion-item accordion-item bg-secondary p-3 border-radius-8">
                                                <h2 className="accordion-header">
                                                    <button
                                                        className="accordion-button collapsed"
                                                        type="button"
                                                        data-bs-toggle="collapse"
                                                        data-bs-target="#flush-collapseSeven"
                                                        aria-expanded="false"
                                                        aria-controls="flush-collapseSeven"
                                                    >
                                                        <span className="accordion-arrow">
                                                            <i className="ph ph-caret-right"></i>
                                                        </span>
                                                        <p className="font-size-20 font-weight-600 text-primary">
                                                            <span className="pl-2">{t('Files')}</span>
                                                        </p>
                                                    </button>
                                                </h2>
                                                <div
                                                    id="flush-collapseSeven"
                                                    className="accordion-collapse collapse"
                                                    data-bs-parent="#accordionFlushExample"
                                                >
                                                    <div className="accordion-body">
                                                        <SoftBox pt={2}>
                                                            <DropZoneTabs
                                                                tabs={dropZoneTabs}
                                                                defaultFiles={tabFiles}
                                                                defaultTab={'wechselrichter_und_umgebung'}
                                                                callBackData={setCallBackData}
                                                                dropZonValidationError={dropZonValidationError}
                                                            />
                                                        </SoftBox>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion accordion-flush mt-3" id="accordionFlushExample">
                                                <div className="accordion-item accordion-item bg-secondary p-3 border-radius-8">
                                                    <h2 className="accordion-header">
                                                        <button
                                                            className="accordion-button collapsed"
                                                            type="button"
                                                            data-bs-toggle="collapse"
                                                            data-bs-target="#flush-collapseEight"
                                                            aria-expanded="false"
                                                            aria-controls="flush-collapseEight"
                                                        >
                                                            <span className="accordion-arrow">
                                                                <i className="ph ph-caret-right"></i>
                                                            </span>
                                                            <p className="font-size-20 font-weight-600 text-primary">
                                                                <span className="pl-2">PDF/Sign</span>
                                                            </p>
                                                        </button>
                                                    </h2>
                                                    <div
                                                        id="flush-collapseEight"
                                                        className="accordion-collapse collapse"
                                                        data-bs-parent="#accordionFlushExample"
                                                    >
                                                        <div className="accordion-body">
                                                         <ACabnahmeSign saveBtnDisplay={false} flashLoader={false} pdfDisplay={true} project_id={params?.id} id={id} />
                                                        </div>
                                                    </div>
                                                </div>
                                        </div>

                            </div>
                        </div> 
 

                    

                        

                        <SoftBox p={2} display="flex">
                            {JSON.parse(Cookies.get('permissions')).indexOf("acAbhU") !== -1 ? (
                                <>
                                    {!saving && (
                                        <button type="submit" id="acAbname" className="btn btn-primary">
                                            {t('Save')}
                                        </button>
                                    )}
                                    {saving && (
                                        <button type="submit" className="btn btn-disabled" disabled>
                                            {t('Saving ...')}
                                        </button>
                                    )}
                                </>
                            ) : <></>}

                            <a target='_blank' className="btn btn-primary mx-2" href={Cookies.get('backend_url') + 'acabname/' + (id ? id : 'empty')}>{t('PDF')}</a>
                        </SoftBox>
                    </form>

                </div> 
            </div>


        </>
    )
}

export default ACAbnahmeprotokoll
