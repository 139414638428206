import { useMemo, useEffect, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import callFetch from "helpers/callFetch";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftEditor from "components/SoftEditor";

import Cookies from "js-cookie";
import "react-toastify/dist/ReactToastify.css";
import SignaturePad from "react-signature-pad-wrapper"; 
import SoftSnackbar from "components/SoftSnackbar";
import ComisioningModal from "../ComisioningModal";
import DropZoneTabs from "components/DropZoneTabs/Index";
import Overview from "../../Overview";
import DCabnahmeSign from "../DCabnahmeSign";

const DCAbnahmeprotokoll = (props) => {
  const { t } = useTranslation();
  const [message, setMessage] = useState("Success");
  const [color, setColor] = useState("success");
  const params = useParams();
  const user = JSON.parse(Cookies.get("user"));
  const signaturePadRef = useRef(null);
  const submitBtn = useRef();
  const [saving, setSaving] = useState(false);
  const [modules, setModules] = useState([]);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const [editDelay, setEditDelay] = useState(0);
  const [refresh2, setRefresh2] = useState(0);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [description, setDescription] = useState("");
  const [descriptionErdung, setDescriptionErdung] = useState("");
  const [attachments, setAttachment] = useState([]);
  const [data, setData] = useState([]);
  const [link, setLink] = useState(null);
  const [id, setID] = useState(0);
  const [newDropzone, setNewDropzone] = useState(true);
  const [editForm, setEditForm] = useState(false);
  const [tabFiles, setTabFiles] = useState({});
  const [strgItemRefresh, setStrgItemRefresh] = useState(0);
  const [strgItem, setStrgItem] = useState({ strg_1: "" });
  const [readOnly, setReadOnly] = useState(false);
  const [dropZoneTabs, setDropZoneTabs] = useState([
    {
      name: "ziegelbearbeitungMontageDachhaken",
      titel: "Kabelverlegung unter Ziegel",
      require: true,
    },
    {
      name: "montierteDachhaken",
      titel: "Montierte Dachhaken",
      require: true,
    },
    {
      name: "uKAllerGeneratorflachen",
      titel: "UK aller Flächen",
      require: true,
    },
    {
      name: "erdungGesUnterkonstruktion",
      titel: "Erdung ges. Unterkonstruktion",
      require: true,
    },
    {
      name: "dCKabelverlegung",
      titel: "DC Kabelverlegung",
      require: true,
    },
    {
      name: "abdichtungKabeleintritt",
      titel: "Abdichtung Kabeleintritt",
      require: true,
    },
    {
      name: "zwischenraumDerModuleUndZiegel",
      titel: "Zwischenraum der Module und Ziegel",
      require: true,
    },
    {
      name: "generatorenflachenAllerDachflachen",
      titel: "Fertige Montage Fotos aller Dachflächen",
      require: true,
    },
    {
      name: "typenschildDerVerbautenModule",
      titel: "Typenschild der verbauten Module",
      require: true,
    },
    {
      name: "stringplanEntGeneratorenflache",
      titel: "Stringplan verbauter Flächen",
      require: true,
    },
    {
      name: "dCUberstannungsschutz",
      titel: "DC Überstannungsschutz",
      require: true,
    },
  ]);

  const [dropZonValidationError, setDropZonValidationError] = useState("");
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [activeSpike, setActiveSpike] = useState(false);

  const handleCheckboxChange = () => {
    setIsCheckboxChecked(!isCheckboxChecked);
    //console.log(isCheckboxChecked);
  };

  const handleCheckboxChangeTwo = (name) => {
    if(name == 'pot_schine'){
      setValue('pot_schine', 1);
      setValue('erdspieß', 0);
      setActiveSpike(1);
    }else{
      setValue('pot_schine', 0);
      setValue('erdspieß', 1);
      setActiveSpike(2);
    }
  };

  const [successSB, setSuccessSB] = useState(false);
  const openSuccessSB = () => setSuccessSB(true);
  const closeSuccessSB = () => setSuccessSB(false);
  const renderSuccessSB = (
    <SoftSnackbar
      color={color}
      icon="check"
      title={t(message)}
      //content={t("Changes saved successfully")}
      dateTime={t("")}
      open={successSB}
      onClose={closeSuccessSB}
      close={closeSuccessSB}
      bgSuccess
    />
  );

  const {
    register,
    handleSubmit,
    setError,
    setValue,
    getValues,
    reset,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (JSON.parse(Cookies.get("permissions")).indexOf("dabhU") == -1) {
      setReadOnly(true);
    }

    callFetch("module/all", "GET", []).then((res) => {
      setModules(res.data);
    });
  }, [0]);

  useEffect(() => {
    if (props.project_id !== undefined) {
      callFetch(
        "projectdistribution/" +
          props.project_id +
          "/edit?project_id=" +
          props.project_id,
        "GET",
        []
      ).then((res) => {
        setValue("modultyp", res?.data?.quations?.modul);
        setValue("modulanzahl", res?.data?.quations?.anzahl_module);
        setEditDelay(1);
      });

      callFetch(
        "dc_abnahmeprotokoll/" + props.project_id + "/edit",
        "GET",
        []
      ).then((res) => {
        if (res?.data?.dcabname) {
          setTabFiles(JSON.parse(res?.data?.dcabname?.filesdata));

          setID(res?.data?.dcabname?.id);
          var payload = res?.data?.dcabname?.payload
            ? typeof res?.data?.dcabname?.payload === "string"
              ? JSON.parse(res?.data?.dcabname?.payload)
              : res?.data?.dcabname?.payload
            : {};

          setStrgItem(JSON.parse(res?.data?.dcabname?.strg));

          setDescription(
            payload?.description && payload?.description != "null"
              ? payload?.description
              : ""
          );
          setDescriptionErdung(
            payload?.description_erdung && payload?.description_erdung != "null"
              ? payload?.description_erdung
              : ""
          );

          if (JSON.parse(Cookies.get("permissions")).indexOf("dabhU") !== -1) {
            signaturePadRef.current.fromDataURL(res?.data?.dcabname?.drawing);
          }

          setValue("modultyp", res?.data?.dcabname?.modultyp);
          setValue("modulanzahl", res?.data?.dcabname.modulanzahl);
          setLink(res?.data?.pdf);
          for (let [key, value] of Object.entries(res?.data?.dcabname)) {
            if (key == "payload") {
              for (let [key, value] of Object.entries(payload)) {
                //console.log(key+': '+(value === 'false' ? 0 : value === 'true' ? 1 : value))
                setValue(
                  key,
                  value == null || value == "null"
                    ? ""
                    : value === "false" || value === "0"
                    ? 0
                    : value === "true" || value === "1"
                    ? 1
                    : value
                );
              }
            } else if (key == "filesdata") {
              setTabFiles(JSON.parse(value));
            } else if (key == "strg") {
              var strg = JSON.parse(value);
              for (let [strg_key, strg_value] of Object.entries(strg)) {
                setValue("strg[" + strg_key + "]", strg_value);
              }
            } else if (key == "volt") {
              var volt = JSON.parse(value);
              for (let [volt_key, volt_value] of Object.entries(volt)) {
                setValue("volt[" + volt_key + "]", volt_value);
              }
            } else {
              setValue(key, value == null || value == "null" ? "" : value);
            }
          }
          setStrgItemRefresh(strgItemRefresh + 1);
        }
      });
    }
  }, [refresh]);

  const setCallBackData = (data) => {
    setTabFiles(data);

    setDropZonValidationError("");
    for (let [key, dropZoneTab] of Object.entries(dropZoneTabs)) {
      if (
        dropZoneTab?.require === true &&
        (!data[dropZoneTab?.name] || data[dropZoneTab?.name]?.length <= 0)
      ) {
        setDropZonValidationError(dropZoneTab?.titel + " is required");
        return;
      }
    }
  };

  const onSubmit = (formData) => {
    if (readOnly) return;

    setDropZonValidationError("");
    for (let [key, dropZoneTab] of Object.entries(dropZoneTabs)) {
      if (
        dropZoneTab?.require === true &&
        (!tabFiles[dropZoneTab?.name] ||
          tabFiles[dropZoneTab?.name]?.length <= 0)
      ) {
        setDropZonValidationError(dropZoneTab?.titel + " is required");
        return;
      }
    }

    setSaving(true);

    var loadingElement = document.querySelector(
      ".res-options .btn-primary-save"
    );
    loadingElement.classList.add("btn-secondary");
    loadingElement.innerHTML = t("wird geladen...");

    formData.description = description;
    formData.description_erdung = descriptionErdung;
    formData.sign = signaturePadRef.current.toDataURL("image/png");
    formData.filesdata = JSON.stringify(tabFiles);
    formData.strg = JSON.stringify(getValues("strg"));
    formData.volt = JSON.stringify(getValues("volt"));

    if (id == 0) {
      formData.project_id = props.project_id;
      formData.eidt_id = Cookies.get("dc_pdf");
    }

    //   Cookies.remove('dc_pdf')
    if (signaturePadRef.current.isEmpty()) {
      setColor("error");
      setMessage("Fill Out Skizze/Modulbelegung");
      setTimeout(() => {
        setSaving(false);

        loadingElement.classList.remove("btn-secondary");
        loadingElement.innerHTML = t("Save");

        openSuccessSB();
      }, 2000);
      return;
    }

    callFetch(
      id == 0 ? "dc_abnahmeprotokoll" : "dc_abnahmeprotokoll/" + id,
      "POST",
      formData,
      setError
    ).then((res) => {
      setData(res);
      setLink(res?.data?.pdf);
      setID(res?.data?.id);

      setColor("success");
      setMessage("Success");

      setTimeout(() => {
        document.getElementById("saveSign").click();
      }, 2000);

      setTimeout(() => {
        setSaving(false);
        openSuccessSB();

        loadingElement.classList.remove("btn-secondary");
        loadingElement.innerHTML = t("Save");

        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        document.getElementById("main-content").scrollTop = 0;

        /*
                if (id == 0) {
                    document.getElementById('ComisioningModalOpen').click();
                }
                    */

        if (!res.ok) return;
        setSubmitSuccess(true);
        setRefresh(refresh + 1);
        clearForm();
      }, 2000);
    });
  };
  const clearForm = () => {
    reset({
      id: 0,
      title: "",
      status: "",
      description: "",
      attachments: JSON.stringify([]),
    });

    setNewDropzone(true);
    setEditForm(false);
    setAttachment([]);
    setDescription("");
  };

  return (
    <>
      <div className="row">
        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
          {/* <Overview
            className="mb-3"
            bg="white"
            project_id={params?.id}
            title={t("DC Abnahmeprotokoll")}
          ></Overview> */}

          <form
            className={`needs-validation ${
              Object.keys(errors).length ? "was-validated" : ""
            }`}
            onSubmit={handleSubmit(onSubmit)}
            noValidate
            autoComplete="off"
          >
            <div className="card">
              <div className="card-body pb-3">
                <div
                  className="accordion accordion-flush"
                  id="accordionFlushExample"
                >
                  <div className="accordion-item accordion-item bg-secondary p-3 border-radius-8">
                    <h2 className="accordion-header">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseOne"
                        aria-expanded="false"
                        aria-controls="flush-collapseOne"
                      >
                        <span className="accordion-arrow">
                          <i className="ph ph-caret-right"></i>
                        </span>
                        <p className="font-size-20 font-weight-600 text-primary">
                          <span className="pl-2">DC Abnahmeprotokoll</span>
                        </p>
                      </button>
                    </h2>
                    <div
                      id="flush-collapseOne"
                      className="accordion-collapse collapse"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div className="accordion-body">
                        <>
                          {link !== null && (
                            <a
                              className="opacity-0"
                              target={"_blank"}
                              id="dc_abnahmeprotokoll"
                              href={link && link}
                            ></a>
                          )}

                          <input type="hidden" id="dcid" value={id} />

                          {id > 0 ? (
                            <input
                              type="hidden"
                              defaultValue="PUT"
                              {...register("_method")}
                            />
                          ) : (
                            ""
                          )}

                          <div sx={{ overflow: "visible" }}>
                            <SoftBox lineHeight={1}>
                              <Grid 
                                container
                                spacing={2}
                                className="mb-0"
                              >
                                {Object.keys(strgItem).map(function (
                                  keyName,
                                  index
                                ) {
                                  return (
                                    <>
                                      <Grid item xs={6} sm={6} md={6} lg={6}>
                                        <label className="mb-1">Strg {index + 1}</label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder={"Strg " + (index + 1)}
                                          {...register(
                                            "strg[" + keyName + "]",
                                            {
                                              required: true,
                                            }
                                          )}
                                          required
                                          readOnly={readOnly}
                                        />
                                      </Grid>

                                      <Grid item xs={6} sm={6} md={6} lg={6}>
                                        <label className="mb-1">Volt {index + 1}</label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder={"Volt " + (index + 1)}
                                          {...register(
                                            "volt[" + keyName + "]",
                                            {
                                              required: true,
                                            }
                                          )}
                                          required
                                          readOnly={readOnly}
                                        />
                                      </Grid>
                                    </>
                                  );
                                })}
                                {JSON.parse(Cookies.get("permissions")).indexOf(
                                  "dabhU"
                                ) !== -1 ? (
                                  <Grid
                                    item
                                    xs={6}
                                    sm={6}
                                    md={2}
                                    lg={2}
                                    className="p-0"
                                  >
                                    <a
                                      className="btn btn-link mb-0"
                                      onClick={() => {
                                        var strgItems = strgItem;
                                        strgItems[
                                          "strg_" +
                                            (Object.keys(strgItem).length + 1)
                                        ] = "";
                                        setStrgItem(strgItems);
                                        setStrgItemRefresh(strgItemRefresh + 1);
                                      }}
                                    >
                                      <i class="ph ph-plus"></i>
                                      {t("Add")}
                                    </a>
                                  </Grid>
                                ) : (
                                  ""
                                )}
                              </Grid>

                              <Grid
                                container
                                spacing={2}
                                className="mt-0 mb-0"
                              >
                                <Grid
                                  item
                                  xs={12}
                                  sm={12}
                                  md={6}
                                  lg={6}
                                  className="mt-0 pt-0"
                                >
                                  <label className="mb-1">Modultyp</label>
                                  <select
                                    readOnly={readOnly}
                                    type="text"
                                    className=" form-control"
                                    {...register("modultyp", {
                                      required: true,
                                    })}
                                    required
                                  >
                                    <option>--</option>
                                    {modules &&
                                      modules.map((modules, index) => {
                                        return (
                                          <option
                                            key={index}
                                            value={modules.id}
                                          >
                                            {modules.name}
                                          </option>
                                        );
                                      })}
                                  </select>
                                  <div className="invalid-feedback">
                                    {errors.modultyp && errors.modultyp.message}
                                  </div>
                                </Grid>

                                <Grid
                                  item
                                  xs={12}
                                  sm={12}
                                  md={6}
                                  lg={6}
                                  className="mt-0 pt-0"
                                >
                                  <label className="mb-1">Modulanzahl</label>
                                  <input
                                    readOnly={readOnly}
                                    type="number"
                                    className=" form-control"
                                    placeholder="Modulanzahl"
                                    {...register("modulanzahl", {
                                      required: true,
                                    })}
                                    required
                                  />
                                  <div className="invalid-feedback">
                                    {errors.modulanzahl &&
                                      errors.modulanzahl.message}
                                  </div>
                                </Grid>
                              </Grid>

                              <Grid
                                my={2}
                                container
                                spacing={2}
                                className="mt-0 mb-0"
                              >
                                <Grid
                                  item
                                  xs={12}
                                  sm={12}
                                  md={6}
                                  lg={6}
                                  className="mt-0"
                                >
                                  <label className="mb-1">ErdungsspieB gesetzt?</label>
                                  {/* <input type="text" className=" form-control" placeholder="Erdungsanschluss" {...register('erdungsanschluss',{required: true})} required /> */}
                                  <div className="d-flex justify-content-between border-primary p-1 simple-tab border-radius-5 bg-white">
                                    <button
                                      type="button"
                                      onClick={() => handleCheckboxChangeTwo('pot_schine')}
                                      className={
                                        activeSpike == 1
                                          ? "btn btn-primary mb-0 w-50"
                                          : "btn text-center text-secondary bg-white mb-0 w-50 me-3 font-weight-400"
                                      }
                                    >
                                      Ja
                                    </button>
                                    <button
                                      type="button"
                                      onClick={() => handleCheckboxChangeTwo('erdspie')}
                                      className={
                                        activeSpike == 2
                                          ? "btn btn-primary mb-0 w-50"
                                          : "btn text-center text-secondary bg-white mb-0 w-50 me-3 font-weight-400"
                                      }
                                    >
                                      Nein
                                    </button>
                                  </div>
                                  {/* <div className="d-flex align-items-center justify-content-between mt-2">
                                    <div className=" d-flex align-items-center">
                                      <label className="mt-2">Ja</label>
                                      <div className="form-check d-inline-block">
                                        <input
                                          disabled={readOnly}
                                          type="checkbox"
                                          {...register("pot_schine")}
                                          className="form-check-input me-md-12 ms-1"
                                          value={1}
                                        />
                                      </div>
                                    </div>
                                    <div className=" d-flex align-items-center">
                                      <label className="mt-2">Nein</label>
                                      <div className="form-check d-inline-block">
                                        <input
                                          type="checkbox"
                                          disabled={readOnly}
                                          {...register("erdspieß")}
                                          required
                                          className="form-check-input me-md-12 ms-1"
                                          value={1}
                                        />
                                      </div>
                                    </div>
                                  </div> */}
                                  <div className="invalid-feedback">
                                    {errors.erdungsanschluss &&
                                      errors.erdungsanschluss.message}
                                  </div>
                                </Grid>

                                <Grid
                                  item
                                  xs={12}
                                  sm={12}
                                  md={6}
                                  lg={6}
                                  className="mt-0 mb-0"
                                >
                                  <label className="mb-1">Stecker System</label>
                                  <input
                                    readOnly={readOnly}
                                    type="text"
                                    className=" form-control"
                                    placeholder="Stecker System"
                                    {...register("stecker_system", {
                                      required: true,
                                    })}
                                    required
                                  />
                                  <div className="invalid-feedback">
                                    {errors.stecker_system &&
                                      errors.stecker_system.message}
                                  </div>
                                </Grid>
                              </Grid>

                              <Grid
                                my={2}
                                container
                                spacing={2}
                                className="mt-0 mb-0"
                              >
                                <Grid
                                  item
                                  xs={12}
                                  sm={12}
                                  md={12}
                                  lg={12}
                                  className="mt-0"
                                >
                                  <div>
                                    <SoftBox
                                      display="flex"
                                      flexDirection="column"
                                      justifyContent="flex-end"
                                      height="100%"
                                    >
                                      <SoftBox
                                        mb={1}
                                        ml={0.5}
                                        lineHeight={0}
                                        display="inline-block"
                                      >
                                        <SoftTypography
                                          component="label"
                                          variant="caption"
                                          fontWeight="bold"
                                        >
                                          {t("Description")}
                                        </SoftTypography>
                                      </SoftBox>
                                      {readOnly ? (
                                        <>{descriptionErdung}</>
                                      ) : (
                                        <div className="card box-shadow-none">
                                          <div className="card-body p-0">
                                            <SoftEditor
                                              value={descriptionErdung}
                                              onChange={setDescriptionErdung}
                                            />
                                          </div>
                                        </div>
                                      )}
                                    </SoftBox>
                                  </div>
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  sm={12}
                                  md={6}
                                  lg={6}
                                  className="mt-0"
                                >
                                  <label className="mb-1">
                                    Sichtbare Schäden an der PV Anlage
                                  </label>
                                  <select
                                    readOnly={readOnly}
                                    className=" form-control"
                                    {...register("pv_system", {
                                      required: true,
                                    })}
                                    required
                                  >
                                    <option>----</option>
                                    <option value="Ja">Ja</option>
                                    <option value="Nein">Nein</option>
                                  </select>
                                  <div className="invalid-feedback">
                                    {errors.pv_system &&
                                      errors.pv_system.message}
                                  </div> 
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  sm={12}
                                  md={6}
                                  lg={6}
                                  className="mt-0"
                                >
                                    <div style={{ marginTop: "15px;" }}>
                                    <label className="mb-1">Montagesystem</label>
                                    <input
                                      readOnly={readOnly}
                                      type="text"
                                      className=" form-control"
                                      placeholder="Montagesystem"
                                      {...register("montagesystem", {
                                        required: true,
                                      })}
                                      required
                                    />
                                    <div className="invalid-feedback">
                                      {errors.montagesystem &&
                                        errors.montagesystem.message}
                                    </div>
                                  </div>
                                </Grid>
                              </Grid>

                              <Grid
                                my={2}
                                container
                                spacing={2}
                                className="mt-0 mb-0"
                              >
                                <Grid
                                  item
                                  xs={12}
                                  sm={12}
                                  md={6}
                                  lg={6}
                                  className="mt-0"
                                >
                                  <label className="mb-1">Sichtbare Schäden am Dach</label>
                                  <select
                                    readOnly={readOnly}
                                    className=" form-control"
                                    {...register("the_roof", {
                                      required: true,
                                    })}
                                    required
                                  >
                                    <option>----</option>
                                    <option value="Ja">Ja</option>
                                    <option value="Nein">Nein</option>
                                  </select>
                                  <div className="invalid-feedback">
                                    {errors.the_roof && errors.the_roof.message}
                                  </div>
                                </Grid>

                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                  <label className="mb-1">Sichtbare Schäden am Gebäude</label>
                                  <select
                                    readOnly={readOnly}
                                    className=" form-control"
                                    {...register("to_build", {
                                      required: true,
                                    })}
                                    required
                                  >
                                    <option>----</option>
                                    <option value="Ja">Ja</option>
                                    <option value="Nein">Nein</option>
                                  </select>
                                  <div className="invalid-feedback">
                                    {errors.to_build && errors.to_build.message}
                                  </div>
                                </Grid>
                              </Grid>

                              <Grid
                                my={2}
                                container
                                spacing={2}
                                className="mt-0 mb-0"
                              >
                                <Grid
                                  item
                                  xs={12}
                                  sm={12}
                                  md={6}
                                  lg={6}
                                  className="mt-0"
                                >
                                  <label className="mb-1">Spannungsprotokoll erstellt</label>
                                  <select
                                    readOnly={readOnly}
                                    className=" form-control"
                                    {...register("log_created", {
                                      required: true,
                                    })}
                                  >
                                    <option>----</option>
                                    <option value="Ja">Ja</option>
                                    <option value="Nein">Nein</option>
                                  </select>
                                  <div className="invalid-feedback">
                                    {errors.log_created &&
                                      errors.log_created.message}
                                  </div>
                                </Grid>

                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                  <label className="mb-1">
                                    Sichtbare Schäden an Verkablung, Stecker
                                  </label>
                                  <select
                                    readOnly={readOnly}
                                    className=" form-control"
                                    {...register("wiring_plugs", {
                                      required: true,
                                    })}
                                    required
                                  >
                                    <option>----</option>
                                    <option value="Ja">Ja</option>
                                    <option value="Nein">Nein</option>
                                  </select>
                                  <div className="invalid-feedback">
                                    {errors.wiring_plugs &&
                                      errors.wiring_plugs.message}
                                  </div>
                                </Grid>
                              </Grid>

                              <Grid
                                my={2}
                                container
                                spacing={2}
                                className="mt-0 mb-0"
                              >
                                <Grid
                                  item
                                  xs={12}
                                  sm={12}
                                  md={6}
                                  lg={6}
                                  className="mt-0"
                                >
                                  <label className="mb-1">
                                    Sichtbare Schäden an Leitungsführung
                                  </label>

                                  <select
                                    readOnly={readOnly}
                                    className=" form-control"
                                    {...register("to_the_wiring", {
                                      required: true,
                                    })}
                                    require
                                  >
                                    <option>----</option>
                                    <option value="Ja">Ja</option>
                                    <option value="Nein">Nein</option>
                                  </select>
                                  <div className="invalid-feedback">
                                    {errors.to_the_wiring &&
                                      errors.to_the_wiring.message}
                                  </div>
                                </Grid>

                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                  <label className="mb-1">
                                    Sichtbare Schäden am Montagesystem
                                  </label>
                                  <select
                                    readOnly={readOnly}
                                    className=" form-control"
                                    {...register("mounting_system", {
                                      required: true,
                                    })}
                                    required
                                  >
                                    <option>----</option>
                                    <option value="Ja">Ja</option>
                                    <option value="Nein">Nein</option>
                                  </select>
                                </Grid>
                              </Grid>

                              <Grid
                                my={2}
                                container
                                spacing={2}
                                className="mt-0 mb-0"
                              >
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                  <label className="mb-1">
                                    Sichtbare Schäden am Wechselrichter
                                  </label>
                                  <select
                                    readOnly={readOnly}
                                    className=" form-control"
                                    {...register("the_inverter", {
                                      required: true,
                                    })}
                                    required
                                  >
                                    <option>----</option>
                                    <option value="Ja">Ja</option>
                                    <option value="Nein">Nein</option>
                                  </select>
                                </Grid>

                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                  <label className="mb-1">Baustelle sauber hinterlassen</label>
                                  <select
                                    readOnly={readOnly}
                                    className=" form-control"
                                    {...register("site_clean", {
                                      required: true,
                                    })}
                                    required
                                  >
                                    <option>----</option>
                                    <option value="Ja">Ja</option>
                                    <option value="Nein">Nein</option>
                                  </select>
                                </Grid>
                              </Grid>

                              <Grid
                                my={2}
                                container
                                spacing={2}
                                className="mt-0 mb-0"
                              >
                                <Grid
                                  item
                                  xs={12}
                                  sm={6}
                                  md={6}
                                  lg={6}  
                                >
                                  <div className="col-md-12 mt-3">
                                    <div className="border-primary border-radius-5 p-2 bg-white">
                                      <div className="d-flex justify-content-between">
                                        <p className="font-size-16 mb-0 text-primary">
                                          {t("Leistungsoptim")}
                                        </p>
                                        <div className="form-check">
                                          <input
                                            onClick={handleCheckboxChange}
                                            type="checkbox"
                                            {...register("leistungsoptim_check")}
                                            className="form-check-input"
                                            value={1}
                                          /> 
                                        </div>
                                      </div>
                                    </div>
                                  </div> 
                                </Grid>
                                
                                <Grid
                                  item
                                  xs={12}
                                  sm={6}
                                  md={6}
                                  lg={6}  
                                >
                                   <label className="mb-1">Stk</label>
                                    <input
                                      readOnly={readOnly}
                                      className=" form-control"
                                      placeholder="Stk"
                                      {...register("stk", {
                                        required: isCheckboxChecked,
                                      })}
                                      required={isCheckboxChecked}
                                    />
                                </Grid>
                              </Grid>

                              <Grid
                                container
                                mt={1}
                                spacing={2}
                                className="mt-0 mb-0"
                              >
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                <div className="col-md-12">
                                    <label className="mb-1">Verkablung / Leitungsführung:</label>
                                    <div className="border-primary border-radius-5 p-2 bg-white">
                                      <div className="d-flex justify-content-between">
                                        <p className="font-size-16 mb-0 text-primary">
                                          {t("In Ordnung")}
                                        </p>
                                        <div className="form-check">
                                          <input
                                            disabled={readOnly}
                                            type="checkbox"
                                            {...register("in_ordnung")}
                                            className="form-check-input"
                                            value={1}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div> 
                                 
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                  <SoftBox
                                    display="flex"
                                    flexDirection="column"
                                    justifyContent="flex-end"
                                    height="100%"
                                  >
                                    <SoftBox
                                      mb={1}
                                      ml={0.5}
                                      lineHeight={0}
                                      display="inline-block"
                                    >
                                      <SoftTypography
                                        component="label"
                                        variant="caption"
                                        fontWeight="bold"
                                      >
                                        {t("Beanstandet")}
                                      </SoftTypography>
                                    </SoftBox>
                                    <SoftInput
                                      placeholder={t("Beanstandet")}
                                      {...register("beanstandet", {
                                        required: false,
                                      })}
                                      readOnly={readOnly}
                                    />
                                  </SoftBox>
                                </Grid>
                              </Grid>

                              <Grid
                                container
                                spacing={2}
                                my={1}
                                className="mt-0 mb-0"
                              >
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                <div className="col-md-12">
                                    <label className="mb-1">Asführung entspricht dem Angebot:</label>
                                    <div className="border-primary border-radius-5 p-2 bg-white">
                                      <div className="d-flex justify-content-between">
                                        <p className="font-size-16 mb-0 text-primary">
                                          {t("In Ordnung")}
                                        </p>
                                        <div className="form-check">
                                          <input
                                            disabled={readOnly}
                                            type="checkbox"
                                            {...register("in_ordnung2")}
                                            className="form-check-input"
                                            value={1}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>  
 
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                  <SoftBox
                                    display="flex"
                                    flexDirection="column"
                                    justifyContent="flex-end"
                                    height="100%"
                                  >
                                    <SoftBox
                                      mb={1}
                                      ml={0.5}
                                      lineHeight={0}
                                      display="inline-block"
                                    >
                                      <SoftTypography
                                        component="label"
                                        variant="caption"
                                        fontWeight="bold"
                                      >
                                        {t("Beanstandet")}
                                      </SoftTypography>
                                    </SoftBox>
                                    <SoftInput
                                      placeholder={t("Beanstandet")}
                                      {...register("beanstandet2", {
                                        required: false,
                                      })}
                                      readOnly={readOnly}
                                    />
                                  </SoftBox>
                                </Grid>
                              </Grid>

                              <SoftBox
                                display="flex"
                                flexDirection="column"
                                justifyContent="flex-end"
                                height="100%"
                              >
                                <SoftBox
                                  mb={1}
                                  ml={0.5}
                                  mt={3}
                                  lineHeight={0}
                                  display="inline-block"
                                >
                                  <SoftTypography
                                    component="label"
                                    variant="caption"
                                    fontWeight="bold"
                                  >
                                    {t("Description")}
                                  </SoftTypography>
                                </SoftBox>
                                {readOnly ? (
                                  <>{description}</>
                                ) : (
                                  <div className="card box-shadow-none">
                                    <div className="card-body p-0">
                                      <SoftEditor
                                        value={description}
                                        onChange={setDescription}
                                      />
                                    </div>
                                  </div>
                                )}
                              </SoftBox>
                            </SoftBox>
                          </div>
                        </>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="accordion accordion-flush mt-3"
                  id="accordionFlushExample"
                >
                  <div className="accordion-item accordion-item bg-secondary p-3 border-radius-8">
                    <h2 className="accordion-header">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseTwo"
                        aria-expanded="false"
                        aria-controls="flush-collapseTwo"
                      >
                        <span className="accordion-arrow">
                          <i className="ph ph-caret-right"></i>
                        </span>
                        <p className="font-size-20 font-weight-600 text-primary">
                          <span className="pl-2">{t("Files")}</span>
                        </p>
                      </button>
                    </h2>
                    <div
                      id="flush-collapseTwo"
                      className="accordion-collapse collapse"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div className="accordion-body">
                        <SoftBox pb={3}>
                          <DropZoneTabs
                            tabs={dropZoneTabs}
                            defaultFiles={tabFiles}
                            defaultTab={"ziegelbearbeitungMontageDachhaken"}
                            callBackData={setCallBackData}
                            readOnly={readOnly}
                            dropZonValidationError={dropZonValidationError}
                          />
                        </SoftBox>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="accordion accordion-flush mt-3"
                  id="accordionFlushExample"
                >
                  <div className="accordion-item accordion-item bg-secondary p-3 border-radius-8">
                    <h2 className="accordion-header">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseThree"
                        aria-expanded="false"
                        aria-controls="flush-collapseThree"
                      >
                        <span className="accordion-arrow">
                          <i className="ph ph-caret-right"></i>
                        </span>
                        <p className="font-size-20 font-weight-600 text-primary">
                          <span className="pl-2">Skizze/Modulbelegung</span>
                        </p>
                      </button>
                    </h2>
                    <div
                      id="flush-collapseThree"
                      className="accordion-collapse collapse"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div className="accordion-body">
                        {JSON.parse(Cookies.get("permissions")).indexOf(
                          "dabhU"
                        ) !== -1 ? (
                          <div className="position-relative">
                            <SignaturePad
                              options={{
                                minWidth: 1,
                                maxWidth: 1,
                                penColor: "rgb(0, 0, 0)",
                                backgroundColor: "#ECF6FF",
                              }}
                              ref={signaturePadRef}
                            />

                            <button
                              style={{ top: "8px", right: "8px" }}
                              type="button"
                              className="btn btn-light btn-brand-hover position-absolute"
                              onClick={() => {
                                signaturePadRef.current.clear();
                              }}
                            >
                              <i class="ph ph-eraser"></i>
                            </button>
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-3">
                  <DCabnahmeSign
                    saveBtnDisplay={false}
                    flashLoader={false}
                    pdfDisplay={true}
                    project_id={params?.id}
                    id={id}
                  />
                </div>
              </div>
            </div>

            <SoftBox>
              {JSON.parse(Cookies.get("permissions")).indexOf("dabhU") !==
              -1 ? (
                <>
                  <a
                    href="#"
                    className="btn btn-danger mx-2 d-none"
                    onClick={() => {
                      clearForm();
                    }}
                  >
                    {t("Cancel")}
                  </a>
                  {!saving && (
                    <button
                      id="dcAbname"
                      type="submit"
                      className="btn btn-primary"
                    >
                      {t("Save")}
                    </button>
                  )}
                  {saving && (
                    <button
                      type="button"
                      className="btn btn-secondary"
                      disabled
                    >
                      {t("wird geladen...")}
                    </button>
                  )}
                </>
              ) : (
                <></>
              )}
              <a
                target="_blank"
                className="btn btn-primary mx-2"
                href={
                  Cookies.get("backend_url") + "dcabname/" + (id ? id : "empty")
                }
              >
                {t("PDF")}
              </a>
            </SoftBox>
          </form>
        </div> 
        {renderSuccessSB}
      </div>
      <ComisioningModal
                      projectid={props.project_id}
                      id={id}
                      type={"dc"}
                      setRefresh={setRefresh}
                    />
    </>
  );
};

export default DCAbnahmeprotokoll;
