import { React, useEffect, useState } from "react";
import callFetch from "../../helpers/callFetch";
function LastMessage(props) {
  const [message, setMessage] = useState("");
  const [lastMessageTime, setLastMessageTime] = useState("");
  useEffect(() => {
    callFetch("chat/last-message/" + props.userId, "GET", []).then((res) => {
      // console.log(res.lastMessage);
      setMessage(res.lastMessage);
      setLastMessageTime(res.last_message_time);
    });
  }, [props.userId]);
  return (
    <>
      <h5 className="text-truncate font-size-14 mb-1 font-wieght-500 position-relative">
        {props.name}{" "}
        <span className="font-size-12 position-absolute last-message-time">
          {lastMessageTime}
        </span>
      </h5>
      <p className="chat-user-message text-truncate mb-0">
        {message.includes("-") &&
        message.split("-").length > 2 &&
        (message.split("-")[0] === "You : project" ||
          message.split("-")[0] === "project")
          ? "You : Project #" + message.split("-")[2]
          : message}
      </p>
    </>
  );
}

export default LastMessage;
