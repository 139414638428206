import { React, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { callFetch } from "../../../helpers/callFetch";
import { useTranslation } from "react-i18next";

// @mui core components
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

import EmailForm from "./EmailForm";
import Timeline from "./Timeline";
import Select, { components } from "react-select";

import ProjectDistribution from "./Distribution/Index";
import Overview from "../Overview";
import SoftSnackbar from "components/SoftSnackbar";
import Cookies from "js-cookie";
import { NumericFormat } from "react-number-format";
import Swal from "sweetalert2";
import ProfitCalculatonCreate from "./ProfitCalculation/Create";
import DistributionSign from "./Distribution/DistributionSign";
import PositionsModal from "./Distribution/PositionsModal";
import Wirtschaftlichkeitsberechnung from "./Wirtschaftlichkeitsberechnung";

function Distribution(props) {
  let params = useParams();
  const { t } = useTranslation();
  const [modules, setModules] = useState([]);
  const [speichers, setSpeichers] = useState([]);
  const [wechselrichters, setWechselrichters] = useState([]);
  const [allProducts, setAllProducts] = useState([]);
  const [products, setProducts] = useState([]);
  const [saving, setSaving] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const [refresh2, setRefresh2] = useState(0);
  const [refresh3, setRefresh3] = useState(0);
  const [productItemsRefresh, setproductItemsRefresh] = useState(0);
  const [editDelay, setEditDelay] = useState(0);
  const [data, setData] = useState(false);
  const [zusatzvereinbarungen, setZusatzvereinbarungen] = useState(null);
  const [meter_location_floor, setMeter_location_floor] = useState(null);
  const [house_connection_location, setHouse_connection_location] =
    useState(null);
  const [storage_wrspace, setStorage_wrspace] = useState(null);
  const [show, setShow] = useState(0);
  const [showTwo, setShowTwo] = useState(false);
  const [radio, setRadio] = useState(true);
  const [speicherEingeben, setSpeicherEingeben] = useState("no");
  const [moduleEingeben, setModuleEingeben] = useState("no");
  const [wechselrichterEingeben, setWechselrichter] = useState("no");
  const [customEmail, setCustomEmail] = useState("");
  const [readOnly, setReadOnly] = useState(true);
  const [selectedQuationId, setSelectedQuationId] = useState("");
  const [quationRefresh, setQuationRefresh] = useState(0);
  const [selectedproduct, setSelectedproduct] = useState([]);
  const [create, setCreate] = useState(false);
  const [resaleAngebot, setResaleAngebot] = useState(false);
  const [newQuation, setNewQuation] = useState(true);
  const [bundles, setBundles] = useState([]);
  const [meter_numbers, setMeter_number] = useState([""]);
  const [dachtyp, setDachtyp] = useState([""]);
  const [dacheindeckung, setDacheindeckung] = useState([""]);
  const [mitarbeiterkennung, setMitarbeiterkennung] = useState(false);

  const [expandedRow, setExpandedRow] = useState(null);
  const toggleRow = (id) => {
    setExpandedRow(expandedRow == id ? null : id);
  };

  const [mitarbeiterkennungOptional, setMitarbeiterkennungOptional] =
    useState(false);
  const [paymentOptions, setPaymentOptions] = useState({});
  const [productIndex, setProductIndex] = useState("");

  const [productItems, setProductItems] = useState({
    ust_positionen: 0,
    ust_positionen_amount: 0,
    subTotal: 0,
    gesami_netto: 0,
    items: [
      {
        id: 0,
        type: "bundle",
        product_type: "",
        quantity: 1,
        price: 0,
        sales_person_price: 0,
        tax: 0,
        discount: 0,
        totalDiscount: 0,
        total: 0,
        lieferanten_id: 0,
        item: [],
      },
    ],
  });

  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const handleInputChange = () => {
    setMenuIsOpen(true); // Show the menu when input is clicked/focused
  };

  const [reactSelectStyle, setReactSelectStyle] = useState({
    control: (baseStyles, state) => ({
      ...baseStyles,
      minHeight: "32px",
      //height: '32px',
      fontSize: "0.75rem",
      padding: "0rem 0rem",
      borderRadius: "0.5rem",
    }),
  });

  const {
    register,
    handleSubmit,
    setError,
    setValue,
    getValues,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      angebotsdatum: new Date().toISOString().split('T')[0] // today's date
    }
  });

  const [bValue, setBValue] = useState(null);

  // This function will be passed to B so B can tell A when something changes
  const handleBUpdate = (newValue) => {
    setBValue(newValue);
  };

  const CustomOption = ({ innerProps, option, itemIndex }) => {
    // console.log('option')
    //console.log(option)
    // console.log(productItems?.items[itemIndex]?.product_type)

    return (
      <>
        {productItems?.items[itemIndex]?.product_type ? (
          <>
            {productItems?.items[itemIndex]?.product_type === option?.type ? (
              <li
                {...innerProps}
                className={
                  "custom-option " +
                  (productItems?.items[itemIndex]?.item?.value == option?.value
                    ? "active"
                    : "")
                }
              >
                {option?.label}
              </li>
            ) : (
              ""
            )}
          </>
        ) : (
          <>
            <li
              {...innerProps}
              className={
                "custom-option " +
                (productItems?.items[itemIndex]?.item?.value == option?.value
                  ? "active"
                  : "")
              }
            >
              {productItems?.items[itemIndex]?.type == "bundle" ? (
                <strong>{option?.label}</strong>
              ) : (
                option?.label
              )}

              {productItems?.items[itemIndex]?.type == "bundle" && option.id ? (
                <div>
                  {option?.items
                    ? option?.items.map((item, item_index) => (
                        <>
                          <p
                            style={{
                              fontSize: "0.65rem",
                              fontWeight: "normal",
                            }}
                            className="m-0 p-0"
                          >
                            {item[item?.type]?.name +
                              " - " +
                              item?.number +
                              " Stück"}
                          </p>
                        </>
                      ))
                    : ""}
                </div>
              ) : (
                ""
              )}
            </li>
          </>
        )}
      </>
    );
  };

  const countOccurrences = (obj, key, value) => {
    let count = 0;
    for (let prop in obj) {
      if (obj.hasOwnProperty(prop)) {
        if (prop === key && obj[prop] === value) {
          count++;
        } else if (typeof obj[prop] === "object") {
          count += countOccurrences(obj[prop], key, value);
        }
      }
    }
    return count;
  };

  const searchKeyValue = (obj, key, value) => {
    let count = 0;
    for (let prop in obj) {
      if (obj.hasOwnProperty(prop)) {
        // //console.log(obj[prop])
        // if (obj[prop]?.type === 'speicher') {
        //     if (obj[prop]?.speicher?.spei_type && value == obj[prop]?.speicher?.spei_type) {
        //         console.log(obj[prop]?.speicher?.spei_type);
        //     }
        // }

        if (prop === key && obj[prop] === value) {
          count++;
        } else if (typeof obj[prop] === "object") {
          count += countOccurrences(obj[prop], key, value);
        }
      }
    }
    return count;
  };

  const searchSpeicherTypeValue = (obj, value) => {
    let count = 0;
    for (let prop in obj) {
      if (obj.hasOwnProperty(prop)) {
        if (obj[prop]?.type === "speicher") {
          if (
            obj[prop]?.speicher?.speicher_type?.id &&
            value == obj[prop]?.speicher?.speicher_type?.id
          ) {
            //console.log(obj[prop]?.speicher?.speicher_type?.id);
            count++;
          }
        }
      }
    }
    return count;
  };

  const getWattsFromBundles = (lieferanten_id, speicher_type) => {
    var wattsss = [];
    for (let [key, bundle] of Object.entries(bundles)) {
      for (let [option_key, option] of Object.entries(bundle?.options)) {
        for (let [option_item_key, option_item] of Object.entries(
          option?.items
        )) {
          //if (bundle?.id == lieferanten_id && option_item?.speicher?.speicher_type?.id == speicher_type && option_item?.type === 'speicher' && option_item?.speicher?.watt && wattsss.indexOf(option_item?.speicher?.watt) == -1) {
          if (
            option_item?.type === "speicher" &&
            option_item?.speicher?.watt &&
            wattsss.indexOf(option_item?.speicher?.watt) == -1
          ) {
            wattsss.push(option_item?.speicher?.watt);
          }
        }
      }
    }
    return wattsss;
  };

  const getSpeicherTypesFromBundles = (lieferanten_id) => {
    var speicherTypes = [];
    for (let [key, bundle] of Object.entries(bundles)) {
      for (let [option_key, option] of Object.entries(bundle?.options)) {
        for (let [option_item_key, option_item] of Object.entries(
          option?.items
        )) {
          var alreadyAdd = countOccurrences(
            speicherTypes,
            "id",
            option_item?.speicher?.speicher_type?.id
          );

          //if (bundle?.id == lieferanten_id && option_item?.type === 'speicher' && option_item?.speicher?.speicher_type?.name && alreadyAdd == 0) {
          if (
            option_item?.type === "speicher" &&
            option_item?.speicher?.speicher_type?.name &&
            alreadyAdd == 0
          ) {
            speicherTypes.push({
              id: option_item?.speicher?.speicher_type?.id,
              name: option_item?.speicher?.speicher_type?.name,
            });
          }
        }
      }
    }
    return speicherTypes;
  };

 

  const zusatzproduktLiferentCount = (obj, key, value) => {
    let count = 0;
    for (let prop in obj) {
      if (obj.hasOwnProperty(prop)) {
        if (
          obj["product_from"] === "zusatzprodukte" &&
          prop === key &&
          obj[prop] === value
        ) {
          count++;
        } else if (typeof obj[prop] === "object") {
          count += zusatzproduktLiferentCount(obj[prop], key, value);
        }
      }
    }
    return count;
  };

  const CustomGroupLabel = ({ innerProps, group, itemIndex }) => {
    if (productItems?.items[itemIndex]?.product_form === "zusatzprodukte") {
      if (productItems.items[itemIndex].product_type) {
        var totalOptions = zusatzproduktLiferentCount(
          group?.options,
          "type",
          productItems.items[itemIndex].product_type
        );
      } else {
        var totalOptions = countOccurrences(
          group?.options,
          "product_from",
          productItems.items[itemIndex].product_form
        );
      }
    } else {
      var totalOptions = countOccurrences(
        group?.options,
        "product_from",
        productItems.items[itemIndex].product_form
      );
    }

    return (
      <>
        {(productItems?.items[itemIndex].type == "bundle" &&
          group?.options.length) ||
        (productItems?.items[itemIndex].type != "bundle" &&
          totalOptions > 0) ? (
          <li
            class={
              "nav-item nav-link py-1 " +
              (group?.id ===
              (productItems?.items[itemIndex].lieferanten_id
                ? productItems?.items[itemIndex].lieferanten_id
                : productItems?.items[itemIndex].type == "bundle"
                ? bundles[0]?.id
                : products[0]?.id)
                ? "active"
                : "")
            }
            id={generateId(group?.label) + "-tab"}
            data-bs-target={"#" + generateId(group?.label)}
            onClick={() => {
              var update = productItems;
              update.items[itemIndex].lieferanten_id = group?.id;
              //update.items[itemIndex].searchWatt = '';

              if (productItems?.items[itemIndex].type == "bundle") {
                update.items[itemIndex].searchWatt = "";
                update.items[itemIndex].searchType = "";

                update.items[itemIndex].price = 0;
                update.items[itemIndex].item = [];
              }

              setProductItems(update);
              setproductItemsRefresh(productItemsRefresh + 1);
            }}
            data-bs-toggle="tab"
            type="button"
            role="tab"
            aria-controls={generateId(group?.label)}
            aria-selected="true"
          >
            {group?.label}
          </li>
        ) : (
          ""
        )}
      </>
    );
  };

  const generateId = (text) => {
    var str = text
      ? text
          .toLowerCase()
          .replace(/[^a-zA-Z0-9]/g, "-")
          .replace(/\s+/g, "")
      : Math.floor((Math.random() + 1) * 900000);

    return str;
  };

  const withOrWithoutSpeicher = (with_speicher = "all", option) => {
    var data = true;

    if (with_speicher === "all") {
      var data = true;
    } else if (with_speicher === true) {
      var searchSpeicher = searchKeyValue(option.items, "type", "speicher");

      if (searchSpeicher > 0) {
        var data = true;
      } else {
        var data = false;
      }

      //console.log('searchSpeicher')
      //console.log(searchSpeicher)
    } else if (with_speicher === false) {
      var searchSpeicher = searchKeyValue(option.items, "type", "speicher");

      if (searchSpeicher <= 0) {
        var data = true;
      } else {
        var data = false;
      }
    }

    return data;
  };

  const withWattSearch = (watt = "", option) => {
    var data = false;
    if (watt === "") {
      var data = true;
    } else if (watt > 0) {
      var searchCount = searchKeyValue(option.items, "watt", watt);
      if (searchCount > 0) {
        var data = true;
      } else {
        var data = false;
      }
    }
    return data;
  };

  const withTypeSearch = (type = "", option) => {
    console.log("withTypeSearch");
    console.log(option.items);
    console.log(type);

    var data = false;
    if (type === "") {
      var data = true;
    } else if (type > 0) {
      var searchCount = searchSpeicherTypeValue(option.items, type);

      //var searchSpeicher = searchKeyValue(option.items, 'type', "speicher");

      //if(searchSpeicher > 0){
      //console.log(option?.items)
      //}

      //console.log(type)
      //console.log('searchCount')
      // console.log(searchCount)

      if (searchCount > 0) {
        //console.log(option?.items)
        var data = true;
      } else {
        var data = false;
      }
    }
    return data;
  };

  const CustomMenu = (props) => {
    const itemIndex = props.selectProps.itemIndex;

    return (
      <components.Menu {...props} style={{ width: "700px" }}>
        <div
          style={
            {
              //minWidth: '700px',
              //width: '500px',
              //border: 'none'
              //overflow: 'auto'
            }
          }
          className="react-select-custom-group-dropdown"
        >
          <div className="row m-0">
            {productItems.items[itemIndex].type === "product" ? (
              <div
                className={
                  productItems?.items[itemIndex]?.product_form ===
                  "zusatzprodukte"
                    ? "col-3 p-0"
                    : "col-4 p-0"
                }
                //className={'col p-0'}
                style={{
                  borderRight: "1px solid #c5c5c5",
                }}
              >
                <h6 className="m-0 option-header">Produktkategorie</h6>
                <ul class="nav nav-tabs d-block">
                  <li
                    className={
                      "nav-item nav-link py-1 " +
                      (productItems?.items[itemIndex]?.product_form ==
                      "zusatzprodukte"
                        ? "active"
                        : "")
                    }
                    onClick={() => {
                      //product_type
                      var update = productItems;
                      update.items[itemIndex].product_form = "zusatzprodukte";
                      setProductItems(update);
                      setproductItemsRefresh(productItemsRefresh + 1);
                    }}
                  >
                    Zusatzprodukte
                  </li>

                  <li
                    className={
                      "nav-item nav-link py-1 " +
                      (productItems?.items[itemIndex]?.product_form == "module"
                        ? "active"
                        : "")
                    }
                    onClick={() => {
                      //product_type
                      var update = productItems;
                      update.items[itemIndex].product_form = "module";
                      update.items[itemIndex].product_type = "";
                      setProductItems(update);
                      setproductItemsRefresh(productItemsRefresh + 1);
                    }}
                  >
                    Module
                  </li>

                  <li
                    className={
                      "nav-item nav-link py-1 " +
                      (productItems?.items[itemIndex]?.product_form ==
                      "speicher"
                        ? "active"
                        : "")
                    }
                    onClick={() => {
                      //product_type
                      var update = productItems;
                      update.items[itemIndex].product_form = "speicher";
                      update.items[itemIndex].product_type = "";
                      setProductItems(update);
                      setproductItemsRefresh(productItemsRefresh + 1);
                    }}
                  >
                    Speicher
                  </li>

                  <li
                    className={
                      "nav-item nav-link py-1 " +
                      (productItems.items[itemIndex]?.product_form ==
                      "wechselrichter"
                        ? "active"
                        : "")
                    }
                    onClick={() => {
                      //product_type
                      var update = productItems;
                      update.items[itemIndex].product_form = "wechselrichter";
                      update.items[itemIndex].product_type = "";
                      setProductItems(update);
                      setproductItemsRefresh(productItemsRefresh + 1);
                    }}
                  >
                    Wechselrichter
                  </li>
                </ul>
              </div>
            ) : (
              ""
            )}

            {productItems?.items[itemIndex]?.product_form ===
            "zusatzprodukte" ? (
              <div
                className={
                  productItems?.items[itemIndex]?.product_form ===
                  "zusatzprodukte"
                    ? "col-3 p-0"
                    : "col-4 p-0"
                }
                //className={'col p-0'}
                style={{
                  borderRight: "1px solid #c5c5c5",
                }}
              >
                <h6 className="m-0 option-header">Kategorie</h6>

                <ul class="nav nav-tabs d-block">
                  <li
                    className={
                      "nav-item nav-link py-1 " +
                      (productItems.items[itemIndex].product_type == ""
                        ? "active"
                        : "")
                    }
                    onClick={() => {
                      //product_type
                      var update = productItems;
                      update.items[itemIndex].product_type = "";
                      setProductItems(update);
                      setproductItemsRefresh(productItemsRefresh + 1);
                    }}
                  >
                    Alle
                  </li>
                  <li
                    className={
                      "nav-item nav-link py-1 " +
                      (productItems.items[itemIndex].product_type === "ac"
                        ? "active"
                        : "")
                    }
                    onClick={() => {
                      //product_type
                      var update = productItems;
                      update.items[itemIndex].product_type = "ac";
                      setProductItems(update);
                      setproductItemsRefresh(productItemsRefresh + 1);
                    }}
                  >
                    AC Produkt
                  </li>
                  <li
                    className={
                      "nav-item nav-link py-1 " +
                      (productItems.items[itemIndex].product_type === "dc"
                        ? "active"
                        : "")
                    }
                    onClick={() => {
                      //product_type
                      var update = productItems;
                      update.items[itemIndex].product_type = "dc";
                      setProductItems(update);
                      setproductItemsRefresh(productItemsRefresh + 1);
                    }}
                  >
                    DC Produkt
                  </li>
                  <li
                    className={
                      "nav-item nav-link py-1 " +
                      (productItems.items[itemIndex].product_type ===
                      "Dienstleistung"
                        ? "active"
                        : "")
                    }
                    onClick={() => {
                      //product_type
                      var update = productItems;
                      update.items[itemIndex].product_type = "Dienstleistung";
                      setProductItems(update);
                      setproductItemsRefresh(productItemsRefresh + 1);
                    }}
                  >
                    Dienstleistung
                  </li>
                </ul>
              </div>
            ) : (
              ""
            )}

            <div
              className={
                productItems?.items[itemIndex]?.product_form ===
                "zusatzprodukte"
                  ? "col-3 p-0"
                  : "col p-0"
              }
              //className={'col p-0'}
              style={{
                borderRight: "1px solid #c5c5c5",
              }}
            >
              <h6 className="m-0 option-header">Hersteller</h6>
              <ul class="nav nav-tabs d-block" role="tablist">
                {props?.options.map((group) => (
                  <CustomGroupLabel
                    key={group.label}
                    group={group}
                    itemIndex={itemIndex}
                  />
                ))}
              </ul>
            </div>

            {productItems.items[itemIndex].type === "bundle" ? (
              <>
                {productItems?.items[itemIndex].lieferanten_id ? (
                  <div
                    className={"col p-0"}
                    style={{
                      borderRight: "1px solid #c5c5c5",
                    }}
                  >
                    <h6 className="m-0 option-header">Speicher vorh.</h6>
                    <ul class="nav nav-tabs d-block">
                      <li
                        className={
                          "nav-item nav-link py-1 " +
                          (productItems?.items[itemIndex]?.with_speicher ===
                          "all"
                            ? "active"
                            : "")
                        }
                        onClick={() => {
                          //product_type
                          var update = productItems;
                          update.items[itemIndex].with_speicher = "all";
                          setProductItems(update);
                          setproductItemsRefresh(productItemsRefresh + 1);
                        }}
                      >
                        {t("All")}
                      </li>
                      <li
                        className={
                          "nav-item nav-link py-1 " +
                          (productItems?.items[itemIndex]?.with_speicher ===
                          true
                            ? "active"
                            : "")
                        }
                        onClick={() => {
                          //product_type
                          var update = productItems;
                          update.items[itemIndex].with_speicher = true;

                          setProductItems(update);
                          setproductItemsRefresh(productItemsRefresh + 1);
                        }}
                      >
                        {t("With Speicher")}
                      </li>
                      <li
                        className={
                          "nav-item nav-link py-1 " +
                          (productItems?.items[itemIndex]?.with_speicher ===
                          false
                            ? "active"
                            : "")
                        }
                        onClick={() => {
                          //product_type
                          var update = productItems;
                          update.items[itemIndex].with_speicher = false;
                          update.items[itemIndex].searchWatt = "";
                          update.items[itemIndex].searchType = "";

                          update.items[itemIndex].price = 0;
                          update.items[itemIndex].item = [];

                          setProductItems(update);
                          setproductItemsRefresh(productItemsRefresh + 1);
                        }}
                      >
                        {t("Without Speicher")}
                      </li>
                    </ul>
                  </div>
                ) : (
                  ""
                )}

                {productItems?.items[itemIndex]?.with_speicher ? (
                  <>
                    {productItems?.items[itemIndex].lieferanten_id ? (
                      <div
                        className={"col p-0"}
                        style={{
                          borderRight: "1px solid #c5c5c5",
                        }}
                      >
                        <h6 className="m-0 option-header">Speicher Typ</h6>
                        <ul class="nav nav-tabs d-block">
                          {/*
                                                    <li
                                                        className={"nav-item nav-link py-1 " + (!productItems?.items[itemIndex]?.searchType || productItems?.items[itemIndex]?.searchType === '' ? 'active' : '')}
                                                        onClick={() => {
                                                            //product_type
                                                            var update = productItems;
                                                            update.items[itemIndex].searchType = '';
                                                            setProductItems(update);
                                                            setproductItemsRefresh(productItemsRefresh + 1);
                                                        }}
                                                    >{t('All')}</li>
                                                    */}

                          {getSpeicherTypesFromBundles(
                            productItems?.items[itemIndex].lieferanten_id
                          ).map((bundle_speicher_types) => (
                            <li
                              className={
                                "nav-item nav-link py-1 " +
                                (productItems?.items[itemIndex]?.searchType ==
                                bundle_speicher_types?.id
                                  ? "active"
                                  : "")
                              }
                              onClick={() => {
                                var update = productItems;
                                update.items[itemIndex].searchType =
                                  bundle_speicher_types?.id;
                                update.items[itemIndex].searchWatt = "";

                                update.items[itemIndex].price = 0;
                                update.items[itemIndex].item = [];

                                setProductItems(update);
                                setproductItemsRefresh(productItemsRefresh + 1);
                              }}
                            >
                              {t(bundle_speicher_types?.name)}
                            </li>
                          ))}
                        </ul>
                      </div>
                    ) : (
                      ""
                    )}

                    {productItems?.items[itemIndex]?.searchType ? (
                      <div
                        className={"col p-0"}
                        style={{
                          borderRight: "1px solid #c5c5c5",
                        }}
                      >
                        <h6 className="m-0 option-header">Speicher kWh</h6>
                        <ul class="nav nav-tabs d-block">
                          {/*
                                                    <li
                                                        className={"nav-item nav-link py-1 " + (productItems?.items[itemIndex]?.searchWatt === '' ? 'active' : '')}
                                                        onClick={() => {
                                                            var update = productItems;
                                                            update.items[itemIndex].searchWatt = '';
                                                            setProductItems(update);
                                                            setproductItemsRefresh(productItemsRefresh + 1);
                                                        }}
                                                    >{t('All')}</li>
                                                    */}

                          {getWattsFromBundles(
                            productItems?.items[itemIndex].lieferanten_id,
                            productItems?.items[itemIndex]?.searchType
                          ).map((bundle_watt) => (
                            <li
                              className={
                                "nav-item nav-link py-1 " +
                                (productItems?.items[itemIndex]?.searchWatt ==
                                bundle_watt
                                  ? "active"
                                  : "")
                              }
                              onClick={() => {
                                var update = productItems;
                                update.items[itemIndex].searchWatt =
                                  bundle_watt;

                                update.items[itemIndex].price = 0;
                                update.items[itemIndex].item = [];

                                setProductItems(update);
                                setproductItemsRefresh(productItemsRefresh + 1);
                              }}
                            >
                              {t(bundle_watt + " kWh")}
                            </li>
                          ))}
                        </ul>
                      </div>
                    ) : (
                      ""
                    )}
                  </>
                ) : (
                  ""
                )}
              </>
            ) : (
              ""
            )}

            {(
              productItems.items[itemIndex].type === "bundle"
                ? (productItems?.items[itemIndex].lieferanten_id &&
                    productItems?.items[itemIndex]?.with_speicher === false) ||
                  (productItems?.items[itemIndex].lieferanten_id &&
                    productItems?.items[itemIndex].searchWatt)
                : productItems?.items[itemIndex].lieferanten_id
            ) ? (
              <div
                //className={productItems.items[itemIndex].type === 'bundle' && productItems?.items[itemIndex]?.with_speicher === false ? 'col-6' : productItems?.items[itemIndex]?.product_form === 'zusatzprodukte' || productItems.items[itemIndex].type === 'bundle' ? 'col-3' : productItems.items[itemIndex].type === 'product' ? "col-4" : 'col-8'}
                className={"col p-0"}
              >
                <h6 className="m-0 option-header">
                  {productItems.items[itemIndex].type === "bundle"
                    ? "Bundle"
                    : "Produkt"}
                </h6>
                <div class="tab-content">
                  {props?.options.map((group) => (
                    <div
                      class={
                        "tab-pane fade show " +
                        (group?.id ===
                        (productItems?.items[itemIndex].lieferanten_id
                          ? productItems?.items[itemIndex].lieferanten_id
                          : productItems?.items[itemIndex].type == "bundle"
                          ? bundles[0]?.id
                          : products[0]?.id)
                          ? "active"
                          : "")
                      }
                      id={generateId(group.label)}
                      role="tabpanel"
                      aria-labelledby={generateId(group.label) + "-tab"}
                    >
                      <ul className="custom-options">
                        {props?.options
                          .find(
                            (group) =>
                              group?.id ===
                              (productItems?.items[itemIndex].lieferanten_id
                                ? productItems?.items[itemIndex].lieferanten_id
                                : productItems?.items[itemIndex].type ==
                                  "bundle"
                                ? bundles[0]?.id
                                : products[0]?.id)
                          )
                          ?.options.map((option) => (
                            <>
                              {(productItems?.items[itemIndex].type == "bundle"
                                ? withTypeSearch(
                                    productItems?.items[itemIndex]?.searchType,
                                    option
                                  ) &&
                                  withWattSearch(
                                    productItems?.items[itemIndex]?.searchWatt,
                                    option
                                  ) &&
                                  withOrWithoutSpeicher(
                                    productItems?.items[itemIndex]
                                      ?.with_speicher,
                                    option
                                  )
                                : true) &&
                              option?.product_from ===
                                productItems?.items[itemIndex]?.product_form ? (
                                <CustomOption
                                  key={option.value}
                                  innerProps={{
                                    onClick: () => {
                                      var update = productItems;
                                      update.items[itemIndex].price =
                                        option?.price ? option?.price : 0;
                                      update.items[itemIndex].item = option;

                                      //console.log(option)

                                      setProductItems(update);
                                      setproductItemsRefresh(
                                        productItemsRefresh + 1
                                      );

                                      setMenuIsOpen(false);

                                      if (
                                        mitarbeiterkennungOptional === false
                                      ) {
                                        //console.log('Mitarbeiterkennung open')
                                        setMitarbeiterkennung(true);

                                        type LoginFormResult = {
                                          mitarbeiterkennung: string,
                                        };

                                        let mitarbeiterkennungInput: HTMLInputElement;
                                        let show_hide_mitarbeiterkennung: HTMLDivElement;

                                        //Sales person price
                                        const swalWithBootstrapButtons =
                                          Swal.mixin({
                                            customClass: {
                                              confirmButton:
                                                "btn btn-primary me-2",
                                              cancelButton: "btn btn-danger",
                                              input:
                                                "form-control form-control-sm mt-4",
                                            },
                                            buttonsStyling: false,
                                          });

                                        return swalWithBootstrapButtons
                                          .fire({
                                            title: t("Mitarbeiterkennung"),
                                            html: `
                                                                    <div class="input-group">
                                                                        <input type="password" id="mitarbeiterkennung" class="form-control" pattern="[0-9]*" inputmode="numeric">
                                                                        <div class="input-group-append">
                                                                            <span id="show_hide_mitarbeiterkennung" style="left: -35px; top: 10px; position: relative; z-index: 5; cursor: pointer;">
                                                                                <i class="fa fa-eye-slash " aria-hidden="true"></i>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                `,
                                            showCancelButton: true,
                                            confirmButtonText: t("Save"),
                                            cancelButtonText: t("Cancel"),
                                            focusConfirm: false,
                                            didOpen: () => {
                                              const popup = Swal.getPopup();
                                              mitarbeiterkennungInput =
                                                popup.querySelector(
                                                  "#mitarbeiterkennung"
                                                );
                                              show_hide_mitarbeiterkennung =
                                                popup.querySelector(
                                                  "#show_hide_mitarbeiterkennung"
                                                );
                                              mitarbeiterkennungInput.onkeyup =
                                                (event) =>
                                                  event.key === "Enter" &&
                                                  Swal.clickConfirm();
                                              show_hide_mitarbeiterkennung.addEventListener(
                                                "click",
                                                () => {
                                                  if (
                                                    mitarbeiterkennungInput.getAttribute(
                                                      "type"
                                                    ) == "password"
                                                  ) {
                                                    mitarbeiterkennungInput.setAttribute(
                                                      "type",
                                                      "number"
                                                    );
                                                  } else {
                                                    mitarbeiterkennungInput.setAttribute(
                                                      "type",
                                                      "password"
                                                    );
                                                  }
                                                }
                                              );
                                            },
                                            preConfirm: () => {
                                              const mitarbeiterkennung =
                                                mitarbeiterkennungInput.value;
                                              if (
                                                !mitarbeiterkennung ||
                                                !/^[0-9]+$/.test(
                                                  mitarbeiterkennung
                                                )
                                              ) {
                                                Swal.showValidationMessage(
                                                  `gültige Kennung eingeben`
                                                );
                                              } else {
                                                var update = productItems;
                                                update.items[itemIndex].price =
                                                  Number(
                                                    update.items[itemIndex]
                                                      .price
                                                  ) +
                                                  Number(
                                                    mitarbeiterkennung
                                                      ? mitarbeiterkennung
                                                      : 0
                                                  );
                                                update.items[
                                                  itemIndex
                                                ].sales_person_price =
                                                  Number(mitarbeiterkennung);
                                                setProductItems(update);
                                                setproductItemsRefresh(
                                                  productItemsRefresh + 4
                                                );
                                              }
                                              return { mitarbeiterkennung };
                                            },
                                          })
                                          .then((result) => {
                                            if (result.isConfirmed) {
                                            }

                                            //console.log('Mitarbeiterkennung close')
                                            setMitarbeiterkennung(false);
                                          });
                                      }
                                    },
                                  }}
                                  label={option.label}
                                  option={option}
                                  itemIndex={itemIndex}
                                />
                              ) : (
                                ""
                              )}
                            </>
                          ))}
                      </ul>
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </components.Menu>
    );
  };

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <i class="fa-solid fa-chevron-down"></i>
      </components.DropdownIndicator>
    );
  };

  useEffect(() => {
    if (radio && document.getElementById("nav-home-tab")) {
      document.getElementById("nav-home-tab").click();
    }
  }, [radio]);

  useEffect(() => {
    var update = productItems;
    var totalPrice = 0;
    for (let [key, item] of Object.entries(productItems?.items)) {
      var total = Number(item.quantity) * Number(item.price); //without tax, discount

      var discount = ((Number(item.discount) / 100) * Number(total)).toFixed(2);
      var total = Number(total) - Number(discount); //without discount
      var tax = ((Number(item.tax) / 100) * Number(total)).toFixed(2);
      var total = Number(total) + Number(tax); //after tax, discount

      totalPrice += Number(total);
      update.items[key].totalDiscount = discount;
      update.items[key].totalTax = tax;
      update.items[key].total = total;
    }

    update.subTotal = totalPrice;
    update.gesami_netto = totalPrice;

    setProductItems(update);
    setRefresh3(refresh3 + 1);

    calCulateAnlagenProduktion();
  }, [productItemsRefresh]);

  useEffect(() => {
    if (JSON.parse(Cookies.get("permissions")).indexOf("PDU") !== -1) {
      setReadOnly(false);
    } else {
      setReadOnly(true);
    }
    setValue("project_id", props?.projectid ? props?.projectid : params.id);

    callFetch("products/all", "GET", []).then((res) => {
      setBundles(res?.data?.bundles);
      setProducts(res?.data?.products);
      setModules(res?.data?.modules);
      setWechselrichters(res?.data?.wechselrichters);
      setSpeichers(res?.data?.speichers);
      setAllProducts(res?.data?.allProducts);
      setEditDelay(editDelay + 1);
    });
  }, [refresh]);

  useEffect(() => {
    if (editDelay > 0) {
      var perameters =
        "?project_id=" + (props?.projectid ? props?.projectid : params.id);
      if (selectedQuationId) {
        perameters += "&quationId=" + selectedQuationId;
      }

      callFetch(
        "projectdistribution/" +
          (props?.projectid ? props?.projectid : params.id) +
          "/edit" +
          perameters,
        "GET",
        []
      ).then((res) => {
        var resData = res?.data;
        console.log(res?.data);
        if (!resData?.quations) {
          resData.quations = {};
        }
        setPaymentOptions(res?.payment_options);
        Object.keys(res?.payment_options).map((value, index) => {
          if (index === 0) {
            setValue('payment_option', res?.payment_options[value]);
            console.log(res?.payment_options[value]);

          }
        }); 
        setData(resData);
        setMitarbeiterkennungOptional(
          res?.applicationSetting?.mitarbeiterkennung_optional == 1
            ? true
            : false
        );

        //console.log(res?.data);
        setValue("status", res?.data?.status);
        setValue("project_id", res?.data?.id);
        setValue(
          "email",
          res?.data?.email && res?.data?.email != "null"
            ? res?.data?.email
            : ""
        );
        //get data for Update quation
        if (res?.data?.quations && res?.data?.quations?.id) {
          setCreate(false);
          setSelectedQuationId(res?.data?.quations?.id);
          setSpeicherEingeben(res?.data?.quations?.speicher_eingeben);
          setWechselrichter(res?.data?.quations?.wechselrichter_eingeben);
          setModuleEingeben(res?.data?.quations?.module_eingeben);
          setCustomEmail(res?.data?.quations?.email);

          if (res?.data?.quations?.meter_number) {
            var metNumbers = res?.data?.quations?.meter_number;
            setMeter_number(metNumbers.split(","));
          }

          if (res?.data?.quations?.dachtyp) {
            var dachtyps = res?.data?.quations?.dachtyp;
            setDachtyp(dachtyps.split(","));
          }

          if (res?.data?.quations?.dacheindeckung) {
            var dacheindeckung = res?.data?.quations?.dacheindeckung;
            setDacheindeckung(dacheindeckung.split(","));
          }

          if (res?.data?.quations?.productItems) {
            setProductItems(JSON.parse(res?.data?.quations?.productItems));
          }

          if (res?.data?.quations?.newQuation) {
            setNewQuation(
              res?.data?.quations?.newQuation === "no" ? false : true
            );
            //setNewQuation(false);
          }

          for (let [key, value] of Object.entries(res?.data?.quations)) {
            if (key == "filesdata") {
              if (value) {
                // setTabFiles(JSON.parse(value));
              }
            } else if (key == "netto_brotto") {
              setShow(Number(value));
            } else if (key == "module_description") {
              if (value != "undefined") {
                for (let [description_key, description_value] of Object.entries(
                  JSON.parse(value)
                )) {
                  setValue(
                    "module_description[" + description_key + "]",
                    description_value
                  );
                }
              }
            } else if (key == "angebotsdatum") {
              var dateValue = new Date(res?.data?.quations?.created_at); // Remove extra zeros
              var formattedDate = dateValue.toISOString().slice(0, 10);
              setValue(
                "angebotsdatum",
                value == null || value == "null" ? formattedDate : value
              );
            } else if (key == "products") {
              var slectProduct = [];
              var productIds = [];
              for (let [key, PVal] of Object.entries(value)) {
                //console.log(PVal?.product)
                slectProduct.push(PVal?.product);
                productIds.push(PVal?.product?.id);
              }
              setSelectedproduct(slectProduct);
              setValue("products", JSON.stringify(productIds));
            } else if (key != "user") {
              setValue(key, value == "null" ? "" : value);
            }
          }
          setZusatzvereinbarungen(
            getValues("zusatzvereinbarungen")
              ? JSON.parse(getValues("zusatzvereinbarungen"))
              : null
          );

          setMeter_location_floor(
            getValues("meter_location_floor")
              ? JSON.parse(getValues("meter_location_floor"))
              : null
          );
          setStorage_wrspace(
            getValues("storage_wrspace")
              ? JSON.parse(getValues("storage_wrspace"))
              : null
          );
          setHouse_connection_location(
            getValues("house_connection_location")
              ? JSON.parse(getValues("house_connection_location"))
              : null
          );
        } else {
          setCreate(true);
          setNewQuation(true);
          setSelectedQuationId("");
          //setQuationRefresh(quationRefresh + 1);
          //get data for create quation

          setValue("speicher_eingeben", speicherEingeben);
          setValue("wechselrichter_eingeben", wechselrichterEingeben);
          setValue("module_eingeben", moduleEingeben);

          setValue("wirtschaftlichkeitsberechnung", "Nein");
          setValue("angebot_per_post_senden", "Nein");

          //By default Email Address
          //setValue('email', 'info@solacloud.de');
          setCustomEmail("info@solacloud.de");

          //By default Netto
          setShow(1);
          setValue("netto_brotto", 1);

          for (let [key, value] of Object.entries(res.data)) {
            if (["user", "quations", "id", "user_id"].indexOf(key) == -1) {
              if (key == "nr") {
                setValue(
                  "hausnummer",
                  value == null || value == "null" ? "" : value
                );
              } else if (key == "ziegelart") {
                setValue(
                  "dacheindeckung",
                  value == null || value == "null" ? "" : value
                );
              } else {
                setValue(key, value == null || value == "null" ? "" : value);
              }
            }
          }
        }
      });
    }
  }, [editDelay, quationRefresh]);

  useEffect(() => {
    if (!data.quations?.payment_option) return;
    setValue(
      "payment_option",
      data.quations?.payment_option == null ||
        data.quations?.payment_option == "null"
        ? ""
        : data.quations?.payment_option
    );
  }, [data.quations?.payment_option]);

  useEffect(() => {
    setRefresh2(refresh2 + props.refresh);
  }, [props.refresh]);

  const [successSB, setSuccessSB] = useState(false);
  const [errorSB, setErrorSB] = useState(false);
  const openSuccessSB = () => setSuccessSB(true);
  const closeSuccessSB = () => setSuccessSB(false);
  const closeErrorSB = () => setErrorSB(false);
  const renderSuccessSB = (
    <SoftSnackbar
      color={'success'}
      // icon="check"
      title={t("Success")}
      content={t("Changes successfully saved!")} 
      open={successSB}
      onClose={() => closeSuccessSB(false)}
      close={() => closeSuccessSB(false)} 
    />
  );

  const renderErrorSB = (
    <SoftSnackbar
      color="info" 
      title={t("Create the quation first")}
      //content={t("Changes saved successfully")}
      dateTime={t("")}
      open={errorSB}
      onClose={() => closeErrorSB(false)}
      close={() => closeErrorSB(false)} 
    />
  );

  const addNewAngebot = () => {
    //console.log('addNewAngebot')
    setCreate(true);
    setResaleAngebot(true);
    setTimeout(() => {
      document.getElementById("editdstBtn").click();
    }, 1000);
  };

  const editAngebot = (id) => {
    setSelectedQuationId(id);
    setCreate(false);
    setQuationRefresh(quationRefresh + 1);
  };

  const primaryAngebot = (primary, quation_id) => {
    var formData = {};
    console.log(quation_id);
    formData.primary = primary ? "1" : "0";
    formData.id = quation_id;
    formData.project_id = props?.projectid ? props?.projectid : params.id;

    callFetch("primary-angebot", "POST", formData, setError).then((res) => {});
  };

  const onSubmit = (formData) => {
    //console.log(getValues('angebotsdatum'));
   
    if (newQuation === true) {
      formData.gesami_brutto = productItems.gesami_netto * 1.19;
      formData.gesami_netto = productItems.gesami_netto;
      formData.newQuation = "yes";
    } else {
      formData.newQuation = "no";
    }

    formData.productItems = JSON.stringify(productItems);
    formData.module_description = JSON.stringify(
      formData.module_description ? formData.module_description : []
    );
    formData.speicher_without_energiespeichersystem =
      formData.speicher_without_energiespeichersystem == true ? 1 : 0;

    if (resaleAngebot) {
      // console.log('resaleAngebot')
      formData.resale = "1";
    } else {
      formData.resale = "0";
    }

    formData.meter_number = meter_numbers.join(",");
    formData.dachtyp = dachtyp.join(",");
    formData.dacheindeckung = dacheindeckung.join(",");

    setSaving(true);

    var loadingElement = document.querySelector(
      ".res-options .btn-primary-save"
    );

    if (loadingElement) {
      loadingElement.classList.add("btn-secondary");
      loadingElement.innerHTML = t("wird geladen...");
    }

    //console.log('create=' + create)
    if (create) {
      formData._method = "POST";
    }

    callFetch(
      data?.quations?.id && create === false
        ? "projectdistribution/" +
            (props?.projectid ? props?.projectid : params.id)
        : "projectdistribution",
      "POST",
      formData,
      setError
    ).then((res) => {
      setRefresh2(refresh2 + 1);
      setEditDelay(editDelay + 1);
      setRefresh(refresh+1);
      setCreate(false);
      setResaleAngebot(false);

      if (create) {
        setSelectedQuationId("");
        setQuationRefresh(quationRefresh + 1);
      }

      if (formData?.angebot_per_post_senden == "Ja") {
        emailAngebotPerPostSenden();
      }

      setTimeout(() => {
        setSaving(false);

        if (loadingElement) {
          loadingElement.classList.remove("btn-secondary");
          loadingElement.innerHTML = t("Save");
        }

        if (document.getElementById("OrderSalesTab")) {
          document.getElementById("OrderSalesTab").click();
        }

        if (document.getElementById("angebot-from-area")) {
          document.body.scrollTop = 0; // For Safari
          document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
          document.getElementById("angebot-from-area").scrollTop = 0;
        }
        openSuccessSB();
        //if (!res.ok) return;
        //setSubmitSuccess(true);
      }, 2000);
    });
  };

  const onChangeHandeler = (e) => {
    const name = e.target.name;
    if (name == "eigenverbrauch" || name == "stromkosten") {
      kwh_preis_calculate();
    }

    if (name == "angebot_per_post_senden") {
      if (getValues("angebot_per_post_senden") == "Ja") {
        setValue("email", "info@solacloud.de");
      } else {
        setValue("email", customEmail);
      }
    }

    if (name == "gesami_netto") {
      const gesami_netto = Number(productItems.gesami_netto);
      const gesami_brutto = gesami_netto * 1.19;
      setValue("gesami_brutto", gesami_brutto.toFixed(2));
    }
  };

  const kwh_preis_calculate = () => {
    const stromkosten = getValues("stromkosten");
    const eigenverbrauch = getValues("eigenverbrauch");
    const value = stromkosten / eigenverbrauch;
    setValue("kwh_preis", value.toFixed(2));
  };

  const calCulateGesDachflache = () => {
    var module_eingeben = getValues("module_eingeben");
    var anzahl_module = getValues("anzahl_module");
    if (module_eingeben == "no") {
      for (let [key, value] of Object.entries(modules)) {
        if (value?.id == getValues("modul")) {
          var moduleDescription = JSON.parse(value.description);
          var width = moduleDescription?.Modulabmessung_width
            ? moduleDescription?.Modulabmessung_width
            : 0;
          var height = moduleDescription?.Modulabmessung_height
            ? moduleDescription?.Modulabmessung_height
            : 0;
        }
      }
    } else {
      var width = getValues("module_description[Modulabmessung_width]");
      var height = getValues("module_description[Modulabmessung_height]");
    }

    var ges_dachflache =
      (width ? width / 1000 : 0) *
      (height ? height / 1000 : 0) *
      (anzahl_module ? anzahl_module : 0);
    setValue("ges_dachflache", ges_dachflache.toFixed(2));
  };

  const calCulateModuleWatt = () => {
    var totalQuantity = 0;
    var totalWatt = 0;

    //console.log(productItems);

    for (let [key, item] of Object.entries(productItems?.items)) {
      if (item?.type === "product" && item?.item?.watt) {
        var pQuantity = Number(item?.quantity);
        var pWatt = Number(item?.item?.watt);

        if (pWatt && pWatt > 0) {
          totalQuantity += pQuantity;
          totalWatt += pWatt * pQuantity;
        }
      } else if (item?.type === "bundle" && item?.item?.items) {
        var bQuantity = Number(item?.quantity);
        //var pQuantity = Number(item?.quantity);

        var bTotalWatt = 0;
        var bTotalQty = 0;

        for (let [BKey, bItem] of Object.entries(item?.item?.items)) {
          if (bItem?.type === "module") {
            var pQuantity = Number(bItem?.number);

            var pWatt = Number(bItem.module?.watt);
            bTotalWatt += pWatt * pQuantity;
            bTotalQty += pQuantity;

            //console.log(bItem)

            //console.log('bQuantity='+bQuantity+', pQuantity='+pQuantity+', pWatt='+pWatt);
          }
        }

        bTotalWatt = bTotalWatt * bQuantity;
        bTotalQty = bTotalQty * bQuantity;

        totalQuantity += bTotalQty;
        totalWatt += bTotalWatt;

        //console.log('bTotalWatt='+bTotalWatt+', bTotalQty='+bTotalQty);
      }
    }

    //console.log('pWatt='+totalWatt+', qty='+totalQuantity);

    //setValue('anzahl_module', totalQuantity);
    return {
      totalWatt: totalWatt,
      totalQuantity: totalQuantity,
    };
  };

  const calCulateAnlagenProduktion = () => {
    var module_eingeben = getValues("module_eingeben");
    var sonnenstunden = getValues("sonnenstunden");
    var anzahl_module = getValues("anzahl_module");
    var own_consumption = getValues("own_consumption");

    if (newQuation === true) {
      var moduleWatt = calCulateModuleWatt();
      var module_watt = moduleWatt.totalWatt / moduleWatt.totalQuantity;
      var anzahl_module = moduleWatt.totalQuantity;

      setValue("anzahl_module", anzahl_module);
    } else if (module_eingeben == "no") {
      for (let [key, value] of Object.entries(modules)) {
        if (value?.id == getValues("modul")) {
          var module_watt = value?.watt;
        }
      }
    } else {
      var module_watt = getValues("module_watt");
    }

    var total_plant_production =
      (sonnenstunden ? sonnenstunden : 0) *
      ((anzahl_module ? anzahl_module : 0) * (module_watt ? module_watt : 0));
    var total_plant_production = total_plant_production / 1000;

    var feed_in =
      total_plant_production - (own_consumption ? own_consumption : 0);
    setValue("total_plant_production", total_plant_production.toFixed(2));
    setValue("feed_in", feed_in.toFixed(2));

    setRefresh3(refresh3 + 3);
  };

  const emailAngebotPerPostSenden = (formData = {}) => {
    if (!data?.quations?.id) return;

    setSaving(true);

    callFetch(
      "quation/" + data?.quations?.id + "?pdf_generate",
      "GET",
      []
    ).then((res) => {
      if (res?.message == "success") {
        formData.type_id = data?.quations?.id;
        formData.type = "angebot";
        formData.pdf = true;
        formData.email = "info@solacloud.de";

        setSaving(true);
        callFetch("sendemail", "POST", formData, setError).then((res) => {
          setSaving(false);
          if (!res.ok) return;
          setSubmitSuccess(true);
        });
      } else {
        setSaving(false);
      }
      setSubmitSuccess(true);
    });
  };

  const [currentStep, setCurrentStep] = useState(0);
  const steps = ["Step 1", "Step 2"];

  const handleNext = () => {
    if (currentStep < steps.length - 1) {
      setCurrentStep(currentStep + 1);
    } else {
      alert("Form submitted!");
    }
  };

  const handlePrev = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  return (
    <>
      <div className="row">
        <div
          className={
            props?.fullwidth != false
              ? "col-sm-12 col-md-12 col-lg-8 col-xl-8 col-xxl-9 mb-3 "
              : "col-sm-12 col-md-12 col-lg-8 col-xl-8 col-xxl-9 mb-3 "
          }
          id="angebot-from-area"
        >
          <div className="">
            <div className="step-progress plr-7rem">
              <div className="step-wrapper cursor-pointer" onClick={() => setCurrentStep(0)}>
                <div
                  className={`step ${currentStep >= 0 ? "completed" : ""}`}
                  id="step-1"
                >
                  {currentStep > 0 ? <i className="fa fa-check"></i> : "1"}
                </div>
                <div
                  className={`step-line ${currentStep > 0 ? "completed" : ""}`}
                  id="line-1"
                >
                  <span
                    className={`${
                      currentStep == 0 ? "" : "d-none-xs-cp-progress"
                    }`}
                  >
                    Angebot
                  </span>
                </div>
              </div>

              <div className="step-wrapper cursor-pointer" style={{ flexGrow: "0" }} onClick={() => setCurrentStep(1)}>
                <div
                  className={`step ${currentStep >= 1 ? "completed" : ""}`}
                  id="step-2"
                >
                  {currentStep > 1 ? <i className="fa fa-check"></i> : "2"}
                </div>
                <div>
                  <span
                    className={`${
                      currentStep == 1 ? "" : "d-none-xs-cp-progress"
                    }`}
                  >
                    Digital Unterschreiben
                  </span>
                </div>
              </div>
            </div>

            <div className="step-card ">
              {currentStep === 0 && (
                <div className="step-content">
                  <div className="card inner-scroll-two  sc-pb">
                    <div className="card-body pb-3 callcenter">
                      <ul
                        className="nav nav-tabs nav-underline tabs-res"
                        id="myTab"
                        role="tablist"
                        style={{ width: "97.5%" }}
                      >
                        <li className="nav-item" role="presentation" onClick={() => setIsOpen(false)}>
                          <a 
                            className="nav-link active p-0"
                            style={{padding: '8px'}}
                            id="home-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#home-tab-pane"
                            type="button"
                            role="tab"
                            aria-controls="home-tab-pane"
                            aria-selected="true"
                           
                          >
                            Angebot
                          </a>
                        </li>

                        <li className="nav-item" role="presentation" onClick={() => setIsOpen(true)}>
                          <a
                            className="nav-link p-0"
                            style={{padding: '8px'}}
                            id="wirtsch-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#wirtsch-tab-pane"
                            type="button"
                            role="tab"
                            aria-controls="wirtsch-tab-pane"
                            aria-selected="true" 
                          >
                            Wirtschaftlichkeitsberechnung
                          </a>
                        </li>
                      </ul>
                      <div className="tab-content" id="myTabContent">
                        <div
                          className="tab-pane fade show active pt-3 tabs"
                          id="home-tab-pane"
                          role="tabpanel"
                          aria-labelledby="home-tab"
                          tabIndex="0"
                        >
                          <div
                            className="accordion accordion-flush"
                            id="accordionFlushExamplea"
                          >
                            <div className="accordion-item bg-secondary p-3 border-radius-8">
                              <h2 className="accordion-header">
                                <button
                                  className="accordion-button collapsed"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#flush-collapseOne"
                                  aria-expanded="false"
                                  aria-controls="flush-collapseOne"
                                >
                                  <span className="accordion-arrow">
                                    <i className="ph ph-caret-right"></i>
                                  </span>
                                  <p className="font-size-20 font-weight-600 text-primary">
                                    <span className="pl-2">{t('Kundendaten')}</span>
                                  </p>
                                </button>
                              </h2>
                              <div
                                id="flush-collapseOne"
                                className="accordion-collapse collapse"
                                data-bs-parent="#accordionFlushExamplea"
                              >
                                <div className="accordion-body p-0">
                                  {props?.overview != false ? (
                                    <>
                                      <Overview
                                        bg="white"
                                        quation_id={data?.quations?.id}
                                        project_id={
                                          props?.projectid
                                            ? props?.projectid
                                            : params.id
                                        }
                                        title={t("Quotation")}
                                      ></Overview>
                                    </>
                                  ) : (
                                    ""
                                  )}

                                  <div className="row mt-3">
                                    <div className="col-sm-6">
                                      <div className="form-group">
                                        <label>E-mail Adresse</label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          {...register("email")}
                                          required
                                        />
                                      </div>
                                    </div>
                                    <div className="col-sm-6">
                                      <div className="form-group">
                                        <label>Angebotsdatum</label>
                                        <input
                                          type="date"
                                          className="form-control"
                                          {...register("angebotsdatum")}
                                          required
                                        />
                                      </div>
                                    </div>
                                    <div className="col-sm-6">
                                      <div class="form-check">
                                        <input
                                          class="form-check-input"
                                          type="checkbox"
                                          value=""
                                          id="flexCheckDefault"
                                        />
                                        <label
                                          class="form-check-label"
                                          for="flexCheckDefault"
                                        >
                                          Referenzkunde
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="row mt-3">
                            <div className="col-md-12">
                              <form
                                className={`needs-validation ${
                                  Object.keys(errors).length
                                    ? "was-validated"
                                    : ""
                                }`}
                                onSubmit={handleSubmit(onSubmit)}
                                noValidate
                                autoComplete="off"
                                id="editdst"
                              >
                                <input
                                  type="hidden"
                                  className="form-control form-control-sm"
                                  placeholder={t("")}
                                  {...register("project_id")}
                                />

                                {data?.quations?.id ? (
                                  <input
                                    type="hidden"
                                    defaultValue="PUT"
                                    {...register("_method")}
                                  />
                                ) : (
                                  ""
                                )}

                                <>
                                  {readOnly == false || newQuation === false ? (
                                    <div
                                      className="accordion accordion-flush"
                                      id="accordionFlushExampleb"
                                    >
                                      <div className="accordion-item bg-secondary p-3 border-radius-8">
                                        <h2 className="accordion-header">
                                          <div className="d-flex justify-content-between">
                                            <div className="d-flex">
                                              <button
                                                className="accordion-button mb-0"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#flush-collapseFour"
                                                aria-expanded="false"
                                                aria-controls="flush-collapseFour"
                                              >
                                                <span className="accordion-arrow">
                                                  <i className="ph ph-caret-right"></i>
                                                </span>
                                                <p className="font-size-20 font-weight-600 text-primary">
                                                  <span className="pl-2">
                                                    Positionen
                                                  </span>
                                                </p>
                                              </button>
                                            </div>
                                            <div>
                                              <button
                                                type="button"
                                                className="btn btn-secondary mb-0"
                                                style={{
                                                  textTransform: "uppercase",
                                                  fontWeight: 600,
                                                }}
                                                onClick={() => {
                                                  var items = productItems;
                                                  items.items.push({
                                                    id: 0,
                                                    type: "product",
                                                    product_type: "",
                                                    product_form: "",
                                                    quantity: 1,
                                                    price: 0,
                                                    sales_person_price: 0,
                                                    tax: 0,
                                                    discount: 0,
                                                    totalDiscount: 0,
                                                    total: 0,
                                                    lieferanten_id: 0,
                                                    item: [],
                                                  });
                                                  setProductItems(items);
                                                  setproductItemsRefresh(
                                                    productItemsRefresh + 1
                                                  );
                                                  setProductIndex(
                                                    productItems.items.length -
                                                      1
                                                  );
                                                  setShowTwo(true);
                                                }}
                                              >
                                                <i class="ph ph-plus"></i>{" "}
                                                <span className="position-relative top--4">Zusatzprodukt</span>
                                              </button>

                                              <button
                                                type="button"
                                                className="btn ms-2 mb-0"
                                                style={{
                                                  background: "#2684FF",
                                                  color: "#ffffff",
                                                  textTransform: "uppercase",
                                                  fontWeight: 600,
                                                }}
                                                onClick={() => {
                                                  var items = productItems;
                                                  items.items.push({
                                                    id: 0,
                                                    type: "bundle",
                                                    product_type: "",
                                                    quantity: 1,
                                                    price: 0,
                                                    sales_person_price: 0,
                                                    tax: 0,
                                                    discount: 0,
                                                    totalDiscount: 0,
                                                    total: 0,
                                                    lieferanten_id: 0,
                                                    item: [],
                                                  });

                                                  setProductItems(items);
                                                  setproductItemsRefresh(
                                                    productItemsRefresh + 1
                                                  );
                                                  setProductIndex(
                                                    productItems.items.length -
                                                      1
                                                  );
                                                  setShowTwo(true);
                                                }}
                                              >
                                                <i class="ph ph-plus"></i>{" "}
                                                <span className="position-relative top--4">Bundle</span>
                                              </button>
                                            </div>
                                          </div>
                                        </h2>
                                        <div
                                          id="flush-collapseFour"
                                          className="accordion-collapse collapse show"
                                          data-bs-parent="#accordionFlushExampleb"
                                        >
                                          <div className="accordion-body mt-2">
                                            {newQuation === true ? (
                                              <>
                                                <div class="table-responsive border table-border-radius">
                                                  <table className="table bg-white">
                                                    <thead>
                                                      <tr>
                                                        <th
                                                          style={{
                                                            width: "20px",
                                                            padding:
                                                              "16px 12px",
                                                          }}
                                                        >
                                                          <label>
                                                            {t("Pos.")}
                                                          </label>
                                                        </th>

                                                        <th
                                                          style={{
                                                            width: "30px",
                                                            padding:
                                                              "16px 12px",
                                                          }}
                                                        ></th>

                                                        <th
                                                          style={{
                                                            minWidth: "130px",
                                                            padding:
                                                              "16px 12px",
                                                          }}
                                                        >
                                                          <label>
                                                            {t("Artikel")}
                                                          </label>
                                                        </th>
                                                        <th
                                                          style={{
                                                            width: "100px",
                                                            padding:
                                                              "16px 12px",
                                                          }}
                                                        >
                                                          <label>
                                                            {t("Menge")}
                                                          </label>
                                                        </th>
                                                        <th
                                                          style={{
                                                            width: "130px",
                                                            padding:
                                                              "16px 12px",
                                                          }}
                                                        >
                                                          <label>
                                                            {t("Preis (€)")}
                                                          </label>
                                                        </th>
                                                        <th
                                                          style={{
                                                            width: "200px",
                                                            padding:
                                                              "16px 12px",
                                                          }}
                                                        >
                                                          <label>
                                                            {t("Steuer")}
                                                          </label>
                                                        </th>
                                                        <th
                                                          style={{
                                                            width: "200px",
                                                            padding:
                                                              "16px 12px",
                                                          }}
                                                        >
                                                          <label>
                                                            {t("Rabatt (%)")}
                                                          </label>
                                                        </th>
                                                        <th
                                                          className="fixed-column-right"
                                                          style={{
                                                            width: "150px",
                                                            padding:
                                                              "16px 12px",
                                                          }}
                                                        >
                                                          <label className="me-4">
                                                            {t(
                                                              "Pos. Gesamt (€)"
                                                            )}
                                                          </label>
                                                          &nbsp;
                                                          &nbsp;
                                                          &nbsp;
                                                          &nbsp;
                                                          &nbsp;
                                                          &nbsp;
                                                        </th> 
                                                      </tr>
                                                    </thead>
                                                    <tbody>
                                                      {productItems?.items.map(
                                                        (productItem, j) => (
                                                          <>
                                                            <tr key={j + 1} className="pos-action-con">
                                                              <td>
                                                                <p
                                                                  className="p-0 m-0 mb-0 text-primary"
                                                                  style={{
                                                                    fontSize:
                                                                      "0.875rem",
                                                                    fontWeight:
                                                                      "normal",
                                                                  }}
                                                                >
                                                                  {j + 1}
                                                                </p>
                                                              </td>
                                                              <td>
                                                                <span
                                                                  style={{
                                                                    cursor:
                                                                      "pointer",
                                                                  }}
                                                                  onClick={() =>
                                                                    toggleRow(
                                                                      j + 1
                                                                    )
                                                                  }
                                                                >
                                                                  {expandedRow ==
                                                                  j + 1 ? (
                                                                    <i class="ph ph-caret-down font-size-20 table-toggle-btn"></i>
                                                                  ) : (
                                                                    <i class="ph ph-caret-right font-size-20 table-toggle-btn"></i>
                                                                  )}
                                                                </span>
                                                              </td>
                                                              <td onClick={() => { setProductIndex(
                                                                            j
                                                                          );
                                                                          setShowTwo(
                                                                            true
                                                                          );}}>
                                                                <Select
                                                                  value={
                                                                    productItems
                                                                      .items[j]
                                                                      .item
                                                                  }
                                                                  
                                                                   
                                                                  placeholder={
                                                                    productItem?.type ==
                                                                    "bundle"
                                                                      ? "Bundle"
                                                                      : "Zusatzprodukt"
                                                                  }
                                                                  // onMenuOpen={() => setMenuIsOpen(true)}
                                                                  // onMenuClose={() => setMenuIsOpen(false)}
                                                                  menuIsOpen={false}
                                                                  //onInputChange={handleInputChange}
                                                                  styles={{
                                                                    control: (
                                                                      baseStyles,
                                                                      state
                                                                    ) => ({
                                                                      ...baseStyles,
                                                                      
                                                                      fontSize:
                                                                        "0.875rem",
                                                                      padding:
                                                                        "0rem 0rem",
                                                                      borderRadius:
                                                                        "0.25rem",
                                                                      width: '200px',
                                                                      borderColor: '#E7EBF3',
                                                                      height: '34px !important',
                                                                      minHeight: '34px !important',
                                                                      lineHeight: '24px'
                                                                    }),
                                                                    menu: (
                                                                      provided,
                                                                      state
                                                                    ) => ({
                                                                      ...provided,
                                                                      width:
                                                                        productItems
                                                                          .items[
                                                                          j
                                                                        ]
                                                                          .type ===
                                                                          "bundle" &&
                                                                        productItems
                                                                          .items[
                                                                          j
                                                                        ]
                                                                          .searchWatt
                                                                          ? "820px"
                                                                          : productItems
                                                                              ?.items[
                                                                              j
                                                                            ]
                                                                              ?.product_form ==
                                                                              "zusatzprodukte" ||
                                                                            productItems
                                                                              .items[
                                                                              j
                                                                            ]
                                                                              .type ===
                                                                              "bundle"
                                                                          ? "770px"
                                                                          : "600px", 
                                                                          // Adjust the width as needed
                                                                      //backgroundColor: '#f2f2f2', // Background color
                                                                      // borderRadius: '4px', // Rounded corners
                                                                      //boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', // Box shadow
                                                                    }),
                                                                  }}
                                                                />
                                                              </td>
                                                              <td>
                                                                <input
                                                                 style={{width: '65px'}}
                                                                  className="form-control form-control-sm text-primary"
                                                                  type="number"
                                                                  min={0}
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    var update =
                                                                      productItems;
                                                                    update.items[
                                                                      j
                                                                    ].quantity =
                                                                      e.target
                                                                        .value <
                                                                      0
                                                                        ? 0
                                                                        : e
                                                                            .target
                                                                            .value;
                                                                    setProductItems(
                                                                      update
                                                                    );
                                                                    setproductItemsRefresh(
                                                                      productItemsRefresh +
                                                                        1
                                                                    );
                                                                  }}
                                                                  value={
                                                                    productItems
                                                                      .items[j]
                                                                      .quantity
                                                                  }
                                                                />
                                                              </td>
                                                              <td>
                                                                {mitarbeiterkennung ? (
                                                                  <input
                                                                  style={{width: '65px'}}
                                                                    className="form-control form-control-sm text-primary"
                                                                    type="number"
                                                                    styp={0.01}
                                                                    readOnly
                                                                  />
                                                                ) : (
                                                                  <input
                                                                  style={{width: '65px'}}
                                                                    className="form-control form-control-sm text-primary"
                                                                    type="number"
                                                                    styp={0.01}
                                                                    min={0}
                                                                    onChange={(
                                                                      e
                                                                    ) => {
                                                                      var update =
                                                                        productItems;
                                                                      update.items[
                                                                        j
                                                                      ].price =
                                                                        e.target
                                                                          .value <
                                                                        0
                                                                          ? 0
                                                                          : e
                                                                              .target
                                                                              .value;
                                                                      setProductItems(
                                                                        update
                                                                      );
                                                                      setproductItemsRefresh(
                                                                        productItemsRefresh +
                                                                          1
                                                                      );
                                                                    }}
                                                                    value={
                                                                      productItems
                                                                        .items[
                                                                        j
                                                                      ].price
                                                                    }
                                                                  />
                                                                )}
                                                              </td>
                                                              <td>
                                                                <div className="d-flex">
                                                                  <select
                                                                  style={{width: '65px'}}
                                                                    className="form-control form-control-sm text-primary"
                                                                    type="number"
                                                                    onChange={(
                                                                      e
                                                                    ) => {
                                                                      var update =
                                                                        productItems;
                                                                      update.items[
                                                                        j
                                                                      ].tax =
                                                                        e.target.value;
                                                                      setProductItems(
                                                                        update
                                                                      );
                                                                      setproductItemsRefresh(
                                                                        productItemsRefresh +
                                                                          1
                                                                      );
                                                                    }}
                                                                    value={
                                                                      productItems
                                                                        .items[
                                                                        j
                                                                      ].tax
                                                                    }
                                                                  >
                                                                    <option
                                                                      value={0}
                                                                    >
                                                                      0%
                                                                    </option>
                                                                    <option
                                                                      value={19}
                                                                    >
                                                                      19%
                                                                    </option>
                                                                  </select>

                                                                  <p
                                                                    className="p-0 m-0 mt-2 ms-1"
                                                                    style={{
                                                                      fontSize:
                                                                        "0.75rem",
                                                                      fontWeight:
                                                                        "normal",
                                                                      color:
                                                                        "red",
                                                                    }}
                                                                  >
                                                                    -
                                                                    <NumericFormat
                                                                      value={Number(
                                                                        mitarbeiterkennung
                                                                          ? 0
                                                                          : productItems
                                                                              .items[
                                                                              j
                                                                            ]
                                                                              .totalTax
                                                                      )}
                                                                      displayType="text"
                                                                      thousandSeparator={
                                                                        "."
                                                                      }
                                                                      decimalSeparator=","
                                                                      decimalScale={
                                                                        2
                                                                      }
                                                                      fixedDecimalScale
                                                                      suffix={
                                                                        " € "
                                                                      }
                                                                    />
                                                                  </p>
                                                                </div>
                                                              </td>
                                                              <td>
                                                                <div className="d-flex">
                                                                  <input
                                                                  style={{width: '65px'}}
                                                                    className="form-control form-control-sm text-primary"
                                                                    type="number"
                                                                    styp={0.01}
                                                                    min={0}
                                                                    max={10}
                                                                    onChange={(
                                                                      e
                                                                    ) => {
                                                                      var update =
                                                                        productItems;
                                                                      update.items[
                                                                        j
                                                                      ].discount =
                                                                        e.target
                                                                          .value <
                                                                        0
                                                                          ? 0
                                                                          : e
                                                                              .target
                                                                              .value >
                                                                            10
                                                                          ? 10
                                                                          : e
                                                                              .target
                                                                              .value;
                                                                      setProductItems(
                                                                        update
                                                                      );
                                                                      setproductItemsRefresh(
                                                                        productItemsRefresh +
                                                                          1
                                                                      );
                                                                    }}
                                                                    value={
                                                                      productItems
                                                                        .items[
                                                                        j
                                                                      ].discount
                                                                    }
                                                                  />

                                                                  <p
                                                                    className="p-0 m-0 mt-2 ms-1"
                                                                    style={{
                                                                      fontSize:
                                                                        "0.875rem",
                                                                      fontWeight:
                                                                        "normal",
                                                                      color:
                                                                        "red",
                                                                    }}
                                                                  >
                                                                    -
                                                                    <NumericFormat
                                                                      value={Number(
                                                                        mitarbeiterkennung
                                                                          ? 0
                                                                          : productItems
                                                                              .items[
                                                                              j
                                                                            ]
                                                                              .totalDiscount
                                                                      )}
                                                                      displayType="text"
                                                                      thousandSeparator={
                                                                        "."
                                                                      }
                                                                      decimalSeparator=","
                                                                      decimalScale={
                                                                        2
                                                                      }
                                                                      fixedDecimalScale
                                                                      suffix={
                                                                        " € "
                                                                      }
                                                                    />
                                                                  </p>
                                                                </div>
                                                              </td>
                                                              <td  className="fixed-column-right">
                                                                <div className="d-flex align-items-center justify-content-between"> 
                                                                <p
                                                                  className="p-0 m-0 mt-2 text-primary"
                                                                  style={{
                                                                    fontSize:
                                                                      "0.875rem",
                                                                    fontWeight:
                                                                      "normal",
                                                                  }}
                                                                >
                                                                  <NumericFormat
                                                                    value={Number(
                                                                      mitarbeiterkennung
                                                                        ? 0
                                                                        : productItems
                                                                            .items[
                                                                            j
                                                                          ]
                                                                            .total
                                                                    )}
                                                                    displayType="text"
                                                                    thousandSeparator={
                                                                      "."
                                                                    }
                                                                    decimalSeparator=","
                                                                    decimalScale={
                                                                      2
                                                                    }
                                                                    fixedDecimalScale
                                                                    suffix={
                                                                      " € "
                                                                    }
                                                                  />
                                                                </p>

                                                                <div class="dropdown d-flex align-items-center gap-2 ">
                                                                  <i class="ph ph-pencil-simple font-size-20 cursor-pointer pos-action" onClick={() => {
                                                                          setProductIndex(
                                                                            j
                                                                          );
                                                                          setShowTwo(
                                                                            true
                                                                          );
                                                                        }}></i>
                                                                  <i class="ph ph-trash-simple font-size-20 cursor-pointer text-danger" onClick={() => {
                                                                          var update =
                                                                            productItems;
                                                                          update.items.splice(
                                                                            j,
                                                                            1
                                                                          );
                                                                          setProductItems(
                                                                            update
                                                                          );
                                                                          setproductItemsRefresh(
                                                                            productItemsRefresh +
                                                                              1
                                                                          );
                                                                        }}></i>
                                                                  <button
                                                                    class="btn d-none"
                                                                    style={{
                                                                      height:
                                                                        "0px",
                                                                    }}
                                                                    type="button"
                                                                    id="dotsDropdown"
                                                                    data-bs-toggle="dropdown"
                                                                    aria-expanded="false"
                                                                  >
                                                                    <img
                                                                      src="/assets/icons/DotsThreeVertical.svg"
                                                                      className="dropdown-btn-empty-icon-img"
                                                                    />
                                                                  </button>
                                                                  <ul
                                                                    class="dropdown-menu"
                                                                    aria-labelledby="dotsDropdown"
                                                                  >
                                                                    <li>
                                                                      <a
                                                                        class="dropdown-item d-flex align-items-center"
                                                                        href="#"
                                                                        onClick={() => {
                                                                          setProductIndex(
                                                                            j
                                                                          );
                                                                          setShowTwo(
                                                                            true
                                                                          );
                                                                        }}
                                                                      >
                                                                        <i class="ph ph-pencil font-size-20"></i>{" "}
                                                                        <span className="position-relative left-4">
                                                                          {t(
                                                                            "Bearbeiten"
                                                                          )}
                                                                        </span>
                                                                      </a>
                                                                    </li>

                                                                    <li>
                                                                      <a
                                                                        class="dropdown-item d-flex align-items-center"
                                                                        href="#"
                                                                        onClick={() => {
                                                                          var update =
                                                                            productItems;
                                                                          update.items.splice(
                                                                            j,
                                                                            1
                                                                          );
                                                                          setProductItems(
                                                                            update
                                                                          );
                                                                          setproductItemsRefresh(
                                                                            productItemsRefresh +
                                                                              1
                                                                          );
                                                                        }}
                                                                      >
                                                                        <i class="ph ph-trash font-size-20"></i>{" "}
                                                                        <span className="position-relative left-4">
                                                                          {t(
                                                                            "Delete"
                                                                          )}
                                                                        </span>
                                                                      </a>
                                                                    </li>
                                                                  </ul>
                                                                </div>
                                                                </div>
                                                              </td> 
                                                            </tr>
                                                            {expandedRow ==
                                                              j + 1 && (
                                                              <tr>
                                                                <td colSpan={5}>
                                                                  {productItem?.type ==
                                                                    "bundle" &&
                                                                  productItems
                                                                    .items[j]
                                                                    .item.id ? (
                                                                    <div className="position-expand-data">
                                                                      <p
                                                                        style={{
                                                                          fontSize:
                                                                            "0.875rem",
                                                                          fontWeight:
                                                                            "600",
                                                                        }}
                                                                        className="m-0 p-0 text-secondary"
                                                                      >
                                                                        {
                                                                          productItems
                                                                            .items[
                                                                            j
                                                                          ].item
                                                                            ?.label
                                                                        }
                                                                      </p>
                                                                      <hr />
                                                                      {productItems
                                                                        .items[
                                                                        j
                                                                      ].item
                                                                        ?.items
                                                                        ? productItems.items[
                                                                            j
                                                                          ].item?.items.map(
                                                                            (
                                                                              item,
                                                                              item_index
                                                                            ) => (
                                                                              <>
                                                                                <div className="d-flex justify-content-between align-items-center">
                                                                                  <p className="text-secondary font-size-14 mb-0">
                                                                                    {
                                                                                      item[
                                                                                        item
                                                                                          ?.type
                                                                                      ]
                                                                                        ?.name
                                                                                    }
                                                                                  </p>
                                                                                  <p className="text-primary font-size-14 mb-0 font-weight-500">
                                                                                    {item?.number +
                                                                                      " Stück"} 
                                                                                  </p>
                                                                                </div>
                                                                              </>
                                                                            )
                                                                          )
                                                                        : ""}
                                                                    </div>
                                                                  ) : (
                                                                    ""
                                                                  )}
                                                                </td>
                                                              </tr>
                                                            )}
                                                          </>
                                                        )
                                                      )}
                                                    </tbody>
                                                    <tfoot>

                                                      <tr style={{ borderTop: "1px solid #E7EBF3"}}>
                                                        <td colSpan={6}></td> 
                                                        <td colSpan={3}  className="fixed-column-right">
                                                          <div className="d-flex justify-content-between">
                                                            <p>Positionen Netto</p>
                                                            <p className="font-size-14 p-0 m-0 mt-1 text-end"> 
                                                              <NumericFormat
                                                                  value={Number(
                                                                    mitarbeiterkennung
                                                                      ? 0
                                                                      : productItems?.subTotal
                                                                  )}
                                                                  displayType="text"
                                                                  thousandSeparator={
                                                                    "."
                                                                  }
                                                                  decimalSeparator=","
                                                                  decimalScale={2}
                                                                  fixedDecimalScale
                                                                  suffix={" € "}
                                                                />
                                                              </p>
                                                          </div> 
                                                        </td> 
                                                      </tr>

                                                      <tr style={{ borderTop: "1px solid #E7EBF3"}}>
                                                        <td colSpan={6}></td> 
                                                        <td colSpan={3}  className="fixed-column-right">
                                                          <div className="d-flex justify-content-between">
                                                            <p>
                                                              USt. Positionen (
                                                              <NumericFormat
                                                                value={Number(
                                                                  mitarbeiterkennung
                                                                    ? 0
                                                                    : productItems?.ust_positionen
                                                                )}
                                                                displayType="text"
                                                                thousandSeparator={
                                                                  "."
                                                                }
                                                                decimalSeparator=","
                                                                decimalScale={2}
                                                                fixedDecimalScale
                                                                suffix={" % "}
                                                              />
                                                              )
                                                            </p>

                                                            <p className="font-size-14 p-0 m-0 mt-1 text-end"> 
                                                              <NumericFormat
                                                                value={Number(
                                                                  mitarbeiterkennung
                                                                    ? 0
                                                                    : productItems?.ust_positionen_amount
                                                                )}
                                                                displayType="text"
                                                                thousandSeparator={
                                                                  "."
                                                                }
                                                                decimalSeparator=","
                                                                decimalScale={2}
                                                                fixedDecimalScale
                                                                suffix={" € "}
                                                              />
                                                            </p>
                                                          </div> 
                                                        </td> 
                                                      </tr>


                                                      <tr style={{ borderTop: "1px solid #E7EBF3"}}>
                                                        <td colSpan={6}></td> 
                                                        <td colSpan={3}  className="fixed-column-right">
                                                          <div className="d-flex justify-content-between">
                                                            <p className="font-size-14 p-0 m-0 mt-1 font-weight-500">Gesamt Brutto</p>
                                                            <p className="font-size-14 p-0 m-0 mt-1 text-end">
                                                              <NumericFormat
                                                                  value={Number(
                                                                    mitarbeiterkennung
                                                                      ? 0
                                                                      : productItems?.gesami_netto
                                                                  )}
                                                                  displayType="text"
                                                                  thousandSeparator={
                                                                    "."
                                                                  }
                                                                  decimalSeparator=","
                                                                  decimalScale={2}
                                                                  fixedDecimalScale
                                                                  suffix={" € "}
                                                                />
                                                              </p>
                                                          </div> 
                                                        </td> 
                                                      </tr>

                                                      
                                                    </tfoot>
                                                  </table>
                                                </div>
                                              </>
                                            ) : (
                                              <div className="row g-3">
                                                {/*
                                                          <div className="col-12 col-md-2 mt-0">
                                                              <label>{t('Dacheindeckung')} *</label>
                                                          </div>
                                                          <div className="col-12 col-md-4 mt-0 mb-1">
  
                                                          </div>
                                                      */}

                                                <div className="col-md-6 mt-0 mb-1">
                                                  <div className="form-check">
                                                    <label>
                                                      {t("Speicher")}
                                                    </label>
                                                    <input
                                                      className="form-check-input"
                                                      type="checkbox"
                                                      onChange={(e) => {
                                                        if (
                                                          e.target.checked ===
                                                          true
                                                        ) {
                                                          setSpeicherEingeben(
                                                            "yes"
                                                          );
                                                          setValue(
                                                            "speicher_eingeben",
                                                            "yes"
                                                          );
                                                          data.quations.speicher_eingeben =
                                                            "yes";
                                                        } else {
                                                          setSpeicherEingeben(
                                                            "no"
                                                          );
                                                          setValue(
                                                            "speicher_eingeben",
                                                            "no"
                                                          );
                                                          data.quations.speicher_eingeben =
                                                            "no";
                                                        }
                                                        setData(data);
                                                        setRefresh3(
                                                          refresh3 + 3
                                                        );
                                                      }}
                                                      checked={
                                                        data?.quations
                                                          ?.speicher_eingeben ===
                                                        "yes"
                                                          ? true
                                                          : false
                                                      }
                                                    />
                                                  </div>
                                                </div>

                                                {speicherEingeben === "no" ? (
                                                  <>
                                                    <div className="col-12 col-md-2 mt-0">
                                                      <label>
                                                        {t("Speicher")}{" "}
                                                      </label>
                                                    </div>
                                                    <div className="col-12 col-md-4 mt-0 mb-1">
                                                      <select
                                                        className="form-control form-control-sm"
                                                        {...register(
                                                          "speicher",
                                                          {
                                                            required: false,
                                                            disabled: readOnly,
                                                          }
                                                        )}
                                                      >
                                                        <option value="">
                                                          --
                                                        </option>
                                                        {speichers &&
                                                          speichers.map(
                                                            (speicher) => (
                                                              <option
                                                                key={
                                                                  speicher.id
                                                                }
                                                                value={
                                                                  speicher.id
                                                                }
                                                              >
                                                                {speicher.name}
                                                              </option>
                                                            )
                                                          )}
                                                      </select>
                                                      <div className="invalid-feedback">
                                                        {errors.speicher &&
                                                          errors.speicher
                                                            .message}
                                                      </div>
                                                    </div>
                                                  </>
                                                ) : (
                                                  <>
                                                    <div className="col-12 col-md-2 mt-0">
                                                      <label>
                                                        {t("Speicher Titel")}{" "}
                                                      </label>
                                                    </div>
                                                    <div className="col-12 col-md-4 mt-0 mb-1">
                                                      <input
                                                        type="text"
                                                        className="form-control form-control-sm"
                                                        placeholder={t("")}
                                                        {...register(
                                                          "speicher_titel",
                                                          {
                                                            required: false,
                                                            disabled: readOnly,
                                                          }
                                                        )}
                                                        required
                                                      />
                                                      <div className="invalid-feedback">
                                                        {errors.speicher_titel &&
                                                          errors.speicher_titel
                                                            .message}
                                                      </div>
                                                    </div>

                                                    <div className="col-12 mt-0 mb-1">
                                                      <label>
                                                        {t(
                                                          "Speicher Description"
                                                        )}
                                                      </label>
                                                      <textarea
                                                        className="form-control form-control-sm"
                                                        rows={4}
                                                        {...register(
                                                          "speicher_description",
                                                          {
                                                            required: false,
                                                            disabled: readOnly,
                                                          }
                                                        )}
                                                      ></textarea>
                                                    </div>

                                                    <div className="col-12 mt-0 mb-1">
                                                      <div className="form-check">
                                                        <label>
                                                          {t(
                                                            "Ohne Energiespeicher"
                                                          )}
                                                        </label>
                                                        <input
                                                          className="form-check-input"
                                                          type="checkbox"
                                                          value={""}
                                                          {...register(
                                                            "speicher_without_energiespeichersystem",
                                                            {
                                                              disabled:
                                                                readOnly,
                                                            }
                                                          )}
                                                        />
                                                      </div>
                                                      <div className="invalid-feedback">
                                                        {errors.speicher_without_energiespeichersystem &&
                                                          errors
                                                            .speicher_without_energiespeichersystem
                                                            .message}
                                                      </div>
                                                    </div>
                                                  </>
                                                )}

                                                <div className="col-md-6 mt-0 mb-1">
                                                  <div className="form-check">
                                                    <label>
                                                      {t("Wechselrichter")}
                                                    </label>
                                                    <input
                                                      className="form-check-input"
                                                      type="checkbox"
                                                      onChange={(e) => {
                                                        if (
                                                          e.target.checked ===
                                                          true
                                                        ) {
                                                          setWechselrichter(
                                                            "yes"
                                                          );
                                                          setValue(
                                                            "wechselrichter_eingeben",
                                                            "yes"
                                                          );
                                                          data.quations.wechselrichter_eingeben =
                                                            "yes";
                                                        } else {
                                                          setWechselrichter(
                                                            "no"
                                                          );
                                                          setValue(
                                                            "wechselrichter_eingeben",
                                                            "no"
                                                          );
                                                          data.quations.wechselrichter_eingeben =
                                                            "no";
                                                        }
                                                        setData(data);
                                                        setRefresh3(
                                                          refresh3 + 3
                                                        );
                                                      }}
                                                      checked={
                                                        data?.quations
                                                          ?.wechselrichter_eingeben ===
                                                        "yes"
                                                          ? true
                                                          : false
                                                      }
                                                    />
                                                  </div>
                                                </div>

                                                {wechselrichterEingeben ===
                                                "no" ? (
                                                  <>
                                                    <div className="col-12 col-md-2 mt-0">
                                                      <label>
                                                        {t(
                                                          "Wechselrichter (Kein Pflichtfeld)"
                                                        )}
                                                      </label>
                                                    </div>
                                                    <div className="col-12 col-md-4 mt-0 mb-1">
                                                      <select
                                                        className="form-control form-control-sm"
                                                        {...register(
                                                          "wechselrichter_id",
                                                          {
                                                            required: false,
                                                            disabled: readOnly,
                                                          }
                                                        )}
                                                      >
                                                        <option value="">
                                                          --
                                                        </option>
                                                        {wechselrichters &&
                                                          wechselrichters.map(
                                                            (
                                                              wechselrichter
                                                            ) => (
                                                              <option
                                                                key={
                                                                  wechselrichter.id
                                                                }
                                                                value={
                                                                  wechselrichter.id
                                                                }
                                                              >
                                                                {
                                                                  wechselrichter.name
                                                                }
                                                              </option>
                                                            )
                                                          )}
                                                      </select>
                                                      <div className="invalid-feedback">
                                                        {errors.wechselrichter_id &&
                                                          errors
                                                            .wechselrichter_id
                                                            .message}
                                                      </div>
                                                    </div>
                                                  </>
                                                ) : (
                                                  <>
                                                    <div className="col-12 col-md-2 mt-0">
                                                      <label>
                                                        {t(
                                                          "Wechselrichter Titel"
                                                        )}
                                                      </label>
                                                    </div>
                                                    <div className="col-12 col-md-4 mt-0 mb-1">
                                                      <input
                                                        type="text"
                                                        className="form-control form-control-sm"
                                                        placeholder={t("")}
                                                        {...register(
                                                          "wechselrichter_titel",
                                                          {
                                                            required: false,
                                                            disabled: readOnly,
                                                          }
                                                        )}
                                                      />
                                                      <div className="invalid-feedback">
                                                        {errors.wechselrichter_titel &&
                                                          errors
                                                            .wechselrichter_titel
                                                            .message}
                                                      </div>
                                                    </div>

                                                    <div className="col-12 mt-0 mb-1">
                                                      <label>
                                                        {t(
                                                          "Wechselrichter Description"
                                                        )}
                                                      </label>
                                                      <textarea
                                                        className="form-control form-control-sm"
                                                        rows={4}
                                                        {...register(
                                                          "wechselrichter_description",
                                                          {
                                                            required: false,
                                                            disabled: readOnly,
                                                          }
                                                        )}
                                                      ></textarea>
                                                    </div>
                                                  </>
                                                )}

                                                <div className="col-md-6 mt-0 mb-1">
                                                  <div className="form-check">
                                                    <label>{t("Modul")}</label>
                                                    <input
                                                      className="form-check-input"
                                                      type="checkbox"
                                                      onChange={(e) => {
                                                        if (
                                                          e.target.checked ===
                                                          true
                                                        ) {
                                                          setModuleEingeben(
                                                            "yes"
                                                          );
                                                          setValue(
                                                            "module_eingeben",
                                                            "yes"
                                                          );
                                                          data.quations.module_eingeben =
                                                            "yes";
                                                        } else {
                                                          setModuleEingeben(
                                                            "no"
                                                          );
                                                          setValue(
                                                            "module_eingeben",
                                                            "no"
                                                          );
                                                          data.quations.module_eingeben =
                                                            "no";
                                                        }
                                                        calCulateGesDachflache();
                                                        calCulateAnlagenProduktion();
                                                        setData(data);
                                                        setRefresh3(
                                                          refresh3 + 3
                                                        );
                                                      }}
                                                      checked={
                                                        data?.quations
                                                          ?.module_eingeben ===
                                                        "yes"
                                                          ? true
                                                          : false
                                                      }
                                                    />
                                                  </div>
                                                </div>

                                                {moduleEingeben === "no" ? (
                                                  <>
                                                    <div className="col-12 col-md-2 mt-0">
                                                      <label>
                                                        {t("Modul")} *
                                                      </label>
                                                    </div>
                                                    <div className="col-12 col-md-4 mt-0 mb-1">
                                                      <select
                                                        className="form-control form-control-sm"
                                                        {...register("modul", {
                                                          required: true,
                                                          disabled: readOnly,
                                                          onChange: (e) => {
                                                            calCulateGesDachflache();
                                                            calCulateAnlagenProduktion();
                                                          },
                                                        })}
                                                        required
                                                      >
                                                        <option value="">
                                                          --
                                                        </option>
                                                        {modules &&
                                                          modules.map(
                                                            (modul) => (
                                                              <option
                                                                key={modul.id}
                                                                value={modul.id}
                                                              >
                                                                {modul.name}
                                                              </option>
                                                            )
                                                          )}
                                                      </select>
                                                      <div className="invalid-feedback">
                                                        {errors.modul &&
                                                          errors.modul.message}
                                                      </div>
                                                    </div>
                                                  </>
                                                ) : (
                                                  <>
                                                    <div className="col-12 col-md-2 mt-0">
                                                      <label>
                                                        {t("Title")} *
                                                      </label>
                                                    </div>
                                                    <div className="col-12 col-md-4 mt-0 mb-1">
                                                      <input
                                                        type="text"
                                                        className="form-control form-control-sm mb-4"
                                                        placeholder={t("")}
                                                        {...register(
                                                          "module_name",
                                                          {
                                                            required: true,
                                                            disabled: readOnly,
                                                          }
                                                        )}
                                                        required
                                                      />
                                                      <div className="invalid-feedback">
                                                        {errors.name &&
                                                          errors.name.message}
                                                      </div>
                                                    </div>

                                                    <div className="col-12 col-md-2 mt-0">
                                                      <label>
                                                        {t("Short Title")} *
                                                      </label>
                                                    </div>
                                                    <div className="col-12 col-md-4 mt-0 mb-1">
                                                      <input
                                                        type="text"
                                                        className="form-control form-control-sm mb-4"
                                                        placeholder={t("")}
                                                        {...register(
                                                          "module_short_title",
                                                          {
                                                            required: true,
                                                            disabled: readOnly,
                                                          }
                                                        )}
                                                        required
                                                      />
                                                      <div className="invalid-feedback">
                                                        {errors.short_itle &&
                                                          errors.short_itle
                                                            .message}
                                                      </div>
                                                    </div>

                                                    <div className="col-12 col-md-2 mt-0">
                                                      <label>
                                                        {t("Watt")} *
                                                      </label>
                                                    </div>
                                                    <div className="col-12 col-md-4 mt-0 mb-1">
                                                      <input
                                                        type="number"
                                                        className="form-control form-control-sm mb-4"
                                                        placeholder={t("")}
                                                        {...register(
                                                          "module_watt",
                                                          {
                                                            required: true,
                                                            disabled: readOnly,
                                                            onChange: (e) => {
                                                              calCulateAnlagenProduktion();
                                                            },
                                                          }
                                                        )}
                                                        required
                                                      />
                                                      <div className="invalid-feedback">
                                                        {errors.watt &&
                                                          errors.watt.message}
                                                      </div>
                                                    </div>

                                                    <div className="col-12 col-md-2 mt-0">
                                                      <label>
                                                        {t("Markenhersteller")}
                                                      </label>
                                                    </div>
                                                    <div className="col-12 col-md-4 mt-0 mb-1">
                                                      <input
                                                        type="text"
                                                        className="form-control form-control-sm mb-4"
                                                        placeholder={t("")}
                                                        {...register(
                                                          "module_description[Markenhersteller]",
                                                          {
                                                            required: true,
                                                            disabled: readOnly,
                                                          }
                                                        )}
                                                        required
                                                      />
                                                    </div>

                                                    <div className="col-12 col-md-2 mt-0">
                                                      <label>{t("Typ")}</label>
                                                    </div>
                                                    <div className="col-12 col-md-4 mt-0 mb-1">
                                                      <input
                                                        type="text"
                                                        className="form-control form-control-sm mb-4"
                                                        placeholder={t("")}
                                                        {...register(
                                                          "module_description[Typ]",
                                                          {
                                                            required: true,
                                                            disabled: readOnly,
                                                          }
                                                        )}
                                                        required
                                                      />
                                                    </div>

                                                    <div className="col-12 col-md-2 mt-0">
                                                      <label>
                                                        {t("Zelltyp")}
                                                      </label>
                                                    </div>
                                                    <div className="col-12 col-md-4 mt-0 mb-1">
                                                      <input
                                                        type="text"
                                                        className="form-control form-control-sm mb-4"
                                                        placeholder={t("")}
                                                        {...register(
                                                          "module_description[Zelltyp]",
                                                          {
                                                            required: true,
                                                            disabled: readOnly,
                                                          }
                                                        )}
                                                        required
                                                      />
                                                    </div>

                                                    <div className="col-12 col-md-2 mt-0">
                                                      <label>
                                                        {t("Leistungstoleranz")}
                                                      </label>
                                                    </div>
                                                    <div className="col-12 col-md-4 mt-0 mb-1">
                                                      <input
                                                        type="text"
                                                        className="form-control form-control-sm mb-4"
                                                        placeholder={t("")}
                                                        {...register(
                                                          "module_description[Leistungstoleranz]",
                                                          {
                                                            required: true,
                                                            disabled: readOnly,
                                                          }
                                                        )}
                                                        required
                                                      />
                                                    </div>

                                                    <div className="col-12 col-md-2 mt-0">
                                                      <label>
                                                        {t("Modulabmessung") +
                                                          " (" +
                                                          t("Width") +
                                                          ")"}
                                                      </label>
                                                    </div>
                                                    <div className="col-12 col-md-4 mt-0 mb-1">
                                                      <input
                                                        type="number"
                                                        step={0.01}
                                                        className="form-control form-control-sm mb-4"
                                                        placeholder={t("")}
                                                        {...register(
                                                          "module_description[Modulabmessung_width]",
                                                          {
                                                            required: true,
                                                            disabled: readOnly,
                                                            onChange: (e) => {
                                                              calCulateGesDachflache();
                                                            },
                                                          }
                                                        )}
                                                        required
                                                      />
                                                    </div>

                                                    <div className="col-12 col-md-2 mt-0">
                                                      <label>
                                                        {t("Modulabmessung") +
                                                          " (" +
                                                          t("Height") +
                                                          ")"}
                                                      </label>
                                                    </div>
                                                    <div className="col-12 col-md-4 mt-0 mb-1">
                                                      <input
                                                        type="number"
                                                        step={0.01}
                                                        className="form-control form-control-sm mb-4"
                                                        placeholder={t("")}
                                                        {...register(
                                                          "module_description[Modulabmessung_height]",
                                                          {
                                                            required: true,
                                                            disabled: readOnly,
                                                            onChange: (e) => {
                                                              calCulateGesDachflache();
                                                            },
                                                          }
                                                        )}
                                                        required
                                                      />
                                                    </div>

                                                    <div className="col-12 col-md-2 mt-0">
                                                      <label>
                                                        {t("Gewicht")}
                                                      </label>
                                                    </div>
                                                    <div className="col-12 col-md-4 mt-0 mb-1">
                                                      <input
                                                        type="text"
                                                        className="form-control form-control-sm mb-4"
                                                        placeholder={t("")}
                                                        {...register(
                                                          "module_description[Gewicht]",
                                                          {
                                                            required: true,
                                                            disabled: readOnly,
                                                          }
                                                        )}
                                                        required
                                                      />
                                                    </div>

                                                    <div className="col-12 col-md-2 mt-0">
                                                      <label>
                                                        {t("Modulwirkungsgrad")}
                                                      </label>
                                                    </div>
                                                    <div className="col-12 col-md-4 mt-0 mb-1">
                                                      <input
                                                        type="text"
                                                        className="form-control form-control-sm mb-4"
                                                        placeholder={t("")}
                                                        {...register(
                                                          "module_description[Modulwirkungsgrad]",
                                                          {
                                                            required: true,
                                                            disabled: readOnly,
                                                          }
                                                        )}
                                                        required
                                                      />
                                                    </div>

                                                    <div className="col-12 col-md-2 mt-0">
                                                      <label>
                                                        {t(
                                                          "Max. Druckbelastung"
                                                        )}
                                                      </label>
                                                    </div>
                                                    <div className="col-12 col-md-4 mt-0 mb-1">
                                                      <input
                                                        type="text"
                                                        className="form-control form-control-sm mb-4"
                                                        placeholder={t("")}
                                                        {...register(
                                                          "module_description[MaxDruckbelastung]",
                                                          {
                                                            required: true,
                                                            disabled: readOnly,
                                                          }
                                                        )}
                                                        required
                                                      />
                                                    </div>

                                                    <div className="col-12 col-md-2 mt-0">
                                                      <label>
                                                        {t("Leistungsgarantie")}
                                                      </label>
                                                    </div>
                                                    <div className="col-12 col-md-4 mt-0 mb-1">
                                                      <input
                                                        type="text"
                                                        className="form-control form-control-sm mb-4"
                                                        placeholder={t("")}
                                                        {...register(
                                                          "module_description[Leistungsgarantie]",
                                                          {
                                                            required: true,
                                                            disabled: readOnly,
                                                          }
                                                        )}
                                                        required
                                                      />
                                                    </div>

                                                    <div className="col-12 col-md-2 mt-0">
                                                      <label>
                                                        {t("Produktgarantie")}
                                                      </label>
                                                    </div>
                                                    <div className="col-12 col-md-4 mt-0 mb-1">
                                                      <input
                                                        type="text"
                                                        className="form-control form-control-sm mb-4"
                                                        placeholder={t("")}
                                                        {...register(
                                                          "module_description[Produktgarantie]",
                                                          {
                                                            required: true,
                                                            disabled: readOnly,
                                                          }
                                                        )}
                                                        required
                                                      />
                                                    </div>
                                                  </>
                                                )}

                                                <div className="col-12 col-md-2 mt-0">
                                                  <label>
                                                    {t("Zusatzvereinbarungen")}
                                                  </label>
                                                </div>
                                                <div className="col-12 col-md-4 mt-0 mb-2">
                                                  <Select
                                                    placeholder={t("Select")}
                                                    options={[
                                                      {
                                                        value: "Zwischenzähler",
                                                        label: "Zwischenzähler",
                                                      },
                                                      {
                                                        value: "Power to Heat",
                                                        label: "Power to Heat",
                                                      },
                                                      {
                                                        value:
                                                          "inkl. Technikpaket",
                                                        label:
                                                          "inkl. Technikpaket",
                                                      },
                                                      {
                                                        value:
                                                          "inkl. Garantieverlängerungen auf 20 Jahre SENEC",
                                                        label:
                                                          "inkl. Garantieverlängerungen auf 20 Jahre SENEC",
                                                      },
                                                      {
                                                        value:
                                                          "Zählerzusammenlegung",
                                                        label:
                                                          "Zählerzusammenlegung",
                                                      },
                                                      {
                                                        value:
                                                          "inkl. Aufständerung Ost/West",
                                                        label:
                                                          "inkl. Aufständerung Ost/West",
                                                      },
                                                      {
                                                        value:
                                                          "inkl. Aufständerung Süd",
                                                        label:
                                                          "inkl. Aufständerung Süd",
                                                      },
                                                      {
                                                        value:
                                                          "Steuerberatergutschein SENEC",
                                                        label:
                                                          "Steuerberatergutschein SENEC",
                                                      },
                                                      {
                                                        value:
                                                          "inkl. Kreuzverbund",
                                                        label:
                                                          "inkl. Kreuzverbund",
                                                      },
                                                      {
                                                        value:
                                                          "inkl. Schlüsselfertiger Preis / All in one Paket",
                                                        label:
                                                          "inkl. Schlüsselfertiger Preis / All in one Paket",
                                                      },
                                                      {
                                                        value:
                                                          "inkl. Blechziegel",
                                                        label:
                                                          "inkl. Blechziegel",
                                                      },
                                                      {
                                                        value:
                                                          "TIGO Optimierer",
                                                        label:
                                                          "TIGO Optimierer",
                                                      },
                                                      {
                                                        value:
                                                          "inkl. Notstrombox SENEC",
                                                        label:
                                                          "inkl. Notstrombox SENEC",
                                                      },
                                                      {
                                                        value:
                                                          "inkl. Notstrombox E3DC",
                                                        label:
                                                          "inkl. Notstrombox E3DC",
                                                      },
                                                      {
                                                        value:
                                                          "inkl. Wallbox 22kw",
                                                        label:
                                                          "inkl. Wallbox 22kw",
                                                      },
                                                      {
                                                        value:
                                                          "inkl. Wallbox 11kw",
                                                        label:
                                                          "inkl. Wallbox 11kw",
                                                      },
                                                      {
                                                        value: "Taubenschutz",
                                                        label: "Taubenschutz",
                                                      },
                                                      {
                                                        value:
                                                          "Schneefanggitter",
                                                        label:
                                                          "Schneefanggitter",
                                                      },
                                                    ]}
                                                    value={zusatzvereinbarungen}
                                                    onChange={(e) => {
                                                      setZusatzvereinbarungen(
                                                        e
                                                      );
                                                      setValue(
                                                        "zusatzvereinbarungen",
                                                        JSON.stringify(e)
                                                      );
                                                    }}
                                                    isMulti
                                                    isDisabled={readOnly}
                                                    styles={reactSelectStyle}
                                                    components={{
                                                      DropdownIndicator,
                                                    }}
                                                  />
                                                  <div className="invalid-feedback">
                                                    {errors.zusatzvereinbarungen &&
                                                      errors
                                                        .zusatzvereinbarungen
                                                        .message}
                                                  </div>
                                                </div>

                                                <div className="col-12 col-md-2 mt-0">
                                                  <label>
                                                    {t(
                                                      "Zusatzvereinbarungen 2"
                                                    )}
                                                  </label>
                                                </div>
                                                <div className="col-12 col-md-4 mt-0 mb-2">
                                                  <Select
                                                    placeholder={t("Select")}
                                                    options={allProducts}
                                                    getOptionLabel={(option) =>
                                                      option.name
                                                    }
                                                    getOptionValue={(option) =>
                                                      option.id
                                                    }
                                                    value={selectedproduct}
                                                    onChange={(e) => {
                                                      //setZusatzvereinbarungen(e);
                                                      //setValue('zusatzvereinbarungen', JSON.stringify(e));
                                                      var productIds = [];
                                                      for (let [
                                                        key,
                                                        value,
                                                      ] of Object.entries(e)) {
                                                        productIds.push(
                                                          value.id
                                                        );
                                                      }
                                                      setValue(
                                                        "products",
                                                        JSON.stringify(
                                                          productIds
                                                        )
                                                      );
                                                      setSelectedproduct(e);
                                                    }}
                                                    isMulti
                                                    isDisabled={readOnly}
                                                    styles={reactSelectStyle}
                                                    components={{
                                                      DropdownIndicator,
                                                    }}
                                                  />
                                                  <div className="invalid-feedback">
                                                    {errors.zusatzvereinbarungen &&
                                                      errors
                                                        .zusatzvereinbarungen
                                                        .message}
                                                  </div>
                                                </div>

                                                <div className="col-12 col-md-2 mt-0">
                                                  <label>
                                                    {t(
                                                      "Zahlungsart: (Pflicht)"
                                                    )}
                                                  </label>
                                                </div>
                                                <div className="col-12 col-md-4 mt-0 mb-1">
                                                  <select
                                                    //style={{ height: '2.570rem', borderRadius: '0.5rem', padding: '0.25rem 0.75rem', border: '0.0625rem solid rgb(210, 214, 218)' }}
                                                    {...register(
                                                      "payment_method",
                                                      {
                                                        required: true,
                                                        disabled: readOnly,
                                                      }
                                                    )}
                                                    className="form-control form-control-sm"
                                                  >
                                                    <option value="">
                                                      {t("Auswählen")}
                                                    </option>
                                                    <option value="Finanzierer">
                                                      Finanzierer
                                                    </option>
                                                    <option value="Eigenkapital">
                                                      Eigenkapital
                                                    </option>
                                                  </select>
                                                </div>

                                                {readOnly == false ? (
                                                  <>
                                                    <div className="col-md-12">
                                                      <div className="row">
                                                        <div className="col-6 col-md-2 d-flex align-items-center justify-content-between">
                                                          <div
                                                            onClick={() => {
                                                              setShow(1);
                                                              setValue(
                                                                "netto_brotto",
                                                                1
                                                              );
                                                            }}
                                                            className="form-check"
                                                          >
                                                            <input
                                                              className="form-check-input"
                                                              type="radio"
                                                              name="flexRadioDefault"
                                                              id="flexRadioDefault2"
                                                              checked={
                                                                show === 1 &&
                                                                true
                                                              }
                                                            />
                                                            <label
                                                              className="form-check-label"
                                                              for="flexRadioDefault2"
                                                            >
                                                              Netto
                                                            </label>
                                                          </div>

                                                          <div
                                                            onClick={() => {
                                                              setShow(0);
                                                              setValue(
                                                                "netto_brotto",
                                                                0
                                                              );
                                                            }}
                                                            className="form-check"
                                                          >
                                                            <input
                                                              className="form-check-input"
                                                              type="radio"
                                                              name="flexRadioDefault"
                                                              id="flexRadioDefault1"
                                                              checked={
                                                                show === 0 &&
                                                                true
                                                              }
                                                            />
                                                            <label
                                                              className="form-check-label"
                                                              for="flexRadioDefault1"
                                                            >
                                                              Brutto
                                                            </label>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>

                                                    {show === 0 && (
                                                      <>
                                                        <div className="col-12 col-md-2 mt-0">
                                                          <label>
                                                            {t("Gesamt Netto")}{" "}
                                                            *
                                                          </label>
                                                        </div>
                                                        <div className="col-12 col-md-4 mt-0 mb-1">
                                                          <div className="input-group ">
                                                            <input
                                                              type="number"
                                                              className="form-control form-control-sm"
                                                              placeholder={t(
                                                                ""
                                                              )}
                                                              {...register(
                                                                "gesami_netto",
                                                                {
                                                                  required: true,
                                                                  disabled:
                                                                    readOnly,
                                                                  onChange: (
                                                                    e
                                                                  ) =>
                                                                    onChangeHandeler(
                                                                      e
                                                                    ),
                                                                }
                                                              )}
                                                              required
                                                            />
                                                            <div className="input-group-append ms-2">
                                                              <span className="input-group-text px-5">
                                                                €
                                                              </span>
                                                            </div>
                                                          </div>
                                                          <div className="invalid-feedback">
                                                            {errors.gesami_netto &&
                                                              errors
                                                                .gesami_netto
                                                                .message}
                                                          </div>
                                                        </div>

                                                        <div className="col-12 col-md-2 mt-0">
                                                          <label>
                                                            {t("Gesamt Brutto")}{" "}
                                                            *
                                                          </label>
                                                        </div>
                                                        <div className="col-12 col-md-4 mt-0 mb-1">
                                                          <div className="input-group">
                                                            <input
                                                              type="number"
                                                              className="form-control form-control-sm"
                                                              placeholder={t(
                                                                ""
                                                              )}
                                                              {...register(
                                                                "gesami_brutto",
                                                                {
                                                                  required: true,
                                                                  disabled:
                                                                    readOnly,
                                                                }
                                                              )}
                                                              required
                                                            />
                                                            <div className="input-group-append ms-2">
                                                              <span className="input-group-text px-5">
                                                                €
                                                              </span>
                                                            </div>
                                                          </div>
                                                          <div className="invalid-feedback">
                                                            {errors.gesami_brutto &&
                                                              errors
                                                                .gesami_brutto
                                                                .message}
                                                          </div>
                                                        </div>
                                                      </>
                                                    )}

                                                    {show === 1 && (
                                                      <>
                                                        <div className="col-12 col-md-2 mt-0">
                                                          <label>
                                                            {t("Gesamt Netto")}{" "}
                                                            *
                                                          </label>
                                                        </div>
                                                        <div className="col-12 col-md-4 mt-0 mb-1">
                                                          <div className="input-group">
                                                            <input
                                                              type="number"
                                                              className="form-control form-control-sm"
                                                              placeholder={t(
                                                                ""
                                                              )}
                                                              {...register(
                                                                "gesami_netto",
                                                                {
                                                                  required: true,
                                                                  disabled:
                                                                    readOnly,
                                                                  onChange: (
                                                                    e
                                                                  ) =>
                                                                    onChangeHandeler(
                                                                      e
                                                                    ),
                                                                }
                                                              )}
                                                              required
                                                            />
                                                            <div className="input-group-append ms-2">
                                                              <span className="input-group-text px-5">
                                                                €
                                                              </span>
                                                            </div>
                                                          </div>
                                                          <div className="invalid-feedback">
                                                            {errors.gesami_netto &&
                                                              errors
                                                                .gesami_netto
                                                                .message}
                                                          </div>
                                                        </div>
                                                      </>
                                                    )}
                                                  </>
                                                ) : (
                                                  ""
                                                )}
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ) : (
                                    ""
                                  )}

                                  <div
                                    className="accordion mt-3 accordion-flush"
                                    id="accordionFlushExamplec"
                                  >
                                    <div className="accordion-item bg-secondary p-3 border-radius-8">
                                      <h2 className="accordion-header">
                                        <div className="d-flex justify-content-between">
                                          <div className="d-flex">
                                            <button
                                              className="accordion-button mb-0"
                                              type="button"
                                              data-bs-toggle="collapse"
                                              data-bs-target="#flush-collapseTwo"
                                              aria-expanded="false"
                                              aria-controls="flush-collapseTwo"
                                            >
                                              <span className="accordion-arrow">
                                                <i className="ph ph-caret-right"></i>
                                              </span>
                                              <p className="font-size-20 font-weight-600 text-primary">
                                                <span className="pl-2">
                                                  Eckdaten PV
                                                </span>
                                              </p>
                                            </button>
                                          </div>
                                          <div>
                                            <a
                                              href="#"
                                              className="btn btn-link mb-0 font-wieght-600 font-size-14"
                                              data-bs-toggle="modal"
                                              data-bs-target="#staticBackdropddd"
                                            >
                                              Datenerfassung für die
                                              Wirtschaftlichkeitsberechnunung
                                            </a>
                                          </div>
                                        </div>
                                      </h2>
                                      <div
                                        id="flush-collapseTwo"
                                        className="accordion-collapse collapse show"
                                        data-bs-parent="#accordionFlushExamplec"
                                      >
                                        <div className="accordion-body">
                                          <SoftBox component="form">
                                            <div className="row g-3 mt-0">
                                              <div className="col-12 col-md-6 mt-0">
                                                <label>
                                                  {t("Stromverbrauch in kwh")} *
                                                </label>
                                                <input
                                                  type="text"
                                                  className="form-control"
                                                  placeholder={t("")}
                                                  {...register(
                                                    "eigenverbrauch",
                                                    {
                                                      required: true,
                                                      disabled: readOnly,
                                                      onChange: (e) =>
                                                        onChangeHandeler(e),
                                                    }
                                                  )}
                                                  required
                                                />
                                                <div className="invalid-feedback">
                                                  {errors.eigenverbrauch &&
                                                    errors.eigenverbrauch
                                                      .message}
                                                </div>
                                              </div>

                                              <div className="col-12 col-md-6 mt-0">
                                                <label>
                                                  {t("Stromkosten im Jahr(€)")}{" "}
                                                  *
                                                </label>
                                                <input
                                                  type="text"
                                                  className="form-control"
                                                  placeholder={t("")}
                                                  {...register("stromkosten", {
                                                    required: true,
                                                    disabled: readOnly,
                                                    onChange: (e) =>
                                                      onChangeHandeler(e),
                                                  })}
                                                  required
                                                />
                                                <div className="invalid-feedback">
                                                  {errors.stromkosten &&
                                                    errors.stromkosten.message}
                                                </div>
                                              </div>

                                              <div className="col-12 col-md-6 mt-0">
                                                <label>
                                                  {t("Kwh Preis(€)")} *
                                                </label>
                                                <input
                                                  step={0.001}
                                                  type="text"
                                                  className="form-control"
                                                  placeholder={t("")}
                                                  {...register("kwh_preis", {
                                                    required: true,
                                                    disabled: readOnly,
                                                  })}
                                                  required
                                                />
                                                <div className="invalid-feedback">
                                                  {errors.kwh_preis &&
                                                    errors.kwh_preis.message}
                                                </div>
                                              </div>

                                              <div className="col-12 col-md-6 mt-0">
                                                <label>
                                                  {t("Anzahl der Module")} *
                                                </label>
                                                <input
                                                  type="number"
                                                  className="form-control"
                                                  placeholder={t("")}
                                                  {...register(
                                                    "anzahl_module",
                                                    {
                                                      required: true,
                                                      disabled: readOnly,
                                                      onChange: (e) => {
                                                        calCulateGesDachflache();
                                                        calCulateAnlagenProduktion();
                                                      },
                                                    }
                                                  )}
                                                  required
                                                  readOnly={
                                                    newQuation === true
                                                      ? true
                                                      : false
                                                  }
                                                />
                                                <div className="invalid-feedback">
                                                  {errors.anzahl_module &&
                                                    errors.anzahl_module
                                                      .message}
                                                </div>
                                              </div>

                                              <div className="col-12 col-md-6 mt-0">
                                                <label>
                                                  {t(
                                                    "Gesamt Bebauungsflache in qm"
                                                  )}{" "}
                                                  
                                                </label>
                                                <input
                                                  type="number"
                                                  step={0.001}
                                                  className="form-control"
                                                  placeholder={t("")}
                                                  {...register(
                                                    "ges_dachflache",
                                                    {
                                                      required: false,
                                                      disabled: readOnly,
                                                    }
                                                  )} 
                                                />
                                                <div className="invalid-feedback">
                                                  {errors.ges_dachflache &&
                                                    errors.ges_dachflache
                                                      .message}
                                                </div>
                                              </div>

                                              <div className="col-12 col-md-6 mt-0">
                                                <div className="form-group solacloud-select icon-caret-down icon-right">
                                                  <label>
                                                    {t("Dachausrichtung")} 
                                                  </label>
                                                  <select
                                                    className="form-control"
                                                    {...register(
                                                      "dachausrichtung",
                                                      {
                                                        required: false,
                                                        disabled: readOnly,
                                                      }
                                                    )} 
                                                  >
                                                    <option value="">--</option>
                                                    <option value="Süd">
                                                      {t("Süd")}
                                                    </option>
                                                    <option value="S-O u. S-W">
                                                      {t("S-O u. S-W")}
                                                    </option>
                                                    <option value="Süd West">
                                                      {t("Süd West")}
                                                    </option>
                                                    <option value="West Süd">
                                                      {t("West Süd")}
                                                    </option>
                                                    <option value="West">
                                                      {t("West")}
                                                    </option>
                                                    <option value="WNW">
                                                      {t("WNW")}
                                                    </option>
                                                    <option value="SSO">
                                                      {t("SSO")}
                                                    </option>
                                                    <option value="Süd Ost">
                                                      {t("Süd Ost")}
                                                    </option>
                                                    <option value="OSO">
                                                      {t("OSO")}
                                                    </option>
                                                    <option value="Ost">
                                                      {t("Ost")}
                                                    </option>
                                                    <option value="ONO">
                                                      {t("ONO")}
                                                    </option>
                                                    <option value="Nord">
                                                      {t("Nord")}
                                                    </option>
                                                    <option value="Nord-Ost">
                                                      {t("Nord-Ost")}
                                                    </option>
                                                    <option value="Nord-West">
                                                      {t("Nord-West")}
                                                    </option>
                                                    <option value="Ost-West">
                                                      {t("Ost-West")}
                                                    </option>
                                                    <option value="Süd u. Nord">
                                                      {t("Süd u. Nord")}
                                                    </option>
                                                    <option value="West u. Ost">
                                                      {t("West u. Ost")}
                                                    </option>
                                                    <option value="Süd & Ost & West">
                                                      {t("Süd & Ost & West")}
                                                    </option>
                                                  </select>
                                                </div>
                                                <div className="invalid-feedback">
                                                  {errors.dachausrichtung &&
                                                    errors.dachausrichtung
                                                      .message}
                                                </div>
                                              </div>

                                              <div className="col-12 col-md-6 mt-0">
                                                <div className="form-group solacloud-select icon-caret-down icon-right">
                                                <label>
                                                  {t(
                                                    "Hindernisse oder Schattierung vorhanden"
                                                  )}
                                                </label>
                                                <select
                                                  className="form-control"
                                                  {...register(
                                                    "anzahl_der_leistungsoptimierer",
                                                    {
                                                      required: false,
                                                      disabled: readOnly,
                                                    }
                                                  )}
                                                >
                                                  <option value="">--</option>
                                                  <option value="Ja">
                                                    {t("Yes")}
                                                  </option>
                                                  <option value="Nein">
                                                    {t("No")}
                                                  </option>
                                                </select>
                                                </div>
                                                <div className="invalid-feedback">
                                                  {errors.anzahl_der_leistungsoptimierer &&
                                                    errors
                                                      .anzahl_der_leistungsoptimierer
                                                      .message}
                                                </div>
                                              </div>

                                              <div className="col-12 col-md-3 mt-0">
                                                <div className="form-group solacloud-select icon-caret-down icon-right">
                                                  <label>{t("Zahlung")}</label>
                                                  <select
                                                    className="form-control"
                                                    {...register("zahlung", {
                                                      required: false,
                                                      disabled: readOnly,
                                                    })}
                                                  > 
                                                    <option value="Barzahlung">
                                                      Barzahlung
                                                    </option>
                                                    <option value="Finanzierung">
                                                      Finanzierung
                                                    </option>
                                                  </select>
                                                </div>
                                                <div className="invalid-feedback">
                                                  {errors.zahlung &&
                                                    errors.zahlung.message}
                                                </div>
                                              </div>
                                              <div className="col-12 col-md-3 mt-4">
                                                {/* <label>{t('Payment Option')}</label> */}
                                                <div className="form-group">
                                                <select
                                                  className="form-control" 
                                                  {...register(
                                                    "payment_option",
                                                    {
                                                      required: false,
                                                      disabled: readOnly,
                                                    }
                                                  )} 
                                                > 
                                                  {Object.keys(
                                                    paymentOptions
                                                  ).map((value, index) => (
                                                    <option
                                                      key={index} 
                                                      value={value}
                                                    >
                                                      {paymentOptions[value]}  
                                                    </option>
                                                  ))}
                                                </select>
                                                </div>
                                                <div className="invalid-feedback">
                                                  {errors.zahlung &&
                                                    errors.zahlung.message}
                                                </div>
                                              </div>

                                              <div className="col-12 col-md-6 mt-0">
                                                <label>{t("Dachtyp")} </label>
                                                {dachtyp
                                                  ? dachtyp.map(
                                                      (dach, index) => (
                                                        <>
                                                          <div className="row">
                                                            <div className="col-6 col-md-6 mt-0 mb-1 pl-0">
                                                             <div className="form-group">
                                                              
                                                             <select
                                                                className="form-control mb-2"
                                                                value={
                                                                  dachtyp?.[
                                                                    index
                                                                  ]
                                                                }
                                                                required={false}
                                                                disabled={
                                                                  readOnly
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  var update =
                                                                    dachtyp;
                                                                  update[
                                                                    index
                                                                  ] =
                                                                    e.target.value;
                                                                  setDachtyp(
                                                                    update
                                                                  );
                                                                  setRefresh3(
                                                                    refresh3 + 3
                                                                  );
                                                                }}
                                                              >
                                                                <option value="">
                                                                  --
                                                                </option>
                                                                <option value="Sonstiges">
                                                                  {t(
                                                                    "Sonstiges"
                                                                  )}
                                                                </option>
                                                                <option value="Satteldach">
                                                                  {t(
                                                                    "Satteldach"
                                                                  )}
                                                                </option>
                                                                <option value="Pultdach">
                                                                  {t(
                                                                    "Pultdach"
                                                                  )}
                                                                </option>
                                                                <option value="Flachdach">
                                                                  {t(
                                                                    "Flachdach"
                                                                  )}
                                                                </option>
                                                                <option value="Walmdach">
                                                                  {t(
                                                                    "Walmdach"
                                                                  )}
                                                                </option>
                                                                <option value="2/ Hausdach + Garagendach">
                                                                  {t(
                                                                    "2/ Hausdach + Garagendach"
                                                                  )}
                                                                </option>
                                                                <option value="Fasade">
                                                                  {t("Fasade")}
                                                                </option>
                                                                <option value="Gaube">
                                                                  {t("Gaube")}
                                                                </option>
                                                              </select>
                                                             </div>
                                                            </div>

                                                            <div className="col-6 col-md-6 mt-0 mb-1">
                                                              <select
                                                                className="form-control"
                                                                value={
                                                                  dacheindeckung?.[
                                                                    index
                                                                  ]
                                                                }
                                                                required={false}
                                                                disabled={
                                                                  readOnly
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  var update =
                                                                    dacheindeckung;
                                                                  update[
                                                                    index
                                                                  ] =
                                                                    e.target.value;
                                                                  setDacheindeckung(
                                                                    update
                                                                  );
                                                                  setRefresh3(
                                                                    refresh3 + 3
                                                                  );
                                                                }}
                                                              >
                                                                <option value="">
                                                                  --
                                                                </option>
                                                                <option value="Sonstiges">
                                                                  {t(
                                                                    "Sonstiges"
                                                                  )}
                                                                </option>
                                                                <option value="Ziegel">
                                                                  {t("Ziegel")}
                                                                </option>
                                                                <option value="Blech">
                                                                  {t("Blech")}
                                                                </option>
                                                                <option value="Kies">
                                                                  {t("Kies")}
                                                                </option>
                                                                <option value="Schiefer">
                                                                  {t(
                                                                    "Schiefer"
                                                                  )}
                                                                </option>
                                                                <option value="Schindel">
                                                                  {t(
                                                                    "Schindel"
                                                                  )}
                                                                </option>
                                                                <option value="Betumen">
                                                                  {t("Betumen")}
                                                                </option>
                                                                <option value="Gründach">
                                                                  {t(
                                                                    "Gründach"
                                                                  )}
                                                                </option>
                                                                <option value="Wellblech">
                                                                  {t(
                                                                    "Wellblech"
                                                                  )}
                                                                </option>
                                                                <option value="Trapezblech">
                                                                  {t(
                                                                    "Trapezblech"
                                                                  )}
                                                                </option>
                                                                <option value="Sandwiche">
                                                                  {t(
                                                                    "Sandwiche"
                                                                  )}
                                                                </option>
                                                              </select>
                                                              <div className="invalid-feedback">
                                                                {errors.dacheindeckung &&
                                                                  errors
                                                                    .dacheindeckung
                                                                    .message}
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </>
                                                      )
                                                    )
                                                  : ""}
                                              </div>
                                              <div className="col-12 col-md-6 mt-0">
                                                <label>
                                                  {t(
                                                    "Anzahl der zu belegenden Dächer"
                                                  )}{" "}
                                                   
                                                </label>
                                                <input
                                                  type="text"
                                                  className="form-control"
                                                  placeholder={t("")}
                                                  {...register(
                                                    "anzahl_der_dacher",
                                                    {
                                                      required: false,
                                                      disabled: readOnly,
                                                    }
                                                  )} 
                                                />
                                                <div className="invalid-feedback">
                                                  {errors.anzahl_der_dacher &&
                                                    errors.anzahl_der_dacher
                                                      .message}
                                                </div>
                                              </div>
                                              <div className="col-12 col-md-6 mt-0 mb-1 row mx-0 px-0">
                                                <div className="col-6 col-md-6 mt-0 mb-1">
                                                  {/* <a class="btn-link btn-plus-icon btn-icon-primary">Dokument hinzufügen</a> */}
                                                  <a
                                                    className="btn-link btn-plus-icon btn-icon-primary"
                                                    onClick={() => {
                                                      var update = dachtyp;
                                                      update.push("");
                                                      setDachtyp(update);

                                                      var update =
                                                        dacheindeckung;
                                                      update.push("");
                                                      setDacheindeckung(update);

                                                      setRefresh3(refresh3 + 3);
                                                    }}
                                                  >
                                                    Dachtyp Hinzufügen
                                                  </a>
                                                </div>
                                              </div>
                                            </div>
                                          </SoftBox>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  {/* <SoftBox p={3}>
                                            <div className="row">
                                                <div className="col-sm-6"><SoftTypography variant="h5">{t('Eckdaten PV')}</SoftTypography></div>
                                                <div className="col-sm-6 text-end"><SoftTypography variant="h5"><p data-bs-toggle="modal" data-bs-target="#staticBackdropddd" className="data-collection-text">{t('Datenerfassung für die Wirtschaftlichkeitsberechnunung')}</p></SoftTypography></div>
                                            </div>
                                        </SoftBox> */}
                                </>

                                <div
                                  className="accordion mt-3 accordion-flush"
                                  id="accordionFlushExampled"
                                >
                                  <div className="accordion-item bg-secondary p-3 border-radius-8">
                                    <h2 className="accordion-header">
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseThree"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseThree"
                                      >
                                        <span className="accordion-arrow">
                                          <i className="ph ph-caret-right"></i>
                                        </span>
                                        <p className="font-size-20 font-weight-600 text-primary">
                                          <span className="pl-2">
                                            Checkliste
                                          </span>
                                        </p>
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapseThree"
                                      className="accordion-collapse collapse"
                                      data-bs-parent="#accordionFlushExampled"
                                    >
                                      <div className="accordion-body">
                                        <SoftBox>
                                          <div className="row">
                                            <div className="col-12 col-md-6 mt-0">
                                              <label>{t("Ziegelart")} *</label>
                                              <div className="row">
                                                <div className="col-md-6">
                                                  <select
                                                    className="form-control"
                                                    {...register(
                                                      "ziegelart_option",
                                                      {
                                                        required: false,
                                                        disabled: readOnly,
                                                      }
                                                    )}
                                                     
                                                  >
                                                    <option value="">--</option>
                                                    <option value="Frankfurter Pfanne">
                                                      {t("Frankfurter Pfanne")}
                                                    </option>
                                                    <option value="Flachdach Pfanne">
                                                      {t("Flachdach Pfanne")}
                                                    </option>
                                                    <option value="Falszziegel">
                                                      {t("Falszziegel")}
                                                    </option>
                                                    <option value="Glattziegel">
                                                      {t("Glattziegel")}
                                                    </option>
                                                    <option value="Biberschwanz">
                                                      {t("Biberschwanz")}
                                                    </option>
                                                    <option value="Sonstiges">
                                                      {t("Sonstiges")}
                                                    </option>
                                                  </select>
                                                  <div className="invalid-feedback">
                                                    {errors.ziegelart_option &&
                                                      errors.ziegelart_option
                                                        .message}
                                                  </div>
                                                </div>
                                                <div className="col-12 col-md-6 mt-0 mb-1">
                                                  <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder={t("")}
                                                    {...register("ziegelart", {
                                                      required: false,
                                                      disabled: readOnly,
                                                    })}
                                                  />
                                                  <div className="invalid-feedback">
                                                    {errors.ziegelart &&
                                                      errors.ziegelart.message}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>

                                            <div className="col-12 col-md-6 mt-0">
                                              <label>
                                                {t("Anzahl der Zähler")} *
                                              </label>
                                              <select
                                                className="form-control"
                                                {...register(
                                                  "anzahl_der_zahler_in_meter",
                                                  {
                                                    required: false,
                                                    disabled: readOnly,
                                                  }
                                                )}
                                              >
                                                <option value="">--</option>
                                                {[...Array(12)].map((x, i) => (
                                                  <option value={i + 1}>
                                                    {i + 1}
                                                  </option>
                                                ))}
                                              </select>

                                              <div className="invalid-feedback">
                                                {errors.anzahl_der_zahler_in_meter &&
                                                  errors
                                                    .anzahl_der_zahler_in_meter
                                                    .message}
                                              </div>
                                            </div>

                                            <div className="col-12 col-md-6 mt-0">
                                              <label>
                                                {t("Ziegel gemörgelt")} *
                                              </label>
                                              <select
                                                className="form-control"
                                                {...register(
                                                  "ziegel_gemoergelt",
                                                  {
                                                    required: false,
                                                    disabled: readOnly,
                                                  }
                                                )}
                                                 
                                              >
                                                <option value="">--</option>
                                                <option value="Ja">
                                                  {t("Yes")}
                                                </option>
                                                <option value="Nein">
                                                  {t("No")}
                                                </option>
                                              </select>
                                              <div className="invalid-feedback">
                                                {errors.ziegel_gemoergelt &&
                                                  errors.ziegel_gemoergelt
                                                    .message}
                                              </div>
                                            </div>

                                            <div className="col-12 col-md-6 mt-0">
                                              <label>
                                                {t("Wärmepumpe vorhanden")} *
                                              </label>
                                              <select
                                                className="form-control"
                                                {...register(
                                                  "waermepumpe_vorhanden",
                                                  {
                                                    required: false,
                                                    disabled: readOnly,
                                                  }
                                                )}
                                                 
                                              >
                                                <option value="">--</option>
                                                <option value="Ja">
                                                  {t("Yes")}
                                                </option>
                                                <option value="Nein">
                                                  {t("No")}
                                                </option>
                                              </select>
                                              <div className="invalid-feedback">
                                                {errors.waermepumpe_vorhanden &&
                                                  errors.waermepumpe_vorhanden
                                                    .message}
                                              </div>
                                            </div>

                                            <div className="col-12 col-md-6 mt-0">
                                              <label>
                                                {t(
                                                  "Aufdachdammun gestiefe in cm"
                                                )}{" "}
                                                *
                                              </label>
                                              <input
                                                type="text"
                                                className="form-control"
                                                placeholder={t("")}
                                                {...register(
                                                  "aufdachdaemmung_hoehe_in_cm",
                                                  {
                                                    required: false,
                                                    disabled: readOnly,
                                                  }
                                                )}
                                                 
                                              />
                                              <div className="invalid-feedback">
                                                {errors.aufdachdaemmung_hoehe_in_cm &&
                                                  errors
                                                    .aufdachdaemmung_hoehe_in_cm
                                                    .message}
                                              </div>
                                            </div>

                                            <div className="col-12 col-md-6 mt-0">
                                              <label>{t("Welcher EVU")}</label>
                                              <input
                                                type="text"
                                                className="form-control"
                                                placeholder={t("")}
                                                {...register("welcher_evu", {
                                                  required: false,
                                                  disabled: readOnly,
                                                })}
                                                 
                                              />
                                              <div className="invalid-feedback">
                                                {errors.welcher_evu &&
                                                  errors.welcher_evu.message}
                                              </div>
                                            </div>

                                            <div className="col-12 col-md-6 mt-0">
                                              <label>
                                                {t(
                                                  "Höhe in Meter zur Dachrinne"
                                                )}{" "}
                                                *
                                              </label>
                                              <input
                                                type="text"
                                                className="form-control"
                                                placeholder={t("")}
                                                {...register(
                                                  "hoehe_zur_dachrinne_in_meter",
                                                  {
                                                    required: false,
                                                    disabled: readOnly,
                                                  }
                                                )}
                                                 
                                              />
                                              <div className="invalid-feedback">
                                                {errors.hoehe_zur_dachrinne_in_meter &&
                                                  errors
                                                    .hoehe_zur_dachrinne_in_meter
                                                    .message}
                                              </div>
                                            </div>

                                            <div className="col-12 col-md-6 mt-0">
                                              <label>
                                                {t(
                                                  "Von Rinne bis Fürst: (Meter)"
                                                )}{" "}
                                                *
                                              </label>
                                              <input
                                                type="text"
                                                className="form-control"
                                                placeholder={t("")}
                                                {...register(
                                                  "von_rinne_bis_fuerst_in_meter",
                                                  {
                                                    required:  false,
                                                    disabled: readOnly,
                                                  }
                                                )}
                                                 
                                              />
                                              <div className="invalid-feedback">
                                                {errors.von_rinne_bis_fuerst_in_meter &&
                                                  errors
                                                    .von_rinne_bis_fuerst_in_meter
                                                    .message}
                                              </div>
                                            </div>

                                            <div className="col-12 col-md-6 mt-0">
                                              <label>
                                                {t("Sparren Abstand in Meter")}{" "}
                                                *
                                              </label>
                                              <input
                                                type="text"
                                                className="form-control"
                                                placeholder={t("")}
                                                {...register(
                                                  "sparren_abstand_in_meter",
                                                  {
                                                    required: false,
                                                    disabled: readOnly,
                                                  }
                                                )}
                                                 
                                              />
                                              <div className="invalid-feedback">
                                                {errors.sparren_abstand_in_meter &&
                                                  errors
                                                    .sparren_abstand_in_meter
                                                    .message}
                                              </div>
                                            </div>

                                            <div className="col-12 col-md-6 mt-0">
                                              <label>
                                                {t("Oberleitung Isolation")} *
                                              </label>
                                              <select
                                                className="form-control"
                                                {...register(
                                                  "oberleitung_isolation",
                                                  {
                                                    required: false,
                                                    disabled: readOnly,
                                                  }
                                                )}
                                                 
                                              >
                                                <option value="">--</option>
                                                <option value="Ja">
                                                  {t("Yes")}
                                                </option>
                                                <option value="Nein">
                                                  {t("No")}
                                                </option>
                                              </select>
                                              <div className="invalid-feedback">
                                                {errors.oberleitung_isolation &&
                                                  errors.oberleitung_isolation
                                                    .message}
                                              </div>
                                            </div>

                                            <div className="col-12 mt-0">
                                              <label>
                                                {t("Bemerkung zur Montage")}
                                              </label>
                                            </div>
                                            <div className="col-12 mt-0 mb-1">
                                              <textarea
                                                className="form-control"
                                                {...register("description", {
                                                  required: false,
                                                  disabled: readOnly,
                                                })}
                                              ></textarea>
                                              <div className="invalid-feedback">
                                                {errors.description &&
                                                  errors.description.message}
                                              </div>
                                            </div>
                                          </div>
                                        </SoftBox>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="row g-3 mt-1 d-none">
                                  <div className="col-12 mt-4">
                                    {JSON.parse(
                                      Cookies.get("permissions")
                                    ).indexOf("PDU") !== -1 ? (
                                      <>
                                        {!saving && (
                                          <button
                                            type="submit"
                                            className="btn btn-primary"
                                            id="editdstBtn"
                                          >
                                            {t("Save")}
                                          </button>
                                        )}
                                        {saving && (
                                          <button
                                            type="button"
                                            className="btn btn-secondary"
                                            disabled
                                          >
                                            {t("wird geladen...")}
                                          </button>
                                        )}
                                      </>
                                    ) : (
                                      <></>
                                    )}

                                    {data?.quations?.id ? (
                                      <>
                                        {JSON.parse(
                                          Cookies.get("permissions")
                                        ).indexOf("PDU") !== -1 ? (
                                          <>
                                            <a
                                              target={"_blank"}
                                              rel="noreferrer"
                                              href={
                                                Cookies.get("backend_url") +
                                                "quation/" +
                                                data?.quations?.id
                                              }
                                              className="btn btn-outline-dark ms-2"
                                            >
                                              {t("Quoation PDF")}
                                            </a>
                                          </>
                                        ) : (
                                          <></>
                                        )}
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                  </div>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>

                        <div
                          className="tab-pane fade pt-3 tabs"
                          id="wirtsch-tab-pane"
                          role="tabpanel"
                          aria-labelledby="wirtsch-tab"
                          tabIndex="0"
                        > 
                          {isOpen ? <Wirtschaftlichkeitsberechnung refresh={refresh} isOpen={isOpen} value={bValue}/> : ''}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {currentStep === 1 && (
                <div className="step-content">
                  <div className="col-sm-12 col-xl-12">
                    <div className="card inner-scroll-two sc-pb">
                      <div className="card-body">
                        <DistributionSign></DistributionSign>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        {props?.sidebar != false ? (
          <>
            <div className="col-sm-12 col-md-12 col-lg-4 col-xl-4 col-xxl-3 pl-4 ">
              <div className="card inner-right-siderbar-scroll sc-pb">
                <div className="card-body">
                  <div className="row">
                    <div className="col-sm-12">
                      {data?.quations?.id ? (
                        <button
                          className="btn btn-light position-relative btn-brand-hover"
                          onClick={() => {
                            // document
                            //   .getElementById("digitalSignQuation")
                            //   .click();
                            setCurrentStep(1);
                          }}
                          style={{
                            width: "100%",
                            textAlign: "left",
                          }}
                        >
                          {t("Unterschreiben")}
                          <span className="btn-icon-right">
                            <i class="ph ph-caret-right"></i>
                          </span>
                        </button>
                      ) : (
                        <button
                          className="btn btn-light position-relative btn-brand-hover"
                          onClick={() => {
                            setErrorSB(true);
                          }}
                          style={{ width: "100%", textAlign: "left" }}
                        >
                          {t("Unterschreiben")}
                          <span className="btn-icon-right">
                            <i class="ph ph-caret-right"></i>
                          </span>
                        </button>
                      )}

                      {JSON.parse(Cookies.get("permissions")).indexOf(
                        "Reseller"
                      ) !== -1 ? (
                        <button
                          type="button"
                          className="mb-2 btn btn-light position-relative btn-brand-hover"
                          onClick={() => {
                            addNewAngebot();
                          }}
                          style={{
                            width: "100%",
                            textAlign: "left",
                          }}
                        >
                          {t("Angebot Hinzufügen")}
                          <span className="btn-icon-right">
                            <i class="ph ph-caret-right"></i>
                          </span>
                        </button>
                      ) : (
                        <></>
                      )}
                      <hr />
                    </div>
                    <div className="col-sm-12">
                      <p className="font-size-16 font-weight-500 text-primary">
                        Angebots Nr.
                      </p>
                      {data?.allquations
                        ? data?.allquations.map((quation, i) => (
                            <div
                              onClick={() => {
                                // Create a copy of the data object
                                let updatedData = { ...data };
                                // console.log(quation.id);
                                editAngebot(quation?.id);

                                // Loop through the allquations object and update the primary value
                                for (let [key, value] of Object.entries(
                                  updatedData.allquations
                                )) {
                                  if (value?.id === quation?.id) {
                                    updatedData.allquations[key].primary = "1";
                                  } else {
                                    updatedData.allquations[key].primary = "0";
                                  }
                                }

                                // Set the updated data to the state
                                setData(updatedData);

                                // Call the primaryAngebot function with the appropriate values
                                primaryAngebot(true, quation?.id);
                              }}
                              className={
                                quation?.id == data?.quations?.id
                                  ? "btn-brand-hover-with-border mb-2 d-flex justify-content-between align-items-center border-brand-primary px-2 py-1 border-radius-5 project-details-right-side"
                                  : "mb-2 d-flex justify-content-between align-items-center border-primary px-2 py-1 border-radius-5 project-details-right-side btn-brand-hover-with-border"
                              }
                            >
                              <p className="text-brand-primary font-size-14 font-weight-500 mb-0">
                                {quation?.id}
                              </p>
                              <a className="profile">
                                <img
                                  src="/assets/img/placeholder.png"
                                  className="border-primary"
                                  alt="Profile"
                                />
                                <span className="font-weight-500 font-size-14 text-capitalize">
                                  {quation?.user?.name}
                                </span>
                              </a>
                            </div>
                          ))
                        : ""}
                      <hr />
                    </div>

                    {/*<div className="col-12">
                                        <div className="card mb-3">
                                            <div className="card-body p-3">
                                                <div className="d-flex">
                                                    <div>
                                                        <div className="icon icon-shape bg-gradient-dark text-center border-radius-md">
                                                            <i className="ni ni-money-coins text-lg opacity-10" aria-hidden="true" />
                                                        </div>
                                                    </div>
                                                    <div className="ms-3">
                                                        <div className="numbers">
                                                            <p className="text-sm mb-0 text-capitalize font-weight-bold">{t('Creator')}</p>
                                                            <h5 className="font-weight-bolder mb-0">
                                                                {data?.quations?.user?.name ? data?.quations?.user?.name : '-'}
                                                            </h5>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>*/}
                  </div>

                  <div className=" mb-3">
                    <div className="card-header p-0">
                      <p className="font-size-16 font-weight-500 text-primary">
                        {t("Email")}
                      </p>
                    </div>
                    <div className="">
                      <EmailForm
                        projectid={
                          props?.projectid ? props?.projectid : params.id
                        }
                        email={getValues('email')}
                        id={data?.quations?.id}
                        type={"angebot"}
                        pdf={true}
                        netto={data?.quations?.gesami_netto}
                      />
                    </div>
                  </div>
                  <Timeline refresh={refresh2} />
                </div>
              </div>
            </div>
          </>
        ) : (
          ""
        )}

        <div
          className="fixed-bottom mb-3 fixed-bottom-width-available pe-3" 
        >
          <div className="card">
            <div className="card-body text-end d-flex justify-content-end">
              {data?.quations?.id ? (
                <>
                  {JSON.parse(Cookies.get("permissions")).indexOf("PDU") !==
                  -1 ? (
                    <>
                      <a
                        className="btn btn-default border-primary me-3 text-brand-primary d-flex align-items-center"
                        target={"_blank"}
                        rel="noreferrer"
                        href={
                          Cookies.get("backend_url") +
                          "quation/" +
                          data?.quations?.id
                        }
                      >
                       <i class="ph ph-file-pdf font-size-20 me-2"></i> {t("Quoation PDF")}
                      </a>
                    </>
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <></>
              )}

              {!saving ? <>
                <button
                className="btn btn-primary"
                type="button"
                onClick={currentStep == 0 ? () => document.getElementById("editdstBtn").click() : () => document.getElementById("saveSign").click()}
              >
                {t("Save")}{" "} 
              </button>
              </> : 
                <button
                className="btn btn-disabled"
                type="button"
                disabled 
              >
                {t("Saving...")}{" "} 
              </button>
              }
            </div>
          </div>
        </div>

        {renderSuccessSB}
        {renderErrorSB}
        <ProfitCalculatonCreate value={bValue} onUpdate={handleBUpdate} refreshh={refresh}></ProfitCalculatonCreate>
        <div className={`right-modal ${showTwo ? " show" : ""}`}>
          {(props?.projectid || params?.id) && productIndex !== "" && (
            <PositionsModal
              setShowTwo={setShowTwo}
              //id={props?.projectid || params.id}
              //value={productItems.items[productIndex].item}
              //value={productItems.items[productIndex]}
              productItems={productItems}
              productIndex={productIndex}
              bundles={bundles}
              products={products}
              getSpeicherTypesFromBundles={getSpeicherTypesFromBundles}
              getWattsFromBundles={getWattsFromBundles}
              setProductItems={setProductItems}
              setproductItemsRefresh={setproductItemsRefresh}
              productItemsRefresh={productItemsRefresh}
              mitarbeiterkennungOptional={mitarbeiterkennungOptional}
              setMitarbeiterkennung={setMitarbeiterkennung}
              withTypeSearch={withTypeSearch}
              withWattSearch={withWattSearch}
              withOrWithoutSpeicher={withOrWithoutSpeicher}
              //options={productItems?.[productIndex]?.type == 'bundle' ? bundles : products}
            />
          )}
        </div>
      </div>
    </>
  );
}

export default Distribution;
