import { React, useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import "./style.css"

import IndexTableProject from "./IndexTableProject";
import IndexTableProjectWithSalses from "./IndexTableProjectWithSalses";
import IndexTableEmployee from "./IndexTableEmployee";
import IndexTableBundle from "./IndexTableBundle";

const OutputModal = (props) => {
    const { t } = useTranslation();
    const [saving, setSaving] = useState(false);
    const [refresh, setRefresh] = useState(0);
    const [fileData, setFileData] = useState(null);
    const [fileName, setFileName] = useState(t('Files Supported') + ": XLSX");
    const [success, setSuccess] = useState(null);

    return (
        <>
            <button
                type="button"
                id={props?.type + 'outputModal-show-button'}
                className="btn bg-gradient-primary d-none"
                data-bs-toggle={"modal"}
                data-bs-target={`#${props?.type}outputModal`}
            >{t('Import Modal')}</button>

            <div className="modal fade" id={props?.type + "outputModal"} tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-xl" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="outputtModalLabel">{t('Import')}</h5>
                            <button
                                type="button"
                                onClick={() => { setSuccess(null); }}
                                className="btn-close text-dark"
                                id="outputModal-button-close"
                                data-bs-dismiss="modal"
                                aria-label="Close">
                            </button>
                        </div>

                        {props.type == 'project' ? (
                            <IndexTableProject filename={props.filename} type={props.type} />
                        ) : props.type == 'projectWithSales' ? (
                            <IndexTableProjectWithSalses filename={props.filename} type={props.type} />
                        ) : props.type == 'employee' ? (
                            <IndexTableEmployee filename={props.filename} type={props.type} />
                        ) : props.type == 'bundle' ? (
                            <IndexTableBundle filename={props.filename} type={props.type} />
                        ) : ''}
                    </div>
                </div>
            </div>
        </>
    )
}

export default OutputModal