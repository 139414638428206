import { Grid } from "@mui/material";
import SoftBox from "components/SoftBox";
import Swal from 'sweetalert2';
import React, { useMemo, useState, useRef, useEffect } from "react";
import { NavLink } from "react-router-dom";
import callFetch from "helpers/callFetch";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import SoftTypography from "components/SoftTypography";
import SoftEditor from "components/SoftEditor";
import SoftSnackbar from "components/SoftSnackbar";

import ProjectPost from "pages/project/create/ProjectPost";

import ConfiguratorRoot from "examples/Configurator/ConfiguratorRoot";
import Icon from "@mui/material/Icon";
import Divider from "@mui/material/Divider";
import {
  useSoftUIController,
  setOpenConfigurator,
  setTransparentSidenav,
  setMiniSidenav,
  setFixedNavbar,
  setSidenavColor,
} from "context";

const EditPlan = (props) => {
  const { t } = useTranslation();
  const [description, setDescription] = useState("Sehr geehrte Damen und Herren, <br/><br/>hiermit bestätigen wir Ihnen, Ihren Termin mit unserem Techniker. <br/><br/>Sollten Sie diesen Termin nicht wahrnehmen können, bitten wir Sie uns unter der info@solacloud.de zu informieren. <br/><br/>Bitte zögern Sie nicht, im Falle von Unklarheiten oder Rückfragen, mit uns Kontakt aufzunehmen. <br/><br/>Gerne steht Ihnen unser Team zu unseren Geschäftszeiten Mo. – Do. von 09:00 – bis 18:00 Uhr und Fr. von 09:00 bis 15:00 Uhr unter +49 89 904220890 oder jederzeit per eMail unter info@solacloud.de zur Verfügung.<br/><br/>Ihr Solacloud Team<br/>Solacloud GmbH<br/>+49 89 904220890 info@solacloud.de");
  const [refresh, setRefresh] = useState(0);
  const [saving, setSaving] = useState(false);
  const [sendingEmail, setSendingEmail] = useState(false);
  const [backToPlan, setBackToPlan] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [employees, setEmployees] = useState([]);
  const [data, setData] = useState([]);
  const [isVisible, setIsVisible] = useState(0);
  const [miltarbeiterId, setMiltarbeiterId] = useState(0);

  const [activeTab, setActiveTab] = useState(0);


  const fp = useRef(null);
  const fpt = useRef(null);
  //const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, true);
  const [controller, dispatch] = useSoftUIController();
  const { openConfigurator, transparentSidenav, miniSidenav, fixedNavbar, sidenavColor } = controller;
  const handleCloseConfigurator = () => setOpenConfigurator(dispatch, false);
  const handleClearButton = () => {
    document.getElementById('ortInput').value = '';
    document.getElementById('min').value = '';
    document.getElementById('max').value = '';
    document.getElementById('projectidInput').value = '';
    document.getElementById('kundenInput').value = '';
    document.getElementById('vkbInput').value = '';
    fp.current.flatpickr.clear();
    fpt.current.flatpickr.clear();
  }

  const [messageSb, setMessageSB] = useState(false);
  const [messageSbColor, setMessageSbColor] = useState('success');
  const [messageSbTitle, setMessageSbTitle] = useState('Something wrong!');

  const renderMessageSB = (
    <SoftSnackbar
      color={messageSbColor}
      title={t(messageSbTitle)}
      //content={t("Notification Description")}
      open={messageSb}
      onClose={() => setMessageSB(false)}
      close={() => setMessageSB(false)}
    />
  );


  const {
    register,
    handleSubmit,
    setError,
    setValue,
    getValues,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (!props?.data?.id) return;

    setSendingEmail(false);

    var url = '';

    if (props?.data?.planform == "Reclamation") {
      url = "reclamation-plan/" + props?.data?.id + "/edit";
    } else if (props?.data?.planform == "ACFertigmeldung") {
      url = "zahlertermin-plan/" + props?.data?.id + "/edit";
    } else {
      url = "assign-order-processing/" + props?.data?.id + "/edit";
    }

    callFetch(url, "GET", []).then((res) => {
      setEmployees(res?.data?.employees);
      setData(res?.data?.data);
    });

  }, [props?.data?.id, props?.refresh]);

  useEffect(() => {
    if (!data?.id) return;

    for (let [key, value] of Object.entries(data)) {
      setValue(key, (value == null || value == 'null' ? "" : value));
    }
  }, [data, props?.refresh]);

  const sendEmail = () => {
    if (!data?.id) return;
    //e.preventDefault();

    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-primary m-0',
        cancelButton: 'btn btn-secondary m-0'
      },
      buttonsStyling: false
    });

    return swalWithBootstrapButtons.fire({
      title: t('Message'),
      text: t("Are you sure to send this E-mail?"),
      // icon: 'warning',
      showCancelButton: true,
      confirmButtonText: t('Yes, do it!'),
      cancelButtonText: t('Cancel'),
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {

        //console.log(props?.data);


        var formData = new FormData();
        formData.event_id = props?.data?.id;
        formData.planform = props?.data?.planform;
        formData.description = description;
        formData.project_id = data.project_id;
        formData.miltarbeiter = getValues('miltarbeiter');
        formData.time = getValues('time');
        formData.date = getValues('date');
        formData.dachmontag_elektromontage = getValues('dachmontag_elektromontage');
        callFetch("send-new-plan-email", "POST", formData, setError).then((res) => {
          // console.log(res.data);
          setSaving(false);
          if (!res.ok) return;
          setSendingEmail(true);
          setSubmitSuccess(true);
        });
      }
    });
  }

  const sendBackToPlan = () => {
    if (!data?.id) return;

    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-primary m-0',
        cancelButton: 'btn btn-secondary m-0'
      },
      buttonsStyling: false
    });

    return swalWithBootstrapButtons.fire({
      title: t('Message'),
      text: t("Are you sure to send back to plan?"),
      // icon: 'warning',
      showCancelButton: true,
      confirmButtonText: t('Yes, do it!'),
      cancelButtonText: t('Cancel'),
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        setSaving(true);

        var url = '';
        if (props?.data?.planform == "Reclamation") {
          url = "reclamation-plan/" + data.id;
        } else {
          url = "assign-order-processing/" + data.id;
        }

        callFetch(url, "POST", { '_method': 'DELETE' }, null).then((res) => {

          setSaving(false);
          setSubmitSuccess(true);
          props.setRefresh((Math.random() * 1000) + 1000);
          handleCloseConfigurator();

          if (document.getElementById('planHinzufugenRefresh')) {
            document.getElementById('planHinzufugenRefresh').click()
          }

          if (!res.ok) {
            setMessageSbTitle('Something Wrong!');
            setMessageSbColor('error');
            setMessageSB(true);
            return;
          } else {
            setMessageSbTitle('send back to plan.');
            setMessageSbColor('success');
            setMessageSB(true);
          }
        });


      }
    });
  }

  const onSubmit = (formData) => {
    if (!data?.id) { alert('id not found'); return; }
    setSaving(true);
    formData.project_id = data.project_id;
    formData.description = description;

    var url = '';
    if (props?.data?.planform == "Reclamation") {
      url = "reclamation-plan/" + data.id;
    } else if (props?.data?.planform == "ACFertigmeldung") {
      url = "zahlertermin-plan/" + props?.data?.id;
    } else {
      url = "assign-order-processing/" + data.id;
    }

    callFetch(url, "POST", formData, setError).then((res) => {
      //console.log('edit plan submit')

      setSaving(false);

      if (!res.ok) {
        setMessageSbTitle('Something Wrong!');
        setMessageSbColor('error');
        setMessageSB(true);
      } else {
        setMessageSbTitle('Save Successful');
        setMessageSbColor('success');
        setMessageSB(true);
        handleCloseConfigurator();
      }

      if (!res.ok) return;
      setSubmitSuccess(true);
      props.setRefresh((Math.random() * 1000) + 1000);
    });
  };

  const setNearestMiltarbeiter = (id) => {
    if (props?.data?.id) return;

    setValue('miltarbeiter', id);
    setMiltarbeiterId({
      id: id,
      name: '',
    });
  }

  const setDirectionDistance = (data) => {
    console.log('setDirectionDistance');
  }



  return (
    <>
      <ConfiguratorRoot
        variant="permanent"
        ownerState={{ openConfigurator }}
        configWidth={'100%'}
        configMaxWidth={'520px'}
        padding={0}
        sx={{
          maxWidth: '520px'
        }}
      >


        <div className="p-3 border-bottom d-flex align-items-center justify-content-between gap-2">
          <h5 className="text-title m-0">{t("Edit Plan")}</h5>
          <i
            className="ph ph-x cursor-pointer text-title"
            onClick={handleCloseConfigurator}
          ></i>
        </div>


        <div className="p-3">
          <h5 className="text-title m-0">{t('Projektnummer')}: <NavLink to={'/projects/' + data?.project_id} className="text-brand">{data?.project_id}</NavLink></h5>

          <div className="p-3 bg-secondary border-radius mt-3">
            <div className="d-flex align-items-center">
              <div className="d-flex col-6 align-items-center gap-3">
                <i
                  className="ph ph-user-circle-check p-2 text-secondary"
                  style={{
                    fontSize: '20px',
                    background: '#E7EBF3',
                    borderRadius: '5px'
                  }}
                ></i>
                <div>
                  <p className="text-sm text-secondary m-0">Name</p>
                  <p className="text-sm text-primary m-0">{data?.project?.vorname} {data?.project?.name}</p>
                </div>
              </div>

              <div className="d-flex col-6 align-items-center gap-3">
                <i
                  className="ph ph-user-circle-check p-2 text-secondary"
                  style={{
                    fontSize: '20px',
                    background: '#E7EBF3',
                    borderRadius: '5px'
                  }}
                ></i>
                <div>
                  <p className="text-sm text-secondary m-0">Telefonnummer</p>
                  <p className="text-sm text-primary m-0">{data?.project?.telefonnummer}</p>
                </div>
              </div>
            </div>

            <div className="d-flex align-items-center mt-4">
              <div className="d-flex col-6 align-items-center gap-3">
                <i
                  className="ph ph-user-circle-check p-2 text-secondary"
                  style={{
                    fontSize: '20px',
                    background: '#E7EBF3',
                    borderRadius: '5px'
                  }}
                ></i>
                <div>
                  <p className="text-sm text-secondary m-0">Adresse</p>
                  <p className="text-sm text-primary m-0">{data?.project?.street}, {data?.project?.nr}</p>
                </div>
              </div>

              <div className="d-flex col-6 align-items-center gap-3">
                <i
                  className="ph ph-user-circle-check p-2 text-secondary"
                  style={{
                    fontSize: '20px',
                    background: '#E7EBF3',
                    borderRadius: '5px'
                  }}
                ></i>
                <div>
                  <p className="text-sm text-secondary m-0">{t('PLZ, Ort')}</p>
                  <p className="text-sm text-primary m-0">{data?.project?.plz}, {data?.project?.ort}</p>
                </div>
              </div>
            </div>
          </div>


          <ul className="nav nav-tabs border-0 gap-2 mt-3">
            <li className="nav-item">
              <button onClick={() => { setActiveTab(0); }} className={`px-2 text-primary text-md nav-link ${activeTab === 0 ? 'active' : ''}`}>Details</button>
            </li>
            <li className="nav-item">
              <button onClick={() => { setActiveTab(1) }} className={`px-2 text-primary text-md nav-link ${activeTab === 1 ? 'active' : ''}`}>Notizen</button>
            </li>
          </ul>
        </div>


        {activeTab === 0 ? (
          <>

            <form
              className={`needs-validation ${Object.keys(errors).length ? "was-validated" : ""}`}
              onSubmit={handleSubmit(onSubmit)}
              noValidate
              autoComplete="off"
              style={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                borderRadius: "0px", // No need for !important in inline styles
              }}
            >
              <div className="p-3"
                style={{
                  flex: 1,
                  overflowY: 'auto'
                }}
              >
                <input type="hidden" defaultValue="PUT" {...register("_method")} />
                <div className="row">
                  <div className="col-sm-6">
                    <label>{t("Start Datum")} *</label>
                    <input
                      type="date"
                      className="form-control form-control-sm mb-2 flatpickr"
                      placeholder={t("eg. 16-04-2022")}
                      {...register("date", {
                        required: true,
                      })}
                      required
                    />
                    <div className="invalid-feedback">
                      {errors.date && errors.date.message}
                    </div>
                  </div>

                  <div className="col-sm-6">
                    <label>{t("Start Uhrzeit")} *</label>
                    <select
                      className="form-control form-control-sm mb-2"
                      {...register("time", {
                        required: true,
                      })}
                      required
                    >
                      <option value="">--</option>
                      <option value="08:00">08:00</option>
                      <option value="10:00">10:00</option>
                      <option value="12:00">12:00</option>
                      <option value="14:00">14:00</option>
                      <option value="16:00">16:00</option>
                      <option value="18:00">18:00</option>
                      <option value="20:00">20:00</option>
                    </select>
                    <div className="invalid-feedback">
                      {errors.date && errors.date.message}
                    </div>
                  </div>

                  <div className="col-sm-6">
                    <label>{t("End Datum")} *</label>
                    <input
                      type="date"
                      className="form-control form-control-sm mb-2 flatpickr"
                      placeholder={t("eg. 16-04-2022")}
                      {...register("end_date", {
                        required: true,
                      })}
                      required
                    />
                    <div className="invalid-feedback">
                      {errors.date && errors.date.message}
                    </div>
                  </div>

                  <div className="col-sm-6">
                    <label>{t("End Uhrzeit")} *</label>
                    <select
                      className="form-control form-control-sm mb-2"
                      {...register("end_time", {
                        required: true,
                      })}
                      required
                    >
                      <option value="">--</option>
                      <option value="08:00">08:00</option>
                      <option value="10:00">10:00</option>
                      <option value="12:00">12:00</option>
                      <option value="14:00">14:00</option>
                      <option value="16:00">16:00</option>
                      <option value="18:00">18:00</option>
                      <option value="20:00">20:00</option>
                    </select>
                    <div className="invalid-feedback">
                      {errors.date && errors.date.message}
                    </div>
                  </div>


                  <div className="col-sm-6">
                    <label>{t("Employees")} *</label>
                    <select
                      className="form-control form-control-sm mb-2"
                      {...register("miltarbeiter", {
                        required: true,
                        onChange: (e) => {
                          setMiltarbeiterId({
                            id: e.target.value,
                            name: e.target.selectedOptions[0].text
                          });
                        }
                      })}
                      required
                    >
                      <option>----</option>
                      {employees?.map((technician, index) => (
                        <option value={technician.id}>
                          {technician.name}
                        </option>
                      ))}
                    </select>
                    <div className="invalid-feedback">
                      {errors.employees && errors.employees.message}
                    </div>
                  </div>

                  <div className="col-sm-6">
                    <label>{t("Dachmontage/Elektromontage")} *</label>
                    <select
                      className="form-control form-control-sm mb-2"
                      {...register("dachmontag_elektromontage", {
                        required: true,
                      })}
                      required
                      readOnly={true}
                    >
                      <option value="Dachmontage">Dachmontage</option>
                      <option value="Elektromontage">Elektromontage</option>
                    </select>
                    <div className="invalid-feedback">
                      {errors.date && errors.date.message}
                    </div>
                  </div>
                </div>
              </div>

              <div className="p-3 border-top d-flex align-items-center justify-content-between gap-2">

                <div className="dropup">
                  <button
                    type="button"
                    className="btn btn-secondary m-0"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i class="ph ph-dots-three-vertical m-0"></i>
                  </button>

                  <ul
                    className="dropdown-menu dropdown-menu-end"
                  >
                    <li
                      className={`cursor-pointer dropdown-item ${sendingEmail ? 'text-success' : ''}`}
                      data-bs-toggle="modal"
                      data-bs-target="#planEmailSendModal"
                    >{!saving ? t("Send E-mail") : t("Sending ...")}</li>

                    <li
                      className="cursor-pointer dropdown-item text-danger"
                      href="#"
                      onClick={() => {
                        if (backToPlan) return;

                        sendBackToPlan()
                      }}
                    >{t("Back To Plan")}</li>
                  </ul>
                </div>

                {!saving ? (
                  <button type="submit" className="btn btn-primary m-0 w-100">
                    {t("Save")}
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn btn-disabled m-0 w-100"
                    disabled
                  >
                    {t("Saving ...")}
                  </button>
                )}
              </div>
            </form>

          </>
        ) : (
          <>
            <div className="p-3">
              <ProjectPost
                id={data?.project_id}
                create={true}
              />
            </div>
          </>
        )}
      </ConfiguratorRoot >


      <div className="modal fade" id="planEmailSendModal" tabIndex={-1} role="dialog" aria-labelledby="planEmailSendModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered  modal-lg" role="document">
          <div className="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="planEmailSendModalLongTitle">{t("Send E-mail")}</h5>
              <button type="button" className="btn-close text-dark" data-bs-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div class="modal-body">
              <SoftTypography component="label" variant="caption" fontWeight="bold">{t('Termin Verschieben')}</SoftTypography>
              <SoftEditor
                value={description}
                onChange={setDescription}
              />
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-primary" onClick={(e) => sendEmail()}>{t("Send E-mail")}</button>
            </div>
          </div>
        </div>
      </div>



      <button type="button" id="triggerEditPlanBtn" onClick={handleConfiguratorOpen} className="btn bg-gradient-primary d-none">Edit Plan</button>
      {renderMessageSB}
    </>
  );
};

export default EditPlan;
