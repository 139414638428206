import {
  React,
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";
import { Navigate, NavLink, useNavigate } from "react-router-dom";
// @mui material components
import Card from "@mui/material/Card";
// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";
import SoftSelect from "components/SoftSelect";
import Select from "react-select";
import { useTranslation } from "react-i18next";
//import { Textarea } from "flowbite-react";
import { useForm } from "react-hook-form";
import callFetch from "../../helpers/callFetch";
import LastMessage from "./LastMessage";
const ChatSendMessage = forwardRef((props, ref) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [recipients, setRecipients] = useState([]);
  const [query, setQuery] = useState("");
  const [users, setUsers] = useState([]);
  const [message, setMessage] = useState("asdfsdfas");
  const [saving, setSaving] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const {
    register,
    handleSubmit,
    setError,
    setValue,
    getValues,
    formState: { errors },
  } = useForm();

  const renderList = () => {
    return users.map((data) => ({ value: data.id, label: data.name }));
  };

  const handleChange = () => {};

  useImperativeHandle(ref, () => ({
    triggerFormSubmit: () => {
      document.getElementById("chatForm").requestSubmit();
    },
  }));

  useEffect(() => {
    callFetch("chat/users", "GET", []).then((res) => {
      setUsers(res.data);
    });
  }, [refresh]);

  // const [participations, setParticipations] = useState([]);
  // useEffect(() => {
  // callFetch("chat-participation", "GET", []).then((res) => {
  //   //   console.log(res.contacts);
  //     setParticipations(res.contacts);
  // });
  // }, [refresh]);

  const onSubmit = (formData) => {
    setSaving(true);
    let recipients = getValues("recipients");
    let firstValue = 0;
    let firstValueText = '';
    try {
      recipients = JSON.parse(recipients); // Convert string to JSON array
    } catch (error) {
      console.error("Invalid JSON format:", error);
      recipients = []; // Set to empty array if parsing fails
    }

    if (Array.isArray(recipients) && recipients.length > 0) {
      firstValue = recipients[0]?.value;
      firstValueText = recipients[0]?.label;
    } else {
      console.log("Recipients data is missing or empty.");
    }

    callFetch(
      "message-sent/" + getValues("recipients"),
      "POST",
      formData,
      setError
    ).then((res) => {
      setSaving(false);
      setSubmitSuccess(true);
      setRefresh(refresh + 1);
      document.getElementById("close-newmsg").click();
      navigate("/chat/" + firstValue+'/'+firstValueText);
    });
  };

  return (
    <>
      <div className="layout-wrapper d-lg-flex">
        {/* start left sidebar code which is copyed */}

        {/* end chat-leftsidebar */}

        {/* Start User chat */}
        <div className=" w-100 ">
          <div className="d-lg-flex">
            {/* start chat conversation section */}
            <div className="w-100">
              {/* start chat conversation */}
              <div className="">
                <form onSubmit={handleSubmit(onSubmit)} id="chatForm">
                  <SoftBox pb={3}>
                    <div className="col-md-12">
                      <label className="text-secondary font-size-12 font-weight-500">
                        {t("Benutzer")}
                      </label>
                      <div className="form-group">
                        <Select
                          placeholder={t("Select")}
                          options={renderList()}
                          value={recipients}
                          onChange={(e) => {
                            setRecipients(e);
                            setValue("recipients", JSON.stringify(e));
                          }}
                          isMulti
                        />
                        <div className="invalid-feedback"></div>
                      </div>
                      {/* {recipients.map((recipient) => (
                                <input type="hidden" {...register('receiver')} value={recipient.value} />
                                ))}  */}
                      <div className="form-group">
                        {/* <div style={{ position: 'relative', display: 'inline-block', cursor: 'pointer', top: '30px', left: '12px', zIndex: '99' }} className="me-3"> */}
                        {/* <input
                                    {...register('attachment')}
                                    type="file"
                                    id="attachmentInput"
                                    onChange={handleChange}
                                    className=""
                                    style={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        width: '100%',
                                        height: '100%',
                                        opacity: 0,
                                        cursor: 'pointer',
                                    }}
                                />
                                <img
                                    src="../../assets/icons/Paperclip.svg"
                                    alt="Attach File"
                                    style={{cursor: 'pointer'}}
                                /> */}
                        {/* </div> */}
                        <SoftInput
                          placeholder={t("Type here...")}
                          onChange={(e) => setMessage(e.target.value)}
                          multiline
                          rows={5}
                          {...register("message")}
                        />
                      </div>

                      <div className="row g-3">
                        <div className="col-12 my-4">
                          {!saving && (
                            <button
                              type="submit"
                              className="btn btn-primary d-none"
                            >
                              {t("Send")}
                            </button>
                          )}
                          {saving && (
                            <button
                              type="submit"
                              className="btn btn-disabled d-none"
                              disabled
                            >
                              {t("Sending ...")}
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </SoftBox>
                </form>
              </div>
              {/* end chat conversation end */}
            </div>
          </div>
          {/* End User chat */}
        </div>
      </div>
    </>
  );
});

export default ChatSendMessage;
