import React from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";

const Alert = ({ color, icon, title, content, dateTime, close, ...rest }) => {
  const { t } = useTranslation();
  return (
    <>
       <div
            className={`d-flex justify-content-between gap-2 p-3 border-radius notification-${color}`}
            style={{
            background: '#ffffff',
            width: '100%'
            }}
        >
        <div className="d-flex justify-content-between gap-2">
          <i className={`notification-icon ph ph-${icon ? icon
            : color === 'success' ? 'check-circle'
              : color === 'info' ? 'info'
                : color === 'warning' ? 'warning'
                  : color === 'error' ? 'x-circle'
                    : 'info'
            } title-xl ${color === 'success' ? 'text-status-success-primary'
              : color === 'info' ? 'text-status-info-primary'
                : color === 'warning' ? 'text-status-warning-primary'
                  : color === 'error' ? 'text-status-error-primary'
                    : 'text-status-info-primary'
            }`}></i>
          <div className="d-flex flex-column gap-1">
            <p className="m-0 text-primary text-lg font-weight-600 text-capitalize">{title}</p>
            {content && <p className="m-0 text-secondary text-link">{content}</p>}
            {dateTime && <p className="m-0 text-secondary text-link">{dateTime}</p>}
          </div>
        </div>
        {/* <i className="notification-icon ph ph-x text-xl text-secondary" onClick={close}></i> */}
      </div>
    </>
  );
};

export default Alert; 