/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/**
 * The base colors for the Soft UI Dashboard PRO React.
 * You can add new color using this file.
 * You can customized the colors for the entire Soft UI Dashboard PRO React using thie file.
 */

const colors = {
  background: {
    default: "#f8f9fa",
  },

  text: {
    main: "#273042",
    focus: "#273042",
  },

  transparent: {
    main: "transparent",
  },

  white: {
    main: "#ffffff",
    focus: "#ffffff",
  },

  black: {
    light: "#17181814",
    main: "#000000",
    focus: "#000000",
  },

  primary: {
    main: "#fff",
    focus: "#ad0a87",
  },

  secondary: {
    main: "#8392ab",
    focus: "#96a2b8",
  },

  info: {
    main: "#005498",
    focus: "#3acaeb",
  },

  success: {
    main: "#72AC27",
    focus: "#95dc39",
  },

  warning: {
    main: "#fbcf33",
    focus: "#fcd652",
  },

  error: {
    main: "#ea0606",
    focus: "#c70505",
  },

  light: {
    main: "#63B0FF1F",
    focus: "#e9ecef",
  },

  dark: {
    main: "#273042",
    focus: "#273042",
  },

  bgcsone: {
    main: "#cb0c9f",
    focus: "#cb0c9f",
  },

  grey: {
    100: "#f8f9fa",
    200: "#e9ecef",
    300: "#dee2e6",
    400: "#ced4da",
    500: "#adb5bd",
    600: "#6c757d",
    700: "#495057",
    800: "#343a40",
    900: "#212529",
  },

  gradients: {
    primary: {
      main: "#7928ca",
      state: "#ff0080",
    },

    secondary: {
      main: "#627594",
      state: "#a8b8d8",
    },

    bgcsone: {
      main: "#cb0c9f",
      state: "#cb0c9f",
    },
    
    bgcsan: {
      main: "#17c1e8",
      state: "#17c1e8",
    },
    
    bgcsub: {
      main: "#344767",
      state: "#344767",
    },
    
    bgcsku: {
      main: "#f53939",
      state: "#f53939",
    },
   
    bgcske: {
      main: "#b700ff",
      state: "#b700ff",
    },
   
    bgcsst: {
      main: "#006344",
      state: "#006344",
    },

    bgcskun: {
      main: "#a17000",
      state: "#a17000",
    },

    bgcsent: {
      main: "#8392ab",
      state: "#8392ab",
    },

    bgcszei: {
      main: "#0011ff",
      state: "#0011ff",
    },

    info: {
      main: "#2152ff",
      state: "#005498",
    },

    success: {
      main: "#17ad37",
      state: "#98ec2d",
    },

    warning: {
      main: "#f53939",
      state: "#fbcf33",
    },

    error: {
      main: "#ea0606",
      state: "#ff667c",
    },

    light: {
      main: "#ced4da",
      state: "#ebeff4",
    },

    dark: {
      main: "#141727",
      state: "#3a416f",
    },
  },

  socialMediaColors: {
    facebook: {
      main: "#3b5998",
      dark: "#344e86",
    },

    twitter: {
      main: "#55acee",
      dark: "#3ea1ec",
    },

    instagram: {
      main: "#125688",
      dark: "#0e456d",
    },

    linkedin: {
      main: "#0077b5",
      dark: "#00669c",
    },

    pinterest: {
      main: "#cc2127",
      dark: "#b21d22",
    },

    youtube: {
      main: "#e52d27",
      dark: "#d41f1a",
    },

    vimeo: {
      main: "#1ab7ea",
      dark: "#13a3d2",
    },

    slack: {
      main: "#3aaf85",
      dark: "#329874",
    },

    dribbble: {
      main: "#ea4c89",
      dark: "#e73177",
    },

    github: {
      main: "#24292e",
      dark: "#171a1d",
    },

    reddit: {
      main: "#ff4500",
      dark: "#e03d00",
    },

    tumblr: {
      main: "#35465c",
      dark: "#2a3749",
    },
  },

  alertColors: {
    primary: {
      main: "#7928ca",
      state: "#d6006c",
      border: "#efb6e2",
    },

    secondary: {
      main: "#627594",
      state: "#8ca1cb",
      border: "#dadee6",
    },

    info: {
      main: "#2152ff",
      state: "#02c6f3",
      border: "#b9ecf8",
    },

    success: {
      main: "#17ad37",
      state: "#84dc14",
      border: "#daf3b9",
    },

    warning: {
      main: "#f53939",
      state: "#fac60b",
      border: "#fef1c2",
    },

    error: {
      main: "#ea0606",
      state: "#ff3d59",
      border: "#f9b4b4",
    },

    light: {
      main: "#ced4da",
      state: "#d1dae6",
      border: "#f8f9fa",
    },

    dark: {
      main: "#141727",
      state: "#2c3154",
      border: "#c2c8d1",
    },
  },

  badgeColors: {
    primary: {
      background: "#f883dd",
      text: "#a3017e",
    },

    secondary: {
      background: "#e4e8ed",
      text: "#5974a2",
    },

    info: {
      background: "#abe9f7",
      text: "#08a1c4",
    },

    success: {
      background: "#6ecd972b",
      text: "#368D5A",
    },

    warning: {
      background: "#fef5d3",
      text: "#fbc400",
    },

    error: {
      background: "#ff465b40",
      text: "#D4273B",
    },

    light: {
      background: "#ffffff",
      text: "#c7d3de",
    },

    dark: {
      background: "#8097bf",
      text: "#1e2e4a",
    },
  },

  inputColors: {
    borderColor: { main: "#E7EBF3", focus: "#E7EBF3" },
    boxShadow: "#E7EBF3",
    error: "#fd5c70",
    success: "#66d432",
  },

  sliderColors: {
    thumb: { borderColor: "#d9d9d9" },
  },

  circleSliderColors: {
    background: "#d3d3d3",
  },

  tabs: {
    indicator: { boxShadow: "#ddd" },
  },
};

export default colors;
