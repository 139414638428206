import React, { useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import SoftSelect from "components/SoftSelect";
import CalendarRoot from "examples/Calendar/CalendarRoot";
import { useTranslation } from "react-i18next";

function Calendar({ dashboard, absenceBtn = true, searchKey, setRefresh, refresh, header, abhnameFilter, getAbhnamheStatus, ...rest }) {
  const { t } = useTranslation();
  const calendarRef = useRef(null);
  const [title, setTitle] = useState("");

  const validClassNames = [
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ];

  const events = rest.events
    ? rest.events.map((el) => ({
      ...el,
      className: validClassNames.find((item) => item === el.className)
        ? `event-${el.className}`
        : "event-info",
    }))
    : [];

  useEffect(() => {
    // Update the calendar title dynamically when the component mounts or events change
    updateTitle();
  }, []);

  const updateTitle = () => {
    if (calendarRef.current) {
      const calendarApi = calendarRef.current.getApi();
      setTitle(calendarApi.view.title);
    }
  };

  const handlePrevClick = () => {
    if (calendarRef.current) {
      const calendarApi = calendarRef.current.getApi();
      calendarApi.prev();
      updateTitle();
    }
  };

  const handleNextClick = () => {
    if (calendarRef.current) {
      const calendarApi = calendarRef.current.getApi();
      calendarApi.next();
      updateTitle();
    }
  };

  return (
    <>
      <div className="card">
        <div className="card-header d-flex flex-column flex-lg-row gap-2 justify-content-between align-items-center">
          <div className="d-flex align-items-center justify-content-end gap-2">
            <h6 className="title">{title}</h6>
            <img
              className="calendar-prev"
              src="/assets/icons/CaretLeft.svg"
              onClick={handlePrevClick}
              alt="Previous"
              style={{ cursor: "pointer" }}
            />
            <img
              className="calendar-next"
              src="/assets/icons/CaretRight.svg"
              onClick={handleNextClick}
              alt="Next"
              style={{ cursor: "pointer" }}
            />
          </div>

          <div className="d-flex flex-column flex-lg-row align-items-center justify-content-end gap-2">
            {abhnameFilter && (
              <>
                <div
                  style={{
                    width: '250px'
                  }}
                >
                  <SoftSelect
                    className="w-100"
                    placeholder={t("Filtern der Abnahmeprotokole")}
                    options={[
                      { value: "Erfolgreiche AC", label: "AC Abnahme Erfolgreich" },
                      { value: "nicht Erfolgreiche AC", label: "AC Abnahme nicht Erfolgreich" },
                      { value: "Erfolgreiche DC", label: "DC Abnahme Erfolgreich" },
                      { value: "nicht Erfolgreiche DC", label: "DC Abnahme nicht Erfolgreich" },
                    ]}
                    onChange={(e) => {
                      getAbhnamheStatus(e.value);
                    }}
                  />
                </div>
              </>
            )}

            {dashboard && (
              <div
                style={{
                  width: '250px'
                }}
              >
                <input
                  type="text"
                  onChange={(e) => searchKey(e.target.value)}
                  className="form-control"
                  placeholder="Names, VK, AC, DC "
                />
              </div>
            )}

            <a
              className={`btn-link btn-plus-icon btn-icon-primary mt-2 ${absenceBtn == false ? 'd-none' : ''}`}
              data-bs-toggle="modal"
              data-bs-target="#absenceRequest"
              id="createAbwesenheitRequest"
              onClick={() => setRefresh(refresh + 1)}
            >
              {t("Abwesenheit")}
            </a>
          </div>
        </div>

        <div className="card-body pt-0 pb-3">
          <CalendarRoot>
            <FullCalendar
              ref={calendarRef}
              {...rest}
              plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin]}
              events={events}
              //height="100%"
              weekNumberFormat={{ week: "numeric" }}
              height="auto"
            //datesSet={updateTitle} // Updates the title whenever the visible range changes
            />
          </CalendarRoot>
        </div>
      </div>
    </>
  );
}

// Setting default values for the props of Calendar
Calendar.defaultProps = {
  header: {
    title: "",
    date: "",
  },
};

// Typechecking props for the Calendar
Calendar.propTypes = {
  header: PropTypes.shape({
    title: PropTypes.string,
    date: PropTypes.string,
  }),
};

export default Calendar;
