import { useState, useRef, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import callFetch from "../helpers/callFetch";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import { NavLink } from "react-router-dom";

const Search = () => {
    const { t } = useTranslation();
    const targetRef = useRef(null);

    const [searchKeyMobile, setSearchKeyMobile] = useState(0)
    const [query, setQuery] = useState('');
    const [showCloseMobile, setShowCloseMobile] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingMobile, setLoadingMobile] = useState(false);
    const [menu, setMenu] = useState(false);

    const [showClose, setShowClose] = useState(false);
    const [menuMobile, setMenuMobile] = useState(false);
    const [showNotFound, setShowNotFound] = useState(false);

    const [searchData, setSearchData] = useState([])
    const [searchDataMobile, setSearchDataMobile] = useState([])
    const [searchKey, setSearchKey] = useState(0)

    const [results, setResults] = useState({ projects: [], quations: [], orders: [], employees: [], invoices: [] });
    const [resultsMobile, setResultsMobile] = useState({ projects: [], quations: [], orders: [], employees: [], invoices: [] });

    const categories = [
        { key: "projects", title: "Projects", icon: "fa-briefcase" },
        { key: "quations", title: "Quations", icon: "fa-bag-shopping" },
        { key: "orders", title: "Orders", icon: "fa-bag-shopping" },
        { key: "employees", title: "Employees", icon: "fa-users" },
        { key: "invoices", title: "Invoices", icon: "fa-coins" }
    ];

    const handleClearSearch = () => {
        setShowNotFound(false);
        setSearchKey("");
        setShowClose(false);
        setSearchDataMobile([]);
        setMenu(false);
    };

    const addToRecentSearch = (id, realText, type, icon, url) => {
        var formData = new FormData();
        formData.id = id;
        formData.real_text = realText;
        formData.type = type;
        formData.icon = icon;
        formData.text = searchKey;
        callFetch("post-recent-search", "POST", formData).then((res) => {
            console.log(res);
            window.location.href = '/' + url + '/' + id;
        });
    }

    useEffect(() => {
        setShowNotFound(false);
        if (searchKey.length > 0 || searchKeyMobile.length > 0) {
            const searchTerm = searchKey.length > 0 ? searchKey : searchKeyMobile;
            setQuery(searchTerm);
            console.log(searchTerm.trim());
            if (searchTerm.trim().length > 2) {
                setShowClose(false);
                setShowCloseMobile(false);
                setLoading(true);
                setShowNotFound(false);
                setLoadingMobile(true);
                try {
                    callFetch('global/search/' + searchTerm, "GET", []).then((res) => {
                        if (res.status !== 401) {
                            setResults(res.allData);
                            setResultsMobile(res.allData);

                            setLoading(false);
                            setLoadingMobile(false);

                            setShowClose(true);
                            setShowCloseMobile(true);

                            setSearchData(res.data.data);
                            setSearchDataMobile(res.data.data);
                            if (res.data.data.length == 0) {
                                console.log('truenotfound')
                                setShowNotFound(true);
                                setMenu(false);
                                setMenuMobile(false);
                            } else {
                                setMenu(true);
                                setMenuMobile(true);
                            }

                        } else {
                            setSearchData([]);
                            setSearchDataMobile([]);
                            setMenu(false);
                            setShowNotFound(false)
                            setMenuMobile(false);
                            setShowNotFound(false);
                        }

                    })
                } catch (error) {
                    console.error('Error fetching search results:', error);
                }
            } else {
                setShowNotFound(false);
                setMenu(false);
                setMenuMobile(false);
                setResults({ projects: [], quations: [], orders: [], employees: [], invoices: [] });
            }
        } else {
            setSearchKey('')
            setSearchKeyMobile('')
            setMenu(false)
            setShowClose(false)
            setShowCloseMobile(false)
            setMenuMobile(false);
            setSearchData([])
        }
    }, [searchKey, searchKeyMobile])

    const SearchResultItem = ({ item, type, icon }) => (
        <SoftBox
            display="flex"
            justifyContent="flex-start"
            alignItems="top"
            gap={1}
            borderRadius="8px"
            sx={{
                backgroundColor: '#F8F9FA',
                padding: '8px',
                height: '60px',
                '&:hover': {
                    backgroundColor: 'var(--brand-light)',
                    color: 'var(--gray-900)',
                },
            }}
            mb={1}
        >
            <SoftBox display="flex" alignItems="center">
                <img
                    style={{
                        border: '0.125rem solid #E7EBF3',
                        height: '40px',
                        width: '40px'
                    }}
                    class="rounded-full"
                    src={'/assets/img/placeholder.png'}
                    alt="Jese image"
                />
            </SoftBox>
            <div
                className="flex-fill d-flex flex-column"
                style={{
                    width: '214px'
                }}
            >
                <p className="text-md text-primary m-0">
                    {type === 'projects' ? (
                        <>{item?.project_id} - {item?.vorname + ' ' + item?.name + ' - '} {item?.telefonnummer && item?.telefonnummer != '' ? item?.telefonnummer : <span style={{ fontStyle: 'italic', fontSize: '12px' }}>{t('Keine Telefonnumer')}</span>}</>
                    ) : type === 'employees' ? (
                        <>{item?.employee_id}</>
                    ) : type === 'invoices' ? (
                        <>{item?.invoice_id}</>
                    ) : item?.id}
                </p>
                <p className="text-link text-secondary m-0 text-overflow-ellipsis">
                    {type === 'projects' ? (
                        <>{item?.salse_person?.name} {item?.street + ', ' + item?.plz + ', ' + item?.ort}</>
                    ) : item?.user?.name}
                </p>
            </div>
        </SoftBox>
    );

    return (
        <>
            <a
                className="d-flex align-items-center"
                data-bs-toggle="dropdown"
                aria-expanded="false"
            >
                <i
                    className="ph ph-magnifying-glass"
                    style={{
                        marginRight: -25,
                        zIndex: 1,
                        color: '#4D586E',
                        fontSize: '20px'
                    }}
                ></i>
                <input
                    autoComplete="off"
                    id="searchInput"
                    className="form-control header-search-field"
                    placeholder={t("Search...")}
                    value={searchKey || ""}
                    onChange={(e) => setSearchKey(e.target.value)}
                />
            </a>

            {/*
                {showClose && <i className="fa fa-close search-input-clear" style={{ color: "#208dfc" }} onClick={handleClearSearch}></i>}
                {loading && <i className="fa fa-circle-o-notch fa-spin search-input-clear" style={{ color: "#208dfc" }}></i>}
                */}

            <div className="dropdown-menu res-dropdown-menu dropdown-menu-end">
                {menu ? (
                    <>
                        <div className="card">
                            <div className="card-body">
                                {categories.map(({ key, title, icon }) => ((results?.[key]?.data?.length > 0 || results?.[key]?.length > 0) && (
                                    <>
                                        <h3 className="title text-lg border-bottom mb-3 pb-2">{title}</h3>

                                        {(results?.[key]?.data ? results?.[key]?.data : results?.[key]).map((item) => (
                                            <>
                                                <NavLink
                                                    to={
                                                        `/${key}/${(
                                                            key === 'projects' ? item?.project_id
                                                                : key === 'quations' ? item?.quation_id
                                                                    : key === 'orders' ? item?.order_id
                                                                        : key === 'employees' ? item?.employee_id
                                                                            : key === 'invoices' ? item?.invoice_id
                                                                                : '')}`
                                                    }
                                                >
                                                    <SearchResultItem key={item.id} item={item} type={key} icon={icon} />
                                                </NavLink>
                                            </>
                                        ))}

                                    </>
                                )))}
                            </div>
                        </div>
                        {/*
                    <div className="card position-absolute p-0 m-0 me-10 top-100 w-100 mt-1 border-radius-475rem" style={{ minHeight: "400px" }}>
                        <div className="card-body m-0 search-result-box">
                            <div className="wrapper-search">
                                {categories.map(({ key, title, icon }) => (
                                    results[key]?.length > 0 && (
                                        <div key={key}>
                                            <p className="search-category-title">{title}</p>
                                            <ul>
                                                {results[key].map((item) => (
                                                    <SearchResultItem key={item.id} item={item} type={key} icon={icon} />
                                                ))}
                                            </ul>
                                        </div>
                                    )))}
                            </div>
                        </div>
                    </div>
                    */}
                    </>
                ) : showNotFound ? (
                    <div className="card">
                        <div className="card-body d-flex align-items-center justify-content-center">
                            <div className="text-center">
                                <img
                                    src="/assets/icons/NotePad.svg"
                                    style={{
                                        width: '48px',
                                        height: '52px',
                                        filter: 'brightness(0) saturate(100%) invert(35%) sepia(15%) saturate(765%) hue-rotate(182deg) brightness(91%) contrast(89%)'
                                    }}
                                    className="mb-2"
                                />
                                <p className="text-secondary text-md m-0 mb-2">{t('No result found!')}</p>
                            </div>
                        </div>
                    </div>
                ) : null}
            </div >
        </>
    );
};

export default Search;
