/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useMemo } from "react";

// porp-types is a library for typechecking of props
import PropTypes from "prop-types";
import SoftBadge from "components/SoftBadge";
import SoftBadgeDot from "components/SoftBadgeDot";
// react-chartjs-2 components
import { Pie } from "react-chartjs-2";
import Grid from "@mui/material/Grid";

// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import { useTranslation } from 'react-i18next';
// PieChart configurations
import configs from "examples/Charts/PieChart/configs";

function PieChart({ title, description, height, chart }) {
  const { data, options } = configs(chart.labels || [], chart.datasets || {});
  const { t } = useTranslation();
  const renderChart = (
    <div style={{padding: '16px 0px 8px 16px', boxShadow: 'none'}}>
      {title || description ? (
        <>
          {title && (
            <SoftBox>
               <SoftTypography variant="caption" className="font-size-20 text-primary font-weight-600">{title}</SoftTypography>
            </SoftBox>
          )}
          <SoftBox>
            <SoftTypography component="div" variant="button" fontWeight="regular" color="text">
              {description}
            </SoftTypography>
          </SoftBox>
        </>
      ) : null}
      {useMemo(
        () => (
          <div>
             <Grid container alignItems="center">
                  <Grid item xs={6}>
                    <SoftBox height={height}>
                      <Pie data={data} options={options} />
                    </SoftBox>
                  </Grid>
                  <Grid item xs={6}>
                    <SoftBox px={1}>
                      <SoftBox mb={0.5}>
                          <SoftBadgeDot color="error" size="sm" badgeContent={t('Überfällig Reklamationen')} />
                      </SoftBox> 
                      <SoftBox mb={0.5}>
                          <SoftBadgeDot color="warning" size="sm" badgeContent={t('Offene Reklamationen')} />
                      </SoftBox>
                      <SoftBox mb={0.5}>
                          <SoftBadgeDot color="info" size="sm" badgeContent={t('Heutige Reklamationen')} />
                      </SoftBox>
                    </SoftBox>
                  </Grid>
              </Grid> 
          </div> 
        ),
        [chart, height]
      )}
    </div>
  );

  return title || description ? <Card className="border-radius-8 new-box-shadow border-none">{renderChart}</Card> : renderChart;
}

// Setting default values for the props of PieChart
PieChart.defaultProps = {
  title: "",
  description: "",
  height: "18.125rem",
};

// Typechecking props for the PieChart
PieChart.propTypes = {
  title: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  chart: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.array, PropTypes.object])).isRequired,
};

export default PieChart;
