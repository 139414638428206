import { React, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { callFetch } from "helpers/callFetch";
import { useTranslation } from 'react-i18next';
import deleteAlert from "helpers/deleteAlert";

function TypeModal(props) {
    const { t } = useTranslation();
    const [speicherTypes, setSpeicherTypes] = useState([]);
    const [saving, setSaving] = useState(false);
    const [refresh, setRefresh] = useState(0);
    const {
        register,
        handleSubmit,
        setError,
        formState: { errors },
        reset
    } = useForm();

    let serial = 1;

    useEffect(() => {
        callFetch("speicherTypes", "GET", []).then((res) => {
            setSpeicherTypes(res.data);
        });
    }, [refresh]);

    const onSubmit = (formData) => {
        setSaving(true);
        callFetch("speicherTypes", "POST", formData, setError).then((res) => {
            setSaving(false);
            if (!res.ok) return;
            reset();
            setRefresh(refresh + 1);
            props.refreshParent();
        });
    };

    function doUpdate(e, id) {
        let name = e.target.parentNode.parentNode.parentNode.getElementsByClassName('speicher-type-input')[0].value;
        callFetch("speicherTypes/" + id, "POST", { name: name, _method: 'PUT' }).then((res) => {
            if (!res.ok) return;
            setRefresh(refresh + 1);
            props.refreshParent();
        });
    }

    return (
        <form className={`needs-validation ${Object.keys(errors).length ? "was-validated" : ""}`} onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
            <div className="modal fade" id="speicherTypeModal" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-md modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="speicherTypeModalLabel">{t('Speicher Type')}</h5>
                            <button type="button" className="btn-close text-dark" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="text-end mb-3">
                                <a class="btn-link btn-plus-icon btn-icon-primary text-end" data-bs-toggle="modal" data-bs-target="#speicherTypeModal">{t('Typ Hinzufügen')}</a>
                            </div>

                            <div class="table-responsive border border-radius">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th style={{ width: '48px' }}>{t('#')}</th>
                                            <th>{t('Beschreibung')}</th>
                                            <th style={{ width: '44px' }}></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {speicherTypes && speicherTypes.map((speicherType) => (
                                            <tr key={speicherType.id}>
                                                <td>
                                                    {serial++}
                                                </td>
                                                <td>
                                                    {/*<input className="form-control speicher-type-input" defaultValue={speicherType.name} placeholder={t('eg. Team Lead')} />*/}
                                                    {speicherType.name}
                                                </td>
                                                <td>
                                                    {/*
                                                    <button
                                                        type="button"
                                                        className="btn btn-sm btn-secondary"
                                                        onClick={(e) => doUpdate(e, speicherType.id)}
                                                    >{t('Update')}</button>
                                                    */}

                                                    <i
                                                        className="ph ph-trash-simple text-status-error-primary text-xl cursor-pointer"
                                                        onClick={(e) =>
                                                            deleteAlert(e, 'speicherTypes', speicherType.id, t).then(res => {
                                                                setRefresh(refresh + 1);
                                                                props.refreshParent();
                                                            })}
                                                    ></i>
                                                </td>
                                            </tr>
                                        ))}

                                        <tr>
                                            <td colSpan={3}>
                                                <div className="d-flex align-items-center justify-content-end gap-2">
                                                    <input
                                                        type="text"
                                                        className="form-control border-0"
                                                        placeholder={t('Name')}
                                                        {...register("name", {
                                                            required: true,
                                                        })}
                                                        required
                                                    />
                                                    
                                                    <button type="button" className="btn btn-sm btn-secondary m-0">{t('Close')}</button>

                                                    {saving ? (
                                                        <button type="button" className="btn btn-sm btn-disabled m-0" disabled>
                                                            {t('Saving ...')}
                                                        </button>
                                                    ) : (
                                                        <button type="submit" className="btn btn-sm btn-primary m-0">
                                                            {t('Save')}
                                                        </button>
                                                    )}
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div className="modal-footer d-flex gap-3">
                            <button type="button" className="btn btn-secondary m-0 px-4" data-bs-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    );
}

export default TypeModal;
