import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from 'react-i18next';
import { Navigate, useParams } from "react-router-dom";
import { useCallFetchv2 } from 'helpers/callFetchv2';
import Cookies from 'js-cookie';
function SmtpEdit(props) {
    let params = useParams();
    const { t } = useTranslation();
    const callFetch = useCallFetchv2();
    const [resData, setResData] = useState(null);
    const [saving, setSaving] = useState(false);
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [refresh, setRefresh] = useState(0);
    const {
        register,
        handleSubmit,
        setError,
        setValue,
        formState: { errors },
    } = useForm();

    useEffect(() => {
        callFetch("smtp-configurations/" + props.id + "/edit", "GET", [], setError).then((res) => {
            setResData(res.data);
            for (let [key, value] of Object.entries(res.data)) {
                setValue(key, (value == null || value == 'null' ? "" : value));
            }
        });
    }, [props.id]);

    const onSubmit = (formData) => {
        setSaving(true);
        callFetch("smtp-configurations/" + props.id, "POST", formData, setError).then((res) => {
            setSaving(false);
            setRefresh(refresh + 1);

            setTimeout(() => {
                props?.setReIndex(refresh + Math.floor(Math.random() * 100));
            }, 3000)
            if (!res.ok) return;
            document.getElementById('editSMTPModalClose').click();
            //setSubmitSuccess(true);
        });
    };

    return submitSuccess ? <Navigate to='/settings/smtp' /> :
        <form className={`needs-validation ${Object.keys(errors).length ? "was-validated" : ""}`} onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
            <input type="hidden" defaultValue="PUT" {...register("_method")} />
            <div class="modal fade right-side" id="exampleModalEdit" tabindex="-1" aria-labelledby="exampleModalLabel1" aria-hidden="true">
                <div class="modal-dialog modal-dialog-slide-right">
                    <div class="modal-content">
                        <div className='modal-header'>
                            <p className='title mb-0'>{t('Edit SMTP')}</p>
                            <button
                                id="editSMTPModalClose"
                                type="button"
                                class="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ></button>
                        </div>

                        <div className="modal-body tb-ac-cs">
                            <div className="row">
                                <div className="col-12">
                                    <div className="form-group">
                                        <label>
                                            {t('Sender Name')} <b>*</b>
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder={t('Sender Name')}
                                            {...register("sender_name", {
                                                required: true,
                                            })}
                                            required
                                        />
                                        <div className="invalid-feedback">{errors.sender_name && errors.sender_name.message}</div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-group">
                                        <label>
                                            {t('Sender Email')} <b>*</b>
                                        </label>
                                        <input
                                            type="email"
                                            className="form-control"
                                            placeholder={t('Sender Email')}
                                            {...register("sender_email", {
                                                required: true,
                                            })}
                                            required
                                        />
                                        <div className="invalid-feedback">{errors.sender_email && errors.sender_email.message}</div>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>
                                            {t('Host')} <b>*</b>
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder={t('Host')}
                                            {...register("smtp_host", {
                                                required: true,
                                            })}
                                            required
                                        />
                                        <div className="invalid-feedback">{errors.smtp_host && errors.smtp_host.message}</div>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>
                                            {t('Port')} <b>*</b>
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control "
                                            placeholder={t('Port')}
                                            {...register("smtp_port", {
                                                required: true,
                                            })}
                                            required
                                        />
                                        <div className="invalid-feedback">{errors.smtp_port && errors.smtp_port.message}</div>
                                    </div>
                                </div>

                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label>
                                            {t('User')} <b>*</b>
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder={t('User')}
                                            {...register("smtp_user", {
                                                required: true,
                                            })}
                                            required
                                        />
                                        <div className="invalid-feedback">{errors.smtp_user && errors.smtp_user.message}</div>
                                    </div>
                                </div>

                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label>
                                            {t('Password')} <b>*</b>
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder={t('Password')}
                                            {...register("smtp_password", {
                                                required: true,
                                            })}
                                            required
                                        />
                                        <div className="invalid-feedback">{errors.smtp_password && errors.smtp_password.message}</div>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>
                                            {t('Type')} <b>*</b>
                                        </label>
                                        <select
                                            className="form-control"
                                            {...register("smtp_type", {
                                                required: true,
                                            })}
                                            required
                                        >
                                            <option value="default">Default</option>
                                            <option value="ssl">SSL</option>
                                            <option value="tls">TLS</option>
                                        </select>
                                        <div className="invalid-feedback">{errors.smtp_type && errors.smtp_type.message}</div>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>
                                            {t('Status')} <b>*</b>
                                        </label>
                                        <select
                                            className="form-control"
                                            {...register("status", {
                                                required: true,
                                            })}
                                            required
                                        >
                                            <option value="1">{t('Active')}</option>
                                            <option value="0">{t('Inactive')}</option>
                                        </select>
                                        <div className="invalid-feedback">{errors.status && errors.status.message}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer d-flex align-items-center w-100 gap-3">
                            <button type="button" class="btn btn-secondary m-0 flex-grow-1" data-bs-dismiss="modal" aria-label="Close">Close</button>

                            {!saving ? (
                                <button type="submit" className="btn btn-primary m-0 flex-grow-1">
                                    {t('Save')}
                                </button>
                            ) : (
                                <button type="submit" className="btn btn-disabled m-0 flex-grow-1" disabled>
                                    {t('Saving')} ...
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </form>;
}

export default SmtpEdit;
