import { React, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Navigate, useLocation, useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import DepartmentManageModal from "../../DepartmentManageModal";
import DesignationManageModal from "../../DesignationManageModal";
import ImagePopup from "../../ImagePopup";
import callFetch from "helpers/callFetch";
import SoftSnackbar from "components/SoftSnackbar";
import { NavLink } from 'react-router-dom'

function MonthlySalary({ title, employee, refreshData }) {
    const params = useParams();
    const { t } = useTranslation();
    const [designations, setDesignations] = useState([]);
    const [roles, setRoles] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [saving, setSaving] = useState(false);
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [refresh, setRefresh] = useState(0);
    const [selectedImage, setSelectedImage] = useState(null);
    const [isModalVisible, setModalVisible] = useState(false);
    const [files, setFile] = useState();
    const [image, setImage] = useState();
    const [provision, setProvision] = useState('')
    const [receivedError, setReceivedError] = useState(null);
    const {
        register,
        handleSubmit,
        setError,
        setValue,
        formState: { errors },
    } = useForm();

    const handleBlur = () => {
        if (provision !== '' && !provision.endsWith('%')) {
            setValue(provision + '%');
        }
    };

    useEffect(() => {
        if (employee) {
            setValue('bruttogehalt_money', employee?.bruttogehalt_money)
            setValue('provision', employee?.provision)
        }
    }, [refresh, employee]);

    const [successSB, setSuccessSB] = useState(false);
    const openSuccessSB = () => setSuccessSB(true);
    const closeSuccessSB = () => setSuccessSB(false);
    const renderSuccessSB = (
        <SoftSnackbar
            color="success"
            icon="check"
            title="Success"
            content="Monthly Salary Successfully Updated"
            dateTime="Just Now"
            open={successSB}
            onClose={closeSuccessSB}
            close={closeSuccessSB}
            bgWhite
        />
    );


    const onSubmit = (formData) => {
        formData.employee_identity_number = params?.id
        formData.name = employee?.name
        formData.email = employee?.email
        setSaving(true);
        callFetch("employees/" + params?.id, "POST", formData, setError).then((res) => {
            setSaving(false);
            if (receivedError == null && res.message === 'success' && params?.id) {
                openSuccessSB();
            }
            refreshData();
            if (!res.ok) return;
            setSubmitSuccess(true);
        });
    };

    return submitSuccess && params?.id ? <Navigate to={'/employees/' + params?.id + '?step=2'} /> : (
        <>
            <form className={`needs-validation ${Object.keys(errors).length ? "was-validated" : ""}`} onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
                <input type="hidden" defaultValue="PUT" {...register("_method")} />

                <div className="container">
                    <div className="card">
                        <div className="card-header">
                            <h3 className='title'>{t("Monatiches Gehalt")}</h3>
                        </div>
                        <div className="card-body pb-3">
                            <div className="row monthly-salary-input">
                                <div className="col-md-6">
                                    <label>
                                        {t('Monatiches Bruttogehalt in EUR')} *
                                    </label>
                                    <div className="input-group">
                                        <i class="ph ph-currency-eur icon text-primary"></i>
                                        <input
                                            type="number"
                                            className="form-control input-group-input"
                                            placeholder={t('Bruttogehalt')}
                                            {...register("bruttogehalt_money", {
                                                required: true,
                                            })}
                                            required
                                            aria-label="Input group example"
                                            aria-describedby="btnGroupAddon"
                                        />
                                    </div>
                                    <div className="invalid-feedback">{errors.monthly_gross_salary && errors.monthly_gross_salary.message}</div>
                                </div>
                                <div className="col-md-6">
                                    <label>
                                        {t('Provision in %')} *
                                    </label>
                                    <div className="input-group">
                                        <i class="ph ph-percent icon text-primary"></i>
                                        <input
                                            type="number"
                                            className="form-control input-group-input"
                                            placeholder={t('provision')}
                                            {...register("provision", {
                                                required: true,
                                            })}
                                            required
                                            aria-label="Input group example"
                                            aria-describedby="btnGroupAddon"
                                        />
                                    </div>
                                    <div className="invalid-feedback">{errors.provision && errors.provision.message}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="card fixed-bottom mb-3 fixed-bottom-width-available" >
                    <div className="card-footer d-flex align-items-center justify-content-end gap-2">

                        <NavLink to={`/employees/${params?.id}?step=0`} className="btn btn-secondary m-0 d-flex align-items-center justify-content-between gap-2">
                            <i className='ph ph-caret-left'></i>
                            {t('Back')}
                        </NavLink>

                        {saving ? (
                            <button type="button" className="btn btn-disabled m-0" disabled>
                                {t('Saving ...')}
                            </button>
                        ) : (
                            <button type="submit" className="btn btn-primary m-0 d-flex align-items-center justify-content-between gap-2">
                                {t("Speichern und fortfahren")}
                                <i className='ph ph-caret-right'></i>
                            </button>
                        )}
                    </div>
                </div>
            </form>
            {renderSuccessSB}
        </>
    )
}

export default MonthlySalary;
