import { Divider } from '@mui/material';
import SoftTypography from 'components/SoftTypography';
import callFetch from 'helpers/callFetch';
import React, { useEffect, useState } from 'react'
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import SoftSnackbar from "components/SoftSnackbar";
import RoutenCard from './RoutenCard';
import DataTable from 'react-data-table-component';
import Tooltip from "@mui/material/Tooltip";
import dateFormat, { masks } from "dateformat";
import SoftSelect from "components/SoftSelect";
import SoftDatePicker from "components/SoftDatePicker";

const Index = (props) => {
    const { t } = useTranslation();
    const [saving, setSaving] = useState(false);
    const [refresh, setRefresh] = useState(0);
    const [refreshValidation, setRefreshValidation] = useState(0);
    const [employees, setEmployees] = useState([]);
    const [allreadyBookedEmployees, setAllreadyBookedEmployees] = useState([]);


    const [showRequiredFieldCheck, setShowRequiredFieldCheck] = useState(false);
    const [requriedErrors, setRequriedErrors] = useState([]);

    const [routeNumber, setRouteNumber] = useState(0);
    const [projectIds, setProjectIds] = useState([]);
    const [hours, setHours] = useState([]);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [absenceDate, setAbsenceDate] = useState('');

    // multiple datePicker
    const tomorrow = new Date()
    tomorrow.setDate(tomorrow.getDate() + 1)
    const [routeCard, setRouteCard] = useState([])
    const [schedulesErrors, setSchedulesErrors] = useState([]);

    const [messageSb, setMessageSB] = useState(false);
    const [messageSbColor, setMessageSbColor] = useState('success');
    const [messageSbTitle, setMessageSbTitle] = useState('Something wrong!');

    const [expandedRows, setExpandedRows] = useState({});
    const timeOptions = [
        { value: '08:00', label: '08:00' },
        { value: '10:00', label: '10:00' },
        { value: '12:00', label: '12:00' },
        { value: '14:00', label: '14:00' },
        { value: '16:00', label: '16:00' },
        { value: '18:00', label: '18:00' },
        { value: '20:00', label: '20:00' },
    ];

    const {
        register,
        handleSubmit,
        getValues,
        setValue,
        control,
        setError,
        formState: { errors },
        reset
    } = useForm();

    const renderMessageSB = (
        <SoftSnackbar
            color={messageSbColor}
            title={t(messageSbTitle)}
            //content={t("Notification Description")}
            open={messageSb}
            onClose={() => setMessageSB(false)}
            close={() => setMessageSB(false)}
        />
    );

    const toggleRowExpand = (rowId) => {
        setExpandedRows(prev => ({
            ...prev,
            [rowId]: !prev[rowId], // Toggle row expansion
        }));
    };

    useEffect(() => {
        console.log('props?.projectIds', props?.projectIds)
        if (!props?.projectIds) return;
        setRouteNumber(props?.routeNumber ? parseInt(props?.routeNumber) : 0);
        setEmployees(props?.employees ? props?.employees : []);
        setStartDate(props?.startDate ? props?.startDate : '');
        setEndDate(props?.endDate ? props?.endDate : '');
        setAbsenceDate(props?.absenceDate ? props?.absenceDate : '');

        var selectedprojectIds = [];
        var hours = [];
        for (let [selectedrow_key, selectedrow_value] of Object.entries(props?.projectIds)) {
            if (selectedrow_value && selectedrow_key) {
                selectedprojectIds.push(selectedrow_key + '-' + props?.plan_form[selectedrow_key] + '-' + props?.allData?.find(it => it.id === parseInt(selectedrow_key)).plan_hours);
                hours[selectedrow_key] = props?.allData?.find(it => it.id === parseInt(selectedrow_key)).plan_hours;
            }
        }
        setProjectIds(selectedprojectIds);
        setHours(hours);

    }, [props?.startDate, props?.endDate, props?.absenceDate, props?.routeNumber, props?.projectIds, props?.allData, props?.plan_form, props?.employees]);

    useEffect(() => {
        if (!projectIds.length) return;

        var formData = {};
        formData.projectIds = JSON.stringify(projectIds);
        //formData.routeNumber = JSON.stringify(routeNumber);
        formData.routeNumber = projectIds.length || 0;
        formData.planType = props?.planType;
        formData.startDate = startDate;
        formData.endDate = endDate;
        formData.absenceDate = absenceDate;

        callFetch("routeplan", "POST", formData, setError).then((res) => {
            setSaving(false);
            if (!res.ok) return;
            setRouteCard(res.data);
            //setSchedules(res.schedules);
            setAllreadyBookedEmployees(res?.allreadyBookedEmployees)
            setRefresh(refresh + 1);
        });
    }, [projectIds, startDate, endDate, absenceDate]);

    const handleDataChange = async (value, index, key) => {
        setRouteCard((prev) =>
            prev.map((route, i) =>
                i === index
                    ? {
                        ...route,
                        data: route.data.map((item) => ({
                            ...item,
                            [key]: value,
                        })),
                    }
                    : route
            )
        );

        setRefreshValidation(refreshValidation + 1);
    };

    useEffect(() => {
        validateSchedule()
    }, [refreshValidation]);

    const validateSchedule = async (requiredFieldCheck = false) => {

        console.log('requiredFieldCheck', requiredFieldCheck)

        if (requiredFieldCheck === true || showRequiredFieldCheck === true) {
            const formErrors = [];
            for (let [routeCard_key, routeCardvalue] of Object.entries(routeCard)) {
                for (let [itemKey, itemValue] of Object.entries(routeCardvalue?.data)) {
                    if (itemValue?.id) {
                        // Check for required fields (startDate, startTime, endDate, endTime)
                        if (
                            !itemValue?.startDate ||
                            !itemValue?.startTime ||
                            !itemValue?.endDate ||
                            !itemValue?.endTime ||
                            !getValues('miltarbeiter[' + routeCard_key + ']')
                        ) {
                            // Push the invalid field into the errors array
                            formErrors.push({
                                itemId: itemValue?.id,
                                missingFields: {
                                    startDate: !itemValue?.startDate,
                                    startTime: !itemValue?.startTime,
                                    endDate: !itemValue?.endDate,
                                    endTime: !itemValue?.endTime,
                                    workers: !getValues('miltarbeiter[' + routeCard_key + ']')
                                }
                            });
                        }
                    }
                }
            }

            setRequriedErrors(formErrors);
            setShowRequiredFieldCheck(true);

            if (formErrors.length > 0) {
                return false;
            };
        }


        var projectIds = [];
        var dates = [];
        var times = [];
        var endDates = [];
        var endTimes = [];
        var workers = [];
        for (let [routeCard_key, routeCardvalue] of Object.entries(routeCard)) {
            for (let [itemKey, itemValue] of Object.entries(routeCardvalue?.data)) {
                if (itemValue?.id) {
                    if (itemValue?.startDate && itemValue?.startTime && itemValue?.endDate && itemValue?.endTime) {
                        projectIds.push(itemValue?.id);
                        dates.push(itemValue?.startDate);
                        times.push(itemValue?.startTime);
                        endDates.push(itemValue?.endDate);
                        endTimes.push(itemValue?.endTime);
                        workers.push(getValues('miltarbeiter[' + routeCard_key + ']'));
                    } else {
                        return false;
                    }
                }
            }
        }

        let schedule = [];
        let errors = [];

        for (let i = 0; i < workers.length; i++) {
            schedule.push({
                project_id: projectIds[i],
                miltarbeiter: workers[i],
                start: new Date(`${dates[i]}T${times[i]}`),
                end: new Date(`${endDates[i]}T${endTimes[i]}`),
            });
        }

        // Get the minimum start date and maximum end date
        let minStartDate = schedule.reduce((min, s) => (s.start < min ? s.start : min), schedule[0]?.start);
        let maxEndDate = schedule.reduce((max, s) => (s.end > max ? s.end : max), schedule[0]?.end);

        if (!minStartDate || !maxEndDate) {
            return false
        }

        maxEndDate = new Date(maxEndDate); // Ensure it's a separate instance
        maxEndDate.setHours(23, 59, 59, 999);

        let bookedSchedules = [];
        await callFetch("unavailable-time-slots", "POST", {
            startDate: minStartDate.toISOString(),
            endDate: maxEndDate.toISOString(),
        }).then((res) => {
            console.log('calanderdata')

            bookedSchedules = res?.data || []

        });

        // console.log("Min Start Date:", minStartDate);
        // console.log("Max End Date:", maxEndDate);
        // console.log("bookedSchedules:", bookedSchedules);

        // Sort by start time
        schedule.sort((a, b) => a.start - b.start);

        let workerSchedules = {};

        for (let i = 0; i < schedule.length; i++) {
            const { miltarbeiter, start, end, project_id } = schedule[i];

            if (!workerSchedules[miltarbeiter]) {
                workerSchedules[miltarbeiter] = [];
            }

            // Check for conflicts with existing booked schedules
            for (let booked of bookedSchedules) {
                let bookedStart = new Date(booked.start);
                let bookedEnd = new Date(booked.end);

                if (parseInt(booked.miltarbeiter) === parseInt(miltarbeiter)) {
                    console.log('start', start)
                    console.log('end', end)

                    if (
                        (start >= bookedStart && start < bookedEnd) ||  // New start overlaps
                        (end > bookedStart && end <= bookedEnd) ||  // New end overlaps
                        (start <= bookedStart && end >= bookedEnd)  // New fully covers booked
                    ) {
                        let errorMsg = `miltarbeiteris already assigned or absence`;
                        //errors.push(`Conflict for worker ${miltarbeiter} with existing booking ID: ${booked.id}`);

                        errors.push({
                            project_id,
                            error: errorMsg
                        });
                        // Update state with all errors
                        setSchedulesErrors(errors);

                        setMessageSbTitle(errorMsg);
                        setMessageSbColor('error');
                        setMessageSB(true);

                        return false;
                    }
                }
            }

            // Check for conflicts within new schedules
            for (let j = 0; j < workerSchedules[miltarbeiter].length; j++) {
                let existing = workerSchedules[miltarbeiter][j];

                if (
                    (start >= existing.start && start < existing.end) || // Overlaps start time
                    (end > existing.start && end <= existing.end) || // Overlaps end time
                    (start <= existing.start && end >= existing.end) // Completely within existing time
                ) {
                    //let errorMsg = `miltarbeiteris already assigned to project ${existing.project_id} from ${existing.start.toISOString()} to ${existing.end.toISOString()}`;
                    let errorMsg = `miltarbeiteris already assigned to project ${existing.project_id}`;

                    errors.push({
                        project_id,
                        error: errorMsg
                    });
                    // Update state with all errors
                    setSchedulesErrors(errors);

                    setMessageSbTitle(errorMsg);
                    setMessageSbColor('error');
                    setMessageSB(true);

                    return errors.length === 0;
                }
            }

            // No conflicts, add to schedule
            workerSchedules[miltarbeiter].push({ start, end, project_id });
        }

        // Update state with all errors
        setSchedulesErrors(errors);
        console.log(errors)
        return errors.length === 0;
    };


    const onSubmit = async (data) => {
        setSaving(true);
        props?.setPlanSaving(true);

        const validateResult = await validateSchedule(true);

        //if (validationSuccess()) {
        if (validateResult) {
            var selectedids = [];
            var date = [];
            var time = [];
            var end_date = [];
            var end_time = [];
            var miltarbeiter = [];
            var dachmontag_elektromontage = [];
            var plan_form = [];
            for (let [routeCard_key, routeCardvalue] of Object.entries(routeCard)) {
                for (let [itemKey, itemValue] of Object.entries(routeCardvalue?.data)) {
                    if (itemValue?.id) {

                        if (itemValue?.startDate && itemValue?.startTime && itemValue?.endDate && itemValue?.endTime) {
                            selectedids.push(itemValue?.id);
                            //date.push(schedules[itemKey].date);
                            //time.push(schedules[itemKey].start);
                            date.push(itemValue?.startDate);
                            time.push(itemValue?.startTime);
                            end_date.push(itemValue?.endDate);
                            end_time.push(itemValue?.endTime);
                            miltarbeiter.push(getValues('miltarbeiter[' + routeCard_key + ']'));
                            dachmontag_elektromontage.push(itemValue?.icon == "DC" ? "Dachmontage" : "Elektromontage");
                            plan_form.push(itemValue?.plan_form);
                        } else {

                            setMessageSbTitle('Die Anzahl der Routen reicht für den ausgewählten Zeitraum nicht aus.');
                            setMessageSbColor('error');
                            setMessageSB(true);

                            setSaving(false);
                            props?.setPlanSaving(false);
                            return;
                        }
                    }
                }
            }

            var formData = {};
            formData.project_id = JSON.stringify(selectedids);
            formData.date = JSON.stringify(date);
            formData.time = JSON.stringify(time);
            formData.end_date = JSON.stringify(end_date);
            formData.end_time = JSON.stringify(end_time);
            formData.miltarbeiter = JSON.stringify(miltarbeiter);
            formData.dachmontag_elektromontage = JSON.stringify(dachmontag_elektromontage);
            formData.plan_form = JSON.stringify(plan_form);


            callFetch("orders-to-plan", "POST", formData, setError).then((res) => {
                setMessageSbTitle('Plan added successfully!');
                setMessageSbColor('success');
                setMessageSB(true);

                setTimeout(() => {
                    setRefresh(refresh + 1);
                    props.setRefresh((Math.random() * 1000) + 1000);
                    props.refresh((Math.random() * 1000) + 1000);
                    props.setShowRoutePlanScreen(false);
                    setSaving(false);
                    props?.setPlanSaving(false);

                    reset({});
                }, 3000)
            });
        } else {
            console.log('validation error')
            //setMessageSbTitle('Some plan schedule already booked');
            //setMessageSbColor('error');
            //setMessageSB(true);
            setSaving(false);
            props?.setPlanSaving(false);
        }
    }

    return (
        <>
            <form style={{ minHeight: '50vh', maxHeight: '620px', overflowY: 'auto', overflowX: 'hidden' }} className={`needs-validation ${Object.keys(errors).length ? "was-validated" : ""}`} onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
                <div className="row ">
                    <div className="col-12">
                        {
                            routeCard.map((route, i) => (
                                <>
                                    {route?.data?.length ? (
                                        <>
                                            <div className={`card-body ${schedulesErrors.some(err => err.project_id === route.data[0]?.id) ? 'bg-error-primary' : 'bg-secondary'} m-3 mb-0 pb-3 border-radius`}>

                                                <SoftTypography variant="h5" fontSize="14px" className={`mb-2 ${i === 0 ? 'mt-4' : 'mt-4'} d-inline-block`} >{route?.title}</SoftTypography>

                                                <DataTable
                                                    expandableRows
                                                    expandableRowsHideExpander={true}
                                                    expandableRowExpanded={(row) => expandedRows[row.id] || false}
                                                    expandableRowsComponent={({ data }) => {
                                                        const item = props?.allData?.find(item => item.id === data?.id);

                                                        return (
                                                            <>
                                                                <div className="p-3 m-3 bg-secondary border-radius col-12 col-lg-7">
                                                                    {item?.plan_form == 'Reclamation' ? (
                                                                        <>
                                                                            {item?.products ? JSON.parse(item?.products).map((product, product_index) => (
                                                                                <>
                                                                                    <div className="d-flex align-items-center justify-content-between gap-2 mt-2">
                                                                                        <div className="d-flex align-items-center justify-content-between gap-2 ">
                                                                                            <p className="text-link text-secondary">
                                                                                                {product?.title}
                                                                                            </p>
                                                                                        </div>
                                                                                        <p className="text-link text-primary">{product?.quantity + ' Stück'}</p>
                                                                                    </div>
                                                                                </>
                                                                            )) : ''}

                                                                            <div className="d-flex align-items-center justify-content-between gap-2 mt-2">
                                                                                <div className="d-flex align-items-center justify-content-between gap-2 ">
                                                                                    <p className="text-link text-secondary">
                                                                                        {t('Dachtyp')}
                                                                                    </p>
                                                                                </div>
                                                                                <p className="text-link text-primary">{item?.dachtyp}</p>
                                                                            </div>

                                                                            <div className="d-flex align-items-center justify-content-between gap-2 mt-2">
                                                                                <div className="d-flex align-items-center justify-content-between gap-2 ">
                                                                                    <p className="text-link text-secondary">
                                                                                        {t('Vorh. Techniker')}
                                                                                    </p>
                                                                                </div>
                                                                                <p className="text-link text-primary">{item?.prevelektromontage?.employee?.name ? item?.prevelektromontage?.employee?.name : item?.prevdachmontag?.employee?.name ? item?.prevdachmontag?.employee?.name : '--'}</p>
                                                                            </div>

                                                                            <div className="d-flex align-items-center justify-content-between gap-2 mt-2">
                                                                                <div className="d-flex align-items-center justify-content-between gap-2 ">
                                                                                    <p className="text-link text-secondary">
                                                                                        {t('Project')}
                                                                                    </p>
                                                                                </div>
                                                                                <p className="text-link text-primary">{item?.project_nr}</p>
                                                                            </div>
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            {item?.newQuation === 'yes' ? (
                                                                                <>
                                                                                    {item?.productItems && item?.productItems != 'null' ? JSON.parse(item?.productItems)?.items?.map((productItem, j) => (
                                                                                        <>
                                                                                            {productItem?.type == 'bundle' && productItem.item.id ? (
                                                                                                <>
                                                                                                    <p
                                                                                                        style={{
                                                                                                            fontSize: '0.875rem',
                                                                                                            fontWeight: 'bold',
                                                                                                            color: '#2D3748'
                                                                                                        }}
                                                                                                        className="m-0 p-0 mt-0"
                                                                                                    >{productItem.item?.label}</p>
                                                                                                    {productItem.item?.items ? productItem.item?.items.map((item, item_index) => (
                                                                                                        <>
                                                                                                            <div className="d-flex align-items-center justify-content-between gap-2 mt-2">
                                                                                                                <div className="d-flex align-items-center justify-content-between gap-2 ">
                                                                                                                    <p className="text-link text-secondary">
                                                                                                                        {item[item?.type]?.name}
                                                                                                                    </p>

                                                                                                                    <Tooltip
                                                                                                                        title={item[item?.type]?.lieferdatum ? ('PO-' + item[item?.type]?.po_number + ': ' + item[item?.type]?.lieferdatum) : ''}
                                                                                                                        placement="bottom"
                                                                                                                    >
                                                                                                                        <p className={`badge ${item[item?.type]?.lieferdatum ? 'badge-success-light' : 'badge-secondary text-primary text-sm '} m-0`}>
                                                                                                                            <i className="ph ph-truck me-1"></i>
                                                                                                                            {item[item?.type]?.lieferdatum ? `Lieferdaturm: ${item[item?.type]?.lieferdatum}` : 'Nicht vorhanden'}
                                                                                                                        </p>
                                                                                                                    </Tooltip>
                                                                                                                </div>
                                                                                                                <p className="text-link text-primary">{item?.number + ' Stück'}</p>
                                                                                                            </div>
                                                                                                        </>
                                                                                                    )) : ''}
                                                                                                </>
                                                                                            ) : productItem?.type == 'product' ? (
                                                                                                <>
                                                                                                    <div className="d-flex align-items-center justify-content-between gap-2 mt-2">
                                                                                                        <div className="d-flex align-items-center justify-content-between gap-2 ">
                                                                                                            <p className="text-link text-secondary">
                                                                                                                {productItem?.item?.label}
                                                                                                            </p>

                                                                                                            <Tooltip
                                                                                                                title={productItem?.item?.lieferdatum ? ('PO-' + productItem?.item?.po_number + ': ' + productItem?.item?.lieferdatum) : ''}
                                                                                                                placement="bottom"
                                                                                                            >
                                                                                                                <p className={`badge ${productItem?.item?.lieferdatum ? 'badge-success-light' : 'badge-secondary text-primary text-sm '} m-0`}>
                                                                                                                    <i className="ph ph-truck me-1"></i>
                                                                                                                    {productItem?.item?.lieferdatum ? `Lieferdaturm: ${productItem?.item?.lieferdatum}` : 'Nicht vorhanden'}
                                                                                                                </p>
                                                                                                            </Tooltip>
                                                                                                        </div>
                                                                                                        <p className="text-link text-primary">{productItem?.quantity + ' Stück'}</p>
                                                                                                    </div>
                                                                                                </>
                                                                                            ) : (
                                                                                                <></>
                                                                                            )}
                                                                                        </>
                                                                                    )) : ''}
                                                                                </>
                                                                            ) : (
                                                                                <>
                                                                                    <SoftTypography className="p-0" style={{ fontSize: '0.75rem' }} variant="caption"><span >{t('Modul')}</span>: <strong>{item?.anzahl_module + ' Stck. | ' + (item?.module_eingeben == 'no' ? item?.module_short_title : item?.custom_module_short_title)}</strong></SoftTypography><br />
                                                                                    <SoftTypography className="p-0" style={{ fontSize: '0.75rem' }} variant="caption"><span >{t('Speicher')}</span>: <strong>{item?.speicher_eingeben == 'no' ? item?.speicher_name : item?.custom_speicher_name}</strong></SoftTypography><br />
                                                                                </>
                                                                            )}

                                                                            <div className="d-flex align-items-center justify-content-start gap-2 mt-2">
                                                                                <p className="text-link text-secondary m-0">{t('Dachtyp')}:</p>
                                                                                <p className="text-link text-primary m-0">{item?.dachtyp}</p>
                                                                            </div>

                                                                            {item?.plan_form == 'ACFertigmeldung' && (
                                                                                <div className="d-flex align-items-center justify-content-start gap-2 mt-2">
                                                                                    <p className="text-link text-secondary m-0">{t('Vorh. Techniker')}:</p>
                                                                                    <p className="text-link text-primary m-0">{item?.prevelektromontage?.employee?.name ? item?.prevelektromontage?.employee?.name : item?.prevdachmontag?.employee?.name ? item?.prevdachmontag?.employee?.name : '--'}</p>
                                                                                </div>
                                                                            )}
                                                                        </>
                                                                    )}
                                                                </div>
                                                            </>
                                                        )
                                                    }}
                                                    columns={[
                                                        {
                                                            name: <></>,
                                                            width: '44px',
                                                            cell: item => <>
                                                                <div className="form-check">
                                                                    <input
                                                                        className="form-check-input"
                                                                        type="checkbox"
                                                                        checked
                                                                    />
                                                                </div>
                                                            </>
                                                        },
                                                        {
                                                            name: "",
                                                            cell: (row) => <>
                                                                <i
                                                                    onClick={() => toggleRowExpand(row.id)}
                                                                    style={{
                                                                        fontSize: '20px',
                                                                        cursor: 'pointer'
                                                                    }}
                                                                    className={expandedRows[row.id] ? "ph ph-caret-down" : "ph ph-caret-right"}
                                                                ></i>
                                                            </>,
                                                            width: "44px",
                                                            expander: true,
                                                        },

                                                        {
                                                            name: t('Projekt'),
                                                            width: '132px',
                                                            selector: data => <>
                                                                {(data?.plan_form == 'Reclamation' ? (
                                                                    <a target='_blank' href={'/reclamation/' + data?.id} className='text-brand-primary'>{'R-' + data?.id}</a>
                                                                ) : (
                                                                    <a target='_blank' href={'/projects/' + (data?.plan_form == 'ACFertigmeldung' ? data.project_id : data.id)} className='text-brand-primary'>{(data?.plan_form == 'ACFertigmeldung' ? data.project_id : data.id)}</a>
                                                                ))}
                                                            </>,
                                                            sortable: false,
                                                        },
                                                        {
                                                            name: t('Adresse'),
                                                            // width: '132px',
                                                            cell: row => {
                                                                const item = props?.allData?.find(item => item.id === row?.id);
                                                                return (<>
                                                                    <p className="text-link text-primary text-overflow-ellipsis">{item?.street + ' ' + item?.nr + ', ' + item?.plz_ort}</p>
                                                                </>
                                                                )
                                                            },
                                                            sortable: false,
                                                        },
                                                        {
                                                            name: t('Wunschtermin'),
                                                            // width: '140px',
                                                            cell: item => <>{item.assembly_date ? dateFormat(item?.assembly_date, "dd.mm.yyyy") : '--'}</>,
                                                            sortable: false,
                                                        },
                                                        {
                                                            name: t('Kunde'),
                                                            //width: '140px',
                                                            cell: item => <>{item?.name}</>,
                                                            sortable: false,
                                                        },
                                                        {
                                                            name: t('Bereit zur Planung'),
                                                            width: '86px',
                                                            cell: (item, index) => <>
                                                                <div class="form-check form-switch float-end">
                                                                    <input
                                                                        class="form-check-input"
                                                                        type="checkbox"
                                                                        onChange={(e) => {
                                                                            //redayToplanSubmit(e.target.checked, item?.plan_form, item?.id, (item?.plan_form == 'Reclamation' ? item?.project_nr : item?.plan_form == 'ACFertigmeldung' ? item.project_id : item.id));
                                                                            //var updateData = data;
                                                                            //updateData[index].ready_to_plan = e.target.checked ? 'yes' : 'no';
                                                                            //setData(updateData);
                                                                        }}
                                                                        checked={props?.allData?.find(it => it.id === item?.id)?.ready_to_plan === 'yes' ? true : false}
                                                                    />
                                                                </div>
                                                            </>,
                                                            sortable: false,
                                                        },
                                                        {
                                                            name: t('Days'),
                                                            width: '140px',
                                                            cell: item => <>

                                                                <SoftSelect
                                                                    placeholder="Select Hours"
                                                                    options={[
                                                                        { label: "3 Stunden", value: "3" },
                                                                        { label: "6 Stunden", value: "6" },
                                                                        { label: "9 Stunden", value: "9" },
                                                                        { label: "1 Tage", value: "12" },
                                                                        { label: "2 Tage", value: "24" },
                                                                    ]}
                                                                    value={{
                                                                        label: item?.needHours
                                                                            ? `${item?.needHours} ${item?.needHours < 12 ? 'Stunden' : 'Tage'}`
                                                                            : item?.plan_form === "Reclamation"
                                                                                ? "3 Stunden"
                                                                                : item?.anzahl_module <= 22
                                                                                    ? "1 Tage"
                                                                                    : "2 Tage",
                                                                        value: item?.needHours ?? (item?.plan_form === "Reclamation"
                                                                            ? "3"
                                                                            : item?.anzahl_module <= 22
                                                                                ? "12"
                                                                                : "24"),
                                                                    }}
                                                                    menuPortalTarget={document.body}
                                                                    menuPlacement="auto"
                                                                />
                                                            </>,
                                                            sortable: false,
                                                        },
                                                        {
                                                            name: t(''),
                                                            width: '44px',
                                                            cell: item => <>
                                                                <div className="text-center dropstart">
                                                                    <a href="/" className="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                                                        <i className="fa fa-ellipsis-v text-xs"></i>
                                                                    </a>
                                                                    <ul className="dropdown-menu">
                                                                        <li>Example 1</li>
                                                                        <li><hr className="dropdown-divider" /></li>
                                                                        <li>Example 2</li>
                                                                    </ul>
                                                                </div>
                                                            </>,
                                                            sortable: false,
                                                        }
                                                    ]}
                                                    data={route?.data}
                                                    className="py-3"
                                                />
                                                <div className='row'>
                                                    <div className="col-12 col-md-4">
                                                        <div className='row'>
                                                            <div className="form-group col-6">
                                                                <label>{t('Startdatum')}</label>
                                                                <SoftDatePicker
                                                                    className={`form-control form-control-sm ${requriedErrors.find(error => error.itemId === route?.data[0]?.id)?.missingFields?.startDate && 'is-invalid'}`}
                                                                    value={route.data[0]?.startDate ? new Date(route.data[0].startDate) : ""}
                                                                    onChange={(date) => {
                                                                        const selectedDate = date[0]
                                                                            ? date[0].toLocaleDateString('en-CA') // 'en-CA' gives 'YYYY-MM-DD'
                                                                            : "";

                                                                        // Handle changes for startDate and reset other related fields
                                                                        handleDataChange(selectedDate, i, "startDate");
                                                                        handleDataChange('', i, "startTime");
                                                                        handleDataChange('', i, "endDate");
                                                                        handleDataChange('', i, "endTime");
                                                                    }}
                                                                    options={{
                                                                        minDate: new Date(), // Disable past dates
                                                                    }}
                                                                    required
                                                                />
                                                            </div>
                                                            <div className="form-group col-6">
                                                                <label>{t("Start Time")} *</label>
                                                                <SoftSelect
                                                                    size="small"
                                                                    value={timeOptions.find(option => option.value === route.data[0]?.startTime) || null}
                                                                    onChange={(selected) => handleDataChange(selected?.value, i, "startTime")}
                                                                    options={timeOptions}
                                                                    placeholder="--"
                                                                    menuPortalTarget={document.body}
                                                                    menuPlacement="auto"
                                                                    error={requriedErrors.find(error => error.itemId === route.data[0]?.id)?.missingFields.startTime ? true : false}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-12 col-md-4">
                                                        <div className='row'>
                                                            <div className="form-group col-6">
                                                                <label>
                                                                    {t('Enddatum')}
                                                                </label>
                                                                <SoftDatePicker
                                                                    className={`form-control form-control-sm ${requriedErrors.find(error => error.itemId === route.data[0]?.id)?.missingFields.endDate && 'is-invalid'}`}
                                                                    value={route.data[0]?.endDate ? new Date(route.data[0].endDate) : ""}
                                                                    onChange={(date) => {
                                                                        const selectedDate = date[0]
                                                                            ? date[0].toLocaleDateString('en-CA') // 'en-CA' gives 'YYYY-MM-DD'
                                                                            : "";
                                                                        handleDataChange(selectedDate, i, "endDate");
                                                                    }}
                                                                    options={{
                                                                        minDate: route.data[0]?.startDate
                                                                            ? new Date(route.data[0]?.startDate)  // Convert to Date object
                                                                            : new Date(),  // Default to today
                                                                    }}
                                                                    required
                                                                />
                                                            </div>
                                                            <div className="form-group col-6">
                                                                <label>{t("End Time")} *</label>

                                                                <SoftSelect
                                                                    size="small"
                                                                    value={timeOptions.find(option => option.value === route.data[0]?.endTime) || null} // Sets the selected option
                                                                    onChange={(selected) => handleDataChange(selected?.value, i, "endTime")} // Passes the selected value
                                                                    options={timeOptions}
                                                                    placeholder="--"
                                                                    menuPortalTarget={document.body}
                                                                    menuPlacement="auto"
                                                                    error={requriedErrors.find(error => error.itemId === route.data[0]?.id)?.missingFields.endTime ? true : false}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-12 col-md-4">
                                                        <div className='row'>
                                                            <div className="form-group col-12 col-md-8">
                                                                <label>{t('Team Zuweisen')}</label>
                                                                <SoftSelect
                                                                    size="small"
                                                                    options={employees.map((technician) => ({
                                                                        value: technician.id,
                                                                        label: technician.name,
                                                                        isDisabled: allreadyBookedEmployees.includes(String(technician.id)),
                                                                    }))}
                                                                    getOptionLabel={(e) => (
                                                                        <span className={allreadyBookedEmployees.includes(String(e.value)) ? 'already-booked-employee' : ''}>
                                                                            {e.label}
                                                                        </span>
                                                                    )}
                                                                    onChange={(e) => {
                                                                        console.log(e.value)
                                                                        setValue("miltarbeiter[" + i + "]", e.value);
                                                                    }}
                                                                    placeholder="Select Team Member"
                                                                    menuPortalTarget={document.body}
                                                                    menuPlacement="auto"
                                                                    error={requriedErrors.find(error => error.itemId === route.data[0]?.id)?.missingFields.workers ? true : false}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    ) : ''}
                                </>
                            ))
                        }
                    </div>
                    <div className="col-12 my-3 d-none">
                        {!saving && (
                            <button type="submit" className="btn btn-primary" id="routePlanSave">
                                {t('Save')}
                            </button>
                        )}
                        {saving && (
                            <button type="submit" className="btn btn-disabled" disabled>
                                {t('Saving ...')}
                            </button>
                        )}
                    </div>
                </div>
            </form >
            {renderMessageSB}
        </>
    );
}

export default Index