import { React, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Navigate, useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import callFetch from "helpers/callFetch";
import { Grid, Switch } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftSnackbar from "components/SoftSnackbar";
import { NavLink } from 'react-router-dom'

function StartDateExitDate({ employee, title, refreshData }) {
    const params = useParams();
    const { t } = useTranslation();
    const [roles, setRoles] = useState([]);
    const [saving, setSaving] = useState(false);
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [refresh, setRefresh] = useState(0);
    const [visible, setVisible] = useState(true);
    const [receivedError, setReceivedError] = useState(null);
    const {
        register,
        handleSubmit,
        setError,
        setValue,
        formState: { errors },
    } = useForm();

    const handleSetVisible = () => setVisible(!visible);


    useEffect(() => {
        if (employee) {
            setValue('start_date', employee?.start_date);
            setValue('end_date', (employee?.end_date == null || employee?.end_date == 'null' ? "" : employee?.end_date));
            if (employee?.status === "aktivieren") {
                setVisible(true)
            } else if (employee?.status === "deaktivieren") {
                setVisible(false)
            }
        }
    }, [refresh, employee]);

    const [successSB, setSuccessSB] = useState(false);
    const openSuccessSB = () => setSuccessSB(true);
    const closeSuccessSB = () => setSuccessSB(false);
    const renderSuccessSB = (
        <SoftSnackbar
            color="success"
            icon="check"
            title="Success"
            content="Beginndatum/Austrittsdatum Successfully Updated"
            dateTime="Just Now"
            open={successSB}
            onClose={closeSuccessSB}
            close={closeSuccessSB}
            bgWhite
        />
    );


    const onSubmit = (formData) => {
        setSaving(true);
        formData.status = visible ? "aktivieren" : "deaktivieren"
        formData.employee_identity_number = params?.id
        formData.name = employee?.name
        formData.email = employee?.email
        callFetch("employees/" + params?.id, "POST", formData, setError).then((res) => {
            setSaving(false);
            if (receivedError == null && res.message === 'success' && params?.id) {
                openSuccessSB();
            }
            refreshData();
            if (!res.ok) return;
            setSubmitSuccess(true);
        });
    };

    return submitSuccess && params?.id ? <Navigate to={'/employees'} /> : (
        <>
            <form className={`needs-validation ${Object.keys(errors).length ? "was-validated" : ""}`} onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
                <input type="hidden" defaultValue="PUT" {...register("_method")} />
                <div className="container">
                    <div className="card">
                        <div className="card-header">
                            <h3 className='title'>{t("Beginndatum/Austrittsdatum")}</h3>
                        </div>
                        <div className="card-body pb-3">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>
                                            {t('Joining Date')} *
                                        </label>
                                        <input
                                            type="date"
                                            className="form-control flatpickr"
                                            placeholder={t('eg. 16-04-2022')}
                                            {...register("start_date", {
                                                required: true,
                                            })}
                                            required
                                        />
                                        <div className="invalid-feedback">{errors.joining_date && errors.joining_date.message}</div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>
                                            {t('End Date')}
                                        </label>
                                        <input
                                            type="date"
                                            className="form-control flatpickr"
                                            placeholder={t('eg. 16-04-2022')}
                                            {...register("end_date")} />
                                        <div className="invalid-feedback">{errors.date_of_birth && errors.date_of_birth.message}</div>
                                    </div>
                                </div>

                                <div className="col-sm-6">
                                    <div className="border-primary border-radius-5 p-2">
                                        <div className="d-flex justify-content-between">
                                            <p className="font-size-16 mb-0 text-primary">
                                                {t('Mitarbeiter')} {visible ? "deaktivieren" : "aktivieren"}
                                            </p>
                                            <div className="">
                                                <Switch checked={visible} onChange={handleSetVisible} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="card fixed-bottom mb-3 fixed-bottom-width-available">
                    <div className="card-footer d-flex align-items-center justify-content-end gap-2">
                        <NavLink to={`/employees/${params?.id}?step=2`} className="btn btn-secondary m-0 d-flex align-items-center justify-content-between gap-2">
                            <i className='ph ph-caret-left'></i>
                            {t('Back')}
                        </NavLink>

                        {saving ? (
                            <button type="button" className="btn btn-disabled m-0" disabled>
                                {t('Saving ...')}
                            </button>
                        ) : (
                            <button type="submit" className="btn btn-primary m-0 d-flex align-items-center justify-content-between gap-2">
                                {t("Save")}
                                <i className='ph ph-caret-right'></i>
                            </button>
                        )}
                    </div>
                </div>
            </form>
            {renderSuccessSB}
        </>
    )
}

export default StartDateExitDate