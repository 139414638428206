import { React, useEffect, useState } from 'react';
import { NavLink } from "react-router-dom";
import callFetch from "../../helpers/callFetch";
import { useTranslation } from 'react-i18next';
import DataTable from 'react-data-table-component';
import deleteAlert from "../../helpers/deleteAlert";
import Cookies from 'js-cookie';
import dateFormat from "dateformat";

function IndexTable() {
    const { t } = useTranslation();
    const [tabledata, setTableData] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [refresh, setRefresh] = useState(0);

    const tableHeadings = [
        {
            name: t('Modulnr.'),
            selector: row => (
                <NavLink to={'/products/module/' + row.id + '/edit'} className={"text-brand-primary"}>{row.module_identity}</NavLink>
            ),
            sortable: true,
        },
        {
            name: t('Title'),
            selector: row => row.name,
        },
        {
            name: t('Watt'),
            cell: row => row.watt,
        },
        {
            name: t('Create Date'),
            selector: row => dateFormat(row?.created_at, "dd.mm.yyyy"),
        },
        {
            name: t(''),
            width: '44px',
            cell: row => (
                <>
                    {JSON.parse(Cookies.get('permissions')).indexOf("MU") !== -1 ||
                        JSON.parse(Cookies.get('permissions')).indexOf("MD") !== -1 ? (
                        <div className="text-center dropstart">
                            <a href="/" className="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                <i className="fa fa-ellipsis-v text-xs"></i>
                            </a>
                            <ul className="dropdown-menu">
                                {JSON.parse(Cookies.get('permissions')).indexOf("MU") !== -1 ? (
                                    <li>
                                        <NavLink to={'/products/module/' + row.id + '/edit'} className="dropdown-item">
                                            {t('Edit')}
                                        </NavLink>
                                    </li>
                                ) : <></>}
                                <li><hr className="dropdown-divider" /></li>
                                {JSON.parse(Cookies.get('permissions')).indexOf("MD") !== -1 ? (
                                    <li><a className="dropdown-item text-danger" href="#0" onClick={(e) => deleteAlert(e, 'module', row.id, t).then(res => setRefresh(refresh + 1))}>{t('Delete')}</a></li>
                                ) : <></>}
                            </ul>
                        </div>
                    ) : <></>}
                </>
            )
        }
    ];

    useEffect(() => {
        callFetch("module?page=" + pageNumber, "GET", []).then((res) => {
            setTableData(res.data);
        });
    }, [pageNumber, refresh]);

    const handlePageChange = page => {
        setPageNumber(page);
    }

    function toPages(pages) {
        const results = [];

        for (let i = 1; i <= pages; i++) {
            results.push(i);
        }

        return results;
    }

    // RDT exposes the following internal pagination properties
    const BootyPagination = ({
        onChangePage,
        currentPage
    }) => {
        const handleBackButtonClick = () => {
            onChangePage(currentPage - 1);
        };

        const handleNextButtonClick = () => {
            onChangePage(currentPage + 1);
        };

        const handlePageNumber = (e) => {
            onChangePage(Number(e.target.value));
        };

        const pages = tabledata.last_page;
        const pageItems = toPages(pages);
        const nextDisabled = currentPage === tabledata.last_page;
        const previosDisabled = currentPage === 1;

        return (
            <div className='table-pagination d-flex justify-content-between align-items-center pb-0'>
                <p className="entries">{tabledata.from} - {tabledata.to} {t('of')} {tabledata.total} {t('entries')}</p>
                <ul className="pagination ">
                    <li className="page-item">
                        <button
                            className="page-link"
                            onClick={handleBackButtonClick}
                            disabled={previosDisabled}
                            aria-disabled={previosDisabled}
                            aria-label="previous page"
                        >
                            <i className='ph ph-caret-double-left text-primary text-xl'></i>
                        </button>
                    </li>

                    <li className="page-item">
                        <button
                            className="page-link"
                            onClick={handleBackButtonClick}
                            disabled={previosDisabled}
                            aria-disabled={previosDisabled}
                            aria-label="previous page"
                        >
                            <i className='ph ph-caret-left text-primary text-xl'></i>
                        </button>
                    </li>

                    {tabledata?.links.map((page) => {
                        //const className = (parseInt(page.label) === currentPage ? "page-item active" : "page-item");
                        const className = (page.active === true ? "page-item active" : "page-item");
                        return (
                            <li key={page.label} className={className + ' ' + (parseInt(page.label) || page.label == '...' ? '' : 'd-none')}>
                                <button
                                    className="page-link"
                                    onClick={page.label != '...' ? handlePageNumber : ''}
                                    value={page.label}
                                    disabled={page.label == '...' ? true : false}
                                >
                                    {page.label}
                                </button>
                            </li>
                        );
                    })}

                    <li className="page-item">
                        <button
                            className="page-link"
                            onClick={handleNextButtonClick}
                            disabled={nextDisabled}
                            aria-disabled={nextDisabled}
                            aria-label="next page"
                        >
                            <i className='ph ph-caret-right text-primary text-xl'></i>
                        </button>
                    </li>

                    <li className="page-item">
                        <button
                            className="page-link"
                            onClick={handleNextButtonClick}
                            disabled={nextDisabled}
                            aria-disabled={nextDisabled}
                            aria-label="next page"
                        >
                            <i className='ph ph-caret-double-right text-primary text-xl'></i>
                        </button>
                    </li>
                </ul>
            </div>
        );
    };

    return <DataTable
        columns={tableHeadings}
        data={tabledata.data}
        noDataComponent={
            <div className="d-flex align-items-center justify-content-center py-3" style={{ height: "100%" }}>
                <div className="text-center">
                    <i className='ph ph-notepad data-table-no-record-icon'></i>
                    <p className="text-secondary text-md m-0">{t('There are no records to display')}</p>
                </div>
            </div>
        }
        className='data-table'
        pagination
        highlightOnHover
        paginationComponentOptions={{ noRowsPerPage: true }}
        paginationServer
        paginationTotalRows={tabledata.total}
        onChangePage={handlePageChange}
        paginationComponent={BootyPagination}
    />;
}

export default IndexTable;
