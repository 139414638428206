import { useMemo, useEffect, useState, useRef } from "react";
import { useTranslation } from 'react-i18next';
import dateFormat, { masks } from "dateformat";
import { useForm } from "react-hook-form";
// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import { Navigate, NavLink, useNavigate, withRouter } from "react-router-dom";
import Checkbox from "@mui/material/Checkbox";

// Data
import Cookies from 'js-cookie';
import callFetch from "../../helpers/callFetch";
import DashboardCalendar from 'pages/dashboard/Calendar';
import SoftDatePicker from "components/SoftDatePicker";

import OrdersToPlan from "../reports/OrdersToPlan";

function Index(props) {
  const { t } = useTranslation();
  const calendarAreaRef = useRef(null);
  const [teamsOpenCollapseHeight, setTeamsOpenCollapseHeight] = useState('253px');
  const [teams, setTeams] = useState([]);
  const [teamsColor, setTeamsColor] = useState([]);
  const [selectedTeams, setSelectedTeams] = useState([]);
  const [selectedTeamMembers, setSelectedTeamMembers] = useState([]);
  const [byDefaultSelectedTeams, setByDefaultSelectedTeams] = useState(null);
  const [readyToLoad, setReadyToLoad] = useState(false);
  const [projectDisplay, setProjectDisplay] = useState(true);
  const [isUpdate, setIsUpdate] = useState(false);
  const [editEvent, setEditEvent] = useState(false);
  const [refresh, setRefresh] = useState(1);
  const [openId, setOpenId] = useState(0);

  const fetchRefresh = (refresh) => {
    setRefresh(refresh);
  }


  const {
    register,
    handleSubmit,
    setError,
    setValue,
    getValues,
    formState: { errors },
  } = useForm();

  const handleColorPickerToggle = (id) => {
    console.log(id);
    if (openId == id) {
      setOpenId(0);
    } else {
      setOpenId(id);
    }
  };

  const updateTeamColor = (id, code, bgcode, old) => {

    var formData = new FormData();
    formData.id = id;
    //code is for checkbox backgruond color
    formData.code = code;
    formData.old_color = old;
    //bg code is for calendar item background color
    formData.bgcode = bgcode;

    if (code == old) {
      //to avoid a single color for 2 or more team
      setTeams(teams =>
        teams.map(team =>
          team.color_code === code ? { ...team, color_code: '' } : team
        )
      );
    } else {
      //to avoid a single color for 2 or more team
      setTeams(teams =>
        teams.map(team =>
          team.color_code === code ? { ...team, color_code: '' } : team
        )
      );

      //update state 
      setTeams(teams =>
        teams.map(team =>
          team.id === id ? { ...team, color_code: code } : team
        )
      );
    }

    //update on server/db
    callFetch("update-team-color", "POST", formData, setError).then((res) => {
      console.log(id, code, bgcode, old);
      setOpenId(0);
    });

  };

  const checkColorCodeExists = (color_code) => {
    return teams.some(team => team.color_code === color_code);
  }

  const checkAll = (teamtype, check) => {
    console.log('teamtype=' + teamtype)
    console.log('check=' + check)



    var selectedAllTeams = selectedTeams;
    var selectedAllTeamMember = selectedTeamMembers;
    var members = selectedAllTeamMember;
    var selectedTeam = selectedAllTeams;

    for (let [key, team] of Object.entries(teams)) {

      if (team?.team_type == teamtype) {
        if (selectedTeams.indexOf(parseInt(team?.id)) == -1 && check) {
          selectedTeam.push(parseInt(team?.id));
        } else if (selectedTeams.indexOf(parseInt(team?.id)) !== -1 && check == false) {
          selectedTeam = selectedTeam.filter(e => e !== parseInt(team?.id))
        }

        Object.keys(team?.teammember).map(function (key) {
          if (selectedTeamMembers.indexOf(parseInt(team.teammember[key]?.employe?.id)) == -1 && check) {
            members.push(parseInt(team.teammember[key]?.employe?.id));
          } else if (selectedTeamMembers.indexOf(parseInt(team.teammember[key]?.employe?.id)) !== -1 && check == false) {
            members = members.filter(e => e !== parseInt(team.teammember[key]?.employe?.id))
          }
        });

        Object.keys(team?.manager).map(function (key) {
          //console.log('check all')
          //console.log(team.manager[key]?.user?.id)

          if (selectedTeamMembers.indexOf(parseInt(team.manager[key]?.user?.id)) == -1 && check) {
            //console.log('check manager')
            members.push(parseInt(team.manager[key]?.user?.id));
          } else if (selectedTeamMembers.indexOf(parseInt(team.manager[key]?.user?.id)) !== -1 && check == false) {
            //console.log('uncheck manager')
            members = members.filter(e => e !== parseInt(team.manager[key]?.user?.id))
          }
        });


      }
    }

    members = members.filter(e => e !== null)
    members = members.filter(e => !isNaN(e))

    setSelectedTeams(selectedTeam);
    setSelectedTeamMembers(members);

    setRefresh(refresh * (Math.random() * 10) * 10);
    //filteredCalendarData();
  }

  useEffect(() => {
    const handleResize = () => {
      console.log('handleResize')
      if (!calendarAreaRef.current) return;
      let height = (calendarAreaRef.current.offsetHeight - (342 + 130));
      // Check if the window's height has changed
      console.log('height', height)
      setTeamsOpenCollapseHeight(height);
    };

    //Default run
    handleResize();

    // Attach the resize event listener
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [teamsOpenCollapseHeight]);

  useEffect(() => {

    callFetch("my-teams?team_type=" + (props?.team_type ? props?.team_type : 'sales,ac,dc'), "GET", []).then((res) => {
      setByDefaultSelectedTeams(res.selected_teams)
      // console.log(res.teams);
      setTeamsColor(res.teams);
      if (res.selected_team_members != null) {
        setSelectedTeamMembers(res.selected_team_members)
      }
      // console.log(res.data);
      setTeams(res.data);
    });
  }, [isUpdate]);

  useEffect(() => {
    if (!teams.length) return;

    if (byDefaultSelectedTeams == null) {
      var vals = [];
      for (let [key, team_value] of Object.entries(teams)) {
        vals.push(parseInt(team_value.id));
      }
    } else {
      var vals = JSON.parse(byDefaultSelectedTeams);
    }

    filteredCalendarData(vals);
  }, [teams]);

  const filteredCalendarData = (teamIds = '', mobile_teams = '') => {
    if (teamIds) {
      var vals = teamIds;
    } else {
      if (mobile_teams == '') {
        var input_teams = document.getElementsByName('teams[]');
      } else {
        var input_teams = document.getElementsByName('mobile_teams[]');
      }
      var vals = [];
      for (var i = 0, n = input_teams.length; i < n; i++) {
        if (input_teams[i].checked) {
          vals.push(parseInt(input_teams[i].value));
        }
      }
    }

    setSelectedTeams(vals);
    setProjectDisplay(props?.vkEventHide == true ? false : true);
    setEditEvent(props?.editEvent == true ? true : false);
    setReadyToLoad(true);
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <div className="d-flex flex-column flex-lg-row gap-3">
            <div
              style={{
                maxWidth: '355px'
              }}
            >
              <div className="card" style={{ height: '100%' }}>
                <div className="card-body">
                  <div className="dropdown">
                    <button
                      className="btn btn-primary w-100 dropdown-toggle"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >Erstellen</button>

                    <ul
                      className="dropdown-menu dropdown-menu-end"
                      style={{
                        width: '100%'
                      }}
                    >
                      <li
                        className="cursor-pointer dropdown-item"
                        onClick={(e) => {
                          if (document.getElementById('createAbwesenheitRequest')) {
                            document.getElementById('createAbwesenheitRequest').click()
                          }
                        }}
                      >{t('Absence')}</li>
                      <li className="cursor-pointer dropdown-item">{t('Plan hinzufügen')}</li>
                    </ul>
                  </div>

                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6} lg={12} className="calendar-ac-dc">
                      <SoftDatePicker
                        options={{
                          inline: true,
                          disableMobile: true,
                          dateFormat: "Y-m-d",
                          //defaultDate: selectedDate,
                          weekNumbers: false,
                          //onChange: (date) => { setSelectedDate(date[0]) },
                        }}
                        className="d-none"
                      />
                    </Grid>

                    <Grid item xs={12} md={6} lg={12}>
                      <div className="accordion mb-3">
                        <div className="accordion-item"
                        >
                          <div className="accordion-header">
                            <h5
                              className="text-lg text-primary cursor-pointer open-hide-icon m-0 pb-3"
                              data-bs-toggle="collapse"
                              data-bs-target="#teamsOpen-collapseOne"
                              aria-expanded="true"
                              aria-controls="teamsOpen-collapseOne"
                            >Teams</h5>
                          </div>

                          <div
                            id="teamsOpen-collapseOne"
                            className="accordion-collapse collapse show scroll-cs"
                            aria-labelledby="teamsOpen-headingOne"
                            style={{
                              overflowY: 'auto',
                              height: teamsOpenCollapseHeight,
                              maxHeight: '100%',
                            }}
                          >
                            <div className="accordion-body">
                              <SoftBox className="py-2" display="flex" alignItems="center">
                                <Checkbox
                                  defaultChecked={false}
                                  onChange={(e) => {
                                    checkAll('ac', e.target.checked);
                                  }}
                                  value={'ac'}
                                />
                                <SoftBox mr={0.7} lineHeight={1}><SoftTypography variant="button" fontWeight="medium">{'AC Check / Uncheck'}</SoftTypography></SoftBox>
                              </SoftBox>

                              <SoftBox className="py-2" display="flex" alignItems="center">
                                <Checkbox
                                  defaultChecked={false}
                                  onChange={(e) => {
                                    checkAll('dc', e.target.checked);
                                  }}
                                  value={'dc'}
                                />
                                <SoftBox mr={0.7} lineHeight={1}><SoftTypography variant="button" fontWeight="medium">{'DC Check / Uncheck'}</SoftTypography></SoftBox>
                              </SoftBox>

                              <div className="mt-3">
                                {teams ? teams.map((team) => (<>
                                  <div className="d-flex align-items-center justify-content-between gap-2 hover-button">
                                    <div className="d-flex">
                                      <Checkbox
                                        value={team.id}
                                        name="teams[]"
                                        //defaultChecked={false}
                                        className={selectedTeams.indexOf(team.id) !== -1 ? team.color_code : ''}
                                        checked={selectedTeams.indexOf(team.id) !== -1 ? true : false}
                                        onChange={(e) => {
                                          if (team?.teammember) {
                                            var selectedAllTeamMember = selectedTeamMembers;
                                            var members = selectedAllTeamMember;

                                            Object.keys(team?.teammember).map(function (key) {
                                              if (selectedTeamMembers.indexOf(parseInt(team.teammember[key]?.employe?.id)) == -1 && e.target.checked) {
                                                members.push(parseInt(team.teammember[key]?.employe?.id));
                                              } else if (selectedTeamMembers.indexOf(parseInt(team.teammember[key]?.employe?.id)) !== -1 && e.target.checked == false) {
                                                members = members.filter(e => e !== parseInt(team.teammember[key]?.employe?.id))
                                              }
                                            });

                                            Object.keys(team?.manager).map(function (key) {
                                              console.log('check all')
                                              console.log(team.manager[key]?.user?.id)

                                              if (selectedTeamMembers.indexOf(parseInt(team.manager[key]?.user?.id)) == -1 && e.target.checked) {
                                                members.push(parseInt(team.manager[key]?.user?.id));
                                              } else if (selectedTeamMembers.indexOf(parseInt(team.manager[key]?.user?.id)) !== -1 && e.target.checked == false) {
                                                members = members.filter(e => e !== parseInt(team.manager[key]?.user?.id))
                                              }
                                            });


                                            setSelectedTeamMembers(members);
                                            setRefresh(refresh * (Math.random() * 10) * 10)
                                          }

                                          filteredCalendarData();
                                        }}
                                      />
                                      <p className="text-link text-primary m-0">{team.team_name}</p>
                                    </div>
                                    <div className="d-flex align-items-center justify-content-between gap-2">
                                      <div className="d-flex align-items-center justify-content-between gap-2 hover-button-show">
                                        <i
                                          className="ph ph-plus btn btn-sm mb-0 text-primary text-lg"
                                          onClick={(e) => {
                                            if (e.target.getAttribute("data-members") == 'show') {
                                              e.target.setAttribute("data-members", 'hide');
                                              document.getElementById("teamMembers" + team.id).classList.add("d-none");
                                            } else {
                                              e.target.setAttribute("data-members", 'show');
                                              document.getElementById("teamMembers" + team.id).classList.remove("d-none");
                                            }
                                          }}
                                          data-members="show"
                                          style={{ cursor: 'pointer' }}
                                        ></i>

                                        <div className="dropdown">
                                          <i
                                            class="ph ph-dots-three-vertical team-color-picker-icon btn btn-sm m-0 text-primary text-lg"
                                            data-bs-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                            onClick={() => handleColorPickerToggle(team.id)}
                                          ></i>

                                          <div
                                            className="dropdown-menu dropdown-menu-end"
                                            style={{
                                              minWidth: 'unset',
                                              textAlign: 'center'
                                            }}
                                          >
                                            <div className="team-color-picker d-block">
                                              <span onClick={() => updateTeamColor(team.id, 'team-color-1', 'team-bg-color-1', team.color_code)} className="color" style={{ position: 'relative', backgroundColor: '#D68B00' }}> {checkColorCodeExists('team-color-1') ? <i style={{ position: 'absolute', right: '6px' }} className="fa-solid fa-check"></i> : ''} </span>
                                              <span onClick={() => updateTeamColor(team.id, 'team-color-2', 'team-bg-color-2', team.color_code)} className="color" style={{ position: 'relative', backgroundColor: '#48BB78' }}> {checkColorCodeExists('team-color-2') ? <i style={{ position: 'absolute', right: '6px' }} className="fa-solid fa-check"></i> : ''} </span>
                                              <span onClick={() => updateTeamColor(team.id, 'team-color-3', 'team-bg-color-3', team.color_code)} className="color" style={{ position: 'relative', backgroundColor: '#CB0C9F' }}> {checkColorCodeExists('team-color-3') ? <i style={{ position: 'absolute', right: '6px' }} className="fa-solid fa-check"></i> : ''}  </span>
                                              <span onClick={() => updateTeamColor(team.id, 'team-color-4', 'team-bg-color-4', team.color_code)} className="color" style={{ position: 'relative', backgroundColor: '#668650', marginRight: '0px' }}> {checkColorCodeExists('team-color-4') ? <i style={{ position: 'absolute', right: '6px' }} className="fa-solid fa-check"></i> : ''}  </span>
                                              <span onClick={() => updateTeamColor(team.id, 'team-color-5', 'team-bg-color-5', team.color_code)} className="color" style={{ position: 'relative', backgroundColor: '#2424B2' }}> {checkColorCodeExists('team-color-5') ? <i style={{ position: 'absolute', right: '6px' }} className="fa-solid fa-check"></i> : ''}  </span>
                                              <span onClick={() => updateTeamColor(team.id, 'team-color-6', 'team-bg-color-6', team.color_code)} className="color" style={{ position: 'relative', backgroundColor: '#7E5A59' }}> {checkColorCodeExists('team-color-6') ? <i style={{ position: 'absolute', right: '6px' }} className="fa-solid fa-check"></i> : ''}  </span>
                                              <span onClick={() => updateTeamColor(team.id, 'team-color-7', 'team-bg-color-7', team.color_code)} className="color" style={{ position: 'relative', backgroundColor: '#ABB521' }}> {checkColorCodeExists('team-color-7') ? <i style={{ position: 'absolute', right: '6px' }} className="fa-solid fa-check"></i> : ''}  </span>
                                              <span onClick={() => updateTeamColor(team.id, 'team-color-8', 'team-bg-color-8', team.color_code)} className="color" style={{ position: 'relative', backgroundColor: '#2D3748', marginRight: '0px' }}> {checkColorCodeExists('team-color-8') ? <i style={{ position: 'absolute', right: '6px' }} className="fa-solid fa-check"></i> : ''}  </span>
                                              <span onClick={() => updateTeamColor(team.id, 'team-color-9', 'team-bg-color-9', team.color_code)} className="color" style={{ position: 'relative', backgroundColor: '#00C6D6', marginBottom: '0px' }}>  {checkColorCodeExists('team-color-9') ? <i style={{ position: 'absolute', right: '6px' }} className="fa-solid fa-check"></i> : ''}  </span>
                                              <span onClick={() => updateTeamColor(team.id, 'team-color-10', 'team-bg-color-10', team.color_code)} className="color" style={{ position: 'relative', backgroundColor: '#D1063D', marginBottom: '0px' }}>  {checkColorCodeExists('team-color-10') ? <i style={{ position: 'absolute', right: '6px' }} className="fa-solid fa-check"></i> : ''}  </span>
                                              <span onClick={() => updateTeamColor(team.id, 'team-color-11', 'team-bg-color-11', team.color_code)} className="color" style={{ position: 'relative', backgroundColor: '#5F00CC', marginBottom: '0px' }}>  {checkColorCodeExists('team-color-11') ? <i style={{ position: 'absolute', right: '6px' }} className="fa-solid fa-check"></i> : ''}  </span>
                                              <span onClick={() => updateTeamColor(team.id, 'team-color-12', 'team-bg-color-12', team.color_code)} className="color" style={{ marginRight: '0px', position: 'relative', backgroundColor: '#DBBA00', marginBottom: '0px' }}>  {checkColorCodeExists('team-color-12') ? <i style={{ position: 'absolute', right: '6px' }} className="fa-solid fa-check"></i> : ''}  </span>

                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="team-members" id={'teamMembers' + team.id}>
                                    {team?.manager ? team?.manager?.map((item) => (
                                      <>
                                        {item?.user?.name ? (
                                          <SoftBox className="ps-4 py-2 hover-button" display="flex" alignItems="center">
                                            <Checkbox
                                              value={item?.user?.id}
                                              name="team_members[]"
                                              checked={(selectedTeamMembers.indexOf(parseInt(item?.user?.id)) !== -1 ? true : false)}
                                              className={(selectedTeamMembers.indexOf(parseInt(item?.user?.id)) !== -1 ? team.color_code : false)}
                                              onChange={(e) => {

                                                var selectedAllTeamMember = selectedTeamMembers;
                                                var members = selectedAllTeamMember;

                                                if (selectedTeamMembers.indexOf(parseInt(item?.user?.id)) == -1 && e.target.checked) {
                                                  members.push(parseInt(e.target.value));
                                                } else {
                                                  members = members.filter(e => e !== parseInt(item?.user?.id))
                                                }
                                                setSelectedTeamMembers(members);
                                                setRefresh(refresh * (Math.random() * 10) * 10)
                                              }}
                                            />
                                            <p className="text-link text-primary m-0">{item?.user?.name}</p>
                                          </SoftBox>
                                        ) : ''}
                                      </>
                                    )) : ''}

                                    {team?.teammember ? team?.teammember?.map((item) => (
                                      <>
                                        {item?.employe?.name ? (
                                          <SoftBox className="ps-4 py-2 hover-button" display="flex" alignItems="center">
                                            <Checkbox
                                              value={item?.employe?.id}
                                              name="team_members[]"
                                              checked={(selectedTeamMembers.indexOf(parseInt(item?.employe?.id)) !== -1 ? true : false)}
                                              className={(selectedTeamMembers.indexOf(parseInt(item?.employe?.id)) !== -1 ? team.color_code : false)}
                                              onChange={(e) => {

                                                var selectedAllTeamMember = selectedTeamMembers;
                                                var members = selectedAllTeamMember;

                                                if (selectedTeamMembers.indexOf(parseInt(item?.employe?.id)) == -1 && e.target.checked) {
                                                  members.push(parseInt(e.target.value));
                                                } else {
                                                  members = members.filter(e => e !== parseInt(item?.employe?.id))
                                                }
                                                setSelectedTeamMembers(members);
                                                setRefresh(refresh * (Math.random() * 10) * 10)
                                              }}
                                            />
                                            <p className="text-link text-primary m-0">{item?.employe?.name}</p>
                                          </SoftBox>
                                        ) : ''}
                                      </>
                                    )) : ''}
                                  </div>
                                </>
                                )) : <></>}
                              </div>

                            </div>
                          </div>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </div>

            <div ref={calendarAreaRef} style={{ height: 'fit-content' }}>
              <DashboardCalendar
                class={'calendar-main'}
                teamsEvent={true}
                teams={selectedTeams}
                teamMembers={selectedTeamMembers}
                readyToLoad={readyToLoad}
                projects={projectDisplay}
                assign_orders={true}
                reclamations={true}
                absence={false}
                absenceBtn={false}
                editEvent={editEvent}
                refresh={refresh}
              />
            </div>
          </div>
        </Grid>

        <Grid item xs={12}>
          {props?.ordersToPlan === true && (
            <OrdersToPlan setRefresh={fetchRefresh} />
          )}
        </Grid>
      </Grid>
    </>
  );
}

export default Index;
