import { useMemo } from "react";

import { useTranslation } from 'react-i18next'
// porp-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-chartjs-2 components
import { Doughnut } from "react-chartjs-2";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftBadgeDot from "components/SoftBadgeDot";

import DefaultDoughnutChart from "examples/Charts/DoughnutCharts/DefaultDoughnutChart";
import { NumericFormat } from 'react-number-format';

// ProgressDoughnutChart configurations
import configs from "examples/Charts/DoughnutCharts/DefaultDoughnutChart/configs";

function ProgressChart({ color, icon, title, count, height, chart }) {
  const { t } = useTranslation()
  const { data, options } = configs(chart.labels || [], chart.datasets || {});

  console.log('data?.datasets', data?.datasets?.[0]?.data)

  return (
    <>
      <div className="card">
        <div className="card-header">
          <h3 className='title'>{t('Project Bezahlt')}</h3>
        </div>
        <div className="card-body pb-3">
          <div className="row">
            <div className="col-md-5">
              <div className="bg-secondary p-3 border-radius d-flex align-items-center justify-content-between gap-2">
                <div>
                  <p className="text-secondary m-0">{title}</p>
                  <p className="text-primary m-0 text-xl font-weight-600">{count || 0}</p>
                </div>
                <i className="ph ph-clipboard-text title-xl"></i>
              </div>
            </div>
          </div>

          <div className="d-flex align-items-center justify-content-start gap-3 mt-5 mb-4">
            {useMemo(
              () => (
                <SoftBox style={{ position: "relative", width: '220px', height: '220px' }}>
                  <Doughnut data={data} options={options} />
                  <div
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      textAlign: "center",
                    }}
                  >
                    {/*
                    <p className="text-secondary text-sm-normal m-0">{'Umsatz in EUR'}</p>
                    <p className="text-primary text-title text-center m-0">{count || 0}</p>
                    */}
                  </div>
                </SoftBox>
              ),
              [chart, height]
            )}

            <div>
              {chart.labels ? chart.labels.map((label, index) => {
                const badgeDotKey = `badge-dot-${index}`;

                return (
                  <SoftBadgeDot
                    key={badgeDotKey}
                    //variant="gradient"
                    color={
                      chart.datasets.backgroundColors
                        ? chart.datasets.backgroundColors[index]
                        : "dark"
                    }
                    size="lg"
                    badgeContent={label}
                    boldBadgeContent={(data?.datasets?.[0]?.data?.[index] || 0)}
                    font={{ color: "text", weight: "medium" }}
                    px={0}
                    borderRadius={'5px'}
                  />
                );
              }) : null}
            </div>
          </div>

        </div>
      </div>
    </>

  );
}

// Setting default values for the props of ProgressDoughnutChart
ProgressChart.defaultProps = {
  color: "info",
  count: 0,
  height: "100%",
};

// Typechecking props for the ProgressDoughnutChart
ProgressChart.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
    "#0048B1",
  ]),
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  chart: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.array, PropTypes.object])
  ).isRequired,
};

export default ProgressChart;
