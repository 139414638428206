import React from "react";
import { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Navigate, useParams, NavLink } from "react-router-dom";
import Cookies from "js-cookie";
import Timeline from "../create/Timeline";
import { callFetch } from "../../../helpers/callFetch";
import Overview from "../Overview";
import SoftTypography from "components/SoftTypography";
import IndexTable from "./IndexTable";
import { useForm } from "react-hook-form";
import SoftSnackbar from "components/SoftSnackbar";
import SoftBox from "components/SoftBox";
import Beschaffungsliste from "pages/purchase/Beschaffungsliste/Index";
import { textTransform } from "@mui/system";
import Select from "react-select";
function Index(props) {
  let params = useParams();
  const { t } = useTranslation();
  const [data, setData] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const [saving, setSaving] = useState(false);
  const [articles, setArticles] = useState([]);
  const [articlesNew, setArticlesNew] = useState([]);
  const [items, setItems] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [successSB, setSuccessSB] = useState(false);
  const [errorSB, setErrorSB] = useState(false);
  const closeErrorSB = () => setErrorSB(false);
  const [lager, setLager] = useState([]);
  const [lieferanten, setLieferanten] = useState([]);
  const [beschaffung, setBeschaffung] = useState(0);
  const [beschaffungData, setBeschaffungData] = useState([]);
  const [warenausgangData, setWarenausgangData] = useState([]);
  const [articleName, setArticleName] = useState(null);
  const renderSuccessSB = (
    <SoftSnackbar
      color="success"
      icon="check"
      title={t("Success")}
      //content={t("Changes saved successfully")}
      dateTime={t("")}
      open={successSB}
      onClose={() => setSuccessSB(false)}
      close={() => setSuccessSB(false)}
      bgSuccess
    />
  );

  const renderErrorSB = (
    <SoftSnackbar
      color="error"
      icon="info"
      title={t("Quantity was updated.")}
      //content={t("Changes saved successfully")}
      dateTime={t("")}
      open={errorSB}
      onClose={() => closeErrorSB(false)}
      close={() => closeErrorSB(false)}
      bgSuccess
    />
  );

  const {
    reset,
    resetField,
    register,
    handleSubmit,
    setError,
    setValue,
    getValues,
    formState: { errors },
  } = useForm();

  function deleteProduct() {
    var titems = [];
    items.map((t) => {
      if (!t) return;
      titems.push(t);
    });
    setItems(titems);
    setRefresh(refresh + 1);
  }

  const handleArticleChange = (e, index) => {
    const articleId = e;
    if (articleId) {
      callFetch("items-by-prefix/" + articleId, "GET", []).then((res) => {
        if (res.prefix == "Art") {
          setArticleName(res?.data?.article_name);
          if (res?.data) {
            const updatedItems = [...items];
            updatedItems[index].article_name = res?.data?.article_name;
            updatedItems[index].article_nr = res?.id;
            updatedItems[index].prefix = res?.prefix;
            setItems(updatedItems);
          }
        } else {
          setArticleName(res?.data?.name);
          if (res?.data) {
            const updatedItems = [...items];
            updatedItems[index].article_name = res?.data?.name;
            updatedItems[index].article_nr = res?.id;
            updatedItems[index].prefix = res?.prefix;
            setItems(updatedItems);
          }
        }
      });
    }
  };

  useEffect(() => {
    callFetch("beschaffungsliste", "GET", []).then((res) => {
      setBeschaffung(res.data);
    });
    callFetch("goods/create", "GET", []).then((res) => {
      // setValue('receive_number', res?.goods_number)
      // setLager(res?.data?.lager)
      // setLieferanten(res?.data?.lieferanten);
      const products = [];

      // Assuming res.data.modules is an array
      var updatedProducts = res?.data?.modules.map((item) => ({
        id: item.module_identity,
        name: item.name,
      }));

      // Concatenate the existing products with the new items
      products.push(...updatedProducts);

      // Assuming res.data.modules is an array
      updatedProducts = res?.data?.speichers.map((item) => ({
        id: item.speicher_identity,
        name: item.name,
      }));

      // Concatenate the existing products with the new items
      products.push(...updatedProducts);

      // Assuming res.data.modules is an array
      updatedProducts = res?.data?.wechselrichtes.map((item) => ({
        id: item.identity,
        name: item.name,
      }));

      // Concatenate the existing products with the new items
      products.push(...updatedProducts);

      // Assuming res.data.modules is an array
      updatedProducts = res?.data?.articles.map((item) => ({
        id: item.article_nr,
        name: item.article_name,
      }));

      // Concatenate the existing products with the new items
      products.push(...updatedProducts);

      setArticles(products);
    });

    if (!(props?.projectid ? props?.projectid : params.id)) return;
    reset({});

    // setLoading(true);

    callFetch(
      "product-purchase/details/" +
        (props?.projectid ? props?.projectid : params.id) +
        "?page=" +
        1,
      "GET",
      []
    ).then((res) => {
      setTableData(res.data.data);
      console.log(res);
      setBeschaffungData(res?.beschaffung);
      setWarenausgangData(res?.warenausgang);

      // if (res?.beschaffung && res?.beschaffung?.items) {
      //     for (let [key, value] of Object.entries(res?.beschaffung?.items)) {
      //console.log(value);
      //setValue(key, (value == null || value == 'null' ? "" : value));

      //setValue("qty[" + value?.product_id + "]", (value?.qty == null || value?.qty == 'null' ? "" : value?.qty));
      // setValue("lieferdatum[" + value?.product_id + "]", (value?.lieferdatum == null || value?.lieferdatum == 'null' ? "" : value?.lieferdatum));
      // setValue("versanddatum[" + value?.product_id + "]", (value?.versanddatum == null || value?.versanddatum == 'null' ? "" : value?.versanddatum));
      // setValue("bestelldatum[" + value?.product_id + "]", (value?.bestelldatum == null || value?.bestelldatum == 'null' ? "" : value?.bestelldatum));
      // setValue("delivery_link[" + value?.product_id + "]", (value?.delivery_link == null || value?.delivery_link == 'null' ? "" : value?.delivery_link));
      //     }
      // }

      // setLoading(false);
      // setRefresh2(refresh2 + 1);
    });
  }, [refresh, props?.project_id ? props?.project_id : 0]);

  useEffect(() => {
    callFetch(
      "quation-info-for-material/" +
        (props?.projectid ? props?.projectid : params.id) +
        "?project_id=" +
        (props?.projectid ? props?.projectid : params.id),
      "GET",
      []
    ).then((res) => {
      setData(res?.data);
      setArticlesNew(res?.articles);
      setValue("hacken", res?.data?.quations?.anzahl_module * 2.5);
      setValue("schiene", res?.data?.quations?.anzahl_module * 2.5);
      setValue("module", res?.data?.quations?.anzahl_module);
      setValue(
        "speicher_identity",
        res?.data?.quations?.speichers?.speicher_identity
      );
      setValue("identity", res?.data?.quations?.wechselrichter?.identity);
      setValue("module_identity", res?.data?.quations?.module?.module_identity);
    });

    // callFetch("goods/create", "GET", []).then((res) => {
    //     // setValue('receive_number', res?.goods_number)
    //     // setArticles(res?.data?.articles)
    // });
  }, [refresh]);

  const addProjectMaterial = () => {
    setSaving(true);
    var formData = new FormData();
    formData.items = JSON.stringify(items);
    formData.project_id = props?.projectid ? props?.projectid : params.id;
    callFetch("store-project-material", "POST", formData, setError).then(
      (res) => {
        // console.log(res.message);
        setSuccessSB(true);
        setRefresh(refresh + 1);
        setItems([]);
      }
    );
  };

  //Form Submit
  const onSubmit = (formData) => {
    setSaving(true);
    formData.project_id = props?.projectid ? props?.projectid : params.id;
    console.log(formData);
    // callFetch("deduct-products-qty", "POST", formData, setError).then((res) => {
    //     setSaving(false);
    //     if (res.message == 'success') {
    //         setSuccessSB(true);
    //     } else {
    //         setErrorSB(true);
    //     }

    // });
  };

  return (
    <>
      <div className="row">
        <div className="col-sm-12 col-md-12 col-lg-8 col-xl-8 col-xxl-9">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body pb-3">
                  <Beschaffungsliste
                    projectid={props?.projectid ? props?.projectid : params.id}
                    products={articles}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-md-12 col-lg-4 col-xl-4 col-xxl-3 pl-4 project-details-right-side">
          <div className="card">
            <div className="card-body">
              <div class="details">
                <h6 className="text-primary font-size-16 font-weight-500">
                  Projektinformationen
                </h6>

                <div className="mb-2">
                  <p className="font-size-14 text-secondary mb-0">
                    <i class="ph ph-clipboard-text font-size-20"></i>{" "}
                    <span className="position-relative bottom-4 left-4">
                    {t('Add')}
                    </span>
                  </p>
                  <div class="profile">
                    {/* <img
                                src="/assets/img/placeholder.png"
                                className="border-primary"
                                alt="Profile"
                                /> */}
                    <span className="font-size-14 text-primary">{props?.projectid ? props?.projectid : params.id}</span>
                  </div>
                </div>
              </div>
              <Timeline refresh={refresh} acStatusEdit={true} />
            </div>
          </div>
        </div>
      </div>
      {renderSuccessSB}
      {renderErrorSB}
    </>
  );
}

export default Index;
