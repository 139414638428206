import { useState } from "react";
import { useTranslation } from "react-i18next";
// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";

// Soft UI Dashboard PRO React example components
import PlaceholderCard from "examples/Cards/PlaceholderCard";
import { NavLink } from "react-router-dom";
import Cookies from "js-cookie";
import OrderLineChart from "./layouts/OrderLineChart";
import QuotationLineChart from "./layouts/QuotationLineChart";

import TermineQouteTable from './layouts/TermineQouteTable';
import ProjectBezahltPieChart from './layouts/ProjectBezahltPieChart';
import ProjectTable from './layouts/ProjectTable';
import NewAufgabe from './layouts/NewAufgabe';
import TechnicianSalesStatusPieChart from './layouts/TechnicianSalesStatusPieChart';
import DashboardCalendar from './Calendar';
import TodaysCardList from "./employees-dashboard/component/TodaysCardList";

import BackgroundBlogCard from "examples/Cards/BlogCards/BackgroundBlogCard";
import ivancik from "assets/images/solacloud_icon.png";
import CalendarNew from './CalendarNew';
import TodoList from "./to-do-list/Index";

import UpdateStatusProject from "./employees-dashboard/component/UpdateStatusProjects";

function Index() {
  const { t } = useTranslation();
  const [user, setUser] = useState(JSON.parse(Cookies.get('user')));
  const [technicianSalesTab, setTechnicianSalesTab] = useState('Today');
  const [menu, setMenu] = useState(null);
  const [technicianSalesFilterMenu, setTechnicianSalesFilterMenu] = useState(null);
  const TechnicianSalesFilterOpenMenu = (event) => setTechnicianSalesFilterMenu(event.currentTarget);
  const TechnicianSalesFiltercloseMenu = () => setTechnicianSalesFilterMenu(null);

  const [filterData, setFilterData] = useState({
    verkaufer: 0,
    created_at: 'Current Week',
  });

  return (
    <>
      <Grid container spacing={2}>
        {JSON.parse(Cookies.get('permissions')).indexOf("TodayCL") !== -1 ? (
          <Grid item xs={12} lg={12} xl={12}>
            <UpdateStatusProject />
          </Grid>
        ) : <></>}

        {/*
        <Grid item xs={12} md={6} xl={4}>
          <OrderLineChart />
        </Grid>

        <Grid item xs={12} md={6} xl={4}>
          <QuotationLineChart />
        </Grid>

        {(Cookies.get("user") && JSON.parse(Cookies.get("user")).rolename == "Call Center") ||
          JSON.parse(Cookies.get("user")).rolename == "DC Technician" ? (
          <></>
        ) : (
          <>
            <Grid
              item
              xs={12}
              md={6}
              xl={4}
              style={{ cursor: "pointer" }}
              data-bs-toggle="modal"
              data-bs-target="#newTabModal"
            >
              <PlaceholderCard
                title={{ variant: "h6", text: t("New Tab") }}
                hasBorder
                height="165px"
              />
            </Grid>
          </>
        )}
        */}

        {Cookies.get("permissions").indexOf("SPAnalyAllD") !== -1 || Cookies.get("permissions").indexOf("SPAnaly") !== -1 ? (
          <Grid item xs={12}>
            <div className="d-flex flex-column flex-lg-row gap-3">
              <div
                style={{
                  maxWidth: '355px'
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <BackgroundBlogCard
                      image={ivancik}
                      title={user && user.name ? "Wilkommen,  " + user.name + '!' : ''}
                      description={
                        <>
                          Hier finden Sie alle wichtigen Informationen und Statistiken auf einen Blick. Viel Erfolg bei der Nutzung Ihres Dashboards!
                        </>
                      }
                      action={{
                        type: "popup",
                        targetId: 'newTabModal',
                        label: "Hinzufügen",
                        className: 'btn btn-secondary btn-hover-white btn-plus-icon btn-icon-primary m-0'
                      }}
                    />
                  </Grid>

                  <Grid item xs={12}>

                    <CalendarNew />

                  </Grid>
                </Grid>
              </div>

              <div>
                <Grid container spacing={2}>
                  <Grid item xs={12} lg={12}>
                    <TechnicianSalesStatusPieChart
                      title={technicianSalesTab}
                      filterData={{
                        //created_at: technicianSalesTab
                      }}
                      technicianSalesFilterMenu={technicianSalesFilterMenu}
                      TechnicianSalesFiltercloseMenu={TechnicianSalesFiltercloseMenu}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <TodoList />
                  </Grid>
                </Grid>
              </div>
            </div>
          </Grid>
        ) : <></>}

        {Cookies.get("permissions").indexOf("ProBezPC") !== -1
          || Cookies.get("permissions").indexOf("NeuT") !== -1
          || Cookies.get("permissions").indexOf("ProTab") !== -1
          || Cookies.get("permissions").indexOf("TerQTa") !== -1 ? (
          <Grid item xs={12}>
            <Grid container spacing={2}>
              {Cookies.get("permissions").indexOf("ProTab") !== -1 && (
                <Grid item xs={12} sm={12} lg={12}>
                  <ProjectTable />
                </Grid>
              )}

              {Cookies.get("permissions").indexOf("TerQTa") !== -1 && (
                <Grid item xs={12} sm={12} lg={7}>
                  <TermineQouteTable />
                </Grid>
              )}

              {Cookies.get("permissions").indexOf("ProBezPC") !== -1 || Cookies.get("permissions").indexOf("NeuT") !== -1 ? (
                <Grid item xs={12} sm={12} lg={5}>
                  {Cookies.get("permissions").indexOf("ProBezPC") !== -1 && (
                    <ProjectBezahltPieChart filterData={filterData} />
                  )}

                  {Cookies.get("permissions").indexOf("NeuT") !== -1 && (
                    <>
                      <div className="card mt-3">
                        <NavLink to="/projects-create">
                          <PlaceholderCard
                            title={{ variant: "h6", text: "NeuT" }}
                            hasBorder
                          />
                        </NavLink>
                      </div>
                    </>
                  )}
                </Grid>
              ) : ''}
            </Grid>
          </Grid>
        ) : ''}

        <Grid item xs={12} className="mb-3">
          <DashboardCalendar
            teamsEvent={false}
            readyToLoad={true}
            projects={true}
            assign_orders={true}
            reclamations={true}
            absence={true}
          />
        </Grid>
      </Grid>

      <NewAufgabe />
      {false && JSON.parse(Cookies.get('permissions')).indexOf("TodayCL") !== -1 ? (
        <TodaysCardList />
      ) : <></>}
    </>
  );
}

export default Index;
