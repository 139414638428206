import { React, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Navigate, useParams } from "react-router-dom";
import { callFetch } from "../../../../helpers/callFetch";
import { useTranslation } from "react-i18next";
import SoftEditor from "components/SoftEditor";
import SoftAlert from "components/SoftAlert";
import SoftTypography from "components/SoftTypography";
import SoftSnackbar from "components/SoftSnackbar";
import Cookies from "js-cookie";

function EmailFormInvoice(props) {
  let params = useParams();
  const { t } = useTranslation();
  const [saving, setSaving] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [totalEmails, setTotalEmails] = useState(1);
  const [refresh, setRefresh] = useState(0);
  const [emailText, setEmailText] = useState("");
  const [invoicePercenage, setInvoicePercenage] = useState(0);
  const [id, setId] = useState(0);
  const [subject, setSubject] = useState(
    "Ihr persönliches Angebot der Solacloud"
  );
  const [invoices, setInvoices] = useState([]);
  const [errorSB, setErrorSB] = useState(false);
  const closeErrorSB = () => setErrorSB(false);
  const {
    register,
    handleSubmit,
    setError,
    setValue,
    getValues,
    unregister,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    callFetch("get-invoices/" + props.orderID, "GET", []).then((res) => {
      setInvoices(res.invoices);
    });
  }, [props.orderID]);

  const onSubmit = (formData) => {
    formData.description = emailText;
    formData.subject = subject;
    formData.project_id = props.id;
    if (props?.pdf) {
      callFetch(
        "invoice/" + formData?.type_id + "?pdf_generate",
        "GET",
        []
      ).then((res) => {
        if (res?.message == "success") {
          formData.pdf = true;
          setSaving(true);
          callFetch("sendemail", "POST", formData, setError).then((res) => {
            document.getElementById("modalClose").click();
            setSaving(false);
            if (!res.ok) return;
            setSubmitSuccess(true);
          });
        }
        setSubmitSuccess(true);
      });
    } else {
      formData.pdf = false;
      callFetch("sendemail", "POST", formData, setError).then((res) => {
        document.getElementById("modalClose").click();
        setSaving(false);
        if (!res.ok) return;
        setSubmitSuccess(true);
      });
    }

    /*
        setSaving(true);
        callFetch("sendemail", "POST", formData, setError).then((res) => {
            setSaving(false);
            if (!res.ok) return;
            setSubmitSuccess(true);
        });
        */
  };

  const renderErrorSB = (
    <SoftSnackbar
      color="error"
      icon="info"
      title={t("Create or select invoice first")}
      //content={t("Changes saved successfully")}
      dateTime={t("")}
      open={errorSB}
      onClose={() => closeErrorSB(false)}
      close={() => closeErrorSB(false)}
      bgSuccess
    />
  );

  const beforeSendMail = () => {
    if (id > 0) {
      document.getElementById("emailPopUp").click();
      callFetch(
        "get-smtp-email-configaration/" + "invoice" + invoicePercenage,
        "GET",
        []
      ).then((res) => {
        setEmailText(res?.details?.description);
        setSubject(res?.details?.subject);
      });
    } else {
      setErrorSB(true);
    }
  };

  const handleSelectChange = (event) => {
    const selectedOption = event.target.selectedOptions[0];
    const extraValue = selectedOption.getAttribute("data");
    setId(selectedOption.getAttribute("value"));
    setInvoicePercenage(extraValue);
    // Add any additional logic you need for handling the change event
  };

  const addEmail = () => {
    const total = totalEmails + 1;
    setTotalEmails(total);
  };
  const removeEmail = () => {
    const i = totalEmails - 1;
    const total = i;
    unregister("email[" + i + "]");
    setTotalEmails(total);
  };

  return (
    <>
      <form
        className={`needs-validation ${
          Object.keys(errors).length ? "was-validated" : ""
        }`}
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        autoComplete="off"
      >
        <input type={"hidden"} {...register("id")} value={props?.id} />
        <input type={"hidden"} {...register("pdf")} value={props?.pdf} />
        <input type={"hidden"} {...register("type")} value={"invoice"} />
        <div className="form-group">
          <label>{t("Invoice")} *</label>
          <select
            className="form-control"
            {...register("type_id", {
              required: true,
            })}
            required
            onChange={(event) => handleSelectChange(event)}
          >
            <option value={""}>{t("Choose Invoice")}</option>
            {invoices &&
              invoices.map((inv) => (
                <option data={inv.amountPerchantage} value={inv.id}>
                  {t("Invoice PDF")} {inv.id}{" "}
                </option>
              ))}
          </select>
          <div className="invalid-feedback">
            {errors.status && errors.status.message}
          </div>
        </div>

        {[...Array(totalEmails)].map((x, i) => (
          <div className="form-group" key={i}>
            <label>{t("Email")} *</label>
            <div className="input-group mb-3">
              <input
                type="email"
                {...register("email[" + i + "]")}
                className="form-control"
                placeholder={t("eg.") + " email@mail.com"}
              />

              <div className="input-group-append">
                {totalEmails == i + 1 ? (
                  <span
                    onClick={() => {
                      removeEmail();
                    }}
                    style={{ cursor: "pointer" }}
                    className="input-group-text border-0"
                  >
                    {" "}
                    <i class="ph ph-x font-size-20"></i>
                  </span>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        ))}

        <div className="form-group mb-0">
          <a
            onClick={() => {
              addEmail();
            }}
            className="btn btn-link btn-plus-icon btn-icon-primary p-0"
            style={{ height: "18px" }}
          >
            {" "}
            Hinzufügen
          </a>
        </div>

        {submitSuccess ? (
          <SoftAlert
            color={"success"}
            dismissible
            className="mt-2 dark-alert"
            style={{ fontWeight: "700", fontSize: "0.75rem" }}
          >
            <SoftTypography variant="body2" color="white">
              {t("E-Mail wurde erfolgreich versendet")}
            </SoftTypography>
          </SoftAlert>
        ) : (
          ""
        )}

        <div className="row g-3 mb-3">
          <div className="col-12">
            <input type="submit" id="sendMail" className="d-none" />
            {!saving && (
              <button
                type="button"
                onClick={() => beforeSendMail()}
                className="btn btn-primary mb-0 btn-block w-100"
              >
                {t("Send")}
              </button>
            )}
            {saving && (
              <button
                type="submit"
                className="btn btn-disabled mb-0 btn-block w-100"
                disabled
              >
                {t("Sending ...")}
              </button>
            )}
          </div>
        </div>

        <button
          type="button"
          className="btn btn-primary d-none"
          id="emailPopUp"
          data-bs-toggle="modal"
          data-bs-target="#exampleModal"
        ></button>

        <div
          className="modal fade right-side"
          id="exampleModal"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable modal-dialog-slide-right ">
            <div className="modal-content">
              <div className="modal-body callcenter-area">
                <div className="form-group">
                  <label>{t("Sender")}*</label>
                  <input
                    type="text"
                    readOnly
                    value={"info@solacloud.de"}
                    className="form-control"
                  />
                </div>

                {[...Array(totalEmails)].map((x, i) => (
                  <div className="form-group" key={i}>
                    <label>{t("Empfänger")} *</label>
                    <div className="input-group mb-3">
                      <input
                        type="email"
                        onChange={(e) =>
                          setValue("email[" + i + "]", e.target.value)
                        }
                        defaultValue={getValues("email[" + i + "]")}
                        className="form-control"
                        placeholder={t("eg.") + " email@mail.com"}
                      />
                      <div className="input-group-append">
                        {totalEmails == i + 1 ? (
                          <span
                            onClick={() => {
                              removeEmail();
                            }}
                            style={{ cursor: "pointer" }}
                            className="input-group-text border-0"
                          >
                            {" "}
                            <i class="ph ph-x font-size-20"></i>
                          </span>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>
                ))}

                <div className="form-group">
                  <a
                    onClick={() => {
                      addEmail();
                    }}
                    className="btn btn-link btn-plus-icon btn-icon-primary p-0"
                    style={{ height: "18px" }}
                  >
                    {" "}
                    Hinzufügen
                  </a>
                </div>

                <div className="form-group">
                  <label>{t("Betreff")}*</label>
                  <input
                    type="text"
                    value={subject}
                    onChange={(e) => setSubject(e.target.value)}
                    className="form-control"
                  />
                </div>

                <div>
                  <a
                    target={"_blank"}
                    rel="noreferrer"
                    className="email-attachment-wrap"
                    href={Cookies.get("backend_url") + "invoice/" + id}
                  >
                    {" "}
                    <i class="ph ph-file-text font-size-24 mx-2"></i>{" "}
                    <span className="text-capitalize">{t("Invoice")}</span>
                  </a>
                </div>

                <div className="form-group">
                  <label>{t("E-mail")}*</label>
                  <SoftEditor value={emailText} onChange={setEmailText} />
                </div>
              </div>
              <div className="modal-footer">
                {/* <div style={{position: 'absolute', left: '13px'}}> <a target={"_blank"} rel="noreferrer" href={Cookies.get('backend_url') + "invoice/" + id}>  <svg style={{ height: '36px', width: '18px', position: 'relative', top: '-2px'}} viewBox="0 0 56 54" xmlns="http://www.w3.org/2000/svg" width="2500" height="2411"><path d="M9.9 0h36.2C51.6 0 56 4.4 56 9.9v34.2c0 5.5-4.4 9.9-9.9 9.9H9.9C4.4 54 0 49.6 0 44.1V9.9C0 4.4 4.4 0 9.9 0z" fill="#f53939" /><path d="M45 31.2c-2.6-2.7-9.7-1.6-11.4-1.4-2.5-2.4-4.2-5.3-4.8-6.3.9-2.7 1.5-5.4 1.6-8.3 0-2.5-1-5.2-3.8-5.2-1 0-1.9.6-2.4 1.4-1.2 2.1-.7 6.3 1.2 10.6-1.1 3.1-2.1 6.1-4.9 11.4-2.9 1.2-9 4-9.5 7-.2.9.1 1.8.8 2.5.7.6 1.6.9 2.5.9 3.7 0 7.3-5.1 9.8-9.4 2.1-.7 5.4-1.7 8.7-2.3 3.9 3.4 7.3 3.9 9.1 3.9 2.4 0 3.3-1 3.6-1.9.5-1 .2-2.1-.5-2.9zm-2.5 1.7c-.1.7-1 1.4-2.6 1-1.9-.5-3.6-1.4-5.1-2.6 1.3-.2 4.2-.5 6.3-.1.8.2 1.6.7 1.4 1.7zM25.8 12.3c.2-.3.5-.5.8-.5.9 0 1.1 1.1 1.1 2-.1 2.1-.5 4.2-1.2 6.2-1.5-4-1.2-6.8-.7-7.7zm-.2 19.4c.8-1.6 1.9-4.4 2.3-5.6.9 1.5 2.4 3.3 3.2 4.1 0 .1-3.1.7-5.5 1.5zm-5.9 4c-2.3 3.8-4.7 6.2-6 6.2-.2 0-.4-.1-.6-.2-.3-.2-.4-.5-.3-.9.3-1.4 2.9-3.3 6.9-5.1z" fill="#fff" /></svg> <span style={{color: '#005498'}} className="text-capitalize">{t('Invoice')}</span></a> </div> */}
                <div className="d-flex justify-content-between w-100">
                  <button
                    type="button"
                    className="btn btn-secondary mb-0 w-50  mx-2"
                    id="modalClose"
                    data-bs-dismiss="modal"
                  >
                    {t("Abbrechen")}
                  </button>
                  {!saving && (
                    <button
                      type="submit"
                      onClick={() => {
                        document.getElementById("sendMail").click();
                        setSaving(true);
                      }}
                      className="btn btn-primary mb-0 w-50"
                    >
                      {t("Send")}
                    </button>
                  )}
                  {saving && (
                    <button
                      type="submit"
                      className="btn btn-disabled mb-0 w-50"
                      disabled
                    >
                      {t("Sending ...")}
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      {renderErrorSB}
    </>
  );
}

export default EmailFormInvoice;
