import { React, useEffect, useState } from 'react';
import { NavLink } from "react-router-dom";
import { Navigate, useParams } from "react-router-dom";
import { useCallFetchv2 } from 'helpers/callFetchv2';
import { callFetch } from "../../helpers/callFetch";
import { useTranslation } from 'react-i18next';
import DataTable from 'react-data-table-component';
import SoftTypography from 'components/SoftTypography';
import { useForm } from "react-hook-form";
import SoftSnackbar from "components/SoftSnackbar";
import Select from 'react-select';
import Cookies from 'js-cookie';
import SoftBox from "components/SoftBox"; 
import Swal from 'sweetalert2';
function GoodsOut(props) { 
    const params = useParams();
    const { t } = useTranslation();
    const [articleName, setArticleName] = useState(null);
    const [saving, setSaving] = useState(false);
    const [items, setItems] = useState([]);
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [refresh, setRefresh] = useState(0);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [deliveryNotes, setDeliveryNotes] = useState([]);
    const [quantity, setQuantity] = useState(0);
    const [projectID, setID] = useState(0);
    const [projects, setProjects] = useState([]); 
    const [loading, setLoading] = useState(false);
    const [tabledata, setTableData] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [refresh2, setRefresh2] = useState(0);
    const [selectedProjectId, setSelectedProjectId] = useState({ id: '' });
    const [selectedRows, setSelectedRows] = useState(props.selectedRows);
    const [toggledClearRows, setToggleClearRows] = useState(false);
    

    const {
        register,
        handleSubmit,
        setError,
        setValue,
        getValues,
        reset,
        formState: { errors },
    } = useForm();
  
    useEffect(() => {
        const totalQty = items?.reduce((acc, item) =>{
            return acc + item.qty;
        },0)
        setQuantity(totalQty)
    },[refresh]) 

  
    const handleArticleChange = (e, index) => {
        const articleId = e; 
        if(articleId){
            callFetch("items-by-prefix/"+articleId, "GET", []).then((res)=>{
                if(res.prefix == 'Art'){
                    setArticleName(res?.data?.article_name);
                    if(res?.data){
                        const updatedItems = [...items];
                        updatedItems[index].article_name = res?.data?.article_name;
                        updatedItems[index].article_nr = res?.id;
                        updatedItems[index].prefix = res?.prefix;
                        setItems(updatedItems);

                    }
                }else{ 
                    setArticleName(res?.data?.name);
                    if(res?.data){
                        const updatedItems = [...items];
                        updatedItems[index].article_name = res?.data?.name;
                        updatedItems[index].article_nr = res?.id;
                        updatedItems[index].prefix = res?.prefix;
                        setItems(updatedItems);

                    }
                } 

            })
        }

    };
  
    const [fileInputKey, setFileInputKey] = useState(0);

    const handleFileChange = (e) => {
        const files = Array.from(e.target.files);
        const fileNames = files.map((file) => file.name);
        setSelectedFiles([...selectedFiles, ...fileNames]);
        setFileInputKey(fileInputKey + 1); // Trigger a re-render of the file input
        setDeliveryNotes([...deliveryNotes, ...files]);
      };
    
      const handleRemoveFile = (fileName, index) => {
        const updatedFiles = selectedFiles.filter((name) => name !== fileName);
        setSelectedFiles(updatedFiles);
        setFileInputKey(fileInputKey + 1); // Trigger a re-render of the file input
        const updatedDeliveryNotes = deliveryNotes;
              deliveryNotes.splice(index, 1);
        setDeliveryNotes(updatedDeliveryNotes);
        
      };


      useEffect(()=>{
        if(deliveryNotes){
            const updatedFileList = new DataTransfer();
            deliveryNotes.forEach((file) => {
                updatedFileList.items.add(file);
            });
            setValue('delivery_notes',updatedFileList.files);
        }
      },[handleRemoveFile])

 

    const handleChange = ({ selectedRows }) => {
        // console.log(selectedRows);
        // setSelectedRows(selectedRows);
    };

    const encodedJson = encodeURIComponent(JSON.stringify(props.selectedRows));

  

  
    const [successSB, setSuccessSB] = useState(false);
    const openSuccessSB = () => setSuccessSB(true);
    const closeSuccessSB = () => setSuccessSB(false);
    const renderSuccessSB = (
        <SoftSnackbar
          color="success"
          icon="check"
          title="Success"
          content="Successfully updated"
          dateTime="Just Now"
          open={successSB}
          onClose={closeSuccessSB}
          close={closeSuccessSB}
          bgWhite
        />
      );

      const [errorSB, setErrorSB] = useState(false); 
      const closeErrorSB = () => setErrorSB(false);
  
      const renderErrorSB = (
        <SoftSnackbar
            color="error"
            icon="info"
            title={t('Lieferschein (PDF) ist erforderlich')}
            //content={t("Changes saved successfully")}
            dateTime={t("")}
            open={errorSB}
            onClose={() => closeErrorSB(false)}
            close={() => closeErrorSB(false)}
            bgSuccess
        />
    );

    const loadProjectProduct = (id) => {
        // console.log(props.selectedRows.length);
        const products = JSON.stringify(props.selectedRows);
        const idLength = id.toString().length;
        // console.log(idLength);
        if(idLength > 5){
            setID(id);

            callFetch("get-project-goods-popup/"+id+'/'+products, "GET", [], setError).then((res) => { 
                // console.log(res.data);
                setTableData(res?.data);
                
                if (res?.warenausgang && res?.warenausgang?.items) {
                    for (let [key, value] of Object.entries(res?.warenausgang?.items)) {
                        console.log(value);
                        //setValue(key, (value == null || value == 'null' ? "" : value)); 
                        if(value?.warenausgang != null || value?.warenausgang != 'null'){
                            setValue("versanddatum[" + value?.product_id + "]", (value?.warenausgang == null || value?.warenausgang == 'null' ? "" : value?.warenausgang));
                        }
                    }
                }

            });

            // callFetch("get-project-goods/"+id, "GET", [], setError).then((res) => { 
            //     // console.log(res.data);
            //     setTableData(res?.data);
                
            //     if (res?.warenausgang && res?.warenausgang?.items) {
            //         for (let [key, value] of Object.entries(res?.warenausgang?.items)) {
            //             console.log(value);
            //             //setValue(key, (value == null || value == 'null' ? "" : value)); 
            //             setValue("versanddatum[" + value?.product_id + "]", (value?.warenausgang == null || value?.warenausgang == 'null' ? "" : value?.warenausgang)); 
            //         }
            //     }

            // });
        }
    }
  
  
     const onSubmit = (formData) => { 
 
     }; 
 

    const goodsOutSave = (data, datum) => {
        //console.log(props?.project_id);
        console.log(data);
        // console.log(getValues("versanddatum[" + data?.value + "]"));
        setSaving(true);
        
        const hasValidWarehouses = data.warehouses.some(warehouse => 
            warehouse && typeof warehouse === "object" && Object.keys(warehouse).length > 0
        );
        
        if (hasValidWarehouses) {
            var formData = {};
            // console.log(data);
            formData.article = JSON.stringify(data);
            formData.project_id = projectID;
            formData.warenausgang = getValues("versanddatum[" + data?.value + "]");
            callFetch("goods-out/store", "POST", formData, setError).then((res) => {
                // console.log(res);
                setSaving(false);
                if (!res.ok) return;
                if(res.message === 'success' && params?.id){
                    openSuccessSB();
                }
                setSubmitSuccess(true);
                    
            });
        } else {
            const swalWithBootstrapButtons = Swal.mixin({
                customClass: {
                    confirmButton: 'btn btn-primary me-2',
                    cancelButton: 'btn btn-danger'
                },
                buttonsStyling: false
            });
    
            return swalWithBootstrapButtons.fire({
                text: t("Für dieses Produkt gibt es kein Lagerbestand möchten dennoch fortfahren"),
                // icon: 'warning',
                showCancelButton: true,
                confirmButtonText: t('Yes, do it!'),
                cancelButtonText: t('Cancel')
            }).then((result) => {
                if (result.isConfirmed) {
    
                    var formData = {};
                    // console.log(data);
                    formData.article = JSON.stringify(data);
                    formData.project_id = projectID;
                    formData.warenausgang = getValues("versanddatum[" + data?.value + "]");
                    callFetch("goods-out/store", "POST", formData, setError).then((res) => {
                        // console.log(res);
                        setSaving(false);
                        if (!res.ok) return;
                        if(res.message === 'success' && params?.id){
                            openSuccessSB();
                        }
                        setSubmitSuccess(true);
                            
                    });
    
                }
            });
            
        } 
 
    }
 

    const tableHeadings = [
        {
            name: t('Product Nr.'),
            width: '100px',
            selector: row => <SoftTypography variant="caption" style={{ textDecoration: 'underline', color: 'rgb(0, 84, 152)' }}>{row?.value}</SoftTypography>,
            sortable: false,
        },
        {
            name: t('Description'),
            width: '240px',
            selector: row => <>
                <span title={row?.label}>{row?.label}</span>
            </>,
            sortable: false,
        },
        {
            name: t('Quantity'),
            width: '150px',
            selector: row => (
                <>
                    <input defaultValue={row?.quantity} {...register("qty[" + row?.value + "]", {
                        required: true,
                    })} className='form-control form-control-sm' style={{ width: '60%', float: 'left' }} />
                    <SoftTypography style={{ position: 'relative', top: '8px', left: '5px' }} variant="caption">Stk./m</SoftTypography>
                </>
            ),
            sortable: false,
        },
        {
            name: t('Supplier'),
            width: '100px',
            selector: row => 'Senec AG',
            sortable: false,
        },
        {
            name: t('Kategorie'),
            width: '90px',
            selector: row => <span style={{ textTransform: 'uppercase' }}>{row?.type}</span>,
            sortable: false,
        },
        {
            name: t('Lagerauswahl'),
            width: '200px',
            selector: row => <>
  {/* <label>{t('Lagerauswahl')} *</label> */}
                                                {/* Initialize items[i].data.lager before rendering the select */}
                                                {(() => {
                                                    // Initialize lager if it hasn't been set and there are valid warehouses
                                                    if (!row.lager && row.warehouses?.length > 0) {
                                                        const firstWarehouse = row.warehouses.find(warehouse => warehouse?.warehouse_id);
                                                        if (firstWarehouse) {
                                                            row.lager = `${firstWarehouse.warehouse_id}-${firstWarehouse.storage_area}`;
                                                        }
                                                    }
                                                })()}

                                                <select
                                                    className="form-control"
                                                    style={{width: '160px'}} 
                                                    required
                                                    value={row.lager || ""} 
                                                    onChange={(e) => {
                                                        const updatedRow = { ...row, lager: e.target.value };
                                                        setTableData((prevTableData) =>
                                                            prevTableData.map((dataRow) => (dataRow.identity === updatedRow.identity ? updatedRow : dataRow))
                                                        );
                                                    }}
                                                >
                                                    {row?.warehouses?.length > 0 && row.warehouses.some(warehouse => warehouse?.warehouse_id) ? (
                                                        <>
                                                            <option value="">{t("----")}</option> {/* Placeholder option */}
                                                            {row.warehouses
                                                                .filter(warehouse => warehouse?.warehouse_id) // Filter out invalid warehouses
                                                                .map((warehouse, index) => (
                                                                    <option key={index} value={`${warehouse.warehouse_id}-${warehouse.storage_area}`}>
                                                                        {t(warehouse.warehouse_name) + ' - ' + warehouse.storage_area + ' (' + warehouse.quantity + ')'}
                                                                    </option>
                                                                ))}
                                                        </>
                                                    ) : (
                                                        <option value="">{t("Kein Lagerbestand")}</option> // Show this only when no valid warehouses
                                                    )}
                                                </select>
                                                <div className="invalid-feedback">{errors.warehous && errors.warehous.message}</div>
            </>,
            sortable: false,
        },
        {
            name: t('Warenausgangsdatum'),
            //width: '120px',
            selector: row => (
                <>
                    <input
                        className={'form-control form-control-sm mt-1 '}
                        type='date'
                        placeholder="Select a date"
                        {...register("versanddatum[" + row?.value + "]", {
                            required: true,
                        })}
                    />
                    <div className='my-1'>
                        <a
                            onClick={(e) => {
                                goodsOutSave(row, 'versanddatum');
                            }}
                            className={"btn m-0 btn-sm w-100 " + (getValues("versanddatum[" + row?.value + "]") ? 'btn-ac' : 'btn-primary')}
                            style={{ textTransform: 'capitalize' }}
                        >{t('Bestätigen')}</a>
                    </div>
                </>
            ),
            sortable: false,
        },
         
    ];


    useEffect(() => {
        loadProjectProduct(props.project_id ? props.project_id : 0);
        // setValue('project_nr', props.project_id ? props.project_id : 0);
        // callFetch("get-artikels-projects-employees", "GET", []).then((res) => {
        //     setProjects(res.projects); 
        // });
    }, [props.selectedRows]);

    const handlePageChange = page => {
        setPageNumber(page);
    }

    function toPages(pages) {
        const results = [];

        for (let i = 1; i <= pages; i++) {
            results.push(i);
        }

        return results;
    }

    // RDT exposes the following internal pagination properties
    const BootyPagination = ({
        onChangePage,
        currentPage
    }) => {
        const handleBackButtonClick = () => {
            onChangePage(currentPage - 1);
        };

        const handleNextButtonClick = () => {
            onChangePage(currentPage + 1);
        };

        const handlePageNumber = (e) => {
            onChangePage(Number(e.target.value));
        };

        const pages = tabledata.last_page;
        const pageItems = toPages(pages);
        const nextDisabled = currentPage === tabledata.last_page;
        const previosDisabled = currentPage === 1;

        return (
            <>
                <br />

                <p className="float-md-start pt-2 text-secondary text-xs font-weight-bolder ms-3">{t('Showing')} {tabledata.from} {t('to')} {tabledata.to} {t('of')} {tabledata.total} {t('entries')}</p>
                <nav className="float-md-end me-2">
                    <ul className="pagination">
                        <li className="page-item">
                            <button
                                className="page-link"
                                onClick={handleBackButtonClick}
                                disabled={previosDisabled}
                                aria-disabled={previosDisabled}
                                aria-label="previous page"
                                type='button'
                            >
                                &#60;
                            </button>
                        </li>

                        {tabledata?.links.map((page) => {
                            //const className = (parseInt(page.label) === currentPage ? "page-item active" : "page-item");
                            const className = (page.active === true ? "page-item active" : "page-item");
                            return (
                                <li key={page.label} className={className + ' ' + (parseInt(page.label) || page.label == '...' ? '' : 'd-none')}>
                                    <button
                                        className="page-link"
                                        onClick={page.label != '...' ? handlePageNumber : ''}
                                        value={page.label}
                                        disabled={page.label == '...' ? true : false}
                                        type='button'
                                    >
                                        {page.label}
                                    </button>
                                </li>
                            );
                        })}

                        <li className="page-item">
                            <button
                                className="page-link"
                                onClick={handleNextButtonClick}
                                disabled={nextDisabled}
                                aria-disabled={nextDisabled}
                                aria-label="next page"
                                type='button'
                            >
                                &#62;
                            </button>
                        </li>
                    </ul>
                </nav>
                <div className="clearfix"></div>
            </>
        );
    };

    return (
        <>
            <form className={`needs-validation ${Object.keys(errors).length ? "was-validated" : ""}`} onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off"></form>
            <DataTable
                columns={tableHeadings}
                data={tabledata}
                noDataComponent={loading ? t('Loading...') : t('There are no records to display')}
                className='data-table'
                // pagination
                selectableRows selectableRowsComponentProps={{
                    className: 'table-checkbox form-check-input'
                  }} 
                onSelectedRowsChange={handleChange}
                clearSelectedRows={toggledClearRows}
                highlightOnHover
                subHeader
                selectableRows selectableRowsComponentProps={{
                    className: 'table-checkbox form-check-input'
                  }}elected={() => true}
                subHeaderComponent={
                <>
                    {selectedRows?.length >= 1 &&

                        <div style={{ position: 'absolute', left: '15px', width: '100%'}}>
                            <div className='row'>
                                <div className='col-sm-12' style={{paddingLeft: '0px', paddingRight: '28px', textAlign: 'right'}}>
                                    <a target={"_blank"} rel="noreferrer" href={`${Cookies.get('backend_url')}project-products/${props.project_id}?data=${encodedJson}`}  className="btn btn-secondary ms-2"  style={{ paddingTop: '10px', paddingBottom: '10px' }}><i class="ph ph-file-text font-size-20"></i> <span className="icon-text position-relative top--4">Dokument PDF</span></a>
                                </div>
                            </div>
                        </div>

                    }
                </>
            }
                // paginationComponentOptions={{ noRowsPerPage: true }}
                // paginationServer
                // paginationTotalRows={tabledata?.total}
                // onChangePage={handlePageChange}
                // paginationComponent={BootyPagination}
            />
            <form />
        </>
    )
}

export default GoodsOut; 