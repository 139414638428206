import React from "react";
import { useEffect, useState, useRef } from "react";
import { useNavigate, Navigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import callFetch from "../../helpers/callFetch";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import dateFormat, { masks } from "dateformat";
import Cookies from "js-cookie";
import SoftSnackbar from "components/SoftSnackbar";
import team5 from "assets/images/team-5.jpg";
import SoftAvatar from "components/SoftAvatar";
import SoftInput from "components/SoftInput";
function Comments(props) {
  let params = useParams();
  const { t } = useTranslation();
  const [attachments, setAttachment] = useState([]);
  const [refresh, setRefresh] = useState(0);
  const [data, setData] = useState([]);
  const [saving, setSaving] = useState(false);
  const [displayMessage, setDisplayMessage] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const [messageColor, setMessageColor] = useState("success");
  const [messages, setMessages] = useState([]);
  const [total, setTotal] = useState("");
  useEffect(() => {
    setValue("from_id", props.id);
    setValue("from", props.from);
    callFetch("comments/" + props.from + "/" + props.id, "GET", []).then(
      (res) => {
        console.log(res.comments);

        setData(res.comments);
      }
    );
  }, [props.id]);

  const {
    register,
    handleSubmit,
    reset,
    setError,
    setValue,
    formState: { errors },
  } = useForm();

  const renderdisplayMessage = (
    <SoftSnackbar
      color={""}
      icon=""
      title={t("")}
      //content={t("Changes saved successfully")}
      dateTime={t("")}
      open={displayMessage}
      onClose={() => setDisplayMessage(false)}
      close={() => setDisplayMessage(false)}
      bgSuccess
    />
  );

  const onSubmit = (formData) => {
    setSaving(true);
    callFetch("store-comment", "POST", formData, setError).then((res) => {
      callFetch("comments/" + props.from + "/" + props.id, "GET", []).then(
        (res) => {
          // setMessages(res.data);
          console.log(res);
          setData(res.comments);
          setValue("text", "");
          // setTotal(res.total);
        }
      );
      setSaving(false);
      setSubmitSuccess(true);
    });
  };

  function handleChange(e) {
    // setFile(URL.createObjectURL(e.target.files[0]));
    // setRemove(true);
  }

  return (
    <>
      <div className="layout-wrapper d-lg-flex chat">
        <div
          className="user-chat verflow-hidden border-primary"
          style={{ boxShadow: "unset" }}
        >
          <div className="d-lg-flex">
            {/* Start chat conversation section */}
            <div className="w-100 overflow-hidden position-relative">
              <div className="p-8-12 border-bottom user-chat-topbar">
                <div className="row align-items-center">
                  <div className="col-sm-4 col-10">
                    <div className="d-flex align-items-center">
                      <div className="d-block d-lg-none me-2 ms-0">
                        <a
                          href="#"
                          className="user-chat-remove text-muted font-size-16 p-2"
                        >
                          <i className="ri-arrow-left-s-line"></i>
                        </a>
                      </div>
                      <div className="me-3 ms-0">
                        <img
                          className="avatar avatar-sm"
                          src="/assets/img/placeholder.png"
                          alt=""
                        />
                      </div>
                      <div className="flex-grow-1 overflow-hidden">
                        <h5 className="font-size-14 mb-0 text-truncate font-weight-500">
                          <a href="#0" className="text-reset user-profile-show">
                            User Name
                          </a>
                        </h5>
                        <p className="font-size-12 mb-0">
                          last seen today at 10:30 pm
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-8 col-2">
                    <ul className="list-inline user-chat-nav text-end mb-0">
                      <li className="list-inline-item">
                        <a
                          class="btn-link btn-plus-icon btn-icon-primary"
                          href="#messageBox"
                          onClick={(e) => {
                            e.preventDefault();
                            document.getElementById("messageBox").focus();
                          }}
                        >
                          Nachricht hinzufügen
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              {/* End chat user head */}
              {/* Start chat conversation */}
              <div
                className="chat-conversation p-3"
                style={{ overflow: "auto", height: "400px" }}
              >
                <ul className="list-unstyled mb-0">
                  <li className="right">
                    <div className="conversation-list">
                      <div className="user-chat-content">
                        {/* <div className="ctext-wrap">
                                                  <div className="ctext-wrap-content">
                                                      <p className="mb-0">Message content</p>
                                                      <p className="chat-time mb-0">
                                                          <i className="ri-time-line align-middle"></i>
                                                          <span className="align-middle">3 minutes ago</span>
                                                      </p>
                                                  </div> 
                                              </div> */}
                        <div className="chat-container">
                          <div className="chat-box">
                            <div className="messages">
                              {data &&
                                data.map((comment, key) => (
                                  <div
                                    className={`row mb-3 ${"right"}`}
                                    key={key}
                                  >
                                    <div
                                      className="col-auto-custom"
                                      style={{ width: "100%" }}
                                    >
                                      <div
                                        className="border-radius-8"
                                        style={{ backgroundColor: "#ECF5FF" }}
                                      >
                                        <div className=" p-2">
                                          <p
                                            style={{
                                              fontSize: "0.875rem",
                                              color: "#344767",
                                              paddingBottom: "8px",
                                            }}
                                          >
                                            Notiz erstellt von{" "}
                                            <strong>
                                              {comment?.user?.name}
                                            </strong>
                                          </p>
                                        </div>
                                        <div className=" py-2 px-2">
                                          <p
                                            className="mb-1"
                                            style={{
                                              fontSize: "0.875rem",
                                              lineHeight: "1.3em",
                                              color: "#344767",
                                            }}
                                          >
                                            {comment.text}
                                          </p>
                                          <div className="d-flex align-items-center text-sm opacity-6">
                                            <i className="ni ni-check-bold text-sm me-1"></i>
                                            <small>
                                              {dateFormat(
                                                comment?.created_at,
                                                "dd.mm.yyyy, HH:MM"
                                              )}
                                            </small>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ))}

                              {data?.length ? null : (
                                <p
                                  className="text-center"
                                  style={{ lineHeight: "300px" }}
                                >
                                  {t("Keine Nachrichten vorhanden")}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              {/* End chat conversation */}
              {/* Start chat input section */}
              <form onSubmit={handleSubmit(onSubmit)}>
                <input type="hidden" {...register("from_id")} />
                <div className="message-box">
                  <div
                    className="me-3"
                    style={{
                      position: "relative",
                      display: "flex",
                      cursor: "pointer",
                    }}
                  >
                    <input
                      {...register("attachment")}
                      onChange={handleChange}
                      type="file"
                      className=""
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        opacity: 0,
                        cursor: "pointer",
                      }}
                    />
                    <img
                      src="../../assets/icons/Paperclip.svg"
                      alt="Attach File"
                    />

                    {/* {remove ? (
                      <>
                        <img
                          src={file}
                          style={{
                            height: "38px",
                            width: "100%",
                            borderRadius: "5px",
                          }}
                          alt=""
                        />{" "}
                        <span
                          title="Remove Attachment"
                          style={{
                            fontSize: "12px",
                            right: "-8px",
                            top: "-8px",
                            position: "absolute",
                            zIndex: "999",
                          }}
                        >
                          <i
                            style={{
                              background: "#fff",
                              padding: "3px",
                              borderRadius: "8px",
                              color: "red",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setRemove(false);
                              setFile("");
                              setValue("attachment", "");
                            }}
                            className="fa fa-remove"
                            aria-hidden="true"
                          ></i>
                        </span>
                      </>
                    ) : (
                      ""
                    )} */}
                  </div>
                  <input
                    {...register("text")}
                    id="messageBox"
                    type="text"
                    className="input-field"
                    placeholder="Geben Sie Ihre Nachricht ein"
                  />
                  <button className="send-btn">
                    <img src="../../assets/icons/PaperPlaneRight.svg" />
                  </button>
                </div>
              </form>
              {/* End chat input section */}
            </div>
            {/* End chat conversation section */}
            {/* Start User profile detail sidebar */}
            <div className="user-profile-sidebar">
              <div className="px-3 px-lg-4 pt-3 pt-lg-4">
                <div className="user-chat-nav text-end">
                  <button type="button" className="btn nav-btn">
                    <i className="ri-close-line"></i>
                  </button>
                </div>
              </div>
              <div className="text-center p-4 border-bottom">
                <div className="mb-4">
                  <img
                    src="assets/images/users/avatar-4.jpg"
                    className="rounded-circle avatar-lg img-thumbnail"
                    alt=""
                  />
                </div>
                <h5 className="font-size-16 mb-1 text-truncate">Doris Brown</h5>
                <p className="text-muted text-truncate mb-1">
                  <i className="ri-record-circle-fill font-size-10 text-success me-1 ms-0"></i>{" "}
                  Active
                </p>
              </div>
              <div className="p-4 user-profile-desc">
                <div className="text-muted">
                  <p className="mb-4">If several languages coalesce...</p>
                </div>
              </div>
            </div>
            {/* End User profile detail sidebar */}
          </div>
        </div>
      </div>
    </>
  );
}

export default Comments;
