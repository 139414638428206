import { React, useEffect, useState } from 'react';
import { NavLink } from "react-router-dom";
import callFetch from "../../../../../helpers/callFetch";
import { useTranslation } from 'react-i18next';
import DataTable from 'react-data-table-component';
import deleteAlert from "../../../../../helpers/deleteAlert";
import Cookies from 'js-cookie';
import { useParams } from "react-router-dom";
import dateFormat from "dateformat";
import { useForm } from "react-hook-form";
import Checkbox from "@mui/material/Checkbox";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

function IndexTable(props) {
    let params = useParams();
    const { t } = useTranslation();
    const [data, setData] = useState([]);
    const [tabledata, setTableData] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [loading, setLoading] = useState(false);
    const [refresh, setRefresh] = useState(0);

    // useEffect(() => {
    //     if (!props?.projectId) return;
    //     callFetch("activity?project_id=" + props?.projectId, "GET", []).then((res) => {
    //         console.log(res?.data);
    //         setData(res?.data);
    //     });
   
    // }, [props?.projectId]);


    const activityTitelGenerate = (activity) => {
        if (!activity) return t('No activity available');
        const replaceTypeWithOperation = {
            update_quation: 'aktualisiert angebot ' + activity?.description,
            status: 'aktualisiert ' + activity?.related + ' status ' + activity?.related_id,
            create: 'erstellt ' + activity?.related + ' ' + activity?.related_id,
            update: 'aktualisiert ' + activity?.related + ' ' + activity?.related_id,
            resale_status: 'aktualisiert '+activity?.related+' resale status ' + activity?.related_id,
            dc_approved: 'DC Approved',
            ac_approve: 'AC Approved',
            reclamation_dc_approved: 'reclamation dc approved '+ activity?.related_id,
            comisioning_status: 'aktualisiert '+activity?.related+' comisioning status ' + activity?.related_id,
            ac_reklmation_abnahme_create: 'erstellt AC reklmation abnahme ' + activity?.related_id,
            ac_reklmation_abnahme_update: 'aktualisiert AC reklmation abnahme ' + activity?.related_id,
            ac_abnahme_create: 'erstellt AC abnahme ',
            dc_abnahme_create: 'erstellt DC abnahme ',
            dc_reklmation_abnahme_create: 'erstellt DC reklmation abnahme ' + activity?.related_id,
            dc_reklmation_abnahme_update: 'aktualisiert DC reklmation abnahme ' + activity?.related_id,
            dc_abnahme_update: 'aktualisiert DC abnahme ',
            ac_beschaffung_approve: 'AC Beschaffung Fregegeben',
            dc_beschaffung_approve: 'DC Beschaffung Fregegeben',
        };
        return replaceTypeWithOperation[activity?.type] || t('Unknown activity');
    }; 

    const tableHeadings = [
        {
            name: t('Aktivitat'),
            width: '70%',
            selector: row => (
                <>
                    <span className="font-weight-bold">{row?.user?.name} </span>
                    {activityTitelGenerate(row)}
                </>
            ),
        },
        {
            name: t('Benutzer'),
            selector: row => <img src={'/assets/img/placeholder.png'} style={{ border: '0.125rem solid rgb(4, 75, 178)', borderRadius: '50%' }} class="avatar avatar-sm me-3 " alt="logo spotify" />,
            sortable: true,
        },
        {
            name: t('Date'),
            selector: row => (
                <span style={{ color: '#718096', fontWeight: '500' }}>{dateFormat(row.created_at, "dd.mm.yyyy")}</span>
            )
        }
    ]; 

    useEffect(() => {
        setLoading(true);
        callFetch("activity?project_id=" + props?.projectId, "GET", []).then((res) => {
            console.log(res?.data);
            // setData(res?.data);
            setTableData(res);
        });
    }, [pageNumber, refresh, props?.refresh, props?.projectId]);

    const handlePageChange = page => {
        setPageNumber(page);
    }

    function toPages(pages) {
        const results = [];

        for (let i = 1; i <= pages; i++) {
            results.push(i);
        }

        return results;
    }

    // RDT exposes the following internal pagination properties
    const BootyPagination = ({
        onChangePage,
        currentPage
    }) => {
        const handleBackButtonClick = () => {
            onChangePage(currentPage - 1);
        };

        const handleNextButtonClick = () => {
            onChangePage(currentPage + 1);
        };

        const handlePageNumber = (e) => {
            onChangePage(Number(e.target.value));
        };

        const pages = tabledata.last_page;
        const pageItems = toPages(pages);
        const nextDisabled = currentPage === tabledata.last_page;
        const previosDisabled = currentPage === 1;

        return (
            <>
                {/* <div className='table-pagination d-flex justify-content-between align-items-center pb-0'>
                    <p className="entries">{tabledata.from} - {tabledata.to} {t('of')} {tabledata.total} {t('entries')}</p>
                    <ul className="pagination ">
                        <li className="page-item">
                            <button
                                className="page-link"
                                onClick={handleBackButtonClick}
                                disabled={previosDisabled}
                                aria-disabled={previosDisabled}
                                aria-label="previous page"
                            >
                                <i className='ph ph-caret-double-left text-primary text-xl'></i>
                            </button>
                        </li>

                        <li className="page-item">
                            <button
                                className="page-link"
                                onClick={handleBackButtonClick}
                                disabled={previosDisabled}
                                aria-disabled={previosDisabled}
                                aria-label="previous page"
                            >
                                <i className='ph ph-caret-left text-primary text-xl'></i>
                            </button>
                        </li>

                        {pageItems?.map((page) => {
                            //const className = (parseInt(page.label) === currentPage ? "page-item active" : "page-item");
                            const className = (page.active === true ? "page-item active" : "page-item");
                            return (
                                <li key={page.label} className={className + ' ' + (parseInt(page.label) || page.label == '...' ? '' : 'd-none')}>
                                    <button
                                        className="page-link"
                                        onClick={page.label != '...' ? handlePageNumber : ''}
                                        value={page.label}
                                        disabled={page.label == '...' ? true : false}
                                    >
                                        {page.label}
                                    </button>
                                </li>
                            );
                        })}

                        <li className="page-item">
                            <button
                                className="page-link"
                                onClick={handleNextButtonClick}
                                disabled={nextDisabled}
                                aria-disabled={nextDisabled}
                                aria-label="next page"
                            >
                                <i className='ph ph-caret-right text-primary text-xl'></i>
                            </button>
                        </li>

                        <li className="page-item">
                            <button
                                className="page-link"
                                onClick={handleNextButtonClick}
                                disabled={nextDisabled}
                                aria-disabled={nextDisabled}
                                aria-label="next page"
                            >
                                <i className='ph ph-caret-double-right text-primary text-xl'></i>
                            </button>
                        </li>
                    </ul>
                </div> */}
            </>
        );
    };

    return <DataTable
        columns={tableHeadings}
        data={tabledata?.data}
        noDataComponent={
            <div className="d-flex align-items-center justify-content-center py-3" style={{ height: "100%" }}>
                          <div className="text-center">
                              <i className='ph ph-notepad data-table-no-record-icon'></i>
                              <p className="text-secondary text-md m-0">{t('There are no records to display')}</p>
                          </div>
                      </div>
          }
        className='data-table'
        pagination
        highlightOnHover
        paginationComponentOptions={{ noRowsPerPage: true }}
        paginationServer
        paginationTotalRows={tabledata?.total}
        onChangePage={handlePageChange}
        paginationComponent={BootyPagination}
    />;
}

export default IndexTable;
