import { React, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from 'react-i18next';
import "./style.css"
import * as XLSX from 'xlsx';
import callFetch from "helpers/callFetch";
import SoftTypography from "components/SoftTypography";
import SoftAlert from "components/SoftAlert";
import OutputModal from './OutputModal';
import Cookies from 'js-cookie';

import dataXlsxProject from "./dataXlsxProject.xlsx"
import dataXlsxEmployee from "./dataXlsxEmployee.xlsx"
import dataXlsxProjectWithSales from "./dataXlsxProjectWithSales.xlsx"
import dataXlsxBundle from "./dataXlsxBundle.xlsx"

import FileUploader from "components/FileUploader";

// sweetalert2 components
import Swal from "sweetalert2";

const ImportModal = (props) => {
    const { t } = useTranslation();
    const [saving, setSaving] = useState(false);
    const [refresh, setRefresh] = useState(0);
    const [fileData, setFileData] = useState('');
    const [type, setType] = useState(props?.type);
    const [fileName, setFileName] = useState(t('Files Supported') + ": XLSX");
    const [success, setSuccess] = useState(null);
    const [data, setData] = useState([]);

    const {
        register,
        handleSubmit,
        setError,
        setValue,
        formState: { errors },
        reset,
        clearErrors,
    } = useForm();
    const ReadExcel = (file) => {
        if (!file?.name) return;
        setFileData(file)
        setFileName(file.name);
    }


    //let timerInterval;
    const showAlert = () => {
        Swal.fire({
            title: t("Ladevorgang"),
            //html: "I will close in <b></b> milliseconds.",
            didOpen: () => {
                Swal.showLoading();
            },
        }).then((result) => {
            /* Read more about handling dismissals below */
            /*
            if (result.dismiss === Swal.DismissReason.timer) {
                console.log("I was closed by the timer");
            }
            */
        });
    }

    const onSubmit = (formData) => {
        setSaving(true);
        formData.Files = fileData;
        formData.type = type;
        callFetch("import", "POST", formData, setError).then((res) => {

            if (res.message == 'success') {
                setData(res.data);
                document.getElementById(type + 'import-button-close').click();
                document.getElementById(type + 'outputModal-show-button').click();
            }

            setRefresh(refresh + 1);
            props.refreshParent();

            setSaving(false);
            Swal.close();
        })
    };

    return (
        <>
            {JSON.parse(Cookies.get('permissions')).indexOf("Import") !== -1 ? (
                <>
                    <div className="modal fade" id={type + "importModal"} tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered modal-md" role="document">
                            <div className="modal-content">
                                <form className={`needs-validation ${Object.keys(errors).length ? "was-validated" : ""}`} onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
                                    <div className="modal-header">
                                        <h5 className="modal-title" id={type + "importModalLabel"}>{t('Import')}</h5>
                                        <button
                                            type="button"
                                            onClick={() => { 
                                                setSuccess(null); 
                                                setFileName(null); 
                                                setValue("Files", "")
                                                clearErrors();
                                             }}
                                            className="btn-close text-dark"
                                            id={type + "import-button-close"}
                                            data-bs-dismiss="modal" aria-label="Close"
                                        ></button>
                                    </div>
                                    <div className="modal-body">
                                        <p className="text-link text-secondary mb-3">{t('Before import your file please download')}
                                            <a
                                                className="text-brand-primary text-md font-weight-600 ms-3"
                                                download={type}
                                                href={
                                                    type == 'project' ? dataXlsxProject
                                                        : type == 'employee' ? dataXlsxEmployee
                                                            : type == 'projectWithSales' ? dataXlsxProjectWithSales
                                                                : type == 'bundle' ? dataXlsxBundle
                                                                    : ''
                                                }
                                            >XLSX</a></p>



                                        {
                                            success?.status === 200 && <SoftAlert icon="none" color="success" dismissible>
                                                {success?.success}
                                            </SoftAlert>
                                        }

                                        {
                                            success?.status === 400 && <SoftAlert icon="none" color="warning" dismissible>
                                                {success?.success}
                                            </SoftAlert>
                                        }

                                        <FileUploader
                                            supportedFileType={['xlsx', 'csv']}
                                            maxFileSizeMB={10}//Mb 
                                            multiple={false}
                                            onChange={(files, type) => {
                                                if (type === 'files') {
                                                    //setSelectedFile(files)
                                                    clearErrors();
                                                    ReadExcel(files[0]);
                                                }
                                            }}
                                            previewTitle={"Heruntergeladene Dateien"}
                                            onlyUploader={true}
                                            id={type + "fileID"}
                                        />

                                        {/*
                                        <div className="drop_box">
                                            <header>
                                                <SoftTypography variant="h4" color="text" >{t('Select File here')}</SoftTypography>
                                    </header>
                                    {
                                        fileName ? <p style={{ color: "#0909f3" }}>{fileName}</p> : <p>{t('Files Supported')}: CSV</p>
                                    }


                                    <input 
                                    type="file" 
                                    accept=".xlsx,.csv" 
                                    id={type + "fileID"} 
                                    style={{ display: "none" }} 
                                    {...register("Files", { require: true })} 
                                    required 
                                    onChange={(e) => ReadExcel(e.target.files[0])} 
                                    />
                                    <label htmlFor={type + "fileID"} className="btn">{t('Choose File')}</label>
                            </div>
                                        */}
                                    </div>
                                    <div className="modal-footer">
                                        <button
                                            type="button"
                                            onClick={() => {
                                                setSuccess(null);
                                                setFileName(null);
                                                setValue("Files", "")
                                            }}
                                            className="btn btn-secondary m-0 me-2"
                                            data-bs-dismiss="modal"
                                        >{t('Close')}</button>
                                        {!saving ? (
                                            <button type="submit" className="btn btn-primary m-0">
                                                {t('Save')}
                                            </button>
                                        ) : (
                                            <button type="button" className="btn btn-disabled m-0" disabled>
                                                {t('Saving ...')}
                                            </button>
                                        )}
                                    </div>
                                </form>
                            </div>
                        </div >
                    </div >

                    <OutputModal filename={data} type={type} />
                </>
            ) : <></>}
        </>
    )
}

export default ImportModal