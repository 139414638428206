import { useMemo, useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import dateFormat, { masks } from "dateformat";
import { useForm } from "react-hook-form";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftSnackbar from "components/SoftSnackbar";

import Calendar from "examples/Calendar";
import { Navigate, NavLink, useNavigate, withRouter } from "react-router-dom";
// Data
import Cookies from "js-cookie";
// Images
import callFetch from "../../helpers/callFetch";

import AbsenceRequest from "../absenceRequest/Create";
import AbsenceRequestEdit from "../absenceRequest/Edit";

import ProjectStatus from 'pages/project/ProjectStatusRightSide';
import NewAufgabe from './layouts/NewAufgabe';
import EditPlan from "pages/reports/EditPlan";
import allLocales from '@fullcalendar/core/locales-all';
import SoftBadge from "components/SoftBadge";
import { textTransform } from "@mui/system";

function Index(props) {
    const { t } = useTranslation();
    const [attachments, setAttachment] = useState([]);
    const [calendarEventsData, setCalendarEventsData] = useState([]);
    const [initialDate, setInitialDate] = useState(dateFormat(new Date(), "yyyy-mm-dd"));
    const [modal, setModal] = useState(false);
    const [modalData, setModalData] = useState([]);
    const [saving, setSaving] = useState(false);
    const [employees, setEmployees] = useState([]);
    const [serach, setSearch] = useState('');
    const [refresh, setRefresh] = useState(0);
    const [refresh2, setRefresh2] = useState(0);
    const [absenceId, setAbsenceId] = useState(0);
    const [refreshAbsemce, setRefreshAbsemce] = useState(0);
    const [reloadCalendarData, setReloadCalendarData] = useState(0);

    const [isUpdate, setIsUpdate] = useState(false);
    const [planning, setPlanning] = useState([]);
    const [editEvent, setEditEvent] = useState(false);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [abhnameStatus, setAbhnameStatus] = useState('');
    const [year, setYear] = useState('');
    const [day, setDay] = useState('');
    const [month, setMonth] = useState('');
    const [week, setWeek] = useState('');

    const [tooltipInfo, setTooltipInfo] = useState(null);









    const {
        register,
        handleSubmit,
        setError,
        setValue,
        getValues,
        formState: { errors },
    } = useForm();
    const toggle = () => {
        setModal(modal);
    };

    const handleEventDrop = (event) => {
        if (event.event?._def?.extendedProps?.absence) {
            console.log("absence can't update");
            //setReloadCalendarData(reloadCalendarData+1);
        } else {
            var start_date = new Date(event.event._instance.range.start);
            var end_date = new Date(event.event._instance.range.end);

            /*
            var month = Number(date.getMonth()) + 1;
            var date_day = Number(date.getDate());
            if (month <= 9) {
                month = "0" + month;
            }
            if (date_day <= 9) {
                date_day = "0" + date_day;
            }
            */

            var formData = new FormData();
            formData.id = event.event._def.publicId;
            //formData.date = date.getFullYear() + "-" + month + "-" + date_day;
            formData.start_date = start_date.toISOString();
            formData.end_date = end_date.toISOString();
            //formData.color = event.event._def.ui.backgroundColor;
            formData.event_id = (event?.event?.extendedProps?.event_id ? event?.event?.extendedProps?.event_id : event.event._def.publicId);
            formData.plan_form = event?.event?.extendedProps?.event_type;

            callFetch("update-event-date", "POST", formData, setError).then((res) => {
                //console.log(res.message);
                setSaving(false);
                if (!res.ok) return;

                if (editEvent) {

                    var data = {
                        id: event?.event?.id,
                        project_nr: event.event._def.extendedProps.project_nr,
                        title: event.event._def.extendedProps.event_titel,
                        action: "edit_plan",
                        planform: (event.event._def.extendedProps.event_type == 'order_processing' ? "Projects" : event.event._def.extendedProps.event_type == 'ACFertigmeldung' ? 'ACFertigmeldung' : "Reclamation"),
                    };

                    setPlanning(data);
                    setRefresh2(refresh2 + 1);
                    document.getElementById('triggerEditPlanBtn').click();

                }
            });
        }
    };

    const getWeekNumber = (date) => {
        var d = new Date(date);
        d.setHours(0, 0, 0, 0);
        d.setDate(d.getDate() + 4 - (d.getDay() || 7));
        var yearStart = new Date(d.getFullYear(), 0, 1);
        var weekNo = Math.ceil((((d - yearStart) / 86400000) + 1) / 7);
        return weekNo;
    }

    const handleEventClick = ({ event, el }) => {

        //console.log('event?._def?.extendedProps');
        //console.log(event?._def?.extendedProps);

        if (event?._def?.extendedProps?.absence) {
            if (Cookies.get("user") && JSON.parse(Cookies.get("user")).rolename != "Call Center") {
                setRefreshAbsemce(refreshAbsemce + 1);
                setAbsenceId(event?.id);
                document.getElementById("absenceRequestEditBtn").click();
            }
        } else {

            if (event?._def?.extendedProps?.event_type == 'project') {
                if (document.getElementById("home1-tab")) {
                    document.getElementById("home1-tab").click();
                }

                callFetch("project-event/" + event.id, "GET", []).then((res) => {
                    //setEmployees(res.employees);

                    for (let [key, value] of Object.entries(res.data)) {
                        if (key == 'file') {
                            if (value != null && value != "null") {
                                setAttachment(JSON.parse(value));
                                setValue("file", value);
                            } else {
                                setAttachment([]);
                            }
                        } else {
                            setValue(key, (value == null || value == 'null' ? "" : value));
                        }
                    }

                    setModalData({
                        title: res.data.vorname + " " + res.data.name,
                        id: event.id,
                        project_id: event.id,
                        salse_person: res?.data?.salse_person?.name ? res?.data?.salse_person?.name : '',
                        name: res.data.name,
                        plz: res.data.plz,
                        telefonnummer: res.data.telefonnummer,
                        desc: res.data.description,
                        start: res.data.time,
                        end_time: res.data.end_time,
                        status: res.data.status,
                        event_type: event?._def?.extendedProps?.event_type,
                    });

                    setIsUpdate(false);
                    document.getElementById("calanderDataOpen").click();

                });
            } else if (event?._def?.extendedProps?.event_type == 'order_processing') {

                if (document.getElementById("home2-tab")) {
                    document.getElementById("home2-tab").click();
                }

                callFetch("event-order-processing/" + event.id, "GET", []).then((res) => {
                    //setEmployees(res.employees);

                    for (let [key, value] of Object.entries(res.data)) {
                        if (key == 'file') {
                            if (value != null && value != "null") {
                                setAttachment(JSON.parse(value));
                                setValue("file", value);
                            } else {
                                setAttachment([]);
                            }
                        } else {
                            setValue(key, (value == null || value == 'null' ? "" : value));
                        }
                    }

                    setModalData({
                        title: res.data.name,
                        id: event.id,
                        project_id: res.data.project_id,
                        salse_person: res?.data?.salse_person_name ? res?.data?.salse_person_name : '',
                        name: res.data.name,
                        plz: res.data.plz,
                        telefonnummer: res.data.telefonnummer,
                        desc: res.data.description,
                        start: res.data.time,
                        end_time: res.data.end_time,
                        status: res.data.status,
                        modul: (res.data?.anzahl_module + ' Stck. | ' + (res.data?.module_eingeben == 'no' ? res.data?.module_short_title : res.data?.custom_module_short_title)),
                        speicher: (res.data?.speicher_eingeben == 'no' ? res.data?.speicher_name : res.data?.custom_speicher_name),
                        dachtyp: res.data?.dachtyp,
                        event_type: event?._def?.extendedProps?.event_type,
                    });
                    setIsUpdate(false);
                    document.getElementById("calanderDataOpen").click();
                });
            } else if (event?._def?.extendedProps?.event_type == 'reclamation') {

                if (document.getElementById("home2-tab")) {
                    document.getElementById("home2-tab").click();
                }

                callFetch("event-reclamation/" + event.id, "GET", []).then((res) => {
                    //setEmployees(res.employees);

                    for (let [key, value] of Object.entries(res.data)) {
                        if (key == 'file') {
                            if (value != null && value != "null") {
                                setAttachment(JSON.parse(value));
                                setValue("file", value);
                            } else {
                                setAttachment([]);
                            }
                        } else {
                            setValue(key, (value == null || value == 'null' ? "" : value));
                        }
                    }

                    setModalData({
                        title: res.data.name,
                        id: event.id,
                        project_id: res.data.project_id,
                        reclamation_id: res?.data?.reclamation_id ? res?.data?.reclamation_id : '',
                        reclamation_description: res?.data?.reclamation?.description ? res?.data?.reclamation?.description : '',
                        salse_person: res?.data?.salse_person_name ? res.data.salse_person_name : '',
                        name: res.data.name,
                        plz: res.data.plz,
                        telefonnummer: res.data.telefonnummer,
                        desc: res.data.description,
                        start: res.data.time,
                        end_time: res.data.end_time,
                        status: res.data.status,
                        products: (res?.data?.reclamation?.products ? JSON.parse(res?.data?.reclamation?.products) : []),
                        //modul: (res.data?.anzahl_module + ' Stck. | ' + (res.data?.module_eingeben == 'no' ? res.data?.module_short_title : res.data?.custom_module_short_title)),
                        //speicher: (res.data?.speicher_eingeben == 'no' ? res.data?.speicher_name : res.data?.custom_speicher_name),
                        dachtyp: '',
                        event_type: event?._def?.extendedProps?.event_type,
                    });
                    setIsUpdate(false);
                    document.getElementById("calanderDataOpen").click();
                });
            } else if (event?._def?.extendedProps?.event_type == 'ACFertigmeldung') {

                if (document.getElementById("home2-tab")) {
                    document.getElementById("home2-tab").click();
                }

                callFetch("event-zahlertermin/" + event.id, "GET", []).then((res) => {
                    //setEmployees(res.employees);

                    for (let [key, value] of Object.entries(res.data)) {
                        if (key == 'file') {
                            if (value != null && value != "null") {
                                setAttachment(JSON.parse(value));
                                setValue("file", value);
                            } else {
                                setAttachment([]);
                            }
                        } else {
                            setValue(key, (value == null || value == 'null' ? "" : value));
                        }
                    }

                    setModalData({
                        title: res.data.name,
                        id: event.id,
                        project_id: res.data.project_id,
                        salse_person: res?.data?.salse_person_name ? res?.data?.salse_person_name : '',
                        name: res.data.name,
                        plz: res.data.plz,
                        telefonnummer: res.data.telefonnummer,
                        desc: res.data.description,
                        start: res.data.time,
                        end_time: res.data.end_time,
                        status: res.data.status,
                        modul: (res.data?.anzahl_module + ' Stck. | ' + (res.data?.module_eingeben == 'no' ? res.data?.module_short_title : res.data?.custom_module_short_title)),
                        speicher: (res.data?.speicher_eingeben == 'no' ? res.data?.speicher_name : res.data?.custom_speicher_name),
                        dachtyp: res.data?.dachtyp,
                        event_type: event?._def?.extendedProps?.event_type,
                    });
                    setIsUpdate(false);
                    document.getElementById("calanderDataOpen").click();
                });
            }
        }
    };

    useEffect(() => {

        if (!props?.readyToLoad) return;
        if (!startDate) return;
        if (!endDate) return;

        var fromData = {};
        fromData.serach = serach;
        fromData.projects = (props?.projects && props?.projects == true ? 1 : 0);
        fromData.assign_orders = (props?.assign_orders && props?.assign_orders == true ? 1 : 0);
        fromData.reclamations = (props?.reclamations && props?.reclamations == true ? 1 : 0);
        fromData.absence = (props?.absence && props?.absence == true ? 1 : 0);
        fromData.startDate = startDate;
        fromData.endDate = endDate;

        if (props?.teamsEvent == true) {
            fromData.teams = JSON.stringify(props?.teams);
        }

        if (props?.teamMembers) {
            fromData.teamMembers = JSON.stringify(props?.teamMembers);
        }

        if (abhnameStatus) {
            fromData.abhnameStatus = abhnameStatus;
        }

        if (props?.editEvent == true) {
            setEditEvent(true);
        }

        var calendar = props.class == 'calendar-main' ? '?calendar=main' : '';

        //setCalendarEventsData([]);
        callFetch("projects/calanderdata" + calendar, "POST", fromData).then((res) => {
            setCalendarEventsData(res.data);
            setInitialDate(res.initialDate);
        });
    }, [reloadCalendarData, isUpdate, serach, refresh, startDate, endDate, abhnameStatus, props?.refresh, props?.teams, props?.teamsEvent, props?.teamMembers, props?.readyToLoad, props?.editEvent]);


    const [successSB, setSuccessSB] = useState(false);
    const openSuccessSB = () => setSuccessSB(true);
    const closeSuccessSB = () => setSuccessSB(false);
    const renderSuccessSB = (
        <SoftSnackbar
            color="success"
            icon="check"
            title="Success"
            content="Changes saved successfully"
            dateTime="Just Now"
            open={successSB}
            onClose={closeSuccessSB}
            close={closeSuccessSB}
            bgWhite
        />
    );

    const onSubmit = (formData) => {

        console.log(formData);
        return;
        setSaving(true);
        if (modalData?.event_type == 'order_processing') {
            //console.log(getValues('event_id'));
            callFetch(
                "update-order-event/" + modalData.id,
                "POST",
                formData,
                setError
            ).then((res) => {
                //openSuccessSB();
                setSaving(false);
                setRefresh(refresh + 1);

                if (!res.ok) return;
                var eventModalClose = document.getElementById("projectInfoModalClose");
                eventModalClose.click();
            });
        } else if (modalData?.event_type == 'ACFertigmeldung') {
            //console.log(getValues('event_id'));
            callFetch(
                "update-zahlertermin/" + modalData.id,
                "POST",
                formData,
                setError
            ).then((res) => {
                //openSuccessSB();
                setSaving(false);
                setRefresh(refresh + 1);

                if (!res.ok) return;
                var eventModalClose = document.getElementById("projectInfoModalClose");
                eventModalClose.click();
            });
        } else if (modalData?.event_type == 'project') {
            callFetch(
                "update-project-event/" + modalData.id,
                "POST",
                formData,
                setError
            ).then((res) => {
                //openSuccessSB();
                setSaving(false);
                setRefresh(refresh + 1);

                if (!res.ok) return;
                var eventModalClose = document.getElementById("projectInfoModalClose");
                eventModalClose.click();
            });
        } else {
            console.log('Reclamation update')
            setSaving(false);
        }
    };

    let timer;
    const waitTime = 2000;

    const getAbhnamheStatus = (status) => {
        setAbhnameStatus(status);
    }

    const searchKey = (key) => {


        clearTimeout(timer);

        timer = setTimeout(() => {
            setSearch(key);
        }, waitTime);

        /*
        if (key.length > 0) {
            callFetch("calendardata/search/" + key, "GET", []).then((res) => {
                setCalendarEventsData(res.data);
            });
        } else {
            setIsUpdate(false);
            setSearch(serach + 1);
        }
        */
    };
    
    /*
    const handleEventMouseEnter = (arg) => {
        if (arg?.event?._def?.extendedProps?.event_type === 'absence') return;

        setTooltipInfo({
            data: arg.event,
            top: arg.jsEvent.clientY + 10,
            left: arg.jsEvent.clientX,
        });
    };
    */

    const handleEventMouseEnter = (arg) => {
        if (arg?.event?._def?.extendedProps?.event_type === 'absence') return;
    
        const tooltipWidth = 345; // Approximate width of the tooltip
        const tooltipHeight = 150; // Approximate height of the tooltip
        const screenWidth = window.innerWidth;
        const screenHeight = window.innerHeight;
    
        let leftPosition = arg.jsEvent.clientX;
        let topPosition = arg.jsEvent.clientY + 10;
    
        // If tooltip goes beyond the right edge, adjust left position
        if (leftPosition + tooltipWidth > screenWidth) {
            leftPosition = screenWidth - tooltipWidth - 15; // Add some padding
        }
    
        // If tooltip goes beyond the bottom, adjust top position
        if (topPosition + tooltipHeight > screenHeight) {
            topPosition = screenHeight - tooltipHeight - 15; // Add some padding
        }
    
        setTooltipInfo({
            data: arg.event,
            top: topPosition,
            left: leftPosition,
        });
    };

    const handleEventMouseLeave = () => {
        setTooltipInfo(null);
    };

    const calendarRef = useRef(null);

    const handlePrev = () => {
        const calendarApi = calendarRef.current.getApi();
        calendarApi.prev(); // Go to the previous view
        updateTitle(calendarApi);
    };

    const handleNext = () => {
        const calendarApi = calendarRef.current.getApi();
        calendarApi.next(); // Go to the next view
        updateTitle(calendarApi);
    };

    const handleToday = () => {
        const calendarApi = calendarRef.current.getApi();
        calendarApi.today(); // Go to today's date
        updateTitle(calendarApi);
    };

    const updateTitle = (calendarApi) => {
        const titleElement = document.getElementById("calendar-title");
        titleElement.textContent = calendarApi.view.title; // Update the title dynamically
    };

    return (
        <>
            <SoftBox className={props.class == 'calendar-main' ? 'calendar-main' : 'fullcalanderCustomDesign'}>
                {useMemo(
                    () => (
                        <>
                            {Cookies.get("permissions").indexOf(
                                "CalAllE"
                            ) !== -1 ||
                                Cookies.get("permissions").indexOf(
                                    "CalOwnE"
                                ) !== -1 ? (
                                <>
                                    <Calendar
                                        ref={calendarRef}
                                        locales={allLocales}
                                        allDaySlot={false}
                                        locale={'de'}
                                        weekNumbers={true}
                                        dashboard={true}
                                        absenceBtn={props?.absenceBtn}
                                        abhnameFilter={true}
                                        searchKey={searchKey}
                                        getAbhnamheStatus={getAbhnamheStatus}
                                        setRefresh={setRefresh}
                                        refresh={refresh}
                                        initialView="dayGridMonth"
                                        views={{
                                            dayGridMonth: {
                                                dayMaxEventRows: 4,
                                            },
                                            timeGridWeek: {
                                                dayMaxEventRows: 4,
                                            },
                                        }}
                                        initialDate={initialDate}
                                        events={calendarEventsData}
                                        eventClick={(e) => {

                                            if (editEvent) {
                                                var data = {
                                                    id: e.event.id,
                                                    project_nr: e.event._def.extendedProps.project_nr,
                                                    title: e.event._def.extendedProps.event_titel,
                                                    action: "edit_plan",
                                                    planform: (e.event._def.extendedProps.event_type == 'order_processing' ? "Projects" : e.event._def.extendedProps.event_type == 'ACFertigmeldung' ? 'ACFertigmeldung' : "Reclamation"),
                                                };

                                                setPlanning(data);
                                                setRefresh2(refresh2 + 1);

                                                document.getElementById('triggerEditPlanBtn').click();

                                            } else {
                                                handleEventClick(e);
                                            }
                                        }}
                                        eventDrop={(e) => {
                                            handleEventDrop(e);
                                        }}

                                        datesSet={(arg) => {
                                            var start_date = new Date(arg.start);

                                            start_date = start_date.toISOString();
                                            var end_date = new Date(arg.end);
                                            end_date = end_date.toISOString();
                                            setStartDate(start_date);
                                            setEndDate(end_date);
                                        }}
                                        selectable
                                        editable={JSON.parse(Cookies.get('permissions')).indexOf("CnCcE") !== -1 ? false : true}
                                        buttonText={{
                                            today: t('today'),
                                            day: t('day'),
                                            week: t('week'),
                                            month: t('month'),
                                            list: t('list'),
                                        }}
                                        dayHeaderContent={() => null} // Hides the day headers
                                        customButtons={{
                                            absenceRequest: {
                                                text: "+ " + t("Absence"),
                                                click: function () {
                                                    document
                                                        .getElementById("absenceRequestBtn")
                                                        .click();
                                                },
                                            },
                                        }}
                                        headerToolbar={{
                                            //left: "title,prev,next",
                                            left: "",
                                            center: '',
                                            right: '',
                                        }}
                                        eventTimeFormat={{
                                            hour: "2-digit",
                                            minute: "2-digit",
                                            hour12: false,
                                        }}
                                        eventMouseEnter={handleEventMouseEnter}
                                        eventMouseLeave={handleEventMouseLeave}
                                    />
                                </>
                            ) : (
                                <></>
                            )}
                        </>
                    ),
                    [calendarEventsData]
                )}
            </SoftBox>

            {tooltipInfo && (
                <div
                    style={{
                        position: 'fixed',
                        top: tooltipInfo.top,
                        left: tooltipInfo.left,
                        border: '1px solid #E7EBF3',
                        zIndex: 9999,
                        boxShadow: '0px 3px 6px -3px #17181814',
                        borderRadius: '12px',
                        padding: '16px'
                    }}
                    className="fullcalender-event-hover-view"
                >
                    <div class="event-hover-view-header border-bottom pb-1 d-flex align-items-center justify-content-start gap-2">
                        <img
                            style={{
                                width: '20px',
                            }}
                            src="/assets/icons/Clipboard.svg"
                        />
                        <NavLink
                            className={'text-primary'}
                            style={{
                                // maxWidth: '250px',
                                // position: 'absolute',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                fontSize: '16px',
                                fontWeight: 500
                            }}
                            to={'/projects/' + tooltipInfo?.data?._def?.extendedProps?.project_nr}><span style={{ textDecoration: 'none' }}> {tooltipInfo?.data?._def?.extendedProps?.project_nr}</span> <span dangerouslySetInnerHTML={{ __html: tooltipInfo?.data?._def?.extendedProps?.project_info }}></span></NavLink>
                    </div>
                    <div class="event-hover-view-body">
                        <div className="d-flex align-items-center justify-content-end gap-2">
                            <p className="w-50 text-secondary text-link">Modul:</p>
                            <p className="w-50 text-end text-primary text-link">{(tooltipInfo?.data?._def?.extendedProps?.anzahl_module && tooltipInfo?.data?._def?.extendedProps?.anzahl_module != 'null' ? tooltipInfo?.data?._def?.extendedProps?.anzahl_module : '') + ' Stück | ' + (tooltipInfo?.data?._def?.extendedProps?.module_eingeben == 'no' ? (tooltipInfo?.data?._def?.extendedProps?.module_short_title && tooltipInfo?.data?._def?.extendedProps?.module_short_title != 'null' ? tooltipInfo?.data?._def?.extendedProps?.module_short_title : '') : (tooltipInfo?.data?._def?.extendedProps?.custom_module_short_title && tooltipInfo?.data?._def?.extendedProps?.custom_module_short_title != 'null' ? tooltipInfo?.data?._def?.extendedProps?.custom_module_short_title : ''))}</p>
                        </div>
                        <div className="d-flex align-items-center justify-content-end gap-2">
                            <p className="w-50 text-secondary text-link">Speicher:</p>
                            <p className="w-50 text-end text-primary text-link">{(tooltipInfo?.data?._def?.extendedProps?.speicher_eingeben == 'no' ? (tooltipInfo?.data?._def?.extendedProps?.speicher_name != 'null' ? tooltipInfo?.data?._def?.extendedProps?.speicher_name : '') : (tooltipInfo?.data?._def?.extendedProps?.custom_speicher_name != 'null' ? tooltipInfo?.data?._def?.extendedProps?.custom_speicher_name : ''))} </p>
                        </div>
                        <div className="d-flex align-items-center justify-content-end gap-2">
                            <p className="w-50 text-secondary text-link">Dachtyp:</p>
                            <p className="w-50 text-end text-primary text-link">{(tooltipInfo?.data?._def?.extendedProps?.dachtyp && tooltipInfo?.data?._def?.extendedProps?.dachtyp != 'null' ? tooltipInfo?.data?._def?.extendedProps?.dachtyp : '')}</p>
                        </div>
                        <p>
                            {(tooltipInfo?.data?._def?.extendedProps?.gerust_notwendig == 'JA' ? 'GR ' : '') + (tooltipInfo?.data?._def?.extendedProps?.frealeatung_isolierung == 'JA' ? 'FR ' : '') + (tooltipInfo?.data?._def?.extendedProps?.grabungsarbeiten == 'Yes' ? 'GA ' : '')}
                        </p>
                    </div>
                </div>
            )}

            <button
                type="button"
                id="calanderDataOpen"
                className="btn bg-gradient-primary d-none"
                data-bs-toggle="modal"
                data-bs-target="#calanderModal"
            >
                View Calander Click Event Data
            </button>

            <div
                className="modal fade"
                id="calanderModal"
                tabindex="-1"
                role="dialog"
                aria-labelledby="calanderModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered" role="document" style={{ maxWidth: '640px' }}>
                    <div className="modal-content">
                        <div className="modal-header">
                            <h6
                                className="modal-title"
                                id="calanderModalLabel"
                            >
                                #{modalData?.project_id ? modalData?.project_id : modalData?.id}
                            </h6>
                            <button
                                type="button"
                                className="btn-close text-dark"
                                id="projectInfoModalClose"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            >
                            </button>
                        </div>
                        {modalData?.event_type == 'project' && (
                            <div className="modal-body">
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <div className="d-flex justify-content-between align-items-center gap-3">
                                        <h6
                                            className="modal-title w-60"
                                        >
                                            {getValues("code")} -{" "}
                                            {modalData?.name}{", "}
                                            {'Plz: ' + modalData?.plz}{", "}

                                            {JSON.parse(Cookies.get('permissions')).indexOf("CanSCIn") !== -1 ? (
                                                <>
                                                    {'Tel: ' + modalData?.telefonnummer}{", "}
                                                </>
                                            ) : ''}

                                            {modalData.salse_person ? modalData.salse_person : ""} -{" "}
                                            {modalData.id ? modalData.id : ""}
                                        </h6>

                                        {Cookies.get("permissions").indexOf("QR") !== -1 && Cookies.get("permissions").indexOf("QU") !== -1 ? (
                                            <>{modalData?.id ? <div><ProjectStatus labelShow={false} projectid={modalData.project_id} status={modalData?.status} /></div> : ''}</>
                                        ) : null}
                                    </div>

                                    <p className="text-secondary text-link m-0">{t("Start Date & Time")}: {getValues("time")} -{" "} {dateFormat(getValues("date"), "dd.mm.yyyy")}{" "}</p>

                                    <div className="d-flex justify-content-start align-items-center gap-2 mt-2 mb-3">
                                        <div className="badge-secondary d-flex justify-content-between align-items-center gap-1">
                                            <img src="/assets/icons/EnvelopeSimple.svg" />
                                            <p className="text-primary text-sm m-0">aasas</p>
                                        </div>

                                        <div className="badge-secondary d-flex justify-content-between align-items-center gap-1">
                                            <img src="/assets/icons/Phone.svg" />
                                            <p className="text-primary text-sm m-0">aasas</p>
                                        </div>

                                        <div className="badge-secondary d-flex justify-content-between align-items-center gap-1">
                                            <img src="/assets/icons/Clipboard.svg" />
                                            <NavLink
                                                to={"/projects/" + modalData.id}
                                                onClick={() => { document.getElementById('projectInfoModalClose').click() }}
                                                className="text-brand-primary text-sm m-0"
                                            >{modalData.id}</NavLink>
                                        </div>

                                        <div className="badge-secondary d-flex justify-content-between align-items-center gap-1">
                                            <img src="/assets/icons/UserCircle.svg" />
                                            <p className="text-primary text-sm m-0">{modalData.salse_person}</p>
                                        </div>
                                    </div>

                                    <div className="p-3 bg-light mt-3">
                                        <p className="text-primary text-md">{t("Client Information")}</p>
                                        <div className="d-flex justify-content-start align-items-center gap-3">
                                            <p className="text-secondary text-link w-50">{t("Name")}</p>
                                            <p className="text-primary text-link w-50">{getValues("vorname") ? getValues("vorname") : ""}{" "}{getValues("name") ? getValues("name") : ""}</p>
                                        </div>

                                        <div className="d-flex justify-content-start align-items-center gap-3">
                                            <p className="text-secondary text-link w-50">{t("Address")}</p>
                                            <a
                                                className="text-brand-primary text-link w-50"
                                                target="_blank"
                                                href={getValues("street") ? 'https://www.google.com/maps/place/' + getValues("street") + ' ' + getValues("nr") + ', ' + getValues("plz") + ' ' + getValues("ort") + ', Germany' : ""}
                                                style={{
                                                    color: '#1D7BDB', // Ensures link color matches the ellipsis color
                                                    textDecoration: 'none', // Optional, removes underline
                                                }}
                                            >
                                                {getValues("street") ? getValues("street") : ""}
                                                {getValues("nr") ? ' ' + getValues("nr") : ""}
                                            </a>
                                        </div>

                                        <div className="d-flex justify-content-start align-items-center gap-3">
                                            <p className="text-secondary text-link w-50">{t("PLZ und Ort")}</p>
                                            <a
                                                className="text-primary text-link w-50"
                                                target="_blank"
                                                href={getValues("street") ? 'https://www.google.com/maps/place/' + getValues("street") + ' ' + getValues("nr") + ', ' + getValues("plz") + ' ' + getValues("ort") + ', Germany' : ""}
                                            >
                                                {getValues("plz") ? getValues("plz") : ""},{" "}
                                                {getValues("ort") ? getValues("ort") : ""}
                                            </a>
                                        </div>

                                        {JSON.parse(Cookies.get('permissions')).indexOf("CanSCIn") !== -1 ? (
                                            <div className="d-flex justify-content-start align-items-center gap-3">
                                                <p className="text-secondary text-link w-50">{t("Phone")}</p>
                                                <div className="w-50">
                                                    <a
                                                        href={getValues("telefonnummer") ? "tel:" + getValues("telefonnummer") : "#"}
                                                        className="text-brand-primary text-link"
                                                    >
                                                        {getValues("telefonnummer") ? getValues("telefonnummer") : ""}
                                                    </a>

                                                    {getValues("mobilnummer") ? (
                                                        <a
                                                            href={getValues("mobilnummer") ? "tel:" + getValues("mobilnummer") : "#"}
                                                            className="text-brand-primary text-link mb-0"
                                                        >
                                                            , {getValues("mobilnummer") ? getValues("mobilnummer") : ""}
                                                        </a>
                                                    ) : ''}
                                                </div>
                                            </div>
                                        ) : ''}

                                        <div className="d-flex justify-content-start align-items-center gap-3">
                                            <p className="text-secondary text-link w-50">{t("Modul")}</p>
                                            <p className="text-primary text-link w-50"></p>
                                        </div>
                                        <div className="d-flex justify-content-start align-items-center gap-3">
                                            <p className="text-secondary text-link w-50">{t("Speicher")}</p>
                                            <p className="text-primary text-link w-50"></p>
                                        </div>
                                        <div className="d-flex justify-content-start align-items-center gap-3">
                                            <p className="text-secondary text-link w-50">{t("Dachtyp")}</p>
                                            <p className="text-primary text-link w-50"></p>
                                        </div>
                                    </div>

                                    <div className="p-3 bg-light mt-3">
                                        <p className="text-primary text-md">{t("Project Description")}</p>
                                        <p className="text-secondary text-link mb-0">{modalData.desc}</p>
                                    </div>
                                </form>
                            </div>
                        )}

                        {modalData?.event_type == 'order_processing' || modalData?.event_type == 'reclamation' || modalData?.event_type == 'ACFertigmeldung' ? (
                            <div className="modal-body">
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <div className="d-flex justify-content-between align-items-center gap-3">
                                        <h6
                                            className="modal-title"
                                        >
                                            {getValues("code")} -{" "}
                                            {modalData?.name}{", "}
                                            {'Plz: ' + modalData?.plz}{", "}

                                            {JSON.parse(Cookies.get('permissions')).indexOf("CanSCIn") !== -1 ? (
                                                <>
                                                    {'Tel: ' + modalData?.telefonnummer}{", "}
                                                </>
                                            ) : ''}

                                            {modalData.salse_person ? modalData.salse_person : ""} -{" "}
                                            {modalData.id ? modalData.project_id : ""}
                                        </h6>

                                        {Cookies.get("permissions").indexOf("QR") !== -1 && Cookies.get("permissions").indexOf("QU") !== -1 ? (
                                            <>{modalData?.id ? <div><ProjectStatus labelShow={false} projectid={modalData.project_id} status={modalData?.status} /></div> : ''}</>
                                        ) : null}
                                    </div>

                                    <p className="text-secondary text-link m-0">{t("Start Date & Time")}: {getValues("time")} -{" "} {dateFormat(getValues("date"), "dd.mm.yyyy")}{" "}</p>

                                    <div className="d-flex justify-content-start align-items-center gap-2 mt-2 mb-3">
                                        <div className="badge-secondary d-flex justify-content-between align-items-center gap-1">
                                            <img src="/assets/icons/EnvelopeSimple.svg" />
                                            <p className="text-primary text-sm m-0">aasas</p>
                                        </div>

                                        <div className="badge-secondary d-flex justify-content-between align-items-center gap-1">
                                            <img src="/assets/icons/Phone.svg" />
                                            <p className="text-primary text-sm m-0">aasas</p>
                                        </div>

                                        <div className="badge-secondary d-flex justify-content-between align-items-center gap-1">
                                            <img src="/assets/icons/Clipboard.svg" />
                                            <NavLink
                                                to={"/projects/" + modalData.project_id}
                                                className="text-brand-primary text-sm m-0"
                                                onClick={() => { document.getElementById('projectInfoModalClose').click() }}
                                            >{modalData.project_id}</NavLink>
                                        </div>

                                        <div className="badge-secondary d-flex justify-content-between align-items-center gap-1">
                                            <img src="/assets/icons/UserCircle.svg" />
                                            <p className="text-primary text-sm m-0">{modalData.salse_person}</p>
                                        </div>
                                    </div>

                                    <div className="p-3 bg-light mt-3">
                                        <p className="text-primary text-md">{t("Client Information")}</p>
                                        <div className="d-flex justify-content-start align-items-center gap-3">
                                            <p className="text-secondary text-link w-50">{t("Name")}</p>
                                            <p className="text-primary text-link w-50">{getValues("vorname") ? getValues("vorname") : ""}{" "}{getValues("name") ? getValues("name") : ""}</p>
                                        </div>

                                        <div className="d-flex justify-content-start align-items-center gap-3">
                                            <p className="text-secondary text-link w-50">{t("Address")}</p>
                                            <a
                                                className="text-brand-primary text-link w-50"
                                                target="_blank"
                                                href={getValues("street") ? 'https://www.google.com/maps/place/' + getValues("street") + ' ' + getValues("nr") + ', ' + getValues("plz") + ' ' + getValues("ort") + ', Germany' : ""}
                                                style={{
                                                    color: '#1D7BDB', // Ensures link color matches the ellipsis color
                                                    textDecoration: 'none', // Optional, removes underline
                                                }}
                                            >
                                                {getValues("street") ? getValues("street") : ""}
                                                {getValues("nr") ? ' ' + getValues("nr") : ""}
                                            </a>
                                        </div>

                                        <div className="d-flex justify-content-start align-items-center gap-3">
                                            <p className="text-secondary text-link w-50">{t("PLZ und Ort")}</p>
                                            <a
                                                className="text-primary text-link w-50"
                                                target="_blank"
                                                href={getValues("street") ? 'https://www.google.com/maps/place/' + getValues("street") + ' ' + getValues("nr") + ', ' + getValues("plz") + ' ' + getValues("ort") + ', Germany' : ""}
                                            >
                                                {getValues("plz") ? getValues("plz") : ""},{" "}
                                                {getValues("ort") ? getValues("ort") : ""}
                                            </a>
                                        </div>

                                        {JSON.parse(Cookies.get('permissions')).indexOf("CanSCIn") !== -1 ? (
                                            <div className="d-flex justify-content-start align-items-center gap-3">
                                                <p className="text-secondary text-link w-50">{t("Phone")}</p>
                                                <div className="w-50">
                                                    <a
                                                        href={getValues("telefonnummer") ? "tel:" + getValues("telefonnummer") : "#"}
                                                        className="text-brand-primary text-link"
                                                    >
                                                        {getValues("telefonnummer") ? getValues("telefonnummer") : ""}
                                                    </a>

                                                    {getValues("mobilnummer") ? (
                                                        <a
                                                            href={getValues("mobilnummer") ? "tel:" + getValues("mobilnummer") : "#"}
                                                            className="text-brand-primary text-link mb-0"
                                                        >
                                                            , {getValues("mobilnummer") ? getValues("mobilnummer") : ""}
                                                        </a>
                                                    ) : ''}
                                                </div>
                                            </div>
                                        ) : ''}

                                        {modalData?.event_type == 'reclamation' ? (
                                            <>
                                                {modalData?.products && modalData?.products.map(function (product, i) {
                                                    return (
                                                        <div className="d-flex justify-content-start align-items-center gap-3">
                                                            <p className="text-secondary text-link w-50">{t("Product")}</p>
                                                            <p className="text-primary text-link w-50">{product?.quantity} Stück. {product?.title}</p>
                                                        </div>
                                                    );
                                                })}
                                            </>
                                        ) : (
                                            <>
                                                <div className="d-flex justify-content-start align-items-center gap-3">
                                                    <p className="text-secondary text-link w-50">{t("Modul")}</p>
                                                    <p className="text-primary text-link w-50">{modalData?.modul}</p>
                                                </div>

                                                <div className="d-flex justify-content-start align-items-center gap-3">
                                                    <p className="text-secondary text-link w-50">{t("Speicher")}</p>
                                                    <p className="text-primary text-link w-50">{modalData?.speicher}</p>
                                                </div>

                                                <div className="d-flex justify-content-start align-items-center gap-3">
                                                    <p className="text-secondary text-link w-50">{t("Dachtyp")}</p>
                                                    <p className="text-primary text-link w-50">{modalData?.dachtyp}</p>
                                                </div>
                                            </>
                                        )}
                                    </div>

                                    <div className="p-3 bg-light mt-3">
                                        <p className="text-primary text-md">{t(modalData?.reclamation_id ? "Reklamatione Description" : "Project Description")}</p>
                                        <p className="text-secondary text-link mb-0">{modalData?.reclamation_id ? modalData?.reclamation_description : modalData.desc}</p>
                                    </div>

                                    <div
                                        style={{
                                            display: "flex",
                                            flexWrap: "wrap",
                                            flexShrink: "0",
                                            alignItems: "center",
                                            justifyContent: "flex-end",
                                        }}

                                        className="d-none"
                                    >
                                        <button
                                            type="button"
                                            style={{
                                                textTransform: "capitalize",
                                                padding: "0.50rem 1.5rem",
                                                marginRight: "10px",
                                            }}
                                            className="btn btn-outline-dark btn-start mt-2"
                                            data-bs-dismiss="modal"
                                            aria-label="Close"
                                        >
                                            {t("Cancel")}
                                        </button>
                                        {/* <button type="submit" style={{textTransform: 'capitalize', padding: '0.50rem 2rem'}} className="btn btn-dark btn-start mt-2">{t('Save')}</button>  */}
                                        {Cookies.get("permissions").indexOf("QR") !==
                                            -1 &&
                                            Cookies.get("permissions").indexOf("QU") !==
                                            -1 ? (
                                            <>
                                                {!saving && (
                                                    <button
                                                        type="submit"
                                                        style={{
                                                            textTransform: "capitalize",
                                                            padding: "0.50rem 2rem",
                                                        }}
                                                        className="btn bg-gradient-primary btn-start mt-2"
                                                    >
                                                        {t("Save")}
                                                    </button>
                                                )}
                                                {saving && (
                                                    <button
                                                        type="submit"
                                                        style={{
                                                            textTransform: "capitalize",
                                                            padding: "0.50rem 2rem",
                                                        }}
                                                        className="btn btn-disabled mt-2"
                                                        disabled
                                                    >
                                                        {t("Saving ...")}
                                                    </button>
                                                )}
                                            </>
                                        ) : (
                                            <></>
                                        )}
                                    </div>
                                </form>
                            </div>
                        ) : ''}
                    </div>
                </div>
            </div>


            <NewAufgabe />

            <AbsenceRequest setReloadCalendarData={setReloadCalendarData} />
            <AbsenceRequestEdit
                id={absenceId}
                refresh={refreshAbsemce}
                setReloadCalendarData={setReloadCalendarData}
            />

            {editEvent ? (
                <EditPlan data={planning} refresh={refresh2} setRefresh={setRefresh} />
            ) : ''}

            {renderSuccessSB}
        </>
    );
}

export default Index;
