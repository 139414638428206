import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip } from 'chart.js';
import callFetch from "../../../../../../helpers/callFetch";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip);

const EstimatedProductionThreeLineChart = (props) => {
  let params = useParams();

  const [allDatasets, setAllDatasets] = useState([]);
  const [visibleDatasets, setVisibleDatasets] = useState({
    production_kwh: true,
    verbrauch: true,
    autonomie: true,
  });

  const [labels, setLabels] = useState([]);

  useEffect(() => {
    callFetch("estimated-production-consumption/" + (props?.projectid ? props?.projectid : params.id), "GET", [])
      .then((res) => {
        const resData = res?.response_data_three;

        console.log('Response Data:', resData);

        if (resData && resData.autonomie && resData.production_kwh && resData.verbrauch) {
          const datasets = [
            {
              label: 'Produktion kWh',
              key: 'production_kwh',
              data: resData.production_kwh,
              backgroundColor: 'rgba(229, 62, 62, 0.2)', // Red
              borderColor: 'rgba(229, 62, 62, 1)',
              borderWidth: 2,
              pointBorderColor: 'rgba(229, 62, 62, 1)',
              pointBackgroundColor: 'white',
              pointRadius: 4,
              fill: false,
            },
            {
              label: 'Verbrauch kWh',
              key: 'verbrauch',
              data: resData.verbrauch,
              backgroundColor: 'rgba(72, 187, 120, 0.2)', // Green
              borderColor: 'rgba(72, 187, 120, 1)',
              borderWidth: 2,
              pointBorderColor: 'rgba(72, 187, 120, 1)',
              pointBackgroundColor: 'white',
              pointRadius: 4,
              fill: false,
            },
            {
              label: 'Autonotmle In %',
              key: 'autonomie',
              data: resData.autonomie,
              backgroundColor: 'rgba(32, 141, 252, 0.2)', // Blue
              borderColor: 'rgba(32, 141, 252, 1)',
              borderWidth: 2,
              pointBorderColor: 'rgba(32, 141, 252, 1)',
              pointBackgroundColor: 'white',
              pointRadius: 4,
              fill: false,
            },
          ];

          setAllDatasets(datasets);
          setLabels([
            'Januar', 'Februar', 'März', 'April', 'Mai', 'Juni',
            'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember',
          ]);
        } else {
          console.warn("Incomplete data structure in response:", resData);
        }
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, [props?.projectid, params.id, props?.value]);

  // Toggle dataset visibility
  const handleCheckboxChange = (datasetKey) => {
    setVisibleDatasets((prev) => ({
      ...prev,
      [datasetKey]: !prev[datasetKey],
    }));
  };

  // Filter visible datasets
  const filteredDatasets = allDatasets.map(dataset => ({
    ...dataset,
    hidden: !visibleDatasets[dataset.key],
  }));

  // Chart options
  const options = {
    responsive: true,
    plugins: {
      legend: { display: false }, // Hide default Chart.js legend
    },
    scales: {
      x: {
        grid: {
          drawBorder: false,
          display: true,
          drawOnChartArea: true,
          borderDash: [5, 5],
        },
      },
      y: {
        beginAtZero: true,
        grid: {
          drawBorder: false,
          display: true,
          drawOnChartArea: true,
          borderDash: [5, 5],
        },
      },
    },
  };

  return (
    <div>
      {/* Title */}
      <p className="mb-2 font-size-16 font-weight-600 text-primary">
        Production and Consumption Data
      </p>

      {/* Checkbox Controls */}
      <div className="d-flex gap-3 mb-3">
        {/* Produktion kWh Checkbox */}
        <div className="form-check d-flex align-items-center">
          <input
            className="produktion form-check-input"
            type="checkbox"
            checked={visibleDatasets.production_kwh}
            onChange={() => handleCheckboxChange("production_kwh")}
            style={{
              appearance: "none",
              width: "18px",
              height: "18px",
              borderRadius: "4px",  
              cursor: "pointer",
            }}
          />
          <label className="form-check-label ms-2 text-primary position-relative top-2">
            Produktion kWh
          </label>
        </div>

        {/* Verbrauch kWh Checkbox */}
        <div className="form-check d-flex align-items-center">
          <input
            className="verbrauch form-check-input"
            type="checkbox"
            checked={visibleDatasets.verbrauch}
            onChange={() => handleCheckboxChange("verbrauch")}
            style={{
              appearance: "none",
              width: "18px",
              height: "18px",
              borderRadius: "4px",  
              cursor: "pointer",
            }}
          />
          <label className="form-check-label ms-2 text-primary position-relative top-2">
            Verbrauch kWh
          </label>
        </div>

        {/* Autonomie In % Checkbox */}
        <div className="form-check d-flex align-items-center">
          <input
            className="eigenverbrauch form-check-input"
            type="checkbox"
            checked={visibleDatasets.autonomie}
            onChange={() => handleCheckboxChange("autonomie")}
            style={{
              appearance: "none",
              width: "18px",
              height: "18px",
              borderRadius: "4px",  
              cursor: "pointer",
            }}
          />
          <label className="form-check-label ms-2 text-primary position-relative top-2">
            Autonomie In %
          </label>
        </div>
      </div>

      {/* Line Chart */}
      <Line data={{ labels, datasets: filteredDatasets }} options={options} />
    </div>
  );
};

export default EstimatedProductionThreeLineChart;
